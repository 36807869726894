// import React, { useState } from 'react';
import React from 'react';
import PropTypes from 'prop-types';
import { gql, useMutation } from '@apollo/client';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
// import { passwordVerify } from '../../../service/authService';
import { toast } from 'react-toastify';
// import { forgotPasswordVerify } from '../../../schema/authSchema';
import TextBoxIcon from '../../../Components/text-box-icon/text-box-icon';
import GlobalLoader from '../../../Components/global-loader/global-loader';
import { EmailIcon, PasswordIcon } from '../../../Components/icons/icons';
// import { EmailIcon, PasswordIcon } from '../../../Components/icons/icons';

const QUERY_FORGOTPASS_VERIFY = gql`
  mutation ForgetPasswordVerify($input: forgetPasswordVerifyInput) {
    forgetPasswordVerify(input: $input) {
      message
    }
  }
`;
export default function ForgotPasswordVerify({ userEmail, t }) {
  const [passVerify, { loading }] = useMutation(QUERY_FORGOTPASS_VERIFY);

  const serverCall = (value) => {
    passVerify({
      variables: {
        input: value,
      },
      onCompleted: (data) => {
        window.location.pathname = '/login';
        toast.success(data.forgetPasswordVerify.message);
      },
    });
  };
  const formik = useFormik({
    initialValues: {
      email: userEmail,
      password: '',
      confirmPassword: '',
      code: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email().required(),
      password: Yup.string()
        .required(t('forgotpassword.validation.password'))
        .min(8, t('forgotpassword.validation.passwordmin')),
      confirmPassword: Yup.string()
        .required(t('forgotpassword.validation.confirmpassword'))
        .oneOf(
          [Yup.ref('password'), null],
          t('forgotpassword.validation.samepassword')
        ),
      code: Yup.string().required(t('forgotpassword.validation.code')),
    }),
    onSubmit: (values) => {
      const payload = {
        ...values,
      };
      serverCall(payload);
    },
    enableReinitialize: true,
  });
  return (
    <>
      <form
        onSubmit={formik.handleSubmit}
        enableReinitialize={true}
        autoComplete="off"
      >
        <TextBoxIcon
          name="email"
          id="email"
          required
          type="email"
          data-cy="email-forgot-page"
          label={t('forgotpassword.email')}
          defaultValue={userEmail}
          {...formik.getFieldProps('email')}
          // blur={handleChange}
          disabled
        >
          <EmailIcon color="#A6A6A6" />
        </TextBoxIcon>
        {/* </div> */}

        <TextBoxIcon
          name="password"
          id="password"
          required
          type="password"
          data-cy="password-forgot-page"
          label={t('forgotpassword.password')}
          infoMessage={t('registerscreen.checks.passwordInfo')}
          infoMessageClassName="custom-info-message"
          {...formik.getFieldProps('password')}
          errorMessage={
            formik.touched?.password && formik.errors?.password
              ? formik.errors.password
              : null
          }
          // onChange={handleChange}
          // disabled={col.name === 'email'}
        >
          <PasswordIcon color="#A6A6A6" />
        </TextBoxIcon>
        {/* </div> */}
        {/* {formik.touched?.password && formik.errors?.password ? (
          <p className="error-message">{formik.errors.password}</p>
        ) : null} */}
        {/* <div className="login-group">
          <span className="group-icon">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8 10V7V7C8 4.791 9.791 3 12 3V3C14.209 3 16 4.791 16 7V7V10"
                stroke="#828282"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M12 14V17"
                stroke="#828282"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17 21H7C5.895 21 5 20.105 5 19V12C5 10.895 5.895 10 7 10H17C18.105 10 19 10.895 19 12V19C19 20.105 18.105 21 17 21Z"
                stroke="#828282"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span> */}
        <TextBoxIcon
          name="confirmPassword"
          id="confirmPassword"
          required
          type="password"
          data-cy="confirmPassword-forgot-page"
          label={t('forgotpassword.confirmpassword')}
          errorMessage={
            formik.touched?.confirmPassword && formik.errors?.confirmPassword
              ? formik.errors.confirmPassword
              : null
          }
          {...formik.getFieldProps('confirmPassword')}
          // onChange={handleChange}
          // disabled={col.name === 'email'}
        >
          <PasswordIcon color="#A6A6A6" />
        </TextBoxIcon>
        {/* </div> */}
        {/* {formik.touched?.confirmPassword && formik.errors?.confirmPassword ? (
          <p className="error-message">{formik.errors.confirmPassword}</p>
        ) : null} */}

        <TextBoxIcon
          name="code"
          id="code"
          required
          type="text"
          data-cy="code-forgot-page"
          label={t('forgotpassword.code')}
          errorMessage={
            formik.touched?.code && formik.errors?.code
              ? formik.errors.code
              : null
          }
          {...formik.getFieldProps('code')}
          // onChange={handleChange}
          // disabled={col.name === 'email'}
        >
          <PasswordIcon color="#A6A6A6" />
        </TextBoxIcon>
        {/* {formik.touched?.code && formik.errors?.code ? (
          <p className="error-message">{formik.errors.code}</p>
        ) : null} */}
        {/* ))} */}
        <div className="login-group btn-block">
          <button
            type="submit"
            className="submit"
            data-cy="submitbtn-forgot-page"
          >
            {t('forgotpassword.submit')}
          </button>
        </div>
        <div className="login-group link">
          <Link to="/login" data-cy="backtoLogin-forgot-page">
            {t('forgotpassword.forgotbackloginmsg')}
          </Link>
        </div>
      </form>
      {loading && (
        <div className="login-loader">
          <GlobalLoader newLoad={true} />
        </div>
      )}
    </>
  );
}
ForgotPasswordVerify.propTypes = {
  userEmail: PropTypes.string.isRequired,
  t: PropTypes.func,
};

ForgotPasswordVerify.defaultProps = {
  t: () => {},
};
