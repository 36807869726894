import { gql } from '@apollo/client';

const QUERY_PROFILEDETAILS = gql`
  query FetchProfileDetails {
    fetchProfileDetails {
      companyName
      data {
        email
        actionable
        timezone
        language
        phoneNumber
        country
        profilePicture
        firstName
        lastName
        userSub
        userType
        userStatus
        selfVerified
        poolId
        organizationId
        appName
        appId
        permissions {
          entityCRUD
          usersCRUD
          rolesCRUD
          organizationBasicCRUD
          organizationAdvancedCRUD
        }
        active
        emailVerification
        userVerification
        onlineStatus
        enableMFA
        backupCodeMFA {
          code
          useStatus
          _id
        }
        parentEntityId
        entityDetails {
          parentEntity {
            entityName
            _id
            entityType
          }
          childEntity {
            entityName
            _id
            entityType
          }
        }
      }
    }
  }
`;

export const TRANSFER_ACCOUNT_OWNERSHIP = gql`
  mutation Mutation($input: transferInput) {
    TransferOwnershipMutate(input: $input) {
      msg
    }
  }
`;

export const QUERY_ORGANIZATIONDETAILS = gql`
  query FetchOrganizationDetails(
    $fetchOrganizationDetailsId: Int
    $profileType: String
  ) {
    fetchOrganizationDetails(
      id: $fetchOrganizationDetailsId
      profileType: $profileType
    ) {
      message
      data {
        _id
        about {
          overview
          industry
          certification
          foundedIn
          headquaters
          commodities
        }
        orgName
        orgId
        orgStatus
        orgEntities
        orgCreatedBy
        orgCreatedAt
        orgType
        orgDomains
        orgAddress
        orgCity
        orgState
        orgZip
        orgCountry
        orgPhone
        orgFax
        orgEmail
        orgWebsite
        orgPrimaryContact {
          contactName
          contactEmail
          contactRole
          contactCreatedAt
          contactCreatedBy
        }
        orgSecondaryContact {
          contactName
          contactEmail
          contactRole
          contactCreatedAt
          contactCreatedBy
        }
        admins
        users
        keyAdmin
        buyers
        suppliers
        forwarders
        orgLogo
        orgCover
        orgDescription
        defaultCurrency
        attachment {
          size
          type
          logo
          parentId
          visibility
          docId
          originalName
          path
          url
          uploadedBy {
            userSub
            userName
            profilePicture
          }
          uploadedByOrg
          deleted
          mimeType
          createdAt
          deletable
          sharable
          createFiles
          _id
          accessGivenTo
          totalItems
        }
        parentId
        product {
          itemName
          itemCode
          itemDescription
          add
          attachments {
            docId
            originalFileName
            fileName
            filePath
            url
            _id
            createdAt
            uploadedBy
            deleted
            type
          }
          _id
        }
        orgLat
        orgLong
        configuration
      }
    }
  }
`;

export const QUERY_PROFILEUPDATION = gql`
  mutation userProfileUpdation($input: userUpdateProfileInput) {
    userProfileupdation(input: $input) {
      message
    }
  }
`;

export const QUERY_FETCH_PROFILE = gql`
  query FetchProfileDetails {
    fetchProfileDetails {
      data {
        email
        actionable
        timezone
        language
        profilePicture
        country
        phoneNumber
        firstName
        lastName
        userSub
        userType
        userStatus
        selfVerified
        poolId
        organizationId
        appName
        appId
        permissions {
          entityCRUD
          usersCRUD
          rolesCRUD
          organizationBasicCRUD
          organizationAdvancedCRUD
        }
        active
        emailVerification
        userVerification
        onlineStatus
        enableMFA
        backupCodeMFA {
          code
          useStatus
          _id
        }
        parentEntityId
        entityDetails {
          parentEntity {
            entityName
            _id
            entityType
          }
          childEntity {
            entityName
            _id
            entityType
          }
        }
      }
      companyName
    }
  }
`;

export const SEARCH_COUNTRY = gql`
  query FetchCountry {
    fetchCountry {
      CountryCode
      CountryName
    }
  }
`;
export const QUERY_FETCH_TIMEZONE = gql`
  query FetchTimezone($input: String!) {
    fetchTimezone(search: $input) {
      message
      data {
        TimeZones
        WindowsTimeZones {
          Name
          Id
        }
      }
    }
  }
`;

export const QUERY_ENABLE_MFA = gql`
  mutation ShowEnableMFA($input: showEnableInput) {
    showEnableMFA(input: $input) {
      message
      qrCode
      success
    }
  }
`;

export const QUERY_VERIFY_MFA_OTP = gql`
  mutation Mutation($input: verifyEnableInput) {
    enableMFA(input: $input) {
      verified
      message
      backupCodeMFA {
        useStatus
        code
        _id
      }
    }
  }
`;

export const QUERY_PROFILE_COMPLETION = gql`
  query ProfileCompletion($input: profileInput) {
    ProfileCompletion(input: $input) {
      success
      data
      message
    }
  }
`;

export default QUERY_PROFILEDETAILS;
