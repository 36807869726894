import { gql } from '@apollo/client';

export const SIGNUP_QUERY = gql`
  mutation userSignUpQuery($input: UserSignUpInput) {
    userSignUp(input: $input) {
      message
    }
  }
`;

export const QUERY_FETCH_INVITE = gql`
  query fetchInviteDetails($input: ID!) {
    fetchInvitationDetails(input: $input) {
      email
      firstName
      lastName
      country
      appName
      appId
    }
  }
`;

export const SEARCH_COUNTRY = gql`
  query FetchCountry {
    fetchCountry {
      CountryCode
      CountryName
    }
  }
`;

export const QUERY_FETCH_TIMEZONE = gql`
  query FetchTimezone($input: String!) {
    fetchTimezone(search: $input) {
      message
      data {
        TimeZones
        WindowsTimeZones {
          Name
          Id
        }
      }
    }
  }
`;

export const FETCH_PARTNER_INVITATION = gql`
  query FetchPartnerInvitationDetails($inviteId: String) {
    fetchPartnerInvitationDetails(inviteId: $inviteId) {
      msg
      data {
        inviteeEmail
        inviteeRole
        inviteId
        _id
        inviterSub
        inviterEmail
        invitedDate
        inviterOrg
        inviterRole
        inviteMetadata {
          stauts
          acceptedBy
          acceptedDate
        }
      }
    }
  }
`;

export const TOKEN_REGENERATION = gql`
  query RefreshToken($input: refreshInput) {
    RefreshToken(input: $input) {
      data {
        AuthenticationResult {
          AccessToken
          IdToken
          ExpiresIn
          TokenType
        }
      }
      msg
    }
  }
`;

export const GET_CHANNEL_PARTNER = gql`
  query GetChannelData($input: channelPartnerInput) {
    getChannelData(input: $input) {
      message
      data {
        _id
        partnerId
        subdomain
        logoPath
        favIcon
        leftMenuConfig
        emailConfig
        contentConfig
        eventAction
      }
    }
  }
`;

export const GET_COMPANYNAME_HEADQUARTER = gql`
  query FetchOrgDetailsWithHeadQuarter($input: fetchOrgWithHeadquarterInput) {
    fetchOrgDetailsWithHeadQuarter(input: $input) {
      message
      success
      data
    }
  }
`;
