import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AdvancePopover from '../advance-popover/advance-popover';
import QuantityCard from '../quantity-card/quantity-card';
import ListingStatus from '../listing-status/listing-status';
import './part-number.scss';
import { ArrowCircleDownIcon } from '../icons/icons';

function PartNumber({ text }) {
  const [eventValue, setEventValue] = useState();
  const togglePopover = (e) => {
    setEventValue(e.target);
  };

  const handleClose = () => {
    setEventValue(null);
  };
  return (
    <div className="part-number">
      <button
        className="part"
        type="button"
        onClick={togglePopover}
        id="partNumberPopover"
      >
        {text} <ArrowCircleDownIcon />
      </button>
      <AdvancePopover
        reference="partNumberPopover"
        getEvent={eventValue}
        closePopover={handleClose}
      >
        <table className="parts">
          <thead>
            <tr>
              <th>Delivery Dates</th>
              <th>Supplier Name</th>
              <th>Quantity</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {[1, 2, 3, 4, 5, 6, 7, 8].map(() => (
              <tr>
                <td>15/04/2023</td>
                <td>Supplier Name</td>
                <td>
                  <QuantityCard />
                </td>
                <td>
                  <ListingStatus statusLabel="Accepted" />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </AdvancePopover>
    </div>
  );
}

export default PartNumber;
PartNumber.propTypes = {
  text: PropTypes.string,
};
PartNumber.defaultProps = {
  text: '',
};
