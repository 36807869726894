import React from 'react';
import PropTypes from 'prop-types';
import './button.scss';
import { bool } from 'yup';
import { ExportIcon, SpinnerIcon } from '../icons/icons';

export default function NewButton(
  //   props,
  {
    children,
    color,
    size,
    type,
    radius,
    styles,
    icon,
    iconRight,
    spacing,
    loading,
    disabled,
    link,
    linkType,
    _blank,
    className,
    ...otherProps
  }
) {
  return (
    <>
      {type === 'submit' && (
        <button
          type="submit"
          className={`button ${size} ${color} ${styles} ${className}`}
          style={{ borderRadius: radius }}
          disabled={loading || disabled}
          {...otherProps}
        >
          {icon && <span style={{ marginRight: spacing }}>{icon}</span>}
          {loading ? 'wait...' : children}
          {loading ? <SpinnerIcon /> : ''}
          {iconRight && (
            <span style={{ marginLeft: spacing }}>{iconRight}</span>
          )}
        </button>
      )}
      {type === 'link' && type !== 'submit' && linkType === 'external' && (
        <a
          href={`http://${link}`}
          className={`link-button ${size} ${color} ${styles} ${className}`}
          target="_blank"
          rel="noreferrer"
        >
          {children} <ExportIcon />
        </a>
      )}
      {(!type || type === 'button') && (
        <button
          type="button"
          data-cy="action-btn"
          className={`button ${size} ${color} ${styles} ${className}`}
          style={{ borderRadius: radius }}
          disabled={disabled}
          {...otherProps}
        >
          {icon && <span style={{ marginRight: spacing }}>{icon}</span>}
          {children}
          {iconRight && (
            <span style={{ marginLeft: spacing }}>{iconRight}</span>
          )}
        </button>
      )}
    </>
  );
}
NewButton.propTypes = {
  children: PropTypes.element,
  color: PropTypes.string,
  size: PropTypes.string,
  type: PropTypes.string,
  radius: PropTypes.string,
  styles: PropTypes.string,
  icon: PropTypes.element.isRequired,
  iconRight: PropTypes.element.isRequired,
  className: PropTypes.string,
  spacing: PropTypes.string,
  loading: bool,
  disabled: bool,
  linkType: PropTypes.string,
  link: PropTypes.string,
  _blank: PropTypes.bool,
};
NewButton.defaultProps = {
  children: {},
  color: '',
  size: '',
  type: '',
  radius: '',
  styles: '',
  spacing: '',
  loading: false,
  disabled: false,
  linkType: '',
  link: '',
  className: '',
  _blank: false,
};
