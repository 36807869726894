export const USER_DETAILS = { name: 'john', userId: 2 };
export const USER_LOGIN = { loggedin: true };
export const ORGANIZATION_DETAILS = 'organizationDetails';
export const LIST_ORGANIZATION_USERS = 'listOrganizationUsers';
export const LIST_ORGANIZATION_ENTITIES = 'listOrganizationEntities';
export const PROFILE_DETAILS = 'profileDetails';
export const LEFTMENUSUPPLIERBUYER = 'leftMenuSupplierBuyer';
export const GROUPLISTMENU = 'grouplistmenu';
export const LISTSUPPLIERBUYER = 'lstsupplierbuyer';
export const GROUPINPUT = 'inputStructureGroup';
export const TOGGLESTATE = 'toggleState';
export const SELECTED_SEARCH_ORG = 'selectedSearchOrg';

export const NOTIFICATIONS_LOADED = 'notificationsLoaded';
export const NOTIFICATIONS_CREATED = 'notificationsCreated';
export const NOTIFICATIONS_READ = 'notificationsRead';

export const CHANNEL_DATA = 'channelPartnerData';
