import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { useParams, useNavigate } from 'react-router-dom';
import { useLazyQuery, useQuery, useMutation } from '@apollo/client';
import {
  SEARCH_COMPANY_GQL,
  INVITE_ORGANIZATION_PARTNER,
  CREATE_RELATIONSHIP,
  LIST_BUYER_SUPPLIER_RELATIONSHIP,
  UPDATE_ORG_RELATIONSHIP,
} from '../../graphQL/invitePartnerGQL';

import './invite-partners.scss';

import Logo from '../../assets/image/logo.png';
import coBrandImage from '../../assets/image/my-way-logo.png';
import Invite from '../../assets/image/invite.svg';
import TextBox from '../../Components/text-box/text-box';
// import Button from '../../Components/button/button';
import CustomSearch from '../../Components/custom-search/custom-search';
import RadioButton from '../../Components/custom-radio/custom-radio';
import UserImage from '../../Components/user-image/user-image';

import AdvancePopover from '../../Components/advance-popover/advance-popover';
import InviteList from '../../Components/invite-list/invite-list';
import getInitials from '../../helpers/utils';
import { giveProfile } from '../../Components/global-notification/notification-list';
import { checkPermission } from '../../helpers/permissionHelper';
import NewButton from '../../Components/button/newButton';
import { SendRightUpIcon } from '../../Components/icons/icons';
// import { error } from 'cypress/types/jquery';

function InvitePartners({ organizationId, selectedSearchOrg, toggleState }) {
  const [t] = useTranslation('invitepartner');
  const [t2] = useTranslation('toast');
  const navigate = useNavigate();
  const [handleSearchCompany] = useLazyQuery(SEARCH_COMPANY_GQL);
  const [handleGetList] = useLazyQuery(LIST_BUYER_SUPPLIER_RELATIONSHIP);
  const { relation } = useParams();

  const { data: pendingPartnerList, refetch } = useQuery(
    LIST_BUYER_SUPPLIER_RELATIONSHIP,
    {
      variables: {
        input: {
          page: 1,
          perPage: 1000,
          relStatus: 'pending',
        },
      },
    },
    { enabled: false }
  );
  // const [handleWaitPartner] = useLazyQuery(LIST_BUYER_SUPPLIER_RELATIONSHIP);
  const { data: handleWaitPartnerList, refetch: refetchWaitPartner } = useQuery(
    LIST_BUYER_SUPPLIER_RELATIONSHIP,
    {
      variables: {
        input: {
          page: 1,
          perPage: 1000,
          inviteType: 'received',
          relStatus: 'pending',
        },
      },
    },
    { enabled: false }
  );
  const [handleUpdateRelationship] = useMutation(UPDATE_ORG_RELATIONSHIP);
  const [handleInviteOrganization] = useMutation(INVITE_ORGANIZATION_PARTNER);
  const [handleCreateRelationship] = useMutation(CREATE_RELATIONSHIP);

  const [eventValue, setEventValue] = useState();

  const [listOrgData, setListOrgData] = useState([]);
  const [listPartner, setListPartner] = useState([]);
  const [waitPartner, setWaitPartner] = useState([]);
  const [SearchData, setSearchData] = useState([]);

  const [partnerType, setPartnerType] = useState('');
  // const [companyEmail, setCompanyEmail] = useState('');
  // const [setCompanyName] = useState('');

  const [updateRelation, setUpdateRelation] = useState(false);
  const [isPartner] = useState(false);
  const [inviteUser, setInviteUser] = useState(false);
  const [newInvite, setNewInvite] = useState(false);
  const [isBranding, setIsBranding] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  const getListDataRefresh = () => {
    handleGetList({
      variables: {
        input: {
          page: 1,
          perPage: 1000,
        },
      },
      onCompleted: (data) => {
        const tempData = {};
        data.listBuyerSupplier.OrganizationList.filter((item) => {
          if (
            !Object.prototype.hasOwnProperty.call(
              tempData,
              item.organization.orgId
            )
          ) {
            tempData[item.organization.orgId] = item;
          }
          return '';
        });
        setListOrgData(tempData);
      },
    });
  };
  const getPendingPartnerRefresh = () => {
    if (
      pendingPartnerList &&
      pendingPartnerList?.listBuyerSupplier?.OrganizationList
    ) {
      setListPartner(pendingPartnerList?.listBuyerSupplier?.OrganizationList);
    }
  };

  useEffect(() => {
    setIsBranding(false);
  }, [isBranding]);
  useEffect(() => {
    getListDataRefresh();
    return () => {};
  }, []);
  useEffect(() => {
    getPendingPartnerRefresh();
    return () => {};
  }, [pendingPartnerList?.listBuyerSupplier?.OrganizationList.length]);

  useEffect(() => {
    if (
      handleWaitPartnerList &&
      handleWaitPartnerList?.listBuyerSupplier?.OrganizationList
    ) {
      setWaitPartner(handleWaitPartnerList.listBuyerSupplier.OrganizationList);
    }
    return () => {};
  }, [
    updateRelation,
    handleWaitPartnerList?.listBuyerSupplier?.OrganizationList?.length,
  ]);

  const handleInputChanged = (e) => {
    if (e.target.value.length > 2) {
      handleSearchCompany({
        variables: {
          search: e.target.value,
        },
        onCompleted: (data) => {
          const dataOnSearch = data?.SearchCompany?.data;
          const searchedData = [];
          dataOnSearch.map((item) => {
            let rawData;
            if (item.orgId !== parseInt(organizationId, 10)) {
              rawData = {
                ...item,
                relationship: listOrgData[item.orgId]?.relationship || '',
                status: listOrgData[item.orgId]?.status || '',
              };
            } else {
              return 0;
            }
            searchedData?.push(rawData);
            return null;
          });
          setSearchData(searchedData);
        },
      });
    } else {
      setSearchData([]);
    }
  };

  const handleInviteUser = () => {
    setInviteUser(!inviteUser);
  };
  const handleBack = (resetForm) => {
    setInviteUser(false);
    setNewInvite(false);
    resetForm();
  };
  const handleNewInvite = () => {
    setInviteUser(!inviteUser);
    setNewInvite(!newInvite);
  };
  const handleNewInviteSubmit = () => {
    if (SearchData.length === 0 && newInvite === false) {
      setErrorMessage(t('invitepartnerscreen.validation.comapny'));
      // toast.error(t2('invitepatners.error.companyname'));
    } else {
      setErrorMessage('');
      setInviteUser(!inviteUser);
      setNewInvite(!newInvite);
    }
  };
  const handleSendInvite = () => {
    if (partnerType === '') {
      toast.error(t2('invitepatners.error.invitetype'));
    } else {
      handleCreateRelationship({
        variables: {
          input: {
            inviteeOrgId: selectedSearchOrg.orgId.toString(),
            relationship: partnerType,
            profileType: giveProfile(relation),
          },
        },
        onCompleted: () => {
          toast.success(t2('invitepatners.success.invitationsent'));
          handleInviteUser();

          refetch();
        },
        // onError: (err) => {
        //   toast.error(err.message);
        // },
      });
    }
  };

  const handleChangePartner = (e) => {
    setPartnerType(e.target.value);
  };

  const handleFreshSentInvite = (values) => {
    handleInviteOrganization({
      variables: {
        input: {
          partnerEmail: values.partnerEmail,
          partnerType: values.partnerType,
          orgType: toggleState,
          profileType: giveProfile(relation),
        },
      },
      onCompleted: () => {
        toast.success(t2('invitepatners.success.invitationsent'));
        handleInviteUser();
      },
      // onError: (err) => {
      //   toast.error(err.message);
      // },
    });
  };

  const formik = useFormik({
    initialValues: {
      partnerEmail: '',
      partnerType: '',
    },
    validationSchema: Yup.object({
      partnerEmail: Yup.string()
        .trim()
        .email(t('invitepartnerscreen.invitepartner.emailinvalid'))
        .required(t('invitepartnerscreen.invitepartner.emailrequiredmeesage')),
      partnerType: Yup.string().required(
        t('invitepartnerscreen.invitepartner.partnertypemessage')
      ),
    }),
    validateOnChange: false,
    validateOnBlur: true,
    onSubmit: (values) => {
      handleFreshSentInvite(values);
      // setCompanyEmail('');
    },
  });

  const togglePopover = (e) => {
    setEventValue(e.target);
  };

  const handleClose = () => {
    setEventValue(null);
  };

  const handleUpdateOrgRelationship = (val, e) => {
    handleUpdateRelationship({
      variables: {
        input: {
          invitedByOrgId: val.invitedByOrg.orgId.toString(),
          inviteeOrgId: val.organization.orgId.toString(),
          status: e.target.textContent === 'Approve' ? 'accepted' : 'rejected',
          profileType: giveProfile(relation),
        },
      },
      onCompleted: () => {
        setUpdateRelation(!updateRelation);
        if (e.target.textContent === 'Approve') {
          toast.success(t2('invitepatners.success.accepted'));
        } else {
          toast.success(t2('invitepatners.success.declined'));
        }
        refetchWaitPartner();
        handleClose();
      },
      // onError: (err) => {
      //   toast.error(err.message);
      // },
    });
  };
  const invitePartnerTab = [
    ['Invite Partner', 'inviteOrg'],
    ['Invite Partner', 'inviteOrg', 'V'],
    ['Invite Partner', 'acceptRejectInvite'],
  ];
  return (
    <div className="invite-partners-container">
      {!invitePartnerTab.some((perm) =>
        checkPermission(giveProfile(relation), ...perm)
      ) ? (
        navigate(`/app/${relation}/unauthorized`)
      ) : (
        <div className="invite-partners">
          <div className="invite-partner-nav">
            <button
              type="button"
              onClick={listPartner.length ? togglePopover : null}
              id="pendingInvites"
            >
              {t('invitepartnerscreen.sentinvite')}({listPartner.length})
            </button>
            <button
              type="button"
              onClick={waitPartner.length ? togglePopover : null}
              id="waitingInvites"
            >
              {t('invitepartnerscreen.receivedinvites')}({waitPartner.length})
            </button>
          </div>
          <AdvancePopover
            reference="pendingInvites"
            getEvent={eventValue}
            closePopover={handleClose}
            placement="left"
          >
            <InviteList
              data={listPartner}
              title={t('invitepartnerscreen.sendinvitemenu.title')}
              acceptText="Confirm"
              declineText="Reject"
              self
            />
          </AdvancePopover>

          <AdvancePopover
            reference="waitingInvites"
            getEvent={eventValue}
            closePopover={handleClose}
            placement="left"
          >
            <InviteList
              data={waitPartner}
              title={t('invitepartnerscreen.sendinvitemenu.receivedinvites')}
              acceptText={t('invitepartnerscreen.sendinvitemenu.approve')}
              declineText={t('invitepartnerscreen.sendinvitemenu.decline')}
              handleClick={handleUpdateOrgRelationship}
            />
          </AdvancePopover>
          <div className="invite-header">
            <div className="invite-logo">
              {isBranding ? (
                <img src={coBrandImage} alt="Logo" />
              ) : (
                <img src={Logo} alt="Logo" />
              )}
            </div>
            <h2 className="invite-title">
              {t('invitepartnerscreen.invite')}{' '}
              {isPartner ? 'Partners' : t('invitepartnerscreen.partners')}{' '}
              {t('invitepartnerscreen.toquloiplatfrom')}
            </h2>
            {checkPermission(
              giveProfile(relation),
              'Invite Partner',
              'inviteOrg'
            ) &&
              (!inviteUser ? (
                <div className="invite-search-box">
                  <CustomSearch
                    data={SearchData}
                    handleInputChanged={handleInputChanged}
                    inviteUser={handleInviteUser}
                    newInvite={handleNewInvite}
                    t={t}
                    errorMessage={errorMessage}
                  />

                  {/* <h3>{t('invitepartnerscreen.description')}</h3> */}
                  <NewButton
                    color="primary"
                    style={{ marginTop: '1.042vw' }}
                    onClick={() => {
                      handleNewInviteSubmit();
                    }}
                  >
                    <span className="mr-5">
                      {t('invitepartnerscreen.sendinvite')}
                    </span>
                    <SendRightUpIcon color="#ffffff" />
                  </NewButton>
                </div>
              ) : (
                <div className="invite-box">
                  <div className="invite-image">
                    <img src={Invite} alt="" />
                  </div>

                  {!newInvite ? (
                    <div className="invite-form">
                      <div className="user d-flex">
                        <UserImage
                          size="large"
                          path={selectedSearchOrg?.orgLogo}
                          removeTooltip
                          marginRight="0.694vw"
                          shortName={getInitials(selectedSearchOrg?.orgName)}
                        />
                        <div className="user-box">
                          <h4>{selectedSearchOrg.orgName}</h4>
                          <p>{selectedSearchOrg.orgEmail}</p>
                          <p>{selectedSearchOrg.orgCountry}</p>
                        </div>
                        <div className="action-box">
                          <button
                            type="button"
                            onClick={() => {
                              setSearchData([]);
                              handleInviteUser();
                            }}
                          >
                            {t('invitepartnerscreen.searchmessage.cancel')}
                          </button>
                        </div>
                      </div>
                      <div className="radio-group">
                        {(selectedSearchOrg.orgType.includes('buyer') ||
                          selectedSearchOrg.orgType.includes('supplier')) && (
                          <>
                            <RadioButton
                              label={t(
                                'invitepartnerscreen.invitepartner.buyer'
                              )}
                              name="userType"
                              id="buyer"
                              value="buyer"
                              onClick={handleChangePartner}
                            />

                            <RadioButton
                              label={t(
                                'invitepartnerscreen.invitepartner.supplier'
                              )}
                              name="userType"
                              value="supplier"
                              id="supplier"
                              onClick={handleChangePartner}
                            />
                          </>
                        )}

                        {selectedSearchOrg.orgType.includes('forwarder') && (
                          <RadioButton
                            label={t(
                              'invitepartnerscreen.invitepartner.forwarder'
                            )}
                            name="userType"
                            value="forwarder"
                            id="forwarder"
                            onClick={handleChangePartner}
                          />
                        )}
                        {selectedSearchOrg.orgType.includes('customBroker') && (
                          <RadioButton
                            label={t(
                              'invitepartnerscreen.invitepartner.custombroker'
                            )}
                            name="userType"
                            value="customBroker"
                            id="customBroker"
                            onClick={handleChangePartner}
                          />
                        )}
                      </div>
                      <NewButton
                        onClick={handleSendInvite}
                        size="full-width"
                        color="primary"
                        style={{ marginTop: '2.361vw' }}
                      >
                        <span className="mr-5">
                          {t('invitepartnerscreen.searchmessage.sendinvite')}
                        </span>
                        <SendRightUpIcon color="#ffffff" />
                      </NewButton>
                    </div>
                  ) : (
                    <form
                      onSubmit={formik.handleSubmit}
                      className="invite-form"
                    >
                      <div>
                        <button
                          className="close"
                          type="button"
                          onClick={() => handleBack(formik.resetForm)}
                        >
                          &times;
                        </button>
                        {/* <TextBox
                    label="Company Name"
                    placeholder="Enter Company Name"
                    onChange={(e) => setCompanyName(e.target.value)}
                  /> */}
                        <TextBox
                          label={t('invitepartnerscreen.invitepartner.email')}
                          placeholder={t(
                            'invitepartnerscreen.invitepartner.emailplaceholder'
                          )}
                          {...formik.getFieldProps('partnerEmail')}
                        />
                        {formik.touched.partnerEmail &&
                        formik.errors.partnerEmail ? (
                          <h6 className="profile-error-message">
                            {formik.errors.partnerEmail}
                          </h6>
                        ) : null}
                        <div className="radio-group">
                          <RadioButton
                            label={t('invitepartnerscreen.invitepartner.buyer')}
                            name="partnerType"
                            id="partnerType"
                            value="buyer"
                            onClick={() => {
                              formik.setFieldValue('partnerType', 'buyer');
                            }}
                          />
                          <RadioButton
                            label={t(
                              'invitepartnerscreen.invitepartner.supplier'
                            )}
                            name="partnerType"
                            id="partnerSupplier"
                            value="supplier"
                            onClick={() => {
                              formik.setFieldValue('partnerType', 'supplier');
                            }}
                          />
                          <RadioButton
                            label={t(
                              'invitepartnerscreen.invitepartner.forwarder'
                            )}
                            name="partnerType"
                            id="partnerForwarder"
                            value="forwarder"
                            onClick={() => {
                              formik.setFieldValue('partnerType', 'forwarder');
                            }}
                          />
                          <RadioButton
                            label={t(
                              'invitepartnerscreen.invitepartner.custombroker'
                            )}
                            name="partnerType"
                            id="partnerCustomBroker"
                            value="customBroker"
                            onClick={() => {
                              formik.setFieldValue(
                                'partnerType',
                                'customBroker'
                              );
                            }}
                          />
                        </div>
                        {formik.touched.partnerType &&
                        formik.errors.partnerType ? (
                          <h6 className="profile-error-message">
                            {formik.errors.partnerType}
                          </h6>
                        ) : null}
                        <NewButton
                          size="full-width"
                          color="primary"
                          type="submit"
                          style={{ marginTop: '2.361vw' }}
                        >
                          <span className="mr-5">
                            {t('invitepartnerscreen.sendinvite')}
                          </span>
                          <SendRightUpIcon color="#ffffff" />
                        </NewButton>
                      </div>
                    </form>
                  )}
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    organizationId:
      state?.omniDetails?.fetchOrganizationDetail?.fetchOrganizationDetails
        ?.data?.orgId,
    selectedSearchOrg: state.omniState.selectedSearchOrg,
    toggleState: state.omniState.toggleState,
  };
}
InvitePartners.propTypes = {
  organizationId: PropTypes.string,
  selectedSearchOrg: PropTypes.instanceOf(Object),
  toggleState: PropTypes.string,
};

InvitePartners.defaultProps = {
  organizationId: '',
  selectedSearchOrg: {},
  toggleState: '',
};

export default connect(mapStateToProps, null)(InvitePartners);
