import React from 'react';
import './users-list.scss';
import UserData from './peopleList.json';

export default function UsersList() {
  return (
    <div className="chat-users-list-container">
      <div className="users-search-box">
        <input type="text" placeholder="Search..." />
        <svg
          width="17"
          height="17"
          viewBox="0 0 17 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.8299 11.0794L15.6852 13.9341L14.7419 14.8774L11.8872 12.0221C10.8251 12.8735 9.50391 13.3367 8.14258 13.3347C4.83058 13.3347 2.14258 10.6467 2.14258 7.33472C2.14258 4.02272 4.83058 1.33472 8.14258 1.33472C11.4546 1.33472 14.1426 4.02272 14.1426 7.33472C14.1445 8.69605 13.6814 10.0172 12.8299 11.0794ZM11.4926 10.5847C12.3387 9.71464 12.8112 8.54834 12.8092 7.33472C12.8092 4.75605 10.7206 2.66805 8.14258 2.66805C5.56391 2.66805 3.47591 4.75605 3.47591 7.33472C3.47591 9.91272 5.56391 12.0014 8.14258 12.0014C9.3562 12.0033 10.5225 11.5308 11.3926 10.6847L11.4926 10.5847Z"
            fill="#C9C9C9"
          />
        </svg>
      </div>
      <ul className="chat-users-list">
        {UserData.map((val) => (
          <li>
            <div className="users-image">
              <img src={val.userImage} alt="users" />
            </div>
            <div className="users-details">
              <h4>{val.first_name}</h4>
              <p>{val.message}</p>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
