import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { AcceptBorderedIcon, RejectBorderedIcon } from '../icons/icons';
import Tooltip from '../tooltip/tooltip';
import '../confirmation/confirmation.scss';
import './notification-dialog.scss';
// import Dialog from '../dialog/dialog';
import Confirmation from '../confirmation/confirmation';
import OutsideClick from '../outside-click/outside-click';

function ActionDialog({ acceptYesClick, rejectYesClick, type, t }) {
  const [handleAccept, setHandleAccept] = useState();
  const [handleReject, setHandleReject] = useState();
  const openAcceptDialog = () => {
    setHandleAccept(!handleAccept);
  };
  const openRejectDialog = () => {
    setHandleReject(!handleReject);
  };
  return (
    <>
      <div className="action small">
        <Tooltip content={t('notifications.acceptTitle')} direction="bottom">
          <button type="button" onClick={openAcceptDialog} className="accept">
            <AcceptBorderedIcon color="#666666" size="20" />
          </button>
        </Tooltip>

        <Tooltip content={t('notifications.rejectTitle')} direction="bottom">
          <button type="button" onClick={openRejectDialog} className="reject">
            <RejectBorderedIcon color="#666666" />
          </button>
        </Tooltip>
      </div>
      {/* <Dialog
        isShowing={handleAccept}
        hide={openAcceptDialog}
        enableOutsideClick 
       > */}
      {handleAccept && (
        <>
          <div className="dialog-mask enable-pointer" />
          <OutsideClick onClickOutside={openAcceptDialog}>
            <Confirmation
              className="notification enable-pointer"
              title={t('notifications.acceptTitle')}
              description={`${t('notifications.descriptionText')} ${t(
                `notifications.${type}`
              )}?`}
              submitButtonText={t('notifications.yesBtn')}
              onSubmitClick={() => {
                acceptYesClick();
                openAcceptDialog();
              }}
              cancelButtonText={t('notifications.noBtn')}
              onCancelClick={openAcceptDialog}
            />
          </OutsideClick>
        </>
      )}
      {/* </Dialog> */}
      {/* {handleAccept && (
        <>
          <div className="dialog-mask" />
          <div className="dialog-box notification">
            <h3 className="title">Accept</h3>
            <p className="description">
              Do you wish to Accept this{' '}
              {type.charAt(0).toUpperCase() + type.slice(1)}?
            </p>
            <div className="button-row">
              <button
                type="button"
                className="cancel"
                onClick={openAcceptDialog}
              >
                No
              </button>
              <button
                type="button"
                className="submit"
                onClick={() => {
                  acceptYesClick();
                  openAcceptDialog();
                }}
              >
                Yes
              </button>
            </div>
          </div>
        </>
      )} */}
      {/* <Dialog
        isShowing={handleReject}
        hide={openAcceptDialog}
        enableOutsideClick
      > */}
      {handleReject && (
        <>
          <div className="dialog-mask enable-pointer" />
          <OutsideClick onClickOutside={openAcceptDialog}>
            <Confirmation
              className="notification enable-pointer"
              title="Reject"
              description={`Do you wish to Reject this
              ${type.charAt(0).toUpperCase() + type.slice(1)}?`}
              submitButtonText="Yes"
              onSubmitClick={() => {
                rejectYesClick();
                openRejectDialog();
              }}
              cancelButtonText="No"
              onCancelClick={openRejectDialog}
            />
          </OutsideClick>
        </>
      )}
      {/* </Dialog> */}
      {/* {handleReject && (
        <>
          <div className="dialog-mask" />
          <div className="dialog-box notification">
            <h3 className="title">Reject</h3>
            <p className="description">
              Do you wish to Reject this{' '}
              {type.charAt(0).toUpperCase() + type.slice(1)}?
            </p>
            <div className="button-row">
              <button
                type="button"
                className="cancel"
                onClick={openRejectDialog}
              >
                No
              </button>
              <button
                type="button"
                className="submit"
                onClick={() => {
                  rejectYesClick();
                  openRejectDialog();
                }}
              >
                Yes
              </button>
            </div>
          </div>
        </>
      )} */}
    </>
  );
}

export default ActionDialog;
ActionDialog.propTypes = {
  acceptYesClick: PropTypes.func,
  rejectYesClick: PropTypes.func,
  type: PropTypes.string,
  t: PropTypes.func,
};
ActionDialog.defaultProps = {
  acceptYesClick: () => {},
  rejectYesClick: () => {},
  type: '',
  t: () => {},
};
