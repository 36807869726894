import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { gql, useMutation } from '@apollo/client';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import './left-menu.scss';
// import brandImage from '../../assets/image/logo.png';
import AccountDetails from './account-details';
import MenuItem from './menu-item';
import GroupComponent from './group-component/group-component';
import AddGroup from './add-group/add-group';
// import Freshdesk from './freshdesk/freshdesk';
import GlobalNotification from '../global-notification/global-notification';
import { getProfileWiseNotifications } from '../../helpers/storeHelper';
import Skeleton from '../skeleton/skeleton';
import { CalenderFillIcon, LampIcon, QuloiLogo } from '../icons/icons';

const QUERY_ADD_GROUP = gql`
  mutation AddbuyerSupplierGroup($input: AddbuyerSupplierGroup) {
    AddbuyerSupplierGroup(input: $input) {
      message
    }
  }
`;

function LeftMenu({
  groupVisible,
  addNewGroup,
  cancelUserGroup,
  isEnableAddGroup,
  inputStructureGroup,
  clickEditGroup,
  editGroup,
  closeEditGroup,
  showNotification,
  closeGroup,
  actionsNotification,
  approvalNotification,
  activeNotificationMenu,
  documentNotificationMenu,
  listsupplierbuyer,
  notificationContainer,
  isBrandingLogo,
  brandLoder,
  menuConfig,
  notifiCount,
  isOnmi,
  poChangesNotification,
  pochanges,
  enableChat,
  showChats,
}) {
  const [t] = useTranslation('toast');
  const [handleAddGroup] = useMutation(QUERY_ADD_GROUP);
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    setTotalCount(getProfileWiseNotifications(listsupplierbuyer));
  }, [notificationContainer, listsupplierbuyer]);

  const handleSubmit = () => {
    handleAddGroup({
      variables: {
        input: {
          groupName: inputStructureGroup.groupName,
          relationship: inputStructureGroup.relationship,
          list: inputStructureGroup?.listOrg?.map((list) => {
            return { orgId: Number(list.orgId) };
          }),
        },
      },
      onCompleted: () => {
        toast.success(t('leftmenu.success.groupcreated'));
        window.location.reload();
      },
      // onError: (err) => {
      //   toast.error(err.message);
      // },
    });
  };
  return (
    <div className="left-menu-container">
      <AccountDetails />
      <div className="left-menu-body">
        {!addNewGroup ? (
          <>
            <div
              className={`left-menu-switch-box ${
                groupVisible || isOnmi ? 'no-padding' : ''
              }`}
            >
              {groupVisible ? (
                <GroupComponent
                  isEnableAddGroup={isEnableAddGroup}
                  clickEditGroup={clickEditGroup}
                  editGroup={editGroup}
                  closeEditGroup={closeEditGroup}
                  closeGroup={closeGroup}
                />
              ) : (
                !isEnableAddGroup && (
                  <>
                    <GlobalNotification
                      showNotification={showNotification}
                      totalCount={totalCount}
                      notifiCount={notifiCount}
                      actionsNotification={actionsNotification}
                      approvalNotification={approvalNotification}
                      activeNotificationMenu={activeNotificationMenu}
                      documentNotificationMenu={documentNotificationMenu}
                      poChangesNotification={poChangesNotification}
                      pochanges={pochanges}
                      enableChat={enableChat}
                      showChats={showChats}
                    />
                    <MenuItem
                      menuConfig={menuConfig?.globalMenuConfig}
                      isOnmi={isOnmi}
                    />
                    {false && (
                      <div className="calender-area">
                        <button className="calender" type="button">
                          <span className="d-flex">
                            <CalenderFillIcon />
                            <span className="label">My Calendar</span>
                          </span>
                          <span className="event-label">
                            5+ Upcoming Events{' '}
                          </span>
                        </button>
                      </div>
                    )}
                    {isOnmi && (
                      <div className="lamp-area">
                        <LampIcon />
                      </div>
                    )}
                  </>
                )
              )}
            </div>
            {/* {editGroup ? '' : <Freshdesk />} */}
          </>
        ) : (
          <div className="left-menu-switch-box no-padding">
            <AddGroup
              cancelClick={cancelUserGroup}
              submitClick={handleSubmit}
            />
          </div>
        )}
        {brandLoder ? (
          <Skeleton skHeight="3.125vw" skWidth="15.278vw" skRadius="0" />
        ) : (
          <Link
            to="/"
            className={`branding${isOnmi ? ' bg-transparent omni' : ''}${
              addNewGroup ? ' pt-10' : ''
            }`}
          >
            {isOnmi ? <QuloiLogo /> : <img src={isBrandingLogo} alt="" />}
          </Link>
        )}
      </div>
    </div>
  );
}
LeftMenu.propTypes = {
  groupVisible: PropTypes.bool,
  addNewGroup: PropTypes.bool,
  cancelUserGroup: PropTypes.func.isRequired,
  isEnableAddGroup: PropTypes.bool,
  inputStructureGroup: PropTypes.instanceOf(Object),
  listsupplierbuyer: PropTypes.instanceOf(Object),
  notificationContainer: PropTypes.instanceOf(Object),
  clickEditGroup: PropTypes.func,
  editGroup: PropTypes.bool,
  closeEditGroup: PropTypes.func,
  showNotification: PropTypes.func,
  closeGroup: PropTypes.func,
  approvalNotification: PropTypes.func,
  actionsNotification: PropTypes.func,
  documentNotificationMenu: PropTypes.func,
  activeNotificationMenu: PropTypes.string,
  isBrandingLogo: PropTypes.string,
  brandLoder: PropTypes.bool,
  menuConfig: PropTypes.instanceOf(Object),
  notifiCount: PropTypes.instanceOf(Object),
  isOnmi: PropTypes.bool,
  poChangesNotification: PropTypes.func,
  pochanges: PropTypes.bool,
  enableChat: PropTypes.bool,
  showChats: PropTypes.func,
};
LeftMenu.defaultProps = {
  groupVisible: false,
  addNewGroup: false,
  isEnableAddGroup: false,
  inputStructureGroup: {},
  listsupplierbuyer: {},
  clickEditGroup: () => {},
  editGroup: false,
  closeEditGroup: () => {},
  showNotification: () => {},
  closeGroup: () => {},
  approvalNotification: () => {},
  actionsNotification: () => {},
  documentNotificationMenu: () => {},
  activeNotificationMenu: '',
  isBrandingLogo: '',
  notificationContainer: {},
  brandLoder: false,
  menuConfig: {},
  notifiCount: {},
  isOnmi: false,
  poChangesNotification: () => {},
  pochanges: false,
  enableChat: false,
  showChats: () => {},
};

function mapStateToProps(state) {
  return {
    inputStructureGroup: state.omniState.inputStructureGroup,
    listsupplierbuyer: state.omniDetails.listsupplierbuyer,
    notificationContainer: state.onmiNotifications,
  };
}

export default connect(mapStateToProps, null)(LeftMenu);
