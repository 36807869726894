import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import './userList.scss';
import { NormalSearchIcon, PlusIcon } from '../../icons/icons';

export default function ListOfUser({ users, addUserInChannel }) {
  const [t] = useTranslation('manageorders');
  const [userList, setUserList] = useState(users);

  const handleFilter = (e) => {
    const query = e.target.value;
    if (query.length > 2) {
      const filterd = [];
      users.forEach((user) => {
        if (
          `${user.firstName}${user.lastName}`
            .toLowerCase()
            .includes(query.toLowerCase())
        ) {
          filterd.push(user);
        }
      });
      setUserList(filterd);
    } else {
      setUserList(users);
    }
  };

  useEffect(() => {
    setUserList(users);
  }, [users]);
  const handleInitialUserName = (str) => {
    let name = str
      .split(' ')
      .map((word) => word[0])
      .join('');
    name = name.substr(0, 1);
    return name.toUpperCase();
  };
  const handleColorCodeIns = (str) => {
    let name = str
      .split(' ')
      .map((word) => word[0])
      .join('');
    name = name.substr(0, 1);
    return name?.toLowerCase();
  };
  return (
    <div className="chat-user-list-container">
      <div className="user-search-box">
        <input
          type="text"
          placeholder={t('manageordersscreen.chatmenu.search')}
          onChange={handleFilter}
        />
        <NormalSearchIcon size="1.181vw" />
      </div>

      <ul className="chat-user-list">
        {users &&
          userList.map((val) => (
            <li className="hover-pointer" key={val.id}>
              <div
                className={`user-image border-white ${handleColorCodeIns(
                  val.firstName
                )}`}
              >
                {val.profilePicture !== '' ? (
                  <img src={val.profilePicture} alt="user" />
                ) : (
                  <span>
                    {handleInitialUserName(val.firstName)}
                    {handleInitialUserName(val.lastName)}
                  </span>
                )}
              </div>
              <div className="user-details">
                <h4>
                  {val.firstName} {val.lastName}
                </h4>
                <p>{val.email}</p>
              </div>
              <button
                type="button"
                className="invite-btn-chat"
                onClick={() => addUserInChannel(val.userSub)}
              >
                <PlusIcon color="#1d3e69" />
                {/* {t('manageordersscreen.chatmenu.add')} */}
              </button>
            </li>
          ))}
      </ul>
    </div>
  );
}

ListOfUser.propTypes = {
  users: PropTypes.objectOf(Array),
  addUserInChannel: PropTypes.func.isRequired,
};
ListOfUser.defaultProps = {
  users: [],
};
