import { gql } from '@apollo/client';

export const SEARCH_QUERY_PORT_TO_PORT = gql`
  query searchOceanPort($input: SearchOceanPort) {
    searchOceanPortDetails(input: $input) {
      data {
        OceanPortLongName
        UNECECode
        GeoCoordinate
        CountryName
      }
    }
  }
`;

export const SEARCH_QUERY_AIRPORT_TO_AIRPORT = gql`
  query SearchOceanPortDetails($input: SearchOceanPort) {
    searchAirPortDetails(input: $input) {
      data {
        AirportCodeIATA
        GeoCoordinate
        AirportLongName
        CountryName
      }
    }
  }
`;

export const DOOR_ADDRESS = gql`
  query GAutoSuggest($searchParameter: String) {
    GAutoSuggest(searchParameter: $searchParameter) {
      success
      data {
        description
        matched_substrings {
          length
          offset
        }
        place_id
        reference
        structured_formatting {
          main_text
          secondary_text
          main_text_matched_substrings {
            length
            offset
          }
        }
        terms {
          offset
          value
        }
        types
      }
      message
    }
  }
`;

export const GET_GEOCODE = gql`
  query FetchGeoCode($searchParameter: String) {
    FetchGeoCode(searchParameter: $searchParameter) {
      success
      data {
        address_components {
          long_name
          short_name
          types
        }
        formatted_address
        geometry {
          location {
            lat
            lng
          }
          location_type
        }
      }
      message
    }
  }
`;

export const getIncoTerm = gql`
  query Query {
    FetchIncoterm {
      data {
        Abbreviation
        Name
        Mode
        OriginAddressReq
        DestinationAddressReq
      }
      message
    }
  }
`;
