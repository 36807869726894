import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { QUERY_SUPPLIER_BUYER_LIST } from '../../../graphQL/OrganizationQuery';
import { inputStructureGroup } from '../../../redux/actions';
import Chip from '../../chip/chip';
import Button from '../../button/button';
import './add-group.scss';
import TextBox from '../../text-box/text-box';

function AddGroup({
  cancelClick,
  submitClick,
  listsupplierbuyer,
  addGroupMenuDetails,
  toggleState,
  Clicklabel,
  // groupInputData,
  // selectedOrg,
}) {
  const [t] = useTranslation('globalleftmenu');
  const [t2] = useTranslation('toast');
  const [searchValue, setSearchValue] = useState('');
  const dispatch = useDispatch();
  const [handleList] = useLazyQuery(QUERY_SUPPLIER_BUYER_LIST);
  const handleInitialOrgName = (str) => {
    let name = str
      .split(' ')
      .map((word) => word[0])
      .join('');
    name = name.substr(0, 2);
    return name.toUpperCase();
  };
  const [selected, setSelected] = useState(
    addGroupMenuDetails?.list?.map((list) => Number(list.orgId)) || []
  );

  const [groupName, setGroupName] = useState('');

  useEffect(() => {
    setGroupName(addGroupMenuDetails.groupName || '');
  }, []);
  const relationship = toggleState;
  const [listOrg, setListOrg] = useState(
    addGroupMenuDetails?.list?.map((list) => {
      return { orgId: list.orgId };
    }) || ''
  );
  const id = addGroupMenuDetails._id || '';

  const handleSelected = (e) => {
    const selectValue = parseInt(e.target.value, 10);

    if (!selected.includes(selectValue)) {
      setSelected([...selected, selectValue]);
      setListOrg((oldList) => [...oldList, { orgId: String(selectValue) }]);
    } else {
      const updated = selected.filter((item) => item !== selectValue);
      setSelected(updated);
      setListOrg(listOrg.filter((itm) => itm.orgId !== String(selectValue)));
    }

    setSearchValue('');
  };
  const removeOrg = (orgId) => {
    const updated = selected.filter((item) => item !== Number(orgId));
    setSelected(updated);
    setListOrg(updated);
  };

  // const handleChangeInput = (e) => {
  //   setgroupName(e.target.value);
  // };

  const handleSubmit = () => {
    if (selected.length === 0) {
      toast.error(t2('addgroup.error.organizations'));
    }
    submitClick();
    handleList({
      variables: {
        input: {
          page: 1,
          relationship: toggleState || 'supplier',
        },
      },
      onCompleted: (data) => {
        dispatch(listsupplierbuyer(data.listsupplierbuyer));
      },
    });
  };
  const serachHandler = (e) => {
    setSearchValue(e.target.value);
  };

  const input = useMemo(() => {
    return {
      groupName,
      relationship,
      listOrg,
      id,
    };
  }, [groupName, relationship, listOrg, id]);

  const formik = useFormik({
    initialValues: {
      groupName: addGroupMenuDetails.groupName || '',
    },
    validationSchema: Yup.object({
      groupName: Yup.string().required(t('grouppopup.emptymessage')),
    }),
    enableReinitialize: true,
    onSubmit: (values) => {
      const payload = {
        ...values,
      };

      input.groupName = payload.groupName;
      handleSubmit();
    },
  });
  useEffect(() => {
    dispatch(inputStructureGroup(input));
    return () => {};
  }, [input]);
  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="add-group-container">
        <div className="add-group-body">
          <div className="input-group">
            <TextBox
              required
              id="groupName"
              name="groupName"
              label={t('grouppopup.groupname')}
              type="text"
              value={groupName}
              onChange={(e) => {
                formik.setFieldValue(groupName, e.target.value);
                setGroupName(e.target.value);
              }}
              errorMessage={
                formik.touched.groupName && formik.errors.groupName
                  ? formik.errors.groupName
                  : null
              }
              {...formik.getFieldProps('groupName')}
            />
          </div>
          <div className="input-group">
            <div
              className={
                selected.length > 0 ? 'selected-group' : 'selected-group blank'
              }
            >
              {selected?.map((item) => {
                const name =
                  addGroupMenuDetails?.list?.filter(
                    (list) => Number(list.orgId) === item
                  )[0]?.orgName ||
                  listsupplierbuyer?.OrganizationList?.filter(
                    (list) => list.organization.orgId === item
                  )[0]?.organization?.orgName;
                return (
                  <Chip
                    name={name}
                    value={item}
                    removeComp={() => removeOrg(item)}
                  />
                );
              })}
              <input
                type="text"
                placeholder={t('grouppopup.placeholder')}
                onChange={serachHandler}
                value={searchValue}
              />
            </div>
          </div>
          <div className="group-list">
            <ul>
              {listsupplierbuyer?.OrganizationList?.filter((val) =>
                val.organization.orgName.toLowerCase().includes(searchValue)
              )?.map((val) => {
                return (
                  <li
                    className={
                      selected.includes(val.organization.orgId) ? 'active' : ''
                    }
                  >
                    <label>
                      <input
                        type="checkbox"
                        defaultChecked={selected.includes(
                          val.organization.orgId
                        )}
                        value={val.organization.orgId}
                        onClick={handleSelected}
                        tabIndex="0"
                      />
                      <span className="group-item">
                        <span className="user">
                          {val?.organization?.orgLogo !== '' ? (
                            <img src={val?.organization?.orgLogo} alt="" />
                          ) : (
                            <span>
                              {handleInitialOrgName(val.organization.orgName)}
                            </span>
                          )}
                        </span>
                        <span className="details">
                          <span className="sup-title">
                            {val.organization.orgName.toUpperCase()}
                          </span>
                          {/* <span className="sup-description">
                          Short details text description about co..
                        </span> */}
                        </span>
                      </span>
                    </label>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        <div className="add-group-footer">
          <Button
            label={t('grouppopup.cancel')}
            size="x-small"
            styles="outline"
            color="cancel"
            onClick={cancelClick}
          />
          <Button
            type="submit"
            label={Clicklabel || t('grouppopup.create')}
            size="x-small"
            color="submit"
            // onClick={handleSubmit}
          />
        </div>
      </div>
    </form>
  );
}
AddGroup.propTypes = {
  cancelClick: PropTypes.func.isRequired,
  submitClick: PropTypes.func.isRequired,
  Clicklabel: PropTypes.string,
  // selectedOrg: PropTypes.instanceOf(Array),
  listsupplierbuyer: PropTypes.instanceOf(Object),
  // groupInputData: PropTypes.func,
  toggleState: PropTypes.string,
  addGroupMenuDetails: PropTypes.instanceOf(Object),
};

function mapStateToProps(state) {
  return {
    listsupplierbuyer: state.omniDetails.listsupplierbuyer,
    addGroupMenuDetails: state.omniDetails.addGroupMenuDetails,
    inputStructureGroup: state.omniState.inputStructureGroup,
    toggleState: state.omniState.toggleState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    inputStructureGroup: (data) => dispatch(inputStructureGroup(data)),
  };
}

AddGroup.defaultProps = {
  listsupplierbuyer: {},
  // selectedOrg: [],
  Clicklabel: '',
  toggleState: '',
  addGroupMenuDetails: {},
  // groupInputData: () => {},
};

export default connect(mapStateToProps, mapDispatchToProps)(AddGroup);
