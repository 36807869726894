import React from 'react';
import Skeleton from '../skeleton/skeleton';
import './user-group.scss';

export default function UserGroupLoader() {
  return (
    <div className="user-group-loader">
      <Skeleton skHeight="1.806vw" skWidth="1.806vw" skRadius="50%" />
      <Skeleton skHeight="1.806vw" skWidth="1.806vw" skRadius="50%" />
      <Skeleton skHeight="1.806vw" skWidth="1.806vw" skRadius="50%" />
    </div>
  );
}
