import React from 'react';
import PropTypes from 'prop-types';
import './text-box.scss';
import { getNumberWithDot } from '../../helpers/utils';
import { InfoIcon } from '../icons/icons';

export default function TextBox({
  id,
  label,
  errorMessage,
  required,
  message,
  mBottom,
  size,
  width,
  className,
  type,
  maxLength,
  bMargin,
  infoMessage,
  infoMessageColour,
  textboxClassName,
  ...otherProps
}) {
  return (
    <div
      className={`text-box-group ${className}`}
      style={{ marginBottom: mBottom, width }}
    >
      {label && (
        <label htmlFor={id} data-cy="text-box-label">
          {label}
          {required && <span className="required">*</span>}
        </label>
      )}
      <div className="textbox-group" data-cy="text-box-input-block">
        <input
          id={id}
          type={type}
          onKeyDown={(e) => {
            if (type === 'number' && id === 'totalPrice') {
              getNumberWithDot({ e, decimalLimit: 4 });
            }
          }}
          {...otherProps}
          style={{ fontSize: size }}
          maxLength={maxLength}
          className={textboxClassName}
        />
        {infoMessage !== '' ? (
          <span className="info-icon">
            <InfoIcon color={infoMessageColour} />
            <span className="info-message">{infoMessage}</span>
          </span>
        ) : null}
      </div>

      {message !== '' && (
        <p className="message" style={{ marginBottom: bMargin }}>
          {message}
        </p>
      )}
      {errorMessage && errorMessage !== '' && (
        <p className="error-message" style={{ marginBottom: bMargin }}>
          {errorMessage}
        </p>
        // <p className="profile-error-message">{errorMessage}</p>
      )}
    </div>
  );
}
TextBox.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  errorMessage: PropTypes.string,
  required: PropTypes.bool,
  message: PropTypes.string,
  mBottom: PropTypes.string,
  size: PropTypes.string,
  width: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string,
  maxLength: PropTypes.number,
  bMargin: PropTypes.string,
  infoMessage: PropTypes.string,
  infoMessageColour: PropTypes.string,
  textboxClassName: PropTypes.string,
};
TextBox.defaultProps = {
  label: '',
  id: '',
  errorMessage: '',
  required: false,
  message: '',
  mBottom: '',
  size: '',
  width: '',
  className: '',
  type: 'text',
  maxLength: 50,
  bMargin: '',
  infoMessage: '',
  infoMessageColour: '',
  textboxClassName: '',
};
