import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// import Checkbox from '../custom-checkbox/custom-checkbox';
import { useParams } from 'react-router-dom';
import UserImage from '../user-image/user-image';
import './invite-list.scss';
import Button from '../button/button';
import { AcceptIcon, RejectIcon } from '../icons/icons';
import CustomTag from '../custom-tag/custom-tag';
import { checkPermission } from '../../helpers/permissionHelper';
import { giveProfile } from '../global-notification/notification-list';

export default function InviteList({
  title,
  data,
  acceptText,
  declineText,
  self,
  handleClick,
}) {
  const [t] = useTranslation('invitepartner');
  const { relation } = useParams();
  return (
    <div className="invite-list">
      <div className="invite-list-header">
        <h2 className="invite-list-title">{title}</h2>
      </div>
      <div className="invite-list-body">
        <ul className="list-items">
          {data?.map((val) => (
            <li>
              <div className="details">
                <UserImage
                  size="x-small"
                  shortName={
                    self
                      ? val?.organization?.orgName
                          .split(' ')
                          .map((word) => word[0])
                          .join('')
                          .substr(0, 2)
                          .toUpperCase()
                      : val?.invitedByOrg?.orgName
                          .split(' ')
                          .map((word) => word[0])
                          .join('')
                          .substr(0, 2)
                          .toUpperCase()
                  }
                  removeTooltip
                  marginRight="0.694vw"
                />
                <div className="details-group">
                  {self ? (
                    <>
                      <h4>{val?.organization?.orgName}</h4>
                      <p>{val?.organization?.orgEmail}</p>
                    </>
                  ) : (
                    <>
                      <h4>{val?.invitedByOrg?.orgName}</h4>
                      <p>{val?.invitedByOrg?.orgEmail}</p>
                    </>
                  )}
                </div>
              </div>
              <div className="action">
                <div className="relationship">
                  {t('invitepartnerscreen.sendinvitemenu.invite')}{' '}
                  {val?.relationship}
                </div>
                {self ? (
                  <CustomTag
                    text={t('invitepartnerscreen.sendinvitemenu.status')}
                    type="waiting"
                  />
                ) : (
                  checkPermission(
                    giveProfile(relation),
                    'Invite Partner',
                    'acceptRejectInvite'
                  ) && (
                    <>
                      <Button
                        styles="submit"
                        label={acceptText}
                        icon={<AcceptIcon size="8" />}
                        size="xx-small"
                        radius="0.694vw"
                        onClick={(e) => handleClick(val, e)}
                      />
                      <Button
                        styles="reject"
                        label={declineText}
                        icon={
                          <RejectIcon
                            color="#747474"
                            size="7"
                            style={{ marginRight: '0.278vw' }}
                          />
                        }
                        size="xx-small"
                        radius="0.694vw"
                        onClick={(e) => handleClick(val, e)}
                      />
                    </>
                  )
                )}
                {/* <Checkbox /> */}
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
InviteList.propTypes = {
  data: PropTypes.instanceOf(Array),
  title: PropTypes.string,
  acceptText: PropTypes.string,
  declineText: PropTypes.string,
  self: PropTypes.bool,
  handleClick: PropTypes.func,
};

InviteList.defaultProps = {
  data: [],
  title: '',
  acceptText: '',
  declineText: '',
  self: false,
  handleClick: () => {},
};
