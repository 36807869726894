import React from 'react';
import './timeline-vertical.scss';
import {
  NewOrderBox,
  DelayedOrderBox,
  UpdatedCargoReady,
  ExpectedCargoReady,
  InfoGradientIcon,
} from '../icons/icons';
import Tooltip from '../tooltip/tooltip';

export default function VerticalTimeline() {
  return (
    <div className="vertical-timeline-container pt">
      <ul className="vertical-timeline-list">
        <li className="done">
          <div className="icon">
            <NewOrderBox />
          </div>
          <div className="details">
            <p className="title">New</p>
            <p className="description">15/05/23</p>
            <p className="status">On Time</p>
          </div>
        </li>
        <li className="current">
          <div className="icon">
            <DelayedOrderBox />
          </div>
          <div className="details">
            <p className="title">Accepted</p>
            <p className="description">
              18/05/23{' '}
              <Tooltip
                direction="top"
                content="Expected- 15/05/23 Actual- 18/05/23"
              >
                <span>
                  <InfoGradientIcon />
                </span>
              </Tooltip>
            </p>
          </div>
        </li>
        <li className="in-progress">
          <div className="icon">
            <ExpectedCargoReady />
          </div>
          <div className="details">
            <p className="title">Cargo ready </p>
            <p className="description">15/05/23</p>
          </div>
        </li>
        <li className="delay">
          <div className="icon">
            <UpdatedCargoReady />
          </div>
          <div className="details">
            <p className="title">Updated cargo ready</p>
            <p className="description">15/05/23</p>
          </div>
        </li>
        <li className="delay">
          <div className="icon">
            <UpdatedCargoReady />
          </div>
          <div className="details">
            <p className="title">Actual cargo ready</p>
            <p className="description">15/05/23</p>
          </div>
        </li>
        <li className="pending">
          <div className="icon">
            <UpdatedCargoReady />
          </div>
          <div className="details">
            <p className="title">Cargo pick-up</p>
            <p className="description">15/05/23</p>
          </div>
        </li>
        <li className="pending">
          <div className="icon">
            <UpdatedCargoReady />
          </div>
          <div className="details">
            <p className="title">CShipment</p>
            <p className="description">15/05/23</p>
          </div>
        </li>
      </ul>
    </div>
  );
}
