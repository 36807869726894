import { gql } from '@apollo/client';

const QUERY_ORDER_DETAILS = gql`
  query PODetails($input: PODetailInput) {
    PODetails(input: $input) {
      success
      data {
        _id
        poNumber
        buyerId
        supplierId
        forwarderId
        buyerUserId
        supplierUserId
        forwarderUserId
        LastUpdatedAt
        incoTerm
        buyerCollaborators {
          userId
          invitedBy
          invitedOn
          status
          _id
        }
        supplierCollaborators {
          userId
          invitedBy
          invitedOn
          status
          _id
        }
        forwarderCollaborators {
          userId
          invitedBy
          invitedOn
          status
          _id
        }
        organizationId
        createdBy
        poDueDate
        orderValue
        orderCurrency
        statusHistory {
          statusName
          statusDate
          createdBy
          subStatusName
          comments
          attachment
          _id
        }
        lineItems {
          itemName
          itemCode
          description
          price
          quantity
          grossWeight
          weightUnit
          volumeWeight
          dimensions {
            length
            height
            width
            _id
          }
          discountAmt
          discountPer
          total
          dueDate
          notes
          bookedQty
          cargoQty
          pendingQty
          _id
          statusName
          eventAction {
            Accept
            Reject
            ProposeChange
            AddBooking
            ManualUpdate
            CreateShipment
            upComingStage {
              stageName
              QtySplit
            }
          }
          lineItemStatus {
            statusName
            statusDate
            createdBy
            createdDate
            _id
          }
          ERPLineitemID
          subLineItems {
            itemErpID
            itemNumber
            itemCode
            itemName
            price
            orderPlaceQTY
            GRNQty
            QualityQty
            rejectedQty
            statusName
            statusHistory {
              statusName
              statusDate
              createdBy
              createdDate
              _id
            }
            itemId
            shippedQTY
            parentItemId
            quantityObj {
              status
              value
              className
            }
          }
          newlineItemWiseProposeChange {
            createdBy
            createdAt
            statusName
            changeLog {
              reason
              reasonLog {
                oldData
                newData
                parameter
                currentStatus
              }
            }
            userData {
              updatedBy {
                email
                profilePicture
                firstName
                lastName
              }
              createdBy {
                email
                profilePicture
                firstName
                lastName
              }
            }
          }
        }
        bookings {
          _id
          poNumber
          bookingNo
          currentStatus
          buyerId
          supplierId
          forwarderId
          buyerUserId
          shipmentNo
          supplierUserId
          forwarderUserId
          incoTerm
          createdBy
          bookingDate
          orderID
          mode
          shipmentType
          containerType
          originFacility
          destinationFacility
          isSpacialEquipment
          typeofMove

          address {
            type
            portName
            street
            city
            state
            country
            zipcode
            id
          }
          entities {
            type
            id
            name
            _id
          }
          createdAt
          shipmentId
          updatedAt
          ShipperDetails {
            orgName
            orgAddress
            orgState
            orgZip
            orgCountry
            orgPhone
            orgEmail
            orgWebsite
            orgLogo
            orgCover
            orgDescription
            defaultCurrency
          }
          ConsigneeDetails {
            orgName
            orgAddress
            orgState
            orgZip
            orgCountry
            orgPhone
            orgEmail
            orgWebsite
            orgLogo
            orgCover
            orgDescription
            defaultCurrency
          }
        }
        proposeChangesHistory {
          id
          reason
          itemCode
          deliveryTime
          quantity
          grossPrice
          discount
          priceUnitQuantity
        }
        createdAt
        updatedAt
        originFacility
        destinationFacility
        cargoType
        newETA
        ETA
        plannedETA
        currentStatus
        shipmentList {
          _id
          shipmentNo
          BLno
          currentStatus
          mode
          bookingType
        }
        uniqueUserIds {
          email
          profilePicture
          firstName
          lastName
          userSub
          userType
          organizationId
        }
        attachment {
          docId
          originalFileName
          fileName
          filePath
          url
          _id
          createdAt
          uploadedBy
          deleted
          type
        }
        eventAction {
          _id
          type
          currentStatus
          profileType
          Accept
          Reject
          ProposeChange
          AddBooking
          ManualUpdate
        }
        bookingList {
          entities
          containerType
          bookingOwner
          address
          destinationGeoCode
          originGeoCode
          typeofMove
          incoTerm
          _id
          buyerId
          attachmentComment
          bookingNo
          currentStatus
          supplierId
          referenceId
          originFacility
          destinationFacility
          bookingDate
          poNumber
          mode
          isPrimary
          packages
          linerQuotes
          linerQuotesCount
          bookingAction
          bookingType
          BuyerDetails {
            orgName
            orgAddress
            orgState
            orgZip
            orgCountry
            orgPhone
            orgEmail
            orgWebsite
            orgLogo
            orgCover
            orgDescription
            defaultCurrency
          }
          SupplierDetails {
            orgName
            orgAddress
            orgState
            orgZip
            orgCountry
            orgPhone
            orgEmail
            orgWebsite
            orgLogo
            orgCover
            orgDescription
            defaultCurrency
          }
        }
      }
      message
      buyerDetails {
        message
        data {
          orgName
          orgId
          orgStatus
          orgEntities
          orgCreatedBy
          orgCreatedAt
          orgType
          orgDomains
          orgAddress
          orgCity
          orgState
          orgZip
          orgCountry
          orgPhone
          orgFax
          orgEmail
          orgWebsite
          admins
          users
          keyAdmin
          buyers
          suppliers
          forwarders
          orgLogo
          orgCover
          orgDescription
          defaultCurrency
        }
      }
      supplierDetails {
        message
        data {
          orgName
          orgId
          orgStatus
          orgEntities
          orgCreatedBy
          orgCreatedAt
          orgType
          orgDomains
          orgAddress
          orgCity
          orgState
          orgZip
          orgCountry
          orgPhone
          orgFax
          orgEmail
          orgWebsite
          admins
          users
          keyAdmin
          buyers
          suppliers
          forwarders
          orgLogo
          orgCover
          orgDescription
          defaultCurrency
        }
      }
      Progress {
        status
        title
        date
        description
        lineItems
      }
      frontProgress {
        status
        title
        date
        description
      }
    }
  }
`;

export const SAVE_ACCEPT_REJECT_ORDER = gql`
  mutation Mutation($input: UpdatePOLineInput) {
    UpdatePOLineStatus(input: $input) {
      message
    }
  }
`;

export const SAVE_PROPOSECHANGES = gql`
  mutation UpdateBulkPOProposal($input: updateBulkPOProposalInput) {
    updateBulkPOProposal(input: $input) {
      message
    }
  }
`;

export const PO_MILESTONE_QUERY = gql`
  query POMilestone($moduleType: String) {
    POMilestone(moduleType: $moduleType) {
      success
      data {
        displayManualUpdate
        displaydefaultView
      }
      message
    }
  }
`;

export const QUERY_ORDER_DETAILS_STATUS = gql`
  query PODetails($input: PODetailInput) {
    PODetails(input: $input) {
      data {
        _id
        poNumber
        statusHistory {
          statusName
          statusDate
          createdBy
          subStatusName
          comments
          attachment
          _id
        }
      }
      success
    }
  }
`;

export const QUERY_PROPOSECHANGEMASTER = gql`
  query ProposeChange {
    proposeChange {
      message
      data {
        _id
        reason
        option {
          name
          value
          type
        }
      }
    }
  }
`;

export const BULKACCEPTREJECT = gql`
  mutation BulkAcceptReject($input: bulkAcceptRejectInput) {
    bulkAcceptReject(input: $input) {
      message
    }
  }
`;

export const UPDATE_ORDERITEMS = gql`
  mutation UpdateOrderItem($input: updateOrderInput) {
    updateOrderItem(input: $input) {
      message
    }
  }
`;

export default QUERY_ORDER_DETAILS;
