import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { gql, useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import './contact-item.scss';
import {
  // ActiveFillIcon,
  // ChatIcon,
  EditFillIcon,
  SMSIconFill,
  // InactiveFillIcon,
  // MoreIcon,
  StatusActive,
  StatusInactive,
} from '../icons/icons';
// import UserShartName from '../user-short-name/user-short-name';
import UserImage from '../user-image/user-image';
import CustomTag from '../custom-tag/custom-tag';
import Confirmation from '../confirmation/confirmation';
import Dialog from '../dialog/dialog';
import useDialog from '../dialog/useDialog';
import Tooltip from '../tooltip/tooltip';
import CustomSwitch from '../custom-switch/custom-switch';
import { checkPermission } from '../../helpers/permissionHelper';
import { giveProfile } from '../global-notification/notification-list';
import AdvancePopover from '../advance-popover/advance-popover';

const QUERY_ENABLE_USER = gql`
  mutation enableUserOrg($input: DisableInput) {
    enableUser(input: $input) {
      message
    }
  }
`;

const QUERY_DISABLE_USER = gql`
  mutation disableUserOrg($input: DisableInput) {
    disableUser(input: $input) {
      message
    }
  }
`;

export const checkProfile = (userProfile) => {
  let userPro;
  switch (userProfile) {
    case 'keyAdmin':
      userPro = 'keyAdmin';
      break;
    case 'locationAdmin':
      userPro = 'admin';
      break;
    default:
      userPro = userProfile;
      break;
  }
  return userPro;
};

export default function ContactItem({
  contactUserImage,
  contactName,
  // type,
  email,
  // mobile,
  // deleteItem,
  editItem,
  userType,
  // userStatus,
  // status,
  userSub,
  userPoolId,
  active,
  emailVerification,
  // userVerification,
  refetchData,
  contactLocation,
  action,
  disabledOptions,
  // onlineStatus,
  t,
}) {
  const [eventValue, setEventValue] = useState();
  const [shortName, setShortName] = useState();
  const { relation } = useParams();
  const toggleLocationPopover = (e) => {
    setEventValue(e.target);
  };
  const [t2] = useTranslation('toast');
  const [handleActiveUser] = useMutation(QUERY_ENABLE_USER);
  const [handleDisableUser] = useMutation(QUERY_DISABLE_USER);
  const { isShowing: isActivateDislog, toggle: activateContact } = useDialog();
  const { isShowing: isDeactivateDislog, toggle: deactivateContact } =
    useDialog();
  const serverActivateUser = () => {
    handleActiveUser({
      variables: {
        input: {
          userSub,
          userPoolId,
        },
      },
      onCompleted: () => {
        toast.success(t2('contactitem.success.activated'));
        activateContact();
        refetchData();
      },
    });
  };

  const ServerDisableUser = () => {
    handleDisableUser({
      variables: {
        input: {
          userSub,
          userPoolId,
        },
      },
      onCompleted: () => {
        toast.success(t2('contactitem.success.deactivated'));
        deactivateContact();
        refetchData();
      },
    });
  };

  const handleClose = () => {
    setEventValue(null);
  };

  useEffect(() => {
    const fullName = contactName.split(' ');
    const initials = fullName.shift().charAt(0) + fullName.pop().charAt(0);
    setShortName(initials.toUpperCase());

    // setNameLetter(initials[0]);
  }, []);
  const toggleStatus = (e) => {
    if (e.target.checked === true) {
      activateContact();
    } else {
      deactivateContact();
    }
  };
  return (
    <div
      data-cy="contact-box"
      className={`contact-box ${
        checkProfile(userType) === 'keyAdmin' ? 'key-admin' : ''
      }${active && emailVerification ? '' : 'pending'}`}
    >
      <div className="top">
        <div className="top-left" data-cy="contact-role">
          <CustomTag text={checkProfile(userType)} />
        </div>
        <div className="contact-actions" data-cy="contact-actions">
          <span className="c-status" data-cy="email-verified">
            {active && emailVerification ? (
              <Tooltip
                content={t('companydetailscreen.detailstab.verified')}
                direction="left"
                block
                className="mb-10"
              >
                <StatusActive color="#2179C4" />
              </Tooltip>
            ) : (
              <Tooltip
                content={t('companydetailscreen.detailstab.pending')}
                direction="left"
                block
                className="mb-10"
              >
                <StatusInactive />
              </Tooltip>
            )}
          </span>
          {action.edit &&
            checkPermission(
              giveProfile(relation),
              'My Company',
              'Manage contacts'
            ) && (
              <span className="c-edit">
                <Tooltip
                  content={t('companydetailscreen.detailstab.editcontactbtn')}
                  direction="left"
                  block
                >
                  <button
                    type="button"
                    onClick={editItem}
                    className="edit"
                    data-cy="contact-edit-location"
                  >
                    <EditFillIcon color="#1D3E69" size="8" />
                  </button>
                </Tooltip>
              </span>
            )}
          <div className="c-email">
            <Tooltip content={email} direction="left" block className="mb-10">
              <button type="button" className="no-link">
                <SMSIconFill
                  fillOpacity={active && emailVerification ? 1 : 0.5}
                  color={active && emailVerification ? '#1D3E69' : '#0E0E0E'}
                />
              </button>
            </Tooltip>
          </div>

          {/* <button
              type="button"
              className="more"
              onClick={togglePopover}
              id="morePopover"
            >
              <MoreIcon />
            </button> */}
          {/* )} */}
        </div>
      </div>
      <div className="bottom">
        <UserImage
          path={contactUserImage}
          name={contactName}
          email={email}
          userRole={checkProfile(userType)}
          removeTooltip
          size="large"
          shortName={shortName}
        />
        <Tooltip content={contactName}>
          <p className="name" title={contactName} data-cy="contact-name">
            {contactName.length > 25
              ? `${contactName.slice(0, 20)}...`
              : contactName}
          </p>
        </Tooltip>

        {!disabledOptions && (
          <div className="location-list mb-18">
            {contactLocation?.parentEntity?.length > 0 ? (
              contactLocation?.parentEntity?.map((loc) => (
                <p data-cy="contact-location">{loc.entityName}</p>
              ))
            ) : (
              <p>&nbsp; </p>
            )}
            {contactLocation?.childEntity?.length > 0 && (
              <button
                type="button"
                onClick={toggleLocationPopover}
                id="moreLocationPopover"
                className="count"
                data-cy="contact-location-list"
              >
                +{contactLocation?.childEntity?.length}
              </button>
            )}
            <AdvancePopover
              reference="moreLocationPopover"
              getEvent={eventValue}
              closePopover={handleClose}
              placement="left"
              closeOnclick
            >
              <ul className="action-list">
                {contactLocation?.childEntity?.map((loc) => (
                  <li>
                    <button type="button">{loc.entityName}</button>
                  </li>
                ))}
              </ul>
            </AdvancePopover>
          </div>
        )}
      </div>
      <div className="footer" data-cy="contact-deactivation-switch">
        {emailVerification &&
          action?.activeDeactive &&
          checkPermission(
            giveProfile(relation),
            'My Company',
            'Deactivate contacts'
          ) && (
            <CustomSwitch
              size="small"
              onChange={toggleStatus}
              checked={active}
              color="green"
            />
          )}
      </div>
      <Dialog
        isShowing={isActivateDislog}
        hide={activateContact}
        placement="center"
      >
        <Confirmation
          cancelButtonText={t(
            'companydetailscreen.detailstab.activatecontactspopup.cancel'
          )}
          submitButtonText={t(
            'companydetailscreen.detailstab.activatecontactspopup.confirm'
          )}
          title={t(
            'companydetailscreen.detailstab.activatecontactspopup.activate'
          )}
          description={t(
            'companydetailscreen.detailstab.activatecontactspopup.activatedescription'
          )}
          onSubmitClick={serverActivateUser}
          onCancelClick={activateContact}
        />
      </Dialog>
      <Dialog
        isShowing={isDeactivateDislog}
        hide={deactivateContact}
        placement="center"
      >
        <Confirmation
          cancelButtonText={t(
            'companydetailscreen.detailstab.activatecontactspopup.cancel'
          )}
          submitButtonText={t(
            'companydetailscreen.detailstab.activatecontactspopup.confirm'
          )}
          title={t(
            'companydetailscreen.detailstab.activatecontactspopup.deactivate'
          )}
          description={t(
            'companydetailscreen.detailstab.activatecontactspopup.deactivatedescription'
          )}
          onCancelClick={deactivateContact}
          onSubmitClick={ServerDisableUser}
        />
      </Dialog>
    </div>
  );
}

ContactItem.propTypes = {
  contactUserImage: PropTypes.string,
  contactName: PropTypes.string,
  // type: PropTypes.string,
  email: PropTypes.string,
  // mobile: PropTypes.string,
  //  deleteItem: PropTypes.func.isRequired,
  editItem: PropTypes.func.isRequired,
  // userStatus: PropTypes.string,
  // status: PropTypes.string,
  userSub: PropTypes.string,
  userPoolId: PropTypes.string,
  userType: PropTypes.string,
  active: PropTypes.string,
  emailVerification: PropTypes.bool,
  // userVerification: PropTypes.string,
  refetchData: PropTypes.func,
  contactLocation: PropTypes.instanceOf(Object),
  action: PropTypes.instanceOf(Object),
  // onlineStatus: PropTypes.string,
  disabledOptions: PropTypes.bool,
  t: PropTypes.func,
};
ContactItem.defaultProps = {
  contactUserImage: '',
  contactName: '',
  // type: '',
  email: '',
  // mobile: '',
  // userStatus: '',
  //  status: '',
  userSub: '',
  userPoolId: '',
  userType: '',
  active: '',
  emailVerification: false,
  // userVerification: '',
  refetchData: () => {},
  contactLocation: {},
  action: {},
  // onlineStatus: '',
  disabledOptions: false,
  t: () => {},
};
