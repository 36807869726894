import React from 'react';
import PropTypes from 'prop-types';
import './radio-button.scss';

export function RadioButtonGroup({
  required,
  label,
  errorMessage,
  bMargine,
  ...props
}) {
  return (
    <div className="radio-button-group">
      <span className="label">
        {label}
        {required && <span className="required">*</span>}
      </span>
      <div className="radio-buttons">{props.children}</div>
      {errorMessage !== '' && (
        <p className="error-message" style={{ marginBottom: bMargine }}>
          {errorMessage}
        </p>
      )}
    </div>
  );
}
RadioButtonGroup.propTypes = {
  children: PropTypes.node.isRequired,
  label: PropTypes.string,
  errorMessage: PropTypes.string,
  required: PropTypes.bool,
  bMargine: PropTypes.string,
};
RadioButtonGroup.defaultProps = {
  label: '',
  errorMessage: '',
  required: false,
  bMargine: '',
};

export function RadioButton({
  label,
  value,
  children,
  className,
  ...otherProps
}) {
  return (
    <label className={`radio-button ${className}`}>
      <input type="radio" value={value} {...otherProps} />
      <span className="radio-round" />
      <span className="radio-value">
        {label}
        {children}
      </span>
    </label>
  );
}

RadioButton.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};
RadioButton.defaultProps = {
  value: '',
  label: '',
  className: '',
};
