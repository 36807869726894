import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import './assign-contact.scss';
import DialogPanelBody from '../../../Components/dialog-panel/dialog-panel-body/dialog-panel-body';
import DialogPanel from '../../../Components/dialog-panel/dialog-panel';
import DialogPanelFooter from '../../../Components/dialog-panel/dialog-panel-footer/dialog-panel-footer';
import Button from '../../../Components/button/button';
import { MAP_ROLE_USER } from '../../../graphQL/rolesAndPermissions';
import AssignUsers from '../assign-users';
import { giveProfile } from '../../../Components/global-notification/notification-list';

function AssignContact({
  roleName,
  assignUserToggle,
  roleId,
  refetchRolesList,
  t,
}) {
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [handleMapRoleUser] = useMutation(MAP_ROLE_USER);
  const { relation } = useParams();

  const mapRoleUserServerCall = async () => {
    const input = {
      action: 'add',
      users: selectedContacts?.map((val) => val?.userSub),
      roleId,
      profileType: giveProfile(relation),
    };
    await handleMapRoleUser({
      variables: {
        input,
      },
      onCompleted: (data) => {
        toast.success(data?.MapRoleUser?.message);
        refetchRolesList();
        assignUserToggle();
      },
    });
  };

  return (
    <DialogPanel
      title={t(
        'systemsettings.roles&permission.actionspopups.assignuserspopup.assigncontact'
      )}
    >
      <DialogPanelBody>
        <div className="assign-contact-container">
          <h3 className="m-0 mb-13 sub-title-color fs-16 fw-500">{roleName}</h3>
          <AssignUsers
            roleId={roleId}
            setSelectedContacts={setSelectedContacts}
            selectedContacts={selectedContacts}
            sliceVal={1}
            t={t}
          />
        </div>
      </DialogPanelBody>
      <DialogPanelFooter>
        <Button
          label={t(
            'systemsettings.roles&permission.actionspopups.assignuserspopup.cancel'
          )}
          styles="cancel"
          onClick={assignUserToggle}
        />
        <Button
          label={t(
            'systemsettings.roles&permission.actionspopups.assignuserspopup.assign'
          )}
          color="primary"
          onClick={mapRoleUserServerCall}
        />
      </DialogPanelFooter>
    </DialogPanel>
  );
}

export default AssignContact;

AssignContact.propTypes = {
  roleName: PropTypes.string,
  assignUserToggle: PropTypes.func,
  roleId: PropTypes.string,
  refetchRolesList: PropTypes.func,
  t: PropTypes.func,
};

AssignContact.defaultProps = {
  roleName: '',
  assignUserToggle: () => {},
  roleId: '',
  refetchRolesList: () => {},
  t: () => {},
};
