import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './user-group.scss';
import UserImage from '../user-image/user-image';
// import Popover from '../custom-popover/custom-popover';
import UserCounter from '../user-counter/user-counter';
import AdvancePopover from '../advance-popover/advance-popover';
import { AddUserIcon } from '../icons/icons';
import ListOfUser from '../message/userList/userList';

export default function UserGroup({
  userData,
  infoDirection,
  sliceVal,
  startSliceVal,
  removeTooltip,
  size,
  onAddClick,
  eventID,
  enableAdd,
  newUserData,
  enableHover,
  onExtarnalClick,
  extarnalClick,
}) {
  const [eventValue, setEventValue] = useState();
  const togglePopover = (e) => {
    setEventValue(e.target);
  };
  const handleClose = () => {
    setEventValue(null);
  };
  const [addUserEventValue, setAddUserEventValue] = useState();
  const toggleAddUserPopover = (e) => {
    setAddUserEventValue(e.target);
  };
  const handleCloseAddUserPopover = () => {
    setAddUserEventValue(null);
  };
  return (
    <div className={`user-group ${enableHover ? 'enable-hover' : ''}`}>
      {userData?.length > 0 &&
        userData?.slice(startSliceVal, sliceVal)?.map((data) => {
          return (
            <UserImage
              path={data?.profilePicture}
              size={size}
              name={`${data?.firstName} ${data?.lastName}`}
              shortName={`${data?.firstName?.[0]}${data?.lastName?.[0]}`?.toUpperCase()}
              email={data?.email}
              verified={data?.emailVerification || true}
              infoDirection={infoDirection}
              removeTooltip={removeTooltip}
            />
          );
        })}
      {userData?.length > sliceVal &&
        (!extarnalClick ? (
          <UserCounter
            text={`+${userData.length - sliceVal}`}
            counter
            onClick={togglePopover}
            id="userPopover"
            size={size}
          />
        ) : (
          <UserCounter
            text={`+${userData.length - sliceVal}`}
            counter
            onClick={onExtarnalClick}
            size={size}
          />
        ))}
      {enableAdd && (
        <>
          <UserCounter
            text={
              <AddUserIcon color="#ffffff" width="0.972vw" height="0.972vw" />
            }
            counter
            onClick={toggleAddUserPopover}
            size={size}
            id={eventID}
          />
          <AdvancePopover
            reference="actionPopover"
            getEvent={addUserEventValue}
            closePopover={handleCloseAddUserPopover}
            placement="left"
          >
            <ListOfUser users={newUserData} addUserInChannel={onAddClick} />
          </AdvancePopover>
        </>
      )}
      <AdvancePopover
        reference="userPopover"
        getEvent={eventValue}
        closePopover={handleClose}
      >
        <ul className="action-list user-list">
          {userData?.slice(sliceVal)?.map((user) => {
            return (
              <li>
                <button type="button">
                  <UserImage
                    size="small"
                    path={user?.profilePicture}
                    name={`${user?.firstName} ${user?.lastName}`}
                    shortName={`${user?.firstName?.[0]}${user?.lastName?.[0]}`?.toUpperCase()}
                    email={user?.email}
                    infoDirection="right"
                    removeTooltip={removeTooltip}
                  />
                  {`${user?.firstName} ${user?.lastName}`}
                </button>
              </li>
            );
          })}
        </ul>
      </AdvancePopover>
    </div>
  );
}
UserGroup.propTypes = {
  userData: PropTypes.arrayOf(),
  newUserData: PropTypes.arrayOf(),
  enableHover: PropTypes.bool,
  infoDirection: PropTypes.string,
  sliceVal: PropTypes.number,
  startSliceVal: PropTypes.number,
  removeTooltip: PropTypes.bool,
  size: PropTypes.string,
  onAddClick: PropTypes.func,
  eventID: PropTypes.string,
  enableAdd: PropTypes.bool,
  onExtarnalClick: PropTypes.func,
  extarnalClick: PropTypes.bool,
};
UserGroup.defaultProps = {
  userData: [],
  newUserData: [],
  infoDirection: '',
  sliceVal: 2,
  startSliceVal: 0,
  removeTooltip: false,
  size: 'small',
  onAddClick: () => {},
  eventID: '',
  enableAdd: false,
  enableHover: false,
  onExtarnalClick: () => {},
  extarnalClick: false,
};
