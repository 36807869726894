// eslint-disable-next-line
import store from '../redux/store';
import { allOrgIdArray } from './detailsHelper';

const getNotificationsFromStore = () => {
  return store.getState().onmiNotifications;
};

export const getProfileWiseNotifications = (data) => {
  const org = allOrgIdArray(data);
  const orgIds = org.map((item) => Number(item.orgId));
  const allNotifications = getNotificationsFromStore();
  let profileWiseCount = 0;
  Object.keys(allNotifications).forEach((key) => {
    if (orgIds.includes(Number(key))) {
      const { order, booking, shipment } = allNotifications[key];
      const total =
        ((order && Object.keys(order).length) || 0) +
        ((booking && Object.keys(booking).length) || 0) +
        ((shipment && Object.keys(shipment).length) || 0);
      profileWiseCount += total;
    }
  });
  return profileWiseCount;
};

export default getNotificationsFromStore;
