import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// import { toast } from 'react-toastify';
import { useParams, useNavigate, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useLazyQuery, useMutation } from '@apollo/client';
import './global-left-menu.scss';
import { connect, useDispatch } from 'react-redux';
import {
  addLeftMenuDetails,
  addGroupMenuDetails,
  listsupplierbuyer,
  toggleState,
} from '../../redux/actions';
import {
  QUERY_SUPPLIER_BUYER_LIST,
  QUERY_BUYER_SUPPLIER_ORG_DETAILS,
} from '../../graphQL/OrganizationQuery';
import addUpdateUserActivity from '../../graphQL/addUserActivity';

import {
  BuyerIcon,
  BuyerIconActive,
  FAQIconNew,
  MoreHorNewIcon,
  PLusSquare,
  PoweredBy,
  SearchIcon,
  Settingsicon,
  SupplierIcon,
  SupplierIconActive,
  SupportIconNew,
} from '../icons/icons';
import Dialog from '../dialog/dialog';
import useDialog from '../dialog/useDialog';
import GlobalSearch from '../global-search/global-search';
import Skeleton from '../skeleton/skeleton';
import Tooltip from '../tooltip/tooltip';
import GlobalLoader from '../global-loader/global-loader';
import {
  getGroupNotificationCount,
  getTotalNotificationCountOfOrganization,
} from '../../helpers/notificationHelper';
import MoreItems from './more-items';
import getInitials from '../../helpers/utils';
import UserShartName from '../user-short-name/user-short-name';
import AdvancePopover from '../advance-popover/advance-popover';
import { checkPermission } from '../../helpers/permissionHelper';
import { giveProfile } from '../global-notification/notification-list';

function GlobalLeftMenu({
  toggleGroup,
  addGroup,
  organizationDetails,
  addLeftMenuDetail,
  hideGroup,
  closeAddnewGroup,
  disabled,
  removeGroup,
  listSupplierBuyer,
  notificationContainer,
  isBranding,
}) {
  const [t] = useTranslation('globalleftmenu');
  const { relation, companyId } = useParams();

  const navigate = useNavigate();
  const { isShowing: isSearchDislog, toggle: searchDialog } = useDialog();
  const dispatch = useDispatch();
  dispatch(toggleState(relation));
  const [activeList, setActiveList] = useState(0);
  const [currentURL, setCurrentURL] = useState();
  const [searchOrg, setSearchOrg] = useState();
  let orgType;
  if (organizationDetails.length !== 0) {
    orgType = [...organizationDetails.fetchOrganizationDetails.data.orgType];
  }
  const [toggleClick, setToggleClick] = useState({
    page: 1,
    relationship: 'supplier',
  });

  const [org, setOrg] = useState([]);
  const { isShowing: isActivateDislog, toggle: toggleDialog } = useDialog();

  const [handleList] = useLazyQuery(QUERY_SUPPLIER_BUYER_LIST);
  const [handleOrgDetails, { loading: orgLoad }] = useLazyQuery(
    QUERY_BUYER_SUPPLIER_ORG_DETAILS
  );
  const [handleAddUpdateActivity] = useMutation(addUpdateUserActivity);
  const [userActivity, setUserActivity] = useState([]);

  function customSort(a, b) {
    return (
      new Date(b.ActivityTime).getTime() - new Date(a.ActivityTime).getTime()
    );
  }

  if (orgLoad) {
    <GlobalLoader newLoad={true} />;
  }

  useEffect(() => {
    setToggleClick({
      ...toggleClick,
      relationship: relation,
    });
  }, [relation]);

  const serverCallForToggle = (newToggle) => {
    handleList({
      variables: {
        input: newToggle,
      },
      onCompleted: (data) => {
        // setOrg(data.listBuyerSupplier);
        dispatch(listsupplierbuyer(data.listBuyerSupplier));
        const orgData = data.listBuyerSupplier;
        setSearchOrg(orgData);
        const sortedList = [];
        orgData.OrganizationList.map((item) => {
          return sortedList.push({
            ...item,
            ActivityTime:
              userActivity.filter((uItem) => {
                return uItem.typeId === item.organization.orgId;
              })[0]?.ActivityTime || '2023-01-01T18:41:44.937Z',
          });
        });
        orgData.buyerSupplierGroupList.map((item) => {
          return sortedList.push({
            ...item,
            ActivityTime: '',
          });
        });

        sortedList.sort(customSort);

        setOrg(sortedList);
      },
      notifyOnNetworkStatusChange: true,
      errorPolicy: 'all',
      // onError: (err) => {
      //   toast.error(err.message);
      // },
    });
  };

  const addUserActivity = (orgId, type) => {
    handleAddUpdateActivity({
      variables: {
        input: {
          type,
          relation: relation === 'buyer' ? 'supplier' : 'buyer',
          typeId: orgId,
        },
      },
      onCompleted: (data) => {
        setUserActivity(data.UserActivity.data);
      },
    });
  };

  const handleDataSaveToRedux = (orgList) => {
    const buyerSupplierId = orgList?.organization?.orgId;
    handleOrgDetails({
      variables: {
        orgId: orgList?.organization?.orgId?.toString(),
      },
      onCompleted: (data) => {
        const leftMenudata = {
          ...data.fetchSupplierBuyerOrgDetails[0],
          orgType: toggleClick.relationship,
          buyerSupplierId,
        };

        dispatch(addLeftMenuDetail(leftMenudata));
      },
      // onError: (err) => {
      //   toast.error(err.message);
      // },
    });

    hideGroup();
  };

  useEffect(() => {
    setCurrentURL(window.location.pathname.split('/')[3]);
    serverCallForToggle(toggleClick);
    if (companyId) {
      setActiveList(companyId);
      const orgActiveList = listSupplierBuyer?.OrganizationList?.filter(
        (orgList) => {
          return orgList.organization._id === companyId;
        }
      );

      if (orgActiveList && orgActiveList[0] === undefined) {
        listSupplierBuyer?.buyerSupplierGroupList.filter((orgList) => {
          return orgList.list.filter((orgListItem) => {
            if (orgListItem._id === companyId) {
              return orgActiveList.push({ organization: orgListItem });
            }
            return '';
          });
        });
      }
      if (orgActiveList) handleDataSaveToRedux(orgActiveList[0]);
    } else {
      setActiveList();
      dispatch(addLeftMenuDetail());
    }
    return () => {};
  }, [toggleClick, companyId, listSupplierBuyer]);

  const handleClick = (e) => {
    const logg = e.currentTarget.value;
    dispatch(toggleState(logg));
    setToggleClick((oldState) => ({
      ...oldState,
      relationship: logg,
    }));
    serverCallForToggle({
      ...toggleClick,
      relationship: logg,
    });
    dispatch(addLeftMenuDetail());
    setActiveList();
    hideGroup();
    navigate(`/app/${logg}/dashboard`);
  };

  const handleGroupToRedux = (e) => {
    const id = e._id;
    setActiveList(null);
    org?.filter((groupList) => {
      if (groupList._id === id) {
        dispatch(addGroupMenuDetails(groupList));
      }
      return true;
    });
    toggleGroup();
    closeAddnewGroup();
  };

  const handleInitialOrgName = (str) => {
    let name = str
      .split(' ')
      .map((word) => word[0])
      .join('');
    name = name.substr(0, 2);
    return name.toUpperCase();
  };

  const handleCreateGroup = () => {
    addGroup();
    dispatch(addGroupMenuDetails());
    removeGroup();
  };

  const activeOrganization = localStorage.getItem('activeOrg');
  const [eventValue, setEventValue] = useState();
  const togglePopover = (e) => {
    setEventValue(e.target);
  };

  const handleClose = () => {
    setEventValue(null);
  };

  const permissionsToCheck = [
    ['Configuration', 'Manage Roles', 'V'],
    ['Configuration', 'Manage Roles'],
    ['Configuration', 'configure Webhook', 'V'],
    ['Configuration', 'configure Webhook'],
    ['Configuration', 'API Documentation'],
    ['Configuration', 'API Documentation', 'V'],
    ['Configuration', 'Deactivate Roles'],
    ['Configuration', 'Transfer account'],
  ];

  const settingPermission = permissionsToCheck.some((permission) =>
    checkPermission(giveProfile(relation), ...permission)
  );
  return (
    <div className={`global-left-menu ${disabled ? 'disabled' : ''}`}>
      <ul className="gl-left-menu toggle">
        {!orgType && (
          <>
            <li>
              <Skeleton
                skWidth="2.5vw"
                skHeight="2.5vw"
                margin=" 0px"
                skRadius="50%"
              />
            </li>
            <li>
              <Skeleton
                skWidth="2.5vw"
                skHeight="2.5vw"
                margin=" 0px"
                skRadius="50%"
              />
            </li>
          </>
        )}

        {orgType?.includes('supplier') && (
          <li>
            <Tooltip content="Buyer" direction="right">
              <button
                type="button"
                value="supplier"
                className={
                  toggleClick.relationship === 'supplier' ? 'active' : null
                }
                onClick={handleClick}
              >
                {orgType.length !== 1 ? (
                  <span className="type">
                    {toggleClick.relationship === 'supplier' ? (
                      <BuyerIconActive />
                    ) : (
                      <BuyerIcon />
                    )}
                  </span>
                ) : (
                  (orgType.length === 1 &&
                    organizationDetails?.fetchOrganizationDetails?.data
                      ?.orgLogo !== '' && (
                      <img
                        src={
                          organizationDetails?.fetchOrganizationDetails?.data
                            ?.orgLogo
                        }
                        alt="buyer"
                      />
                    )) || (
                    <UserShartName
                      removeTooltip
                      text={getInitials(
                        organizationDetails?.fetchOrganizationDetails?.data
                          ?.orgName
                      )}
                    />
                  )
                )}
              </button>
            </Tooltip>
          </li>
        )}
        {orgType?.includes('buyer') && (
          <li>
            <Tooltip content="Supplier" direction="right">
              <button
                type="button"
                value="buyer"
                className={
                  toggleClick.relationship === 'buyer' ? 'active' : null
                }
                onClick={handleClick}
              >
                {orgType.length !== 1 ? (
                  <span className="type">
                    {toggleClick.relationship === 'buyer' ? (
                      <SupplierIconActive />
                    ) : (
                      <SupplierIcon />
                    )}
                  </span>
                ) : (
                  (orgType.length === 1 &&
                    organizationDetails?.fetchOrganizationDetails?.data
                      ?.orgLogo !== '' && (
                      <img
                        src={
                          organizationDetails?.fetchOrganizationDetails?.data
                            ?.orgLogo
                        }
                        alt="supplier"
                      />
                    )) || (
                    <UserShartName
                      removeTooltip
                      text={getInitials(
                        organizationDetails?.fetchOrganizationDetails?.data
                          ?.orgName
                      )}
                    />
                  )
                )}
              </button>
            </Tooltip>
          </li>
        )}
        {orgType?.includes('forwarder') && (
          <li>
            <Tooltip
              content={
                organizationDetails?.fetchOrganizationDetails?.data?.orgName
              }
              direction="right"
            >
              <button
                type="button"
                value="forwarder"
                className={
                  toggleClick.relationship === 'forwarder' ? 'active' : null
                }
                onClick={handleClick}
              >
                {organizationDetails?.fetchOrganizationDetails?.data
                  ?.orgLogo !== '' ? (
                  <img
                    src={
                      organizationDetails?.fetchOrganizationDetails?.data
                        ?.orgLogo
                    }
                    alt="forwarder"
                  />
                ) : (
                  <UserShartName
                    removeTooltip
                    text={getInitials(
                      organizationDetails?.fetchOrganizationDetails?.data
                        ?.orgName
                    )}
                  />
                )}
              </button>
            </Tooltip>
          </li>
        )}
        {orgType?.includes('customBroker') && (
          <li>
            <Tooltip
              content={
                organizationDetails?.fetchOrganizationDetails?.data?.orgName
              }
              direction="right"
            >
              <button
                type="button"
                value="customBroker"
                className={
                  toggleClick.relationship === 'customBroker' ? 'active' : null
                }
                onClick={handleClick}
              >
                {organizationDetails?.fetchOrganizationDetails?.data
                  ?.orgLogo !== '' ? (
                  <img
                    src={
                      organizationDetails?.fetchOrganizationDetails?.data
                        ?.orgLogo
                    }
                    alt="forwarder"
                  />
                ) : (
                  <UserShartName
                    removeTooltip
                    text={getInitials(
                      organizationDetails?.fetchOrganizationDetails?.data
                        ?.orgName
                    )}
                  />
                )}
              </button>
            </Tooltip>
          </li>
        )}
      </ul>

      {orgLoad && org.length === 0 && (
        <>
          <Skeleton skWidth="1.944vw" skHeight="1.944vw" skMargin="1.111vw" />
          <Skeleton skWidth="1.944vw" skHeight="1.944vw" skMargin="1.111vw" />
          <Skeleton skWidth="1.944vw" skHeight="1.944vw" skMargin="1.111vw" />
          <Skeleton skWidth="1.944vw" skHeight="1.944vw" skMargin="1.111vw" />
        </>
      )}
      <div
        className={`scroll-view ${isBranding ? 'branding' : ''} ${
          !(orgType?.includes('supplier') && orgType?.includes('buyer'))
            ? 'single-type'
            : ''
        }`}
      >
        <ul className="gl-left-menu customer">
          {org.map((orgList) => {
            const orgNotifications =
              notificationContainer[orgList?.organization?.orgId];
            const countOfOrg =
              getTotalNotificationCountOfOrganization(orgNotifications) || 0;

            const notificationCount = getGroupNotificationCount(orgList?.list);
            const groupActive = orgList?.list?.map((item) => item.orgId);
            return (
              <li>
                <Tooltip
                  content={orgList?.organization?.orgName || orgList?.groupName}
                  direction="right"
                >
                  {orgList?.organization?.orgId ? (
                    <button
                      type="button"
                      value={orgList?.organization?.orgId || orgList?._id}
                      onClick={() => {
                        addUserActivity(
                          orgList?.organization?.orgId,
                          'Organization'
                        );

                        localStorage.setItem(
                          'activeOrg',
                          orgList?.organization?.orgId
                        );
                        navigate(
                          `/app/${relation}/${orgList?.organization?._id}/company-dashboard`
                        );
                      }}
                      className={
                        String(orgList?.organization?._id) === activeList ||
                        orgList?.organization?._id === currentURL
                          ? 'active'
                          : ''
                      }
                    >
                      <span className="name">
                        {orgList?.organization?.orgLogo ? (
                          <img src={orgList?.organization?.orgLogo} alt="" />
                        ) : (
                          <>
                            {handleInitialOrgName(
                              orgList?.organization?.orgName
                            )}
                          </>
                        )}
                        {orgNotifications &&
                          countOfOrg !== 0 &&
                          countOfOrg !== '0' && (
                            <span className="group-notification-count">
                              {countOfOrg > '9' ? '9+' : countOfOrg}
                            </span>
                          )}
                      </span>
                    </button>
                  ) : (
                    <button
                      type="button"
                      onClick={() => handleGroupToRedux(orgList)}
                      // value={orgList?._id}
                      id={orgList?._id}
                      className={
                        groupActive.includes(activeOrganization) ? 'active' : ''
                      }
                    >
                      <span className="group">
                        {orgList?.list.map((groupName) => {
                          return (
                            <span>
                              {groupName?.orgName &&
                                groupName?.orgName[0].toUpperCase()}
                            </span>
                          );
                        })}
                        {notificationCount !== 0 && (
                          <span className="group-notification-count">
                            {notificationCount > 9 ? '9+' : notificationCount}
                          </span>
                        )}
                      </span>
                    </button>
                  )}
                </Tooltip>
              </li>
            );
          })}
        </ul>
      </div>

      <ul className="gl-left-menu bottom">
        <li>
          <button
            type="button"
            className="search"
            onClick={togglePopover}
            id="actionPopover"
          >
            <MoreHorNewIcon color="#C9C9C9" />
          </button>
        </li>
        {(searchOrg?.OrganizationList?.length !== 0 ||
          searchOrg.buyerSupplierGroupList.length !== 0) && (
          <li>
            <button type="button" className="search" onClick={toggleDialog}>
              <SearchIcon color="#C9C9C9" height="1.389vw" width="1.389vw" />
            </button>
            <Dialog
              isShowing={isActivateDislog}
              hide={toggleDialog}
              placement="center"
            >
              <MoreItems
                orgData={searchOrg?.OrganizationList}
                groupData={searchOrg?.buyerSupplierGroupList}
                hide={toggleDialog}
              />
            </Dialog>
          </li>
        )}
        {searchOrg?.OrganizationList?.length !== 0 ? (
          <li>
            <button
              type="button"
              className="search"
              onClick={handleCreateGroup}
            >
              <PLusSquare clolor="#C9C9C9" />
            </button>
          </li>
        ) : null}
      </ul>
      <AdvancePopover
        placement="top-right"
        reference="actionPopover"
        getEvent={eventValue}
        closePopover={handleClose}
        maskColor="rgba(0, 0, 0, 0.3)"
        closeOnclick
      >
        <div className="menu-popover">
          {settingPermission && (
            <Link to="settings" className="item">
              <span className="icon">
                <Settingsicon color="#1D3E69" />
              </span>
              <span className="content-area">
                <span className="title">
                  {t('threedotmenu.settings.title')}
                </span>
                <span className="sub-title">
                  {t('threedotmenu.settings.description')}
                </span>
              </span>
            </Link>
          )}
          <a href="/" className="item">
            <span className="icon">
              <FAQIconNew />
            </span>
            <span className="content-area">
              <span className="title">{t('threedotmenu.FAQs.title')}</span>
              <span className="sub-title">
                {t('threedotmenu.FAQs.description')}
              </span>
            </span>
          </a>
          <a href="/" className="item">
            <span className="icon">
              <SupportIconNew />
            </span>
            <span className="content-area">
              <span className="title">{t('threedotmenu.help.title')}</span>
              <span className="sub-title">
                {t('threedotmenu.help.description')}
              </span>
            </span>
          </a>
        </div>
      </AdvancePopover>
      {isBranding && (
        <div className="powered-by">
          <PoweredBy />
        </div>
      )}
      <Dialog
        isShowing={isSearchDislog}
        hide={searchDialog}
        placement="center"
        enableOutsideClick
      >
        <GlobalSearch />
      </Dialog>
    </div>
  );
}
function mapStateToProps(state) {
  // if (state.omniDetails.fetchOrganizationDetail.length !== 0) {
  //   return { organizationDetails: state.omniDetails.fetchOrganizationDetail };
  // }
  return {
    organizationDetails: state.omniDetails.fetchOrganizationDetail,
    listSupplierBuyer: state.omniDetails.listsupplierbuyer,
    notificationContainer: state.onmiNotifications,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    addLeftMenuDetail: (data) => dispatch(addLeftMenuDetails(data)),
    listsupplierbuyer: (data) => dispatch(listsupplierbuyer(data)),
    addGroupMenuDetails: (data) => dispatch(addGroupMenuDetails(data)),
    toggleState: (data) => dispatch(toggleState(data)),
  };
}

GlobalLeftMenu.propTypes = {
  toggleGroup: PropTypes.func.isRequired,
  addGroup: PropTypes.func.isRequired,
  organizationDetails: PropTypes.instanceOf(Array),
  addLeftMenuDetail: PropTypes.func,
  hideGroup: PropTypes.func,
  closeAddnewGroup: PropTypes.func,
  disabled: PropTypes.bool,
  isBranding: PropTypes.bool,
  removeGroup: PropTypes.func,
  listSupplierBuyer: PropTypes.instanceOf(Object),
  notificationContainer: PropTypes.instanceOf(Object),
};

GlobalLeftMenu.defaultProps = {
  organizationDetails: [],
  addLeftMenuDetail: () => {},
  hideGroup: () => {},
  closeAddnewGroup: () => {},
  removeGroup: () => {},
  listSupplierBuyer: {},
  disabled: false,
  isBranding: false,
  notificationContainer: {},
};
export default connect(mapStateToProps, mapDispatchToProps)(GlobalLeftMenu);
