import React from 'react';
import './quantity-card.scss';
import PropTypes from 'prop-types';
import Tooltip from '../tooltip/tooltip';

function QuantityCard({ quantityObj }) {
  return (
    <div className="quantity-card">
      {quantityObj &&
        quantityObj.map((item) => {
          return (
            <Tooltip content={item.status} direction="top">
              <span className={item.className ? item.className : 'status'}>
                {item.value}
              </span>
            </Tooltip>
          );
        })}
    </div>
  );
}

export default QuantityCard;
QuantityCard.propTypes = {
  quantityObj: PropTypes.instanceOf(Array),
};
QuantityCard.defaultProps = {
  quantityObj: [],
};
