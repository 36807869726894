import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import './widget-details.scss';
import { toast } from 'react-toastify';
import Dialog from '../../../../Components/dialog/dialog';
import DashboardCard from '../../dashboard-card/dashboard-card';

import LargeWidgets from '../lardge-widgets/lardge-widgets';
import DashboardCardLoader from '../../dashboard-card/loader';
import LargeWidgetsLoader from '../lardge-widgets/loader';
import NoData from '../../../../Components/no-data/no-data';
import {
  CardEditIcon,
  DashboardBookingIcon,
  DashboardOrderIcon,
  DashboardQuotesIcon,
  DashboardShipmentIcon,
  DefaultNoDataIcon,
  // DownloadIcon,
  PlusIcon,
} from '../../../../Components/icons/icons';
import CardPanel from '../../../../Components/card-panel/card-panel';
import CardPanelBody from '../../../../Components/card-panel/card-panel-body';
import UserDetails from '../../../../Components/user-details/user-details';
import BarPanel from '../../../../Components/bar-panel/bar-panel';
import StackedBar from '../../../../Components/chart/stacked-bar';
import IconButton from '../../../../Components/icon-button/icon-button';
import Skeleton from '../../../../Components/skeleton/skeleton';

import { gqlMicroServiceTokenClient } from '../../../../service/gqlClient';
import { QUERY_ADDSUBSCRIPTION } from '../../../../graphQL/dashboardwidget';
import { giveProfile } from '../../../../Components/global-notification/notification-list';

function WidgetDetail({
  toggleWidgetDetails,
  handleToggleWidgetDetailsDialog,
  widgetDetailsTitle,
  maskBG,
  data,
  onClick,
  period,
  loading,
  t,
  mode,
  widgetDetailsSubTitle,
  scoreCardClick,
}) {
  // For dummy data
  const operationalRatings = [
    {
      score: '66%',
      performance: 'Good',
      evaluation: [
        {
          evalutionType: 'Excellent',
          evalutionScore: '15%',
          color: '#603ACB',
        },
        {
          evalutionType: 'Good',
          evalutionScore: '40%',
          color: '#0088FA',
        },
        {
          evalutionType: 'Fair',
          evalutionScore: '20%',
          color: '#F2C94C',
        },
        {
          evalutionType: 'Poor',
          evalutionScore: '10%',
          color: '#F29C4C',
        },
        {
          evalutionType: 'Very Poor',
          evalutionScore: '15%',
          color: '#F24C4C',
        },
      ],
      text: 'Overall average',
      titleText: 'On Time Rate',
    },
    {
      score: '85%',
      performance: 'Excellent',
      evaluation: [
        {
          evalutionType: 'Excellent',
          evalutionScore: '55%',
          color: '#603ACB',
        },
        {
          evalutionType: 'Good',
          evalutionScore: '30%',
          color: '#0088FA',
        },
        {
          evalutionType: 'Fair',
          evalutionScore: '5%',
          color: '#F2C94C',
        },
        {
          evalutionType: 'Poor',
          evalutionScore: '5%',
          color: '#F29C4C',
        },
        {
          evalutionType: 'Very Poor',
          evalutionScore: '5%',
          color: '#F24C4C',
        },
      ],
      text: 'Overall average',
      createdAt: '1696498912392',
      titleText: 'Responsiveness',
    },
    {
      score: '95%',
      performance: 'Excellent',
      evaluation: [
        {
          evalutionType: 'Excellent',
          evalutionScore: '75%',
          color: '#603ACB',
        },
        {
          evalutionType: 'Good',
          evalutionScore: '25%',
          color: '#0088FA',
        },
        {
          evalutionType: 'Fair',
          evalutionScore: '0%',
          color: '#F2C94C',
        },
        {
          evalutionType: 'Poor',
          evalutionScore: '0%',
          color: '#F29C4C',
        },
        {
          evalutionType: 'Very Poor',
          evalutionScore: '0%',
          color: '#F24C4C',
        },
      ],
      text: 'Overall average',
      createdAt: '1696498912392',
      titleText: 'Document centralisation',
    },
    {
      score: '65%',
      performance: 'Good',
      evaluation: [
        {
          evalutionType: 'Excellent',
          evalutionScore: '5%',
          color: '#603ACB',
        },
        {
          evalutionType: 'Good',
          evalutionScore: '50%',
          color: '#0088FA',
        },
        {
          evalutionType: 'Fair',
          evalutionScore: '15%',
          color: '#F2C94C',
        },
        {
          evalutionType: 'Poor',
          evalutionScore: '25%',
          color: '#F29C4C',
        },
        {
          evalutionType: 'Very Poor',
          evalutionScore: '5%',
          color: '#F24C4C',
        },
      ],
      text: 'Overall average',
      createdAt: '1696498912392',
      titleText: 'Lead Time',
    },
  ];
  const operationalLabels = [
    {
      labelName: 'Very Poor',
      color: '#F24C4C',
    },
    {
      labelName: 'Poor',
      color: '#F29C4C',
    },
    {
      labelName: 'Fair',
      color: '#F2C94C',
    },
    {
      labelName: 'Good',
      color: '#3B86F2',
    },
    {
      labelName: 'Excellent',
      color: '#603ACB',
    },
  ];
  // For dummy data end
  const [activeCard, setActiveCard] = useState('');
  const [activeAdd, setActiveAdd] = useState(false);
  const [subTitle, setSubTitle] = useState('');
  const [currentComp, setCurrentComp] = useState({});

  const { companyId, relation } = useParams();

  const handlegetTitile = (e) => {
    setSubTitle(e);
  };

  const [addSubscriptionWidget] = useMutation(QUERY_ADDSUBSCRIPTION, {
    client: gqlMicroServiceTokenClient,
  });
  const addNewWidgetCommon = async (widgetKey) => {
    try {
      addSubscriptionWidget({
        variables: {
          input: {
            widgetKey,
            profile: giveProfile(relation),
            ...(companyId && { partnerId: '1' }),
          },
        },

        onCompleted: (resp) => {
          toast.success(resp?.addSubscription?.message);
        },
      });
    } catch (error) {
      console.error(error);
      return '';
      //  setLoading(false);
    }
    return '';
  };
  return (
    <Dialog
      isShowing={toggleWidgetDetails}
      hide={handleToggleWidgetDetailsDialog}
      placement="middle"
      enableOutsideClick
      maskBG={maskBG}
      enableBlurBG
    >
      <div className="widget-details-container">
        <div className="widget-details-header">
          {data?.smallStatusWidget && data?.smallStatusWidget?.length > 0 && (
            <h3 className="widget-details-title">{widgetDetailsTitle}</h3>
          )}{' '}
          {data?.smallStatusWidget && data?.smallStatusWidget?.length === 0 && (
            <h3 className="widget-details-title">
              {data?.largWidgetData[0]?.largeData?.title}
            </h3>
          )}
          {/* {false && ( */}
          <div className="widget-details-header-action">
            {/* <IconButton className="hover-effect-fill">
              <DownloadIcon />
            </IconButton> */}
            <IconButton
              className={`hover-effect${activeAdd ? ' active' : ''}`}
              onClick={() => setActiveAdd(!activeAdd)}
            >
              <CardEditIcon color="#7D7D7D" />
            </IconButton>
          </div>
          {/* )} */}
        </div>

        <div className="widget-details-body">
          {loading ? (
            <div className="widget-details-row">
              <div className="widget-details-small-col">
                <DashboardCardLoader />
              </div>
              <div className="widget-details-small-col">
                <DashboardCardLoader />
              </div>
              <div className="widget-details-small-col">
                <DashboardCardLoader />
              </div>
              <div className="widget-details-small-col">
                <DashboardCardLoader />
              </div>
              <div className="widget-details-small-col">
                <DashboardCardLoader />
              </div>
            </div>
          ) : (
            data?.largWidgetData &&
            data?.largWidgetData?.length > 0 &&
            data?.smallStatusWidget?.length > 0 && (
              <div
                className={`widget-details-row${
                  data?.smallStatusWidget?.length < 3 ? ' justify-start' : ''
                }`}
                style={{
                  gridGap: data?.smallStatusWidget?.length === 2 ? '25%' : '0',
                }}
              >
                {data?.smallStatusWidget?.map((val) => {
                  return (
                    <div className="widget-details-small-col">
                      <DashboardCard
                        icon={
                          (mode.toLowerCase() === 'order' && (
                            <DashboardOrderIcon />
                          )) ||
                          (mode.toLowerCase() === 'booking' && (
                            <DashboardBookingIcon />
                          )) ||
                          (mode.toLowerCase() === 'shipment' && (
                            <DashboardShipmentIcon />
                          )) ||
                          (mode.toLowerCase() === 'quotation' && (
                            <DashboardQuotesIcon />
                          ))
                        }
                        title={Object.keys(val)[0]}
                        cardValue={val[Object.keys(val)[0]]?.cardValue}
                        value={val[Object.keys(val)[0]]?.currentValue}
                        progressValue={
                          val[Object.keys(val)[0]]?.progress || '+0'
                        }
                        // valueIcon={<DashboardValToDoIcon />}
                        onClick={(event) => {
                          onClick(event);
                          handlegetTitile(event);
                        }}
                        period={period}
                        activeCard={
                          activeCard ||
                          Object.keys(data?.smallStatusWidget[0])[0]
                        }
                        setActiveCard={setActiveCard}
                        enableActive
                        t={t}
                        disableExpand
                      />
                      {activeAdd && (
                        <IconButton
                          size="small"
                          verient="add"
                          className="position-absolute t--10 r--10 z-2"
                          onClick={() => {
                            addNewWidgetCommon(val[Object.keys(val)[0]]?.key);
                          }}
                        >
                          <PlusIcon />
                        </IconButton>
                      )}
                    </div>
                  );
                })}
              </div>
            )
          )}

          {loading ? (
            <div className="widget-details-row flex-wrap">
              <div className="widget-details-large-col">
                <Skeleton
                  skMargin="2.083vw 0 0.694vw"
                  skHeight="2.222vw"
                  skWidth="9.722vw"
                />
                <LargeWidgetsLoader />
              </div>
              <div className="widget-details-large-col">
                <Skeleton
                  skMargin="2.083vw 0 0.694vw"
                  skHeight="2.222vw"
                  skWidth="16.667vw"
                />
                <LargeWidgetsLoader />
              </div>
            </div>
          ) : (
            <div className="widget-details-row flex-wrap">
              {data?.largWidgetData?.map((lval) => {
                return (
                  <div className="widget-details-large-col">
                    <h4 className="widget-details-sub-title">
                      {subTitle === '' && data?.smallStatusWidget.length > 0
                        ? Object.keys(data?.smallStatusWidget[0])?.at(0)
                        : t(subTitle)}{' '}
                      {data?.smallStatusWidget.length > 0 &&
                        widgetDetailsSubTitle}
                    </h4>
                    <div className="position-relative">
                      {activeAdd && (
                        <IconButton
                          size="small"
                          verient="add"
                          className="position-absolute t--10 r--10 z-2"
                          onClick={() => {
                            addNewWidgetCommon(lval[Object.keys(lval)[0]]?.key);
                          }}
                        >
                          <PlusIcon />
                        </IconButton>
                      )}

                      <LargeWidgets
                        title={lval?.largeData?.title}
                        subTitle={lval?.largeData?.subTitle}
                        chartType={lval?.largeData?.chartType}
                        cardType={lval?.largeData?.cardType}
                        data={lval?.largeData?.list}
                        disableDownload
                        disableExpand
                        detailsWidget
                        disableButtons
                        mode={mode}
                        chartHeight={lval?.largeData?.list?.chartHeight}
                        chartAspectRatio={2}
                        t={t}
                        companySpecific={lval?.companySpecific}
                        detailHeader={lval?.largeData?.cardType === 'chart'}
                        xyText={lval?.xAxis}
                        largeSubTitle={lval?.largeSubTitle}
                        scoreCardClick={scoreCardClick}
                        currentComp={currentComp}
                        setCurrentComp={setCurrentComp}
                        noData={lval?.nodata}
                        height="15.278vw"
                        // enabelHover
                      />
                    </div>
                  </div>
                );
              })}
              {false && (
                <div className="widget-details-large-col">
                  <CardPanel>
                    <CardPanelBody>
                      <UserDetails
                        size="small"
                        enableLink
                        userName="Supplier Name"
                        url="/"
                      />
                      <BarPanel
                        title="Statistics"
                        subTitle="Operational Performance"
                        titleRight="Total:"
                        subTitleRight="20 Orders"
                      >
                        {operationalRatings && (
                          <StackedBar
                            data={operationalRatings}
                            height="15.278vw"
                            labels={operationalLabels}
                          />
                        )}
                      </BarPanel>
                    </CardPanelBody>
                  </CardPanel>
                </div>
              )}
            </div>
          )}
        </div>
        {!data?.largWidgetData &&
          data?.largWidgetData?.length === 0 &&
          data?.smallStatusWidget?.length === 0 &&
          !loading && <NoData image={<DefaultNoDataIcon />} />}
      </div>
    </Dialog>
  );
}

WidgetDetail.propTypes = {
  toggleWidgetDetails: PropTypes.bool,
  handleToggleWidgetDetailsDialog: PropTypes.func,
  widgetDetailsTitle: PropTypes.string,
  maskBG: PropTypes.string,
  data: PropTypes.instanceOf(Array),
  onClick: PropTypes.func,
  period: PropTypes.string,
  loading: PropTypes.bool,
  t: PropTypes.func,
  mode: PropTypes.string,
  widgetDetailsSubTitle: PropTypes.string,
  scoreCardClick: PropTypes.func,
};
WidgetDetail.defaultProps = {
  toggleWidgetDetails: false,
  handleToggleWidgetDetailsDialog: () => {},
  widgetDetailsTitle: '',
  maskBG: '',
  data: [],
  onClick: () => {},
  period: '',
  loading: true,
  t: () => {},
  mode: '',
  widgetDetailsSubTitle: '',
  scoreCardClick: () => {},
};

export default WidgetDetail;
