import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import './user-info.scss';

export default function UserInfo({
  userName,
  userEmail,
  userRole,
  shortName,
  direction,
  ...props
}) {
  let timeout;
  const [active, setActive] = useState(false);
  const [leftPosition, setLeftPosition] = useState();
  const [topPosition, setTopPosition] = useState();
  const [userInfoHeight, setUserInfoHeight] = useState(0);
  const [userInfoWidth, setUseInfoWidth] = useState(0);
  const userInfoContainer = useRef(null);
  const [colorCode, setColorCode] = useState('');
  useEffect(() => {
    setUserInfoHeight(userInfoContainer?.current?.clientHeight);
    setUseInfoWidth(userInfoContainer?.current?.clientWidth);
  });

  const showUserInfo = (e) => {
    if (direction === 'right') {
      timeout = setTimeout(() => {
        const rectangle = e.target?.getBoundingClientRect();
        if (rectangle.x + rectangle.width + userInfoWidth > window.innerWidth) {
          setLeftPosition(rectangle.x - userInfoWidth);
        } else {
          setLeftPosition(rectangle.x + rectangle.width + 2);
        }
        if (
          rectangle.y + rectangle.height + userInfoHeight >
          window.innerHeight
        ) {
          setTopPosition(rectangle.y - userInfoHeight);
        } else {
          setTopPosition(rectangle.y - rectangle.height);
        }

        setActive(true);
      }, 200);
    } else if (direction === 'left') {
      setTimeout(() => {
        const rectangle = e.target?.getBoundingClientRect();
        setLeftPosition(rectangle.x - userInfoWidth);
        setTopPosition(rectangle.y - rectangle.height);
        setActive(true);
      }, 200);
    } else {
      setTimeout(() => {
        const rectangle = e.target?.getBoundingClientRect();
        if (rectangle.x + rectangle.width + userInfoWidth > window.innerWidth) {
          setLeftPosition(rectangle.x - userInfoWidth);
        } else {
          setLeftPosition(rectangle.x + rectangle.width - 10);
        }
        if (
          rectangle.y + rectangle.height + userInfoHeight >
          window.innerHeight
        ) {
          setTopPosition(rectangle.y - userInfoHeight);
        } else {
          setTopPosition(rectangle.y - rectangle.height);
        }
        setActive(true);
      }, 200);
    }
  };
  const showInfo = () => {
    setActive(true);
  };
  const hideUserInfo = () => {
    clearInterval(timeout);
    setTimeout(() => {
      setActive(false);
    }, 200);
  };
  useEffect(() => {
    const colorIns = shortName?.split('');
    setColorCode(colorIns?.shift()?.toLowerCase());
  });

  return (
    <div
      className="user-info-wrapper"
      onMouseEnter={showUserInfo}
      onMouseLeave={hideUserInfo}
    >
      {props.children}
      {/* {active && (
        <div className="user-info-mask" onMouseEnter={hideUserInfo}>
          &nbsp;
        </div>
      )} */}
      <div
        className={`user-info ${active ? 'show' : ''} ${
          direction === 'right' ? 'right' : ''
        }`}
        ref={userInfoContainer}
        style={{ left: leftPosition, top: topPosition }}
        onMouseLeave={hideUserInfo}
        onMouseEnter={showInfo}
      >
        <div className={`info-user-image ${colorCode}`}>
          {props.userUrl !== '' ? (
            <img src={props.userUrl} alt="" />
          ) : (
            <span>{shortName}</span>
          )}
        </div>
        <div className="info-user-details">
          <p className="info-user-name">{userName}</p>
          <p className="info-user-email">{userEmail}</p>
          <p className="info-user-role">{userRole}</p>

          {/* <button className="send-message" type="button">
          Send Mesage
        </button> */}
        </div>
      </div>
    </div>
  );
}

UserInfo.propTypes = {
  children: PropTypes.node.isRequired,
  userName: PropTypes.string,
  userEmail: PropTypes.string,
  userRole: PropTypes.string,
  userUrl: PropTypes.string,
  shortName: PropTypes.string,
  direction: PropTypes.string,
};
UserInfo.defaultProps = {
  userName: '',
  userEmail: '',
  userRole: '',
  userUrl: '',
  shortName: '',
  direction: '',
};
