import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useMutation, useQuery } from '@apollo/client';
import Button from '../../Components/button/button';
import DialogPanel from '../../Components/dialog-panel/dialog-panel';
import DialogPanelBody from '../../Components/dialog-panel/dialog-panel-body/dialog-panel-body';
import DialogPanelFooter from '../../Components/dialog-panel/dialog-panel-footer/dialog-panel-footer';
import TextBox from '../../Components/text-box/text-box';
import {
  ROLE_NOT_EXIST,
  UPDATE_ROLE_NAME,
} from '../../graphQL/rolesAndPermissions';
import { giveProfile } from '../../Components/global-notification/notification-list';
import { GreenTickCircle } from '../../Components/icons/icons';

function RenameRole({
  renameToggleFunc,
  roleName,
  roleId,
  refetchRolesList,
  t,
}) {
  const { relation } = useParams();
  const [editRoleName, setEditRoleName] = useState(roleName);
  const [isDirty, setIsDirty] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [roleNameToVaildate, setRoleNameToVaildate] = useState('');
  const [isRoleNameAvailable, setIsRoleNameAvailable] = useState(false);

  let roleNameValidating = false;
  const { loading, refetch } = useQuery(ROLE_NOT_EXIST, {
    variables: {
      input: {
        roleName: roleNameToVaildate,
      },
    },
    onCompleted: (data) => {
      setErrorMessage('');
      if (data?.RoleExist?.data?.createRole && roleName !== editRoleName) {
        setErrorMessage(
          t(
            'systemsettings.roles&permission.actionspopups.renamepopup.validation.nameexists'
          )
        );
        setIsRoleNameAvailable(false);
      } else {
        setErrorMessage('');
        setIsRoleNameAvailable(true);
      }
      roleNameValidating = loading;
    },
  });

  const [handleUpdateRoleName] = useMutation(UPDATE_ROLE_NAME);
  const updateRoleNameServerCall = async () => {
    const input = {
      roleId,
      updatedName: editRoleName,
      profileType: giveProfile(relation),
    };
    await handleUpdateRoleName({
      variables: {
        input,
      },
      onCompleted: (data1) => {
        toast.success(data1?.UpdateRoleName?.message);
        refetchRolesList();
        renameToggleFunc();
      },
    });
  };
  return (
    <DialogPanel
      title={t(
        'systemsettings.roles&permission.actionspopups.renamepopup.rename'
      )}
    >
      <DialogPanelBody>
        <h3 className="m-0 mb-13 sub-title-color fs-16 fw-500">{roleName}</h3>
        <div className="assign-contact-container d-flex justify-between align-center position-relative">
          <div className="w-100">
            <TextBox
              type="input"
              label={t(
                'systemsettings.roles&permission.actionspopups.renamepopup.rename'
              )}
              value={editRoleName}
              onChange={(e) => {
                setIsRoleNameAvailable(false);
                setIsDirty(true);
                setEditRoleName(e.target.value);
                setErrorMessage('');
              }}
              required
              errorMessage={isDirty && !isRoleNameAvailable && errorMessage}
              message={t(
                'systemsettings.roles&permission.actionspopups.renamepopup.validation.availability'
              )}
            />
          </div>
          {roleNameValidating ? (
            'loading'
          ) : (
            <Button
              styles="icon"
              className="px-10 position-absolute r-0 z-2"
              icon={<GreenTickCircle />}
              onClick={() => {
                setRoleNameToVaildate(editRoleName);
                refetch();
              }}
              disabled={roleName === editRoleName}
            />
          )}
        </div>
      </DialogPanelBody>
      <DialogPanelFooter>
        <Button
          label={t(
            'systemsettings.roles&permission.actionspopups.renamepopup.cancel'
          )}
          styles="cancel"
          onClick={renameToggleFunc}
        />
        <Button
          label={t(
            'systemsettings.roles&permission.actionspopups.renamepopup.rename'
          )}
          onClick={() => {
            if (editRoleName?.trim() && !errorMessage) {
              updateRoleNameServerCall();
            }
          }}
          color="secondary"
          disabled={
            !editRoleName?.trim() ||
            errorMessage ||
            roleName === editRoleName ||
            !isRoleNameAvailable
          }
        />
      </DialogPanelFooter>
    </DialogPanel>
  );
}

export default RenameRole;

RenameRole.propTypes = {
  renameToggleFunc: PropTypes.func,
  roleName: PropTypes.string,
  roleId: PropTypes.string,
  refetchRolesList: PropTypes.func,
  t: PropTypes.func,
};

RenameRole.defaultProps = {
  renameToggleFunc: () => {},
  roleName: '',
  roleId: '',
  refetchRolesList: () => {},
  t: () => {},
};
