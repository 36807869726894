import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import './search-select.scss';
import Button from '../button/button';
import { EditIcon, SearchIcon, ShortDownIcon } from '../icons/icons';
import AdvancePopover from '../advance-popover/advance-popover';

function SearchSelect({
  label,
  data,
  onChange,
  onClick,
  defaultValue,
  selectProduct,
  t,
}) {
  const selectPackageType = useRef(null);
  const [query, setQuery] = useState('');
  const [selectedLabel, setSelectedlabel] = useState(defaultValue);
  const [eventValue, setEventValue] = useState();
  const [keepOpenPopover, setKeepOpenPopover] = useState(false);
  const [isToggle, setIsToggle] = useState(!selectProduct);

  const togglePopover = () => {
    setEventValue(selectPackageType.current);
  };

  const handleClose = () => {
    setEventValue(null);
    setKeepOpenPopover(false);
  };
  const handleChange = (e) => {
    setQuery(e.target.value?.toLowerCase());
    setSelectedlabel('');
    togglePopover(e);
    setKeepOpenPopover(true);
  };
  const getlabel = (e) => {
    setSelectedlabel(e.target.value);
    onChange(e);
    handleClose();
    setKeepOpenPopover(false);
    setIsToggle(false);
  };
  return (
    <>
      {(isToggle || !selectedLabel) && (
        <div className="search-select">
          <button
            className="search-select-box"
            type="button"
            onClick={togglePopover}
            id="actionPopover"
            ref={selectPackageType}
          >
            <span className="search-select-value">
              {selectedLabel === '' ? label : selectedLabel}
            </span>
            <ShortDownIcon color="#0088FA" />
          </button>
          <Button type="icon" icon={<EditIcon />} />
          <AdvancePopover
            reference="actionPopover"
            getEvent={eventValue}
            closePopover={handleClose}
            className="search-select-popover"
            popoverOpen={keepOpenPopover}
            placement="left"
          >
            <div className="search-select-search">
              <input type="text" onChange={handleChange} />
              <SearchIcon />
            </div>
            <ul>
              {data
                ?.filter((val) => val?.label?.toLowerCase()?.includes(query))
                .map((val) => (
                  <li key={val.value}>
                    <button type="button" value={val.value} onClick={getlabel}>
                      {val?.label}
                    </button>
                  </li>
                ))}
            </ul>
            <p>
              {t(
                'manageordersscreen.sendbookingdetails.packagetype.cantfindpackage'
              )}{' '}
              <br />{' '}
              {t('manageordersscreen.sendbookingdetails.packagetype.dontworry')}{' '}
              <button type="button" onClick={onClick}>
                {' '}
                {t(
                  'manageordersscreen.sendbookingdetails.packagetype.nextstep'
                )}
              </button>
              .
            </p>
          </AdvancePopover>
        </div>
      )}
      {!isToggle && selectedLabel && (
        <>
          {selectedLabel}
          <Button
            size="icon"
            className="p-0 ml-10"
            icon={<EditIcon />}
            onClick={() => setIsToggle(true)}
          />
        </>
      )}
    </>
  );
}

export default SearchSelect;
SearchSelect.propTypes = {
  label: PropTypes.string,
  data: PropTypes.instanceOf(Array),
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  defaultValue: PropTypes.string,
  selectProduct: PropTypes.bool,
  t: PropTypes.func,
};
SearchSelect.defaultProps = {
  label: '',
  data: [],
  onChange: () => {},
  onClick: () => {},
  defaultValue: '',
  selectProduct: false,
  t: () => {},
};
