import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import './advance-select.scss';
import { ShortDownIcon } from '../icons/icons';

export default function AdvanceSelect({
  placeholder,
  onChange,
  data,
  handleInputChanged,
  defaultValue,
  label,
  errorMessage,
  message,
  disabled,
  radiusStyle,
  disableSearch,
  required,
  width,
  fontSize,
  maxLength,
}) {
  const [t] = useTranslation('company');
  const [query, setQuery] = useState('');
  const [selectedValue, setSelectedValue] = useState();
  const [selectedLabel, setSelectedlabel] = useState();
  const [openDDL, setOpenDDl] = useState();
  const [getTop, setGetTop] = useState();
  const [getLeft, setGetLeft] = useState();
  const [getWidth, setGetWidth] = useState();
  const listHeight = useRef();
  const [searchValue, setSearchValue] = useState();
  const ref = useRef(null);
  const boxHeight = useRef(null);

  useEffect(() => {
    setSelectedValue(defaultValue?.value);
    setSelectedlabel(defaultValue?.label);
  }, [defaultValue?.value, defaultValue?.label]);

  const getValue = (e) => {
    setSelectedValue(e.target.value);
    setSelectedlabel(e.target.nextSibling.innerText);
    onChange(e);
    setOpenDDl(false);
    setSearchValue('');
    document.body.style.overflow = 'auto';
  };

  const getPosition = () => {
    setQuery('');
    setTimeout(() => {
      if (
        boxHeight.current.getBoundingClientRect().top +
          boxHeight.current.getBoundingClientRect().height +
          listHeight.current.getBoundingClientRect().height >
        window.innerHeight
      ) {
        setGetTop(
          boxHeight.current.getBoundingClientRect().top -
            listHeight.current.getBoundingClientRect().height
        );
      } else {
        setGetTop(
          boxHeight.current.getBoundingClientRect().top +
            boxHeight.current.getBoundingClientRect().height
        );
      }

      setOpenDDl(true);
      ref.current.focus();
    }, 200);
    document.body.style.overflow = 'hidden';
    setGetWidth(boxHeight.current.getBoundingClientRect().width);
    setGetLeft(boxHeight.current.getBoundingClientRect().left);
  };
  const handleChange = (e) => {
    setQuery(e.target.value?.toLowerCase());
    handleInputChanged(e);
    setSelectedValue(null);
    setSelectedlabel('');
    setTimeout(() => {
      if (
        boxHeight.current.getBoundingClientRect().top +
          boxHeight.current.getBoundingClientRect().height +
          listHeight.current.getBoundingClientRect().height >
        window.innerHeight
      ) {
        setGetTop(
          boxHeight.current.getBoundingClientRect().top -
            listHeight.current.getBoundingClientRect().height
        );
      } else {
        setGetTop(
          boxHeight.current.getBoundingClientRect().top +
            boxHeight.current.getBoundingClientRect().height
        );
      }
      ref.current.focus();
    }, 100);
    setGetWidth(e.target.parentElement.offsetWidth);
    setGetLeft(e.target.parentElement.clientY);
    setSearchValue(e.target.value);
  };
  const closeDropdown = () => {
    setOpenDDl(false);
    document.body.style.overflow = 'auto';
  };
  return (
    <div
      className={`advance-select ${openDDL ? 'active' : ''} ${
        disabled ? 'disabled' : ''
      }`}
      style={{ width }}
    >
      {label !== '' && (
        <label htmlFor={label} style={{ fontSize }}>
          {label}
          {required && <span className="required">*</span>}{' '}
        </label>
      )}
      <div className={`textbox-area ${radiusStyle}`} ref={boxHeight}>
        <div className="selected-value">{selectedLabel}</div>

        <input
          type="text"
          placeholder={selectedLabel === '' ? placeholder : ''}
          onChange={handleChange}
          ref={ref}
          value={searchValue}
          onClick={getPosition}
          readOnly={disableSearch}
          id={label}
          maxLength={maxLength}
          // defaultValue={selectedLabel}
        />
        <span className="arrow">
          <ShortDownIcon />
        </span>
      </div>

      {openDDL && (
        <div
          className="select-mask"
          onClick={closeDropdown}
          onKeyDown={closeDropdown}
          role="button"
          tabIndex="0"
        >
          &nbsp;
        </div>
      )}
      {data.length === 0 ? (
        <ul
          className={`advance-select-options ${openDDL ? 'show' : ''}`}
          style={{ width: getWidth, top: getTop, left: getLeft }}
          ref={listHeight}
        >
          <li>
            <label className="option">
              <span className="label">
                {t(
                  'companydetailscreen.documentstab.list.viewhistory.requestnew.nooptions'
                )}
              </span>
            </label>
          </li>
        </ul>
      ) : (
        <ul
          className={`advance-select-options ${openDDL ? 'show' : ''}`}
          style={{ width: getWidth, top: getTop, left: getLeft }}
          ref={listHeight}
        >
          {data
            ?.filter((val) => val?.label?.toLowerCase()?.includes(query))
            .map((val) => (
              <li key={val.value}>
                <label
                  className={`option ${
                    selectedValue === val.value ? 'active' : ''
                  }`}
                  htmlFor={val.label}
                >
                  <input
                    type="radio"
                    onChange={getValue}
                    onClick={getValue}
                    name="ddOptions"
                    value={val.value}
                    defaultChecked={val.value === selectedValue}
                    id={val.label}
                  />

                  <span className="label">{val.label}</span>
                </label>
              </li>
            ))}
        </ul>
      )}
      {message !== '' && <p className="message">{message}</p>}
      {errorMessage !== '' && <p className="error-message">{errorMessage}</p>}
    </div>
  );
}
AdvanceSelect.propTypes = {
  placeholder: PropTypes.string,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
  data: PropTypes.instanceOf(Array),
  handleInputChanged: PropTypes.func,
  label: PropTypes.string,
  errorMessage: PropTypes.string,
  message: PropTypes.string,
  disabled: PropTypes.bool,
  radiusStyle: PropTypes.string,
  disableSearch: PropTypes.bool,
  required: PropTypes.bool,
  width: PropTypes.string,
  fontSize: PropTypes.string,
  maxLength: PropTypes.number,
};
AdvanceSelect.defaultProps = {
  placeholder: '',
  defaultValue: '',
  onChange: () => {},
  data: [],
  handleInputChanged: () => {},
  label: '',
  errorMessage: '',
  message: '',
  disabled: false,
  radiusStyle: '',
  disableSearch: false,
  required: false,
  width: '',
  fontSize: '',
  maxLength: 50,
};
