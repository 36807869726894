import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactHtmlParser from 'react-html-parser';
import './no-data.scss';
import PropTypes from 'prop-types';
import Button from '../button/button';

export default function NoData({
  bg,
  smallHeight,
  medHeight,
  message,
  onClick,
  actionText,
  enebleAction,
  image,
  width,
}) {
  const [t] = useTranslation('managebooking');
  const deafultMessage = t(
    'managebookingscreen.bookingdetails.emptymessage.nodata'
  );
  return (
    <div
      className={`no-data-container ${bg ? ' bg' : ''} ${
        smallHeight ? ' small-height' : ''
      }${medHeight ? ' med-height' : ''}`}
    >
      <div className="no-data-image">
        {image !== undefined ? (
          image
        ) : (
          <img
            alt="No Data"
            src="https://static.quloi.com/assets/myway_images/no-data.svg"
            width={width}
          />
        )}

        {message ? (
          <p style={{ fontSize: '0.972vw' }}>{ReactHtmlParser(message)}</p>
        ) : (
          <p style={{ fontSize: '0.972vw' }}>{deafultMessage}</p>
        )}
        {enebleAction && (
          <Button
            onClick={onClick}
            type="submit"
            label={actionText}
            color="submit"
          />
        )}
      </div>
    </div>
  );
}
NoData.propTypes = {
  bg: PropTypes.string,
  smallHeight: PropTypes.string,
  medHeight: PropTypes.string,
  message: PropTypes.string,
  onClick: PropTypes.func,
  actionText: PropTypes.string,
  enebleAction: PropTypes.bool,
  image: PropTypes.objectOf(PropTypes.symbol),
  width: PropTypes.string,
};
NoData.defaultProps = {
  bg: '',
  smallHeight: '',
  medHeight: '',
  message: null,
  onClick: () => {},
  actionText: '',
  enebleAction: false,
  image: undefined,
  width: '',
};
