import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './rich-text-editor.scss';

function FormattingButton({ sendFormatting, toggle }) {
  const [formatting, setFormatting] = useState(false);
  const ToogelButton = () => {
    if (formatting === true) {
      setFormatting(false);
    } else setFormatting(true);
  };
  sendFormatting(formatting);
  return (
    <button
      className={`toggleformating ${toggle ? 'toggle' : ''}`}
      onClick={ToogelButton}
      title={formatting ? 'Hide Formatting' : 'Show Formatting'}
      type="button"
    >
      <svg data-hid="true" aria-hidden="true" viewBox="0 0 20 20">
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M6.941 3.952c-.459-1.378-2.414-1.363-2.853.022l-4.053 12.8a.75.75 0 0 0 1.43.452l1.101-3.476h6.06l1.163 3.487a.75.75 0 1 0 1.423-.474l-4.27-12.81Zm1.185 8.298L5.518 4.427 3.041 12.25h5.085Zm6.198-5.537a4.74 4.74 0 0 1 3.037-.081A3.743 3.743 0 0 1 20 10.208V17a.75.75 0 0 1-1.5 0v-.745a7.971 7.971 0 0 1-2.847 1.355 2.998 2.998 0 0 1-3.15-1.143C10.848 14.192 12.473 11 15.287 11H18.5v-.792c0-.984-.641-1.853-1.581-2.143a3.24 3.24 0 0 0-2.077.056l-.242.089a2.222 2.222 0 0 0-1.34 1.382l-.048.145a.75.75 0 0 1-1.423-.474l.048-.145a3.722 3.722 0 0 1 2.244-2.315l.243-.09ZM18.5 12.5h-3.213c-1.587 0-2.504 1.801-1.57 3.085.357.491.98.717 1.572.57a6.47 6.47 0 0 0 2.47-1.223l.741-.593V12.5Z"
          clipRule="evenodd"
        />
      </svg>
      {/* ::after */}
    </button>
  );
}

FormattingButton.propTypes = {
  sendFormatting: PropTypes.func,
  toggle: PropTypes.bool,
};
FormattingButton.defaultProps = {
  sendFormatting: () => {},
  toggle: false,
};
export default FormattingButton;
