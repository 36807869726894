import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { matchSorter } from 'match-sorter';
import './advance-multi-select.scss';
import { CloseIcon, ShortDownIcon } from '../icons/icons';

const useDebounce = (value, delay = 500) => {
  const [debouncedValue, setDebouncedValue] = useState('');
  const timerRef = useRef();

  useEffect(() => {
    timerRef.current = setTimeout(() => setDebouncedValue(value), delay);

    return () => {
      clearTimeout(timerRef.current);
    };
  }, [value, delay]);

  return debouncedValue;
};

function MultiSelectComponent({
  value,
  noOptionsPlaceholder,
  onChange,
  options,
  required,
  name,
  id,
  placeholder,
  readonly,
  disabled,
  renderValue,
  renderOption,
  filterKeys,
  getKey,
  errorMessage,
  message,
  label,
  sliceVal,
  disableArrow,
  className,
  mBottom,
  mode,
  children,
  maxHeight,
}) {
  // usestate
  const [t] = useTranslation('company');
  const [optionslaceholder, setOptionsPlaceholder] =
    useState(noOptionsPlaceholder);
  const [inputValue, setInputValue] = useState('');
  const debouncedValue = useDebounce(inputValue, 500);
  const [showDropDown, toggleDropDown] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState(options);

  const boxHeight = useRef(null);
  const [ddlBoxHeight, setDdlBopxHeight] = useState();
  const [ddlBoxTop, setDdlBopxTop] = useState();

  // const [listTop, setListTop] = useState();
  const [listLeft, setListLeft] = useState();
  const [listWidth, setListWidth] = useState();

  const getPosition = () => {
    setTimeout(() => {
      setDdlBopxHeight(boxHeight.current?.getBoundingClientRect()?.height);
      setDdlBopxTop(boxHeight.current?.getBoundingClientRect()?.top);
      // setListTop(ddlBoxTop + ddlBoxHeight);
      setListLeft(boxHeight.current?.getBoundingClientRect()?.left);
      setListWidth(boxHeight.current?.getBoundingClientRect()?.width);
    }, 200);
  };

  useEffect(() => {
    setOptionsPlaceholder(
      t(
        'companydetailscreen.detailstab.editdetailspopup.formrequiredmessage.temp'
      )
    );
  }, []);
  const openDropDown = useCallback(() => {
    toggleDropDown(true);
    getPosition();
  }, []);

  const closeDropDown = useCallback(() => {
    toggleDropDown(false);
    setInputValue('');
  }, []);

  const onInputChange = useCallback((e) => {
    // const value();
    getPosition();
    setInputValue(e.target.value);
  }, []);

  const removeItem = useCallback(
    (key) => {
      onChange(value?.filter((item) => getKey(item) !== key));
    },
    [onChange, value]
  );

  const onOptionClick = useCallback(
    (option, isValuePresent) => {
      setInputValue('');
      if (value) {
        if (isValuePresent) {
          removeItem(getKey(option));
        } else {
          onChange([...value, option]);
        }
      }
    },
    [onChange, value]
  );

  useEffect(() => {
    let keys = [];
    if (filterKeys && filterKeys?.length > 0) {
      keys = filterKeys;
    } else {
      keys = options.length ? Object.keys(options[0]) : [];
    }
    // const keys =
    //   filterKeys && filterKeys?.length > 0
    //     ? filterKeys
    //     : options.length
    //     ? Object.keys(options[0]).filter((key) => key !== 'id')
    //     : [];
    if (debouncedValue) {
      setFilteredOptions(
        matchSorter(options, debouncedValue, {
          keys,
        })
      );
    } else {
      setFilteredOptions(options);
    }
  }, [debouncedValue, options, filterKeys]);

  return (
    <div
      data-cy="drop-down-from-role"
      className={`advance-multi-select${
        mode === 'material' ? ' material' : ''
      }${`${className ? ` ${className}` : ''}`}`}
    >
      {label && (
        <label
          className={`advance-multi-select-label${
            value.length > 0 || showDropDown ? ' active' : ''
          }`}
        >
          {label}
          {required && <span className="required">*</span>}
        </label>
      )}
      <div ref={boxHeight} className="advance-multi-select-form-group">
        <span className="icon">{children}</span>
        {value?.length > 0 && (
          <div
            className="content"
            onClick={openDropDown}
            role="button"
            onKeyUp={openDropDown}
            tabIndex={0}
          >
            {value?.slice(0, sliceVal).map((item) => {
              return (
                <span className="label" key={getKey(item)}>
                  <span>{renderValue(item)}</span>

                  <button
                    type="button"
                    className="button-transparent"
                    onClick={() => removeItem(getKey(item))}
                  >
                    <CloseIcon />
                  </button>
                </span>
              );
            })}
            {value.length > sliceVal && (
              <span className="label count">+{value.length - sliceVal}</span>
            )}
          </div>
        )}

        <input
          value={inputValue}
          onChange={onInputChange}
          inputMode="text"
          autoComplete="off"
          type="text"
          // required={required}
          name={name}
          id={id}
          tabIndex={0}
          readOnly={readonly}
          disabled={disabled}
          placeholder={
            mode === 'material' && (label || showDropDown) ? '' : placeholder
          }
          className={`input-select ${value?.length > 0 ? 'not-empty' : ''}`}
          spellCheck="false"
          onFocus={openDropDown}
          onClick={openDropDown}
        />
        {!disableArrow && (
          <span
            className="arrow"
            onClick={openDropDown}
            role="button"
            tabIndex={0}
            onKeyUp={openDropDown}
          >
            <ShortDownIcon />
          </span>
        )}

        {showDropDown && (
          <>
            <button
              className="advance-multi-select-mask"
              type="button"
              onClick={closeDropDown}
            >
              &nbsp;
            </button>
            {filteredOptions.length > 0 ? (
              <div
                className="advance-multi-select-options"
                style={{
                  width: listWidth,
                  top: ddlBoxTop + ddlBoxHeight,
                  left: listLeft,
                  maxHeight,
                }}
              >
                <ul>
                  {filteredOptions.map((val) => {
                    const isValuePresent = value.find(
                      (item) => getKey(item) === getKey(val)
                    );
                    return (
                      <li>
                        <button
                          type="button"
                          key={getKey(val)}
                          className={`option ${
                            isValuePresent ? 'checked' : ''
                          }`}
                          onClick={() => {
                            onOptionClick(val, isValuePresent);
                          }}
                          // disabled={renderValue(item)(renderOption(val))}
                        >
                          {renderOption(val)}
                        </button>
                      </li>
                    );
                  })}
                </ul>
              </div>
            ) : (
              <div
                className="advance-multi-select-options"
                style={{
                  width: listWidth,
                  top: ddlBoxTop + ddlBoxHeight,
                  left: listLeft,
                  maxHeight,
                }}
              >
                <ul>
                  <li>
                    <button disabled="disabled" type="button">
                      {optionslaceholder}
                    </button>
                  </li>
                </ul>
                {/* <div className="option"></div> */}
              </div>
            )}
          </>
        )}
      </div>
      {message !== '' && (
        <p className="message" style={{ marginBottom: mBottom }}>
          {message}
        </p>
      )}
      {errorMessage !== '' && (
        <p className="error-message">{errorMessage}</p>
        // <p className="profile-error-message">{errorMessage}</p>
      )}
    </div>
  );
}
const MultiSelect = memo(MultiSelectComponent);
export default MultiSelect;
MultiSelectComponent.propTypes = {
  placeholder: PropTypes.string,
  options: PropTypes.instanceOf(Array),
  value: PropTypes.instanceOf(Array),
  label: PropTypes.string,
  message: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  readonly: PropTypes.bool,
  disabled: PropTypes.bool,
  noOptionsPlaceholder: PropTypes.string,
  renderValue: PropTypes.func,
  renderOption: PropTypes.func,
  filterKeys: PropTypes.instanceOf(Array),
  getKey: PropTypes.func,
  errorMessage: PropTypes.string,
  sliceVal: PropTypes.number,
  disableArrow: PropTypes.bool,
  className: PropTypes.string,
  mBottom: PropTypes.string,
  mode: PropTypes.string,
  children: PropTypes.node,
  maxHeight: PropTypes.string,
};
MultiSelectComponent.defaultProps = {
  message: '',
  label: '',
  value: [],
  noOptionsPlaceholder: 'No items found',
  options: [],
  required: false,
  name: '',
  id: '',
  placeholder: 'Select items',
  readonly: false,
  disabled: false,
  renderValue: () => {},
  renderOption: () => {},
  onChange: () => {},
  getKey: () => {},
  filterKeys: [],
  errorMessage: '',
  sliceVal: 1,
  disableArrow: false,
  className: '',
  mBottom: '',
  mode: '',
  children: [],
  maxHeight: '',
};
