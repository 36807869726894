import React, { useEffect, useState } from 'react';
import './create-new-role.scss';
import PropTypes from 'prop-types';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import CREATE_ROLE, {
  FETCH_MASTER_INPUT,
  ROLE_NOT_EXIST,
} from '../../../graphQL/rolesAndPermissions';
import TextBox from '../../../Components/text-box/text-box';
import {
  AddUserNewCircleIcon,
  KeyFrameIcon,
  GreenTickCircle,
  RedInfoCircle,
  LoaderCircle,
} from '../../../Components/icons/icons';
import NewButton from '../../../Components/button/newButton';
import { giveProfile } from '../../../Components/global-notification/notification-list';
import PermissionsTab from '../permissions-tab';
import DialogPanelBody from '../../../Components/dialog-panel/dialog-panel-body/dialog-panel-body';
import DialogPanelFooter from '../../../Components/dialog-panel/dialog-panel-footer/dialog-panel-footer';
import DialogPanel from '../../../Components/dialog-panel/dialog-panel';
import AssignUsers from '../assign-users';
import GlobalLoader from '../../../Components/global-loader/global-loader';

function CreateNewRole({ togglehandler, refetchRolesList, t }) {
  const { relation } = useParams();
  const [handleCreateRole] = useMutation(CREATE_ROLE);

  const [createRoleButton, setCreateRoleButton] = useState('Create Role');
  const [createRoleDone, setCreateRoleDone] = useState('Create Role');
  const [newRole, setNewRole] = useState('');
  const [isAssignUsersEnabled, setIsAssignUsersEnabled] = useState(false);
  const [permissionList, setPermissionList] = useState();
  const [multiSelectValue, setMultiSelectValue] = useState();
  const [isDirty, setIsDirty] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isRoleNameAvailable, setIsRoleNameAvailable] = useState(false);
  const [roleNameValidating, setRoleNameValidating] = useState(false);

  const [checkRoleName] = useLazyQuery(ROLE_NOT_EXIST, {
    onCompleted: (data) => {
      setErrorMessage('');
      setRoleNameValidating(false);

      if (data?.RoleExist?.data?.createRole) {
        setErrorMessage(
          t(
            'systemsettings.roles&permission.actionspopups.renamepopup.validation.nameexists'
          )
        );
        setIsRoleNameAvailable(false);
      } else {
        setErrorMessage('');
        setIsRoleNameAvailable(true);
      }
    },
  });

  const { loading } = useQuery(FETCH_MASTER_INPUT, {
    variables: {
      input: {
        profileType: giveProfile(relation),
      },
    },
    onCompleted: (data) => {
      const permList = data?.FetchMasterPermissionsModule?.data?.map(
        ({ moduleName, modulePermissions, moduleTranslated }) => ({
          moduleName,
          modulePermissions,
          moduleTranslated,
        })
      );
      setPermissionList(permList);
    },
  });

  useEffect(() => {
    setIsAssignUsersEnabled(!!newRole?.trim());
  }, [permissionList, newRole]);

  useEffect(() => {
    if (!newRole.trim()) {
      return undefined;
    }

    const timer = setTimeout(() => {
      checkRoleName({
        variables: {
          input: {
            roleName: newRole.trim(),
          },
        },
      });
    }, 500);

    return () => clearTimeout(timer);
  }, [newRole]);

  const createRoleServerCall = async (assignUsers) => {
    const input = {
      roleName: newRole,
      users: assignUsers ? multiSelectValue?.map((val) => val?.userSub) : [],
      profileType: giveProfile(relation),
      modules: permissionList,
    };
    await handleCreateRole({
      variables: {
        input,
      },
      onCompleted: (data) => {
        toast.success(data?.CreateRole?.message);
        refetchRolesList();
        togglehandler();
      },
    });
  };

  const renderValidationIcon = () => {
    if (!isDirty || !newRole.trim()) return null;

    let icon = null;
    if (roleNameValidating) {
      icon = <LoaderCircle />;
    } else if (isRoleNameAvailable) {
      icon = <GreenTickCircle />;
    } else {
      icon = <RedInfoCircle />;
    }

    return <div className="icon-container">{icon}</div>;
  };
  const renderStatusValidation = () => {
    let statusClass = '';
    if (roleNameValidating) {
      statusClass = '';
    } else if (isRoleNameAvailable) {
      statusClass = 'success';
    } else if (errorMessage) {
      statusClass = 'error';
    }

    return { statusClass };
  };

  return (
    <DialogPanel
      title={t('systemsettings.roles&permission.createnewrolepopup.title')}
    >
      <DialogPanelBody>
        <div className="create-new-role-container">
          {!loading && permissionList?.length ? (
            <>
              <ul className="settings-tab-list">
                <li
                  className={`settings-tab-nav-item ${
                    createRoleDone ? 'done' : ''
                  } ${createRoleButton === 'Create Role' ? 'current' : ''}`}
                >
                  <button
                    type="button"
                    onClick={() => {
                      setCreateRoleButton('Create Role');
                      setCreateRoleDone(true);
                    }}
                  >
                    <KeyFrameIcon />
                    {t(
                      'systemsettings.roles&permission.createnewrolepopup.createrole'
                    )}
                  </button>
                </li>
                <li
                  className={`settings-tab-nav-item ${
                    createRoleButton === 'Assign Users' ? 'current' : ''
                  }`}
                >
                  <button
                    type="button"
                    onClick={() => {
                      setCreateRoleButton('Assign Users');
                    }}
                    disabled={!isAssignUsersEnabled}
                  >
                    <AddUserNewCircleIcon />
                    {t(
                      'systemsettings.roles&permission.createnewrolepopup.assignusers'
                    )}
                  </button>
                </li>
              </ul>
              {createRoleButton === 'Create Role' && (
                <>
                  <div className="d-flex justify-between align-center position-relative">
                    <div className="w-100">
                      <TextBox
                        type="input"
                        placeholder={t(
                          'systemsettings.roles&permission.createnewrolepopup.roleplaceholder'
                        )}
                        label={t(
                          'systemsettings.roles&permission.createnewrolepopup.role'
                        )}
                        required
                        value={newRole}
                        onChange={(e) => {
                          setIsRoleNameAvailable(false);
                          setIsDirty(true);
                          setNewRole(e.target.value);
                          setErrorMessage('');
                          setRoleNameValidating(true);

                          if (e.target.value.trim()) {
                            setRoleNameValidating(true);
                          } else {
                            setRoleNameValidating(false);
                          }
                        }}
                        errorMessage={
                          isDirty && !isRoleNameAvailable && errorMessage
                        }
                        message={t(
                          'systemsettings.roles&permission.actionspopups.renamepopup.validation.availability'
                        )}
                        bMargin="0.069vw"
                        textboxClassName={renderStatusValidation().statusClass}
                      />
                    </div>

                    {renderValidationIcon()}
                  </div>
                  <PermissionsTab
                    permissionList={permissionList}
                    setPermissionList={setPermissionList}
                    t={t}
                  />
                </>
              )}
              {createRoleButton === 'Assign Users' && (
                <>
                  <h3 className="m-0 mb-13 sub-title-color fs-16 fw-500">
                    {newRole}
                  </h3>
                  <AssignUsers
                    setSelectedContacts={setMultiSelectValue}
                    selectedContacts={multiSelectValue}
                    sliceVal={4}
                    t={t}
                  />
                </>
              )}
            </>
          ) : (
            <GlobalLoader newLoad={true} height="6.944vw" width="6.944vw" />
          )}
        </div>
      </DialogPanelBody>

      <DialogPanelFooter>
        {createRoleButton === 'Assign Users' && (
          <>
            <NewButton styles="cancel" onClick={togglehandler}>
              {t('systemsettings.roles&permission.createnewrolepopup.cancel')}
            </NewButton>
            <NewButton
              styles="secondary"
              disabled={
                !isAssignUsersEnabled || errorMessage || !isRoleNameAvailable
              }
              onClick={() => {
                createRoleServerCall('assignUsers');
              }}
            >
              {t(
                'systemsettings.roles&permission.createnewrolepopup.createassignusers'
              )}
            </NewButton>
          </>
        )}
        {createRoleButton === 'Create Role' && (
          <>
            <NewButton
              styles="primary outline"
              disabled={errorMessage || !isRoleNameAvailable}
              onClick={() => {
                createRoleServerCall('');
              }}
            >
              {t(
                'systemsettings.roles&permission.createnewrolepopup.createrole'
              )}
            </NewButton>
            <NewButton
              styles="secondary"
              onClick={() => setCreateRoleButton('Assign Users')}
              disabled={
                !isAssignUsersEnabled || errorMessage || !isRoleNameAvailable
              }
            >
              {t(
                'systemsettings.roles&permission.createnewrolepopup.createassignusers'
              )}
            </NewButton>
          </>
        )}
      </DialogPanelFooter>
    </DialogPanel>
  );
}

export default CreateNewRole;

CreateNewRole.propTypes = {
  togglehandler: PropTypes.func,
  refetchRolesList: PropTypes.func,
  t: PropTypes.func,
};

CreateNewRole.defaultProps = {
  togglehandler: () => {},
  refetchRolesList: () => {},
  t: () => {},
};
