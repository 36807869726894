import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
// eslint-disable-next-line
import reducers from './reducers';

const preLoadedState = {};
const store = configureStore({
  reducer: reducers,
  preLoadedState,
  middleware: [thunk],
  devTools: true,
});

export default store;
