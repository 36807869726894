import React from 'react';
import PropTypes from 'prop-types';

function DialogPanelFooter({ align = 'space-between', ...props }) {
  return <div className={`dialog-panel-footer ${align}`}>{props.children}</div>;
}

export default DialogPanelFooter;
DialogPanelFooter.propTypes = {
  children: PropTypes.node.isRequired,
  align: PropTypes.string,
};
DialogPanelFooter.defaultProps = {
  align: 'flex-end',
};
