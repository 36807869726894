import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import './style.css';

function Header({ link, ancorText }) {
  return (
    <header>
      <div class="container">
        <div class="d-flex justify-between">
          <a href="https://quloi.com/" class="logo">
            <svg
              width="12.897vw"
              height="4.828vw"
              viewBox="0 0 195 73"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.23315 34.542C5.67745 33.5964 5.64935 32.5487 5.86886 31.5486C7.84446 22.6001 15.6819 15.3191 24.5396 14.2327C35.8278 12.8558 46.1396 19.7863 49.0037 30.8302C51.0303 38.6588 49.376 45.7549 44.2395 52.0181C43.9813 52.446 43.5476 52.3544 43.163 52.358C41.514 52.372 39.8633 52.3773 38.2143 52.358C37.6031 52.3426 36.9998 52.4987 36.4723 52.8087C33.5466 54.4956 30.6298 56.2018 27.6725 57.8341C26.4713 58.4962 25.2614 59.1935 23.8653 59.4083C15.6556 57.8147 10.08 53.013 6.86456 45.3675C6.10769 43.5697 5.69852 41.6698 5.42984 39.7329C5.4035 39.5445 5.5071 39.2874 5.23315 39.18V37.5794C5.36135 37.1079 5.36135 36.6106 5.23315 36.1391V34.542Z"
                fill="#1A3F6A"
              />
              <path
                d="M5.23315 36.1426C5.35936 36.3616 5.4258 36.61 5.4258 36.8628C5.4258 37.1156 5.35936 37.3639 5.23315 37.5829V36.1426Z"
                fill="#597392"
              />
              <path
                d="M149.443 59.403C144.939 59.3866 140.541 58.0315 136.805 55.509C133.069 52.9865 130.162 49.4099 128.453 45.2313C126.744 41.0527 126.309 36.4598 127.203 32.0333C128.096 27.6068 130.279 23.5454 133.475 20.3625C136.67 17.1797 140.735 15.0184 145.156 14.1519C149.577 13.2853 154.154 13.7524 158.31 15.4942C162.466 17.2359 166.013 20.1741 168.504 23.9372C170.994 27.7002 172.316 32.1193 172.303 36.6356C172.273 49.2537 162.087 59.4065 149.443 59.403Z"
                fill="#2DC0DA"
              />
              <path
                d="M56.7218 29.4233C56.7218 24.4349 56.7323 19.4465 56.7078 14.4563C56.7078 13.8735 56.8254 13.6904 57.4418 13.6992C60.1064 13.7356 62.7704 13.7356 65.4338 13.6992C66.0484 13.6992 66.1696 13.8752 66.1678 14.4528C66.1491 23.3895 66.145 32.3263 66.1555 41.263C66.1555 43.221 66.3311 45.1544 67.1565 46.9628C68.6211 50.1745 71.9647 51.8702 75.6191 51.2785C79.2735 50.6869 81.6689 48.2147 82.2923 44.4114C82.4557 43.2762 82.5273 42.1297 82.5065 40.983C82.5065 32.1789 82.5012 23.3749 82.4907 14.5708C82.4907 13.8788 82.6365 13.6727 83.3582 13.6904C85.9924 13.7397 88.6265 13.7203 91.2712 13.7009C91.7541 13.7009 91.9613 13.7784 91.9595 14.3401C91.9315 23.97 91.9701 33.5999 91.8981 43.2298C91.8647 47.5544 90.4932 51.4493 87.5395 54.691C84.9211 57.5576 81.6337 59.2093 77.8353 59.7763C73.6751 60.3961 69.5817 60.1126 65.7639 58.1633C60.6572 55.5556 57.8615 51.2592 57.0502 45.6474C56.555 42.2297 56.8202 38.7803 56.7675 35.3396C56.7394 33.3657 56.7675 31.3901 56.7675 29.4162L56.7218 29.4233Z"
                fill="#1A3F6A"
              />
              <path
                d="M100.647 36.6409C100.647 29.2455 100.659 21.8624 100.629 14.4722C100.629 13.7239 100.84 13.546 101.558 13.5619C103.636 13.6094 105.715 13.6182 107.791 13.5619C108.539 13.5408 108.653 13.7943 108.651 14.4652C108.632 26.4693 108.628 38.4734 108.639 50.4774C108.639 51.6625 108.504 51.5093 109.643 51.5093C115.186 51.5163 120.727 51.5134 126.268 51.5005C126.872 51.5005 127.09 51.6202 127.076 52.277C127.032 54.5185 127.041 56.76 127.076 58.9998C127.076 59.5756 126.901 59.7041 126.354 59.7041C118.042 59.6877 109.73 59.6877 101.418 59.7041C100.759 59.7041 100.638 59.4893 100.64 58.8906C100.655 51.4729 100.657 44.0563 100.647 36.6409Z"
                fill="#2DC0DA"
              />
              <path
                d="M187.275 36.7007C187.275 44.1402 187.263 51.5797 187.293 59.0191C187.293 59.8027 187.061 59.9981 186.306 59.9788C184.198 59.9295 182.091 59.9629 179.994 59.9612C178.953 59.9612 178.951 59.9612 178.951 58.9416V16.9461C178.951 16.0393 178.97 15.1307 178.951 14.2257C178.937 13.7643 179.049 13.5636 179.564 13.5689C181.935 13.5935 184.305 13.6041 186.674 13.5689C187.377 13.5566 187.284 13.9563 187.284 14.3859C187.278 21.8236 187.275 29.2619 187.275 36.7007Z"
                fill="#2DC0DA"
              />
              <path
                d="M23.8653 59.4083C28.3732 57.4714 32.4701 54.7843 36.7392 52.402C37.0584 52.2137 37.4226 52.1158 37.7929 52.1185C39.628 52.129 41.4631 52.1185 43.2982 52.1185C43.6164 52.1478 43.9373 52.1118 44.2412 52.0128C45.1843 52.2241 46.1396 52.0868 47.0896 52.1026C48.6069 52.129 50.1224 52.1237 51.6397 52.1026C52.0769 52.1026 52.2455 52.2118 52.2402 52.6819C52.2192 54.971 52.2104 57.2601 52.2402 59.5491C52.2525 60.2711 51.8047 60.1284 51.4026 60.1249C44.5661 60.0668 37.7296 59.9893 30.8914 59.9576C28.533 59.9576 26.1816 59.9312 23.8653 59.4083Z"
                fill="#2DC0DA"
              />
              <path
                d="M40.5061 37.0072C40.3989 41.33 38.7289 45.0576 35.164 47.8028C30.1293 51.6766 23.1541 51.1765 18.6322 46.6882C13.3358 41.4303 13.2164 32.6157 18.439 27.2927C21.8915 23.7852 26.1008 22.5174 30.8581 23.8451C35.7488 25.2097 38.7113 28.6098 40.067 33.4556C40.3902 34.572 40.4639 35.7165 40.5061 37.0072Z"
                fill="#FEFEFE"
              />
              <path
                d="M149.521 50.0777C147.75 50.08 145.997 49.7327 144.36 49.0555C142.724 48.3783 141.236 47.3845 139.983 46.1309C138.729 44.8773 137.734 43.3884 137.055 41.7492C136.375 40.1101 136.024 38.3527 136.022 36.5775C136.019 34.8023 136.366 33.0441 137.041 31.4031C137.716 29.7622 138.708 28.2707 139.958 27.0138C141.208 25.757 142.693 24.7593 144.328 24.0778C145.963 23.3964 147.715 23.0444 149.486 23.0421C156.916 23.0421 163.007 29.1557 162.997 36.6004C162.982 40.1763 161.556 43.601 159.031 46.1263C156.506 48.6516 153.087 50.0722 149.521 50.0777Z"
                fill="#FEFEFE"
              />
            </svg>
          </a>
          <div class="header-right">
            <Link to={link}>{ancorText}</Link>
            {/* <Link to="/register" class="fill">
              Sign Up
            </Link> */}
          </div>
        </div>
      </div>
    </header>
  );
}

Header.propTypes = {
  link: PropTypes.string,
  ancorText: PropTypes.string,
};
Header.defaultProps = {
  link: '',
  ancorText: '',
};
export default Header;
