import React from 'react';
import PropTypes from 'prop-types';

function DialogPanelBody({ className, bottomPadding, ...props }) {
  return (
    <div
      data-cy="dialog-panel-body"
      className={`dialog-panel-body ${className}`}
      style={{ paddingBottom: bottomPadding }}
    >
      {props.children}
    </div>
  );
}

export default DialogPanelBody;
DialogPanelBody.propTypes = {
  children: PropTypes.node.isRequired,
  bottomPadding: PropTypes.string,
  className: PropTypes.string,
};
DialogPanelBody.defaultProps = {
  bottomPadding: '',
  className: '',
};
