import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './dashboard-search.scss';
import { CloseIcon, NormalSearchIcon } from '../../icons/icons';

function DashboardSearch({ onChange, placeholder, className }) {
  const [toggleSearch, setToggleSratch] = useState(false);
  return (
    <div
      className={`dashboard-search-container${
        className ? ` ${className}` : ''
      }${toggleSearch ? ' active' : ''}`}
    >
      {toggleSearch && (
        <input type="text" placeholder={placeholder} onChange={onChange} />
      )}

      {!toggleSearch ? (
        <button
          type="button"
          className="dashboard-search-button"
          onClick={() => setToggleSratch(!toggleSearch)}
        >
          <NormalSearchIcon color="#616B80" height="24" width="24" />
        </button>
      ) : (
        <button
          type="button"
          className="dashboard-search-button"
          onClick={() => setToggleSratch(!toggleSearch)}
        >
          <CloseIcon size="20" color="#616B80" />
        </button>
      )}
    </div>
  );
}

export default DashboardSearch;
DashboardSearch.propTypes = {
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  className: PropTypes.string,
};
DashboardSearch.defaultProps = {
  onChange: () => {},
  placeholder: 'Type here and press Enter/Return to Search...',
  className: '',
};
