import React from 'react';
import PropTypes from 'prop-types';
import './account-settings-panel.scss';

function AccountSettingsPanelHeadAction({ ...props }) {
  return (
    <div className="d-flex justify-between align-center">{props.children}</div>
  );
}

export default AccountSettingsPanelHeadAction;
AccountSettingsPanelHeadAction.propTypes = {
  children: PropTypes.node,
};
AccountSettingsPanelHeadAction.defaultProps = {
  children: {},
};
