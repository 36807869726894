import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import GoogleMapReact from 'google-map-react';
import { MapMarkerIcon } from '../icons/icons';

export function Marker() {
  return <MapMarkerIcon />;
}

export default function Googlemap({
  height,
  width,
  latitude,
  long,
  text,
  className,
}) {
  const [center, setCenter] = useState();
  const [zoom, setZoom] = useState();
  useEffect(() => {
    setCenter({
      lat: latitude,
      lng: long,
    });
    setZoom(5);
  }, [latitude]);

  return (
    <div
      className={className}
      style={{ height: `${height}`, width: `${width}` }}
    >
      <GoogleMapReact
        bootstrapURLKeys={{
          key: 'AIzaSyAdUg5RYhac4wW-xnx-p0PrmKogycWz9pI',
        }}
        defaultCenter={center}
        defaultZoom={zoom}
        center={center}
      >
        <Marker lat={latitude} lng={long} text={text} />
      </GoogleMapReact>
    </div>
  );
}
Googlemap.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  latitude: PropTypes.number,
  long: PropTypes.number,
  text: PropTypes.string,
  className: PropTypes.string,
};
Googlemap.defaultProps = {
  height: 'auto',
  width: 'auto',
  latitude: 0,
  long: 0,
  text: 'Add',
  className: 'test',
};
