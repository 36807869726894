import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import './listing-status.scss';
import Tooltip from '../tooltip/tooltip';
import {
  ArrowBlueDownIcon,
  ArrowDownIcon,
  // EditCalenderIcon,
} from '../icons/icons';
import AdvancePopover from '../advance-popover/advance-popover';
import DatepickerBox from '../datepicker-box/datepicker-box';

export default function ListingStatus({
  statusLabel,
  enableDropdown,
  futureStatus,
  handleSubmit,
  item,
  autoWidth,
  className,
}) {
  const [t] = useTranslation('status');
  const [eventValue, setEventValue] = useState();
  const [date, setDate] = useState(new Date());
  const [qty, setQty] = useState(item?.pendingQty);
  const togglePopover = (e) => {
    setEventValue(e?.target);
  };

  const handleClose = () => {
    setEventValue(null);
  };

  const handleStatus = (e) => {
    handleSubmit({
      statusName: e.stageName,
      statusDate: date,
      Qty: Number(qty),
    });
  };

  const handleUpdate = (e) => {
    handleStatus(e);
    handleClose();
  };

  return !enableDropdown ? (
    <Tooltip
      className="justify-center"
      content={t(`statuslist.substatus.${statusLabel?.toLowerCase()}`)}
    >
      <span
        className={`status ${
          statusLabel?.toLowerCase() === 'booked' ? 'new' : ''
        }${statusLabel?.toLowerCase().replace(/\s/g, '-')}${
          statusLabel?.toLowerCase() === 'approved' ? 'accepted' : ''
        } ${autoWidth ? 'w-auto' : ''} ${className}`}
      >
        <span className="txt">
          {statusLabel &&
            t(`statuslist.substatus.${statusLabel?.toLowerCase()}`)}
        </span>
      </span>
    </Tooltip>
  ) : (
    <>
      <Tooltip
        content={t(`statuslist.substatus.${statusLabel?.toLowerCase()}`)}
      >
        <button
          type="button"
          className={`status changable ${
            statusLabel?.toLowerCase() === 'booked' ? 'new' : ''
          }${
            statusLabel?.toLowerCase() === 'approved' ? 'accepted' : ''
          }${statusLabel?.toLowerCase().replace(/\s/g, '-')} ${
            autoWidth ? 'w-auto' : ''
          }`}
          onClick={togglePopover}
          id="statusPopover"
        >
          <span className="txt">
            {statusLabel &&
              t(`statuslist.substatus.${statusLabel?.toLowerCase()}`)}
          </span>

          <ArrowDownIcon />
        </button>
      </Tooltip>
      <AdvancePopover
        reference="statusPopover"
        getEvent={eventValue}
        closePopover={handleClose}
        placement="self-bottom"
      >
        <div className="change-status-container small">
          <ArrowBlueDownIcon />
          {futureStatus?.length > 0 &&
            futureStatus?.map((sts) => (
              <>
                {sts.QtySplit && (
                  <div className="quantity-box">
                    <label htmlFor="">{t('dropdown.quantity')}</label>
                    <input
                      type="text"
                      placeholder={t('dropdown.quantityplaceholder')}
                      defaultValue={qty}
                      onChange={(e) => setQty(e.target.value)}
                    />
                  </div>
                )}
                <span className="date-picker">
                  {t('dropdown.date')}
                  <DatepickerBox
                    selectedDate={date}
                    onChange={(e) => {
                      if (e <= new Date()) {
                        setDate(e);
                      }
                    }}
                    placeholder={t('dropdown.dateplaceholder')}
                  />
                  {/* <EditCalenderIcon /> */}
                </span>
                <button
                  type="button"
                  className={`button-submit ${sts.stageName
                    ?.toLowerCase()
                    .replace(/\s/g, '-')}`}
                  onClick={() => {
                    handleUpdate(sts);
                  }}
                  disabled={
                    qty < 1 || qty > item.pendingQty || date > new Date()
                  }
                >
                  {t(`statuslist.updatestatus.${sts.stageName}`)}
                </button>
              </>
            ))}
        </div>
      </AdvancePopover>
    </>
  );
}
ListingStatus.propTypes = {
  statusLabel: PropTypes.string,
  enableDropdown: PropTypes.bool,
  futureStatus: PropTypes.instanceOf(Array),
  handleSubmit: PropTypes.func,
  item: PropTypes.instanceOf(Object),
  autoWidth: PropTypes.bool,
  className: PropTypes.string,
};
ListingStatus.defaultProps = {
  statusLabel: '',
  enableDropdown: false,
  futureStatus: [],
  handleSubmit: () => {},
  item: {},
  autoWidth: false,
  className: '',
};
