import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import { useMutation, useLazyQuery } from '@apollo/client';
import { toast } from 'react-toastify';
import './register.scss';
import {
  EmailIcon,
  GlobeIcon,
  // InfoIcon,
  NameIcon,
  PasswordIcon,
  PhoneIcon,
  TimezoneIcon,
  MyCompany,
  LocationOutlined,
} from '../../../Components/icons/icons';
import GlobalLoader from '../../../Components/global-loader/global-loader';
import {
  SIGNUP_QUERY,
  QUERY_FETCH_INVITE,
  SEARCH_COUNTRY,
  QUERY_FETCH_TIMEZONE,
  FETCH_PARTNER_INVITATION,
  GET_COMPANYNAME_HEADQUARTER,
} from '../../../graphQL/registerGQL';
// import TextBox from '../../../Components/text-box/text-box';
import AdvanceSelectLabelIcon from '../../../Components/advance-select/advance-select-icon';
// import CustomSelect from '../../../Components/custom-select/custom-select';
import TextBoxIcon from '../../../Components/text-box-icon/text-box-icon';
import { getDigitsForPhoneNumber, phoneRegExp } from '../../../helpers/utils';
import AddSupplierAddress from '../../supplier-details/supplier-locations/grid-view/add-supplier-address';

let ZoneOptions = [
  {
    value: 'No TimeZone Found',
    label: 'Select Country First',
  },
];
const noZoneOptions = [];
let fetchCountry = {};
let fetchTimezoneCountry = {};

function SignUp({ t }) {
  const [t2] = useTranslation('toast');
  const [validInviteId, setValidInviteId] = useState('');
  const [validPartnerId, setValidPartnerId] = useState('');
  const [fetchInvite] = useLazyQuery(QUERY_FETCH_INVITE);
  const [fetchTimezone] = useLazyQuery(QUERY_FETCH_TIMEZONE);
  const [handleusersignp, { loading: signupLoading }] =
    useMutation(SIGNUP_QUERY);
  const [handlePartnerInvitation] = useLazyQuery(FETCH_PARTNER_INVITATION);
  const [disable, setDisable] = useState(false);
  // const [noTimeZone, setNoTimeZone] = useState(false);
  const [initialState, setInitialState] = useState(true);
  const [originOptions, setOrginOptions] = useState([]);
  const [origin, setOrigin] = useState('');
  const [originGeoCode, setOriginGeoCode] = useState('');
  const [isDomain, setIsDomain] = useState(false);
  const [orgCountry, setOrgCountry] = useState('');
  const [orgCity, setOrgCity] = useState('');
  const [orgState, setOrgState] = useState('');
  const [orgLat, setOrgLat] = useState('');
  const [orgLong, setOrgLong] = useState('');
  const [orgZip, setOrgZip] = useState('0');
  const [zoneOpt, setZoneOpt] = useState([
    {
      value: 'No TimeZone Found',
      label: 'Select Country First',
    },
  ]);
  const [fetchCompanyName] = useLazyQuery(GET_COMPANYNAME_HEADQUARTER);

  const [resultData, setResultData] = useState({});
  const [options, setOptions] = useState([
    {
      label: '',
      value: '',
    },
  ]);
  // const handledisable = () => {
  //   setDisable(false);
  // };

  const [fetchCountryList] = useLazyQuery(SEARCH_COUNTRY);
  document.body.style.overflow = 'auto';
  useEffect(() => {
    fetchCountryList({
      onCompleted: (data) => {
        const countryList = data.fetchCountry.map((country) => {
          fetchCountry = {
            label: country.CountryName,
            value: country.CountryCode,
          };
          return fetchCountry;
        });
        setOptions(countryList);
      },
    });
  }, []);

  const serverCall = async (values) => {
    await handleusersignp({
      variables: {
        input: {
          ...values,
          lang: localStorage.getItem('language'),
          clientDomain: window.location.hostname,
          inviteId: validInviteId || '',
          partnerInvite: validPartnerId || '',
          headquarter: values.address,
          // phoneNumber: values.phoneNumber.toString(),
          phoneNumber: values.phoneNumber,
          headquarterAddress: [
            {
              zip: orgZip || '0',
              state: orgState || '-',
              long: orgLong || '00',
              lat: orgLat || '00',
              country: orgCountry || '-',
              city: orgCity || '-',
              addressLine2: '-',
              addressLine1: values.address || '-',
            },
          ],
        },
      },
    }).then((res) => {
      setTimeout(() => {
        window.location = '/login';
      }, 3000);
      toast.success(res.data.userSignUp.message); // We will need this line
      toast.success(t2('register.success.accountcreated'));
      toast.info(t2('register.info.emailverification'));
    });
  };

  const params = new URLSearchParams(window.location.search);
  const inviteId = Object.fromEntries(params.entries());
  useEffect(() => {
    if (inviteId?.id) {
      fetchInvite({
        variables: {
          input: inviteId.id,
        },
        onCompleted: (res) => {
          setValidInviteId(inviteId.id);

          if (res?.fetchInvitationDetails) {
            setResultData(res?.fetchInvitationDetails);
          }
        },
        onError: () => {
          toast.error(t2('register.error.invalidinvitation'));
        },
      });
    } else if (inviteId?.partnerInvite) {
      handlePartnerInvitation({
        variables: {
          inviteId: inviteId.partnerInvite,
        },
        onCompleted: (res) => {
          setValidPartnerId(inviteId.partnerInvite);
          setResultData(res?.fetchPartnerInvitationDetails?.data);
        },
        onError: () => {
          toast.error(t2('register.error.invalidinvitation'));
        },
      });
    }

    return () => {};
  }, []);

  const formik = useFormik({
    initialValues: {
      email: resultData?.email || resultData?.inviteeEmail || '',
      password: '',
      confirmPassword: '',
      firstName: resultData?.firstName || '',
      lastName: resultData?.lastName || '',
      timezone: '',
      country: '',
      phoneNumber: '',
      address: '',
      orgName: '',
    },
    validationSchema: Yup.object({
      firstName: Yup.string()
        .trim()
        .required(t('registerscreen.emptymessage.firstname'))
        .min(2, t('registerscreen.checks.nameshort'))
        .max(50, t('registerscreen.checks.namelong')),
      lastName: Yup.string()
        .trim()
        .required(t('registerscreen.emptymessage.lastname')),
      email: Yup.string()
        .email(t('registerscreen.checks.email'))
        .required(t('registerscreen.emptymessage.email')),
      phoneNumber: Yup.string().matches(
        phoneRegExp,
        t('registerscreen.checks.phonenumber')
      ),
      password: Yup.string()
        .matches(
          /^(?=.*\d)(?=.*[!@#$%^&*()])(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
          t('registerscreen.checks.password')
        )
        .required(t('registerscreen.emptymessage.password'))
        .min(
          8
          // 'Password must include 8 characters, a numerical value,  a special, a lower and an upper case character.'
        ),

      confirmPassword: Yup.string()
        .required(t('registerscreen.emptymessage.confirmpassword'))
        .oneOf(
          [Yup.ref('password'), null],
          t('registerscreen.checks.confirmpassword')
        ),
      country: Yup.string().required(t('registerscreen.emptymessage.country')),
      address: Yup.string()
        .trim()
        .required(t('registerscreen.emptymessage.address')),
      orgName: Yup.string()
        .required(t('registerscreen.emptymessage.companyname'))
        .trim(),
      // phoneNumber: Yup.string().matches(
      //   /^\+\d{1,4}(\s?[-]?\d{1,4}[-]?\d{1,4}){1,3}$/,
      //   'phone number is invalid.'
      //   //  Valid examples are +1 555-123-4567, +917045000000'
      // ),
      // timezone: Yup.string().required('Please select country'),
    }),
    onSubmit: (values) => {
      const payload = {
        ...values,
        appId: {
          name: 'My Way',
          appKey: '1',
        },
      };
      serverCall(payload);
    },
    enableReinitialize: true,
  });

  const fetchTimezoneC = (e) => {
    fetchTimezone({
      variables: {
        input: e || '',
      },
      onCompleted: (zones) => {
        if (zones.fetchTimezone.message !== 'record not found') {
          setInitialState(false);
          setDisable(true);
          noZoneOptions.splice(0, noZoneOptions.length);
          ZoneOptions = zones.fetchTimezone.data[0].TimeZones.map((zone) => {
            fetchTimezoneCountry = {
              label: zone,
              value: zone,
            };
            return fetchTimezoneCountry;
          });
          setZoneOpt(ZoneOptions);

          if (ZoneOptions.length === 1) {
            formik.setFieldValue('timezone', ZoneOptions[0].value);
          }
        }
      },
      onError: () => {
        noZoneOptions.push({
          label: 'No Time Zone Found',
          value: 'No Time Zone Found',
        });
        // setNoTimeZone(false);
        setDisable(false);
        // if (res === 'record not found') {
        // }
        // return fetchTimezoneCountry;
      },
    });
  };
  const companySearchOnEmail = (emailId) => {
    if (emailId) {
      formik.setFieldValue('email', emailId);
      setOriginGeoCode('');
      formik.setFieldValue('orgName', '');
      setOrigin('');
      formik.setFieldValue('address', '');
      setOrginOptions([]);
      setIsDomain(false);
      try {
        fetchCompanyName({
          variables: {
            input: {
              email: emailId,
            },
          },
          onCompleted: (res) => {
            if (res?.fetchOrgDetailsWithHeadQuarter?.data) {
              setOrginOptions([
                {
                  label: res?.fetchOrgDetailsWithHeadQuarter?.data?.orgAddress,
                  value: res?.fetchOrgDetailsWithHeadQuarter?.data?.orgAddress,
                },
              ]);
              formik.setFieldValue(
                'orgName',
                res?.fetchOrgDetailsWithHeadQuarter?.data?.orgName
              );
              setOrigin(
                res?.fetchOrgDetailsWithHeadQuarter?.data?.orgAddress || ''
              );
              formik.setFieldValue(
                'address',
                res?.fetchOrgDetailsWithHeadQuarter?.data?.orgAddress
              );
              formik.setFieldValue(
                'country',
                res?.fetchOrgDetailsWithHeadQuarter?.data
                  ?.headquarterAddress?.[0]?.country
                // res?.fetchOrgDetailsWithHeadQuarter?.data?.orgName
              );
              // formik.validateField('orgName');
              fetchTimezoneC(
                res?.fetchOrgDetailsWithHeadQuarter?.data
                  ?.headquarterAddress?.[0]?.country
              );
              setIsDomain(true);
              // formik.setFieldValue('timezone', null);
            }
            setTimeout(() => {
              formik.validateForm();
            }, 0);
          },
        });
      } catch (error) {
        setIsDomain(false);
        setOriginGeoCode('');
        setOrginOptions([]);
        formik.setFieldValue('orgName', '');
        setOrigin('');
        formik.setFieldValue('address', '');
      }
    }
  };
  useEffect(() => {
    if (formik.getFieldProps('email')?.value) {
      companySearchOnEmail(formik.getFieldProps('email').value);
    }
  }, [formik.getFieldProps('email').value]);
  // const togglePopover = (e) => {
  //   setEventValue(e.target);
  // };
  // const handleClose = () => {
  //   setEventValue(null);
  // };
  // useEffect(() => {
  //   if (
  //     { ...formik.getFieldProps('country') }.value &&
  //     options &&
  //     options.length > 1
  //   ) {
  //     fetchTimezoneC(
  //       options &&
  //         options.length > 1 &&
  //         options.filter(
  //           (item) =>
  //             item.value === `${{ ...formik.getFieldProps('country') }.value}`
  //         )[0]
  //     );
  //   }
  // }, [{ ...formik.getFieldProps('country') }.value, options]);
  return (
    <>
      {signupLoading && (
        <div className="login-loader">
          <GlobalLoader newLoad={true} />
        </div>
      )}
      <form
        onSubmit={formik.handleSubmit}
        enableReinitialize={true}
        className="signup-container"
        autoComplete="off"
      >
        <div className="login-group-container">
          <TextBoxIcon
            required
            id="firstName"
            name="firstName"
            label={t('registerscreen.fields.firstname')}
            type="text"
            data-cy="firstName-register-page"
            {...formik.getFieldProps('firstName')}
            errorMessage={
              formik.touched.firstName && formik.errors.firstName
                ? formik.errors.firstName
                : null
            }
          >
            <NameIcon color="#A6A6A6" />
          </TextBoxIcon>

          {/* {formik.touched.firstName && formik.errors.firstName ? (
            <p className="error-message">{formik.errors.firstName}</p>
          ) : null} */}
        </div>
        <div className="login-group-container">
          <TextBoxIcon
            required
            id="lastName"
            name="lastName"
            label={t('registerscreen.fields.lastname')}
            type="text"
            data-cy="lastName-register-page"
            {...formik.getFieldProps('lastName')}
            errorMessage={
              formik.touched.lastName && formik.errors.lastName
                ? formik.errors.lastName
                : null
            }
          >
            <NameIcon color="#A6A6A6" />
          </TextBoxIcon>

          {/* {formik.touched.lastName && formik.errors.lastName ? (
            <p className="error-message">{formik.errors.lastName}</p>
          ) : null} */}
        </div>
        <div className="login-group-container">
          <TextBoxIcon
            required
            id="email"
            name="email"
            data-cy="email-register-page"
            label={t('registerscreen.fields.email')}
            onBlur={(e) => {
              companySearchOnEmail(e.target.value);
            }}
            type="email"
            // defaultValue={formik.getFieldProps('email')?.value}
            {...formik.getFieldProps('email')}
            disabled={inviteId?.id || inviteId?.partnerInvite}
            errorMessage={
              formik.touched.email && formik.errors.email
                ? formik.errors.email
                : null
            }
          >
            <EmailIcon color="#A6A6A6" />
          </TextBoxIcon>
        </div>
        <div className="login-group-container">
          <TextBoxIcon
            id="phoneNumber"
            name="phoneNumber"
            data-cy="phoneNumber-register-page"
            label={t('registerscreen.fields.phonenumber')}
            type="text"
            {...formik.getFieldProps('phoneNumber')}
            onKeyDown={(e) => {
              getDigitsForPhoneNumber(e);
            }}
            errorMessage={
              formik.touched.phoneNumber && formik.errors.phoneNumber
                ? formik.errors.phoneNumber
                : null
            }
          >
            <PhoneIcon color="#A6A6A6" />
          </TextBoxIcon>
          {/* {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
            <p className="error-message">{formik.errors.phoneNumber}</p>
          ) : null} */}
        </div>
        <div className="login-group-container">
          <TextBoxIcon
            required
            id="password"
            name="password"
            data-cy="password-register-page"
            label={t('registerscreen.fields.password')}
            type="password"
            infoMessage={t('registerscreen.checks.passwordInfo')}
            {...formik.getFieldProps('password')}
            errorMessage={
              formik.touched.password && formik.errors.password
                ? formik.errors.password
                : null
            }
          >
            <PasswordIcon color="#A6A6A6" />
          </TextBoxIcon>
          {/* {formik.errors.password && ( */}
          {/* <span className="textbox-info">
            <InfoIcon />
            <span className="info-message">
              {t('registerscreen.fields.passwordinfo')}
            </span>
          </span> */}
          {/* )} */}

          {/* {formik.touched.password && formik.errors.password ? (
            <p className="error-message">{formik.errors.password}</p>
          ) : null} */}
        </div>
        <div className="login-group-container">
          {/* <div className="login-group"> */}
          {/* <span className="group-icon">
            <PasswordIcon color="#A6A6A6" />
          </span> */}
          <TextBoxIcon
            required
            id="confirmPassword"
            name="confirmPassword"
            data-cy="confirmPassword-register-page"
            label={t('registerscreen.fields.confirmpassword')}
            type="password"
            errorMessage={
              formik.touched.confirmPassword && formik.errors.confirmPassword
                ? formik.errors.confirmPassword
                : null
            }
            {...formik.getFieldProps('confirmPassword')}
          >
            <PasswordIcon color="#A6A6A6" />
          </TextBoxIcon>
          {/* </div> */}
          {/* {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
            <p className="error-message">{formik.errors.confirmPassword}</p>
          ) : null} */}
        </div>
        <div className="login-group-container">
          {/* <div className="login-group"> */}
          {/* <span className="group-icon">
              <GlobeIcon color="#A6A6A6" />
            </span> */}
          <div className="text-box">
            <AdvanceSelectLabelIcon
              required
              mBottom="1.597vw"
              id="country"
              data={options}
              name="country"
              label={t('registerscreen.fields.country')}
              defaultValue={
                options &&
                options.length > 0 &&
                options.filter(
                  (item) =>
                    item.value ===
                    `${{ ...formik.getFieldProps('country') }.value}`
                )[0]
              }
              onChange={(e) => {
                formik.setFieldValue('country', e.target.value);
                fetchTimezoneC(e.target.value);
                formik.setFieldValue('timezone', null);
              }}
              onRemoveValue={() => {
                formik.setFieldValue('country', null);
                formik.setFieldValue('timezone', null);
                setZoneOpt(null);
              }}
              errorMessage={
                formik.touched.country && formik.errors.country
                  ? formik.errors.country
                  : null
              }
            >
              <GlobeIcon color="#A6A6A6" />
            </AdvanceSelectLabelIcon>
          </div>
        </div>
        <div className="login-group-container">
          {initialState && noZoneOptions.length === 0 && (
            <div className="text-box">
              <TextBoxIcon
                disabled={!disable}
                data-cy="timeZone-register-page"
                label={t('registerscreen.fields.timezone')}
              >
                <TimezoneIcon
                  color="#A6A6A6"
                  width="1.389vw"
                  height="1.389vw"
                />
              </TextBoxIcon>
            </div>
          )}
          {noZoneOptions.length > 0 && (
            <div className="text-box">
              <TextBoxIcon
                disabled={!disable}
                label={t('registerscreen.fields.notimezonefound')}
              >
                <TimezoneIcon
                  color="#A6A6A6"
                  width="1.389vw"
                  height="1.389vw"
                />
              </TextBoxIcon>
            </div>
          )}
          {disable && noZoneOptions.length === 0 && (
            <div className="text-box">
              <AdvanceSelectLabelIcon
                id="timezone"
                mBottom="1.597vw"
                name="timezone"
                label={t('registerscreen.fields.timezone')}
                data={zoneOpt}
                onChange={(e) => {
                  formik.setFieldValue('timezone', e.target.value);
                }}
                defaultValue={
                  zoneOpt !== null
                    ? zoneOpt &&
                      zoneOpt?.length > 0 &&
                      zoneOpt?.length === 1 &&
                      zoneOpt?.filter(
                        (item) =>
                          item?.value ===
                          `${{ ...formik.getFieldProps('timezone') }.value}`
                      )[0]
                    : { label: '', value: '' }
                }
              >
                <TimezoneIcon color="#A6A6A6" />
              </AdvanceSelectLabelIcon>
            </div>
          )}
          {/* </div> */}
        </div>
        <div className="login-group-container w-100">
          {/* <div className="login-group">
            <span className="group-icon">
              <MyCompany color="#A6A6A6" />
            </span> */}
          <TextBoxIcon
            required
            id="orgName"
            name="orgName"
            label={t('registerscreen.fields.companyname')}
            type="text"
            data-cy="orgName-register-page"
            disabled={isDomain}
            {...formik.getFieldProps('orgName')}
            errorMessage={
              formik.touched.orgName && formik.errors.orgName
                ? formik.errors.orgName
                : null
            }
          >
            <MyCompany color="#A6A6A6" />
          </TextBoxIcon>
          {/* </div> */}
          {/* {formik.touched.orgName && formik.errors.orgName ? (
            <p className="error-message">{formik.errors.orgName}</p>
          ) : null} */}
        </div>
        <div className="login-group-container w-100 mb-20">
          <AddSupplierAddress
            setOrginOptions={(e) => {
              setOrginOptions(e);
            }}
            required
            originOptions={originOptions}
            origin={origin}
            setOrigin={(data) => {
              setOrigin(data);
              formik.setFieldValue('address', data);
            }}
            originGeoCode={originGeoCode}
            setOriginGeoCode={(e) => setOriginGeoCode(e)}
            defaultLat={orgLat || 20.5937}
            defaultLong={orgLong || 78.9629}
            initialGeoCordBool={true}
            initialGeoCord={origin}
            sendautoCountryValue={(e) => setOrgCountry(e)}
            sendautoCity={(e) => {
              setOrgCity(e);
            }}
            sendLat={(e) => {
              setOrgLat(e);
            }}
            sendLong={(e) => {
              setOrgLong(e);
            }}
            sendautoZip={(e) => {
              setOrgZip(e);
            }}
            sendautoStateValue={(e) => {
              setOrgState(e);
            }}
            disabled={isDomain}
            // noDataMessage={t('registerscreen.haveaccount')}
            placeholder={t('registerscreen.fields.address')}
            errorMessage={
              formik.touched.address && formik.errors.address
                ? formik.errors.address
                : null
            }
            mode="material"
            label={t('registerscreen.fields.headquarter')}
          >
            <LocationOutlined color="#A6A6A6" />
          </AddSupplierAddress>
        </div>
        <div
          className="login-group btn-block"
          data-cy="signupbtn-register-page"
        >
          <button type="submit" className="submit">
            {t('registerscreen.signup')}
          </button>
        </div>
        <div
          className="login-group have-account"
          data-cy="already-account-text"
        >
          <p>{t('registerscreen.haveaccount')}</p>
        </div>
        <div className="login-group btn-block" data-cy="loginbtn-register-page">
          <Link type="button" className="other" to="/login">
            {t('registerscreen.login')}
          </Link>
        </div>
      </form>
    </>
  );
}

SignUp.propTypes = {
  t: PropTypes.func,
};
SignUp.defaultProps = {
  t: () => {},
};
export default SignUp;
