import React from 'react';
import Header from './header';
import Footer from './footer';

function PrivacyPolicy() {
  return (
    <div className="static-body">
      <Header link="/terms-and-conditions" ancorText="Terms & Conditions" />
      <div class="body">
        <div class="container">
          <h1 class="text-uppercase">Quloi Privacy Policy</h1>
          <p>Last Reviewed : 02 January, 2024</p>
          <br />
          <br />
          <h3>Introduction</h3>
          <p class="text-justify">
            QULOI, Inc. (&quot;<strong>Quloi</strong>,&quot; &quot;
            <strong>we</strong>,&quot; &quot;
            <strong>our</strong>,&quot; or &quot;<strong>us</strong>&quot;)
            respects your privacy and is committed to protecting it through our
            compliance with this policy. For purposes of this Privacy Policy,
            &quot;you&quot; and &quot;your&quot; means you as the user of the
            Services (as defined below).
          </p>

          <p class="text-justify">
            This policy describes the types of information we may collect from
            you or that you may provide when you register with, access, or use
            the Quloi platform (the &quot;<strong>Platform</strong>&quot;) and
            our related websites, services, applications, products, and content
            (collectively, the &quot;<strong>Services</strong>&quot;) and our
            practices for collecting, using, maintaining, protecting, and
            disclosing that information.
          </p>
          <p class="text-justify">
            This policy applies to information we collect:
          </p>
          <ul class="privacy">
            <li>In connection with your use of the Services.</li>
            <li>
              In email, text, and other electronic messages between you and the
              Services and/or Quloi.
            </li>
          </ul>
          <p class="text-justify">
            It does not apply to information collected by:
          </p>
          <ul class="privacy">
            <li>
              Us offline or through any other means, including on any other
              website operated by Quloi or any third party; or
            </li>
            <li>
              Any third party, including through any application or content
              (including advertising) that may link to or be accessible from or
              through the Services.
            </li>
          </ul>
          <p class="text-justify">
            Please read this policy carefully to understand our policies and
            practices regarding your information and how we will treat it. If
            you do not agree with our policies and practices, your choice is to
            not use our Services. By accessing or using the Services, you agree
            to this Privacy Policy. This policy may change from time to time
            (see &quot;Changes to Our Privacy Policy&quot;). Your continued use
            of the Services after we make changes is deemed to be acceptance of
            those changes, so please check the policy periodically for updates.
          </p>
          <br />
          <br />
          <h3>Children Under the Age of 18</h3>
          <p class="text-justify">
            Our Services are not intended for users under 18 years of age (or
            the age of majority where you reside). No one under age 18 (or the
            age of majority where you reside) may provide any information,
            including any personal information, to or on the Services. We do not
            knowingly collect personal information from children under 18 (or
            the age of majority where you reside). If you are under 18 (or the
            age of majority where you reside), do not use or provide any
            information on the Services or through any of its features, register
            on the Services, or provide any information about yourself to us,
            including your name, address, telephone number, email address, or
            any screen name or user name you may use. If we learn we have
            collected or received personal information from a child under 18 (or
            the age of majority where you reside), we will delete that
            information. If you believe we might have any information from or
            about a child under 18 (or the age of majority where such child
            resides), please contact us at privacy@quloi.com.
          </p>
          <br />
          <h3>Changes to Our Privacy Policy</h3>
          <p class="text-justify">
            It is our policy to post any changes we make to our Privacy Policy
            on this page with a notice that the Privacy Policy has been updated
            on the Services home page. If we make material changes to how we
            treat our users&apos; personal information, we will notify you by
            email to the email address specified in your account or through a
            notice on the Services home page. The date the Privacy Policy was
            last revised is identified at the top of the page. You are
            responsible for ensuring we have an up-to-date active and
            deliverable email address for you, and for periodically visiting our
            Services and this Privacy Policy to check for any changes.
          </p>
          <br />
          <h3>Information We Collect About You and How We Collect It</h3>
          <h3>
            We collect several types of information from and about users of our
            Services, including information:
          </h3>
          <ul class="privacy text-justify">
            <li>
              That identifies, relates to, describes, references, is reasonably
              capable of being associated with, or could reasonably be linked
              with, directly or indirectly, you, your household, or your device
              (&quot;<strong>personal information</strong>&quot;);
            </li>
            <li>
              That is about you but individually does not identify you; and/or
            </li>
            <li>
              About your internet connection, the equipment and personal
              device(s) you use to access our Services, and usage details.
            </li>
          </ul>
          <h3>
            Please note, however, that personal information does not include:
          </h3>
          <ul class="privacy">
            <li>Publicly available information from government records; and</li>
            <li>Deidentified or aggregated consumer information.</li>
          </ul>
          <h3>
            In particular, our Services collects the following categories of
            personal information:
          </h3>
          <ul class="privacy">
            <li data-text="Identifiers">
              - first and last name, email address, postal address, online
              identifier, phone number, Internet Protocol address, account name
              or other User ID, alias, or other similar identifiers
            </li>
            <li data-text="Internet or other similar network activity">
              - browsing history, search history, information on a
              consumer&apos;s interaction with the Services, advertisements, or
              third party websites
            </li>
            <li data-text="Geolocation data">
              - physical location or movements, as well as your time zone
            </li>
          </ul>
          <ul>
            <li>
              Directly from you when you provide it to us by creating an account
              or using the Services.
            </li>
            <li>
              Automatically as you navigate through the Services. Information
              collected automatically may include usage details, IP addresses,
              and information collected through cookies and other tracking
              technologies. If you grant us access, we may collect information
              contained in the messages you send through our Platform and
              information from your phone book on your personal device(s). We do
              not collect personal information from the contents of one-to-one,
              private messaging that you may engage in with other users of the
              Platform, however, we collect information about the sender and
              recipient, the number of messages sent and when, and other
              statistical information that may be related to your personal
              information.
            </li>
            <li>From third parties, for example, our business partners.</li>
          </ul>
          <p class="text-justify">
            Where information is received from a third party, such data
            collection is subject to that third party&apos;s privacy policy. The
            terms of our Privacy Policy do not apply on any third-party website
            or with respect to their information collection practices. Except as
            otherwise required under the relevant laws and regulations, we are
            not responsible for the privacy practices of these third parties and
            their websites. We encourage you to read their respective Terms of
            Use and privacy policies if you wish to utilize these websites so
            that you are informed of their practices.
          </p>
          <h3>Information You Provide to Us</h3>
          <h3>
            The information we collect on or through our Services may include:
          </h3>
          <ul class="privacy text-justify">
            <li>
              Registration information, such as username and password, language,
              email address, phone number, country, and time zone.
            </li>
            <li>Profile information, such as first and last name.</li>
            <li>
              Payment information, including payment card numbers or other
              third-party payment information where required for the purpose of
              payment.
            </li>
            <li>Your opt-in choices and communication preferences.</li>
            <li>
              Information to verify an account such as proof of identity or age.
            </li>
            <li>Information in correspondence you send to us.</li>
            <li>
              Information you share through surveys or your participation in
              challenges, sweepstakes, or contests.
            </li>
          </ul>
          <h3>
            Information We Collect Through Automatic Data Collection
            Technologies
          </h3>
          <p class="text-justify">
            As you navigate through and interact with our Services, we may use
            automatic data collection technologies to collect certain
            information about your equipment, browsing actions, and patterns,
            including:
          </p>
          <ul class="privacy text-justify">
            <li data-text="Usage Details. ">
              When you access and use the Services, we may automatically collect
              certain details of your access to and use of the Services,
              including traffic data, location data, logs, and other
              communication data and the resources that you access and use on or
              through the Services.
            </li>
            <li data-text="Device Information.">
              We may collect information about your mobile device and internet
              connection, including the device&apos;s unique device identifier,
              IP address, operating system, browser type, and mobile network
              information.
            </li>
            <li data-text="Stored Information and Files.">
              The Services also may access metadata and other information
              associated with other files uploaded to the Platform.
            </li>
            <li data-text="Location Information.">
              The Platform collects real-time information about the location of
              your device, including location information based on your IP
              address or elections.
            </li>
          </ul>
          <p class="text-justify">
            We also may use these technologies to collect information about your
            online activities over time and across third-party websites or other
            online services (behavioral tracking). See the section titled &quot;
            <strong>
              Choices About How We Use and Disclose Your Information
            </strong>
            &quot; for information on how you can opt out of behavioral tracking
            on the Services and how we respond to web browser signals and other
            mechanisms that enable consumers to exercise choice about behavioral
            tracking.
          </p>
          <p class="text-justify">
            The information we collect automatically does include statistical
            information and personal information. The statistical information
            collected automatically helps us to improve our Services and to
            deliver a better and more personalized service, including by
            enabling us to:
          </p>
          <ul class="privacy text-justify">
            <li>Estimate our audience size and usage patterns.</li>
            <li>
              Store information about your preferences, allowing us to customize
              our Services according to your individual interests.
            </li>
            <li>Speed up your searches.</li>
            <li>ecognize you when you return to our Services.</li>
          </ul>
          <h3>
            The technologies we use for this automatic data collection may
            include:
          </h3>
          <ul class="privacy text-justify">
            <li data-text="Cookies (or browser cookies).">
              A cookie is a small file placed on the hard drive of your
              computer. You may refuse to accept browser cookies by activating
              the appropriate setting on your browser. However, if you select
              this setting you may be unable to access certain parts of our
              Services. Unless you have adjusted your browser setting so that it
              will refuse cookies, our system will issue cookies when you direct
              your browser to our Services.
            </li>
            <li data-text="Web Beacons.">
              Pages of our Services and our emails may contain small electronic
              files known as web beacons (also referred to as clear gifs, pixel
              tags, and single-pixel gifs) that permit Quloi, for example, to
              count users who have visited those pages or opened an email and
              for other related website statistics (for example, recording the
              popularity of certain website content and verifying system and
              server integrity).
            </li>
            <li data-text="Flash Cookies. ">
              Certain features of our Services may use local stored objects (or
              Flash cookies) to collect and store information about your
              preferences and navigation to, from, and on our Website. Flash
              cookies are not managed by the same browser settings as are used
              for browser cookies. For information about managing your privacy
              and security settings for Flash cookies, see the section titled
              &quot;
              <strong>
                Choices About How We Use and Disclose Your Information.
              </strong>
              &quot;
            </li>
          </ul>
          <h3>Information We Receive from Third Parties</h3>
          <p class="text-justify">
            We may receive the information described in this Privacy Policy from
            other sources, such as:
          </p>
          <ul class="privacy text-justify">
            <li>
              Third-Party Services. We may collect information about you from
              third-party services, such as advertising partners, data
              providers, and analytics providers.
            </li>
            <li>
              Other Users of the Platform. Sometimes other users of the Platform
              may provide us information about you, including through customer
              service inquiries.
            </li>
            <li>
              Other Sources. We may collect information about you from other
              publicly available sources.
            </li>
          </ul>
          <h3>Third-Party Use of Cookies and Other Tracking Technologies</h3>
          <p class="text-justify">
            Some content or applications, including advertisements, on the
            Services are served by third-parties, including advertisers, ad
            networks and servers, content providers, and application providers.
            These third parties may use cookies alone or in conjunction with web
            beacons or other tracking technologies to collect information about
            you when you use our Services. The information they collect may be
            associated with your personal information or they may collect
            information, including personal information, about your online
            activities over time and across different websites and other online
            services. They may use this information to provide you with
            interest-based (behavioral) advertising or other targeted content.
          </p>
          <p class="text-justify">
            We do not control these third parties&apos; tracking technologies or
            how they may be used. If you have any questions about an
            advertisement or other targeted content, you should contact the
            responsible provider directly. For information about how you can opt
            out of receiving targeted advertising from many providers, see the
            section titled &quot;Choices About How We Use and Disclose Your
            Information.&quot;
          </p>
          <p class="text-justify">
            Please note that our Services, to the extent they are accessed
            through a website, are not configured to accept and respond to web
            browser Do Not Track (DNT) signals. As such, if you would like to
            opt-out of certain specific cookies or automatic tracking
            technologies, you will need to do so by exercising one of the other
            methods described in this Privacy Policy.
          </p>
          <br />
          <br />
          <h3>How We Use Your Information</h3>
          <h3>
            We use information that we collect about you or that you provide to
            us, including any personal information:
          </h3>
          <ul class="privacy text-justify">
            <li>To present our Services and its contents to you.</li>
            <li>
              To provide you with information, products, or services that you
              request from us, including to customize the content that you see
              when you use the Services.
            </li>
            <li>
              To send promotional materials from us or on behalf of our
              affiliates, trusted third parties, and marketing partners.
            </li>
            <li>
              To carry out our obligations and enforce our rights arising from
              any contracts entered into between you and us, including for
              billing and collection.
            </li>
            <li>
              To improve and develop our Platform and conduct product
              development.
            </li>
            <li>
              To measure and understand the effectiveness of the advertising we
              serve to you and others and to deliver advertising.
            </li>
            <li>To make suggestions and provide a customized ad experience.</li>
            <li>
              To use User Content as part of our advertising and marketing
              campaigns to promote the Platform.
            </li>
            <li>
              To understand how you use the Platform, including across your
              devices.
            </li>
            <li>
              To infer additional information about you, such as your age,
              gender, and interests.
            </li>
            <li>
              To help us detect abuse, fraud, and illegal activity on the
              Platform.
            </li>
            <li>
              Consistent with your permissions, to provide you with
              location-based services, such as advertising and other
              personalized content.
            </li>
            <li>
              To combine all the information, we collect or receive about you
              for any of the foregoing purposes.
            </li>
            <li>
              To notify you about changes to our Services or any products or
              services we offer or provide through it.
            </li>
            <li>
              In any other way we may describe when you provide the information.
            </li>
            <li>For any other purpose with your consent.</li>
          </ul>
          <h3>Disclosure of Your Information</h3>
          <p class="text-justify">
            We are committed to maintaining your trust, and while Quloi does not
            sell personal information to third parties, we want you to
            understand when and with whom we may share the information we
            collect for business purposes.
          </p>
          <p class="text-justify">
            We may disclose aggregated information about our users, and
            information that does not identify any individual, without
            restriction.
          </p>
          <h3>
            We may disclose personal information that we collect or you provide
            as described in this Privacy Policy:
          </h3>
          <ul class="privacy text-justify">
            <li>To our subsidiaries and affiliates.</li>
            <li>
              To other users of the Services (subject to the settings you have
              made with respect to posts, contributions, chats, and other
              communications within the Platform).
            </li>
            <li>
              To contractors, service providers, and other third parties we use
              to support our business and who are bound by contractual
              obligations to keep personal information confidential and use it
              only for the purposes for which we disclose it to them. For
              example, we may share your personal information with payment
              processors and transaction fulfillment providers in order to
              fulfill the services you have requested or to other users of the
              Platform to provide the Services as you have requested.
            </li>
            <li>
              To a buyer or other successor in the event of a merger,
              divestiture, restructuring, reorganization, dissolution, or other
              sale or transfer of some or all of QULOI, Inc.&apos;s assets,
              whether as a going concern or as part of bankruptcy, liquidation,
              or similar proceeding, in which personal information held by
              QULOI, Inc. about our Services users is among the assets
              transferred.
            </li>
            <li>To fulfill the purpose for which you provide it.</li>
            <li>
              For any other purpose disclosed by us when you provide the
              information.
            </li>
            <li>With your consent.</li>
          </ul>
          <h3>We may also disclose your personal information:</h3>
          <ul class="privacy text-justify">
            <li>
              To comply with any court order, law, or legal process, including
              to respond to any government or regulatory request.
            </li>
            <li>
              To enforce or apply our Terms of Use and other agreements,
              including for billing and collection purposes.
            </li>
            <li>
              If we believe disclosure is necessary or appropriate to protect
              the rights, property, or safety of QULOI, Inc., our customers, or
              others.
            </li>
          </ul>
          <h3>
            The categories of personal information we may disclose include:
          </h3>
          <ul class="privacy text-justify">
            <li>Identifiers</li>
            <li>Internet or other similar network activity</li>
            <li>Geolocation data</li>
          </ul>
          <p class="text-justify">
            If you choose to engage in public activities on the Platform, you
            should be aware that any information you share may be read,
            collected, or used by other users. You should use caution in
            disclosing personal information while using the Platform. We are not
            responsible for the information you choose to submit.
          </p>
          <p class="text-justify">
            Please be aware that messages sent to other users of the Platform
            will be accessible by those users and that we are not responsible
            for the manner in which those users use or disclose messages.
          </p>
          <br />
          <br />
          <h3>Choices About How We Use and Disclose Your Information</h3>
          <p class="text-justify">
            We strive to provide you with choices regarding the personal
            information you provide to us. We have created mechanisms to provide
            you with the following control over your information:
          </p>
          <ul class="privacy text-justify">
            <li data-text="Tracking Technologies and Advertising. ">
              You can set your browser to refuse all or some browser cookies, or
              to alert you when cookies are being sent. To learn how you can
              manage your Flash cookie settings, visit the Flash player settings
              page on Adobe&apos;s website. If you disable or refuse cookies,
              please note that some parts of the Services may then be
              inaccessible or not function properly.
            </li>
            <li data-text="Location Information.">
              You can choose whether or not to allow the Platform to collect and
              use real-time information about your device&apos;s location
              through the device&apos;s security settings. If you block the use
              of location information, some parts of the Platform may become
              inaccessible or not function properly.
            </li>
            <li data-text="Promotional Offers from Quloi. ">
              If you do not wish to have your email address used by Quloi to
              promote our products or services, you are not required to sign-up
              to receive our newsletter. If you have subscribed to our
              newsletter, you can opt-out by using the &quot;Unsubscribe&quot;
              (or other similar) link in the footer of each newsletter email you
              receive or by emailing us at privacy@quloi.com. This opt-out does
              not apply to information provided to Quloi as a result of a
              purchase, product service experience or other transactions.
            </li>
            <li data-text="Targeted Advertising by Quloi.">
              If you do not want us to use information that we collect or that
              you provide to us to deliver advertisements according to our
              advertisers&apos; target-audience preferences, you can opt-out by
              adjusting your user advertising preferences in your account
              profile by checking or unchecking the relevant boxes or by sending
              us an email stating your request to privacy@quloi.com.
            </li>
            <li data-text="Disclosure of Your Information for Third-Party Advertising and Marketing.">
              If you do not want us to share your personal information with
              unaffiliated or non-agent third parties for advertising and
              marketing purposes, you can opt-out by logging into the Platform
              and adjusting your user preferences in your account profile by
              checking or unchecking the relevant boxes or by sending us an
              email stating your request to privacy@quloi.com.
            </li>
          </ul>
          <p class="text-justify">
            We do not control third parties&apos; collection or use of your
            information to serve interest-based advertising. However, these
            third parties may provide you with ways to choose not to have your
            information collected or used in this way. You can opt out of
            receiving targeted ads from members of the Network Advertising
            Initiative (&quot;<strong>NAI</strong>&quot;) on the NAI&apos;s
            website.
          </p>
          <p class="text-justify">
            Residents of certain states, such as California and Nevada may have
            additional personal information rights and choices. Please see the
            section titled &quot;<strong>Your State Privacy Rights</strong>
            &quot; for more information.
          </p>
          <br />
          <h3>Accessing and Correcting Your Information</h3>
          <p class="text-justify">
            You can review and change your personal information by sending us an
            email at privacy@quloi.com to request access to, correct, or delete
            any personal information that you have provided to us. We may not
            accommodate a request to change information if we believe the change
            would violate any law or legal requirement or cause the information
            to be incorrect.
          </p>
          <br />
          <br />
          <h3>Your State Privacy Rights</h3>
          <p class="text-justify">
            State consumer privacy laws may provide their residents with
            additional rights regarding our use of their personal information.
            California&apos;s &quot;Shine the Light&quot; law (Civil Code
            Section § 1798.83) permits users of our Platform that are California
            residents to request certain information regarding our disclosure of
            personal information to third parties for their direct marketing
            purposes. To make such a request, please send an email to
            privacy@quloi.com.
          </p>
          <p class="text-justify">
            Nevada provides its residents with a limited right to opt-out of
            certain personal information sales. Residents who wish to exercise
            this sale opt-out rights may submit a request by email to
            privacy@quloi.com. However, please know we do not currently sell
            data triggering that statute&apos;s opt-out requirements.
          </p>
          <h3>Data Security</h3>
          <p class="text-justify">
            We have implemented technical and organizational precautionary
            measures, in accordance with industry best practices, designed to
            secure your personal information from accidental loss and from
            unauthorized access, use, alteration, disclosure, loss, deletion, or
            destruction. All information you provide to us is stored on our or
            our trusted service providers&apos; secure servers behind firewalls.
          </p>
          <p class="text-justify">
            Unfortunately, the transmission of information via the internet is
            not completely secure. Although we do our best to protect your
            personal information, we cannot guarantee the security of your
            personal information transmitted to our Services. Any transmission
            of personal information is at your own risk. We are not responsible
            for circumvention of any privacy settings or security measures made
            available or incorporated in the Services.
          </p>
          <br />
          <br />
          <h3>Data Retention</h3>
          <p class="text-justify">
            We will only keep your information, including any personal
            information, for as long as necessary to fulfill the purposes for
            which we collected it, to comply with our legal obligations, or to
            enforce our legal rights (or as required so others may enforce their
            legal rights).
          </p>
          <p class="text-justify">
            Except as provided elsewhere in this Privacy Policy, Quloi limits
            access to your personal information, regardless of if it is stored
            electronically or in physical form, to those persons (including
            employees and contractors) in our organization who have a business
            need for such access.
          </p>
          <p class="text-justify">
            Generally, the length of time we keep your information will depend
            on the type of information and the purpose for which we are
            processing it. To determine the appropriate retention period, we
            consider the amount, nature, and sensitivity of the information, the
            potential risk of harm from unauthorized use or disclosure, the
            purposes for which we are processing it and whether we can achieve
            those purposes through other means, and any applicable legal
            requirements.
          </p>
          <p class="text-justify">
            At the end of the appropriate retention period, we will either
            delete your information from our systems completely or anonymize it
            so it can be used without identifying you and so we no longer have
            the ability to re-associate the anonymized information with you in
            the future, such as by aggregating the information.
          </p>
          <p class="text-justify">
            If you would like more information about our retention practices,
            please contact us using the information provided in the &quot;
            <strong>Contact Information</strong>&quot; section below.
          </p>
          <br />
          <br />
          <h3>Contact Information</h3>
          <p class="text-justify">
            To ask questions or comment about this Privacy Policy and our
            privacy practices, or to register a complaint or concern, contact us
            at:
          </p>
          <p class="data-content">
            QULOI, Inc.
            <br />
            ATTN: Data Protection Officer
            <br />
            5 Penn Plaza
            <br />
            Madison Square Garden
            <br />
            23rd Floor
            <br />
            New York, NY 10001
            <br />
            Email: privacy@quloi.com
          </p>
        </div>
      </div>
      <Footer link="/terms-and-conditions" linkText="Terms & Conditions" />
    </div>
  );
}

export default PrivacyPolicy;
