import React from 'react';
import PropTypes from 'prop-types';
import './small-widget.scss';
import DashboardCard from '../../dashboard-card/dashboard-card';
import NewButton from '../../../../Components/button/newButton';
import { MinusRoundIcon } from '../../../../Components/icons/icons';

function SmallWidget({
  label,
  value,
  icon,
  progress,
  valueIcon,
  onClick,
  widgetKey,
  enableRemove,
  enableHover,
  onAddClick,
  period,
  activeCard,
  setActiveCard,
  enableActive,
  onRemoveClick,
  widget,
  t,
}) {
  return (
    <div className="small-widget-container">
      <DashboardCard
        title={label}
        value={value}
        icon={icon}
        progressValue={progress}
        valueIcon={valueIcon}
        onClick={() => {
          onClick(widgetKey, label);
          setActiveCard(label);
        }}
        period={period}
        activeCard={activeCard}
        enableActive={enableActive}
        t={t}
      />
      {enableHover && (
        <button
          className="small-widget-hover"
          type="button"
          onClick={() => onAddClick(widget)}
        >
          <NewButton className="add-widget-button">
            {t('addtodashboard')}{' '}
          </NewButton>
        </button>
      )}
      {enableRemove && (
        <button
          type="button"
          className="card-action-button remove"
          onClick={() => onRemoveClick(widget)}
        >
          <MinusRoundIcon />
        </button>
      )}
    </div>
  );
}

export default SmallWidget;
SmallWidget.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  icon: PropTypes.element.isRequired,
  progress: PropTypes.string,
  valueIcon: PropTypes.element.isRequired,
  onClick: PropTypes.func,
  widgetKey: PropTypes.string,
  enableRemove: PropTypes.bool,
  enableHover: PropTypes.bool,
  onAddClick: PropTypes.func,
  period: PropTypes.string,
  activeCard: PropTypes.string,
  setActiveCard: PropTypes.func,
  enableActive: PropTypes.bool,
  onRemoveClick: PropTypes.func,
  widget: PropTypes.string,
  t: PropTypes.instanceOf(Array),
};
SmallWidget.defaultProps = {
  label: '',
  value: '',
  progress: '',
  onClick: () => {},
  widgetKey: '',
  enableRemove: false,
  enableHover: false,
  onAddClick: () => {},
  period: '',
  activeCard: '',
  setActiveCard: () => {},
  enableActive: false,
  onRemoveClick: () => {},
  widget: '',
  t: [],
};
