import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import './booking.scss';
import { useQuery, useMutation } from '@apollo/client';
import { useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import DialogPanel from '../dialog-panel/dialog-panel';
import DialogPanelBody from '../dialog-panel/dialog-panel-body/dialog-panel-body';
import BesicTable from '../table/basicTable';
import {
  ConfirmEntitiesIcon,
  AddForwarderIcon,
  BagTickIcon,
  BookingDetailsIcon,
  NextIcon,
  PrevIcon,
  ShipmentIcon,
  // TruckTime,
  UploadDocumentIcon,
  MapIcon,
  IncidentsNoData,
  CalenderIcon,
  CreateBookingCurrentIcon,
  CreateBookingCompletedIcon,
  CreateBookingPendingIcon,
  CreateBookingDisabledIcon,
  CreateBookingWarningIcon,
  // CreateBookingCurrentIcon,
  PlusIcon,
} from '../icons/icons';
import Button from '../button/button';
import PanelCard from '../panel-card/panel-card';
import AddShipment from '../add-order-lines/addShipment';
import AddAddress from '../add-order-lines/addAddress';
import ConfirmEntity from '../add-order-lines/confirmEntity';
import SelectForwarder from '../add-order-lines/selectForwarder';
import DatepickerBox from '../datepicker-box/datepicker-box';
import CustomSwitch from '../custom-switch/custom-switch';
import { fetchAddress } from '../../helpers/utils';
import ShipmentProgress from './shipment-progress';
import NoData from '../no-data/no-data';
import useDialog from '../dialog/useDialog';
import Dialog from '../dialog/dialog';
import UploadDocument from '../add-order-lines/uploadDocument';
import PanelCardFooter from '../panel-card/panel-card-footer';
import {
  BULK_BUYER_BOOKING,
  FETCH_BOOKING,
  UPDATE_BOOKING,
} from '../../graphQL/saveBooking';
import { giveProfile } from '../global-notification/notification-list';
import {
  compareDatesEqualWithoutTime,
  getUserTimeZoneDateFormat,
} from '../../helpers/dateHelper';
import Confirmation from '../confirmation/confirmation';
import TextBox from '../text-box/text-box';
import GlobalLoader from '../global-loader/global-loader';
import store from '../../redux/store';
import AccordionPanel from '../accordion-panel/accordion-panel';
import CommentSection from '../comment-section/comment-section';
import AdvancePopover from '../advance-popover/advance-popover';
import ActionButton from '../actions/actions-btn';
import { AddCommentIcon, DownloadZipIcon } from '../icons/comments-icon';
import CommentSectionAccordion from '../comment-section/comment-section-accordion';

function BuyerBooking({
  columns,
  itemsData,
  toggleViewDetailsModal,
  isBulk,
  orderListRefetch,
}) {
  const [t] = useTranslation('manageorders');
  const [t2] = useTranslation('toast');
  const { relation, poId } = useParams();
  const { isShowing: isActivateUploadDislog, toggle: toggleUploadDialog } =
    useDialog();
  const { isShowing: isActivateCommentDialog, toggle: toggleCommentDialog } =
    useDialog();
  const { isShowing: isActivateDislog, toggle: toggleDialog } = useDialog();
  const [updateBookingHandler] = useMutation(UPDATE_BOOKING);
  const [updateBulkBookingHandler] = useMutation(BULK_BUYER_BOOKING);
  const [confirmationMessage, setConfirmationMessage] = useState([]);
  const [confirmationField, setConfirmationField] = useState('');
  const [confirmationData, setConfirmationData] = useState([]);
  const [typeOfMove, setTypeOfMove] = useState({});
  const [supplierTab, setSupplierTab] = useState('booking details');
  const [bookingDetails, setBookingDetails] = useState(false);
  const [shipmentDetails, setShipmentDetails] = useState(false);
  const [confirmEntities, setConfirmEntities] = useState(false);
  const [addForworder, setAddForworder] = useState(false);
  const [disableNxtShip, setDisableNxtShip] = useState(true);
  const [disableLastBtn, setDisableLastBtn] = useState(true);
  const [primaryForwarderList, setPrimaryForwarderList] = useState([]);
  const [pickUpForwarderList, setPickUpForwarderList] = useState([]);
  const [deliveryForwarderList, setDeliveryForwarderList] = useState([]);
  const defaultDate = new Date(new Date().setDate(new Date().getDate() + 1));
  const [primaryDateForm, setPrimaryDateForm] = useState(defaultDate);
  const [pickUpDateForm, setPickUpDateForm] = useState(defaultDate);
  const [deliveryDateForm, setDeliveryDateForm] = useState(defaultDate);
  const [shipmentSwitchTo, setShipmentSwitchTo] = useState(false);
  const [pickUpSwitchTo, setPickUpSwitchTo] = useState(false);
  const [deliverySwitchTo, setDeliverySwitchTo] = useState(false);
  const [update, setUpdate] = useState(false);
  const [updateCountry, setUpdateCountry] = useState(false);
  const [originStatus, setOriginStatus] = useState(false);
  const [destinationStatus, setDestinationStatus] = useState(false);
  const [isOriginDoor, setIsOriginDoor] = useState(false);
  const [isDestinationDoor, setIsDestinationDoor] = useState(false);
  const [selectedVendorType, setSelectedVendorType] = useState('primary');
  const [primaryAttachment, setPrimaryAttachment] = useState([]);
  const [primarySelectedFiles, setPrimarySelectedFiles] = useState([]);
  const [pickUpAttachment, setPickUpAttachment] = useState([]);
  const [pickUpSelectedFiles, setPickUpSelectedFiles] = useState([]);
  const [deliveryAttachment, setDeliveryAttachment] = useState([]);
  const [deliverySelectedFiles, setDeliverySelectedFiles] = useState([]);
  const [disableButton, setDisableButton] = useState(false);
  const [selectedForwarders, setSelectedForwarders] = useState([]);
  const [bookingInfo, setBookingInfo] = useState({});
  const [bookingList, setBookingList] = useState([]);
  const [buyerAction, setBuyerAction] = useState({
    addPrimary: false,
    addPickup: false,
    addDelivery: false,
  });
  const [partiallyBooked, setPartiallyBooked] = useState(false);
  const [primaryBookingData, setPrimaryBookingData] = useState({});
  const [pickUpBookingData, setPickUpBookingData] = useState({});
  const [deliveryBookingData, setDeliveryBookingData] = useState({});
  const [pickUpHandledByPrimary, setPickUpHandledByPrimary] = useState(false);
  const [deliveryHandledByPrimary, setDeliveryHandledByPrimary] =
    useState(false);
  const [disablePrimaryAtSupplier, setDisablePrimaryAtSupplier] =
    useState(false);
  const [disablePickupAtSupplier, setDisablePickupAtSupplier] = useState(false);
  const [disableDeliveryAtSupplier, setDisableDeliveryAtSupplier] =
    useState(false);
  const [primaryBookingCompleted, setPrimaryBookingCompleted] = useState(false);
  const [pickupBookingCompleted, setPickupBookingCompleted] = useState(false);
  const [deliveryBookingCompleted, setDeliveryBookingCompleted] =
    useState(false);
  const [supplierUploadedDocs, setSupplierUploadedDocs] = useState([]);
  const [refreshForm, setRefreshForm] = useState(true);
  const [callRefreshForm, setCallRefreshForm] = useState(true);

  const profileType = giveProfile(relation);

  const [searchParams, setSearchParams] = useSearchParams();

  const { loading, refetch } = useQuery(FETCH_BOOKING, {
    variables: {
      input: {
        referenceId: itemsData?.referenceId,
        poId,
        profileType,
      },
    },
    onCompleted: (data) => {
      setBookingInfo({
        ...data?.FetchBookingTypeList?.data?.bookingDetails,
      });
      setBookingList(data?.FetchBookingTypeList?.data?.bookings);
    },
  });

  // useEffect(() => {
  //   setSelectedForwarders([
  //     ...primaryForwarderList,
  //     ...pickUpForwarderList,
  //     ...deliveryForwarderList,
  //   ]);
  // }, [primaryForwarderList, pickUpForwarderList, deliveryForwarderList]);

  useEffect(() => {
    const updatedSelectedForwarder = [
      ...primaryForwarderList,
      ...pickUpForwarderList,
      ...deliveryForwarderList,
    ];
    const formatForwarderList = (data) => {
      const formattedForwarderList = data?.forwarderDetail?.map((obj) => ({
        ...obj,
        label: obj?.orgName,
        value: obj?.orgId,
      }));
      updatedSelectedForwarder.push(...formattedForwarderList);
    };

    if (primaryBookingData?.forwarderDetail?.length) {
      formatForwarderList(primaryBookingData);
    }
    if (pickUpBookingData?.forwarderDetail?.length) {
      formatForwarderList(pickUpBookingData);
    }
    if (deliveryBookingData?.forwarderDetail?.length) {
      formatForwarderList(deliveryBookingData);
    }

    setSelectedForwarders(updatedSelectedForwarder);
  }, [
    primaryForwarderList,
    pickUpForwarderList,
    deliveryForwarderList,
    primaryBookingData?.forwarderDetail,
    pickUpBookingData?.forwarderDetail,
    deliveryBookingData?.forwarderDetail,
  ]);

  const [bookingValue, setBookingValue] = useState({});
  const [destinationValues, setDestinationValues] = useState({});
  const [options, setOptions] = useState({
    originOptions: [],
    destinationOptions: [],
    pickUpOptions: [],
    deliveryOptions: [],
  });

  const [eventValue, setEventValue] = useState();

  const togglePopover = (e) => {
    setEventValue(e.target);
  };

  const handleClose = () => {
    setEventValue(null);
  };
  const [createRoleButton, setCreateRoleButton] = useState('Create Role');

  const defaultDestinationValues = (data) => {
    return {
      destinationCountry:
        data?.address !== null
          ? data?.address?.filter((itm) => itm?.type === 'destination')?.[0]
              ?.country || ''
          : '',
      destinationDoorAddress: [],
      destinationGeoCode:
        data?.destinationGeoCode !== null
          ? [
              {
                GeoCoordinate: `${
                  data?.destinationGeoCode?.lat !== 'NULL' &&
                  data?.destinationGeoCode?.lat !== undefined &&
                  data?.destinationGeoCode?.long !== undefined &&
                  data?.destinationGeoCode?.lat &&
                  data?.destinationGeoCode?.long
                    ? `${data?.destinationGeoCode?.lat.trim()}, ${data?.destinationGeoCode?.long.trim()}`
                    : 'NULL'
                }`,
              },
            ]
          : [],
      destination:
        data?.destinationFacility !== '-'
          ? data?.destinationFacility
          : '' || '',
    };
  };
  const defaultDeliveryValues = (data) => {
    return {
      destinationCountry: data?.country ? data?.country : '',
      destinationDoorAddress: [],
      destinationGeoCode:
        data?.geoCode !== null
          ? [
              {
                GeoCoordinate: `${
                  data?.geoCode?.lat !== 'NULL' &&
                  data?.geoCode?.lat !== undefined &&
                  data?.geoCode?.long !== undefined &&
                  data?.geoCode?.lat &&
                  data?.geoCode?.long
                    ? `${data?.geoCode?.lat.trim()}, ${data?.geoCode?.long.trim()}`
                    : 'NULL'
                }`,
              },
            ]
          : [],
      destination: data?.portName || '',
    };
  };
  const defaultPickUpValues = (data) => {
    return {
      origin: data?.portName || '',
      originGeoCode:
        data?.geoCode !== null
          ? [
              {
                GeoCoordinate: `${
                  data?.geoCode?.lat !== 'NULL' &&
                  data?.geoCode?.lat !== undefined &&
                  data?.geoCode?.long !== undefined &&
                  data?.geoCode?.lat &&
                  data?.geoCode?.long
                    ? `${data?.geoCode?.lat.trim()}, ${data?.geoCode?.long.trim()}`
                    : 'NULL'
                }`,
              },
            ]
          : [],
      originDoorAddress: [],
      originCountry: data?.country ? data?.country : '',
    };
  };

  const [deliveryValues, setDeliveryValues] = useState({});

  const defaultOriginValue = (data) => {
    return {
      origin: (data?.originFacility !== '-' && data?.originFacility) || '',
      originGeoCode:
        data?.originGeoCode !== null
          ? [
              {
                GeoCoordinate: `${
                  data?.originGeoCode?.lat !== 'NULL' &&
                  data?.originGeoCode?.lat !== undefined &&
                  data?.originGeoCode?.long !== undefined &&
                  data?.originGeoCode?.lat &&
                  data?.originGeoCode?.long
                    ? `${data.originGeoCode?.lat}, ${data.originGeoCode?.long}`
                    : 'NULL'
                }`,
              },
            ]
          : [],
      originDoorAddress: [],
      originCountry:
        data?.address !== null
          ? data?.address?.filter((itm) => itm?.type === 'origin')?.[0]
              ?.country || ''
          : '',
    };
  };

  const [originValues, setOriginValues] = useState({});
  const [pickUpValues, setPickUpValues] = useState({});
  const [confirmEntity, setConfirmEntity] = useState({});

  useEffect(() => {
    setBookingValue({
      mode:
        (bookingInfo?.mode !== null &&
          bookingInfo?.mode !== undefined && {
            label: bookingInfo?.mode === 'Inland' ? 'Road' : bookingInfo?.mode,
            value: bookingInfo?.mode,
          }) ||
        {},
      containerType:
        (bookingInfo?.containerType !== null && {
          label: bookingInfo?.containerType,
          value: bookingInfo?.containerType,
        }) ||
        {},
      cargoLoad:
        (bookingInfo?.cargoLoad !== null && {
          label: bookingInfo?.cargoLoad,
          value: bookingInfo?.cargoLoad,
        }) ||
        {},
      equipmentType:
        (bookingInfo?.equipmentType !== null && {
          label: bookingInfo?.equipmentType,
          value: bookingInfo?.equipmentType,
        }) ||
        {},
      equipTypeQty: bookingInfo?.equipTypeQty || 1,
      typeOfMove:
        (bookingInfo?.typeofMove !== null && {
          label: bookingInfo?.typeofMove
            ?.replaceAll('port', 'Port')
            ?.replaceAll('door', 'Door'),
          value: bookingInfo?.typeofMove,
        }) ||
        {},
      incoTerm:
        (bookingInfo?.incoTerm !== null && {
          label: bookingInfo?.incoTerm,
          value: bookingInfo?.incoTerm,
        }) ||
        {},
      numberOfTrucks: bookingInfo?.numberOfTrucks || 1,
    });
    setDestinationValues(defaultDestinationValues(bookingInfo));
    setOriginValues(defaultOriginValue(bookingInfo));
    let defaultDeliveryAddress = {};
    if (bookingList?.length) {
      bookingList?.forEach((obj) => {
        if (
          (obj?.bookingType === 'Delivery' &&
            obj?.deliveryAddress?.type === 'destination') ||
          (obj?.bookingType === 'Primary' &&
            obj?.bookHandle?.delivery &&
            obj?.deliveryAddress?.type === 'destination')
        ) {
          defaultDeliveryAddress = obj?.deliveryAddress;
        }
      });
    }
    let defaultPickUpAddress = {};
    if (bookingList?.length) {
      bookingList?.forEach((obj) => {
        if (
          (obj?.bookingType === 'Pickup' &&
            obj?.pickupAddress?.type === 'origin') ||
          (obj?.bookingType === 'Primary' &&
            obj?.bookHandle?.pickup &&
            obj?.pickupAddress?.type === 'origin')
        ) {
          defaultPickUpAddress = obj?.pickupAddress;
        }
      });
    }
    setDeliveryValues(defaultDeliveryValues(defaultDeliveryAddress));
    setPickUpValues(defaultPickUpValues(defaultPickUpAddress));
    let defaultNotifyParty = '';
    let defaultConsigneeDetail = '';
    bookingInfo?.entities?.forEach((item) => {
      if (item?.type === 'notify party') {
        defaultNotifyParty = item?.name;
      }
      if (item?.type === 'consignee') {
        defaultConsigneeDetail = item?.name;
      }
    });
    setConfirmEntity({
      notificationDetail: defaultNotifyParty,
      consigneeDetail: defaultConsigneeDetail,
    });

    bookingList?.forEach((obj) => {
      if (obj?.bookingType === 'Primary') {
        if (obj?.bookHandle?.pickup) {
          setBuyerAction((prev) => ({ ...prev, addPickup: true }));
          setPickUpHandledByPrimary(true);
        }
        if (obj?.bookHandle?.delivery) {
          setBuyerAction((prev) => ({ ...prev, addDelivery: true }));
          setDeliveryHandledByPrimary(true);
        }
        setBuyerAction((prev) => ({ ...prev, addPrimary: true }));
        setPrimaryBookingData(obj);
      } else if (obj?.bookingType === 'Delivery') {
        // buyerAction.addDelivery = true;
        setBuyerAction((prev) => ({ ...prev, addDelivery: true }));
        setDeliveryBookingData(obj);
      } else if (obj?.bookingType === 'Pickup') {
        buyerAction.addPickup = true;
        setBuyerAction((prev) => ({ ...prev, addPickup: true }));
        setPickUpBookingData(obj);
      }
    });
    setPartiallyBooked(
      // check bookingInfo?.bookAction?.pickup = false at buyer i.e direct to supplier
      // or use pickupswitchto
      profileType === 'buyer'
        ? !!bookingList?.length ||
            bookingInfo?.directedBooking?.primary ||
            bookingInfo?.directedBooking?.pickup ||
            bookingInfo?.directedBooking?.delivery
        : bookingInfo?.bookAction?.primary ||
            bookingInfo?.bookAction?.pickup ||
            bookingInfo?.bookAction?.delivery
    );
    if (profileType === 'buyer') {
      if (bookingInfo?.directedBooking?.delivery) {
        setDeliverySwitchTo(true);
      }
      if (bookingInfo?.directedBooking?.pickup) {
        setPickUpSwitchTo(true);
      }
      if (bookingInfo?.directedBooking?.primary) {
        setShipmentSwitchTo(true);
      }
    }
  }, [bookingInfo, bookingList]);

  // profileType === supplier && (bookAction -> primary,pickup,delivery)
  // originStatus:true, destinationStatus:true, enable both, selectedVendorType: primary

  // profileType === supplier && (bookAction -> primary,pickup)
  // originStatus:true, destinationStatus:false, enable origin, disable dest, selectedVendorType: primary

  // profileType === supplier && (bookAction -> primary,delivery)
  // originStatus:false, destinationStatus:true, enable dest, disable origin, selectedVendorType: primary

  // profileType === supplier && (bookAction -> pickup, delivery)
  // originStatus:true, destinationStatus:false, enable origin, disable dest, selectedVendorType: pickup

  // profileType === supplier && (bookAction -> primary)
  // originStatus:false, destinationStatus:false and disable it, selectedVendorType: primary

  // profileType === supplier && (bookAction -> pickup)
  // originStatus:true, destinationStatus:false and disable it, selectedVendorType: pickup

  // profileType === supplier && (bookAction -> delivery)
  // originStatus:false, destinationStatus:true and disable it,selectedVendorType: delivery

  // if bookAction.primary = true && bookingList[0].bookingType='Primary' -> booking done
  // if bookAction.pickup = true && (bookingList[0].bookingType='Pickup'||(bookingList[0].bookingType='Primary' && bookingList[0].bookHandle.pickup=true))-> booking done
  // if bookAction.delivery = true && (bookingList[0].bookingType='Delivery'||(bookingList[0].bookingType='Primary' && bookingList[0].bookHandle.delivery=true))-> booking done

  useEffect(() => {
    bookingList?.forEach((obj) => {
      if (obj?.bookingType === 'Primary' && bookingInfo?.bookAction?.primary) {
        // primary booking done
        setPrimaryBookingCompleted(true);
      }
      if (
        bookingInfo?.bookAction?.pickup &&
        (obj?.bookingType === 'Pickup' ||
          (obj?.bookingType === 'Primary' && obj?.bookHandle?.pickup))
      ) {
        // pickup booking done
        setPickupBookingCompleted(true);
      }
      if (
        bookingInfo?.bookAction?.delivery &&
        (obj?.bookingType === 'Delivery' ||
          (obj?.bookingType === 'Primary' && obj?.bookHandle?.delivery))
      ) {
        setDeliveryBookingCompleted(true);
        // delivery booking done
      }
    });
  }, [
    bookingList,
    bookingInfo?.bookAction?.primary,
    bookingInfo?.bookAction?.pickup,
    bookingInfo?.bookAction?.delivery,
  ]);

  useEffect(() => {
    if (profileType === 'supplier') {
      setShipmentSwitchTo(!bookingInfo?.bookAction?.primary);
      setPickUpSwitchTo(!bookingInfo?.bookAction?.pickup);
      setPickUpSwitchTo(!bookingInfo?.bookAction?.delivery);

      if (
        bookingInfo?.bookAction?.primary &&
        bookingInfo?.bookAction?.pickup &&
        bookingInfo?.bookAction?.delivery
      ) {
        if (
          !primaryBookingCompleted &&
          !pickupBookingCompleted &&
          !deliveryBookingCompleted
        ) {
          setOriginStatus(true);
          setDestinationStatus(true);
          setSelectedVendorType('primary');
        } else if (
          primaryBookingCompleted &&
          !pickupBookingCompleted &&
          !deliveryBookingCompleted
        ) {
          setOriginStatus(false);
          setDestinationStatus(false);
          setSelectedVendorType('pickUp');
        } else if (
          primaryBookingCompleted &&
          pickupBookingCompleted &&
          !deliveryBookingCompleted
        ) {
          setOriginStatus(false);
          setDestinationStatus(false);
          setSelectedVendorType('delivery');
        } else if (
          primaryBookingCompleted &&
          !pickupBookingCompleted &&
          deliveryBookingCompleted
        ) {
          setOriginStatus(false);
          setDestinationStatus(false);
          setSelectedVendorType('pickUp');
        } else if (
          !primaryBookingCompleted &&
          pickupBookingCompleted &&
          !deliveryBookingCompleted
        ) {
          setOriginStatus(false);
          setDestinationStatus(true);
          setSelectedVendorType('primary');
        } else if (
          !primaryBookingCompleted &&
          !pickupBookingCompleted &&
          deliveryBookingCompleted
        ) {
          setOriginStatus(true);
          setDestinationStatus(false);
          setSelectedVendorType('primary');
        } else if (
          !primaryBookingCompleted &&
          pickupBookingCompleted &&
          deliveryBookingCompleted
        ) {
          setOriginStatus(false);
          setDestinationStatus(false);
          setSelectedVendorType('primary');
        }
        setIsOriginDoor(true);
        setIsDestinationDoor(true);
      } else if (
        bookingInfo?.bookAction?.primary &&
        bookingInfo?.bookAction?.pickup
      ) {
        if (!primaryBookingCompleted && !pickupBookingCompleted) {
          setDisableDeliveryAtSupplier(true);
          setOriginStatus(true);
          setDestinationStatus(false);
          setSelectedVendorType('primary');
        } else if (primaryBookingCompleted && !pickupBookingCompleted) {
          setDisableDeliveryAtSupplier(true);
          setOriginStatus(false);
          setDestinationStatus(false);
          setSelectedVendorType('pickUp');
        } else if (!primaryBookingCompleted && pickupBookingCompleted) {
          setDisableDeliveryAtSupplier(true);
          setOriginStatus(false);
          setDestinationStatus(false);
          setSelectedVendorType('primary');
        }
        setIsOriginDoor(true);
        setIsDestinationDoor(false);
      } else if (
        bookingInfo?.bookAction?.primary &&
        bookingInfo?.bookAction?.delivery
      ) {
        if (!primaryBookingCompleted && !deliveryBookingCompleted) {
          setDisablePickupAtSupplier(true);
          setOriginStatus(false);
          setDestinationStatus(true);
          setSelectedVendorType('primary');
        } else if (primaryBookingCompleted && !deliveryBookingCompleted) {
          setDisablePickupAtSupplier(true);
          setOriginStatus(false);
          setDestinationStatus(false);
          setSelectedVendorType('delivery');
        } else if (!primaryBookingCompleted && deliveryBookingCompleted) {
          setDisablePickupAtSupplier(true);
          setOriginStatus(false);
          setDestinationStatus(false);
          setSelectedVendorType('primary');
        }
        setIsOriginDoor(false);
        setIsDestinationDoor(true);
      } else if (
        bookingInfo?.bookAction?.delivery &&
        bookingInfo?.bookAction?.pickup
      ) {
        if (!pickupBookingCompleted && !deliveryBookingCompleted) {
          setSelectedVendorType('pickUp');
        } else if (pickupBookingCompleted && !deliveryBookingCompleted) {
          setSelectedVendorType('delivery');
        } else if (!pickupBookingCompleted && deliveryBookingCompleted) {
          setSelectedVendorType('pickUp');
        }
        // disable primary
        setDisablePrimaryAtSupplier(true);
        setOriginStatus(false);
        setDestinationStatus(false);
        setIsOriginDoor(true);
        setIsDestinationDoor(true);
      } else if (bookingInfo?.bookAction?.primary) {
        setSelectedVendorType('primary');
        // disable pickup and delivery
        setDisableDeliveryAtSupplier(true);
        setDisablePickupAtSupplier(true);
        setOriginStatus(false);
        setDestinationStatus(false);
        setIsOriginDoor(false);
        setIsDestinationDoor(false);
      } else if (bookingInfo?.bookAction?.pickup) {
        setSelectedVendorType('pickUp');
        // disable primary, delivery
        setDisablePrimaryAtSupplier(true);
        setDisableDeliveryAtSupplier(true);
        setOriginStatus(true);
        setDestinationStatus(false);
        setIsOriginDoor(true);
        setIsDestinationDoor(false);
      } else if (bookingInfo?.bookAction?.delivery) {
        setSelectedVendorType('delivery');
        // disable primary, pickup
        setDisablePrimaryAtSupplier(true);
        setDisablePickupAtSupplier(true);
        setOriginStatus(false);
        setDestinationStatus(true);
        setIsOriginDoor(false);
        setIsDestinationDoor(true);
      }
    }
  }, [
    bookingInfo?.bookAction?.primary,
    bookingInfo?.bookAction?.pickup,
    bookingInfo?.bookAction?.delivery,
    primaryBookingCompleted,
    pickupBookingCompleted,
    deliveryBookingCompleted,
  ]);

  useEffect(() => {
    if (partiallyBooked) {
      if (profileType === 'buyer') {
        setOriginStatus(pickUpHandledByPrimary);
        setDestinationStatus(deliveryHandledByPrimary);
      }

      if (profileType === 'supplier' && pickupBookingCompleted) {
        setOriginStatus(pickUpHandledByPrimary);
      }
      if (profileType === 'supplier' && deliveryBookingCompleted) {
        setDestinationStatus(deliveryHandledByPrimary);
      }
      setBookingDetails(true);
      setShipmentDetails(true);
      setConfirmEntities(true);
      setAddForworder(true);
      setSupplierTab('add forworder');
    }
  }, [partiallyBooked, pickUpHandledByPrimary, deliveryHandledByPrimary]);

  useEffect(() => {
    if (!partiallyBooked && profileType === 'buyer') {
      switch (bookingValue?.typeOfMove?.value) {
        case 'port to door':
          setOriginStatus(false);
          setDestinationStatus(true);
          setIsOriginDoor(false);
          setIsDestinationDoor(true);
          break;

        case 'port to port':
          setOriginStatus(false);
          setDestinationStatus(false);
          setIsOriginDoor(false);
          setIsDestinationDoor(false);
          break;

        case 'door to door':
          setOriginStatus(true);
          setDestinationStatus(true);
          setIsOriginDoor(true);
          setIsDestinationDoor(true);
          break;

        case 'door to port':
          setOriginStatus(true);
          setDestinationStatus(false);
          setIsOriginDoor(true);
          setIsDestinationDoor(false);
          break;

        default:
          break;
      }
    }
  }, [bookingValue?.typeOfMove?.value]);

  useEffect(() => {
    if (partiallyBooked && profileType === 'buyer') {
      if (
        bookingValue?.typeOfMove?.value === 'door to port' ||
        bookingValue?.typeOfMove?.value === 'door to door'
      ) {
        setIsOriginDoor(!pickUpHandledByPrimary || !buyerAction?.addPickup);
      }
      if (
        bookingValue?.typeOfMove?.value === 'door to door' ||
        bookingValue?.typeOfMove?.value === 'port to door'
      ) {
        setIsDestinationDoor(
          !deliveryHandledByPrimary || !buyerAction?.addDelivery
        );
      }
    }
  }, [partiallyBooked, bookingValue?.typeOfMove?.value]);

  const primaryDocArray = [];
  const pickUpDocArray = [];
  const deliveryDocArray = [];

  const formatDocumentMeta = (attachment, docArray) => {
    attachment?.forEach((attachmentItem) => {
      docArray.push({
        url: attachmentItem?.url,
        size: attachmentItem.fileSize,
        path: attachmentItem.filePath,
        originalName: attachmentItem.originalFileName,
        mimeType: attachmentItem.fileType,
        type: 'file',
      });
    });
  };

  formatDocumentMeta(primaryAttachment, primaryDocArray);
  formatDocumentMeta(pickUpAttachment, pickUpDocArray);
  formatDocumentMeta(deliveryAttachment, deliveryDocArray);

  // const docs = [
  //   {
  //     url: 'https://api.quloi.com/fileAPI/v1/service/getFile/65de22dad7d72170d2e2b433',
  //     size: '9002',
  //     path: 'omni/download (7)-1653039685688-1668773810231-1669264876912 (1)-1709056729945.jpg',
  //     originalName:
  //       'download (7)-1653039685688-1668773810231-1669264876912 (1).jpg',
  //     mimeType: 'image/jpeg',
  //     primarySelected: false,
  //     pickupSelected: false,
  //     deliverySelected: false,
  //   },
  //   {
  //     url: 'https://api.quloi.com/fileAPI/v1/service/getFile/65de22e08b32f70477280b15',
  //     size: '8951',
  //     path: 'omni/gg copy-1709056736510.jpg',
  //     originalName: 'gg copy.jpg',
  //     mimeType: 'image/jpeg',
  //     primarySelected: false,
  //     pickupSelected: false,
  //     deliverySelected: false,
  //   },
  //   {
  //     url: 'https://api.quloi.com/fileAPI/v1/service/getFile/65de22e9950499f51e28db28',
  //     size: '8946',
  //     path: 'omni/gg-1709056745716.jpg',
  //     originalName: 'gg.jpg',
  //     mimeType: 'image/jpeg',
  //     primarySelected: false,
  //     pickupSelected: false,
  //     deliverySelected: false,
  //   },
  // ];

  const updateSupplierUploadedDocs = (e, vendorType, url) => {
    setSupplierUploadedDocs((prev) => {
      const updatedPrev = prev?.map((doc) => {
        if (doc?.url === url) {
          if (vendorType === 'primary') {
            return { ...doc, primarySelected: e?.target?.checked };
          }
          if (vendorType === 'pickup') {
            return { ...doc, pickupSelected: e?.target?.checked };
          }
          if (vendorType === 'delivery') {
            return { ...doc, deliverySelected: e?.target?.checked };
          }
        }
        return doc;
      });
      return updatedPrev;
    });
  };

  const getForwardersAndGeoCodes = ({ forwarderList, origin, destination }) => {
    const forwarders = [];
    if (forwarderList?.length > 0) {
      forwarderList?.forEach((itm) => {
        if (itm.value !== '') {
          forwarders.push(itm.value);
        }
      });
    }
    let originGeoCode;
    let destinationGeoCode;

    if (origin?.originGeoCode?.length > 0) {
      let lat;
      let long;
      if (origin?.originGeoCode[0].GeoCoordinate !== 'NULL') {
        [lat, long] = origin.originGeoCode[0].GeoCoordinate.split(',');
      } else {
        lat = 'NULL';
        long = '';
      }
      originGeoCode = { lat: lat.trim(), long: long.trim() };
    }
    if (destination?.destinationGeoCode?.length > 0) {
      let lat;
      let long;
      if (destination?.destinationGeoCode[0].GeoCoordinate !== 'NULL') {
        [lat, long] =
          destination.destinationGeoCode[0].GeoCoordinate.split(',');
      } else {
        lat = 'NULL';
        long = '';
      }
      destinationGeoCode = { lat: lat.trim(), long: long.trim() };
    }
    return { forwarders, originGeoCode, destinationGeoCode };
  };

  const getAddress = ({ type, originData, destinationData }) => {
    return {
      type,
      portName:
        type === 'origin' ? originData?.origin : destinationData?.destination,
      street:
        fetchAddress(
          type,
          'neighborhood',
          [],
          destinationData?.destinationDoorAddress,
          originData?.originDoorAddress
        ) ||
        fetchAddress(
          type,
          'route',
          [],
          destinationData?.destinationDoorAddress,
          originData?.originDoorAddress
        ),
      city:
        fetchAddress(
          type,
          'administrative_area_level_2',
          [],
          destinationData?.destinationDoorAddress,
          originData?.originDoorAddress
        ) ||
        fetchAddress(
          type,
          'locality',
          [],
          destinationData?.destinationDoorAddress,
          originData?.originDoorAddress
        ),
      state: fetchAddress(
        type,
        'administrative_area_level_1',
        [],
        destinationData?.destinationDoorAddress,
        originData?.originDoorAddress
      ),
      country:
        fetchAddress(
          type,
          'country',
          [],
          destinationData?.destinationDoorAddress,
          originData?.originDoorAddress
        ) || originData?.originCountry,
      // type === 'origin'?originData?.originCountry:destinationData?.destinationCountry
      zipcode: fetchAddress(
        type,
        'postal_code',
        [],
        destinationData?.destinationDoorAddress,
        originData?.originDoorAddress
      ),
    };
  };

  const handleUpdateBooking = () => {
    setDisableLastBtn(true);
    const primaryForwardersAndGeoCodes = getForwardersAndGeoCodes({
      forwarderList: primaryForwarderList,
      origin: originValues,
      destination: destinationValues,
    });
    const pickUpForwardersAndGeoCodes = getForwardersAndGeoCodes({
      forwarderList: pickUpForwarderList,
      origin: pickUpValues,
      destination: '',
    });
    const deliveryForwardersAndGeoCodes = getForwardersAndGeoCodes({
      forwarderList: deliveryForwarderList,
      origin: '',
      destination: deliveryValues,
    });

    // const primaryOriginAddress = getAddress({
    //   type: 'origin',
    //   originData: originValues,
    //   destinationData: destinationValues,
    // });
    // const primaryDestinationAddress = getAddress({
    //   type: 'destination',
    //   originData: originValues,
    //   destinationData: destinationValues,
    // });
    const pickUpAddress = getAddress({
      type: 'origin',
      originData: pickUpValues,
      destinationData: {},
    });
    const deliveryAddress = getAddress({
      type: 'destination',
      originData: {},
      destinationData: deliveryValues,
    });

    const getSelectedDocs = (vendorType) => {
      const selectedDocs = [];
      if (supplierUploadedDocs?.length) {
        supplierUploadedDocs?.forEach(
          ({ primarySelected, pickupSelected, deliverySelected, ...rest }) => {
            if (vendorType === 'primary' && primarySelected) {
              selectedDocs?.push({ ...rest });
            }
            if (vendorType === 'pickup' && pickupSelected) {
              selectedDocs?.push({ ...rest });
            }
            if (vendorType === 'delivery' && deliverySelected) {
              selectedDocs?.push({ ...rest });
            }
            return false;
          }
        );
      }
      return selectedDocs;
    };

    const pickUpPayload = {
      bookingType: 'Pickup',
      directBooking: profileType === 'buyer' ? pickUpSwitchTo : false,
      forwarders: pickUpForwardersAndGeoCodes?.forwarders,
      quotationExpiryDate: pickUpDateForm?.toISOString(),
      address: [
        {
          ...pickUpAddress,
          geoCode: pickUpForwardersAndGeoCodes?.originGeoCode,
        },
      ],
    };

    const deliveryPayload = {
      bookingType: 'Delivery',
      directBooking: profileType === 'buyer' ? deliverySwitchTo : false,
      forwarders: deliveryForwardersAndGeoCodes?.forwarders,
      quotationExpiryDate: deliveryDateForm?.toISOString(),
      address: [
        {
          ...deliveryAddress,
          geoCode: deliveryForwardersAndGeoCodes?.destinationGeoCode,
        },
      ],
    };

    const primaryPayload = {
      bookingType: 'Primary',
      directBooking: profileType === 'buyer' ? shipmentSwitchTo : false,
      forwarders: primaryForwardersAndGeoCodes?.forwarders,
      quotationExpiryDate: primaryDateForm?.toISOString(),
      address: [
        {
          ...pickUpAddress,
          geoCode: pickUpForwardersAndGeoCodes?.originGeoCode,
        },
        {
          ...deliveryAddress,
          geoCode: deliveryForwardersAndGeoCodes?.destinationGeoCode,
        },
      ],
    };

    const bookHandle = {
      pickup: originStatus,
      delivery: destinationStatus,
    };

    let bookingData;
    if (selectedVendorType === 'primary') {
      if (originStatus && destinationStatus) {
        bookingData = [
          {
            ...primaryPayload,
            bookHandle,
            attachments: [...primaryDocArray, ...getSelectedDocs('primary')],
          },
        ];
      } else if (!originStatus && !destinationStatus) {
        bookingData = [
          {
            ...primaryPayload,
            bookHandle,
            address: [],
            attachments: [...primaryDocArray, ...getSelectedDocs('primary')],
          },
        ];
      } else if (originStatus) {
        bookingData = [
          {
            ...pickUpPayload,
            directBooking: profileType === 'buyer' ? shipmentSwitchTo : false,
            bookingType: 'Primary',
            bookHandle,
            forwarders: primaryForwardersAndGeoCodes?.forwarders,
            attachments: [...primaryDocArray, ...getSelectedDocs('primary')],
          },
        ];
      } else if (destinationStatus) {
        bookingData = [
          {
            ...deliveryPayload,
            directBooking: profileType === 'buyer' ? shipmentSwitchTo : false,
            bookingType: 'Primary',
            bookHandle,
            forwarders: primaryForwardersAndGeoCodes?.forwarders,
            attachments: [...primaryDocArray, ...getSelectedDocs('primary')],
          },
        ];
      }
    } else if (selectedVendorType === 'pickUp') {
      bookingData = [
        {
          ...pickUpPayload,
          attachments: [...pickUpDocArray, ...getSelectedDocs('pickup')],
        },
      ];
    } else if (selectedVendorType === 'delivery') {
      bookingData = [
        {
          ...deliveryPayload,
          attachments: [...deliveryDocArray, ...getSelectedDocs('delivery')],
        },
      ];
    }

    const returnAttach = (arr) => {
      const attachData = [];
      arr?.forEach((ele) => {
        attachData.push(...ele.attachments);
      });
      return attachData;
    };

    const input = {
      bookingId: bookingInfo?._id,
      originFacility: originValues?.origin,
      destinationFacility: destinationValues?.destination,
      cargoType: 'streight',
      mode: bookingValue?.mode?.value,
      shipmentType: 'Export',
      profileType: giveProfile(relation),
      typeofMove: bookingValue?.typeOfMove?.value,
      ...(bookingValue?.mode?.value === 'Ocean' && {
        containerType: bookingValue?.containerType?.value,
        equipmentType: bookingValue?.equipmentType?.value,
        equipTypeQty: Number(bookingValue?.equipTypeQty),
      }),
      // ...(bookingValue?.mode?.value !== 'Inland' && {
      //   incoTerm: bookingValue?.incoTerm?.value,
      // }),
      incoTerm: bookingValue?.incoTerm?.value,
      ...(bookingValue?.mode?.value === 'Inland' && {
        cargoLoad: bookingValue?.cargoLoad?.value,
        numberOfTrucks: Number(bookingValue?.numberOfTrucks),
      }),
      // ...(!shipmentSwitchTo && { forwarders }),
      originGeoCode: primaryForwardersAndGeoCodes?.originGeoCode,
      destinationGeoCode: primaryForwardersAndGeoCodes?.destinationGeoCode,
      bookings: bookingData,
      attachments: returnAttach(bookingData),
      entitiesInput: [
        {
          type: 'notify party',
          id: 0,
          name: confirmEntity.notificationDetail,
        },
        {
          type: 'consignee',
          id: 1,
          name: confirmEntity.consigneeDetail,
        },
      ],
    };
    if (!isBulk) {
      updateBookingHandler({
        variables: {
          input,
        },
        onCompleted: (resp) => {
          toast.success(resp?.UpdatingBooking?.message);
          refetch();
        },
      });
    } else {
      updateBulkBookingHandler({
        variables: {
          input,
        },
        onCompleted: (resp) => {
          toast.success(resp?.BulkCompleteBooking?.message);
          refetch();
          orderListRefetch();
        },
      });
    }
  };

  const disableNxtShipFunction = () => {
    if (bookingValue?.mode?.value === undefined) {
      setDisableNxtShip(true);
      return true;
    }
    if (bookingValue?.mode?.value === 'Ocean') {
      if (
        bookingValue?.containerType?.value === '' ||
        bookingValue?.containerType?.value === undefined
      ) {
        setDisableNxtShip(true);
        return true;
      }
      if (bookingValue?.containerType?.value === 'FCL') {
        if (
          bookingValue?.equipmentType.value === '' ||
          bookingValue?.equipmentType.value === undefined ||
          bookingValue?.equipTypeQty === '0' ||
          bookingValue?.equipTypeQty === '' ||
          bookingValue?.equipTypeQty === undefined
        ) {
          setDisableNxtShip(true);
          return true;
        }
      }
    }
    if (bookingValue?.mode?.value === 'Inland') {
      if (
        bookingValue?.cargoLoad?.value === '' ||
        bookingValue?.cargoLoad?.value === undefined
      ) {
        setDisableNxtShip(true);
        return true;
      }

      if (bookingValue?.cargoLoad?.value === 'FTL') {
        if (
          bookingValue?.numberOfTrucks === '' ||
          bookingValue?.numberOfTrucks === undefined ||
          bookingValue?.numberOfTrucks === '0'
        ) {
          setDisableNxtShip(true);
          return true;
        }
      }
    }
    if (
      bookingValue?.mode?.value === '' ||
      bookingValue?.mode?.value === undefined
    ) {
      setDisableNxtShip(true);
      return true;
    }
    if (
      bookingValue?.typeOfMove?.value === '' ||
      bookingValue?.typeOfMove?.value === undefined
    ) {
      setDisableNxtShip(true);
      return true;
    }
    if (
      bookingValue?.incoTerm?.value === '' ||
      (bookingValue?.incoTerm?.value === undefined &&
        bookingValue?.mode?.value !== 'Inland')
    ) {
      setDisableNxtShip(true);
      return true;
    }
    if (originValues?.origin === '' || destinationValues?.destination === '') {
      setDisableNxtShip(true);
      return true;
    }

    if (
      (originValues?.origin !== '' || destinationValues?.destination !== '') &&
      originValues?.originCountry &&
      destinationValues?.destinationCountry &&
      originValues?.originCountry?.toString()?.toUpperCase() ===
        destinationValues?.destinationCountry?.toString()?.toUpperCase() &&
      updateCountry &&
      bookingValue?.mode?.value !== 'Inland'
    ) {
      toast.error(t2('buyer.error.origindestinationnotsame'));
      setDisableNxtShip(true);
      return true;
    }
    setDisableNxtShip(false);
    return false;
  };

  const disableLastButton = () => {
    if (
      !shipmentSwitchTo &&
      selectedVendorType === 'primary' &&
      bookingValue?.mode?.value === 'Inland'
    ) {
      if (
        !compareDatesEqualWithoutTime(primaryDateForm, new Date()) &&
        update
      ) {
        toast.error(t2('buyer.error.notpastdated'));
        setDisableLastBtn(true);
        return true;
      }
      if (primaryForwarderList?.length === 1) {
        setDisableLastBtn(true);
        return true;
      }
      setDisableLastBtn(false);
      return false;
    }
    if (
      shipmentSwitchTo &&
      selectedVendorType === 'primary' &&
      bookingValue?.mode?.value === 'Inland'
    ) {
      setDisableLastBtn(false);
      return false;
    }

    if (
      (selectedVendorType === 'primary' && buyerAction?.addPrimary) ||
      (selectedVendorType === 'pickUp' && buyerAction?.addPickup) ||
      (selectedVendorType === 'delivery' && buyerAction?.addDelivery)
    ) {
      setDisableLastBtn(true);
      return true;
    }
    if (
      ((profileType === 'buyer' && !shipmentSwitchTo) ||
        profileType === 'supplier') &&
      selectedVendorType === 'primary'
    ) {
      if (
        !compareDatesEqualWithoutTime(primaryDateForm, new Date()) &&
        update
      ) {
        toast.error(t2('buyer.error.notpastdated'));
        setDisableLastBtn(true);
        return true;
      }
      if (primaryForwarderList?.length === 1) {
        setDisableLastBtn(true);
        return true;
      }
      if (
        (originStatus &&
          pickUpValues?.origin === '' &&
          destinationStatus &&
          deliveryValues?.origin === '') ||
        (originStatus && pickUpValues?.origin === '') ||
        (destinationStatus && deliveryValues?.destination === '')
      ) {
        setDisableLastBtn(true);
        return true;
      }
      setDisableLastBtn(false);
      return false;
    }
    if (
      ((profileType === 'buyer' && !pickUpSwitchTo) ||
        profileType === 'supplier') &&
      selectedVendorType === 'pickUp'
    ) {
      if (!compareDatesEqualWithoutTime(pickUpDateForm, new Date()) && update) {
        toast.error(t2('buyer.error.notpastdated'));
        setDisableLastBtn(true);
        return true;
      }
      if (pickUpForwarderList?.length === 1 || pickUpValues?.origin === '') {
        setDisableLastBtn(true);
        return true;
      }
      setDisableLastBtn(false);
      return false;
    }
    if (
      ((profileType === 'buyer' && !deliverySwitchTo) ||
        profileType === 'supplier') &&
      selectedVendorType === 'delivery'
    ) {
      if (
        !compareDatesEqualWithoutTime(deliveryDateForm, new Date()) &&
        update
      ) {
        toast.error(t2('buyer.error.notpastdated'));
        setDisableLastBtn(true);
        return true;
      }
      if (
        deliveryForwarderList?.length === 1 ||
        deliveryValues?.destination === ''
      ) {
        setDisableLastBtn(true);
        return true;
      }
      setDisableLastBtn(false);
      return false;
    }

    if (
      profileType === 'buyer' &&
      ((pickUpSwitchTo &&
        selectedVendorType === 'pickUp' &&
        !bookingInfo?.directedBooking?.pickup) ||
        (deliverySwitchTo &&
          selectedVendorType === 'delivery' &&
          !bookingInfo?.directedBooking?.delivery) ||
        (shipmentSwitchTo &&
          selectedVendorType === 'primary' &&
          !bookingInfo?.directedBooking?.primary))
    ) {
      setDisableLastBtn(false);
      return false;
    }
    if (
      profileType === 'buyer' &&
      ((selectedVendorType === 'pickUp' &&
        bookingInfo?.directedBooking?.pickup) ||
        (selectedVendorType === 'delivery' &&
          bookingInfo?.directedBooking?.delivery) ||
        (selectedVendorType === 'primary' &&
          bookingInfo?.directedBooking?.primary))
    ) {
      setDisableLastBtn(true);
      return true;
    }
    if (
      profileType === 'supplier' &&
      ((selectedVendorType === 'pickUp' && !pickupBookingCompleted) ||
        (selectedVendorType === 'delivery' && !deliveryBookingCompleted) ||
        (selectedVendorType === 'primary' && !primaryBookingCompleted))
    ) {
      setDisableLastBtn(false);
      return false;
    }
    if (
      profileType === 'supplier' &&
      ((selectedVendorType === 'pickUp' && pickupBookingCompleted) ||
        (selectedVendorType === 'delivery' && deliveryBookingCompleted) ||
        (selectedVendorType === 'primary' && primaryBookingCompleted))
    ) {
      setDisableLastBtn(true);
      return true;
    }

    return false;
  };

  useEffect(() => {
    disableLastButton();
  }, [
    primaryDateForm,
    primaryForwarderList,
    shipmentSwitchTo,
    pickUpDateForm,
    pickUpForwarderList,
    pickUpSwitchTo,
    deliveryDateForm,
    deliveryForwarderList,
    deliverySwitchTo,
    selectedVendorType,
    pickUpValues,
    deliveryValues,
    originStatus,
    destinationStatus,
    primaryBookingCompleted,
    pickupBookingCompleted,
    deliveryBookingCompleted,
    bookingInfo?.directedBooking?.primary,
    bookingInfo?.directedBooking?.pickup,
    bookingInfo?.directedBooking?.delivery,
  ]);

  useEffect(() => {
    disableNxtShipFunction();
  }, [bookingValue, originValues, destinationValues]);

  const setBookingVal = (fieldName) => (e) => {
    setBookingValue((prev) => ({
      ...prev,
      [fieldName]: e,
    }));
  };

  const setOptionValues = (fieldName) => (e) => {
    setOptions((prev) => ({
      ...prev,
      [fieldName]: e,
    }));
  };

  const setDestyValues = (fieldName) => (e) => {
    if (fieldName === 'destinationCountry') {
      setUpdateCountry(true);
    }
    setDestinationValues((prev) => ({
      ...prev,
      [fieldName]: e,
    }));
  };

  const setDeliveryVal = (fieldName) => (e) => {
    setDeliveryValues((prev) => ({
      ...prev,
      [fieldName]: e,
    }));
  };

  const setOriginVal = (fieldName) => (e) => {
    setOriginValues((prev) => ({
      ...prev,
      [fieldName]: e,
    }));
  };

  const setPickUpVal = (fieldName) => (e) => {
    setPickUpValues((prev) => ({
      ...prev,
      [fieldName]: e,
    }));
  };

  const handleDestinationStatus = () => {
    if (selectedVendorType === 'primary') {
      setDestinationStatus(!destinationStatus);
    }
  };

  const handleOriginStatus = () => {
    if (selectedVendorType === 'primary') {
      setOriginStatus(!originStatus);
    }
  };

  const setEntityDetails = (fieldName) => (e) => {
    setConfirmEntity((prev) => ({ ...prev, [fieldName]: e }));
  };

  const getLoaderStatus = (e) => {
    setDisableButton(e);
  };

  useEffect(() => {
    if (bookingValue?.mode?.value === 'Inland') {
      setBookingVal('typeOfMove')({
        label: 'Door to Door',
        value: 'door to door',
      });
    }
  }, [bookingValue?.mode?.value]);

  if (profileType === 'buyer' && bookingValue?.mode?.value) {
    if (
      (bookingValue?.typeOfMove?.value === 'port to port' ||
        bookingValue?.mode?.value === 'Inland') &&
      (primaryBookingCompleted || bookingInfo?.directedBooking?.primary)
    ) {
      toggleViewDetailsModal();
      searchParams.delete('popover');
      searchParams.delete('popId');
      setSearchParams(searchParams);
    } else if (
      (primaryBookingCompleted || bookingInfo?.directedBooking?.primary) &&
      (bookingValue?.mode?.value !== 'Inland' &&
      bookingValue?.typeOfMove?.value?.includes('door to')
        ? pickupBookingCompleted || bookingInfo?.directedBooking?.pickup
        : true) &&
      (bookingValue?.mode?.value !== 'Inland' &&
      bookingValue?.typeOfMove?.value?.includes('to door')
        ? deliveryBookingCompleted || bookingInfo?.directedBooking?.delivery
        : true)
    ) {
      toggleViewDetailsModal();
      searchParams.delete('popover');
      searchParams.delete('popId');
      setSearchParams(searchParams);
    }
  }

  if (profileType === 'supplier' && bookingValue?.mode?.value) {
    if (bookingValue?.mode?.value === 'Inland' && primaryBookingCompleted) {
      toggleViewDetailsModal();
      searchParams.delete('popover');
      searchParams.delete('popId');
      setSearchParams(searchParams);
    } else if (
      (bookingInfo?.directedBooking?.primary ||
        bookingInfo?.directedBooking?.pickup ||
        bookingInfo?.directedBooking?.delivery) &&
      (bookingInfo?.directedBooking?.primary
        ? primaryBookingCompleted
        : true) &&
      (bookingInfo?.directedBooking?.pickup ? pickupBookingCompleted : true) &&
      (bookingInfo?.directedBooking?.delivery ? deliveryBookingCompleted : true)
    ) {
      toggleViewDetailsModal();
      searchParams.delete('popover');
      searchParams.delete('popId');
      setSearchParams(searchParams);
    }
  }

  const { channelPartnerData } = useSelector((state) => state.omniDetails);

  // to style left menu buttons in case of Send to Supplier
  const isWarning = (type) => {
    const isBooking = bookingInfo?.directedBooking?.[type.toLowerCase()];
    const isBookingList = bookingList?.find(
      (obj) => obj?.bookingType.toLowerCase() === type.toLowerCase()
    );

    let isSwitch;
    if (type === 'primary') {
      isSwitch = shipmentSwitchTo;
    } else if (type === 'pickUp') {
      isSwitch = pickUpSwitchTo;
    } else if (type === 'delivery') {
      isSwitch = deliverySwitchTo;
    }

    return (
      profileType === 'buyer' &&
      (isSwitch || isBooking) &&
      !isBookingList &&
      !(
        (type === 'pickUp' && pickUpHandledByPrimary) ||
        (type === 'delivery' && deliveryHandledByPrimary)
      )
    );
  };
  // to disable Pick-up and/or Delivery buttons in left menu
  const isDisabled = (type) => {
    const isBuyer = profileType === 'buyer';

    if (type === 'Primary') {
      return disablePrimaryAtSupplier;
    }

    if (type === 'Pickup') {
      if (isBuyer) {
        if (!partiallyBooked && !(isOriginDoor && !originStatus)) {
          return true;
        }
        if (partiallyBooked && !isOriginDoor) {
          return true;
        }
        if (pickUpHandledByPrimary) {
          return true;
        }
        return false;
      }

      if (disablePickupAtSupplier) {
        return true;
      }
      if (!(isOriginDoor && !originStatus)) {
        return true;
      }
      return false;
    }

    if (type === 'Delivery') {
      if (isBuyer) {
        if (!partiallyBooked && !(isDestinationDoor && !destinationStatus)) {
          return true;
        }
        if (partiallyBooked && !isDestinationDoor) {
          return true;
        }
        if (deliveryHandledByPrimary) {
          return true;
        }
        return false;
      }

      if (disableDeliveryAtSupplier) {
        return true;
      }
      if (!(isDestinationDoor && !destinationStatus)) {
        return true;
      }
      return false;
    }

    return false;
  };
  const isDisabledIcon = (type) => {
    const isBuyer = profileType === 'buyer';

    if (type === 'Primary') {
      return disablePrimaryAtSupplier;
    }

    if (type === 'Pickup') {
      if (isBuyer) {
        if (!partiallyBooked && !(isOriginDoor && !originStatus)) {
          return <CreateBookingDisabledIcon />;
        }
        if (partiallyBooked && !isOriginDoor) {
          return <CreateBookingDisabledIcon />;
        }
        if (pickUpHandledByPrimary) {
          return <CreateBookingDisabledIcon />;
        }
        return false;
      }

      if (disablePickupAtSupplier) {
        return <CreateBookingDisabledIcon />;
      }
      if (!(isOriginDoor && !originStatus)) {
        return <CreateBookingDisabledIcon />;
      }
      return false;
    }

    if (type === 'Delivery') {
      if (isBuyer) {
        if (!partiallyBooked && !(isDestinationDoor && !destinationStatus)) {
          return <CreateBookingDisabledIcon />;
        }
        if (partiallyBooked && !isDestinationDoor) {
          return <CreateBookingDisabledIcon />;
        }
        if (deliveryHandledByPrimary) {
          return <CreateBookingDisabledIcon />;
        }
        return false;
      }

      if (disableDeliveryAtSupplier) {
        return <CreateBookingDisabledIcon />;
      }
      if (!(isDestinationDoor && !destinationStatus)) {
        return <CreateBookingDisabledIcon />;
      }
      return false;
    }

    return false;
  };
  // const isDisabledColor = (type) => {
  //   const isBuyer = profileType === 'buyer';

  //   if (type === 'Primary') {
  //     return disablePrimaryAtSupplier;
  //   }

  //   if (type === 'Pickup') {
  //     if (isBuyer) {
  //       if (!partiallyBooked && !(isOriginDoor && !originStatus)) {
  //         return '#616B80';
  //       }
  //       if (partiallyBooked && !isOriginDoor) {
  //         return '#616B80';
  //       }
  //       if (pickUpHandledByPrimary) {
  //         return '#616B80';
  //       }
  //       return false;
  //     }

  //     if (disablePickupAtSupplier) {
  //       return '#616B80';
  //     }
  //     if (!(isOriginDoor && !originStatus)) {
  //       return '#616B80';
  //     }
  //     return false;
  //   }

  //   if (type === 'Delivery') {
  //     if (isBuyer) {
  //       if (!partiallyBooked && !(isDestinationDoor && !destinationStatus)) {
  //         return '#616B80';
  //       }
  //       if (partiallyBooked && !isDestinationDoor) {
  //         return '#616B80';
  //       }
  //       if (deliveryHandledByPrimary) {
  //         return '#616B80';
  //       }
  //       return false;
  //     }

  //     if (disableDeliveryAtSupplier) {
  //       return '#616B80';
  //     }
  //     if (!(isDestinationDoor && !destinationStatus)) {
  //       return '#616B80';
  //     }
  //     return false;
  //   }

  //   return false;
  // };

  // to assign className for styling left menu buttons
  const getClassName = (type) => {
    let isDone;
    if (type === 'primary') {
      isDone = buyerAction?.addPrimary || primaryBookingCompleted;
    } else if (type === 'pickUp') {
      isDone = buyerAction?.addPickup || pickupBookingCompleted;
    } else if (type === 'delivery') {
      isDone = buyerAction?.addDelivery || deliveryBookingCompleted;
    }
    const formattedType =
      type.charAt(0).toUpperCase() + type.toLowerCase().slice(1);

    return `
      ${
        selectedVendorType === type &&
        !buyerAction[`add${formattedType}`] &&
        !(isWarning(type) && selectedVendorType === type)
          ? ' active-selected'
          : ''
      }${isDone && selectedVendorType === type ? ' done-selected' : ''}${
      isDone && selectedVendorType !== type ? ' done' : ''
    }${isWarning(type) && selectedVendorType !== type ? ' warning' : ''}${
      isWarning(type) && selectedVendorType === type ? ' warning-selected' : ''
    }
    
    `;
  };
  const getIcons = (type) => {
    let isDone;
    if (type === 'primary') {
      isDone = buyerAction?.addPrimary || primaryBookingCompleted;
    } else if (type === 'pickUp') {
      isDone = buyerAction?.addPickup || pickupBookingCompleted;
    } else if (type === 'delivery') {
      isDone = buyerAction?.addDelivery || deliveryBookingCompleted;
    }
    const formattedType =
      type.charAt(0).toUpperCase() + type.toLowerCase().slice(1);
    return (
      <>
        {selectedVendorType === type &&
          !buyerAction[`add${formattedType}`] &&
          !(
            isWarning(type) &&
            (selectedVendorType === type || selectedVendorType !== type)
          ) && <CreateBookingCurrentIcon />}

        {isDone && selectedVendorType === type && (
          <CreateBookingCompletedIcon />
        )}

        {isDone && selectedVendorType !== type && (
          <CreateBookingCompletedIcon />
        )}

        {/* {isWarning(type) && selectedVendorType === type && (
          <CreateBookingCurrentIcon />
        )} */}

        {isWarning(type) && selectedVendorType !== type && (
          <CreateBookingWarningIcon />
        )}
        {!(
          selectedVendorType === type && !buyerAction[`add${formattedType}`]
        ) &&
        !(isDone && selectedVendorType === type) &&
        !(isDone && selectedVendorType !== type) &&
        !(
          isWarning(type) &&
          (selectedVendorType === type || selectedVendorType !== type)
        ) ? (
          <CreateBookingPendingIcon />
        ) : null}
        {isWarning(type) && selectedVendorType === type ? (
          <CreateBookingWarningIcon />
        ) : (
          ''
        )}
      </>
    );
  };

  // const getColors = (type) => {
  //   let isDone;
  //   if (type === 'primary') {
  //     isDone = buyerAction?.addPrimary || primaryBookingCompleted;
  //   } else if (type === 'pickUp') {
  //     isDone = buyerAction?.addPickup || pickupBookingCompleted;
  //   } else if (type === 'delivery') {
  //     isDone = buyerAction?.addDelivery || deliveryBookingCompleted;
  //   }
  //   const formattedType =
  //     type.charAt(0).toUpperCase() + type.toLowerCase().slice(1);
  //   return `
  //       ${
  //         selectedVendorType === type &&
  //         !buyerAction[`add${formattedType}`] &&
  //         !(
  //           isWarning(type) &&
  //           (selectedVendorType === type || selectedVendorType !== type)
  //         )
  //           ? '#0088FA'
  //           : ''
  //       }${isDone && selectedVendorType === type ? '#55B938' : ''}${
  //     isDone && selectedVendorType !== type ? '#55B938' : ''
  //   }${isWarning(type) && selectedVendorType === type ? '#0088FA' : ''}${
  //     isWarning(type) && selectedVendorType !== type ? '#f29c4c' : ''
  //   }${
  //     !(selectedVendorType === type && !buyerAction[`add${formattedType}`]) &&
  //     !(isDone && selectedVendorType === type) &&
  //     !(isDone && selectedVendorType !== type) &&
  //     !(
  //       isWarning(type) &&
  //       (selectedVendorType === type || selectedVendorType !== type)
  //     )
  //       ? '#616B80'
  //       : ''
  //   }
  //   `;
  // };
  useEffect(() => {
    setRefreshForm(false);
    setTimeout(() => {
      setRefreshForm(true);
    }, [100]);
  }, [callRefreshForm]);

  return (
    <>
      {/* {loading && <GlobalLoader newLoad={true} height={80} width={80} />}
      {!loading && ( */}
      <div className="supplier-booking-container">
        <DialogPanel
          title={t('manageordersscreen.createbookingmenu.title')}
          large="booking-details-title"
        >
          <DialogPanelBody>
            {loading && (
              <GlobalLoader newLoad={true} height="6.944vw" width="6.944vw" />
            )}
            {!loading && (
              <>
                <div className="custom-tabs supplier-booking-wrapper">
                  <ul className="custom-tab-list">
                    <li
                      className={`custom-tab-nav-item ${
                        bookingDetails ? 'done' : ''
                      } ${supplierTab === 'booking details' ? 'current' : ''}`}
                    >
                      <button
                        type="button"
                        onClick={() => setSupplierTab('booking details')}
                      >
                        <BookingDetailsIcon />
                        {t('manageordersscreen.createbookingmenu.tabtitle')}
                      </button>
                    </li>
                    <li
                      className={`custom-tab-nav-item ${
                        shipmentDetails ? 'done' : ''
                      } ${supplierTab === 'shipment details' ? 'current' : ''}`}
                    >
                      <button
                        type="button"
                        onClick={() => {
                          setBookingDetails(true);
                          setSupplierTab('shipment details');
                          if (profileType === 'buyer') {
                            if (partiallyBooked && !buyerAction?.addPrimary) {
                              setSelectedVendorType('primary');
                            } else if (
                              partiallyBooked &&
                              !buyerAction?.addPickup &&
                              (bookingValue?.typeOfMove?.value ===
                                'door to port' ||
                                bookingValue?.typeOfMove?.value ===
                                  'door to door')
                            ) {
                              setSelectedVendorType('pickUp');
                            } else if (
                              partiallyBooked &&
                              !buyerAction?.addDelivery &&
                              (bookingValue?.typeOfMove?.value ===
                                'door to door' ||
                                bookingValue?.typeOfMove?.value ===
                                  'port to door')
                            ) {
                              setSelectedVendorType('delivery');
                            } else {
                              setSelectedVendorType('primary');
                            }
                          }
                        }}
                      >
                        <ShipmentIcon />
                        {t(
                          'manageordersscreen.createbookingmenu.shipmentdetails.title'
                        )}
                      </button>
                    </li>
                    <li
                      className={`custom-tab-nav-item ${
                        confirmEntities ? 'done' : ''
                      } ${supplierTab === 'confirm entity' ? 'current' : ''}`}
                    >
                      <button
                        type="button"
                        disabled={disableNxtShip}
                        onClick={() => {
                          setShipmentDetails(true);
                          setSupplierTab('confirm entity');
                          if (profileType === 'buyer') {
                            if (partiallyBooked && !buyerAction?.addPrimary) {
                              setSelectedVendorType('primary');
                            } else if (
                              partiallyBooked &&
                              !buyerAction?.addPickup &&
                              (bookingValue?.typeOfMove?.value ===
                                'door to port' ||
                                bookingValue?.typeOfMove?.value ===
                                  'door to door')
                            ) {
                              setSelectedVendorType('pickUp');
                            } else if (
                              partiallyBooked &&
                              !buyerAction?.addDelivery &&
                              (bookingValue?.typeOfMove?.value ===
                                'door to door' ||
                                bookingValue?.typeOfMove?.value ===
                                  'port to door')
                            ) {
                              setSelectedVendorType('delivery');
                            } else {
                              setSelectedVendorType('primary');
                            }
                          }
                        }}
                      >
                        <ConfirmEntitiesIcon />
                        {t(
                          'manageordersscreen.createbookingmenu.confirmentities.title'
                        )}
                      </button>
                    </li>
                    <li
                      className={`custom-tab-nav-item ${
                        addForworder ? 'done' : ''
                      } ${supplierTab === 'add forworder' ? 'current' : ''}`}
                    >
                      <button
                        type="button"
                        disabled={disableNxtShip}
                        onClick={() => {
                          setConfirmEntities(true);
                          setUpdate(true);
                          setSupplierTab('add forworder');
                          if (profileType === 'buyer') {
                            if (partiallyBooked && !buyerAction?.addPrimary) {
                              setSelectedVendorType('primary');
                            } else if (
                              partiallyBooked &&
                              !buyerAction?.addPickup &&
                              (bookingValue?.typeOfMove?.value ===
                                'door to port' ||
                                bookingValue?.typeOfMove?.value ===
                                  'door to door')
                            ) {
                              setSelectedVendorType('pickUp');
                            } else if (
                              partiallyBooked &&
                              !buyerAction?.addDelivery &&
                              (bookingValue?.typeOfMove?.value ===
                                'door to door' ||
                                bookingValue?.typeOfMove?.value ===
                                  'port to door')
                            ) {
                              setSelectedVendorType('delivery');
                            } else {
                              setSelectedVendorType('primary');
                            }
                          }
                        }}
                      >
                        <AddForwarderIcon />
                        {t(
                          'manageordersscreen.createbookingmenu.addforwarder.title'
                        )}
                      </button>
                    </li>
                  </ul>
                  {supplierTab === 'booking details' && (
                    <div className="supplier-booking-content">
                      <div className="d-flex justify-between align-center">
                        <h3>
                          {t(
                            'manageordersscreen.bookingdetailsbtn.description'
                          )}
                        </h3>
                        {false && (
                          <Button
                            styles="primary"
                            color="outline"
                            label="Load Calculator"
                            iconRight={<BagTickIcon />}
                            spacing="0.208vw"
                            size="medium"
                          />
                        )}
                      </div>
                      <AccordionPanel
                        title={t(
                          'manageordersscreen.bookingdetailsbtn.addpackage'
                        )}
                      >
                        {bookingInfo?.packages && (
                          <BesicTable
                            columns={columns}
                            // bookingdetails.packages
                            data={bookingInfo?.packages}
                            className="supplier-booking"
                          />
                        )}
                      </AccordionPanel>
                      {false && (
                        <CommentSectionAccordion
                          title={t(
                            'manageordersscreen.bookingdetailsbtn.packagenotes'
                          )}
                        />
                      )}
                      <div className="supplier-booking-button-area">
                        <Button
                          label={t('manageordersscreen.bookingdetailsbtn.next')}
                          styles="submit"
                          size="large"
                          className="px-24"
                          onClick={() => {
                            setBookingDetails(true);
                            setSupplierTab('shipment details');
                          }}
                          iconRight={<NextIcon />}
                          spacing="0.694vw"
                        />
                      </div>
                    </div>
                  )}
                  {supplierTab === 'shipment details' && (
                    <div className="supplier-booking-content">
                      <div className="add-shipment-wrapper">
                        <div className="add-shipment-left">
                          <PanelCard
                            cardTitle={t(
                              'manageordersscreen.createbookingmenu.shipmentdetails.title'
                            )}
                            noShadow
                            border
                            disabled={partiallyBooked}
                          >
                            {/* <AddShipment
                        mode={bookingValue.mode}
                        containerType={bookingValue.containerType}
                        typeOfMove={bookingValue.typeOfMove}
                        equipmentType={bookingValue.equipmentType}
                        incoTerm={bookingValue.incoTerm}
                        equipTypeQty={bookingValue.equipTypeQty}
                        setEquipTypeQty={setBookingVal('equipTypeQty')}
                        setMode={setBookingVal('mode')}
                        setContainerType={setBookingVal('containerType')}
                        setEquipmentType={setBookingVal('equipmentType')}
                        setTypeOfMove={setBookingVal('typeOfMove')}
                        setIncoTerm={setBookingVal('incoTerm')}
                      /> */}
                            {refreshForm && (
                              <AddShipment
                                mode={bookingValue?.mode}
                                containerType={bookingValue?.containerType}
                                cargoLoad={bookingValue?.cargoLoad}
                                typeOfMove={bookingValue?.typeOfMove}
                                equipmentType={bookingValue?.equipmentType}
                                incoTerm={bookingValue?.incoTerm}
                                equipTypeQty={bookingValue?.equipTypeQty}
                                numberOfTrucks={bookingValue?.numberOfTrucks}
                                setEquipTypeQty={setBookingVal('equipTypeQty')}
                                setMode={(e) => {
                                  if (
                                    e.value !== '' &&
                                    (originValues?.origin !== '' ||
                                      // typeOfMove.value !== undefined
                                      bookingValue?.typeOfMove?.value !==
                                        undefined)
                                  ) {
                                    toggleDialog();
                                    setConfirmationData(e);
                                    setConfirmationMessage(
                                      t(
                                        'manageordersscreen.createbookingmenu.shipmentdetails.modeConfirmation'
                                      )
                                    );
                                    setConfirmationField('mode');
                                  } else if (
                                    e.value !== '' &&
                                    originValues?.origin === '' &&
                                    (typeOfMove.value === '' ||
                                      typeOfMove.value === undefined)
                                  ) {
                                    setBookingVal('mode')(e);
                                  }
                                }}
                                changeMode={(field) => {
                                  if (
                                    field === 'mode' &&
                                    bookingValue?.mode?.value &&
                                    (originValues?.origin ||
                                      bookingValue?.typeOfMove?.value)
                                  ) {
                                    toggleDialog();
                                    setConfirmationData({
                                      label: '',
                                      value: '',
                                    });
                                    setConfirmationMessage(
                                      t(
                                        'manageordersscreen.createbookingmenu.shipmentdetails.modeConfirmation'
                                      )
                                    );
                                    setConfirmationField(field);
                                  } else if (
                                    field === 'mode' &&
                                    bookingValue?.mode?.value &&
                                    (!originValues?.origin || !typeOfMove.value)
                                  ) {
                                    setBookingVal('mode')({
                                      label: '',
                                      value: '',
                                    });
                                  }
                                }}
                                setContainerType={setBookingVal(
                                  'containerType'
                                )}
                                setCargoLoad={setBookingVal('cargoLoad')}
                                setEquipmentType={setBookingVal(
                                  'equipmentType'
                                )}
                                setTypeOfMove={setBookingVal('typeOfMove')}
                                setIncoTerm={setBookingVal('incoTerm')}
                                setNumberOfTrucks={setBookingVal(
                                  'numberOfTrucks'
                                )}
                                disableMode={isActivateDislog}
                              />
                            )}
                          </PanelCard>
                        </div>
                        <div className="add-shipment-right">
                          <PanelCard
                            cardTitle={t(
                              'manageordersscreen.createbookingmenu.shipmentdetails.addaddressdetails'
                            )}
                            noShadow
                            border
                            disabled={
                              partiallyBooked ||
                              Object.keys(bookingValue?.mode)?.length === 0 ||
                              Object.keys(bookingValue?.typeOfMove)?.length ===
                                0
                            }
                          >
                            <AddAddress
                              originLabel={
                                bookingValue?.mode?.value === 'Inland'
                                  ? t(
                                      'manageordersscreen.createbookingmenu.shipmentdetails.origindoor'
                                    )
                                  : t(
                                      'manageordersscreen.createbookingmenu.shipmentdetails.originport'
                                    )
                              }
                              destinationLabel={
                                bookingValue?.mode?.value === 'Inland'
                                  ? t(
                                      'manageordersscreen.createbookingmenu.shipmentdetails.destinationdoor'
                                    )
                                  : t(
                                      'manageordersscreen.createbookingmenu.shipmentdetails.destinationport'
                                    )
                              }
                              Mode={bookingValue?.mode}
                              TypeOfMove={
                                bookingValue?.mode?.value === 'Inland'
                                  ? {
                                      label: 'Door to Door',
                                      value: 'door to door',
                                    }
                                  : {
                                      label: 'Port to Port',
                                      value: 'port to port',
                                    }
                              }
                              origin={originValues.origin}
                              destination={destinationValues?.destination}
                              setOrigin={setOriginVal('origin')}
                              setDestination={setDestyValues('destination')}
                              originOptions={options?.originOptions}
                              setOrginOptions={setOptionValues('originOptions')}
                              destinationOptions={options?.destinationOptions}
                              setDestinationOptions={setOptionValues(
                                'destinationOptions'
                              )}
                              originGeoCode={originValues?.originGeoCode}
                              setOriginGeoCode={setOriginVal('originGeoCode')}
                              destinationGeoCode={
                                destinationValues?.destinationGeoCode
                              }
                              setDestinationGeoCode={setDestyValues(
                                'destinationGeoCode'
                              )}
                              setOriginDoorAddress={(e) => {
                                setOriginVal('originDoorAddress')(e);
                                setOriginVal('originCountry')(
                                  fetchAddress(
                                    'origin',
                                    'country',
                                    e,
                                    destinationValues?.destinationDoorAddress,
                                    originValues?.originDoorAddress
                                  )
                                );
                              }}
                              disable={
                                partiallyBooked ||
                                Object.keys(bookingValue?.mode)?.length === 0 ||
                                Object.keys(bookingValue?.typeOfMove)
                                  ?.length === 0
                              }
                              setDestinationDoorAddress={(e) => {
                                setDestyValues('destinationDoorAddress')(e);
                                setDestyValues('destinationCountry')(
                                  fetchAddress(
                                    'destination',
                                    'country',
                                    e,
                                    destinationValues?.destinationDoorAddress,
                                    originValues?.originDoorAddress
                                  )
                                );
                              }}
                              setOriginCountry={setOriginVal('originCountry')}
                              setDestinationCountry={setDestyValues(
                                'destinationCountry'
                              )}
                            />
                          </PanelCard>
                        </div>
                      </div>
                      <div className="supplier-booking-button-area justify-between">
                        <Button
                          label={t(
                            'manageordersscreen.createbookingmenu.shipmentdetails.back'
                          )}
                          styles="back"
                          size="large"
                          icon={<PrevIcon />}
                          spacing="0.694vw"
                          className="px-24"
                          onClick={() => {
                            setSupplierTab('booking details');
                            // setSelectedVendorType('primary');
                          }}
                        />
                        <Button
                          label={t(
                            'manageordersscreen.createbookingmenu.shipmentdetails.next'
                          )}
                          styles="submit"
                          size="large"
                          disabled={disableNxtShip}
                          onClick={() => {
                            setShipmentDetails(true);
                            setSupplierTab('confirm entity');
                          }}
                          iconRight={<NextIcon />}
                          spacing="0.694vw"
                          className="px-24"
                        />
                      </div>
                    </div>
                  )}
                  {supplierTab === 'confirm entity' && (
                    <div className="supplier-booking-content">
                      <div className="add-document-wrapper">
                        <div className="d-flex w-100 add-document">
                          <div className="add-document-wrap">
                            <PanelCard
                              cardTitle={t(
                                'manageordersscreen.createbookingmenu.confirmentities.title'
                              )}
                              noShadow
                              border
                              disabled={partiallyBooked}
                            >
                              <ConfirmEntity
                                buyerDetails={`${bookingInfo?.buyerDetails?.orgName} <br/>${bookingInfo?.buyerDetails?.orgAddress}`}
                                supplierDetails={`${bookingInfo?.supplierDetails?.orgName} <br/>${bookingInfo?.supplierDetails?.orgAddress}`}
                                setNotificationDetail={setEntityDetails(
                                  'notificationDetail'
                                )}
                                notificationDetail={
                                  confirmEntity.notificationDetail
                                }
                                consigneeDetail={confirmEntity.consigneeDetail}
                                setConsigneeDetail={setEntityDetails(
                                  'consigneeDetail'
                                )}
                              />
                            </PanelCard>
                          </div>
                        </div>
                        <div className="d-flex justify-between w-100">
                          <Button
                            label={t(
                              'manageordersscreen.createbookingmenu.confirmentities.back'
                            )}
                            styles="back"
                            size="large"
                            icon={<PrevIcon />}
                            spacing="0.694vw"
                            className="px-24"
                            onClick={() => {
                              setSupplierTab('shipment details');
                            }}
                          />
                          <Button
                            label={t(
                              'manageordersscreen.createbookingmenu.confirmentities.next'
                            )}
                            styles="submit"
                            size="large"
                            iconRight={<NextIcon />}
                            onClick={() => {
                              setConfirmEntities(true);
                              setUpdate(true);
                              setSupplierTab('add forworder');
                            }}
                            spacing="0.694vw"
                            className="px-24"
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {supplierTab === 'add forworder' && (
                    <div className="supplier-booking-content">
                      <div className="add-forwarder-wrapper d-flex justify-center">
                        {bookingValue?.mode?.value !== 'Inland' &&
                        (isOriginDoor || isDestinationDoor) ? (
                          <div className="add-forwarder-left">
                            <ul className="left-tab">
                              <li>
                                <button
                                  type="button"
                                  disabled={disablePrimaryAtSupplier}
                                  onClick={() =>
                                    setSelectedVendorType('primary')
                                  }
                                  className={getClassName('primary')}
                                >
                                  {getIcons('primary')}

                                  {t(
                                    'manageordersscreen.createbookingmenu.addforwarder.primaryvendor.title'
                                  )}
                                  {/* <AddForwarderIcon
                                    size="14"
                                    color={getColors('primary')}
                                  /> */}
                                </button>
                              </li>

                              {!channelPartnerData?.eventAction?.disableBookingEvent?.includes(
                                'InlandBooking'
                              ) && (
                                <>
                                  <li>
                                    <button
                                      type="button"
                                      onClick={() =>
                                        setSelectedVendorType('pickUp')
                                      }
                                      disabled={isDisabled('Pickup')}
                                      className={
                                        isDisabled('Pickup')
                                          ? ''
                                          : getClassName('pickUp')
                                      }
                                    >
                                      {!isDisabledIcon('Pickup')
                                        ? getIcons('pickUp')
                                        : isDisabledIcon('Pickup')}
                                      {t(
                                        'manageordersscreen.createbookingmenu.addforwarder.pickupvendor.title'
                                      )}
                                      {/* <ShipmentIcon
                                        size="14"
                                        color={
                                          isDisabledColor('Pickup')
                                            ? isDisabledColor('Pickup')
                                            : getColors('pickUp')
                                        }
                                      /> */}
                                    </button>
                                  </li>
                                  <li>
                                    <button
                                      type="button"
                                      onClick={() =>
                                        setSelectedVendorType('delivery')
                                      }
                                      className={
                                        isDisabled('Delivery')
                                          ? ''
                                          : getClassName('delivery')
                                      }
                                      disabled={isDisabled('Delivery')}
                                    >
                                      {!isDisabledIcon('Delivery')
                                        ? getIcons('delivery')
                                        : isDisabledIcon('Delivery')}
                                      {t(
                                        'manageordersscreen.createbookingmenu.addforwarder.deliveryvendor.title'
                                      )}
                                      {/* <TruckTime
                                        size="14"
                                        color={
                                          isDisabledColor('Delivery')
                                            ? isDisabledColor('Delivery')
                                            : getColors('delivery')
                                        }
                                      /> */}
                                    </button>
                                  </li>
                                </>
                              )}
                            </ul>
                          </div>
                        ) : null}

                        <div className="add-forwarder-right">
                          <PanelCard noShadow bodyClassName="pt-0">
                            {selectedVendorType === 'primary' && (
                              <>
                                <div className="d-flex justify-between mb-0">
                                  <h3 className="add-forwarder-title">
                                    {t(
                                      'manageordersscreen.createbookingmenu.addforwarder.primaryvendor.title'
                                    )}
                                  </h3>
                                  <div className="d-flex align-items-center">
                                    {profileType === 'buyer' &&
                                      !buyerAction?.addPrimary && (
                                        <>
                                          <label className="pickup-label">
                                            {t(
                                              'manageordersscreen.createbookingmenu.addforwarder.primaryvendor.shipmentsupplierbtn'
                                            )}
                                          </label>

                                          <CustomSwitch
                                            className="pickup-switch"
                                            onChange={(e) => {
                                              setShipmentSwitchTo(
                                                e?.target?.checked
                                              );
                                            }}
                                            defaultChecked={shipmentSwitchTo}
                                            disabled={
                                              buyerAction?.addPrimary ||
                                              bookingInfo?.directedBooking
                                                ?.primary
                                            }
                                          />
                                        </>
                                      )}
                                    {((profileType === 'buyer' &&
                                      !buyerAction?.addPrimary) ||
                                      (profileType === 'supplier' &&
                                        bookingInfo?.bookAction?.primary)) && (
                                      <Button
                                        icon={<UploadDocumentIcon />}
                                        styles="icon"
                                        className="ml-10"
                                        onClick={toggleUploadDialog}
                                        disabled={
                                          profileType === 'buyer'
                                            ? buyerAction?.addPrimary
                                            : !bookingInfo?.bookAction?.primary
                                        }
                                      />
                                    )}
                                    <Dialog
                                      isShowing={isActivateUploadDislog}
                                      hide={toggleUploadDialog}
                                      placement="middle"
                                      maskZindex={1041}
                                    >
                                      <PanelCard
                                        cardTitle={t(
                                          'manageordersscreen.createbookingmenu.addforwarder.primaryvendor.uploaddocuments'
                                        )}
                                        noShadow
                                        border
                                      >
                                        <UploadDocument
                                          selectedFiles={primarySelectedFiles}
                                          Width="400px"
                                          onUpload={(data) => {
                                            setPrimaryAttachment(data);
                                            // setAddDocuments(true);
                                          }}
                                          attachment={primaryAttachment}
                                          getLoaderStatus={getLoaderStatus}
                                          setSelectedFiles={
                                            setPrimarySelectedFiles
                                          }
                                          multiple
                                          enableSelectFileFor="primary"
                                          preUploadedFiles={
                                            supplierUploadedDocs
                                          }
                                          onSelectUploadedDocs={
                                            updateSupplierUploadedDocs
                                          }
                                        />
                                        <PanelCardFooter align="right">
                                          <Button
                                            label={t(
                                              'manageordersscreen.createbookingmenu.addforwarder.primaryvendor.add'
                                            )}
                                            color="submit"
                                            disabled={disableButton}
                                            onClick={() => {
                                              toggleUploadDialog();
                                            }}
                                          >
                                            Add
                                          </Button>
                                        </PanelCardFooter>
                                      </PanelCard>
                                    </Dialog>
                                  </div>
                                </div>
                                <p className="mode-of-shipment">
                                  {t(
                                    'manageordersscreen.createbookingmenu.addforwarder.primaryvendor.origin'
                                  )}{' '}
                                  {originStatus
                                    ? 'Door'
                                    : t(
                                        'manageordersscreen.createbookingmenu.addforwarder.primaryvendor.port'
                                      )}{' '}
                                  {t(
                                    'manageordersscreen.createbookingmenu.addforwarder.primaryvendor.to'
                                  )}{' '}
                                  {t(
                                    'manageordersscreen.createbookingmenu.addforwarder.primaryvendor.destination'
                                  )}{' '}
                                  {destinationStatus
                                    ? 'Door'
                                    : t(
                                        'manageordersscreen.createbookingmenu.addforwarder.primaryvendor.port'
                                      )}
                                </p>
                              </>
                            )}
                            {selectedVendorType === 'pickUp' && (
                              <>
                                <div className="d-flex justify-between mb-0">
                                  <h3 className="add-forwarder-title">
                                    {t(
                                      'manageordersscreen.createbookingmenu.addforwarder.pickupvendor.title'
                                    )}
                                  </h3>
                                  <div className="d-flex align-items-center">
                                    {profileType === 'buyer' &&
                                      !buyerAction?.addPickup && (
                                        <>
                                          <label className="pickup-label">
                                            {t(
                                              'manageordersscreen.createbookingmenu.addforwarder.pickupvendor.pickupdone'
                                            )}
                                          </label>

                                          <CustomSwitch
                                            className="pickup-switch"
                                            onChange={(e) => {
                                              setPickUpSwitchTo(
                                                e?.target?.checked
                                              );
                                            }}
                                            defaultChecked={pickUpSwitchTo}
                                            disabled={
                                              buyerAction?.addPickup ||
                                              bookingInfo?.directedBooking
                                                ?.pickup
                                            }
                                          />
                                        </>
                                      )}
                                    {((profileType === 'buyer' &&
                                      !buyerAction?.addPickup) ||
                                      (profileType === 'supplier' &&
                                        bookingInfo?.bookAction?.pickup)) && (
                                      <Button
                                        icon={<UploadDocumentIcon />}
                                        styles="icon"
                                        className="ml-10"
                                        onClick={toggleUploadDialog}
                                        disabled={
                                          profileType === 'buyer'
                                            ? buyerAction?.addPickup
                                            : !bookingInfo?.bookAction?.pickup
                                        }
                                      />
                                    )}
                                    <Dialog
                                      isShowing={isActivateUploadDislog}
                                      hide={toggleUploadDialog}
                                      placement="middle"
                                      maskZindex={1041}
                                    >
                                      <PanelCard
                                        cardTitle={t(
                                          'manageordersscreen.createbookingmenu.addforwarder.pickupvendor.uploaddocuments'
                                        )}
                                        noShadow
                                        border
                                      >
                                        <UploadDocument
                                          selectedFiles={pickUpSelectedFiles}
                                          Width="400px"
                                          onUpload={(data) => {
                                            setPickUpAttachment(data);
                                            // setAddDocuments(true);
                                          }}
                                          attachment={pickUpAttachment}
                                          getLoaderStatus={getLoaderStatus}
                                          setSelectedFiles={
                                            setPickUpSelectedFiles
                                          }
                                          multiple
                                          enableSelectFileFor="pickup"
                                          preUploadedFiles={
                                            supplierUploadedDocs
                                          }
                                          onSelectUploadedDocs={
                                            updateSupplierUploadedDocs
                                          }
                                        />
                                        <PanelCardFooter align="right">
                                          <Button
                                            label={t(
                                              'manageordersscreen.createbookingmenu.addforwarder.pickupvendor.add'
                                            )}
                                            color="submit"
                                            disabled={disableButton}
                                            onClick={() => {
                                              toggleUploadDialog();
                                            }}
                                          >
                                            Add
                                          </Button>
                                        </PanelCardFooter>
                                      </PanelCard>
                                    </Dialog>
                                  </div>
                                </div>
                                <p className="mode-of-shipment">
                                  {t(
                                    'manageordersscreen.createbookingmenu.addforwarder.pickupvendor.doortoport'
                                  )}
                                </p>
                              </>
                            )}
                            {selectedVendorType === 'delivery' && (
                              <>
                                <div className="d-flex justify-between mb-0">
                                  <h3 className="add-forwarder-title">
                                    {t(
                                      'manageordersscreen.createbookingmenu.addforwarder.deliveryvendor.title'
                                    )}
                                  </h3>
                                  <div className="d-flex align-items-center">
                                    {profileType === 'buyer' &&
                                      !buyerAction?.addDelivery && (
                                        <>
                                          <label className="pickup-label">
                                            {t(
                                              'manageordersscreen.createbookingmenu.addforwarder.deliveryvendor.deliverysupplier'
                                            )}
                                          </label>

                                          <CustomSwitch
                                            className="pickup-switch"
                                            onChange={(e) => {
                                              setDeliverySwitchTo(
                                                e?.target?.checked
                                              );
                                            }}
                                            defaultChecked={deliverySwitchTo}
                                            disabled={
                                              buyerAction?.addDelivery ||
                                              bookingInfo?.directedBooking
                                                ?.delivery
                                            }
                                          />
                                        </>
                                      )}
                                    {((profileType === 'buyer' &&
                                      !buyerAction?.addDelivery) ||
                                      (profileType === 'supplier' &&
                                        bookingInfo?.bookAction?.delivery)) && (
                                      <Button
                                        icon={<UploadDocumentIcon />}
                                        styles="icon"
                                        className="ml-10"
                                        onClick={toggleUploadDialog}
                                        disabled={
                                          profileType === 'buyer'
                                            ? buyerAction?.addDelivery
                                            : !bookingInfo?.bookAction?.delivery
                                        }
                                      />
                                    )}
                                    <Dialog
                                      isShowing={isActivateUploadDislog}
                                      hide={toggleUploadDialog}
                                      placement="middle"
                                      maskZindex={1041}
                                    >
                                      <PanelCard
                                        cardTitle={t(
                                          'manageordersscreen.createbookingmenu.addforwarder.deliveryvendor.uploaddocuments'
                                        )}
                                        noShadow
                                        border
                                      >
                                        <UploadDocument
                                          selectedFiles={deliverySelectedFiles}
                                          Width="400px"
                                          onUpload={(data) => {
                                            setDeliveryAttachment(data);
                                            // setAddDocuments(true);
                                          }}
                                          attachment={deliveryAttachment}
                                          getLoaderStatus={getLoaderStatus}
                                          setSelectedFiles={
                                            setDeliverySelectedFiles
                                          }
                                          multiple
                                          enableSelectFileFor="delivery"
                                          preUploadedFiles={
                                            supplierUploadedDocs
                                          }
                                          onSelectUploadedDocs={
                                            updateSupplierUploadedDocs
                                          }
                                        />
                                        <PanelCardFooter align="right">
                                          <Button
                                            label={t(
                                              'manageordersscreen.createbookingmenu.addforwarder.deliveryvendor.add'
                                            )}
                                            color="submit"
                                            disabled={disableButton}
                                            onClick={() => {
                                              toggleUploadDialog();
                                            }}
                                          >
                                            Add
                                          </Button>
                                        </PanelCardFooter>
                                      </PanelCard>
                                    </Dialog>
                                  </div>
                                </div>
                                <p className="mode-of-shipment">
                                  {t(
                                    'manageordersscreen.createbookingmenu.addforwarder.deliveryvendor.description'
                                  )}
                                </p>
                              </>
                            )}
                            <ShipmentProgress
                              activeOriginDoor={originStatus}
                              activeDestinationDoor={destinationStatus}
                              enableDestinationDoor={handleDestinationStatus}
                              enableOriginDoor={handleOriginStatus}
                              disableOriginDoor={
                                !isOriginDoor ||
                                buyerAction?.addPrimary ||
                                (profileType === 'buyer' &&
                                  bookingInfo?.directedBooking?.primary) ||
                                store
                                  .getState()
                                  .omniDetails?.channelPartnerData?.eventAction?.disableBookingEvent?.includes(
                                    'InlandBooking'
                                  ) ||
                                (profileType === 'supplier' &&
                                  pickupBookingCompleted)
                              }
                              disableDestinationDoor={
                                !isDestinationDoor ||
                                buyerAction?.addPrimary ||
                                (profileType === 'buyer' &&
                                  bookingInfo?.directedBooking?.primary) ||
                                store
                                  .getState()
                                  .omniDetails?.channelPartnerData?.eventAction?.disableBookingEvent?.includes(
                                    'InlandBooking'
                                  ) ||
                                (profileType === 'supplier' &&
                                  deliveryBookingCompleted)
                              }
                              selectedVendorType={selectedVendorType}
                              mode={bookingValue?.mode?.value}
                              typeOfMove={bookingValue.typeOfMove.value}
                            />
                            <div className="add-forwarder-container">
                              {selectedVendorType === 'primary' &&
                                ((profileType === 'buyer' &&
                                  !shipmentSwitchTo) ||
                                  profileType === 'supplier') && (
                                  <div className="form-row">
                                    <div className="icon">
                                      <ShipmentIcon size="1.667vw" />
                                    </div>
                                    <div className="form">
                                      <label className="label">
                                        {t(
                                          'manageordersscreen.createbookingmenu.addforwarder.primaryvendor.selectprimaryvendor'
                                        )}
                                        <span className="required">*</span>
                                      </label>
                                      {((profileType === 'buyer' &&
                                        !buyerAction?.addPrimary) ||
                                        (profileType === 'supplier' &&
                                          bookingInfo?.bookAction?.primary &&
                                          !primaryBookingCompleted)) && (
                                        <SelectForwarder
                                          mode="multi-select"
                                          setForwarder={setPrimaryForwarderList}
                                          forwarder={primaryForwarderList}
                                          selectedForwarders={
                                            selectedForwarders
                                          }
                                          behaviour="select"
                                        />
                                      )}
                                      {((buyerAction?.addPrimary &&
                                        profileType === 'buyer') ||
                                        (profileType === 'supplier' &&
                                          primaryBookingCompleted)) && (
                                        <TextBox
                                          disabled
                                          value={`${
                                            primaryBookingData
                                              ?.forwarderDetail?.[0].orgName
                                          } ${
                                            primaryBookingData?.forwarderDetail
                                              ?.length > 1
                                              ? '+'
                                              : ''
                                          }${
                                            primaryBookingData?.forwarderDetail
                                              ?.length > 1
                                              ? primaryBookingData
                                                  .forwarderDetail.length - 1
                                              : ''
                                          }`}
                                        />
                                      )}
                                    </div>
                                  </div>
                                )}
                              {selectedVendorType === 'pickUp' &&
                                ((profileType === 'buyer' && !pickUpSwitchTo) ||
                                  profileType === 'supplier') && (
                                  <div className="form-row">
                                    <div className="icon">
                                      <ShipmentIcon size="1.667vw" />
                                    </div>
                                    <div className="form">
                                      <label className="label">
                                        {t(
                                          'manageordersscreen.createbookingmenu.addforwarder.pickupvendor.selectpickupvendor'
                                        )}
                                        <span className="required">*</span>
                                      </label>
                                      {((profileType === 'buyer' &&
                                        !buyerAction?.addPickup) ||
                                        (profileType === 'supplier' &&
                                          !pickupBookingCompleted &&
                                          bookingInfo?.bookAction?.pickup)) && (
                                        <SelectForwarder
                                          mode="multi-select"
                                          setForwarder={setPickUpForwarderList}
                                          forwarder={pickUpForwarderList}
                                          selectedForwarders={
                                            selectedForwarders
                                          }
                                          behaviour="select"
                                        />
                                      )}
                                      {((buyerAction?.addPickup &&
                                        profileType === 'buyer') ||
                                        (profileType === 'supplier' &&
                                          pickupBookingCompleted)) && (
                                        <TextBox
                                          disabled
                                          value={`${
                                            pickUpBookingData
                                              ?.forwarderDetail?.[0].orgName
                                          } ${
                                            pickUpBookingData?.forwarderDetail
                                              ?.length > 1
                                              ? '+'
                                              : ''
                                          }${
                                            pickUpBookingData?.forwarderDetail
                                              ?.length > 1
                                              ? pickUpBookingData
                                                  .forwarderDetail.length - 1
                                              : ''
                                          }`}
                                        />
                                      )}
                                    </div>
                                  </div>
                                )}
                              {selectedVendorType === 'delivery' &&
                                ((profileType === 'buyer' &&
                                  !deliverySwitchTo) ||
                                  profileType === 'supplier') && (
                                  <div className="form-row">
                                    <div className="icon">
                                      <ShipmentIcon size="1.667vw" />
                                    </div>
                                    <div className="form">
                                      <label className="label">
                                        {t(
                                          'manageordersscreen.createbookingmenu.addforwarder.deliveryvendor.selectdeliveryvendor'
                                        )}
                                        <span className="required">*</span>
                                      </label>
                                      {((profileType === 'buyer' &&
                                        !buyerAction?.addDelivery) ||
                                        (profileType === 'supplier' &&
                                          !deliveryBookingCompleted &&
                                          bookingInfo?.bookAction
                                            ?.delivery)) && (
                                        <SelectForwarder
                                          mode="multi-select"
                                          setForwarder={
                                            setDeliveryForwarderList
                                          }
                                          forwarder={deliveryForwarderList}
                                          selectedForwarders={
                                            selectedForwarders
                                          }
                                          behaviour="select"
                                        />
                                      )}
                                      {((buyerAction?.addDelivery &&
                                        profileType === 'buyer') ||
                                        (profileType === 'supplier' &&
                                          deliveryBookingCompleted)) && (
                                        <TextBox
                                          disabled
                                          value={`${
                                            deliveryBookingData
                                              ?.forwarderDetail?.[0].orgName
                                          } ${
                                            deliveryBookingData?.forwarderDetail
                                              ?.length > 1
                                              ? '+'
                                              : ''
                                          }${
                                            deliveryBookingData?.forwarderDetail
                                              ?.length > 1
                                              ? deliveryBookingData
                                                  .forwarderDetail.length - 1
                                              : ''
                                          }`}
                                        />
                                      )}
                                    </div>
                                  </div>
                                )}

                              {(selectedVendorType === 'pickUp' ||
                                (originStatus &&
                                  selectedVendorType === 'primary' &&
                                  !shipmentSwitchTo)) &&
                                selectedVendorType !== 'delivery' &&
                                ((profileType === 'buyer' && !pickUpSwitchTo) ||
                                  profileType === 'supplier') &&
                                bookingValue?.mode?.value !== 'Inland' && (
                                  <div className="form-row">
                                    <div className="icon">
                                      <MapIcon width="24" color="#395C85" />
                                    </div>
                                    <div className="form">
                                      <label className="label">
                                        {t(
                                          'manageordersscreen.createbookingmenu.addforwarder.pickupvendor.pickupaddress'
                                        )}
                                        <span className="required">*</span>
                                      </label>
                                      {((profileType === 'buyer' &&
                                        !buyerAction?.addPickup) ||
                                        (profileType === 'supplier' &&
                                          !pickupBookingCompleted &&
                                          bookingInfo?.bookAction?.pickup)) && (
                                        <AddAddress
                                          disableDestination
                                          Mode={bookingValue?.mode}
                                          TypeOfMove={bookingValue?.typeOfMove}
                                          origin={pickUpValues?.origin}
                                          setOrigin={setPickUpVal('origin')}
                                          originOptions={options?.pickUpOptions}
                                          setOrginOptions={setOptionValues(
                                            'pickUpOptions'
                                          )}
                                          originGeoCode={
                                            pickUpValues?.originGeoCode
                                          }
                                          setOriginGeoCode={setPickUpVal(
                                            'originGeoCode'
                                          )}
                                          setOriginDoorAddress={(e) => {
                                            setPickUpVal('originDoorAddress')(
                                              e
                                            );
                                            setPickUpVal('originCountry')(
                                              fetchAddress(
                                                'origin',
                                                'country',
                                                e,
                                                // check
                                                deliveryValues.destinationDoorAddress,
                                                pickUpValues.originDoorAddress
                                              )
                                            );
                                          }}
                                          setOriginCountry={setPickUpVal(
                                            'originCountry'
                                          )}
                                        />
                                      )}
                                      {((buyerAction?.addPickup &&
                                        profileType === 'buyer') ||
                                        (profileType === 'supplier' &&
                                          pickupBookingCompleted)) && (
                                        <TextBox
                                          value={pickUpValues?.origin}
                                          disabled
                                        />
                                      )}
                                    </div>
                                  </div>
                                )}

                              {(selectedVendorType === 'delivery' ||
                                (destinationStatus &&
                                  selectedVendorType === 'primary' &&
                                  !shipmentSwitchTo)) &&
                                selectedVendorType !== 'pickUp' &&
                                !deliverySwitchTo &&
                                bookingValue?.mode?.value !== 'Inland' && (
                                  <div className="form-row">
                                    <div className="icon">
                                      <MapIcon width="24" color="#395C85" />
                                    </div>
                                    <div className="form">
                                      <label className="label">
                                        {t(
                                          'manageordersscreen.createbookingmenu.addforwarder.deliveryvendor.deliveryaddress'
                                        )}
                                        <span className="required">*</span>
                                      </label>
                                      {((profileType === 'buyer' &&
                                        !buyerAction?.addDelivery) ||
                                        (profileType === 'supplier' &&
                                          !deliveryBookingCompleted &&
                                          bookingInfo?.bookAction
                                            ?.delivery)) && (
                                        <AddAddress
                                          disableOrigin
                                          Mode={bookingValue?.mode}
                                          TypeOfMove={bookingValue?.typeOfMove}
                                          destination={
                                            deliveryValues?.destination
                                          }
                                          setDestination={setDeliveryVal(
                                            'destination'
                                          )}
                                          destinationOptions={
                                            options?.deliveryOptions
                                          }
                                          setDestinationOptions={setOptionValues(
                                            'deliveryOptions'
                                          )}
                                          destinationGeoCode={
                                            deliveryValues?.destinationGeoCode
                                          }
                                          setDestinationGeoCode={setDeliveryVal(
                                            'destinationGeoCode'
                                          )}
                                          setDestinationDoorAddress={(e) => {
                                            setDeliveryVal(
                                              'destinationDoorAddress'
                                            )(e);
                                            setDeliveryVal(
                                              'destinationCountry'
                                            )(
                                              fetchAddress(
                                                'destination',
                                                'country',
                                                e,
                                                // check
                                                deliveryValues.destinationDoorAddress,
                                                pickUpValues.originDoorAddress
                                              )
                                            );
                                          }}
                                          setDestinationCountry={setDeliveryVal(
                                            'destinationCountry'
                                          )}
                                        />
                                      )}
                                      {((buyerAction?.addDelivery &&
                                        profileType === 'buyer') ||
                                        (profileType === 'supplier' &&
                                          deliveryBookingCompleted)) && (
                                        <TextBox
                                          value={deliveryValues?.destination}
                                          disabled
                                        />
                                      )}
                                    </div>
                                  </div>
                                )}
                              {selectedVendorType === 'primary' &&
                                ((profileType === 'buyer' &&
                                  !shipmentSwitchTo) ||
                                  profileType === 'supplier') && (
                                  <div className="form-row">
                                    <div className="icon">
                                      <CalenderIcon
                                        color="#395C85"
                                        size="1.667vw"
                                      />
                                    </div>
                                    <div className="form">
                                      <label className="label">
                                        {t(
                                          'manageordersscreen.createbookingmenu.addforwarder.primaryvendor.quotation'
                                        )}
                                        <span className="required">*</span>
                                      </label>
                                      {((profileType === 'buyer' &&
                                        !buyerAction?.addPrimary) ||
                                        (profileType === 'supplier' &&
                                          !primaryBookingCompleted &&
                                          bookingInfo?.bookAction
                                            ?.primary)) && (
                                        <DatepickerBox
                                          required
                                          selectedDate={primaryDateForm}
                                          setDate={(e) => {
                                            setPrimaryDateForm(e);
                                          }}
                                        />
                                      )}
                                      {((buyerAction?.addPrimary &&
                                        profileType === 'buyer') ||
                                        (profileType === 'supplier' &&
                                          primaryBookingCompleted)) && (
                                        <TextBox
                                          value={getUserTimeZoneDateFormat(
                                            primaryBookingData?.quotationExpiryDate
                                          )}
                                          disabled
                                        />
                                      )}
                                    </div>
                                  </div>
                                )}
                              {selectedVendorType === 'pickUp' &&
                                ((profileType === 'buyer' && !pickUpSwitchTo) ||
                                  profileType === 'supplier') && (
                                  <div className="form-row">
                                    <div className="icon">
                                      <CalenderIcon
                                        color="#395C85"
                                        size="1.667vw"
                                      />
                                    </div>
                                    <div className="form">
                                      <label className="label">
                                        {t(
                                          'manageordersscreen.createbookingmenu.addforwarder.pickupvendor.pickupquotation'
                                        )}
                                        <span className="required">*</span>
                                      </label>
                                      {((profileType === 'buyer' &&
                                        !buyerAction?.addPickup) ||
                                        (profileType === 'supplier' &&
                                          !pickupBookingCompleted &&
                                          bookingInfo?.bookAction?.pickup)) && (
                                        <DatepickerBox
                                          required
                                          selectedDate={pickUpDateForm}
                                          setDate={(e) => {
                                            setPickUpDateForm(e);
                                          }}
                                        />
                                      )}
                                      {((buyerAction?.addPickup &&
                                        profileType === 'buyer') ||
                                        (profileType === 'supplier' &&
                                          pickupBookingCompleted)) && (
                                        <TextBox
                                          value={getUserTimeZoneDateFormat(
                                            pickUpBookingData?.quotationExpiryDate
                                          )}
                                          disabled
                                        />
                                      )}
                                    </div>
                                  </div>
                                )}
                              {selectedVendorType === 'delivery' &&
                                ((profileType === 'buyer' &&
                                  !deliverySwitchTo) ||
                                  profileType === 'supplier') && (
                                  <div className="form-row">
                                    <div className="icon">
                                      <CalenderIcon
                                        color="#395C85"
                                        size="1.667vw"
                                      />
                                    </div>
                                    <div className="form">
                                      <label className="label">
                                        {t(
                                          'manageordersscreen.createbookingmenu.addforwarder.deliveryvendor.deliveryquotation'
                                        )}
                                        <span className="required">*</span>
                                      </label>
                                      {((profileType === 'buyer' &&
                                        !buyerAction?.addDelivery) ||
                                        (profileType === 'supplier' &&
                                          !deliveryBookingCompleted &&
                                          bookingInfo?.bookAction
                                            ?.delivery)) && (
                                        <DatepickerBox
                                          required
                                          selectedDate={deliveryDateForm}
                                          setDate={(e) => {
                                            setDeliveryDateForm(e);
                                          }}
                                        />
                                      )}
                                      {((buyerAction?.addDelivery &&
                                        profileType === 'buyer') ||
                                        (profileType === 'supplier' &&
                                          deliveryBookingCompleted)) && (
                                        <TextBox
                                          value={getUserTimeZoneDateFormat(
                                            deliveryBookingData?.quotationExpiryDate
                                          )}
                                          disabled
                                        />
                                      )}
                                    </div>
                                  </div>
                                )}
                            </div>

                            {((shipmentSwitchTo &&
                              selectedVendorType === 'primary') ||
                              (pickUpSwitchTo &&
                                selectedVendorType === 'pickUp') ||
                              (deliverySwitchTo &&
                                selectedVendorType === 'delivery')) &&
                              profileType === 'buyer' && (
                                <NoData
                                  image={<IncidentsNoData size="250px" />}
                                  message={t(
                                    'manageordersscreen.createbookingmenu.addforwarder.primaryvendor.shipmentsupplier'
                                  )}
                                />
                              )}
                          </PanelCard>
                        </div>
                      </div>
                      {false && (
                        <div class="btn-activity-div">
                          <Button
                            label="Actions & Activities"
                            styles="primary outline"
                            id="activities"
                            className="activity-btn"
                            onClick={togglePopover}
                            icon={
                              <PlusIcon
                                color="#2179C4"
                                width="0.903vw"
                                height="0.903vw"
                              />
                            }
                          />
                          <AdvancePopover
                            reference="activities"
                            closeOnclick
                            closePopover={handleClose}
                            getEvent={eventValue}
                            placement="top"
                          >
                            <div className="activity">
                              <ul className="activity-list">
                                <li
                                  className={`${
                                    createRoleButton === 'Assign Users'
                                      ? 'current'
                                      : ''
                                  }`}
                                >
                                  <ActionButton
                                    className="btn-activity"
                                    type="button"
                                    onClick={toggleCommentDialog}
                                  >
                                    <AddCommentIcon />
                                    Add Comments
                                  </ActionButton>
                                  <Dialog
                                    isShowing={isActivateCommentDialog}
                                    hide={toggleCommentDialog}
                                    placement="middle"
                                    maskZindex={1041}
                                  >
                                    <PanelCard
                                      cardTitle="Note"
                                      className="comment-width"
                                    >
                                      <CommentSection
                                        showComment={false}
                                        isEditor={true}
                                      />
                                    </PanelCard>
                                  </Dialog>
                                </li>
                                <li name="name">
                                  <ActionButton
                                    className="btn-activity"
                                    type="button"
                                  >
                                    <UploadDocumentIcon color="#2179C4" />
                                    Upload Document
                                  </ActionButton>
                                </li>
                                <li name="name">
                                  <ActionButton
                                    className="btn-activity"
                                    type="button"
                                    onClick={() => {
                                      setCreateRoleButton('Assign Users');
                                    }}
                                  >
                                    <DownloadZipIcon />
                                    Download zip folder
                                  </ActionButton>
                                </li>
                              </ul>
                            </div>
                          </AdvancePopover>
                        </div>
                      )}

                      <div className="supplier-booking-button-area justify-between">
                        <Button
                          label={t(
                            'manageordersscreen.createbookingmenu.addforwarder.deliveryvendor.back'
                          )}
                          styles="back"
                          size="large"
                          icon={<PrevIcon />}
                          spacing="0.694vw"
                          className="px-24"
                          onClick={() => {
                            setAddForworder(true);
                            setSupplierTab('confirm entity');
                          }}
                        />
                        <Button
                          label={
                            profileType === 'buyer' &&
                            ((shipmentSwitchTo &&
                              selectedVendorType === 'primary') ||
                              (pickUpSwitchTo &&
                                selectedVendorType === 'pickUp') ||
                              (deliverySwitchTo &&
                                selectedVendorType === 'delivery'))
                              ? t(
                                  'manageordersscreen.createbookingmenu.addforwarder.pickupvendor.sendtosupplier'
                                )
                              : t(
                                  'manageordersscreen.createbookingmenu.addforwarder.deliveryvendor.requestquote'
                                )
                          }
                          styles="submit"
                          disabled={disableLastBtn}
                          onClick={handleUpdateBooking}
                          size="large"
                          spacing="0.694vw"
                          className="px-24"
                        />
                      </div>
                    </div>
                  )}
                </div>
                <Dialog
                  isShowing={isActivateDislog}
                  hide={toggleDialog}
                  placement="center"
                >
                  <Confirmation
                    cancelButtonText={t(
                      'manageordersscreen.createbookingmenu.shipmentdetails.warning.cancel'
                    )}
                    submitButtonText={t(
                      'manageordersscreen.createbookingmenu.shipmentdetails.warning.confirm'
                    )}
                    title={t(
                      'manageordersscreen.createbookingmenu.shipmentdetails.warning.title'
                    )}
                    description={confirmationMessage}
                    onSubmitClick={() => {
                      if (confirmationField === 'typeOfMove') {
                        setTypeOfMove(confirmationData);
                      }
                      if (confirmationField === 'mode') {
                        setBookingValue({
                          mode: {},
                          containerType: {},
                          cargoLoad: {},
                          equipmentType: {},
                          equipTypeQty: 1,
                          typeOfMove: {},
                          incoTerm: {},
                        });
                      }
                      setBookingVal('mode')(confirmationData);
                      // if (confirmationData?.value === 'Inland') {
                      //   setBookingVal('typeOfMove')({
                      //     label: 'Door to Door',
                      //     value: 'door to door',
                      //   });
                      // }
                      toggleDialog();
                      setOptions({
                        originOptions: [],
                        destinationOptions: [],
                        pickUpOptions: [],
                        deliveryOptions: [],
                      });
                      setDestinationValues({
                        destinationCountry: '',
                        destinationDoorAddress: [],
                        destinationGeoCode: [],
                        destination: '',
                      });
                      setDeliveryValues({
                        destinationCountry: '',
                        destinationDoorAddress: [],
                        destinationGeoCode: [],
                        destination: '',
                      });
                      setOriginValues({
                        origin: '',
                        originGeoCode: [],
                        originDoorAddress: [],
                        originCountry: '',
                      });
                      setPickUpValues({
                        origin: '',
                        originGeoCode: [],
                        originDoorAddress: [],
                        originCountry: '',
                      });
                      setCallRefreshForm((prev) => !prev);
                    }}
                    onCancelClick={() => {
                      toggleDialog();
                      setCallRefreshForm((prev) => !prev);
                    }}
                  />
                </Dialog>
              </>
            )}
          </DialogPanelBody>
        </DialogPanel>
      </div>
      {/* )} */}
    </>
  );
}

export default BuyerBooking;
BuyerBooking.propTypes = {
  itemsData: PropTypes.arrayOf(PropTypes.string).isRequired,
  columns: PropTypes.arrayOf(PropTypes.string).isRequired,
  toggleViewDetailsModal: PropTypes.func,
  isBulk: PropTypes.bool,
  orderListRefetch: PropTypes.func,
};
BuyerBooking.defaultProps = {
  toggleViewDetailsModal: () => {},
  isBulk: false,
  orderListRefetch: () => {},
};
