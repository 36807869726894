import axios from 'axios';

const fileHost = process.env.REACT_APP_FILE_SERVICE_ENDPOINT;

const headers = {
  headers: {
    Authorization: `Bearer ${process.env.REACT_APP_FILE_UPLOAD_TOKEN}`,
    'Content-Type': 'multipart/form-data',
    'Access-Control-Allow-Origin': '*',
  },
};

const uploadFiles = async (files) => {
  const formData = new FormData();
  formData.append('filepath', 'omni');
  formData.append('appId', 'Omni');
  Object.values(files).map((file) => {
    formData.append('file', file);
    return null;
  });

  const response = await axios.post(
    `${fileHost}service/upload`,
    formData,
    headers
  );

  return response;
};

export default uploadFiles;
