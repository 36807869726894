/* eslint-disable import/prefer-default-export */

import React from 'react';

import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  MenuBookings,
  MenuDashboard,
  MenuInvitePartner,
  MenuMyCompany,
  MenuMyProfile,
  MenuMySuppliers,
  MenuOrders,
  MenuShipments,
} from '../Components/icons/icons';

const menuSchema = () => {
  const [t] = useTranslation('global');
  const { relation, companyId } = useParams();

  const leftmenuSchema = [
    {
      type: 'text',
      name: 'MyDashboard',
      path: `/app/${relation}/dashboard`,
      placeholder: t('menuSchema.mydashboard'),
      icon: <MenuDashboard />,
    },
    {
      type: 'text',
      name: 'MyProfile',
      path: `/app/${relation}/profile`,
      placeholder: t('menuSchema.myaccount'),
      icon: <MenuMyProfile />,
    },
    {
      type: 'text',
      name: 'MyCompany',
      path: `/app/${relation}/my-company`,
      placeholder: t('menuSchema.mycompany'),
      icon: <MenuMyCompany />,
    },
    ...(relation === 'forwarder'
      ? [
          {
            type: 'text',
            name: 'OnboardPartners',
            path: `/app/${relation}/onboard-partners`,
            placeholder: 'Onboard Partners',
            icon: <MenuMyCompany />,
          },
        ]
      : []),
    ...(relation === 'supplier'
      ? [
          {
            type: 'text',
            name: 'SupplierDirectory',
            path: `/app/${relation}/supplier-directory`,
            placeholder: t('menuSchema.mysuppliers'),
            icon: <MenuMySuppliers />,
          },
        ]
      : []),
    {
      type: 'text',
      name: 'InvitePartners',
      path: `/app/${relation}/invite-partners`,
      placeholder: t('menuSchema.invitepartners'),
      icon: <MenuInvitePartner />,
    },
  ];
  const supplierMenuSchema = [
    {
      type: 'text',
      name: 'Dashboard',
      path: `/app/buyer/${companyId}/company-dashboard`,
      placeholder: t('supplierMenuSchema.mydashboard'),
      icon: <MenuDashboard />,
    },
    {
      type: 'text',
      name: 'Order',
      path: `/app/buyer/${companyId}/order-list`,
      placeholder: t('supplierMenuSchema.orders'),
      icon: <MenuOrders />,
    },
    {
      type: 'text',
      name: 'Booking',
      path: `/app/buyer/${companyId}/booking-list`,
      placeholder: t('supplierMenuSchema.bookings'),
      icon: <MenuBookings />,
    },
    {
      type: 'text',
      name: 'Shipment',
      path: `/app/buyer/${companyId}/shipment-list`,
      placeholder: t('supplierMenuSchema.shipments'),
      icon: <MenuShipments />,
    },
  ];
  const buyerMenuSchema = [
    {
      type: 'text',
      name: 'Dashboard',
      path: `/app/supplier/${companyId}/company-dashboard`,
      placeholder: t('buyerMenuSchema.mydashboard'),
      icon: <MenuDashboard />,
    },
    {
      type: 'text',
      name: 'Order',
      path: `/app/supplier/${companyId}/order-list`,
      placeholder: t('buyerMenuSchema.orders'),
      icon: <MenuOrders />,
    },
    {
      type: 'text',
      name: 'Booking',
      path: `/app/supplier/${companyId}/booking-list`,
      placeholder: t('buyerMenuSchema.bookings'),
      icon: <MenuBookings />,
    },
    {
      type: 'text',
      name: 'Shipment',
      path: `/app/supplier/${companyId}/shipment-list`,
      placeholder: t('buyerMenuSchema.shipments'),
      icon: <MenuShipments />,
    },
  ];
  const languageSchema = [
    { label: 'English', value: 'en' },
    { label: 'Turkish/Türkçe', value: 'tr' },
    { label: 'Spanish/Español', value: 'es' },
  ];

  const forwarderMenuSchema = [
    {
      type: 'text',
      name: 'Dashboard',
      path: `/app/forwarder/${companyId}/company-dashboard`,
      placeholder: t('forwarderMenuSchema.mydashboard'),
      icon: <MenuDashboard />,
    },
    {
      type: 'text',
      name: 'Booking',
      path: `/app/forwarder/${companyId}/booking-list`,
      placeholder: t('forwarderMenuSchema.bookings'),
      icon: <MenuBookings />,
    },
    {
      type: 'text',
      name: 'Shipment',
      path: `/app/forwarder/${companyId}/shipment-list`,
      placeholder: t('forwarderMenuSchema.shipments'),
      icon: <MenuShipments />,
    },
  ];
  const customBrokerMenuSchema = [
    {
      type: 'text',
      name: 'Dashboard',
      path: `/app/customBroker/${companyId}/company-dashboard`,
      placeholder: t('forwarderMenuSchema.mydashboard'),
      icon: <MenuDashboard />,
    },
    {
      type: 'text',
      name: 'Shipment',
      path: `/app/customBroker/${companyId}/shipment-list`,
      placeholder: 'Shipments',
      icon: <MenuShipments />,
    },
  ];
  const accountMenu = [
    {
      type: 'text',
      name: 'Logout',
      path: '/login',
      placeholder: 'Logout',
    },
    {
      type: 'text',
      name: 'FAQ',
      path: '/',
      placeholder: 'FAQ',
    },
    {
      type: 'text',
      name: 'Terms & Conditions',
      path: '/',
      placeholder: 'Terms & Conditions',
    },
  ];
  const filterOptionCreatedOnSchema = [
    { label: 'Today', value: 'Today' },
    { label: 'This week', value: 'This week' },
    { label: 'This month', value: 'This month' },
    { label: 'This year', value: 'This year' },
    { label: 'Custom', value: 'Custom' },
  ];
  const dataTab = (key) => {
    let data = [{ label: '', value: '' }];
    switch (key) {
      case 'To Do':
        data = [
          { label: 'New', value: 'New' },
          { label: 'Partially Accepted', value: 'Partially Accepted' },
          { label: 'Rejected', value: 'Rejected' },
          { label: 'Change Requested', value: 'Change Requested' },
        ];
        break;
      case 'Accepted':
        data = [{ label: 'Accepted', value: 'Accepted' }];
        break;
      case 'Production':
        data = [
          { label: 'In Production', value: 'In Production' },
          { label: 'Production Completed', value: 'Production Completed' },
        ];
        break;
      case 'Cargo Ready':
        data = [
          { label: 'Partially Cargo Ready', value: 'Partially Cargo Ready' },
          { label: 'Cargo Ready', value: 'Cargo Ready' },
          { label: 'Partially Packed', value: 'Partially Packed' },
          { label: 'Partially Booked', value: 'Partially Booked' },
        ];
        break;
      case 'Cargo Delivery':
        data = [
          { label: 'Packed', value: 'Packed' },
          { label: 'Shipped', value: 'Shipped' },
          { label: 'Booked', value: 'Booked' },
        ];
        break;

      default:
        data = [
          { label: 'New', value: 'New' },
          { label: 'Partially Accepted', value: 'Partially Accepted' },
          { label: 'Accepted', value: 'Accepted' },
          { label: 'Change Requested', value: 'Change Requested' },
          { label: 'In Production', value: 'In Production' },
          { label: 'Production Completed', value: 'Production Completed' },
          { label: 'Partially Cargo Ready', value: 'Partially Cargo Ready' },
          { label: 'Cargo Ready', value: 'Cargo Ready' },
          { label: 'Partially Packed', value: 'Partially Packed' },
          { label: 'Partially Booked', value: 'Partially Booked' },
          { label: 'Packed', value: 'Packed' },
          { label: 'Shipped', value: 'Shipped' },
          { label: 'Booked', value: 'Booked' },
        ];
        break;
    }
    return data;
  };
  const params = new URLSearchParams(window.location.search);
  const satusOptionSchema = dataTab(params.get('urlID') || 'All');

  const notificationsOptionSchema = [
    { label: 'Milestones', value: 'Milestones' },
    { label: 'Actions', value: 'Actions' },
    { label: 'Approvals', value: 'Approvals' },
    { label: 'Chats & Mentions', value: 'Chats & Mentions' },
    { label: 'Documents', value: 'Documents' },
    { label: 'PO Changes', value: 'PO Changes' },
  ];
  const participantsOptionSchema = [
    { label: 'User Name1', value: 'User Name1' },
    { label: 'User Name2', value: 'User Name2' },
    { label: 'User Name3', value: 'User Name3' },
  ];
  const actionOptions = [
    { label: 'Create Booking', value: 'Create Booking' },
    { label: 'View Booking', value: 'View Booking' },
  ];
  const ownerOptions = [
    { label: 'Self', value: relation === 'buyer' ? 'supplier' : 'buyer' },
    {
      label: relation === 'supplier' ? 'Supplier' : 'Buyer',
      value: relation === 'supplier' ? 'supplier' : 'buyer',
    },
  ];
  const modeOptions = [
    { label: 'Air', value: 'Air' },
    { label: 'Ocean', value: 'Ocean' },
    { label: 'Inland', value: 'Inland' },
    { label: 'Inland Pick-up', value: 'Inland Pick-up' },
    { label: 'Inland Delivery', value: 'Inland Delivery' },
  ];
  const quoteStatusOptions = [
    { label: 'Quotation Requested', value: 'Quotation Requested' },
    { label: 'Quotation Pending', value: 'Quotation Pending' },
    { label: 'Quotation Expired', value: 'Quotation Expired' },
  ];
  const forwarderOptions = [];

  const shipmentDataTab = (key) => {
    let data = [{ label: '', value: '' }];
    switch (key) {
      case 'All':
        data = [
          { label: 'Ready', value: 'Ready' },
          { label: 'Pick-Up Shipment', value: 'Pick-Up Shipment' },
          { label: 'Departed', value: 'Departed' },
          { label: 'Arrived', value: 'Arrived' },
          { label: 'Customs Clearance', value: 'Customs Clearance' },
          { label: 'Customs Started', value: 'Customs Started' },
          { label: 'Customs In-progress', value: 'Customs In-progress' },
          { label: 'Customs Completed', value: 'Customs Completed' },
          { label: 'Delivery Shipment', value: 'Delivery Shipment' },
          { label: 'Delivered', value: 'Delivered' },
        ];
        break;
      case 'Pick-Up Shipment':
        data = [{ label: 'Pick-Up Shipment', value: 'Pick-Up Shipment' }];
        break;
      case 'Ready':
        data = [{ label: 'Ready', value: 'Ready' }];
        break;
      case 'Departed':
        data = [{ label: 'Departed', value: 'Departed' }];
        break;
      case 'Arrived':
        data = [{ label: 'Arrived', value: 'Arrived' }];
        break;
      case 'Delivered':
        data = [{ label: 'Delivered', value: 'Delivered' }];
        break;
      case 'Delivery Shipment':
        data = [{ label: 'Delivery Shipment', value: 'Delivery Shipment' }];
        break;
      case 'Customs Clearance':
        data = [
          { label: 'Customs Clearance', value: 'Customs Clearance' },
          { label: 'Customs Started', value: 'Customs Started' },
          { label: 'Customs In-progress', value: 'Customs In-progress' },
          { label: 'Customs Completed', value: 'Customs Completed' },
        ];
        break;

      default:
        data = [{ label: '', value: '' }];
        break;
    }
    return data;
  };
  const shipmentsStatusOptions = shipmentDataTab(params.get('urlID') || 'All');
  const shipmentsEventsOptions = [
    { label: 'On-Time', value: 'On Time' },
    { label: 'Delay', value: 'Delay' },
    { label: 'Behind schedule', value: 'Behind schedule' },
  ];

  return {
    leftmenuSchema,
    supplierMenuSchema,
    buyerMenuSchema,
    forwarderMenuSchema,
    customBrokerMenuSchema,
    accountMenu,
    languageSchema,
    filterOptionCreatedOnSchema,
    satusOptionSchema,
    notificationsOptionSchema,
    participantsOptionSchema,
    actionOptions,
    ownerOptions,
    modeOptions,
    quoteStatusOptions,
    forwarderOptions,
    shipmentsStatusOptions,
    shipmentsEventsOptions,
  };
};

export default menuSchema;
