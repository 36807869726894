/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import IncidentItem from './Incident-item/Incident-item';
import IncidentsLoader from './Incident-item/loader';

export default function Incidents(props) {
  return (
    <div className="incidents">
      {props?.data ? (
        <>
          {props.data.FetchProject44IncidentsData.map(
            ({ title, description }) => (
              <IncidentItem
                title={title}
                description={description.replace(/(\r\n|\n|\r|\\r|\\n)/gm, ' ')}
              />
            )
          )}
        </>
      ) : (
        <IncidentsLoader />
      )}
    </div>
  );
}
