import { gql } from '@apollo/client';

const QUERY_ORGANIZATIONDETAILS = gql`
  query FetchOrganizationDetails(
    $fetchOrganizationDetailsId: Int
    $profileType: String
  ) {
    fetchOrganizationDetails(
      id: $fetchOrganizationDetailsId
      profileType: $profileType
    ) {
      message
      data {
        about {
          overview
          industry
          certification
          foundedIn
          headquaters
          commodities
        }
        orgName
        orgId
        orgStatus
        orgEntities
        orgCreatedBy
        orgCreatedAt
        orgType
        orgDomains
        orgAddress
        orgCity
        orgState
        orgZip
        orgCountry
        orgPhone
        orgFax
        orgEmail
        orgWebsite
        orgPrimaryContact {
          contactName
          contactEmail
          contactRole
          contactCreatedAt
          contactCreatedBy
        }
        orgSecondaryContact {
          contactName
          contactEmail
          contactRole
          contactCreatedAt
          contactCreatedBy
        }
        admins
        users
        keyAdmin
        buyers
        suppliers
        forwarders
        orgLogo
        orgCover
        orgDescription
        defaultCurrency
        attachment {
          size
          type
          logo
          parentId
          visibility
          docId
          originalName
          path
          url
          uploadedBy {
            userSub
            userName
            profilePicture
          }
          uploadedByOrg
          deleted
          mimeType
          createdAt
          deletable
          sharable
          createFiles
          _id
          accessGivenTo
          totalItems
        }
        parentId
        product {
          itemName
          itemCode
          itemDescription
          add
          attachments {
            docId
            originalFileName
            fileName
            filePath
            url
            _id
            createdAt
            uploadedBy
            deleted
            type
          }
          _id
        }
        orgLat
        orgLong
        configuration
      }
    }
  }
`;

export const QUERY_EDIT_DETAILS = gql`
  mutation UpdateBasicOrganization($input: EditOrganizationBasic) {
    updateBasicOrganization(input: $input) {
      message
    }
  }
`;

export const QUERY_ADVANCE_EDIT_DETAILS = gql`
  mutation Mutation($input: EditOrganizationAdvance) {
    updateAdvanceOrganization(input: $input) {
      message
    }
  }
`;

export const SEARCH_CURRENCY = gql`
  query FetchProfileDetails {
    fetchCurrency {
      CurrencyCode
    }
  }
`;

export const QUERY_ORGANIZATION_USERS_LIST = gql`
  query FetchListOrganisationUsers($fetchListOrganisationUsersId: String) {
    fetchListOrganisationUsers(id: $fetchListOrganisationUsersId) {
      message
      data {
        email
        timezone
        phoneNumber
        country
        lastName
        firstName
        profilePicture
        userType
        userSub
        poolId
        userStatus
        active
        emailVerification
        userVerification
        onlineStatus
        parentEntityId
        userRoles
        action {
          edit
          activeDeactive
          self
        }
        entityDetails {
          parentEntity {
            entityName
            _id
            entityType
          }
          childEntity {
            entityName
            _id
            entityType
          }
        }
      }
    }
  }
`;

export const QUERY_LOACTION_LIST = gql`
  query FetchListOrgEntities($fetchListOrgEntitiesId: String) {
    fetchListOrgEntities(id: $fetchListOrgEntitiesId) {
      message
      data {
        entityName
        parentOrgId
        parentTree
        _id
        children
        entityDescription
        entityType
        entityAddresses {
          addressLine1
          addressLine2
          city
          state
          country
          zip
          lat
          long
        }
        entityDomains
        entityUsers {
          userSub
          invitedBy
          invitedDate
        }
        entityKeyAdmins {
          userSub
          invitedBy
          invitedDate
        }
        entityAdmins {
          userSub
          invitedBy
          invitedDate
        }
        parentTreeDescription {
          orgName
          orgId
        }
        contactEmail
        cityCountryName
        parent
        userDetails {
          firstName
          lastName
          timezone
          country
          phoneNumber
          profilePicture
          emailVerification
          onlineStatus
          email
          active
          userType
          userVerification
        }
        isHeadQuarter
      }
    }
  }
`;

export const QUERY_COUNTRY_LIST = gql`
  query COUNTRY_LIST {
    GlobeOrg {
      success
      data {
        childOrg {
          _id
          orgLat
          orgLong
          orgName
          orgType
          orgLogo
          orgStatus
          orgCover
          orgAddress
          orgCity
          orgState
          orgZip
          orgPhone
          orgFax
          orgEmail
          orgWebsite
          entityList {
            _id
            entityName
            parent
            cityCountryName
            parentOrgId
            parentTree
            children
            entityDescription
            entityType
            entityAddresses {
              addressLine1
              addressLine2
              city
              state
              country
              zip
              lat
              long
            }
            entityDomains
            entityUsers {
              userSub
              invitedBy
              invitedDate
            }
            entityKeyAdmins {
              userSub
              invitedBy
              invitedDate
            }
            entityAdmins {
              userSub
              invitedBy
              invitedDate
            }
            parentTreeDescription {
              orgName
              orgId
            }
            contactEmail
          }
        }
        latitude
        longitude
        country
        countryFlag
        countryName
        TelephoneDialPrefix
        TimeZone
      }
      message
    }
  }
`;

export const UPDATE_ORG_ADVANCE = gql`
  mutation Mutation($input: EditOrganizationAdvance) {
    updateAdvanceOrganization(input: $input) {
      message
    }
  }
`;

export const COUNTRY_ORG_DETAILS = gql`
  query CountryOrgDetails($input: countryOrgDetailsInput) {
    CountryOrgDetails(input: $input) {
      success
      data
    }
  }
`;

export const ORG_DETAILS_SD = gql`
  query OrgDetailSD($input: orgSDInput) {
    orgDetailSD(input: $input) {
      success
      data
      message
    }
  }
`;

export const OPERATIONAL_ANALYSIS = gql`
  query BookingOperationDetails($input: bookingOperationDetailsInput) {
    bookingOperationDetails(input: $input) {
      success
      data {
        mode
        quantity
        percentage
      }
    }
  }
`;

export const COUNTRY_OR_CITY_IMAGE = gql`
  mutation FetchCityImageRef($input: fetchCityImageRefInput) {
    fetchCityImageRef(input: $input) {
      success
      data {
        candidates {
          geometry {
            location {
              lat
              lng
            }
            viewport {
              northeast {
                lat
                lng
              }
              southwest {
                lat
                lng
              }
            }
          }
          name
          photos {
            height
            html_attributions
            photo_reference
            width
          }
        }
        status
      }
      message
    }
  }
`;

export default QUERY_ORGANIZATIONDETAILS;
