import React from 'react';
import './global-loader.scss';
import Lottie from 'react-lottie';
import PropTypes from 'prop-types';
import globalLoader from '../../assets/svg/animation.json';

export default function GlobalLoader({ newLoad, height, width }) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: globalLoader,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  const newHeigth = height || '10.972vw';
  const newWidth = width || '11.806vw';
  return (
    <div className="loader-container">
      {newLoad ? (
        <Lottie options={defaultOptions} height={newHeigth} width={newWidth} />
      ) : (
        <svg width="6.944vw" height="6.944vw" viewBox="0 0 100 100">
          <rect x="0" y="0" width="10" height="60" rx="6" fill="#27307d">
            <animate
              attributeType="CSS"
              attributeName="height"
              values="60;20;60;"
              begin="0s"
              dur="1s"
              repeatCount="indefinite"
            />
            <animate
              attributeType="CSS"
              attributeName="y"
              begin="0s"
              values="0;20;0;"
              dur="1s"
              repeatCount="indefinite"
            />
          </rect>
          <rect x="20" y="0" width="10" height="60" rx="6" fill="#159dd8">
            <animate
              attributeType="CSS"
              attributeName="height"
              values="60;20;60"
              begin="0.5s"
              dur="1s"
              repeatCount="indefinite"
            />
            <animate
              attributeType="CSS"
              attributeName="y"
              values="0;20;0"
              begin="0.5s"
              dur="1s"
              repeatCount="indefinite"
            />
          </rect>
          <rect x="40" y="0" width="10" height="60" rx="6" fill="#27307d">
            <animate
              attributeType="CSS"
              attributeName="height"
              values="60;20;60"
              begin="0s"
              dur="1s"
              repeatCount="indefinite"
            />
            <animate
              attributeType="CSS"
              attributeName="y"
              values="0;20;0"
              begin="0s"
              dur="1s"
              repeatCount="indefinite"
            />
          </rect>
        </svg>
      )}
    </div>
  );
}

GlobalLoader.propTypes = {
  newLoad: PropTypes.bool,
  height: PropTypes.string,
  width: PropTypes.string,
};

GlobalLoader.defaultProps = {
  newLoad: false,
  height: '6.944vw',
  width: '6.944vw',
};
