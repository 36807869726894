import React, { useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import './auto-search.scss';
import { ShortDownIcon } from '../icons/icons';
import Tooltip from '../tooltip/tooltip';

/**
 * @typedef {'material' | 'normal'} ModeType - Size choices for the Demo component.
 */

/**
 * Component to demonstrate different sizes, appearances, and popup options.
 * @param {Object} props
 * @param {ModeType} props.mode
 * @returns {JSX.Element}
 */
export default function AutoSearch({
  placeholder,
  onChange,
  options,
  handleInputChanged,
  defaultValue,
  label,
  required,
  id,
  errorMessage,
  message,
  loading,
  removeSearchValue,
  resetDataList,
  disabled,
  children,
  mode,
  ...otherProps
}) {
  const modeProperties = {
    material: 'material',
    normal: 'normal',
  };
  const modeProps = modeProperties[mode];
  const [t] = useTranslation('manageorders');
  const [selectedValue, setSelectedValue] = useState('');
  const [openDDL, setOpenDDl] = useState();
  const [getTop, setGetTop] = useState();
  const [getLeft, setGetLeft] = useState();
  const [getWidth, setGetWidth] = useState();
  const listHeight = useRef();
  const area = useRef(null);
  const ref = useRef(null);

  const getPosition = () => {
    document.body.style.overflow = 'hidden';
    if (
      area.current.getBoundingClientRect().top +
        area.current.getBoundingClientRect().height +
        listHeight.current.getBoundingClientRect().height >
      window.innerHeight
    ) {
      setGetTop(
        area.current.getBoundingClientRect().top -
          listHeight.current.getBoundingClientRect().height
      );
    } else {
      setGetTop(
        area.current.getBoundingClientRect().top +
          area.current.getBoundingClientRect().height
      );
    }

    setGetWidth(area?.current?.getBoundingClientRect()?.width);
    setGetLeft(area?.current?.getBoundingClientRect()?.left);
    ref.current?.focus();
  };
  const openDropdown = () => {
    setOpenDDl(true);
    ref.current?.focus();
    resetDataList();
  };

  const closeDropdown = () => {
    setOpenDDl(false);
    document.body.style.overflow = 'auto';
  };
  const getValue = (e) => {
    setSelectedValue(e.target.nextSibling.innerHTML);
    onChange(e);
    setOpenDDl(false);
    ref.current.value = '';
    closeDropdown();
  };
  const handleChange = (e) => {
    handleInputChanged(e);
    getPosition();
    setOpenDDl(true);
  };

  const removeValue = () => {
    setSelectedValue('');
    removeSearchValue();
    // getPosition();
    // setOpenDDl(true);
  };
  const removeValueBack = (e) => {
    removeValue();
    if (e.keyCode === 8) {
      setSelectedValue('');
      removeSearchValue();
    }
  };
  useEffect(() => {
    setSelectedValue(defaultValue.value);
  }, [defaultValue.value]);
  useEffect(() => {
    if (options.length > 0) {
      getPosition();
    }
  }, [options]);

  return (
    <div
      className={`auto-search${openDDL ? ' active' : ''}${
        disabled ? ' disabled' : ''
      }`}
    >
      {openDDL && (
        <div
          className="select-mask"
          onClick={closeDropdown}
          onKeyDown={closeDropdown}
          role="button"
          tabIndex="0"
        >
          &nbsp;
        </div>
      )}
      <div
        data-cy="auto-search"
        className={`auto-search-area${` ${modeProps}`}${
          mode === 'material' && (openDDL || selectedValue !== '')
            ? ' active'
            : ''
        }`}
      >
        {label && (
          <label
            htmlFor={id}
            className={`label${
              mode === 'material' &&
              (openDDL || selectedValue !== '' || ref?.current?.value !== '')
                ? ' active'
                : ''
            }`}
          >
            {label}
            {required && <span className="required">*</span>}
          </label>
        )}
        <div
          className="textbox-area"
          // onClick={(e) => getPosition(e)}
          // onKeyDown={openDropdown}
          // role="button"
          // tabIndex="0"
          ref={area}
        >
          {mode === 'material' && <span className="icon">{children}</span>}
          {selectedValue ? (
            <div className="selected-value">
              {selectedValue !== '' && (
                <>
                  <Tooltip content={selectedValue}>
                    <span>{selectedValue}</span>
                  </Tooltip>
                  {otherProps.disabled !== true && (
                    <button type="button" onClick={removeValue}>
                      &times;
                    </button>
                  )}
                </>
              )}
            </div>
          ) : (
            <input
              type="text"
              placeholder={
                !selectedValue && mode !== 'material' ? placeholder : ''
              }
              onChange={handleChange}
              onKeyUp={removeValueBack}
              ref={ref}
              id={id}
              disabled={disabled}
              onClick={(e) => {
                getPosition(e);
                openDropdown();
              }}
              role="button"
              tabIndex="0"
              {...otherProps}
            />
          )}
          <span className="arrow">
            <ShortDownIcon size="2.847vw" />
          </span>
        </div>

        <ul
          className={`select-options ${openDDL ? 'show' : ''}`}
          style={{ width: getWidth, top: getTop, left: getLeft }}
          ref={listHeight}
        >
          {options.length === 0 && (
            <li>
              <label className="option">
                {loading ? (
                  <span className="label no-data">
                    {t(
                      'manageordersscreen.createbookingmenu.shipmentdetails.loading'
                    )}
                  </span>
                ) : (
                  <span className="label no-data">
                    {ref?.current?.value?.length > 0
                      ? t(
                          'manageordersscreen.createbookingmenu.shipmentdetails.noresult'
                        )
                      : t(
                          'manageordersscreen.createbookingmenu.shipmentdetails.findlocation'
                        )}
                  </span>
                )}
              </label>
            </li>
          )}
          {options?.map((val) => {
            return (
              <li key={val.label}>
                <label className="option" htmlFor={val.label}>
                  <input
                    type="radio"
                    onChange={(e) => getValue(e)}
                    name="ddOptions"
                    value={val.value}
                    defaultChecked={
                      defaultValue.value === (val.label || val.value)
                    }
                    id={val.label}
                  />
                  <span className="label">{val.label}</span>
                </label>
              </li>
            );
          })}
        </ul>
      </div>

      {message !== '' && <p className="message">{message}</p>}
      {errorMessage !== '' && <p className="error-message">{errorMessage}</p>}
    </div>
  );
}
AutoSearch.propTypes = {
  placeholder: PropTypes.string,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.instanceOf(Array),
  handleInputChanged: PropTypes.func,
  label: PropTypes.string,
  id: PropTypes.string,
  errorMessage: PropTypes.string,
  message: PropTypes.string,
  loading: PropTypes.bool,
  removeSearchValue: PropTypes.func,
  resetDataList: PropTypes.func,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  children: PropTypes.node,
  mode: PropTypes.string,
};
AutoSearch.defaultProps = {
  placeholder: '',
  defaultValue: '',
  onChange: () => {},
  options: [],
  handleInputChanged: () => {},
  label: '',
  id: '',
  errorMessage: '',
  message: '',
  loading: false,
  removeSearchValue: () => {},
  resetDataList: () => {},
  disabled: false,
  required: false,
  children: [],
  mode: '',
};
