import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Dialog from '../dialog/dialog';
import PanelCard from '../panel-card/panel-card';
import './custom-texteditor.scss';
import {
  BoldIcon,
  ItalicIcon,
  UnderlineIcon,
  LeftAlignIcon,
  CenterAlignIcon,
  RightAlignIcon,
  JustifiedAlignIcon,
  LinkOptionIcon,
  ImageOptionIcon,
  BulletListIcon,
  NumberListIcon,
  TableIconIcon,
} from './wrappedIcons';

import {
  RejectIcon,
  AcceptIcon,
  FormattIcon,
  SendIcon,
  AttachIcon,
} from '../icons/icons';
import FileItem from '../file-item/file-item';

function CustomEditor({
  getEditorValue,
  onSendClick,
  onAttachClick,
  chatFiles,
  onFileDiscardClick,
  enableBullateList,
  enableNumberList,
  enableTable,
  enableImageUpload,
  disabled,
  disabledSend,
  visibleOptions,
  charLimit,
  width,
  label,
  id,
  required,
  placeholder,
}) {
  const editorRef = useRef(null);
  const [showDialog, setShowDialog] = useState(false);
  const [formatVisibility, setFormatVisibility] = useState(false);
  const [urlInput, setUrlInput] = useState('');
  const [selectionRange, setSelectionRange] = useState(null);
  const [currentButton, setCurrentButton] = useState('');
  const [charCount, setCharCount] = useState(0);

  useEffect(() => {
    const handlePaste = (event) => {
      event.preventDefault();
      const text = event.clipboardData.getData('text');
      const currentText = editorRef.current.innerText;
      if (charLimit && currentText.length + text.length > charLimit) {
        document.execCommand(
          'insertText',
          false,
          text.slice(0, charLimit - currentText.length)
        );
      } else {
        document.execCommand('insertText', false, text);
      }
      setCharCount(editorRef.current.innerText.length);
    };

    const editor = editorRef.current;
    editor.addEventListener('paste', handlePaste);
    return () => {
      editor.removeEventListener('paste', handlePaste);
    };
  }, [charLimit]);

  // const applyStyle = (style, value) => {
  //   document.execCommand(style, false, value);
  //   editorRef.current.focus();
  //   setCurrentButton(style);
  // };

  const applyStyle = (style, value) => {
    document.execCommand(style, false, value);
    editorRef.current.focus();
    setCurrentButton(style);
    getEditorValue(editorRef.current.innerHTML);
  };

  const handleChangeFontSize = (e) => {
    const fontSize = e.target.value;
    document.execCommand('fontSize', false, '7');
    const fontElements = document.getElementsByTagName('font');
    for (let i = 0; i < fontElements.length; i++) {
      if (fontElements[i].size === '7') {
        fontElements[i].removeAttribute('size');
        fontElements[i].style.fontSize = `${fontSize}px`;
      }
    }
    editorRef.current.focus();
    setCharCount(editorRef.current.innerText.length);
  };

  const handleAlignmentChange = (alignment) => {
    document.execCommand(`justify${alignment}`, false, null);
    editorRef.current.focus();
    setCurrentButton(`justify${alignment}`);
  };

  const handleInsertImage = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = document.createElement('img');
        img.src = event.target.result;
        editorRef.current.appendChild(img);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleClick = (event) => {
    if (event.target.tagName === 'A') {
      window.open(event.target.href, '_blank');
    }
  };

  const handleKeyPress = (event) => {
    const currentText = editorRef.current.innerText;
    if (
      charLimit &&
      currentText.length >= charLimit &&
      event.key !== 'Backspace'
    ) {
      event.preventDefault();
    } else {
      setTimeout(() => {
        setCharCount(editorRef.current.innerText.length);
        getEditorValue(editorRef.current.innerHTML);
      }, 0);
    }
    if (event.key === 'Enter' && event.target.tagName === 'A') {
      window.open(event.target.href, '_blank');
    }
  };

  const handleInsertLink = () => {
    const selection = window.getSelection();
    if (selection && selection.rangeCount > 0) {
      setSelectionRange(selection.getRangeAt(0));
    } else {
      setSelectionRange(null);
    }
    setShowDialog(true);
  };

  const handleInsertBulletList = () => {
    applyStyle('insertUnorderedList', null);
  };

  const handleInsertNumberList = () => {
    applyStyle('insertOrderedList', null);
  };

  const handleDialogAccept = () => {
    if (urlInput.trim() === '') {
      setShowDialog(false);
      return;
    }

    const selection = window.getSelection();
    if (selectionRange) {
      const link = document.createElement('a');
      link.href = urlInput;
      link.target = '_blank';
      link.rel = 'noopener noreferrer';
      link.appendChild(selectionRange.extractContents());
      selectionRange.insertNode(link);

      const newRange = document.createRange();
      newRange.setStartAfter(link);
      newRange.collapse(true);
      selection.removeAllRanges();
      selection.addRange(newRange);

      setSelectionRange(null);
    } else {
      const link = document.createElement('a');
      link.href = urlInput;
      link.target = '_blank';
      link.rel = 'noopener noreferrer';
      link.textContent = urlInput;
      editorRef.current.appendChild(link);
    }

    setShowDialog(false);
    setUrlInput('');
  };

  const handleSubmitMessage = () => {
    const editorContent = `${editorRef.current.innerHTML} `;
    getEditorValue(editorContent);
    onSendClick();
    editorRef.current.innerHTML = '';
    setCharCount(0);
  };

  return (
    <>
      <label
        style={{
          fontSize: ' 0.764vw',
          fontWeight: ' 500',
          lineHeight: ' 1.458vw',
          marginBottom: ' 0.278vw',
          color: ' #9d9d9d',
          display: ' block',
          textAlign: ' left',
        }}
        htmlFor={id}
      >
        {' '}
        {label} {required && <span className="required">*</span>}
      </label>

      <div
        className={`custom-editor${
          chatFiles.length !== 0 || formatVisibility || visibleOptions
            ? ' larged'
            : ''
        }${disabled ? ' disabled-box' : ''}`}
        style={{ width }}
      >
        {(formatVisibility || visibleOptions) && (
          <div className="toolbar-wrapper top">
            <div className="toolbar">
              <div className="inline-options">
                <div className="option-wrapper">
                  <button
                    type="button"
                    className={`toolbar-btn${
                      currentButton === 'bold' ? ' active' : ''
                    }`}
                    onClick={() => applyStyle('bold', null)}
                  >
                    <BoldIcon />
                  </button>
                </div>
                <div className="option-wrapper">
                  <button
                    type="button"
                    className={`toolbar-btn${
                      currentButton === 'italic' ? ' active' : ''
                    }`}
                    onClick={() => applyStyle('italic', null)}
                  >
                    <ItalicIcon />
                  </button>
                </div>
                <div className="option-wrapper">
                  <button
                    type="button"
                    className={`toolbar-btn${
                      currentButton === 'underline' ? ' active' : ''
                    }`}
                    onClick={() => applyStyle('underline', null)}
                  >
                    <UnderlineIcon />
                  </button>
                </div>
              </div>
              <div className="fontsize-options">
                <div className="option-wrapper">
                  <select onChange={handleChangeFontSize}>
                    {[10, 12, 14, 16, 18, 20, 24, 28, 32].map((size) => (
                      <option key={size} value={size}>
                        {size}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="textalign-options">
                <div className="option-wrapper">
                  <button
                    type="button"
                    className="toolbar-btn"
                    onClick={() => handleAlignmentChange('Left')}
                  >
                    <LeftAlignIcon />
                  </button>
                </div>
                <div className="option-wrapper">
                  <button
                    type="button"
                    className="toolbar-btn"
                    onClick={() => handleAlignmentChange('Center')}
                  >
                    <CenterAlignIcon />
                  </button>
                </div>
                <div className="option-wrapper">
                  <button
                    type="button"
                    className="toolbar-btn"
                    onClick={() => handleAlignmentChange('Right')}
                  >
                    <RightAlignIcon />
                  </button>
                </div>
                <div className="option-wrapper">
                  <button
                    type="button"
                    className="toolbar-btn"
                    onClick={() => handleAlignmentChange('Full')}
                  >
                    <JustifiedAlignIcon />
                  </button>
                </div>
              </div>
              {false && (
                <div className="link-options">
                  <div className="option-wrapper">
                    <button
                      type="button"
                      className="toolbar-btn"
                      onClick={handleInsertLink}
                    >
                      <LinkOptionIcon />
                    </button>
                  </div>
                  {/* <div className="option-wrapper">
              <label className="toolbar-btn">
                <ImageOptionIcon />
                <input
                  type="file"
                  className="attachment"
                  onChange={handleInsertImage}
                  style={{ display: 'none' }}
                />
              </label>
            </div> */}
                </div>
              )}
              <div className="bullet-list">
                {enableBullateList && (
                  <div className="option-wrapper">
                    <button
                      type="button"
                      className="toolbar-btn"
                      onClick={handleInsertBulletList}
                    >
                      <BulletListIcon />
                    </button>
                  </div>
                )}
                {enableNumberList && (
                  <div className="option-wrapper">
                    <button
                      type="button"
                      className="toolbar-btn"
                      onClick={handleInsertNumberList}
                    >
                      <NumberListIcon />
                    </button>
                  </div>
                )}
                {enableTable && (
                  <div className="option-wrapper">
                    <button type="button" className="toolbar-btn">
                      <TableIconIcon />
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        <div
          ref={editorRef}
          className={`editor-content contentEditable${
            chatFiles.length !== 0 ? ' file-added' : ''
          }`}
          contentEditable
          role="textbox"
          tabIndex="0"
          onClick={handleClick}
          onKeyDown={handleKeyPress}
          placeholder={placeholder}
        />
        {chatFiles.length !== 0 && (
          <div className="file-list">
            {chatFiles.map((doc) => {
              return <FileItem file={doc} onDiscard={onFileDiscardClick} />;
            })}
            {/* {chatFiles.length > 2 && (
            <FileItem
              fileName={`+${chatFiles.length - 2} more files`}
              disabledFileType
              disabledRemove
            />
          )} */}
          </div>
        )}
        {!visibleOptions && (
          <div className="toolbar-wrapper justify-between">
            <div className="toolbar">
              <div className="inline-options">
                <div className="option-wrapper">
                  <button
                    type="button"
                    className="toolbar-btn"
                    onClick={() => setFormatVisibility(!formatVisibility)}
                  >
                    <FormattIcon
                      activeColor={formatVisibility ? '#ff1111' : 'none'}
                    />
                  </button>
                </div>
                <div className="option-wrapper">
                  <label className="toolbar-btn">
                    <AttachIcon />
                    <input
                      type="file"
                      className="attachment"
                      onChange={onAttachClick}
                      style={{ display: 'none' }}
                    />
                  </label>
                </div>

                {enableImageUpload && (
                  <div className="option-wrapper">
                    <label className="toolbar-btn">
                      <ImageOptionIcon />
                      <input
                        type="file"
                        className="attachment"
                        onChange={handleInsertImage}
                        style={{ display: 'none' }}
                      />
                    </label>
                  </div>
                )}
                {/* <div className="option-wrapper">
              <button type="button" className="toolbar-btn">
                <FormattIcon />
              </button>
            </div> */}
              </div>
            </div>
            {!disabledSend && (
              <div className="toolbar">
                <div className="inline-options">
                  <div className="option-wrapper">
                    {charLimit && (
                      <div
                        className={`char-count ${
                          charCount >= charLimit ? 'exceed-limit' : ''
                        }`}
                      >
                        {charCount}/{charLimit}
                      </div>
                    )}
                  </div>
                  <div className="option-wrapper">
                    <button
                      type="button"
                      className="toolbar-btn send"
                      onClick={handleSubmitMessage}
                    >
                      <SendIcon color="#ffffff" />
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
        <Dialog
          isShowing={showDialog}
          hide={() => setShowDialog(false)}
          placement="center"
        >
          <PanelCard>
            <div>
              <h5>Enter the URL</h5>
              <div className="d-flex align-center">
                <input
                  className="mr-5"
                  type="text"
                  value={urlInput}
                  onChange={(e) => setUrlInput(e.target.value)}
                />
                <div className="dialog-buttons">
                  <button
                    type="button"
                    style={{ border: 'none', background: 'none' }}
                    onClick={handleDialogAccept}
                  >
                    <AcceptIcon color="#55b938" />
                  </button>
                  <button
                    type="button"
                    style={{ border: 'none', background: 'none' }}
                    onClick={() => setShowDialog(false)}
                  >
                    <RejectIcon color="#f02b1f" />
                  </button>
                </div>
              </div>
            </div>
          </PanelCard>
        </Dialog>
      </div>
    </>
  );
}

export default CustomEditor;

CustomEditor.propTypes = {
  getEditorValue: PropTypes.func,
  onSendClick: PropTypes.func,
  onAttachClick: PropTypes.func,
  chatFiles: PropTypes.instanceOf(Array),
  onFileDiscardClick: PropTypes.func,
  enableBullateList: PropTypes.bool,
  enableNumberList: PropTypes.bool,
  enableTable: PropTypes.bool,
  enableImageUpload: PropTypes.bool,
  disabled: PropTypes.bool,
  disabledSend: PropTypes.bool,
  visibleOptions: PropTypes.bool,
  charLimit: PropTypes.number,
  width: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string,
  required: PropTypes.bool,
};

CustomEditor.defaultProps = {
  getEditorValue: () => {},
  onSendClick: () => {},
  onAttachClick: () => {},
  chatFiles: [],
  onFileDiscardClick: () => {},
  enableBullateList: false,
  enableNumberList: false,
  enableTable: false,
  enableImageUpload: false,
  disabled: false,
  disabledSend: false,
  visibleOptions: false,
  charLimit: null,
  width: '',
  placeholder: '',
  label: '',
  id: '',
  required: false,
};
