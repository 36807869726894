import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
// import axios from 'axios';
// import { toast } from 'react-toastify';
import Googlemap from '../../../../Components/maps/maps';
import AutoSearch from '../../../../Components/auto-search/auto-search';
import ShippingProgressNoData from '../../../../Components/shipping-progress/shipping-progress-no-data/shipping-progress-no-data';
import { DOOR_ADDRESS, GET_GEOCODE } from '../../../../graphQL/addAddress';
import BaiduMap from '../../../../Components/maps/baidumap';
import './add-supplier-address.scss';

function AddSupplierAddress({
  //   Mode,
  initialGeoCordBool,
  origin,
  setOrigin,
  originOptions,
  setOrginOptions,
  originGeoCode,
  setOriginGeoCode,
  setOriginDoorAddress,
  setOriginCountry,
  sendautoCountryValue,
  sendautoCountryLabel,
  sendautoStateValue,
  sendautoStateLabel,
  sendautoZip,
  sendautoCity,
  // sendautoAreaName,
  sendautoStreetName,
  sendEditDefaultBool,
  initialGeoCord,
  //   onSelected,
  sendLat,
  sendLong,
  label,
  defaultLat,
  defaultLong,
  noDataMessage,
  placeholder,
  required,
  errorMessage,
  children,
  mode,
  ...otherProps
}) {
  const modeProperties = {
    material: 'material',
    normal: 'normal',
  };
  const modeProps = modeProperties[mode];
  const [t] = useTranslation('company');
  const [loading, setLoading] = useState(false);
  const [editDefalut, setEditDefalut] = useState(false);

  const [searchDoor] = useLazyQuery(DOOR_ADDRESS);
  const [getDoorGeoCode] = useLazyQuery(GET_GEOCODE);

  const getGeoCode = (SearchParameter, type) => {
    let cityFound = false;
    getDoorGeoCode({
      variables: { searchParameter: SearchParameter },
      onCompleted: (data) => {
        const setVars = data?.FetchGeoCode?.data[0]?.address_components;

        for (
          let i = 0;
          i < data?.FetchGeoCode.data[0]?.address_components.length;
          i++
        ) {
          if (
            setVars[i]?.types[0] === 'locality' &&
            setVars[i]?.types[1] === 'political'
          ) {
            cityFound = true;
            sendautoCity(
              data?.FetchGeoCode.data[0]?.address_components[i]?.long_name
            );
          }
          if (
            setVars[i]?.types[0] === 'administrative_area_level_2' &&
            setVars[i]?.types[1] === 'political' &&
            cityFound !== true
          ) {
            sendautoCity(
              data?.FetchGeoCode.data[0]?.address_components[i]?.long_name
            );
          }
          if (
            setVars[i]?.types[0] === 'administrative_area_level_1' &&
            setVars[i]?.types[1] === 'political'
          ) {
            sendautoStateValue(
              data?.FetchGeoCode.data[0]?.address_components[i]?.short_name
            );
            sendautoStateLabel(
              data?.FetchGeoCode.data[0]?.address_components[i]?.long_name
            );
            sendautoStreetName(data.FetchGeoCode.data[0].formatted_address);
          }
          if (
            setVars[i]?.types[0] === 'country' &&
            setVars[i]?.types[1] === 'political'
          ) {
            sendautoCountryLabel(
              data?.FetchGeoCode.data[0]?.address_components[i]?.long_name
            );
            sendautoCountryValue(
              data?.FetchGeoCode.data[0]?.address_components[i]?.short_name
            );
          }
          if (setVars[i]?.types[0] === 'postal_code') {
            sendautoZip(
              data?.FetchGeoCode.data[0]?.address_components[i]?.long_name
            );
          }
        }

        const geoCode = `${data.FetchGeoCode?.data[0].geometry.location.lat},${data.FetchGeoCode?.data[0].geometry.location.lng}`;
        if (type === 'origin') {
          setOriginGeoCode([{ GeoCoordinate: geoCode }]);
          setOriginDoorAddress(data.FetchGeoCode?.data[0].address_components);
        }
        sendLat(data.FetchGeoCode?.data[0].geometry.location.lat);
        sendLong(data.FetchGeoCode?.data[0].geometry.location.lng);
      },
    });
  };

  useEffect(() => {
    if (
      initialGeoCord !== '' &&
      editDefalut !== true &&
      initialGeoCordBool === true
    ) {
      getGeoCode(initialGeoCord, 'origin');
    }
  }, [initialGeoCord]);

  const serverCall = (type, searchTerm) => {
    setLoading(true);
    if (type === 'origin') {
      setOrginOptions([]);
    }
    if (type === 'origin' && searchTerm !== '') {
      searchDoor({
        variables: {
          searchParameter: searchTerm,
        },
        onCompleted: (data) => {
          const option =
            data &&
            data.GAutoSuggest.data.map((item) => {
              return {
                label: item.description,
                value: item.description,
              };
            });
          if (type === 'origin') {
            setOrginOptions(option);
          }
          setLoading(false);
        },
        onError: () => {
          // toast.error(err.message);
          setLoading(false);
        },
      });
    }
  };

  const handleChange = (e, type) => {
    // onSelected(e);
    serverCall(type, e.target.value);
  };

  const [state, setState] = useState({
    countryName: '',
  });

  const getGeoInfo = () => {
    setState({ countryName: '' });

    // axios
    //   .get('https://ipapi.co/json/')
    //   .then((response) => {
    //     const { data } = response;
    //     setState({
    //       ...state,
    //       countryName: data.country_name,
    //       countryCode: data.country_calling_code,
    //     });
    //   })
    //   .catch((error) => {
    //     toast.error(error.message);
    //   });
  };

  useEffect(() => {
    getGeoInfo();
  }, []);

  useEffect(() => {
    setOrginOptions([]);
  }, []);
  return (
    <>
      <div className="select-address-container">
        {/* {label !== '' && (
          <label htmlFor={label}>
            {label} {required && <span className="required">*</span>}
          </label>
        )} */}
        <AutoSearch
          options={originOptions}
          handleInputChanged={(e) => handleChange(e, 'origin')}
          removeSearchValue={() => {
            sendEditDefaultBool(false);
            setOrigin('');
            setOrginOptions([]);
            setOriginGeoCode('');
            sendautoCountryValue('');
            sendautoCountryLabel('');
            sendautoStateValue('');
            sendautoStateLabel('');
            sendautoZip('');
            sendautoCity('');
            sendautoStreetName('');
            // sendautoAreaName('');
          }}
          placeholder={
            placeholder !== ''
              ? placeholder
              : t(
                  'companydetailscreen.detailstab.editdetailspopup.addressplaceholder'
                )
          }
          onChange={(e) => {
            if (e.target.value !== '') {
              getGeoCode(e.target.value, 'origin');
            }
            setOriginGeoCode(
              originOptions.filter((item) => item.value === e.target.value)
            );

            if (originOptions && originOptions.length > 0) {
              sendEditDefaultBool(true);
              setEditDefalut(true);

              setOrigin(
                originOptions.filter((item) => item.value === e.target.value)[0]
                  ?.label
              );
              setOriginCountry(
                originOptions.filter((item) => item.value === e.target.value)[0]
                  ?.CountryName
              );
            }
          }}
          defaultValue={{
            value:
              initialGeoCord !== '' && editDefalut !== true
                ? initialGeoCord
                : origin,
          }}
          loading={loading}
          errorMessage={errorMessage}
          mode={modeProps}
          label={label}
          required={required}
          {...otherProps}
        >
          {children}
        </AutoSearch>
      </div>

      <div className="map-box">
        {originGeoCode[0]?.GeoCoordinate === 'NULL' || originGeoCode === '' ? (
          <ShippingProgressNoData message={noDataMessage} size="2.083vw" />
        ) : (
          <div>
            {state.countryName !== 'China' ? (
              <Googlemap
                zoom={8}
                height="8.333vw"
                width="100%"
                latitude={
                  (originGeoCode[0]?.GeoCoordinate &&
                    originGeoCode &&
                    parseFloat(
                      originGeoCode[0]?.GeoCoordinate?.toString().split(',')[0]
                    )) ||
                  parseFloat(defaultLat)
                }
                long={
                  (originGeoCode[0]?.GeoCoordinate &&
                    originGeoCode &&
                    parseFloat(
                      originGeoCode[0]?.GeoCoordinate?.toString().split(',')[1]
                    )) ||
                  parseFloat(defaultLong)
                }
              />
            ) : (
              <BaiduMap
                mapHeight="8.333vw"
                latitude={
                  originGeoCode[0]?.GeoCoordinate
                    ? originGeoCode &&
                      parseFloat(
                        originGeoCode[0]?.GeoCoordinate?.toString().split(
                          ','
                        )[0]
                      )
                    : 18.0
                }
                longitude={
                  originGeoCode[0]?.GeoCoordinate
                    ? originGeoCode &&
                      parseFloat(
                        originGeoCode[0]?.GeoCoordinate?.toString().split(
                          ','
                        )[1]
                      )
                    : 72.556
                }
              />
            )}
          </div>
        )}
      </div>
    </>
  );
}

AddSupplierAddress.propTypes = {
  sendautoCountryValue: PropTypes.func,
  sendautoStateValue: PropTypes.func,
  sendautoCountryLabel: PropTypes.func,
  sendautoStateLabel: PropTypes.func,
  sendautoZip: PropTypes.func,
  sendautoCity: PropTypes.func,
  sendautoStreetName: PropTypes.func,
  setOrigin: PropTypes.func,
  sendEditDefaultBool: PropTypes.func,
  initialGeoCordBool: PropTypes.bool,
  origin: PropTypes.string,
  initialGeoCord: PropTypes.string,
  originOptions: PropTypes.instanceOf(Array),
  setOrginOptions: PropTypes.func,
  originGeoCode: PropTypes.string,
  setOriginGeoCode: PropTypes.func,
  placeholder: PropTypes.string,
  setOriginDoorAddress: PropTypes.instanceOf(Array),
  setOriginCountry: PropTypes.func,
  sendLat: PropTypes.func,
  sendLong: PropTypes.func,
  label: PropTypes.string,
  defaultLong: PropTypes.string,
  defaultLat: PropTypes.string,
  noDataMessage: PropTypes.string,
  required: PropTypes.bool,
  errorMessage: PropTypes.string,
  children: PropTypes.node,
  mode: PropTypes.string,
};
AddSupplierAddress.defaultProps = {
  initialGeoCordBool: false,
  sendautoCountryValue: () => {},
  initialGeoCord: '',
  sendEditDefaultBool: () => {},
  sendautoCountryLabel: () => {},
  sendautoStateValue: () => {},
  sendautoStateLabel: () => {},
  sendautoZip: () => {},
  sendautoCity: () => {},
  sendautoStreetName: () => {},
  setOrigin: () => {},
  origin: '',
  placeholder: '',
  originOptions: [],
  setOrginOptions: () => {},
  originGeoCode: '',
  setOriginGeoCode: () => {},
  setOriginDoorAddress: () => {},
  setOriginCountry: () => {},
  sendLat: () => {},
  sendLong: () => {},
  defaultLong: '72.556',
  defaultLat: '18.0',
  label: '',
  noDataMessage: '',
  required: false,
  errorMessage: '',
  children: [],
  mode: 'normal',
};
export default AddSupplierAddress;
