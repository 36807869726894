import React from 'react';
import PropTypes from 'prop-types';
import './panel-card.scss';

function PanelCardFooter({ align, ...props }) {
  return <div className={`panel-card-footer ${align}`}>{props.children}</div>;
}

export default PanelCardFooter;
PanelCardFooter.propTypes = {
  children: PropTypes.node.isRequired,
  align: PropTypes.string,
};
PanelCardFooter.defaultProps = {
  align: '',
};
