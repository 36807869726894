import React from 'react';
import PropTypes from 'prop-types';
import './panel-card.scss';
import { BackIcon } from '../icons/icons';
import NewButton from '../button/newButton';

export default function PanelCard({
  cardTitle,
  noSpacing,
  smallSpacing,
  border,
  noShadow,
  actionText,
  actionType,
  actionClick,
  actionIcon,
  action,
  toggleButton,
  toggleButtonIcon,
  toggleOnClick,
  cardSubtitle,
  enableBack,
  onBackClick,
  toggleActionType,
  className,
  bodyClassName,
  disabled,
  headerClassName,
  ...props
}) {
  return (
    <div
      className={`panel-card-container ${border ? 'border' : ''} ${
        noShadow ? 'noshadow' : ''
      } ${className} ${disabled ? 'disabled' : ''}`}
    >
      {cardTitle && (
        <div
          className={`panel-card-header ${headerClassName}`}
          data-cy="panel-card-header"
        >
          <div className="d-flex">
            {enableBack && (
              <button
                type="button"
                className="button-back"
                onClick={onBackClick}
                data-cy="panel-header-button"
              >
                <BackIcon />
              </button>
            )}

            <h3 className="panel-card-title" data-cy="panel-card-title">
              {cardTitle}
              {cardSubtitle !== '' && (
                <span className="panel-card-subtitle">{cardSubtitle}</span>
              )}
            </h3>
          </div>

          {(actionText !== '' || toggleButton) && (
            <div className="action-area">
              {actionText !== '' && (
                <NewButton
                  color="primary"
                  className={`action ${actionType}`}
                  onClick={actionClick}
                >
                  {actionIcon && (
                    <span style={{ marginRight: '0.347vw' }}>{actionIcon}</span>
                  )}
                  {actionText}
                </NewButton>
              )}

              {toggleButton && (
                <NewButton
                  className={`toggle-button ${toggleActionType}`}
                  onClick={toggleOnClick}
                >
                  {toggleButtonIcon}
                </NewButton>
              )}
              {toggleButton && action && (
                <NewButton
                  className={`toggle-button action ${toggleActionType}`}
                  onClick={toggleOnClick}
                >
                  {toggleButtonIcon}
                </NewButton>
              )}
            </div>
          )}
        </div>
      )}
      <div
        data-cy="panel-card-body"
        className={`panel-card-body ${noSpacing ? 'nopadding' : ''} ${
          smallSpacing ? 'smallpadding' : ''
        } ${bodyClassName}`}
      >
        {props.children}
      </div>
    </div>
  );
}
PanelCard.propTypes = {
  cardTitle: PropTypes.string,
  noSpacing: PropTypes.bool,
  smallSpacing: PropTypes.bool,
  border: PropTypes.bool,
  noShadow: PropTypes.bool,
  children: PropTypes.node.isRequired,
  actionText: PropTypes.string,
  actionType: PropTypes.string,
  toggleActionType: PropTypes.string,
  actionClick: PropTypes.func,
  actionIcon: PropTypes.objectOf(PropTypes.symbol),
  toggleButtonIcon: PropTypes.objectOf(PropTypes.symbol),
  toggleButton: PropTypes.bool,
  toggleOnClick: PropTypes.func,
  cardSubtitle: PropTypes.string,
  enableBack: PropTypes.bool,
  onBackClick: PropTypes.func,
  action: PropTypes.bool,
  className: PropTypes.string,
  bodyClassName: PropTypes.string,
  headerClassName: PropTypes.string,
  disabled: PropTypes.bool,
};
PanelCard.defaultProps = {
  cardTitle: '',
  noSpacing: false,
  smallSpacing: false,
  border: false,
  noShadow: false,
  actionText: '',
  actionType: '',
  toggleActionType: '',
  actionIcon: {},
  toggleButtonIcon: {},
  toggleButton: false,
  toggleOnClick: () => {},
  actionClick: () => {},
  cardSubtitle: '',
  enableBack: false,
  onBackClick: () => {},
  action: false,
  className: '',
  bodyClassName: '',
  headerClassName: '',
  disabled: false,
};
