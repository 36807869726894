import withDefaultIconProps from './withDefaultIconProps'; // Adjust the path as necessary
import {
  Bold,
  Italic,
  Underline,
  LeftAlign,
  CenterAlign,
  RightAlign,
  JustifiedAlign,
  LinkOption,
  ImageOption,
  BulletList,
  NumberList,
  TableIcon,
} from '../icons/icons';

const BoldIcon = withDefaultIconProps(Bold);
const ItalicIcon = withDefaultIconProps(Italic);
const UnderlineIcon = withDefaultIconProps(Underline);
const LeftAlignIcon = withDefaultIconProps(LeftAlign);
const CenterAlignIcon = withDefaultIconProps(CenterAlign);
const RightAlignIcon = withDefaultIconProps(RightAlign);
const JustifiedAlignIcon = withDefaultIconProps(JustifiedAlign);
const LinkOptionIcon = withDefaultIconProps(LinkOption);
const ImageOptionIcon = withDefaultIconProps(ImageOption);
const BulletListIcon = withDefaultIconProps(BulletList);
const NumberListIcon = withDefaultIconProps(NumberList);
const TableIconIcon = withDefaultIconProps(TableIcon);

export {
  BoldIcon,
  ItalicIcon,
  UnderlineIcon,
  LeftAlignIcon,
  CenterAlignIcon,
  RightAlignIcon,
  JustifiedAlignIcon,
  LinkOptionIcon,
  ImageOptionIcon,
  BulletListIcon,
  NumberListIcon,
  TableIconIcon,
};
