import React from 'react';
import PropTypes from 'prop-types';
import './dashboard-card-panel.scss';

export default function DashboardCardPanel({
  border,
  noShadow,
  noSpacing,
  className,
  ...props
}) {
  return (
    <div
      className={`dashboard-card-panel-container ${border ? 'border' : ''} ${
        noShadow ? 'noshadow' : ''
      } ${noSpacing ? 'nopadding' : ''} ${className}`}
    >
      {props.children}
    </div>
  );
}
DashboardCardPanel.propTypes = {
  border: PropTypes.bool,
  noShadow: PropTypes.bool,
  children: PropTypes.node.isRequired,
  noSpacing: PropTypes.bool,
  className: PropTypes.string,
};
DashboardCardPanel.defaultProps = {
  border: false,
  noShadow: false,
  noSpacing: false,
  className: '',
};
