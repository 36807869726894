import React from 'react';
import PropTypes from 'prop-types';
import './number-card.scss';
import Tooltip from '../tooltip/tooltip';

function NumberCard({ value, status, content }) {
  return (
    <span className={`number-card ${status}`}>
      <Tooltip content={content}>{value} </Tooltip>
    </span>
  );
}

export default NumberCard;
NumberCard.propTypes = {
  value: PropTypes.number,
  status: PropTypes.string,
  content: PropTypes.string,
};
NumberCard.defaultProps = {
  value: 0,
  status: '',
  content: '',
};
