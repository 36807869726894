import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import './token-table.scss';
import { CopyClipborad } from '../../helpers/utils';
import CustomSwitch from '../custom-switch/custom-switch';
import { TokenCopyIcon } from '../icons/icons';
import Confirmation from '../confirmation/confirmation';
import Dialog from '../dialog/dialog';
import useDialog from '../dialog/useDialog';
import TOGGLE_APIKEY from '../../graphQL/tokenGQL';
import Tooltip from '../tooltip/tooltip';
import NoData from '../no-data/no-data';
import { getUserTimeZoneDateTime } from '../../helpers/dateHelper';
import { checkPermission } from '../../helpers/permissionHelper';
import { giveProfile } from '../global-notification/notification-list';
import { getUser } from '../../service/authService';

function TokenTable({ data, tokenRefetch, t }) {
  const [copy, setcopy] = useState(false);
  const [t2] = useTranslation('toast');
  const user = getUser();
  const { relation } = useParams();
  const [apiValue, setapiValue] = useState('');
  const [apiToken, setapiToken] = useState('');
  const { isShowing: isActivateDislog, toggle: activateToken } = useDialog();
  const { isShowing: isDeactivateDislog, toggle: deactivateToken } =
    useDialog();
  const [handleToken] = useMutation(TOGGLE_APIKEY);

  const serverToken = () => {
    handleToken({
      variables: {
        input: {
          APIKey: apiValue,
        },
      },
      onCompleted: () => {
        if (apiToken) {
          toast.success(t2('tokentable.success.deactivation'));
          deactivateToken();
        } else if (!apiToken) {
          toast.success(t2('tokentable.success.activated'));
          activateToken();
        }
        tokenRefetch();
      },
    });
  };
  const permission = checkPermission(
    giveProfile(relation),
    'Configuration',
    'API Documentation',
    'E'
  );

  const toggleStatus = (e, apiKey, apiKeyToken) => {
    setapiValue(apiKey);
    setapiToken(apiKeyToken);
    if (e.target.checked === true) {
      activateToken();
    } else {
      deactivateToken();
    }
  };
  return (
    <div>
      {data.length === 0 ? (
        <NoData />
      ) : (
        <table>
          <thead>
            <tr>
              <th className="title">
                {t('accountsettings.apidocument.tokencode')}
              </th>
              <th className="title">
                {t('accountsettings.apidocument.description')}
              </th>
              <th className="title">
                {t('accountsettings.apidocument.lastused')}
              </th>
              <th className="title">
                {t('accountsettings.apidocument.count')}
              </th>
              <th className="title">
                {t('accountsettings.apidocument.actions')}
              </th>
            </tr>
          </thead>
          <tbody>
            {data?.map((apis) => {
              return (
                <tr>
                  <td className="table-data w-30">
                    <button
                      type="button"
                      className="token-name"
                      onClick={() => CopyClipborad(apis?.APIKey)}
                    >
                      {apis?.APIKey && apis.APIKey.length > 25
                        ? `${apis.APIKey.slice(0, 25)}...`
                        : apis?.APIKey}
                    </button>
                  </td>
                  <td className="table-data w-50">
                    {apis?.description && apis.description.length > 50
                      ? `${apis.description.slice(0, 50)}...`
                      : apis?.description}
                  </td>
                  <td className="table-data">
                    {getUserTimeZoneDateTime(apis?.lastUsed) === 'Invalid date'
                      ? '-'
                      : getUserTimeZoneDateTime(apis?.lastUsed)}
                  </td>
                  <td className="table-data">{apis?.counter}</td>
                  <td
                    className={`d-flex table-data actions-padding ${
                      (!permission || user['custom:userType'] === '_user') &&
                      'copy-padding'
                    }`}
                  >
                    {copy ? (
                      <Tooltip
                        content={t('accountsettings.apidocument.copied')}
                      >
                        <button
                          type="button"
                          className="copied-btn"
                          onClick={() => {
                            CopyClipborad(apis?.APIKey);
                          }}
                        >
                          <TokenCopyIcon color="#1d4069" />
                        </button>
                      </Tooltip>
                    ) : (
                      <Tooltip content={t('accountsettings.apidocument.copy')}>
                        <button
                          type="button"
                          className="copied-btn"
                          onClick={() => {
                            CopyClipborad(apis?.APIKey);
                            setcopy(true);
                            setTimeout(() => {
                              setcopy(false);
                            }, 1000);
                          }}
                        >
                          <TokenCopyIcon color="#1d4069" />
                        </button>
                      </Tooltip>
                    )}
                    {!(user['custom:userType'] === '_user') &&
                      permission &&
                      (apis?.isActive ? (
                        <Tooltip
                          content={t(
                            'accountsettings.apidocument.popup.deactivate'
                          )}
                        >
                          <CustomSwitch
                            color="dark-blue"
                            size="small"
                            className="mt-4"
                            checked={apis?.isActive}
                            onChange={(e) => {
                              toggleStatus(e, apis?.APIKey, apis?.isActive);
                            }}
                          />
                        </Tooltip>
                      ) : (
                        <Tooltip
                          content={t(
                            'accountsettings.apidocument.popup.activate'
                          )}
                        >
                          <CustomSwitch
                            color="dark-blue"
                            size="small"
                            className="mt-4"
                            checked={apis?.isActive}
                            onChange={(e) => {
                              toggleStatus(e, apis?.APIKey, apis?.isActive);
                            }}
                          />
                        </Tooltip>
                      ))}
                  </td>
                </tr>
              );
            })}
          </tbody>
          <Dialog
            isShowing={isActivateDislog}
            hide={activateToken}
            placement="center"
          >
            <Confirmation
              cancelButtonText={t('accountsettings.apidocument.popup.cancel')}
              submitButtonText={t('accountsettings.apidocument.popup.confirm')}
              title={t('accountsettings.apidocument.popup.activate')}
              description={t(
                'accountsettings.apidocument.popup.activatedescription'
              )}
              onSubmitClick={() => {
                serverToken();
              }}
              onCancelClick={activateToken}
            />
          </Dialog>
          <Dialog
            isShowing={isDeactivateDislog}
            hide={deactivateToken}
            placement="center"
          >
            <Confirmation
              cancelButtonText={t('accountsettings.apidocument.popup.cancel')}
              submitButtonText={t('accountsettings.apidocument.popup.confirm')}
              title={t('accountsettings.apidocument.popup.deactivate')}
              description={t(
                'accountsettings.apidocument.popup.deactivatedescription'
              )}
              onCancelClick={deactivateToken}
              onSubmitClick={() => {
                serverToken();
              }}
            />
          </Dialog>
        </table>
      )}
    </div>
  );
}

TokenTable.propTypes = {
  data: PropTypes.instanceOf(Array).isRequired,
  tokenRefetch: PropTypes.func.isRequired,
  t: PropTypes.func,
};

TokenTable.defaultProps = {
  t: () => {},
};

export default TokenTable;
