import React, { useState } from 'react';
import PropTypes from 'prop-types';
import '../../Components/search-box/search-box.scss';
import OutsideClick from '../../Components/outside-click/outside-click';
import {
  CloseIcon,
  NormalSearchIcon,
  SearchIcon,
} from '../../Components/icons/icons';

export default function RolesSearchBox({
  width,
  searchValue,
  setSearchValue,
  margin,
  lineHeight,
  paddingRight,
  t,
}) {
  const [toggleSearchArea, setToggleSearchArea] = useState(!!searchValue);
  const [searchText, setSearchText] = useState(searchValue);

  const handleToggleSearchArea = () => {
    setToggleSearchArea(!toggleSearchArea);
  };

  const clearSearch = () => {
    setSearchValue('');
    setSearchText('');
  };

  const handleChange = (e) => {
    if (e.key === 'Enter') {
      setSearchValue(searchText);
    }
  };

  return (
    <div className="search-area" style={{ margin }}>
      {!toggleSearchArea && (
        <button
          type="button"
          className="button-transparent"
          onClick={handleToggleSearchArea}
        >
          <NormalSearchIcon />
        </button>
      )}
      {toggleSearchArea && (
        <OutsideClick onClickOutside={handleToggleSearchArea}>
          <div className="search-box">
            <input
              type="text"
              placeholder={t(
                'systemsettings.roles&permission.activecontactspopup.searchplaceholder'
              )}
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
              onKeyUp={handleChange}
              style={{ width, lineHeight, paddingRight }}
            />
            {searchValue === '' ? (
              <button type="button" className="button-transparent p-0">
                <SearchIcon />
              </button>
            ) : (
              <button
                type="button"
                className="button-transparent p-0"
                onClick={clearSearch}
              >
                <CloseIcon />
              </button>
            )}
          </div>
        </OutsideClick>
      )}
    </div>
  );
}
RolesSearchBox.propTypes = {
  width: PropTypes.string,
  setSearchValue: PropTypes.func,
  searchValue: PropTypes.string,
  margin: PropTypes.string,
  lineHeight: PropTypes.string,
  t: PropTypes.func,
  paddingRight: PropTypes.string,
};
RolesSearchBox.defaultProps = {
  width: '',
  setSearchValue: () => {},
  searchValue: '',
  margin: '',
  lineHeight: '',
  paddingRight: '',
  t: () => [],
};
