/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { Link } from 'react-router-dom';
import NewsLoader from './loader';
import './news.scss';

export default function News(data) {
  return (
    <div className="news-container">
      {data ? (
        data?.data?.QuloiNews?.map(
          ({ title, contentSnippet, link, enclosure }) => (
            <div className="news-item">
              <div className="image">
                {enclosure?.url ? (
                  <img src={enclosure?.url} alt="" />
                ) : (
                  <img
                    src="https://dummyimage.com/50x40.png/dddddd/000000"
                    alt=""
                  />
                )}
              </div>
              <div className="text">
                <p>
                  <a href={link} target="_blank" rel="noreferrer">
                    {title}
                  </a>
                </p>
              </div>
              <p className="description">
                <Link
                  to="/"
                  target="_blank"
                  className="news-line-text-truncate"
                >
                  <span>{contentSnippet}</span>
                </Link>
              </p>
            </div>
          )
        )
      ) : (
        <NewsLoader />
      )}
    </div>
  );
}
