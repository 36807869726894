import React, { useEffect, useState } from 'react';
import './login-register.scss';
import { useLazyQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import Login from './login/login';
import SignUp from './register/register';
import ForgotPassword from './forgotPassword/forgotpassword';
import { GET_CHANNEL_PARTNER } from '../../graphQL/registerGQL';
import LoginRegisterLoader from './loader';
import { PowredByColored, TranslateIcon } from '../../Components/icons/icons';
import { favIconUpdate } from '../../helpers/utils';
import NewButton from '../../Components/button/newButton';
import AdvancePopover from '../../Components/advance-popover/advance-popover';
import { RadioButton } from '../../Components/radio-button/radio-button';
import menuSchema from '../../schema/menuSchema';

export default function LoginRegister() {
  const [handleChannelPartner, { loading: isLoading, called: chnlCalled }] =
    useLazyQuery(GET_CHANNEL_PARTNER);
  const [isBranding, setIsBranding] = useState({});
  const [t, i18n] = useTranslation('global');
  const [eventValue, setEventValue] = useState();
  const togglePopover = (e) => {
    setEventValue(e.target);
  };

  const handleClose = () => {
    setEventValue(null);
  };

  const initChannel = (subdomain) => {
    handleChannelPartner({
      variables: {
        input: {
          subdomain,
        },
      },
      onCompleted: (response) => {
        setIsBranding({
          partnerId: response?.getChannelData?.data?.partnerId,
          contentConfig: response.getChannelData.data?.contentConfig,
          logoPath: response.getChannelData.data?.logoPath,
          favIcon: response?.getChannelData?.data?.favIcon,
        });
      },
    });
  };

  useEffect(() => {
    initChannel([window.location.hostname]);
  }, []);

  const languageSchema = menuSchema()?.languageSchema;

  useEffect(() => {
    favIconUpdate(isBranding?.favIcon, isBranding?.contentConfig?.orgName);
  }, [isBranding]);

  const [language, setLanguage] = useState({
    label: languageSchema?.filter(
      (lng) => lng?.value === (localStorage.getItem('language') || 'en')
    )?.[0]?.label,
    value: localStorage.setItem(
      'language',
      localStorage.getItem('language') || 'en'
    ),
  });

  if (isLoading && chnlCalled) {
    return <LoginRegisterLoader />;
  }

  return (
    <div
      className={`login-register-container ${
        window.location.pathname === '/login' ? 'login' : ''
      }`}
    >
      <div className="login-signup-block">
        <div
          className={
            window.location.pathname === '/register'
              ? 'login-signup-left ls-bg register'
              : 'login-signup-left ls-bg'
          }
        >
          <span className="ball">&nbsp;</span>
          <h1 data-cy="company-business">{t('loginscreen.header')}</h1>
          <p data-cy="company-desciption">
            {isBranding?.contentConfig?.loginScreens ||
              t('loginscreen.description')}
          </p>
          <a
            href="https://www.quloi.com/"
            className="know-more"
            target="_blank"
            rel="noreferrer"
            data-cy="company-know-more"
          >
            {t('loginscreen.morebtn')}
            <svg
              width="1.111vw"
              height="1.111vw"
              viewBox="0 0 16 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.8244 6.58328L6.90741 1.66628L8.20358 0.370117L15.3334 7.49995L8.20358 14.6298L6.90741 13.3336L11.8244 8.41662H0.666748V6.58328H11.8244Z"
                fill="#1d4069"
              />
            </svg>
          </a>
          <div className="footer">
            <p>
              <a
                href="/terms-and-conditions"
                target="_blank"
                rel="noreferrer"
                data-cy="terms-link"
              >
                {t('loginscreen.termsandconditions')}
              </a>
              <span>|</span>
              <a
                // href="https://www.quloi.com/privacy-policy/"
                href="/privacy-policy"
                target="_blank"
                rel="noreferrer"
                data-cy="privacy-link"
              >
                {t('loginscreen.privacypolicy')}
              </a>
            </p>
          </div>
        </div>
        <div
          className={
            window.location.pathname === '/register'
              ? 'login-signup-right register'
              : 'login-signup-right'
          }
        >
          <div className="d-flex justify-between align-center">
            <div className="logo" data-cy="logo">
              <img src={isBranding?.logoPath} alt="logo" />
            </div>
            <NewButton
              color="primary outline"
              onClick={togglePopover}
              id="languagePopover"
              data-cy="language-switch"
            >
              <TranslateIcon margin="0 0.694vw 0 0" size="1.25vw" />
              {language?.label}
            </NewButton>
            <AdvancePopover
              reference="languagePopover"
              getEvent={eventValue}
              closePopover={handleClose}
              placement="left"
            >
              <ul className="language-dropdown">
                {languageSchema?.map((lang) => {
                  return (
                    <li key={lang.label}>
                      <RadioButton
                        name="lang"
                        className="radio-button-individual"
                        value={lang.label}
                        defaultChecked={
                          localStorage.getItem('language') === lang.value
                        }
                        onChange={() => {
                          setLanguage(lang);
                          i18n.changeLanguage(lang.value);
                          window.localStorage.setItem('language', lang.value);
                          handleClose();
                          window.location.reload();
                        }}
                      >
                        {/* <img src={lang.icon} alt={lang.label} />{' '} */}
                        <span>{lang.label}</span>
                      </RadioButton>
                    </li>
                  );
                })}
              </ul>
            </AdvancePopover>
          </div>

          {window.location.pathname === '/register' && (
            <h2 className="title" data-cy="register">
              {t('registerscreen.header')}
            </h2>
          )}
          {(window.location.pathname === '/login' ||
            window.location.pathname === '/forgotpassword') && (
            <h2 className="title" data-cy="welcome-text">
              {isBranding?.contentConfig?.orgNames
                ? `Welcome to ${isBranding?.contentConfig?.orgName}`
                : t('loginscreen.welcome')}
            </h2>
          )}
          <p className="description">
            {window.location.pathname === '/register' && (
              <span data-cy="register-text">
                {t('registerscreen.registertxt')}
              </span>
            )}
            {window.location.pathname === '/login' && (
              <span data-cy="login-text">{t('loginscreen.logintxt')}</span>
            )}
            {window.location.pathname === '/forgotpassword' && (
              <span data-cy="forget-text">{t('loginscreen.resetmsg')}</span>
            )}
          </p>
          {window.location.pathname === '/login' && <Login />}
          {window.location.pathname === '/register' && <SignUp t={t} />}
          {window.location.pathname === '/forgotpassword' && <ForgotPassword />}
          {isBranding?.partnerId !== 0 && (
            <div className="powred-by">
              <PowredByColored width={80} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
