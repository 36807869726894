import React, { useState, useEffect } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import './add-new.scss';
import { PlusIcon } from '../../../../Components/icons/icons';
import Dialog from '../../../../Components/dialog/dialog';
import '../widget-details/widget-details.scss';

import LargeWidgets from '../lardge-widgets/lardge-widgets';
import SmallWidget from '../small-widgets/small-widget';
// import addNewData from './add-new-data';
import {
  QUERY_ADDSUBSCRIPTION,
  QUERY_FETCHNONSUBSCRIBEDWIDGET,
} from '../../../../graphQL/dashboardwidget';
import { gqlMicroServiceTokenClient } from '../../../../service/gqlClient';

import { giveProfile } from '../../../../Components/global-notification/notification-list';

/**
 * @typedef {'small' | 'large' | 'medium'} SizeType - Size choices for the Demo component.
 */

/**
 * Component to demonstrate different sizes, appearances, and popup options.
 * @param {Object} props - Component props.
 * @param {SizeType} props.size - Size of the component ('Small' or 'Large').
 * @returns {JSX.Element} - Rendered component.
 */
export default function AddNewWidget({ size, refreshData, t }) {
  const [toggleAddPopup, setToggleAddPopup] = useState(false);
  const { relation, companyId } = useParams();
  const [dynamicData, setDynamicData] = useState([]);
  const [dashboardWidgetData] = useLazyQuery(QUERY_FETCHNONSUBSCRIBEDWIDGET, {
    client: gqlMicroServiceTokenClient,
  });

  const [addSubscriptionWidget] = useMutation(QUERY_ADDSUBSCRIPTION, {
    client: gqlMicroServiceTokenClient,
  });

  const mainData = () => {
    //  setLoading(true);
    try {
      dashboardWidgetData({
        variables: {
          input: {
            profile: giveProfile(relation),
            ...(companyId && { partnerId: '1' }),
          },
        },

        onCompleted: (resp) => {
          setDynamicData(resp);
          // setLoading(false);
        },
      });
    } catch (error) {
      console.error(error);
      //  setLoading(false);
    }
  };
  useEffect(() => {
    mainData();
  }, []);

  const addWidget = (widgetKey) => {
    try {
      addSubscriptionWidget({
        variables: {
          input: {
            widgetKey,
            profile: giveProfile(relation),
            ...(companyId && { partnerId: '1' }),
          },
        },

        onCompleted: (resp) => {
          setDynamicData(resp);
          refreshData();
          // setLoading(false);
        },
      });
    } catch (error) {
      console.error(error);
      //  setLoading(false);
    }
  };

  const smallWidgetData = dynamicData?.fetchNonSubscribed?.smallWidget || [];
  // const [addSubscription]=useMutation

  const cardProperties = {
    small: 'small',
    medium: 'medium',
    large: 'large',
  };
  const buttonProperties = {
    small: {
      height: '3.472vw',
      width: '3.472vw',
      padding: '0.972vw',
      // background: 'transparent',
      // border: 'none',
      // Add more properties as needed for 'Small'
    },
    medium: {
      height: '7.639vw',
      width: '7.639vw',
      // Add more properties as needed for 'Medium'
    },
    large: {
      height: '7.639vw',
      width: '7.639vw',
      // Add more properties as needed for 'Large'
    },
  };
  const paragraphProperties = {
    small: {
      marginTop: '0.694vw',
      fontSize: '0.972vw',
      fontWeight: '600',
      lineHeight: '1.458vw',
      // Add more properties as needed for 'Small'
    },
  };

  const cardProps = cardProperties[size];
  const buttonProps = buttonProperties[size];
  const paragraphProps = paragraphProperties[size];
  const toggleAddNewPopup = () => {
    setToggleAddPopup(!toggleAddPopup);
  };
  return (
    <>
      {(smallWidgetData.length > 0 ||
        dynamicData?.fetchNonSubscribed?.largeWidget.length > 0) && (
        <button
          type="button"
          // style={{ ...cardProps }}
          className={`add-new-widget-container ${cardProps}`}
          onClick={toggleAddNewPopup}
        >
          <span style={{ ...buttonProps }} className="add-new-widget-button">
            <PlusIcon height="3.889vw" width="3.889vw" color="#616B80" />
          </span>
          <p className="add-new-widget-paragraph" style={{ ...paragraphProps }}>
            {t('addnewwidgets')}
          </p>
        </button>
      )}
      <Dialog
        placement="center"
        enableOutsideClick
        maskBG="linear-gradient(243.36deg, rgba(29, 63, 90, 0.8) 0%, rgba(29, 63, 90, 0.8) 51.32%, rgba(29, 63, 90, 0.8) 100%)"
        isShowing={toggleAddPopup}
        hide={toggleAddNewPopup}
        enableBlurBG
        disabledPointer
      >
        <div className="add-widget-container">
          <h3 className="add-widget-title">{t('widgetlibrary')}</h3>
          <p className="add-widget-sub-title">{t('widgetdescription')}</p>
          <div className="add-widget-body">
            <h3 className="add-widget-body-title">{t('tabwidgets')} </h3>
            <div
              className={`add-widget-row mb-45${
                smallWidgetData?.length < 3 ? ' justify-start' : ''
              }`}
            >
              {smallWidgetData?.map((val) => {
                const progress = val[Object.keys(val)[0]]?.progress;
                const value = val[Object.keys(val)[0]]?.currentValue;
                // totalOrders += val[Object.keys(val)[0]]?.currentValue || 0;
                const key = Object.keys(val)[0].toString();
                // const defaultStatus =
                //   val[Object.keys(val)[0]]?.defaultStatus || 0;

                return (
                  <div className="add-widget-small-col">
                    <SmallWidget
                      label={key}
                      value={value}
                      icon={val?.icon}
                      progress={progress}
                      valueIcon={val?.valueIcon}
                      onAddClick={(wkey) => {
                        addWidget(wkey);
                        toggleAddNewPopup();
                      }}
                      widgetKey={val[Object.keys(val)[0]]?.widgetKey}
                      widget={val[Object.keys(val)[0]]?.key}
                      enableHover
                      t={t}
                    />
                  </div>
                );
              })}
            </div>
            <h4 className="add-widget-body-title">{t('informativewidgets')}</h4>
            <div className="add-widget-row">
              {dynamicData?.fetchNonSubscribed?.largeWidget?.map((lval) => {
                return (
                  <div className="add-widget-large-col">
                    <LargeWidgets
                      title={lval.title}
                      chartType={lval.type}
                      cardType={lval?.cardType} // lval?.largeData?.cardType
                      data={lval?.chartdata}
                      disableDownload
                      disableExpand
                      detailsWidget
                      disableButtons
                      enabelHover
                      onAddClick={(wkey) => {
                        addWidget(wkey);
                        toggleAddNewPopup();
                      }}
                      widget={lval.key}
                      widgetKey={lval.widget}
                      chartAspectRatio={lval.aspectRatio}
                      chartHeight={lval.chartHeight}
                      t={t}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
}

AddNewWidget.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'Large']),
  refreshData: PropTypes.func,
  t: PropTypes.func,
};

AddNewWidget.defaultProps = {
  size: 'medium',
  refreshData: () => {},
  t: () => {},
};
