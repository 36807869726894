import React from 'react';
import './message.scss';
import Skeleton from '../skeleton/skeleton';
import UserGroupLoader from '../user-group/loader';

export default function MessageHeaderLoader() {
  return (
    <div className="message-header">
      <div className="current-user-block">
        <div className="user-image">
          <Skeleton skHeight="1.944vw" skWidth="1.944vw" skRadius="50%" />
        </div>
        <div className="user-details">
          <Skeleton
            skHeight="0.694vw"
            skWidth="6.944vw"
            marginBottom="0.694vw"
          />
          <Skeleton skHeight="0.694vw" skWidth="10.417vw" />
        </div>
      </div>

      <div className="user-group-block">
        <UserGroupLoader />
      </div>
    </div>
  );
}
