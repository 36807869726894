import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import './forgot-password.scss';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { gql, useLazyQuery } from '@apollo/client';
import ForgotPasswordVerify from './forgotpasswordverify';
import TextBoxIcon from '../../../Components/text-box-icon/text-box-icon';
import GlobalLoader from '../../../Components/global-loader/global-loader';
import { EmailIcon } from '../../../Components/icons/icons';

const QUERY_FORGOTPASSWORD = gql`
  query userForgotInitiate($input: forgetPassword) {
    userforgotPassword(input: $input) {
      message
    }
  }
`;

function ForgotPassword() {
  const [forgotPayload, setForgotPayload] = useState({
    email: '',
    clientDomain: window.location.hostname,
  });
  const [t] = useTranslation('global');
  const [resetPassword, setResetPassword] = useState();

  const [handleForgotPass, { loading }] = useLazyQuery(QUERY_FORGOTPASSWORD);

  const serverCall = () => {
    handleForgotPass({
      variables: {
        input: forgotPayload,
      },
      onCompleted: (data) => {
        toast.success(t(`forgotpassword.${data.userforgotPassword.message}`));
        setResetPassword(true);
      },
      onError: () => {
        // toast.error(error.message);
        setResetPassword(false);
      },
    });
  };

  const handleChange = (e) => {
    setForgotPayload({
      ...forgotPayload,
      [e.target.name]: e.target.value,
    });
  };

  const formik = useFormik({
    initialValues: { email: '' },
    validationSchema: Yup.object({
      email: Yup.string()
        .email(t('loginscreen.login.emailinvalidatemsg'))
        .required(t('loginscreen.login.emailrequiredmsg')),
    }),
    onSubmit: (values) => {
      const payload = {
        email: values.email,
        // accessToken: getToken(),
      };
      serverCall(payload);
    },
  });

  return (
    <div className="forgot-password-container">
      {loading && (
        <div className="login-loader">
          <GlobalLoader newLoad={true} />
        </div>
      )}
      {!resetPassword ? (
        <form onSubmit={formik.handleSubmit}>
          {/* <div className="login-group">
            <span className="group-icon">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M4.636 5.04492H19.363C20.267 5.04492 21 5.77792 21 6.68192V17.3179C21 18.2219 20.267 18.9539 19.364 18.9539H4.636C3.733 18.9549 3 18.2219 3 17.3179V6.68192C3 5.77792 3.733 5.04492 4.636 5.04492Z"
                  stroke="#828282"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M3.11914 6.07504L10.8131 11.578C11.5081 12.075 12.4421 12.076 13.1381 11.58L20.8761 6.06104"
                  stroke="#828282"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span> */}
          <TextBoxIcon
            required
            id="email"
            type="email"
            name="email"
            data-cy="email-first-forgot-page"
            {...formik.getFieldProps('email')}
            onChange={(e) => {
              formik.setFieldValue('email', e.target.value);
              handleChange(e);
            }}
            label={t('loginscreen.login.email')}
            // placeholder="email"
            // onError={formik.errors.email}
            errorMessage={
              formik.touched.email && formik.errors.email
                ? formik.errors.email
                : null
            }
          >
            <EmailIcon color="#A6A6A6" />
          </TextBoxIcon>
          {/* </div> */}
          {/* {formik.touched.email && formik.errors.email ? (
            <p className="error-message">{formik.errors.email}</p>
          ) : null} */}
          <div className="login-group btn-block">
            <button
              type="submit"
              className="submit"
              data-cy="submitbtn-forget-page"
            >
              {t('loginscreen.forgotsubmitbtn')}
            </button>
          </div>
          <div className="login-group link" data-cy="back-to-login">
            <Link to="/login">{t('loginscreen.forgotbackloginmsg')}</Link>
          </div>
        </form>
      ) : (
        <ForgotPasswordVerify userEmail={forgotPayload.email} t={t} />
      )}
    </div>
  );
}
export default ForgotPassword;
