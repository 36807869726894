import React from 'react';
import './unverified.scss';
import Logo from '../../assets/image/logo.png';
import InfoClock from '../../assets/image/InfoClock.svg';
import { logout } from '../../service/authService';

export default function UnVerified() {
  return (
    <div className="un-verifiyed-container">
      <div className="un-verifiyed">
        <div className="un-verifiyed-wrap">
          <div className="co-logo">
            <img src={Logo} alt="" />
          </div>
          <div className="icon">
            <img src={InfoClock} alt="" />
          </div>
          <h3>Your account verification is Pending!</h3>
          <p>
            Since, your account is not yet verified so, you have limited access{' '}
            <br />
            Please contact at{' '}
            <a href="mailto:admin@quloi.com">admin@quloi.com </a>
          </p>
          <div className="button-block">
            <button
              type="button"
              onClick={(e) =>
                e.target.innerHTML === 'Logout' ? logout() : null
              }
            >
              Logout
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
