import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import './actions-btn.scss';
// import DialogPanel from '../../Components/dialog-panel/dialog-panel';

function ActionButton({ className, onClick, children, disabled }) {
  const buttonContent = useRef(null);
  const [buttonWidth, setButtonWidth] = useState('1.806vw');
  const getButtonWidth = () => {
    setButtonWidth(buttonContent.current.getBoundingClientRect().width + 2);
  };

  return (
    <button
      type="button"
      className={`action-button ${className}`}
      onClick={onClick}
      disabled={disabled}
      onMouseOver={getButtonWidth}
      onFocus={getButtonWidth}
      onMouseOut={() => setButtonWidth('1.806vw')}
      onBlur={() => setButtonWidth('1.806vw')}
      style={{ width: buttonWidth }}
    >
      <span className="btn-content" ref={buttonContent}>
        {children}
      </span>
    </button>
  );
}

ActionButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node,
  disabled: PropTypes.bool,
};

ActionButton.defaultProps = {
  className: '',
  onClick: () => {},
  children: {},
  disabled: false,
};

export default ActionButton;
