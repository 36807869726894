import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import BesicTable from '../../../../../Components/table/basicTable';
import UserDetails from '../../../../../Components/user-details/user-details';
import './list-card.scss';
import NoData from '../../../../../Components/no-data/no-data';
import ShipmentMode from '../../../../../Components/shipment-mode/shipment-mode';
import { VIEW_QUOTATION_LIST } from '../../../../../graphQL/bookingList';
import CountCard from '../../../../../Components/count-card/count-card';
import { BookListRateQuote } from '../../../../../schema/orderColumns';
import { giveProfile } from '../../../../../Components/global-notification/notification-list';
import { getUserTimeZoneDateFormat } from '../../../../../helpers/dateHelper';

function ListCard({ listData, mode, companySpecific }) {
  let columnNames = [];
  if (listData && listData.length > 0) {
    columnNames = Object.keys(listData[0]);
  }

  const listColumn = [
    {
      Header: columnNames[0] ? columnNames[0] : 'Suppliers',
      accessor: columnNames[0] || 'supplierName',
      Cell: (data) => {
        const { row } = data;
        return (
          <>
            {row.original.Suppliers && (
              <UserDetails
                size="small"
                profilePic={row.original.image}
                userName={row.original[columnNames[0] || 'name']}
                enableLink={row.original.redirectUrl}
                url={row.original.redirectUrl}
              />
            )}
            {!row.original.Suppliers && (
              <p className="count text-left">
                {row.original.redirectUrl ? (
                  <Link to={row.original.redirectUrl} className="link">
                    {row.original[columnNames[0] || 'name']}{' '}
                  </Link>
                ) : (
                  row.original[columnNames[0]]
                )}
              </p>
            )}
          </>
        );
      },
    },
    {
      Header: columnNames[1] ? columnNames[1] : 'Number of orders',
      accessor: columnNames[1] || 'orderCount',
      align: 'center',
      Cell: (data) => {
        const { row } = data;
        const { relation } = useParams();
        //  {mode === 'Booking' && ({
        const [quoteList, setQuoteList] = useState([]);
        const [handleQuotations] = useLazyQuery(VIEW_QUOTATION_LIST);

        const handleQuotes = (ip) => {
          handleQuotations({
            variables: {
              input: {
                referenceId: ip?.referenceId,
                poNumber: ip?.poNumber,
                bookingType: ip?.bookingType,
                profileType: giveProfile(relation),
              },
            },
            onCompleted: (resp) => {
              setQuoteList(resp?.ViewQuotations?.data);
            },
          });
        };
        //   })
        return (
          <>
            {mode === 'Shipment' && companySpecific && (
              <div className="d-flex justify-center">
                <ShipmentMode
                  shipmentMode={row.original.Mode}
                  bookingType={
                    row.original.Mode === 'Inland'
                      ? row?.original?.bookingType
                      : ''
                  }
                />
              </div>
            )}

            {(mode === 'Booking' ||
              (mode === 'quotation' && relation === 'forwarder')) &&
              companySpecific && (
                <CountCard
                  onClick={() => handleQuotes(row?.original)}
                  data={quoteList}
                  label={
                    row?.original?.rateQoute > 1
                      ? `${row?.original?.rateQoute} Quotes`
                      : `${row?.original?.rateQoute} Quote`
                  }
                  enableDynamicTable
                  columns={BookListRateQuote}
                  align="center"
                />
              )}
            {companySpecific === false && mode !== 'quotation' && (
              <p className="count">
                {row.original[columnNames[1] || 'orderCount']}
              </p>
            )}
            {mode === 'quotation' && relation !== 'forwarder' && (
              <p className="count">
                {row.original[columnNames[1] || 'orderCount']}
              </p>
            )}
          </>
        );
      },
    },

    {
      Header: columnNames[2] ? columnNames[2] : 'Latest Due Date ',
      accessor: columnNames[2] || 'dueDate',
      Cell: (data) => {
        const { row } = data;

        return (
          <div className="d-flex align-center">
            <p
              className={
                getUserTimeZoneDateFormat(
                  row.original[columnNames[2] || 'dueDate']
                ) !== '01/01/1970'
                  ? 'date'
                  : 'count'
              }
            >
              {getUserTimeZoneDateFormat(
                row.original[columnNames[2] || 'dueDate']
              ) !== '01/01/1970'
                ? getUserTimeZoneDateFormat(
                    row.original[columnNames[2] || 'dueDate']
                  )
                : row.original[columnNames[2]]}
            </p>
          </div>
        );
      },
    },
  ];
  if (
    columnNames &&
    columnNames.length > 3 &&
    columnNames[3] !== 'redirectUrl' &&
    columnNames[3] !== 'referenceId'
  ) {
    listColumn.push({
      Header: columnNames[3],
      accessor: columnNames[3],
      Cell: (data) => {
        const { row } = data;
        return (
          <div className="d-flex align-center">
            <p
              className={
                getUserTimeZoneDateFormat(row.original[columnNames[3]]) !==
                '01/01/1970'
                  ? 'date'
                  : 'count'
              }
            >
              {getUserTimeZoneDateFormat(row.original[columnNames[3]]) !==
              '01/01/1970'
                ? getUserTimeZoneDateFormat(row.original[columnNames[3]])
                : row.original[columnNames[3]]}
            </p>
          </div>
        );
      },
    });
  }
  return (
    <div className="list-card">
      {listData.length > 0 ? (
        <BesicTable columns={listColumn} data={listData} />
      ) : (
        <NoData />
      )}
    </div>
  );
}

export default ListCard;
ListCard.propTypes = {
  listData: PropTypes.instanceOf(Array),
  mode: PropTypes.string,
  companySpecific: PropTypes.bool,
};
ListCard.defaultProps = {
  listData: [],
  mode: '',
  companySpecific: false,
};
