import React, { useEffect, useState } from 'react';
import PropTypes, { number } from 'prop-types';
import './custom-popover.scss';

function Popover({
  placement,
  getEvent,
  closePopover,
  reference,
  closeOnclick,
  ...props
}) {
  const [leftPosition, setLeftPosition] = useState();
  const [topPosition, setTopPosition] = useState();
  const [popoverHeight, setPopoverHeight] = useState(0);
  const [popoverWidth, setPopoverWidth] = useState(0);
  useEffect(() => {
    setTimeout(() => {
      setPopoverHeight(
        document?.getElementById('popoverContainer')?.offsetHeight
      );
      setPopoverWidth(
        document?.getElementById('popoverContainer')?.offsetWidth
      );

      if (
        typeof getEvent === 'object' &&
        getEvent !== null &&
        'getBoundingClientRect' in getEvent
      ) {
        const rectangle = getEvent?.getBoundingClientRect();
        switch (placement) {
          case 'right':
            setLeftPosition(
              rectangle.x + getEvent.clientWidth + rectangle.width
            );
            setTopPosition(rectangle.y + getEvent.clientHeight);
            break;
          case 'top':
            setLeftPosition(rectangle.x);
            setTopPosition(rectangle.y - popoverHeight);
            break;
          case 'left':
            setLeftPosition(rectangle.x - popoverWidth);
            setTopPosition(rectangle.y + getEvent.clientHeight);
            break;
          case 'center':
            setLeftPosition(rectangle.x - getEvent.clientWidth / 2);
            setTopPosition(rectangle.y + getEvent.clientHeight);
            break;
          case 'middle':
            setLeftPosition(rectangle.x);
            setTopPosition(rectangle.y);
            break;
          default:
            setLeftPosition(rectangle.x);
            setTopPosition(rectangle.y + getEvent.clientHeight);
        }
      } else {
        setLeftPosition();
        setTopPosition();
      }
    }, 10);
  }, [getEvent]);

  return (
    <>
      <div
        className={
          leftPosition && topPosition && reference === getEvent?.id
            ? 'popover-mask'
            : 'popover-mask popover-hide'
        }
        onClick={closePopover}
        onKeyUp={closePopover}
        role="button"
        tabIndex="0"
      >
        &nbsp;
      </div>
      {closeOnclick ? (
        <div
          onClick={closePopover}
          onKeyDown={closePopover}
          role="button"
          tabIndex="0"
          className={
            leftPosition && topPosition && reference === getEvent?.id
              ? 'popover-container'
              : 'popover-container popover-hide'
          }
          id="popoverContainer"
          style={{ left: leftPosition, top: topPosition }}
        >
          {props.children}
        </div>
      ) : (
        <div
          className={
            leftPosition && topPosition && reference === getEvent?.id
              ? 'popover-container'
              : 'popover-container popover-hide'
          }
          id="popoverContainer"
          style={{ left: leftPosition, top: topPosition }}
        >
          {props.children}
        </div>
      )}
    </>
  );
}

export default Popover;
Popover.propTypes = {
  placement: PropTypes.string,
  getEvent: PropTypes.objectOf(number).isRequired,
  closePopover: PropTypes.func.isRequired,
  reference: PropTypes.string,
  closeOnclick: PropTypes.func,
  children: PropTypes.node.isRequired,
};
Popover.defaultProps = {
  placement: '',
  reference: '',
  closeOnclick: () => {},
};
