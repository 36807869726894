import React from 'react';
import PropTypes from 'prop-types';
import './custom-tag.scss';

export default function CustomTag({ text, type, className }) {
  return (
    <span
      className={`custom-tag${
        text === 'keyAdmin' || text === 'Key Admin' ? ' keyAdmin' : ''
      }${text === 'admin' ? ' admin' : ''}${
        type === 'approved' ? ' admin' : ''
      }${type === 'declined' ? ' declined' : ''}${
        type === 'waiting' ? ' waiting' : ''
      }${className ? ` ${className}` : ''}`}
    >
      {text}
    </span>
  );
}
CustomTag.propTypes = {
  text: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
};
CustomTag.defaultProps = {
  text: '',
  type: '',
  className: '',
};
