import React, { useEffect, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useLazyQuery } from '@apollo/client';
import io from 'socket.io-client';
import { GET_CHANNEL_PARTNER } from '../../graphQL/registerGQL';
import GlobalLeftMenu from '../global-left-menu/global-left-menu';
import LeftMenu from '../left-menu/left-menu';
import { getUser } from '../../service/authService';
import {
  addChannelPartnerData,
  newNotificationAdded,
  storeNotificationsInStore,
} from '../../redux/actions';
import {
  // getNotifications,
  getUserUnreadNotifications,
} from '../../service/chatAPIHandler';
import createOrgWiseNotificationMap from '../../helpers/notificationHelper';
import NotificationList, {
  giveProfile,
} from '../global-notification/notification-list';
import URLConst from '../../service/URLConst';
import { favIconUpdate } from '../../helpers/utils';
import { getNotificationCountAPI } from '../../service/APIUtil';

const socket = io(URLConst.host, {
  autoConnect: false,
});

export default function MenuContainer() {
  const user = getUser();
  const dispatch = useDispatch();
  const { relation } = useParams();
  const [isBranding, setIsBranding] = useState({});
  const [groupMenu, setGroupmenu] = useState(false);
  const [addNewGroup, setAddNewGroup] = useState(false);
  const [disabledLeftMenu, setDisabledLeftMenu] = useState(false);
  const [editGroup, setEditGroup] = useState();
  const [notificationSection, setNotificationSection] = useState(false);
  const [approvalNotificationSection, setApprovalNotificationSection] =
    useState(false);
  const [actionsNotificationSection, setActionsNotificationSection] =
    useState(false);
  const [documentNotificationSection, setDocumentNotificationSection] =
    useState(false);
  const [poChangesNotificationSection, setPoChangesNotificationSection] =
    useState(false);
  const [chatsNotificationSection, setChatsNotificationSection] =
    useState(false);
  const [notificationCount, setNotificationCount] = useState({});
  const [handleChannelPartner, { called, loading }] =
    useLazyQuery(GET_CHANNEL_PARTNER);

  const [notification, setNotification] = useState('');
  const toggleGroup = () => {
    setGroupmenu(true);
  };
  const addGroup = () => {
    setAddNewGroup(true);
    setDisabledLeftMenu(true);
  };
  const closeAddnewGroup = () => {
    setAddNewGroup(false);
    setDisabledLeftMenu(false);
  };
  const closeGroup = () => {
    setAddNewGroup(false);
    setDisabledLeftMenu(false);
  };
  const hideGroup = () => {
    setGroupmenu(false);
    setAddNewGroup(false);
  };
  const removeGroup = () => {
    setGroupmenu(false);
  };
  const clickEditGroup = () => {
    setEditGroup(true);
    setDisabledLeftMenu(true);
  };
  const closeEditGroup = () => {
    setEditGroup(false);
    setDisabledLeftMenu(false);
  };
  const handleShowNotification = () => {
    setNotificationSection(true);
    setNotification('all');
  };
  const handleApprovalNotification = () => {
    setApprovalNotificationSection(true);
    setNotification('approval');
  };
  const handleActionsNotification = () => {
    setActionsNotificationSection(true);
    setNotification('action');
  };

  const handledocumentNotification = () => {
    setDocumentNotificationSection(true);
    setNotification('document');
  };
  const handlePoChangestNotification = () => {
    setPoChangesNotificationSection(true);
    setNotification('Po Changes');
  };
  const handleChats = () => {
    setChatsNotificationSection(true);
    setNotification('Chats');
  };
  const handleCloseNotification = () => {
    setNotificationSection(false);
    setApprovalNotificationSection(false);
    setActionsNotificationSection(false);
    setDocumentNotificationSection(false);
    setPoChangesNotificationSection(false);
    setChatsNotificationSection(false);
    setNotification('');
  };
  const closeGrouponClick = () => {
    setGroupmenu(false);
  };

  const notifCountFunc = async () => {
    const notificationPro = giveProfile(relation);
    const notifiData = await getNotificationCountAPI(notificationPro);
    setNotificationCount(notifiData);
  };

  useEffect(() => {
    notifCountFunc();
    return () => {};
  }, [relation]);

  useEffect(() => {
    socket.connect();
    async function getNotifications() {
      try {
        // const dispatch = useDispatch();
        const response = await getUserUnreadNotifications();
        dispatch(
          storeNotificationsInStore(
            createOrgWiseNotificationMap(response.data.data)
          )
        );
      } catch (error) {
        // DO SOMTHING HERE AFTER CATCHING ERROR
      }
    }
    getNotifications();
    return () => {
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    socket.on(`${user.sub}-notification`, (payload) => {
      dispatch(newNotificationAdded(payload));
      notifCountFunc();
    });
    return () => {};
  });

  const initChannel = (partnerId) => {
    handleChannelPartner({
      variables: {
        input: {
          partnerId,
        },
      },
      onCompleted: (response) => {
        dispatch(addChannelPartnerData(response?.getChannelData?.data));
        setIsBranding(response?.getChannelData?.data);
      },
    });
  };

  useEffect(() => {
    const partnerId = Number(user['custom:channelPartners']) || 0;
    initChannel(partnerId);
  }, []);

  useEffect(() => {
    favIconUpdate(isBranding?.favIcon, isBranding?.contentConfig?.orgName);
  }, [isBranding]);

  return (
    <div className="wrapper">
      <div className="wrapper-left">
        <div className="g-left-menu">
          <GlobalLeftMenu
            toggleGroup={toggleGroup}
            addGroup={addGroup}
            hideGroup={hideGroup}
            closeAddnewGroup={closeAddnewGroup}
            disabled={disabledLeftMenu}
            removeGroup={removeGroup}
            isBranding={isBranding?.partnerId !== 0}
            // count={notificationCount}
          />
        </div>
        <div className="left-menu">
          <LeftMenu
            groupVisible={groupMenu}
            addNewGroup={addNewGroup}
            cancelUserGroup={closeGroup}
            clickAddUserGroup={addGroup}
            isEnableAddGroup={addNewGroup}
            clickEditGroup={clickEditGroup}
            editGroup={editGroup}
            closeEditGroup={closeEditGroup}
            closeGroup={closeGrouponClick}
            showNotification={handleShowNotification}
            approvalNotification={handleApprovalNotification}
            actionsNotification={handleActionsNotification}
            documentNotificationMenu={handledocumentNotification}
            activeNotificationMenu={notification}
            isBrandingLogo={isBranding?.logoPath}
            brandLoder={called && loading}
            notifiCount={notificationCount}
            menuConfig={isBranding?.leftMenuConfig}
            isOnmi={isBranding?.partnerId === 0}
            pochanges={['buyer', 'supplier'].includes(giveProfile(relation))}
            poChangesNotification={handlePoChangestNotification}
            showChats={handleChats}
            enableChat
          />
          {notificationSection && (
            <NotificationList
              closeNotification={handleCloseNotification}
              value="milestone"
              show={true}
            />
          )}
          {approvalNotificationSection && (
            <NotificationList
              closeNotification={handleCloseNotification}
              value="approval"
              show={false}
            />
          )}
          {actionsNotificationSection && (
            <NotificationList
              closeNotification={handleCloseNotification}
              value="actionable"
              show={false}
            />
          )}
          {documentNotificationSection && (
            <NotificationList
              closeNotification={handleCloseNotification}
              value="document"
              show={false}
            />
          )}
          {poChangesNotificationSection && (
            <NotificationList
              closeNotification={handleCloseNotification}
              value="poChange"
              show={true}
            />
          )}
          {chatsNotificationSection && (
            <NotificationList
              closeNotification={handleCloseNotification}
              value="chat"
            />
          )}
        </div>
      </div>
      <div className="wrapper-right">
        <Outlet />
      </div>
    </div>
  );
}
