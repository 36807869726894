import React from 'react';
import PropTypes from 'prop-types';
import './account-settings-panel.scss';

function AccountSettingsPanel({ className, ...props }) {
  return (
    <div className={`account-settings-panel ${className}`}>
      {props.children}
    </div>
  );
}

export default AccountSettingsPanel;
AccountSettingsPanel.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};
AccountSettingsPanel.defaultProps = {
  className: '',
};
