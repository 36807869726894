import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './selectable-list-item.scss';
import { SearchIcon } from '../icons/icons';
import UserImage from '../user-image/user-image';
import getInitials from '../../helpers/utils';
// import { ShortDownIcon } from '../icons/icons';

export default function SelectableListItem({
  placeholder,
  onChange,
  data,
  handleInputChanged,
  defaultValue,
  label,
  mode,
  defaultArray,
  behaviour,
  t,
}) {
  const [query, setQuery] = useState('');
  const [selectedValue, setSelectedValue] = useState();
  const [openDDL, setOpenDDl] = useState();
  const [searchValue, setSearchValue] = useState();
  const [toggleOptions, setToggleOptions] = useState();
  const [getValueList, setGetValueList] = useState(false);
  useEffect(() => {
    if (defaultArray?.length) {
      setGetValueList(true);
      const arr = defaultArray?.map((item) => item?.value);
      setSelectedValue(arr);
    } else {
      setGetValueList(false);
      setSelectedValue(defaultValue?.label);
    }
    setSearchValue('');
    setOpenDDl(false);
  }, [defaultValue?.label, defaultArray]);

  const getValue = (e) => {
    if (defaultArray?.length) {
      setSelectedValue((prev) => {
        let updatedPrev = prev;
        if (e?.target?.checked && !selectedValue?.includes(e?.target?.value)) {
          updatedPrev?.push(e.target.value);
        }
        if (!e?.target?.checked && selectedValue?.includes(e?.target?.value)) {
          updatedPrev = updatedPrev?.filter(
            (item) => item !== e?.target?.value
          );
        }
        return updatedPrev;
      });
    } else {
      setSelectedValue(e?.target?.nextSibling?.innerHTML);
    }
    onChange(e);
    setOpenDDl(false);
    setSearchValue('');
  };

  const handleChange = (e) => {
    setQuery(e?.target?.value?.toLowerCase());
    handleInputChanged(e);
    setSearchValue(e?.target?.value);
  };
  const openList = () => {
    setToggleOptions(true);
  };
  const closeList = () => {
    setToggleOptions(false);
  };
  return (
    <div className={`selectable-list-item ${openDDL ? 'active' : ''}`}>
      {label !== '' && <label htmlFor={label}>{label}</label>}
      <div className="textbox-area">
        {!getValueList ? (
          <input
            type="text"
            placeholder={placeholder}
            onChange={(e) => {
              handleChange(e);
              openList();
            }}
            onClick={openList}
            value={searchValue}
            className="selected-value"
            id={label}
          />
        ) : (
          <button className="selected-value" type="button" onClick={openList}>
            {defaultArray?.[0]?.label}
            {defaultArray?.length > 1 && (
              <span className="counter">
                {defaultArray?.length > 1 ? '+' : ''}
                {defaultArray?.length > 1 ? defaultArray.length - 1 : null}
              </span>
            )}
          </button>
        )}
        <SearchIcon />
      </div>
      {toggleOptions && (
        <button
          type="button"
          className="selectable-list-item-mask"
          onClick={closeList}
        />
      )}
      {toggleOptions && (
        <ul
          className={`selectable-list-item-options ${
            mode === 'multi-select' ? 'multi-select' : ''
          } ${behaviour}`}
        >
          {data?.filter((val) => val?.label?.toLowerCase()?.includes(query))
            ?.length === 0 && (
            <li>
              <label className="option">
                <span className="label no-data">
                  {t(
                    'manageordersscreen.createbookingmenu.addforwarder.pickupvendor.selectpickupvendorplcnores'
                  )}
                </span>
              </label>
            </li>
          )}
          {data
            ?.filter((val) => val?.label?.toLowerCase()?.includes(query))
            .map((val) => (
              <li key={val?.value}>
                <label
                  htmlFor={val?.label}
                  className={`option ${
                    selectedValue === val?.value ||
                    selectedValue?.includes(val?.value)
                      ? 'active'
                      : ''
                  }`}
                >
                  {mode === 'multi-select' ? (
                    <input
                      type="checkbox"
                      onChange={getValue}
                      onClick={getValue}
                      value={val?.value}
                      defaultChecked={
                        selectedValue === val?.value ||
                        selectedValue?.includes(val?.value)
                      }
                      id={val?.label}
                    />
                  ) : (
                    <input
                      type="radio"
                      onChange={getValue}
                      onClick={getValue}
                      name="ddOptions"
                      value={val?.value}
                      defaultChecked={
                        selectedValue === val?.value ||
                        selectedValue?.includes(val?.value)
                      }
                      id={val?.label}
                    />
                  )}

                  <span className="label">
                    <span className="radio" />
                    <UserImage
                      removeTooltip
                      path={val?.logo}
                      shortName={getInitials(val?.label)}
                    />
                    <span className="name">{val?.label}</span>
                  </span>
                </label>
              </li>
            ))}
        </ul>
      )}
    </div>
  );
}
SelectableListItem.propTypes = {
  placeholder: PropTypes.string,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
  data: PropTypes.instanceOf(Array),
  handleInputChanged: PropTypes.func,
  label: PropTypes.string,
  mode: PropTypes.string,
  defaultArray: PropTypes.instanceOf(Array),
  behaviour: PropTypes.string,
  t: PropTypes.func,
};
SelectableListItem.defaultProps = {
  placeholder: '',
  defaultValue: '',
  onChange: () => {},
  data: [],
  handleInputChanged: () => {},
  label: '',
  mode: '',
  defaultArray: [],
  behaviour: '',
  t: () => [],
};
