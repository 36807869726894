import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import './advance-select.scss';
import { ShortDownIcon } from '../icons/icons';

export default function AdvanceSelectLabel({
  placeholder,
  onChange,
  data,
  handleInputChanged,
  defaultValue,
  label,
  errorMessage,
  message,
  required,
  disabled,
  className,
  maxLength,
  removeSelectedValue,
}) {
  const [t] = useTranslation('company');
  const [query, setQuery] = useState('');
  const [selectedValue, setSelectedValue] = useState(defaultValue?.value);
  const [selectedLabel, setSelectedLabel] = useState(defaultValue?.label);
  const [openDDL, setOpenDDl] = useState(false);
  const [getTop, setGetTop] = useState();
  const [getLeft, setGetLeft] = useState();
  const [getWidth, setGetWidth] = useState();
  const listHeight = useRef();
  const [searchValue, setSearchValue] = useState('');
  const ref = useRef(null);
  const boxHeight = useRef(null);
  useEffect(() => {
    setSearchValue('');
    setOpenDDl(false);
    setSelectedLabel(defaultValue?.label);
  }, [defaultValue?.label]);

  const getValue = (e) => {
    setSelectedValue(e.target.value);
    setSelectedLabel(e.target.nextSibling.innerText);
    onChange(e);
    setOpenDDl(false);
    setSearchValue('');
    document.body.style.overflow = 'auto';
  };
  const getDLPosition = () => {
    setTimeout(() => {
      if (
        boxHeight.current.getBoundingClientRect().top +
          boxHeight.current.getBoundingClientRect().height +
          listHeight.current.getBoundingClientRect().height >
        window.innerHeight
      ) {
        setGetTop(
          boxHeight.current.getBoundingClientRect().top -
            listHeight.current.getBoundingClientRect().height
        );
      } else {
        setGetTop(
          boxHeight.current.getBoundingClientRect().top +
            boxHeight.current.getBoundingClientRect().height
        );
      }
    }, 200);
  };
  const getPosition = () => {
    setOpenDDl(true);
    getDLPosition();
    setQuery('');
    setSearchValue('');
    document.body.style.overflow = 'hidden';
    setGetWidth(boxHeight.current.getBoundingClientRect().width);
    setGetLeft(boxHeight.current.getBoundingClientRect().left);
  };

  useEffect(() => {
    if (!defaultValue?.value && query && !openDDL) {
      setOpenDDl(true);
    }
  }, [query]);

  const handleChange = (e) => {
    // if (id === 'mode') {
    //   return;
    // }
    setQuery(e.target.value?.toLowerCase());
    handleInputChanged(e);
    setSelectedValue(null);
    setSelectedLabel('');
    getDLPosition();
    setSearchValue(e?.target?.value);
  };
  const closeDropdown = () => {
    setOpenDDl(false);
    document.body.style.overflow = 'auto';
  };
  const removeValue = (e) => {
    if (e.key === 'Backspace') {
      setSearchValue('');
      setSelectedLabel('');
      removeSelectedValue();
      setSelectedValue(null);
      setSelectedLabel(null);
    }
  };

  return (
    <div className={`advance-select ${openDDL ? 'active' : ''} ${className}`}>
      {label && (
        <label htmlFor={searchValue}>
          {label} {required && <span className="required">*</span>}
        </label>
      )}
      <div
        className={`textbox-area${disabled ? ' disabled' : ''}`}
        ref={boxHeight}
      >
        <input
          type="text"
          placeholder={placeholder}
          onChange={handleChange}
          ref={ref}
          value={selectedLabel !== '' ? selectedLabel : searchValue}
          disabled={disabled}
          id={searchValue}
          maxLength={maxLength}
          onClick={getPosition}
          onKeyUp={removeValue}
          className="test"
        />
        <span className="arrow">
          <ShortDownIcon />
        </span>
      </div>

      {openDDL && (
        <div
          className="select-mask"
          onClick={closeDropdown}
          onKeyDown={closeDropdown}
          role="button"
          tabIndex="0"
        >
          &nbsp;
        </div>
      )}

      <ul
        className={`advance-select-options ${openDDL ? 'show' : ''}`}
        style={{ width: getWidth, top: getTop, left: getLeft }}
        ref={listHeight}
      >
        {data?.filter((val) => val.label?.toLowerCase().includes(query))
          .length === 0 && (
          <li>
            <label className="option">
              <span className="label no-data">
                {t('companydetailscreen.abouttab.noresultplaceholder')}
              </span>
            </label>
          </li>
        )}
        {data
          ?.filter((val) => val.label?.toLowerCase().includes(query))
          .map((val) => (
            <li key={val.value}>
              <label
                className={`option ${
                  selectedValue === val.value ? 'active' : ''
                }`}
                htmlFor={val.value}
              >
                <input
                  type="radio"
                  onChange={getValue}
                  onClick={getValue}
                  name="ddOptions"
                  value={val.value}
                  disabled={val.disabled}
                  defaultChecked={selectedValue === val.value}
                  id={val.value}
                />
                <span className="label">{val.label}</span>
              </label>
            </li>
          ))}
      </ul>
      {message !== '' && <p className="message">{message}</p>}
      {errorMessage !== '' && <p className="error-message">{errorMessage}</p>}
    </div>
  );
}
AdvanceSelectLabel.propTypes = {
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
  data: PropTypes.instanceOf(Array),
  handleInputChanged: PropTypes.func,
  label: PropTypes.string,
  errorMessage: PropTypes.string,
  message: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  // forceValue: PropTypes.string,
  maxLength: PropTypes.number,
  // isReadOnly: PropTypes.bool,
  removeSelectedValue: PropTypes.func,
};
AdvanceSelectLabel.defaultProps = {
  required: false,
  placeholder: '',
  defaultValue: '',
  onChange: () => {},
  data: [],
  handleInputChanged: () => {},
  label: '',
  errorMessage: '',
  message: '',
  disabled: false,
  className: '',
  // forceValue: '',
  maxLength: 50,
  // isReadOnly: false,
  removeSelectedValue: () => {},
};
