import { gql } from '@apollo/client';

export const SUBSCRIBER_DETAILS = gql`
  mutation SubscriberDetails($input: subscriberDetailInput) {
    SubscriberDetails(input: $input) {
      success
      data
      message
    }
  }
`;

export const WEBHOOK_SUBSCRIBE = gql`
  mutation Subscribe($input: subscribeInput) {
    Subscribe(input: $input) {
      success
      data
      message
    }
  }
`;

export const TEST_ENDPOINT_URL = gql`
  mutation Mutation($input: validateWebhookInput) {
    ValidateEndURL(input: $input) {
      success
      data
      message
    }
  }
`;

export const EVENTS_LIST = gql`
  query ListEvents {
    ListEvents {
      success
      data
      message
      dropDown
    }
  }
`;

export const ACTIVATE_SUBSCRIPTION = gql`
  mutation Activate($input: webActivateInput) {
    Activate(input: $input) {
      success
      data
      message
    }
  }
`;
