import { gql } from '@apollo/client';

const QUERY_DASHBOARDWIDGET = gql`
  query dashboardWidgets($input: orderStatusWidgetInput) {
    dashboardWidgets(input: $input) {
      order
    }
  }
`;

export const QUERY_DASHBOARDDETAILWIDGET = gql`
  query dashboardDetailWidgets($input: detailsWidgetInput) {
    dashboardDetailWidgets(input: $input) {
      order
    }
  }
`;

export const QUERY_FETCHNONSUBSCRIBEDWIDGET = gql`
  query fetchNonSubscribed($input: fetchNonSubscribedInput) {
    fetchNonSubscribed(input: $input) {
      message
      success
      smallWidget
      largeWidget
    }
  }
`;

export const QUERY_ADDSUBSCRIPTION = gql`
  mutation addSubscription($input: addSubscriptionInput) {
    addSubscription(input: $input) {
      message
      success
    }
  }
`;

export const QUERY_REMOVESUBSCRIPTION = gql`
  mutation removeSubscription($input: addSubscriptionInput) {
    removeSubscription(input: $input) {
      message
      success
    }
  }
`;

export default QUERY_DASHBOARDWIDGET;
