import React from 'react';
import PropTypes from 'prop-types';
import './radioButton.scss';

export default function RadioButton({ id, label, subLabel, ...otherProps }) {
  return (
    <label className="custom-radio" htmlFor={id}>
      <input type="radio" id={id} {...otherProps} />
      <span>&nbsp;</span>
      <span>
        <ins>{label}</ins>
        {subLabel && <span className="sub-label">{subLabel}</span>}
      </span>
    </label>
  );
}
RadioButton.propTypes = {
  label: PropTypes.string,
  subLabel: PropTypes.string,

  id: PropTypes.string,
};
RadioButton.defaultProps = {
  label: '',
  subLabel: '',
  id: '',
};
