import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { useParams } from 'react-router-dom';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import './edit-contact.scss';
import DialogPanel from '../../../Components/dialog-panel/dialog-panel';
import Button from '../../../Components/button/button';
import DialogPanelBody from '../../../Components/dialog-panel/dialog-panel-body/dialog-panel-body';
import DialogPanelFooter from '../../../Components/dialog-panel/dialog-panel-footer/dialog-panel-footer';
import QUERY_LOCATIONWISE_STRUCTURE, {
  QUERY_ADDCONTACT,
  QUERY_EDIT_CONTACT,
} from '../../../graphQL/contact';
import { checkProfile } from '../../../Components/contact-item/contact-item';
// import {
//   RadioButton,
//   RadioButtonGroup,
// } from '../../../Components/radio-button/radio-button';
import { FETCH_ROLES, LIST_ROLE } from '../../../graphQL/rolesAndPermissions';
import { giveProfile } from '../../../Components/global-notification/notification-list';
import MultiSelect from '../../../Components/multi-select/multi-select';
import AdvanceMultiSelect from '../../../Components/advance-multi-select';
import TextBoxIcon from '../../../Components/text-box-icon/text-box-icon';
import {
  AddUserIcon,
  AddUserNewCircleIcon,
  EmailIcon,
  LocationOutlined,
  NameIcon,
} from '../../../Components/icons/icons';
import AdvanceSelectLabelIcon from '../../../Components/advance-select/advance-select-icon';

export default function EditContact({
  SubmitText,
  contactDetails,
  cancelClick,
  submitEdit,
  isEditing,
  validEmailDomain,
  t,
  roleId,
  userSub,
  roleName,
}) {
  const [t2] = useTranslation('systemsettings');
  const [t3] = useTranslation('toast');
  const { relation } = useParams();
  const [editCompanyDetails, setEditCompanyDetails] = useState({});
  const [locationTree, setLocationTree] = useState([]);
  const [defaultMultiSelectValue, setDefaultMultiSelectValue] = useState({
    parentName: [],
    childIds: [],
  });
  const [roles, setRoles] = useState([]);
  const [multiSelectValue, setMultiSelectValue] = useState();
  const [alreadySelectedRoles, setAlreadySelectedRoles] = useState([]);

  const [handleEditcontact] = useMutation(QUERY_EDIT_CONTACT);
  const [handleLocationTree] = useLazyQuery(QUERY_LOCATIONWISE_STRUCTURE);
  const [handleAddContact] = useMutation(QUERY_ADDCONTACT);
  const [defaultUaseType, setDefaultUserType] = useState({});

  useQuery(FETCH_ROLES, {
    variables: {
      input: {
        profileType: giveProfile(relation),
        page: 1,
        perPage: 10000,
      },
    },
    onCompleted: (data) => {
      setRoles(
        data?.FetchRoles?.data?.roleList?.map((role) => ({
          roleId: role?.roleId,
          roleName: role?.roleName,
        }))
      );
    },
  });

  if (roleId && userSub) {
    useQuery(LIST_ROLE, {
      variables: {
        input: {
          roleId,
          userSub,
          profileType: giveProfile(relation),
        },
      },
      onCompleted: (data) => {
        setAlreadySelectedRoles(data?.ListRole?.data);
      },
    });
  }

  useEffect(() => {
    if (roleId) {
      setMultiSelectValue(alreadySelectedRoles);
      setMultiSelectValue((prev) => {
        const updatedPrev = prev;
        return [...updatedPrev, ...[{ roleId, roleName }]];
      });
    }
  }, [alreadySelectedRoles]);

  useEffect(() => {
    if (!roleId) {
      setMultiSelectValue(contactDetails?.userRoles);
    }
  }, [contactDetails]);

  const searchRecordById = (data, searchIds) => {
    const ids = searchIds.map((itm) => Number(itm));
    const searchSet = new Set(ids);
    const foundRecords = [];

    const searchInChildren = (children) => {
      children.forEach((child) => {
        if (searchSet.has(child._id)) {
          foundRecords.push(child);
        }
        if (child.myChildren && child.myChildren.length > 0) {
          searchInChildren(child.myChildren);
        }
      });
    };

    data.forEach((record) => {
      if (searchSet.has(record._id)) {
        foundRecords.push(record);
      }
      if (record.myChildren && record.myChildren.length > 0) {
        searchInChildren(record.myChildren);
      }
    });

    return foundRecords;
  };

  const accessLocation = () => {
    handleLocationTree({
      variables: {
        input: {
          list: false,
        },
      },
      onCompleted: (data) => {
        const locations = data?.LocationTreeStructure?.data;
        setLocationTree(locations);
        const locationFound = searchRecordById(
          locations,
          contactDetails.parentEntityId
        );
        const locEntityName = [];
        let locParentId;
        const locChildren = [];
        locationFound.forEach((loc) => {
          locEntityName.push(loc.entityName);
          locParentId = Number(loc._id);
          locChildren.push(...loc.children, loc._id);
        });
        setDefaultMultiSelectValue({
          parentName: locEntityName,
          childIds: locChildren,
          parentId: locParentId,
        });
      },
    });
  };

  const handleAddUser = (submitpayload) => {
    handleAddContact({
      variables: {
        input: {
          ...submitpayload,
          profileType: giveProfile(relation),
        },
      },
      onCompleted: (data) => {
        toast.success(data.addUserIntoOrg.message);
        submitEdit();
        cancelClick();
      },
    });
  };

  const handleEditUser = (submitpayload) => {
    handleEditcontact({
      variables: {
        input: {
          ...submitpayload,
          profileType: giveProfile(relation),
        },
      },
      onCompleted: () => {
        toast.success(t3('editcontact.success.contactupdated'));
        submitEdit();
      },
    });
  };

  const serverCall = (submitpayload) => {
    if (isEditing) {
      handleEditUser(submitpayload);
    } else {
      handleAddUser(submitpayload);
    }
  };

  useEffect(() => {
    accessLocation();
    const tempPayload = {
      firstName: contactDetails?.firstName,
      lastName: contactDetails?.lastName,
      email: contactDetails?.email,
      userType: `${
        contactDetails?.userType
          ? `_${checkProfile(contactDetails?.userType.replace('_', ''))}`
          : ''
      }`,
      parentEntityId: contactDetails?.parentEntityId,
      assignRoles: roleId ? alreadySelectedRoles : contactDetails?.userRoles,
    };
    setEditCompanyDetails(tempPayload);
    return () => {
      setEditCompanyDetails({});
    };
  }, [contactDetails, alreadySelectedRoles]);

  const formik = useFormik({
    initialValues: editCompanyDetails,
    validationSchema: Yup.object({
      firstName: Yup.string().required(
        t(
          'companydetailscreen.detailstab.addeditcontactpopup.validation.firstname'
        )
      ),
      lastName: Yup.string().required(
        t(
          'companydetailscreen.detailstab.addeditcontactpopup.validation.lastname'
        )
      ),
      email: Yup.string()
        .required(
          t(
            'companydetailscreen.detailstab.addeditcontactpopup.validation.email'
          )
        )
        .email(
          t(
            'companydetailscreen.detailstab.addeditcontactpopup.validation.validemail'
          )
        ),
      userType: Yup.string().required(
        t(
          'companydetailscreen.detailstab.addeditcontactpopup.validation.usertype'
        )
      ),
      parentEntityId: Yup.array()
        .required(
          t(
            'companydetailscreen.detailstab.addeditcontactpopup.validation.parententityid'
          )
        )
        .min(
          1,
          t(
            'companydetailscreen.detailstab.addeditcontactpopup.validation.parententityid'
          )
        ),
      assignRoles: Yup.array()
        .required(
          t(
            'companydetailscreen.detailstab.addeditcontactpopup.validation.onerole'
          )
        )
        .min(
          1,
          t2(
            'systemsettings.roles&permission.activecontactspopup.actions.editbtn.assignroleempty'
          )
        ),
    }),

    enableReinitialize: true,
    onSubmit: (values) => {
      if (!validEmailDomain.includes(values?.email?.split('@')[1])) {
        toast.error(t3('editcontact.error.emailnotallowed'));
      } else {
        const payload = {
          firstName: values?.firstName,
          lastName: values?.lastName,
          userEmail: values?.email,
          userType: values?.userType,
          parentEntityId:
            !Array.isArray(values?.parentEntityId) ||
            values?.parentEntityId?.some((id) => typeof id !== 'string')
              ? values?.parentEntityId?.map(String)
              : values?.parentEntityId,
          assignRoles: multiSelectValue?.map(
            (value) => value?.roleId || value?._id
          ),
        };
        serverCall(payload);
        cancelClick();
        submitEdit();
      }
    },
  });

  const handleSelectedData = (data) => {
    if (data) {
      formik.setFieldValue('parentEntityId', [data.toString()]);
    } else {
      formik.setFieldValue('parentEntityId', []);
    }
  };

  const handleMultiSelectChange = (e) => {
    setMultiSelectValue(e);
  };
  useEffect(() => {
    if (formik.values.userType === '_user') {
      setDefaultUserType({ label: 'User', value: '_user' });
    } else if (formik.values.userType === '_admin') {
      setDefaultUserType({ label: 'Admin', value: '_admin' });
    }
  }, [formik.values.userType]);

  return (
    <DialogPanel
      title={`${
        isEditing
          ? t('companydetailscreen.detailstab.addeditcontactpopup.edit')
          : t('companydetailscreen.detailstab.addeditcontactpopup.add')
      } ${t('companydetailscreen.detailstab.addeditcontactpopup.contact')}`}
    >
      <form onSubmit={formik.handleSubmit} className="add-contact-container">
        <DialogPanelBody>
          <div className="contact-form-block">
            <TextBoxIcon
              label={t(
                'companydetailscreen.detailstab.addeditcontactpopup.firstname'
              )}
              placeholder={t(
                'companydetailscreen.detailstab.addeditcontactpopup.firstname'
              )}
              type="text"
              id="firstName"
              name="firstName"
              required
              {...formik.getFieldProps('firstName')}
              errorMessage={
                formik.touched.firstName && formik.errors.firstName
                  ? formik.errors.firstName
                  : null
              }
              disabled={!!roleId}
            >
              <NameIcon color="#A6A6A6" />
            </TextBoxIcon>
            <TextBoxIcon
              label={t(
                'companydetailscreen.detailstab.addeditcontactpopup.lastname'
              )}
              placeholder={t(
                'companydetailscreen.detailstab.addeditcontactpopup.lastname'
              )}
              type="text"
              id="lastName"
              name="lastName"
              required
              {...formik.getFieldProps('lastName')}
              errorMessage={
                formik.touched.lastName && formik.errors.lastName
                  ? formik.errors.lastName
                  : null
              }
              disabled={!!roleId}
            >
              <NameIcon color="#A6A6A6" />
            </TextBoxIcon>

            <TextBoxIcon
              label={t(
                'companydetailscreen.detailstab.addeditcontactpopup.email'
              )}
              placeholder={t(
                'companydetailscreen.detailstab.addeditcontactpopup.email'
              )}
              type="email"
              id="email"
              name="email"
              disabled={isEditing}
              required
              {...formik.getFieldProps('email')}
              errorMessage={
                formik.touched.email && formik.errors.email
                  ? formik?.errors?.email
                  : null
              }
            >
              <EmailIcon color="#A6A6A6" />
            </TextBoxIcon>
            {/* {formik.touched.email && formik.errors.email ? (
              <p className="profile-error-message">{formik.errors.email}</p>
            ) : null} */}
            <AdvanceSelectLabelIcon
              required
              label={t(
                'companydetailscreen.detailstab.addeditcontactpopup.contacttype'
              )}
              data={[
                // { label: '    ', value: '' },
                { label: 'User', value: '_user' },
                { label: 'Admin', value: '_admin' },
              ]}
              onChange={(e) => formik.setFieldValue('userType', e.target.value)}
              defaultValue={defaultUaseType}
              errorMessage={
                formik.touched.userType && formik.errors.userType
                  ? formik.errors.userType
                  : null
              }
              placeholder={t(
                'companydetailscreen.detailstab.addeditcontactpopup.contacttypePlaceholder'
              )}
            >
              <AddUserNewCircleIcon color="#A6A6A6" />
            </AdvanceSelectLabelIcon>

            <MultiSelect
              label={t(
                'companydetailscreen.detailstab.addeditcontactpopup.location'
              )}
              name="parentEntityId"
              required
              data={locationTree}
              placeholder={t(
                'companydetailscreen.detailstab.addeditcontactpopup.selectlocation'
              )}
              defaultOption={defaultMultiSelectValue}
              handleData={handleSelectedData}
              {...formik.getFieldProps('parentEntityId')}
              errorMessage={
                formik.touched.parentEntityId && formik.errors.parentEntityId
                  ? formik.errors.parentEntityId
                  : null
              }
              disabled={!!roleId}
              zIndex={4}
              // iconPadding="0.556vw 0"
              // width="1.806vw"
              mode="material"
            >
              <LocationOutlined color="#A6A6A6" />
            </MultiSelect>

            <AdvanceMultiSelect
              label={t2(
                'systemsettings.roles&permission.activecontactspopup.actions.editbtn.assignrole'
              )}
              name="assignRoles"
              placeholder={t2(
                'systemsettings.roles&permission.activecontactspopup.actions.editbtn.assignroleplaceholder'
              )}
              required
              value={multiSelectValue}
              options={roles}
              getKey={(item) => String(item?.roleId || item?._id)}
              renderOption={(item) => {
                return item?.roleName;
              }}
              renderValue={(value) => {
                if (!value) {
                  return '';
                }
                return value?.roleName;
              }}
              onChange={(e) => {
                handleMultiSelectChange(e);
                if (e) {
                  formik.setFieldValue('assignRoles', e);
                } else {
                  formik.setFieldValue('assignRoles', []);
                }
              }}
              message={t2(
                'systemsettings.roles&permission.activecontactspopup.actions.editbtn.assignroledescription'
              )}
              sliceVal={1}
              errorMessage={
                formik.touched.assignRoles && formik.errors.assignRoles
                  ? formik.errors.assignRoles
                  : null
              }
              mode="material"
            >
              <AddUserIcon color="#A6A6A6" />
            </AdvanceMultiSelect>
          </div>
        </DialogPanelBody>
        <DialogPanelFooter align="flex-end">
          <Button
            color="cancel"
            label={t(
              'companydetailscreen.detailstab.editcontactpopup.cancelbtn'
            )}
            onClick={cancelClick}
          />
          <Button type="submit" color="submit" label={SubmitText} />
        </DialogPanelFooter>
      </form>
    </DialogPanel>
  );
}

EditContact.propTypes = {
  SubmitText: PropTypes.string,
  cancelClick: PropTypes.func.isRequired,
  submitClick: PropTypes.func.isRequired,
  contactDetails: PropTypes.instanceOf(Object).isRequired,
  submitEdit: PropTypes.func,
  isEditing: PropTypes.bool,
  validEmailDomain: PropTypes.string,
  t: PropTypes.func,
  roleId: PropTypes.string,
  userSub: PropTypes.string,
  roleName: PropTypes.string,
};
EditContact.defaultProps = {
  SubmitText: '',
  submitEdit: () => {},
  isEditing: false,
  validEmailDomain: '',
  t: () => {},
  roleId: '',
  userSub: '',
  roleName: '',
};
