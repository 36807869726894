import React from 'react';
import PropTypes from 'prop-types';

function DashboardCardPanelHeader({
  smallTitle,
  title,
  subtitle,
  inline,
  largeSubTitle,
  ...props
}) {
  return (
    <div
      className={`dashboard-card-panel-header${
        subtitle !== '' && !inline ? ' d-block' : ''
      }`}
    >
      <h3
        className={`dashboard-card-panel-title${inline ? ' inline' : ''} ${
          smallTitle ? 'small' : ''
        }`}
      >
        {title}
        {subtitle !== '' && inline && !largeSubTitle && (
          <span className="dashboard-card-panel-subtitle">{subtitle}</span>
        )}
      </h3>
      {subtitle !== '' && !inline && largeSubTitle && (
        <h4
          className={`dashboard-card-panel-subtitle${
            largeSubTitle ? ' large' : ''
          }`}
        >
          {subtitle}
        </h4>
      )}
      <div className="dashboard-card-panel-action-area">{props.children}</div>
    </div>
  );
}

export default DashboardCardPanelHeader;
DashboardCardPanelHeader.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  inline: PropTypes.bool,
  children: PropTypes.node.isRequired,
  smallTitle: PropTypes.bool,
  largeSubTitle: PropTypes.bool,
};
DashboardCardPanelHeader.defaultProps = {
  title: '',
  subtitle: '',
  inline: false,
  smallTitle: false,
  largeSubTitle: false,
};
