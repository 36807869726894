import React from 'react';
import { Link } from 'react-router-dom';
import './mentions-messages.scss';
import ReactHtmlParser from 'react-html-parser';
import PropTypes from 'prop-types';
import Tabs from '../../tabs/custom-tab';
import getInitials from '../../../helpers/utils';
import UserImage from '../../user-image/user-image';

function MentionsMessages({ data, t }) {
  const hostUrl = `${window.location.protocol}//${window.location.host}`;
  return (
    <div className="mentions-messages-container">
      <Tabs className="notification-tab" disableURL>
        <div value="Chat" label={t('notifications.chat')}>
          <ul className="notification-list">
            {data?.map((ntf) => {
              return (
                <li class="unread">
                  <div className="notification-item">
                    <span className="time">{ntf?.diff}</span>
                    <div className="user-image">
                      <UserImage
                        path={ntf?.senderOrgDetails?.senderOrgLogo}
                        shortName={getInitials(
                          ntf?.senderOrgDetails?.senderOrgName
                        )}
                        size="medium"
                      />
                    </div>
                    <div className="details">
                      <p>
                        <span className="name">
                          {ntf?.senderOrgDetails?.senderOrgName}
                        </span>{' '}
                        <span className="notification-devider-line">|</span>{' '}
                        <span className="name">
                          {ntf?.type}{' '}
                          <Link to={`${hostUrl}/${ntf.link}`} className="link">
                            #{ntf?.relationNo}
                          </Link>
                        </span>
                      </p>
                      <p>
                        <span className="id mr-5">
                          @{ntf?.senderDetails?.firstName}{' '}
                          {ntf?.senderDetails?.lastName}
                        </span>
                        {ReactHtmlParser(ntf?.text)}
                      </p>
                    </div>
                    {ntf?.count > 1 && (
                      <div className="action align-center">
                        <span className="counter">+{ntf.count - 1}</span>
                      </div>
                    )}
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
        {false ? (
          <div label="Mentions" value="Mentions">
            <ul className="notification-list">
              <li>
                <div className="notification-item">
                  <span className="time">a day ago</span>
                  <div className="user-image">
                    <span className="image">MI</span>
                  </div>
                  <div className="details no-action">
                    <p>
                      <span className="name">Mexcool inc</span>
                    </p>
                    <p>
                      Order#{' '}
                      <Link to="/" className="link">
                        321-7678-23368767
                      </Link>
                      ,<span className="id mr-5">@Nick_Jonson</span> mentioned
                      you in a message{' '}
                      <span className="name">
                        “Please create a booking{' '}
                        <span className="id">@Jorge_Dan</span>”
                      </span>
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        ) : (
          <div />
        )}
      </Tabs>
    </div>
  );
}

MentionsMessages.propTypes = {
  data: PropTypes.instanceOf(Array),
  t: PropTypes.func,
};

MentionsMessages.defaultProps = {
  data: [],
  t: () => {},
};

export default MentionsMessages;
