import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import PropTypes, { number } from 'prop-types';
import './advance-popover.scss';

function AdvancePopover({
  placement,
  getEvent,
  closePopover,
  reference,
  closeOnclick,
  className,
  popoverOpen,
  maskColor,
  zIndex,
  ...props
}) {
  const [leftPosition, setLeftPosition] = useState();
  const [topPosition, setTopPosition] = useState();
  const [popoverHeight, setPopoverHeight] = useState(0);
  const [popoverWidth, setPopoverWidth] = useState(0);
  const popoverContainer = useRef(null);
  useEffect(() => {
    const timer = setTimeout(() => {
      setPopoverHeight(popoverContainer?.current?.clientHeight);
      setPopoverWidth(popoverContainer?.current?.clientWidth);

      if (
        typeof getEvent === 'object' &&
        getEvent !== null &&
        'getBoundingClientRect' in getEvent
      ) {
        const rectangle = getEvent?.getBoundingClientRect();
        switch (placement) {
          case 'self-bottom':
            setLeftPosition(
              rectangle.left + rectangle.width / 2 - popoverWidth / 2
            );
            setTopPosition(rectangle.y + rectangle.height);
            break;
          case 'right':
            setLeftPosition(
              rectangle.x + getEvent.clientWidth + rectangle.width
            );
            if (rectangle.y + popoverHeight > window.innerHeight - 100) {
              setTopPosition(rectangle.y - popoverHeight);
            } else {
              setTopPosition(rectangle.y + getEvent.clientHeight);
            }
            break;
          case 'top':
            setLeftPosition(rectangle.x);
            setTopPosition(rectangle.y - popoverHeight);
            break;
          case 'top-right':
            setLeftPosition(rectangle.x + rectangle.width);
            setTopPosition(rectangle.y - popoverHeight);
            break;
          case 'left':
            if (rectangle.y + popoverHeight > window.innerHeight - 100) {
              setTopPosition(rectangle.y - popoverHeight);
            } else {
              setTopPosition(rectangle.y + rectangle.height);
            }
            setLeftPosition(rectangle.x - popoverWidth + rectangle.width);

            break;
          case 'left-center':
            if (rectangle.y + popoverHeight > window.innerHeight - 100) {
              setTopPosition(rectangle.y - popoverHeight);
            } else {
              setTopPosition(rectangle.y + rectangle.height);
            }
            setLeftPosition(rectangle.x);

            break;
          case 'center':
            if (rectangle.y + popoverHeight > window.innerHeight - 100) {
              setTopPosition(rectangle.y - popoverHeight);
            } else {
              setTopPosition(rectangle.y + getEvent.clientHeight);
            }
            setLeftPosition(rectangle.x - getEvent.clientWidth / 2);
            break;
          case 'middle':
            setLeftPosition(
              rectangle.x + rectangle.width / 2 + popoverWidth / 2
            );
            setTopPosition(rectangle.y);
            break;
          case 'top-left':
            setLeftPosition(rectangle.x - popoverWidth + rectangle.width);
            setTopPosition(rectangle.y - popoverHeight);
            break;
          default:
            if (rectangle.x + popoverWidth > window.innerWidth) {
              setLeftPosition(rectangle.x - popoverWidth);
            } else {
              setLeftPosition(rectangle.x);
            }
            if (rectangle.y + popoverHeight > window.innerHeight - 100) {
              setTopPosition(rectangle.y + rectangle.height - popoverHeight);
            } else {
              setTopPosition(rectangle.y);
            }
        }
      } else {
        setLeftPosition();
        setTopPosition();
      }
    }, 10);
    return () => clearTimeout(timer);
  }, [getEvent]);

  return ReactDOM.createPortal(
    <>
      <button
        type="button"
        className={`${
          leftPosition && topPosition && reference === getEvent?.id
            ? 'popover-mask'
            : 'popover-mask hide'
        }`}
        style={{ background: maskColor, zIndex }}
        onClick={closePopover}
      />
      {closeOnclick ? (
        <div
          onClick={closePopover}
          onKeyDown={(e) => console.log(e)}
          role="button"
          tabIndex="0"
          className={`${
            leftPosition && topPosition && reference === getEvent?.id
              ? 'popover-container'
              : 'popover-container popover-hide'
          } ${className}`}
          ref={popoverContainer}
          style={{ left: leftPosition, top: topPosition, zIndex: zIndex + 1 }}
        >
          {props.children}
        </div>
      ) : (
        <div
          className={`${
            (leftPosition && topPosition && reference === getEvent?.id) ||
            popoverOpen
              ? 'popover-container'
              : 'popover-container popover-hide'
          } ${className}`}
          ref={popoverContainer}
          style={{ left: leftPosition, top: topPosition, zIndex: zIndex + 1 }}
        >
          {props.children}
        </div>
      )}
    </>,
    document.body
  );
}

export default AdvancePopover;
AdvancePopover.propTypes = {
  placement: PropTypes.string,
  getEvent: PropTypes.objectOf(number),
  closePopover: PropTypes.func.isRequired,
  reference: PropTypes.string,
  closeOnclick: PropTypes.bool,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  popoverOpen: PropTypes.bool,
  maskColor: PropTypes.string,
  zIndex: PropTypes.number,
};
AdvancePopover.defaultProps = {
  placement: '',
  reference: '',
  closeOnclick: false,
  getEvent: {},
  className: '',
  popoverOpen: false,
  maskColor: '',
  zIndex: 999999,
};
