import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/client';
import Confirmation from '../../../Components/confirmation/confirmation';
import { DELETE_ROLE } from '../../../graphQL/rolesAndPermissions';
import { giveProfile } from '../../../Components/global-notification/notification-list';

function DeleteRole({ roleId, deleteToggle, refetchRolesList, t }) {
  const [handleDeleteRole] = useMutation(DELETE_ROLE);
  const { relation } = useParams();

  const deleteRoleServerCall = async () => {
    const input = {
      roleId,
      profileType: giveProfile(relation),
    };
    await handleDeleteRole({
      variables: {
        input,
      },
      onCompleted: (data1) => {
        toast.success(data1?.DeleteRole?.message);
        refetchRolesList();
        deleteToggle();
      },
    });
  };
  return (
    <Confirmation
      title={t(
        'systemsettings.roles&permission.actionspopups.deletepopup.deleterole'
      )}
      description={t(
        'systemsettings.roles&permission.actionspopups.deletepopup.description'
      )}
      submitButtonText={t(
        'systemsettings.roles&permission.actionspopups.deletepopup.yes'
      )}
      cancelButtonText={t(
        'systemsettings.roles&permission.actionspopups.deletepopup.no'
      )}
      onSubmitClick={deleteRoleServerCall}
      onCancelClick={deleteToggle}
    />
  );
}

export default DeleteRole;

DeleteRole.propTypes = {
  deleteToggle: PropTypes.func,
  roleId: PropTypes.string,
  refetchRolesList: PropTypes.func,
  t: PropTypes.func,
};

DeleteRole.defaultProps = {
  deleteToggle: () => {},
  roleId: '',
  refetchRolesList: () => {},
  t: () => {},
};
