import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

import { Provider } from 'react-redux';

import { ApolloProvider } from '@apollo/client';

import * as Sentry from '@sentry/react';
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router-dom';
import i18next from 'i18next';
import { I18nextProvider } from 'react-i18next';
import store from './redux/store';
import App from './App';
import GlobalErrorBoundary from './pages/error-boundary/globalErrorBoundary';
import client1 from './service/gqlClient';
import setLanguage from './languageSetting';

i18next.init(setLanguage());
Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [/^https:\/\/omni-dev.quloi\.com/],
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
    new Sentry.Replay({ maskAllText: true, blockAllMedia: true }),
  ],
  environment:
    process.env.REACT_APP_STAGE === 'prod' ? 'production' : 'development',
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(document.getElementById('root'));

window.userGuiding.identify('88c0fc020b3dc1104dc60b1c01d956646ae9edb6aaf67fbc');
root.render(
  <GlobalErrorBoundary>
    <ApolloProvider client={client1}>
      <Provider store={store}>
        <I18nextProvider i18n={i18next}>
          <App />
        </I18nextProvider>
      </Provider>
    </ApolloProvider>
  </GlobalErrorBoundary>
);
