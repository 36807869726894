import store from '../redux/store';

export const getUserDetails = () => {
  return store?.getState()?.omniProfile?.profileDetail?.fetchProfileDetails
    ?.data;
};

export const checkPermission = (
  profile,
  module,
  actionType,
  permission = 'E'
) => {
  const userData = getUserDetails();
  const permData = userData?.actionable;
  let actType = {};
  permData?.[profile]?.[module]?.forEach((actionModule) => {
    if (actionModule?.actionName === actionType) {
      actType = actionModule;
    }
  });
  if (actType?.actions?.includes(permission)) {
    return true;
  }
  return false;
};
