import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
// import { toast } from 'react-toastify';
import { TextEditIcon, AcceptIcon, InfoIcon } from '../icons/icons';
import Tooltip from '../tooltip/tooltip';
import { getDigitsOnly, getNumberWithDot } from '../../helpers/utils';

function EditBox({
  setNewValue,
  defaultValue,
  min,
  max,
  id,

  pattern,
  isError,
  errorMessage,
  className,
  eanbleTextBox,
  errorLogic,
  name,
}) {
  const [textBoxEdit, setTextBoxEdit] = useState();
  const [acceptEdit, setAcceptEdit] = useState(false);
  const [errorBox, setErrorBox] = useState(isError);
  const [toolTip, setToolTip] = useState(errorMessage);

  const [value, setValue] = useState();

  const textBox = useRef(null);
  const editTextBox = () => {
    setTextBoxEdit(true);
    textBox.current.focus();
  };
  const unEdit = () => {
    setTextBoxEdit(false);
    setAcceptEdit(false);
  };
  useEffect(() => {
    setTextBoxEdit(isError);
  }, [isError]);

  const handleChange = (e) => {
    // const newValue = e.target.value;
    // if (newValue) {
    setNewValue(e);
    // }
    if (e.target.value === '') {
      setErrorBox(true);
    } else setErrorBox(false);
  };

  const [t] = useTranslation('manageorders');

  const handleError = (e) => {
    if (e.target.value > errorLogic && name === 'quantityToShip') {
      setToolTip(errorMessage);
      setErrorBox(true);
    } else if (
      name === 'quantityToShip' &&
      (Number(e.target.value) === 0 || e.target.value === '' || !e.target.value)
    ) {
      setErrorBox(true);
      setToolTip(
        `${t(
          'manageordersscreen.sendbookingdetails.tooltip.qtygreaterthanzero'
        )}`
      );
    } else if (
      name === 'grossWeight' &&
      (Number(e.target.value) === 0 || e.target.value === '' || !e.target.value)
    ) {
      setErrorBox(true);
      setToolTip(
        `${t('manageordersscreen.sendbookingdetails.tooltip.grossweight')}`
      );
    } else setErrorBox(false);
  };

  return (
    <div
      className={`propose-to-change-text-box-area ${
        textBoxEdit ? 'focus' : ''
      } ${acceptEdit ? 'active' : ''} ${errorBox ? 'error' : ''} ${className}`}
    >
      <input
        type="text"
        onChange={(e) => {
          if (e && e.target && e.target.value !== undefined) {
            setValue(e);
            setAcceptEdit(true);
          }
          handleError(e);
        }}
        onBlur={(e) => {
          handleChange(e);
          handleError(e);
        }}
        min={min}
        max={max}
        className="propose-change-text-box"
        readOnly={!textBoxEdit && !eanbleTextBox}
        defaultValue={defaultValue}
        // value={numbervalue}
        ref={textBox}
        id={id}
        pattern={pattern}
        onKeyDown={(e) => {
          if (id === 'Quantity' || id === 'quantityToShip') {
            getDigitsOnly(e);
          }
          if (id === 'grossWeight') {
            getNumberWithDot({ e, decimalLimit: 2 });
          } else {
            getNumberWithDot({ e });
          }
          if (e.key === 'Tab') {
            textBox.current.blur();
          }
        }}
        name={id}
      />

      {errorBox && (
        <button type="button" className="button-transparent">
          <Tooltip content={`${toolTip}`} direction="right">
            <InfoIcon width="0.694vw" color="#f00" />
          </Tooltip>
        </button>
      )}
      {!errorBox && !textBoxEdit && !acceptEdit && !eanbleTextBox && (
        <button
          type="button"
          className="button-transparent"
          onClick={editTextBox}
        >
          <TextEditIcon />
        </button>
      )}
      {!errorBox && acceptEdit && !eanbleTextBox && (
        <button
          type="button"
          className="button-transparent"
          onClick={() => {
            // if (value) {
            setNewValue(value);
            unEdit();
            // }
          }}
        >
          <AcceptIcon />
        </button>
      )}
    </div>
  );
}

export default EditBox;
EditBox.propTypes = {
  setNewValue: PropTypes.func,
  defaultValue: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  id: PropTypes.string,
  pattern: PropTypes.string,
  isError: PropTypes.bool,
  errorMessage: PropTypes.string,
  className: PropTypes.string,
  eanbleTextBox: PropTypes.bool,
  errorLogic: PropTypes.number,
  name: PropTypes.string,
};

EditBox.defaultProps = {
  setNewValue: () => {},
  defaultValue: 0,
  min: 0,
  max: 99999999999,
  id: '',
  pattern: '',
  isError: false,
  errorMessage: '',
  className: '',
  eanbleTextBox: false,
  errorLogic: 0,
  name: '',
};
