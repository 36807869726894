import React, { useState } from 'react';
import PropTypes from 'prop-types';
import '../message.scss';
import './imagePreview.scss';
import Dialog from '../../dialog/dialog';

export default function Images({ imagePath }) {
  const [fullSize, setFullSIze] = useState('');

  const getImage = () => {
    setFullSIze(imagePath);
  };
  const closeLargeImage = () => {
    setFullSIze(null);
  };
  return (
    <>
      <div className="chat-options-block">
        <button type="button" onClick={getImage}>
          <img src={imagePath} height="6.944vw" width="6.944vw" alt="" />
        </button>
      </div>
      <Dialog
        isShowing={fullSize}
        hide={closeLargeImage}
        placement="center"
        noSpacing
      >
        <div className="image-preview-container">
          <div className="image-row">
            <div className="image-item">
              <img src={fullSize} alt="" />
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
}
Images.propTypes = {
  imagePath: PropTypes.string.isRequired,
};
