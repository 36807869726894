import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import './contact-permissions.scss';
import PermissionsTab from '../permissions-tab';
import CREATE_ROLE, {
  FETCH_PERMISSIONS,
} from '../../../graphQL/rolesAndPermissions';
import Button from '../../../Components/button/button';
import DialogPanel from '../../../Components/dialog-panel/dialog-panel';
import DialogPanelBody from '../../../Components/dialog-panel/dialog-panel-body/dialog-panel-body';
import DialogPanelFooter from '../../../Components/dialog-panel/dialog-panel-footer/dialog-panel-footer';
import { giveProfile } from '../../../Components/global-notification/notification-list';
import GlobalLoader from '../../../Components/global-loader/global-loader';
import { checkPermission } from '../../../helpers/permissionHelper';

function ContactPermissions({
  roleName,
  roleId,
  refetchRolesList,
  togglehandler,
  t,
}) {
  const { relation } = useParams();
  const [permissionList, setPermissionList] = useState([]);
  const [handleUpdateRole] = useMutation(CREATE_ROLE);

  const { loading } = useQuery(FETCH_PERMISSIONS, {
    variables: {
      input: {
        roleId,
        profileType: giveProfile(relation),
      },
    },
    onCompleted: (data) => {
      const permList = data?.FetchPermissions?.data?.map(
        ({ moduleName, modulePermissions, moduleTranslated }) => ({
          moduleName,
          modulePermissions,
          moduleTranslated,
        })
      );
      setPermissionList(permList);
    },
  });

  const updateRoleServerCall = async () => {
    const input = {
      roleName,
      roleId,
      profileType: giveProfile(relation),
      modules: permissionList,
    };
    await handleUpdateRole({
      variables: {
        input,
      },
      onCompleted: (data) => {
        toast.success(data?.CreateRole?.message);
        refetchRolesList();
        togglehandler();
      },
    });
  };

  const isEditPermission = checkPermission(
    giveProfile(relation),
    'Configuration',
    'Manage Roles'
  );

  return (
    <DialogPanel
      title={t('systemsettings.roles&permission.permissionspopup.title')}
    >
      <DialogPanelBody>
        <div className="contact-permissions-container">
          {!loading && permissionList?.length ? (
            <>
              <h3 className="m-0 mb-20 sub-title-color fs-20 fw-600">
                {roleName}
              </h3>
              <PermissionsTab
                permissionList={permissionList}
                setPermissionList={setPermissionList}
                t={t}
                viewOnly={!isEditPermission}
              />
            </>
          ) : (
            <GlobalLoader newLoad={true} height="6.944vw" width="6.944vw" />
          )}
        </div>
      </DialogPanelBody>
      <DialogPanelFooter>
        <Button
          label={t('systemsettings.roles&permission.permissionspopup.cancel')}
          styles="cancel"
          onClick={togglehandler}
        />
        {isEditPermission && (
          <Button
            label={t('systemsettings.roles&permission.permissionspopup.update')}
            color="secondary"
            onClick={updateRoleServerCall}
          />
        )}
      </DialogPanelFooter>
    </DialogPanel>
  );
}

export default ContactPermissions;

ContactPermissions.propTypes = {
  roleName: PropTypes.string,
  roleId: PropTypes.string,
  togglehandler: PropTypes.func,
  refetchRolesList: PropTypes.func,
  t: PropTypes.func,
};

ContactPermissions.defaultProps = {
  roleName: '',
  roleId: '',
  togglehandler: () => {},
  refetchRolesList: () => {},
  t: () => {},
};
