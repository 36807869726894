import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
// import axios from 'axios';
// import { toast } from 'react-toastify';
import Googlemap from '../maps/maps';
import AutoSearch from '../auto-search/auto-search';
import ShippingProgressNoData from '../shipping-progress/shipping-progress-no-data/shipping-progress-no-data';
import {
  SEARCH_QUERY_PORT_TO_PORT,
  SEARCH_QUERY_AIRPORT_TO_AIRPORT,
  DOOR_ADDRESS,
  GET_GEOCODE,
} from '../../graphQL/addAddress';
import BaiduMap from '../maps/baidumap';

function AddAddress({
  Mode,
  TypeOfMove,
  origin,
  destination,
  setOrigin,
  setDestination,
  originOptions,
  setOrginOptions,
  destinationOptions,
  setDestinationOptions,
  originGeoCode,
  setOriginGeoCode,
  destinationGeoCode,
  setDestinationGeoCode,
  setOriginDoorAddress,
  setDestinationDoorAddress,
  setOriginCountry,
  setDestinationCountry,
  disable,
  disableOrigin,
  disableDestination,
  originLabel,
  destinationLabel,
}) {
  const [t] = useTranslation('manageorders');
  const [loading, setLoading] = useState(false);
  const [searchPort] = useLazyQuery(
    Mode.value === 'Ocean'
      ? SEARCH_QUERY_PORT_TO_PORT
      : SEARCH_QUERY_AIRPORT_TO_AIRPORT
  );
  const [searchDoor] = useLazyQuery(DOOR_ADDRESS);
  const [getDoorGeoCode] = useLazyQuery(GET_GEOCODE);

  const getGeoCode = (SearchParameter, type) => {
    getDoorGeoCode({
      variables: { searchParameter: SearchParameter },
      onCompleted: (data) => {
        const geoCode = `${data.FetchGeoCode?.data[0].geometry.location.lat},${data.FetchGeoCode?.data[0].geometry.location.lng}`;

        if (type === 'origin') {
          setOriginGeoCode([{ GeoCoordinate: geoCode }]);
          setOriginDoorAddress(data.FetchGeoCode?.data[0].address_components);
        }
        if (type === 'destination') {
          setDestinationGeoCode([{ GeoCoordinate: geoCode }]);
          setDestinationDoorAddress(
            data.FetchGeoCode?.data[0].address_components
          );
        }
      },
    });
  };
  const serverCall = (type, searchTerm) => {
    setLoading(true);
    if (type === 'origin') {
      setOrginOptions([]);
    } else {
      setDestinationOptions([]);
    }

    if (
      (type === 'origin' &&
        (TypeOfMove.value === 'door to door' ||
          TypeOfMove.value === 'door to port')) ||
      (type === 'destination' &&
        (TypeOfMove.value === 'door to door' ||
          TypeOfMove.value === 'port to door'))
    ) {
      searchDoor({
        variables: {
          searchParameter: searchTerm,
        },
        onCompleted: (data) => {
          const option =
            data &&
            data.GAutoSuggest.data.map((item) => {
              return {
                label: item.description,
                value: item.description,
              };
            });
          if (type === 'origin') {
            setOrginOptions(option);
          } else {
            setDestinationOptions(option);
          }
          setLoading(false);
        },
        onError: () => {
          // toast.error(err.message);
          setLoading(false);
        },
      });
    } else {
      searchPort({
        variables: {
          input: { SearchString: searchTerm },
        },
        onCompleted: (data) => {
          const option =
            Mode.value === 'Ocean'
              ? data.searchOceanPortDetails.data.map((dataMap) => {
                  return {
                    label: dataMap.OceanPortLongName,
                    value: dataMap.UNECECode,
                    GeoCoordinate: dataMap.GeoCoordinate,
                    CountryName: dataMap.CountryName,
                  };
                })
              : data.searchAirPortDetails.data.map((dataMap) => {
                  return {
                    label: dataMap.AirportLongName,
                    value: dataMap.AirportCodeIATA,
                    GeoCoordinate: dataMap.GeoCoordinate,
                    CountryName: dataMap.CountryName,
                  };
                });
          if (type === 'origin') {
            setOrginOptions(option);
          } else {
            setDestinationOptions(option);
          }
          setLoading(false);
        },
        onError: () => {
          // toast.error(err.message);
          setLoading(false);
        },
      });
    }
  };
  const handleChange = (e, type) => {
    serverCall(type, e.target.value);
  };
  const [state, setState] = useState({
    countryName: '',
  });
  // const getGeoInfo = () => {
  //   try {
  //     axios.get('https://ipapi.co/json/').then((response) => {
  //       const { data } = response;
  //       setState({
  //         ...state,
  //         countryName: data.country_name,
  //         countryCode: data.country_calling_code,
  //       });
  //     });
  //   } catch (error) {
  //     console.log(error, 'error');
  //   }

  // };

  useEffect(() => {
    setState({ countryName: '' });
    // getGeoInfo();
  }, []);
  useEffect(() => {
    setOrginOptions([]);
  }, []);
  return (
    <>
      {!disableOrigin && (
        <>
          <div className="select-address-container">
            <AutoSearch
              required
              label={originLabel}
              options={originOptions}
              disabled={disable}
              handleInputChanged={(e) => handleChange(e, 'origin')}
              removeSearchValue={() => {
                setOrigin('');
                setOrginOptions([]);
                setOriginGeoCode('');
              }}
              placeholder={
                TypeOfMove.value === 'door to door' ||
                TypeOfMove.value === 'door to port'
                  ? t(
                      'manageordersscreen.createbookingmenu.addforwarder.pickupvendor.pickupaddressplc'
                    )
                  : t(
                      'manageordersscreen.createbookingmenu.shipmentdetails.originportplaceholder'
                    )
              }
              onChange={(e) => {
                if (
                  (TypeOfMove.value === 'door to door' ||
                    TypeOfMove.value === 'door to port') &&
                  e?.target?.value !== ''
                ) {
                  getGeoCode(e.target.value, 'origin');
                } else {
                  setOriginGeoCode(
                    originOptions.filter(
                      (item) => item.value === e.target.value
                    )
                  );
                }
                if (originOptions && originOptions.length > 0) {
                  setOrigin(
                    originOptions.filter(
                      (item) => item.value === e.target.value
                    )[0]?.label
                  );
                  setOriginCountry(
                    originOptions.filter(
                      (item) => item.value === e.target.value
                    )[0]?.CountryName
                  );
                }
              }}
              defaultValue={{ value: origin }}
              loading={loading}
            />
          </div>

          <div className="map-box">
            {originGeoCode[0]?.GeoCoordinate !== 'NULL' ? (
              <div>
                {state.countryName !== 'China' ? (
                  <Googlemap
                    height="5.556vw"
                    width="100%"
                    latitude={
                      originGeoCode[0]?.GeoCoordinate
                        ? originGeoCode &&
                          parseFloat(
                            originGeoCode[0]?.GeoCoordinate?.toString().split(
                              ','
                            )[0]
                          )
                        : 18.0
                    }
                    long={
                      originGeoCode[0]?.GeoCoordinate
                        ? originGeoCode &&
                          parseFloat(
                            originGeoCode[0]?.GeoCoordinate?.toString().split(
                              ','
                            )[1]
                          )
                        : 72.556
                    }
                    className="address-map"
                  />
                ) : (
                  <BaiduMap
                    mapHeight="8.333vw"
                    latitude={
                      originGeoCode[0]?.GeoCoordinate
                        ? originGeoCode &&
                          parseFloat(
                            originGeoCode[0]?.GeoCoordinate?.toString().split(
                              ','
                            )[0]
                          )
                        : 18.0
                    }
                    longitude={
                      originGeoCode[0]?.GeoCoordinate
                        ? originGeoCode &&
                          parseFloat(
                            originGeoCode[0]?.GeoCoordinate?.toString().split(
                              ','
                            )[1]
                          )
                        : 72.556
                    }
                  />
                )}
              </div>
            ) : (
              <ShippingProgressNoData
                message={
                  originGeoCode[0]?.GeoCoordinate !== 'NULL'
                    ? t(
                        'manageordersscreen.createbookingmenu.shipmentdetails.originMapPlaceholder'
                      )
                    : t(
                        'manageordersscreen.createbookingmenu.shipmentdetails.originNoDataText'
                      )
                }
                size="2.083vw"
              />
            )}
          </div>
        </>
      )}
      {!disableDestination && (
        <>
          <div className="select-address-container">
            <AutoSearch
              required
              label={destinationLabel}
              options={destinationOptions}
              handleInputChanged={(e) => handleChange(e, 'destination')}
              removeSearchValue={() => {
                setDestination('');
                setDestinationOptions([]);
                setDestinationGeoCode('');
              }}
              disabled={disable}
              placeholder={
                TypeOfMove.value === 'door to door' ||
                TypeOfMove.value === 'port to door'
                  ? t(
                      'manageordersscreen.createbookingmenu.addforwarder.pickupvendor.pickupaddressplc'
                    )
                  : t(
                      'manageordersscreen.createbookingmenu.shipmentdetails.destinationportplaceholder'
                    )
              }
              onChange={(e) => {
                if (
                  (TypeOfMove.value === 'door to door' ||
                    TypeOfMove.value === 'port to door') &&
                  e?.target?.value !== ''
                ) {
                  getGeoCode(e.target.value, 'destination');
                } else {
                  setDestinationGeoCode(
                    destinationOptions.filter(
                      (item) => item.value === e.target.value
                    )
                  );
                }
                if (destinationOptions && destinationOptions.length > 0) {
                  setDestination(
                    destinationOptions.filter(
                      (item) => item.value === e.target.value
                    )[0]?.label
                  );
                  setDestinationCountry(
                    destinationOptions.filter(
                      (item) => item.value === e.target.value
                    )[0]?.CountryName
                  );
                }
              }}
              defaultValue={{ value: destination }}
              loading={loading}
            />
          </div>
          <div className="map-box">
            {destinationGeoCode[0]?.GeoCoordinate !== 'NULL' ? (
              <div>
                {state.countryName !== 'China' ? (
                  <Googlemap
                    height="5.556vw"
                    width="100%"
                    latitude={
                      destinationGeoCode[0]?.GeoCoordinate
                        ? destinationGeoCode &&
                          parseFloat(
                            destinationGeoCode[0].GeoCoordinate.toString().split(
                              ','
                            )[0]
                          )
                        : 18.0
                    }
                    long={
                      destinationGeoCode[0]?.GeoCoordinate
                        ? destinationGeoCode &&
                          parseFloat(
                            destinationGeoCode[0].GeoCoordinate.toString().split(
                              ','
                            )[1]
                          )
                        : 72.55
                    }
                    className="address-map"
                  />
                ) : (
                  <BaiduMap
                    mapHeight="8.333vw"
                    latitude={
                      destinationGeoCode[0]?.GeoCoordinate
                        ? destinationGeoCode &&
                          parseFloat(
                            destinationGeoCode[0].GeoCoordinate.toString().split(
                              ','
                            )[0]
                          )
                        : 18.0
                    }
                    longitude={
                      destinationGeoCode[0]?.GeoCoordinate
                        ? destinationGeoCode &&
                          parseFloat(
                            destinationGeoCode[0].GeoCoordinate.toString().split(
                              ','
                            )[1]
                          )
                        : 72.55
                    }
                  />
                )}
              </div>
            ) : (
              <ShippingProgressNoData
                message={
                  destinationGeoCode[0]?.GeoCoordinate !== 'NULL'
                    ? t(
                        'manageordersscreen.createbookingmenu.shipmentdetails.destinationMapPlaceholder'
                      )
                    : t(
                        'manageordersscreen.createbookingmenu.shipmentdetails.destinationNoDataText'
                      )
                }
                size="2.083vw"
              />
            )}
          </div>
        </>
      )}
    </>
  );
}

AddAddress.propTypes = {
  Mode: PropTypes.instanceOf(Object),
  TypeOfMove: PropTypes.instanceOf(Object),
  setOrigin: PropTypes.func,
  setDestination: PropTypes.func,
  origin: PropTypes.string,
  destination: PropTypes.string,
  originOptions: PropTypes.instanceOf(Array),
  setOrginOptions: PropTypes.func,
  destinationOptions: PropTypes.instanceOf(Array),
  setDestinationOptions: PropTypes.func,
  originGeoCode: PropTypes.string,
  setOriginGeoCode: PropTypes.func,
  destinationGeoCode: PropTypes.string,
  setDestinationGeoCode: PropTypes.func,
  setOriginDoorAddress: PropTypes.instanceOf(Array),
  setDestinationDoorAddress: PropTypes.instanceOf(Array),
  setOriginCountry: PropTypes.func,
  setDestinationCountry: PropTypes.func,
  disable: PropTypes.bool,
  disableOrigin: PropTypes.bool,
  disableDestination: PropTypes.bool,
  originLabel: PropTypes.string,
  destinationLabel: PropTypes.string,
};
AddAddress.defaultProps = {
  Mode: {},
  TypeOfMove: {},
  setOrigin: () => {},
  setDestination: () => {},
  origin: '',
  destination: '',
  originOptions: [],
  setOrginOptions: () => {},
  destinationOptions: [],
  setDestinationOptions: () => {},
  originGeoCode: '',
  setOriginGeoCode: () => {},
  destinationGeoCode: '',
  setDestinationGeoCode: () => {},
  setOriginDoorAddress: () => {},
  setDestinationDoorAddress: () => {},
  setOriginCountry: () => {},
  setDestinationCountry: () => {},
  disable: false,
  disableOrigin: false,
  disableDestination: false,
  originLabel: '',
  destinationLabel: '',
};
export default AddAddress;
