import React from 'react';
import { Bar, Doughnut, Line, Pie } from 'react-chartjs-2';
import { registerables, Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import PropTypes from 'prop-types';

/**
 * @typedef {'multiple' | 'single'} BarTypes - Size choices for the Demo component.
 */

/**
 * Component to demonstrate different sizes, appearances, and popup options.
 * @param {Object} props - Component props.
 * @param {BarTypes} props.barType - Size of the component ('Small' or 'Large').
 * @returns {JSX.Element} - Rendered component.
 */

Chart.register(...registerables);
function ChartJsChart({
  chartData,
  chartType,
  height,
  width,
  type,
  chartAspectRatio,
  barType,
}) {
  const barChartMultipleOption = {
    maintainAspectRatio: true,
    responsive: true,
    barThickness: 10,
    maxBarThickness: 10,
    aspectRatio: chartAspectRatio,
    plugins: {
      legend: {
        position: 'top',
        align: 'end',
        maxWidth: 500,
        display: true,
        labels: {
          usePointStyle: true,
          font: {
            size: 11,
          },
          boxWidth: 10,
          useBorderRadius: true,
          borderRadius: 10,
          padding: 25,
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
          // drawBorder: false,
        },
        // ticks: {
        //   display: false,
        // },
        border: {
          display: false,
        },
      },
      y: {
        grid: {
          // display: false,
          drawBorder: false,
          borderDash: [6, 10],
        },
        // ticks: {
        //   display: false,
        // },
        border: {
          display: false,
        },
      },
    },
  };
  const barChartSingleOption = {
    maintainAspectRatio: true,
    responsive: true,
    barThickness: 34,
    maxBarThickness: 34,
    aspectRatio: chartAspectRatio,
    legend: {
      display: false,
      position: 'bottom',
      align: 'start',
    },
    plugins: {
      legend: {
        display: false,
        labels: {
          usePointStyle: true,
          font: {
            size: 10,
          },
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        suggestedMin: 0,
        // suggestedMax: 100,
        ticks: {
          stepSize: 10,
        },
        grid: {
          borderDash: [6, 10],
        },
      },
    },
    elements: {
      line: {
        tension: 0.5,
      },
    },
  };

  return (
    <div className="chart" style={{ height }}>
      {(chartType === 'barChart' || type === 1) && (
        <Bar
          data={chartData}
          height={height}
          options={
            barType === 'multiple'
              ? barChartMultipleOption
              : barChartSingleOption
          }
        />
      )}
      {(chartType === 'pieChart' || type === 2) && (
        <Pie
          data={chartData}
          height={height}
          width={width}
          plugins={[ChartDataLabels]}
          options={{
            maintainAspectRatio: false,
            responsive: true,
            aspectRatio: chartAspectRatio,
            plugins: {
              tooltip: {
                enabled: false,
              },
              legend: {
                maxWidth: 500,
                position: 'right',
                labels: {
                  usePointStyle: true,
                  font: {
                    size: 14,
                  },
                  boxWidth: 20,
                  padding: 25,
                },
              },
              datalabels: {
                color: 'white',
                font: {
                  weight: 'bold',
                  size: 12,
                },
                formatter: (value) => {
                  return `${value > 4 ? value : ''}${value > 4 ? '%' : ''}`;
                },
                // formatter: Math.round(value),
                // formatter: function (value) {
                //   return Math.round(value) + '%';
                // },
              },
            },
          }}
        />
      )}
      {(chartType === 'barHorChart' || type === 3) && (
        <Bar
          data={chartData}
          height={height}
          id="barHor"
          plugins={[ChartDataLabels]}
          options={{
            aspectRatio: chartAspectRatio,
            responsive: true,
            legend: {
              display: false,
              maxWidth: 500,
              position: 'top',
              labels: {
                usePointStyle: true,
                font: {
                  size: 14,
                },
                boxWidth: 20,
              },
            },
            barThickness: 20,
            maxBarThickness: 20,
            maintainAspectRatio: false,
            scaleLineColor: 'rgba(0,0,0,0)',
            indexAxis: 'y',
            scales: {
              x: {
                suggestedMin: 0,
                suggestedMax: 100,
                ticks: {
                  stepSize: 50,
                },
                grid: {
                  display: false,
                },
              },
              y: {
                suggestedMin: 0,
                suggestedMax: 100,

                grid: {
                  display: false,
                },
              },
            },

            plugins: {
              tooltip: {
                enabled: false,
              },
              legend: {
                display: false,
              },
              datalabels: {
                display: true,
                color: ({ dataIndex, dataset }) =>
                  dataset.data[dataIndex] > 90 ? '#ffffff' : '#615E83',
                formatter: (value) => {
                  return `${value}%`;
                },
                anchor: 'end',
                offset: 2,
                align: ({ dataIndex, dataset }) =>
                  dataset.data[dataIndex] > 90 ? 'start' : 'end',
              },
            },
          }}
        />
      )}
      {(chartType === 'stackedBarChart' || type === 4) && (
        <Bar
          data={chartData}
          height={height}
          id="barHor"
          options={{
            aspectRatio: chartAspectRatio,
            responsive: true,
            barThickness: 20,
            maxBarThickness: 20,
            maintainAspectRatio: false,
            scaleLineColor: 'rgba(0,0,0,0)',
            indexAxis: 'y',
            scales: {
              x: {
                stacked: true,
                grid: {
                  display: false,
                },
              },
              y: {
                stacked: true,
                grid: {
                  display: false,
                },
              },
            },

            plugins: {
              tooltip: {
                enabled: false,
              },
              legend: {
                display: false,
              },
            },
          }}
        />
      )}
      {(chartType === 'doughnut' || type === 5) && (
        <div style={{ height, width }}>
          <Doughnut
            data={chartData}
            height={height}
            id="doughut"
            plugins={[ChartDataLabels]}
            options={{
              maintainAspectRatio: false,
              responsive: true,
              cutout: chartData?.datasets?.length > 1 ? '30%' : '65%',
              aspectRatio: chartAspectRatio,
              plugins: {
                tooltip: {
                  enabled: false,
                },
                legend: {
                  maxWidth: 500,
                  display: true,
                  position: 'right',
                  labels: {
                    usePointStyle: true,
                    font: {
                      size: 14,
                    },
                    boxWidth: 20,
                    useBorderRadius: true,
                    borderRadius: 20,
                    padding: 25,
                  },
                },
                datalabels: {
                  color: 'white',
                  font: {
                    weight: 'bold',
                    size: 12,
                  },
                  formatter: (value) => {
                    return `${value > 4 ? value : ''}${value > 4 ? '%' : ''}`;
                  },
                  // formatter: Math.round(value),
                  // formatter: function (value) {
                  //   return Math.round(value) + '%';
                  // },
                },
                // title: {
                //   display: true,
                //   text: 'Custom Chart Title',
                //   position: 'top',
                //   padding: {
                //     top: 10,
                //     bottom: 30,
                //   },
                // },
              },
            }}
          />
        </div>
      )}
      {(chartType === 'line' || type === 6) && (
        <div style={{ height, width }}>
          <Line
            data={chartData}
            height={height}
            id="doughut"
            options={{
              maintainAspectRatio: false,
              responsive: true,
              aspectRatio: chartAspectRatio,
              plugins: {
                tooltip: {
                  enabled: false,
                },
                legend: {
                  maxWidth: 500,
                  display: false,
                  position: 'top',
                  labels: {
                    usePointStyle: true,
                    font: {
                      size: 14,
                    },
                    boxWidth: 20,
                    useBorderRadius: true,
                    borderRadius: 20,
                  },
                },
              },
              scales: {
                y: {
                  suggestedMin: 0,
                  // suggestedMax: 100,
                  ticks: {
                    stepSize: 10,
                  },
                },
              },
              elements: {
                line: {
                  tension: 0.5,
                },
              },
            }}
          />
        </div>
      )}
    </div>
  );
}

ChartJsChart.defaultProps = {
  chartType: '',
  chartData: {},
  height: '',
  width: '',
  type: 0,
  chartAspectRatio: 1,
  barType: PropTypes.string,
};
ChartJsChart.propTypes = {
  chartType: PropTypes.string,
  chartData: PropTypes.instanceOf(Object),
  height: PropTypes.string,
  width: PropTypes.string,
  type: PropTypes.number,
  chartAspectRatio: PropTypes.number,
  barType: 'single',
};
export default ChartJsChart;
