import { useDispatch } from 'react-redux';
import http from './APIUtil';
import { getIDToken } from './authService';
import URLConst from './URLConst';
import { storeNotificationsInStore } from '../redux/actions';
import createOrgWiseNotificationMap from '../helpers/notificationHelper';

const chatHost = `${URLConst.host}/v1/`;

const chatHeader = {
  Authorization: `Bearer ${getIDToken()}`,
};

export const getMessages = async (channel, pageIndex) => {
  const resp = await http.get(`${chatHost}messages`, {
    params: {
      channelId: channel,
      page: pageIndex,
    },
    headers: chatHeader,
  });
  return resp;
};

export const createNewMessage = async (payload) => {
  const resp = await http.post(`${chatHost}messages`, payload, {
    headers: chatHeader,
  });
  return resp;
};

export const getAllMediaOfChannel = async (channelId) => {
  const resp = await http.get(`${chatHost}files/${channelId}`, {
    headers: chatHeader,
  });
  return resp;
};

export const getChannelByRelationId = async (id, type) => {
  const resp = await http.get(
    `${chatHost}channels/getByRelationId/${type}/${id}`,
    {
      headers: chatHeader,
    }
  );
  return resp;
};

export const createChannel = async (payload) => {
  const resp = await http.post(
    `${chatHost}channels/createChannelById`,
    payload,
    {
      headers: chatHeader,
    }
  );
  return resp;
};

export const addUserInChat = async (userSub, channelId) => {
  const resp = await http.put(
    `${chatHost}channels/addParticipant/${channelId}`,
    {
      participant: userSub,
    },
    {
      headers: chatHeader,
    }
  );
  return resp;
};

export const removeUserFromChat = async (userSub, channelId) => {
  const resp = await http.put(
    `${chatHost}channels/removeParticipant/${channelId}`,
    {
      participant: userSub,
    },
    {
      headers: chatHeader,
    }
  );
  return resp;
};

// NOTIFICATIONS

export const getUserUnreadNotifications = async () => {
  const resp = await http.get(`${chatHost}notifications`, {
    headers: chatHeader,
  });
  return resp;
};

export const readNotifications = async (notificationIds) => {
  const resp = await http.put(
    `${chatHost}notifications`,
    {
      notificationIds,
    },
    {
      headers: chatHeader,
    }
  );
  return resp;
};

export async function getNotifications() {
  try {
    const dispatch = useDispatch();
    const response = await getUserUnreadNotifications();
    dispatch(
      storeNotificationsInStore(
        createOrgWiseNotificationMap(response.data.data)
      )
    );
  } catch (error) {
    // DO SOMTHING HERE AFTER CATCHING ERROR
  }
}

export const readChannelNotifications = async (Ids, type) => {
  const resp = await http.put(
    `${chatHost}notifications/read`,
    { Ids, type },
    {
      headers: chatHeader,
    }
  );
  await getNotifications();
  return resp;
};

export const getChatActions = async (id) => {
  const resp = await http.get(`${chatHost}notifications/chatActions`, {
    params: {
      channelId: id,
    },
    headers: chatHeader,
  });
  return resp;
};
