import React from 'react';
import PropTypes from 'prop-types';
import './checkbox.scss';

export default function Checkbox({
  id,
  label,
  className,
  children,
  ...otherProps
}) {
  return (
    <label
      className={`custom-check-box${className !== '' ? ' ' : ''}${
        className !== '' ? className : ''
      }`}
      htmlFor={id}
    >
      <input type="checkbox" {...otherProps} id={id} />
      <span className="checkbox" />
      {label && <ins>{label}</ins>}
      {children}
    </label>
  );
}
Checkbox.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
};
Checkbox.defaultProps = {
  label: '',
  id: '',
  className: '',
  children: [],
};
