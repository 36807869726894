import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import Cropper from 'react-easy-crop';
import './image-upload.scss';
import getCroppedImg from './crop-function';
import Button from '../button/button';
import CardPanel from '../card-panel/card-panel';
import CardPanelHeader from '../card-panel/card-panel-header';
import CardPanelBody from '../card-panel/card-panel-body';
import PanelCardFooter from '../card-panel/card-panel-footer';
import { FILE_UPLOAD_BASE64 } from '../../graphQL/fileUpload';
import Dialog from '../dialog/dialog';
// import DialogPanel from '../dialog-panel/dialog-panel';
// import DialogPanelBody from '../dialog-panel/dialog-panel-body/dialog-panel-body';
// import DialogPanelFooter from '../dialog-panel/dialog-panel-footer/dialog-panel-footer';
import { CloudUploadIcon } from '../icons/icons';
import useDialog from '../dialog/useDialog';

function ImageUpload({
  setCroppedImage,
  title,
  xRatio,
  yRatio,
  defaultImage,
  inlineUploader,
  label,
  banner,
  module,
  moduleId,
}) {
  const [t] = useTranslation('accountsettings');
  const [t2] = useTranslation('company');
  const [handleFileUpload] = useMutation(FILE_UPLOAD_BASE64);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [src, setSrc] = useState(defaultImage);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [fileName, setFileName] = useState('');
  const {
    isShowing: isActivateImageCropDislog,
    toggle: toggleImageCropDialog,
  } = useDialog();
  const [previewImage, setPreviewImage] = useState();

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => {
        reject(error);
      };
    });
  };
  const selectImage = async (file) => {
    const url = await getBase64(file);
    setSrc(url);
  };
  const getImage = (e) => {
    selectImage(e.target.files[0]);
    setFileName(e.target.files[0].name);
  };

  const handleFileData = (data) => {
    setCroppedImage(data);
    setPreviewImage(data.url);
  };

  const successUpload = (data) => {
    let input;
    if (module && moduleId) {
      input = {
        base64EncodedImage: data,
        filePath: `omni/${module}/${moduleId}`,
        originalFileName: fileName,
        appId: 'omni',
      };
    } else {
      input = {
        base64EncodedImage: data,
        filePath: 'base64',
        originalFileName: fileName,
        appId: 'omni',
      };
    }
    handleFileUpload({
      variables: {
        input,
      },
      onCompleted: (resp) => {
        handleFileData(resp.fileUploadBase64.data);
      },
    });
  };

  const onCropComplete = (_, croppedArea) => {
    setCroppedAreaPixels(croppedArea);
  };

  const showCroppedImage = async () => {
    try {
      const croppedImageData = await getCroppedImg(src, croppedAreaPixels);
      successUpload(croppedImageData);
    } catch (e) {
      console.error(e);
    }
  };

  return inlineUploader ? (
    <div className="inline-uploader">
      {label !== '' && <span className="label">{label}</span>}

      {(!src || previewImage) && (
        <label htmlFor={label}>
          <img src={previewImage} alt="" className="image" />
          {!previewImage && (
            <span className="inline-uploader-icon-block">
              <CloudUploadIcon />
            </span>
          )}
          <input
            type="file"
            accept="image/*"
            onChange={(e) => {
              getImage(e);
              toggleImageCropDialog();
            }}
            id={label}
          />
          {!previewImage && (
            <span className="title" data-cy="drag-and-drop-text">
              {t2(
                'companydetailscreen.productstab.editproductdetailspopup.dragdrop'
              )}
            </span>
          )}
          {/* {!previewImage && ( */}
          <div className={previewImage ? 'inline-uploader-block' : 'd-flex'}>
            <span className="button" data-cy="select-image-btn">
              {previewImage
                ? t2('companydetailscreen.productstab.changeimage')
                : t2(
                    'companydetailscreen.productstab.editproductdetailspopup.selectimage'
                  )}
            </span>
          </div>
          {/* )} */}
        </label>
      )}
      <Dialog
        isShowing={isActivateImageCropDislog}
        hide={() => {
          toggleImageCropDialog();
          setSrc(previewImage);
        }}
        placement="center"
      >
        <CardPanel>
          <CardPanelHeader title={title} />
          <CardPanelBody>
            {!src ? (
              <div className="image-upload" data-cy="image-upload-container">
                <label htmlFor={label}>
                  <CloudUploadIcon />
                  <input
                    type="file"
                    onChange={(e) => {
                      getImage(e);
                    }}
                    accept="image/*"
                    id={label}
                  />
                  <span className="content" data-cy="image-upload-content">
                    {t(
                      'accountsettings.myprofile.profilepicture.browse/dragfile'
                    )}
                  </span>
                  <span className="button-content" data-cy="image-upload-btn">
                    {t('accountsettings.myprofile.profilepicture.selectButton')}
                  </span>
                </label>
              </div>
            ) : (
              <div className={`crop-area ${banner ? 'banner' : ''}`}>
                <div className="crop-container">
                  <Cropper
                    image={src}
                    crop={crop}
                    zoom={zoom}
                    aspect={xRatio / yRatio}
                    onCropChange={setCrop}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                  />
                </div>
                <div className="controls">
                  <input
                    type="range"
                    value={zoom}
                    min={1}
                    max={3}
                    step={0.1}
                    aria-labelledby="Zoom"
                    onChange={(e) => {
                      setZoom(e.target.value);
                    }}
                    className="zoom-range"
                  />
                  <div className="slider-container">
                    <div className="slider-bg">
                      <div className="slider-bg-fill" />
                    </div>
                    <div className="slider-belun" />
                  </div>
                </div>
              </div>
            )}
          </CardPanelBody>
          <PanelCardFooter align="right">
            <Button
              onClick={() => {
                showCroppedImage();
                toggleImageCropDialog();
              }}
              variant="contained"
              color="submit"
              label={t2('companydetailscreen.productstab.savephoto')}
            />
          </PanelCardFooter>
        </CardPanel>
      </Dialog>
    </div>
  ) : (
    <CardPanel>
      <CardPanelHeader title={title} />
      <CardPanelBody>
        {!src ? (
          <div className="image-upload" data-cy="image-upload-container">
            <label htmlFor={label}>
              <CloudUploadIcon />
              <input
                type="file"
                onChange={(e) => {
                  getImage(e);
                }}
                accept="image/*"
                id={label}
              />
              <span className="content" data-cy="image-upload-content">
                {t('accountsettings.myprofile.profilepicture.browse/dragfile')}
              </span>
              <span
                className="button-content"
                data-cy="image-upload-select-file"
              >
                {t('accountsettings.myprofile.profilepicture.selectButton')}
              </span>
            </label>
          </div>
        ) : (
          <div className={`crop-area ${banner ? 'banner' : ''}`}>
            <div className="crop-container">
              <Cropper
                image={src}
                crop={crop}
                zoom={zoom}
                aspect={xRatio / yRatio}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
              />
            </div>
            <div className="controls">
              <input
                type="range"
                value={zoom}
                min={1}
                max={3}
                step={0.1}
                aria-labelledby="Zoom"
                onChange={(e) => {
                  setZoom(e.target.value);
                }}
                className="zoom-range"
              />
              <div className="slider-container">
                <div className="slider-bg">
                  <div className="slider-bg-fill" />
                </div>
                <div className="slider-belun" />
              </div>
            </div>
          </div>
        )}
      </CardPanelBody>
      <PanelCardFooter align="right">
        <Button
          onClick={showCroppedImage}
          variant="contained"
          color="submit"
          label={t('accountsettings.myprofile.profilepicture.savephoto')}
        />
      </PanelCardFooter>
    </CardPanel>
  );
}

export default ImageUpload;
ImageUpload.propTypes = {
  title: PropTypes.string,
  setCroppedImage: PropTypes.func,
  xRatio: PropTypes.number,
  yRatio: PropTypes.number,
  defaultImage: PropTypes.string,
  inlineUploader: PropTypes.bool,
  label: PropTypes.string,
  banner: PropTypes.bool,
  module: PropTypes.string,
  moduleId: PropTypes.number,
};
ImageUpload.defaultProps = {
  title: '',
  setCroppedImage: () => {},
  xRatio: 1,
  yRatio: 1,
  defaultImage: '',
  inlineUploader: false,
  label: '',
  banner: false,
  module: '',
  moduleId: 0,
};
