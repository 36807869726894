import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { RightDirectionIcon } from '../../../icons/icons';
import './Incident-item.scss';

export default function IncidentItem({ title, description }) {
  const [toggleIncident, setToggleIncident] = useState(false);
  const HandleToggleIncident = () => {
    setToggleIncident(!toggleIncident);
  };
  return (
    <div className={toggleIncident ? 'incident-item active' : 'incident-item'}>
      <div className="incident-top">
        <p>{title}</p>
        <button type="button" onClick={HandleToggleIncident}>
          {toggleIncident ? (
            <span>
              <RightDirectionIcon />
            </span>
          ) : (
            <RightDirectionIcon />
          )}
        </button>
      </div>
      {toggleIncident && <p className="incident-description">{description}</p>}
      {/* <Link to="/">See affected shipments</Link> */}
    </div>
  );
}

IncidentItem.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};
IncidentItem.defaultProps = {
  title: '',
  description: '',
};
