// eslint-disable-next-line
// import { useDispatch } from 'react-redux';
import {
  // readChannelNotifications,
  readNotifications,
} from '../service/chatAPIHandler';
import {
  getNotificationsForBooking,
  getNotificationsForOrder,
  getNotificationsForShipment,
} from './notificationHelper';
// import { readNotification } from '../redux/actions';

const getInitials = (str) => {
  let name = str
    .split(' ')
    .map((word) => word[0])
    .join('');
  name = name.substr(0, 2);
  return name.toUpperCase();
};

export const metaDataObjectManipulation = (object, item) => {
  let description = object;
  if (item?.type === 'object') {
    const splitKey = item?.accessor.split('.');
    splitKey?.forEach((key) => {
      description = description[key];
    });
  } else {
    description = description[item.accessor];
  }
  return description;
};

export default getInitials;

export const getSingleInitials = (str) => {
  let name = str
    .split(' ')
    .map((word) => word[0])
    .join('');
  name = name.substr(0, 1);
  return name.toUpperCase();
};

export const readNotifData = async (data, type) => {
  const checkNotifications = [];
  await data?.forEach(async (id) => {
    let notifications;
    switch (type) {
      case 'order':
        notifications = getNotificationsForOrder(id).map((item) => item._id);
        break;
      case 'booking':
        notifications = getNotificationsForBooking(id).map((item) => item._id);
        break;
      case 'shipment':
        notifications = getNotificationsForShipment(id).map((item) => item._id);
        break;
      default:
        break;
    }

    async function markNotificationRead() {
      if (notifications.length !== 0) {
        await readNotifications(notifications);
        return notifications;
      }
      return 0;
    }

    const readNotific = await markNotificationRead();
    if (readNotific) {
      checkNotifications.push(...readNotific);
    }
  });
  return checkNotifications;
};

export const favIconUpdate = (favIcon, title) => {
  const unaccepted = [null, undefined, ''];
  const favIconEle = document.getElementById('favicon');
  const appleFavIconEle = document.getElementById('apple-favicon');
  const favTitle = document.getElementById('title-ico');
  if (!unaccepted.includes(favIcon)) {
    favIconEle.href = favIcon;
    appleFavIconEle.href = favIcon;
  }
  if (!unaccepted.includes(title)) {
    favTitle.innerHTML = title;
  }
};

export const getNumber = (e) => {
  let key;
  if (e.type === 'paste') {
    key = e.clipboardData.getData('text/plain');
  } else {
    key = e.keyCode || e.which;
    key = String.fromCharCode(key);
  }
  if ([8, 32, 37, 39, 91, 189, 187, 65].includes(e.keyCode)) {
    return;
  }
  const regex = /[0-9+-/ ]|\./;
  if (!regex.test(key)) {
    e.returnValue = false;
    if (e.preventDefault) e.preventDefault();
  }
};
export const getNumberWithDot = ({ e, decimalLimit }) => {
  let key;
  if (e.type === 'paste') {
    key = e.clipboardData.getData('text/plain');
  } else {
    key = e.key;
  }

  const allowedKeys = [
    'Backspace',
    ' ',
    'ArrowLeft',
    'ArrowRight',
    'Control',
    'Meta',
    'Delete',
    '.',
    'Tab',
  ];

  if (allowedKeys.includes(key)) {
    return;
  }
  if (e.ctrlKey && ['a', 'c', 'v', 'x'].includes(key.toLowerCase())) {
    return;
  }

  if (!/^[0-9.]$/.test(key)) {
    e.preventDefault();
    return;
  }

  const { value, selectionStart } = e.target;
  const dotIndex = value.indexOf('.');
  if (key === '.' && dotIndex !== -1) {
    e.preventDefault();
    return;
  }
  if (
    dotIndex !== -1 &&
    selectionStart > dotIndex &&
    value.split('.')[1].length >= decimalLimit
  ) {
    e.preventDefault();
  }
};

export const getDigitsOnly = (e) => {
  if (e.type === 'paste') {
    const pasteContent = e.clipboardData.getData('text/plain');
    if (!/^\d+$/.test(pasteContent)) {
      e.preventDefault();
    }
    return;
  }
  const { key } = e;
  const controlKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab'];
  if (controlKeys.includes(key)) {
    return;
  }
  if (!/^\d$/.test(key)) {
    e.preventDefault();
  }
};

export function CopyClipborad(text) {
  navigator.clipboard.writeText(text);
}
export const getDigitsForPhoneNumber = (e) => {
  let key;
  if (e.type === 'paste') {
    key = e.clipboardData.getData('text/plain');
  } else {
    key = e.key;
  }
  if (!/[0-9+\b]/.test(key) && e.keyCode !== 8) {
    e.preventDefault();
  }
};

export const fetchAddress = (
  direction,
  option,
  listData,
  destDoorAddrs,
  oriDoorAddrs
) => {
  let address = direction === 'destination' ? destDoorAddrs : oriDoorAddrs;
  if (listData.length > 0) {
    address = listData;
  }
  const resultAddress = address.filter((item) => {
    return item?.types[0] === option;
  })[0]?.long_name;
  return resultAddress || '';
};

export const convertCamelCaseToTitleCase = (input) => {
  const words = input?.split(/(?=[A-Z])/); // Split at capital letters
  const titleCaseWords = words?.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1)
  );
  return titleCaseWords?.join(' ');
};

export const phoneRegExp = /^(?!0)(\+\d{1,4}\s?)?(\d{1,4}\s?)?(\d\s?){6,14}\d$/;
