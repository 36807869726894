import React from 'react';

export function DownloadZipIcon() {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.12158 4.54688H4.76611V5.12656H5.12158C5.22275 5.12656 5.29932 5.09922 5.35127 5.04453C5.40322 4.98984 5.42783 4.92148 5.42783 4.83945C5.42783 4.75742 5.40322 4.68633 5.35127 4.63164C5.29932 4.57422 5.22275 4.54688 5.12158 4.54688Z"
        fill="#2179C4"
      />
      <path
        d="M6.96182 5.14609C6.96182 4.3668 6.37119 3.73516 5.64111 3.73516H1.00635V6.55977H5.64111C6.37119 6.55977 6.96182 5.92539 6.96182 5.14609ZM3.42353 5.96641H2.26963V5.79688L3.0708 4.54727H2.2751V4.32852H3.39346V4.49258L2.58955 5.75039H3.42353V5.96641ZM4.05518 5.96641H3.78174V4.32578H4.05518V5.96641ZM5.12158 5.3457H4.76611V5.96641H4.49268V4.32578H5.12158C5.30205 4.32578 5.44424 4.37227 5.54814 4.46523C5.65205 4.5582 5.70127 4.68125 5.70127 4.83437C5.70127 4.9875 5.64932 5.11055 5.54814 5.20352C5.44424 5.29922 5.30205 5.3457 5.12158 5.3457ZM10.2103 0.970703H12.1435V1.21953H10.2103V0.970703ZM12.1407 0.344531C12.1407 0.246094 12.0614 0.164062 11.9603 0.164062H10.3907C10.2923 0.164062 10.2103 0.246094 10.2103 0.344531V0.721875H12.1407V0.344531ZM12.9747 3.02422C12.9446 2.96133 12.8817 2.92305 12.8134 2.92305H12.1407V1.46562H10.2075V2.92305H9.5376C9.46924 2.92305 9.40635 2.96133 9.37627 3.02422C9.34619 3.08711 9.35439 3.16094 9.39541 3.21289L11.0333 5.31562C11.0661 5.35938 11.1208 5.38398 11.1755 5.38398C11.2302 5.38398 11.2849 5.35938 11.3177 5.31562L12.9556 3.21289C12.9966 3.1582 13.0048 3.08437 12.9747 3.02422Z"
        fill="#2179C4"
      />
      <path
        d="M10.7325 5.55312L9.09463 3.45039C8.96338 3.28086 8.93877 3.04844 9.03447 2.85703C9.12744 2.66562 9.32705 2.54258 9.54033 2.54258H9.83018V1.10156H2.33799C1.60244 1.10156 1.00635 1.69766 1.00635 2.4332V3.35742H5.64111C6.58174 3.35742 7.34463 4.16133 7.34463 5.15117C7.34463 6.14102 6.579 6.94492 5.64111 6.94492H1.00635V12.5066C1.00635 13.2422 1.60244 13.8383 2.33799 13.8383H9.84385C10.5794 13.8383 11.1755 13.2422 11.1755 12.5066V5.77187C11.0005 5.77187 10.8392 5.69258 10.7325 5.55312Z"
        fill="#2179C4"
      />
    </svg>
  );
}
export function AddCommentIcon() {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.91675 1.16406H4.08342C2.47341 1.16406 1.16675 2.4649 1.16675 4.06906V7.5574V8.14073C1.16675 9.7449 2.47341 11.0457 4.08342 11.0457H4.95842C5.11592 11.0457 5.32592 11.1507 5.42508 11.2791L6.30008 12.4399C6.68508 12.9532 7.31508 12.9532 7.70008 12.4399L8.57508 11.2791C8.68591 11.1332 8.86092 11.0457 9.04175 11.0457H9.91675C11.5267 11.0457 12.8334 9.7449 12.8334 8.14073V4.06906C12.8334 2.4649 11.5267 1.16406 9.91675 1.16406ZM7.58342 8.01823H4.08342C3.84425 8.01823 3.64591 7.8199 3.64591 7.58073C3.64591 7.34156 3.84425 7.14323 4.08342 7.14323H7.58342C7.82258 7.14323 8.02092 7.34156 8.02092 7.58073C8.02092 7.8199 7.82258 8.01823 7.58342 8.01823ZM9.91675 5.10156H4.08342C3.84425 5.10156 3.64591 4.90323 3.64591 4.66406C3.64591 4.4249 3.84425 4.22656 4.08342 4.22656H9.91675C10.1559 4.22656 10.3542 4.4249 10.3542 4.66406C10.3542 4.90323 10.1559 5.10156 9.91675 5.10156Z"
        fill="#2179C4"
      />
    </svg>
  );
}
export function BoldDarkIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 3.33594H11.6667C12.5507 3.33594 13.3986 3.68713 14.0237 4.31225C14.6488 4.93737 15 5.78522 15 6.66927C15 7.55333 14.6488 8.40117 14.0237 9.02629C13.3986 9.65141 12.5507 10.0026 11.6667 10.0026H5V3.33594Z"
        stroke="#333333"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5 10H12.5C13.3841 10 14.2319 10.3512 14.857 10.9763C15.4821 11.6014 15.8333 12.4493 15.8333 13.3333C15.8333 14.2174 15.4821 15.0652 14.857 15.6904C14.2319 16.3155 13.3841 16.6667 12.5 16.6667H5V10Z"
        stroke="#333333"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
export function ItalicIconNew() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.8333 3.33594H8.33325"
        stroke="#333333"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.6667 16.6641H4.16675"
        stroke="#333333"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.5 3.33594L7.5 16.6693"
        stroke="#333333"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
export function LinkIconNew() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.50008 14.1693H5.83341C4.72835 14.1693 3.66854 13.7303 2.88714 12.9489C2.10573 12.1675 1.66675 11.1077 1.66675 10.0026C1.66675 8.89754 2.10573 7.83773 2.88714 7.05633C3.66854 6.27492 4.72835 5.83594 5.83341 5.83594H7.50008"
        stroke="#333333"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.5 5.83594H14.1667C15.2717 5.83594 16.3315 6.27492 17.1129 7.05633C17.8943 7.83773 18.3333 8.89754 18.3333 10.0026C18.3333 11.1077 17.8943 12.1675 17.1129 12.9489C16.3315 13.7303 15.2717 14.1693 14.1667 14.1693H12.5"
        stroke="#333333"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.66675 10H13.3334"
        stroke="#333333"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
