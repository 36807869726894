import { gql } from '@apollo/client';

export const QUERY_SUPPLIER_BUYER_LIST = gql`
  query listBuyerSupplier($input: OrggDetailsInput) {
    listBuyerSupplier(input: $input) {
      OrganizationList {
        organization {
          _id
          orgName
          orgId
          orgLogo
          configuration
        }
        status
        relationship
      }
      buyerSupplierGroupList {
        groupName
        relationship
        _id
        list {
          orgId
          _id
          orgName
          orgLogo
          activeStatus
        }
      }
    }
  }
`;

export const QUERY_BUYER_SUPPLIER_ORG_DETAILS = gql`
  query Query($orgId: String) {
    fetchSupplierBuyerOrgDetails(orgId: $orgId) {
      orgName
      _id
      orgStatus
      orgDomains
      orgAddress
      orgCity
      orgState
      orgZip
      orgCountry
      orgPhone
      orgFax
      orgEmail
      orgWebsite
      orgLogo
      orgCover
      orgDescription
      orgType
      configuration
    }
  }
`;

export const QUERY_OTHER_ORG_USERS = gql`
  query FetchOtherOrganisationUsers($input: OtherOrgId) {
    fetchOtherOrganisationUsers(input: $input) {
      data {
        firstName
        lastName
        userSub
        profilePicture
        email
        organizationId
        userType
      }
    }
  }
`;
