const host = `https://${
  process.env.REACT_APP_STAGE === 'prod'
    ? 'api.quloi.com'
    : 'omni-dev-api.quloi.com'
}`;
const GraphQLUrl = `${host}/omniGQL`;
const GraphQLUrlService = `${host}/omniGQLservices/graphql`;

// const host = 'http://localhost:8000/v1/';
// const GraphQLUrl = 'http://localhost:7001/omniGQL';
// const GraphQLUrlService = 'http://localhost:7101/omniGQLservices/graphql';

const LocalGQLUrl = 'http://localhost:7001';
const localGQLService = 'http://localhost:7100/graphql';
const localNotification = 'http://localhost:3008';
export default {
  GraphQLUrl,
  LocalGQLUrl,
  localGQLService,
  localNotification,
  host,
  GraphQLUrlService,
};
