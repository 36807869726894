import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLazyQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { QUERY_SUPPLIER_BUYER_LIST } from '../../graphQL/OrganizationQuery';
// import CustomSelect from '../custom-select/custom-select';
// import AdvanceSelectLabel from '../advance-select/advance-select-label';
import SelectableListItem from '../selectable-list-item/selectable-list-item';

function SelectForwarder({
  forwarder,
  setForwarder,
  setDefaultForworder,
  mode,
  selectedForwarders,
  behaviour,
}) {
  const [t] = useTranslation('manageorders');
  const [options, setOptions] = useState([]);
  const [filteredOptions, setFilteredOptions] = useState();
  const [handleList] = useLazyQuery(QUERY_SUPPLIER_BUYER_LIST);
  // const [defaultForword, setDefaultForworder] = useState();
  let forwarderName = {};
  const serverCall = () => {
    handleList({
      variables: {
        input: {
          page: 1,
          relationship: 'forwarder',
        },
      },
      onCompleted: (data) => {
        let option = [];
        option = data?.listBuyerSupplier?.OrganizationList?.map((item) => {
          forwarderName = {
            label: item?.organization?.orgName,
            value: Number(item?.organization?.orgId),
            logo: item?.organization?.orgLogo,
          };
          return forwarderName;
        });

        setOptions(option);
      },
    });
  };

  useEffect(() => {
    const updatedSelectedForwarders = selectedForwarders?.filter(
      (option) => !forwarder?.some((fwd) => fwd?.value === option?.value)
    );
    const filteredOpts = options?.filter(
      (option) =>
        !updatedSelectedForwarders?.some(
          (selectedOption) => selectedOption?.value === option?.value
        )
    );
    setFilteredOptions(filteredOpts);
  }, [selectedForwarders, options, forwarder]);

  useEffect(() => {
    serverCall();
    if (
      forwarder?.some(
        (item) =>
          item?.value !== undefined ||
          item?.value !== '' ||
          item?.value !== null
      )
    ) {
      return;
    }
    if (forwarder.value === undefined) {
      setForwarder([{ label: 'Select forwarder', value: '' }]);
    }
  }, []);

  const handleMultiSelect = (e) => {
    setForwarder((prev) => {
      let remForwarder = [];
      if (prev?.length > 0) {
        remForwarder = prev?.filter(
          (itm) => Number(itm?.value) !== Number(e?.target?.value)
        );
      }
      if (e?.target?.checked) {
        return [
          ...remForwarder,
          { label: e?.target?.id, value: Number(e?.target?.value) },
        ];
      }
      return [...remForwarder];
    });
  };
  const filteredForwarder = forwarder?.filter((obj) => obj?.value !== '');

  return (
    <div className="select-container">
      <div className="search-forwarder">
        <SelectableListItem
          t={t}
          data={filteredOptions}
          onChange={(e) => {
            // setForwarder({
            //   label: e.target.id,
            //   value: e.target.value,
            // });
            handleMultiSelect(e);
            setDefaultForworder(
              filteredOptions?.filter(
                (item) => item?.label === e?.target?.id
              )?.[0]?.label
            );
          }}
          placeholder={t(
            'manageordersscreen.createbookingmenu.addforwarder.pickupvendor.selectpickupvendorplc'
          )}
          // defaultValue={forwarder}
          defaultArray={filteredForwarder}
          mode={mode}
          behaviour={behaviour}
        />
      </div>
    </div>
  );
}
SelectForwarder.propTypes = {
  forwarder: PropTypes.instanceOf(Object),
  setForwarder: PropTypes.func,
  setDefaultForworder: PropTypes.func,
  mode: PropTypes.string,
  selectedForwarders: PropTypes.instanceOf(Array),
  behaviour: PropTypes.string,
};

SelectForwarder.defaultProps = {
  forwarder: [],
  setForwarder: () => {},
  setDefaultForworder: () => {},
  mode: '',
  selectedForwarders: [],
  behaviour: '',
};
export default SelectForwarder;
