import React, { useState } from 'react';
// import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';

import { EnvelopIcon, SearchIcon } from '../icons/icons';
import UserImage from '../user-image/user-image';

import './custom-search.scss';
import { selectedSearchOrg } from '../../redux/actions';

function CustomSearch({
  data,
  handleInputChanged,
  inviteUser,
  newInvite,
  t,
  errorMessage,
}) {
  const [query, setQuery] = useState('');
  const dispatch = useDispatch();

  const handleChange = (e) => {
    setQuery(e.target.value);
    handleInputChanged(e);
  };

  const handleInvite = (org) => {
    dispatch(selectedSearchOrg(org));
    inviteUser();
  };
  // const [t] = useTranslation('invitepartner');
  return (
    <div className="custom-search">
      <div className="search-box-group">
        <SearchIcon />
        <input
          type="text"
          placeholder={t('invitepartnerscreen.searchmessage.placeholder')}
          onChange={handleChange}
        />
      </div>
      {errorMessage && <p className="error-message fontsize">{errorMessage}</p>}

      {query !== '' && (
        <ul className="search-list">
          {data?.map((val) => (
            <li key={val?.orgId}>
              <button
                onClick={() => (!val?.relationship ? handleInvite(val) : null)}
                type="button"
              >
                <span className="image-box">
                  <UserImage
                    size="x-small"
                    path={val?.orgLogo}
                    shortName={val?.orgName
                      .split(' ')
                      .map((word) => word[0])
                      .join('')
                      .substr(0, 2)
                      .toUpperCase()}
                    removeTooltip
                  />
                </span>
                <span className="user-box">
                  <h4>{val?.orgName}</h4>
                  <p>{val?.orgEmail}</p>
                </span>
                {!val?.relationship ? (
                  <span className="action-box">
                    <span>{t('invitepartnerscreen.searchmessage.invite')}</span>
                  </span>
                ) : (
                  <span className="label">{val?.relationship}</span>
                )}
              </button>
            </li>
          ))}
          <div className="last-child  no-spacing">
            <button type="button">
              <EnvelopIcon />
              {t('invitepartnerscreen.searchmessage.firsthalf')}
              <span
                role="button"
                tabIndex="0"
                onKeyUp={newInvite}
                onClick={newInvite}
              >
                {t('invitepartnerscreen.searchmessage.secondhalf')}
              </span>
            </button>
          </div>
        </ul>
      )}
    </div>
  );
}

CustomSearch.propTypes = {
  data: PropTypes.instanceOf(Array),
  handleInputChanged: PropTypes.func,
  inviteUser: PropTypes.func,
  newInvite: PropTypes.func,
  t: PropTypes.func,
  errorMessage: PropTypes.string,
};

CustomSearch.defaultProps = {
  data: [],
  handleInputChanged: () => {},
  inviteUser: () => {},
  newInvite: () => {},
  t: () => [],
  errorMessage: '',
};

function mapStateToProps(state) {
  return {
    selectedSearchOrg: state.omniState.selectedSearchOrg,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    selectedSearchOrg: (dispatchData) =>
      dispatch(selectedSearchOrg(dispatchData)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomSearch);
