import React, { useState } from 'react';
// import PropTypes from 'prop-types';
import AdvancePopover from '../advance-popover/advance-popover';
import './date-history.scss';
import { CalenderIcon } from '../icons/icons';
import PanelCard from '../panel-card/panel-card';

function DateHistory() {
  const [eventValue, setEventValue] = useState();
  const togglePopover = (e) => {
    setEventValue(e.target);
  };

  const handleClose = () => {
    setEventValue(null);
  };
  return (
    <span className="date-history">
      <button type="button" onClick={togglePopover} id="dateHistoryPopover">
        <CalenderIcon /> <span>Date</span>
      </button>
      <AdvancePopover
        reference="dateHistoryPopover"
        getEvent={eventValue}
        closePopover={handleClose}
        placement="left"
      >
        <div className="date-history-pop">
          <PanelCard cardTitle="Order Dates">
            <ul className="date-history-list">
              {/* {historyArrey.map(() => { */}
              {/* {data?.map(() => { */}
              <li>
                <span className="curcle">
                  <CalenderIcon />
                </span>
                <span className="text">13th Feb 2023</span>
              </li>
              <li>
                <span className="curcle">
                  <CalenderIcon />
                </span>
                <span className="text">13th Feb 2023</span>
              </li>
              <li>
                <span className="curcle">
                  <CalenderIcon />
                </span>
                <span className="text">13th Feb 2023</span>
              </li>
              <li>
                <span className="curcle">
                  <CalenderIcon />
                </span>
                <span className="text">13th Feb 2023</span>
              </li>
              <li>
                <span className="curcle">
                  <CalenderIcon />
                </span>
                <span className="text">13th Feb 2023</span>
              </li>
              {/* })} */}
            </ul>
          </PanelCard>
        </div>
      </AdvancePopover>
    </span>
  );
}
// DateHistory.propTypes = {
//   data: PropTypes.arrayOf(Object),
// };
// DateHistory.defaultProps = {
//   data: [],
// };

export default DateHistory;
