import React, { useEffect, useState, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import './custom-tab.scss';
import Tab from './tab';
import { LeftArrowIcon, RightArrowIcon } from '../icons/icons';
import NewButton from '../button/newButton';

function Tabs({ className, disableURL, slideSize, ...props }) {
  const [activeTab, setActiveTab] = useState(props?.children[0].props.value);
  const tabRef = useRef();
  const tabHeader = useRef();
  const tabMenu = useRef();
  const [searchParams, setSearchParams] = useSearchParams();
  const [tabHeaderWidth, setTabHeaderWidth] = useState(0);
  const [tabMenuWidth, setTabMenuWidth] = useState(0);

  const onClickTabItem = (tab) => {
    setActiveTab(tab);
    if (!disableURL) {
      if (tab === 'Documents') {
        searchParams.set('filterBy', 'all');
        searchParams.delete('markId');
      } else if (tab !== 'Locations') {
        searchParams.forEach((value, key) => {
          searchParams.delete(key);
        });
      }
      searchParams.set('activeTab', tab);
      setSearchParams(searchParams);
    }
  };

  useEffect(() => {
    if (!disableURL) {
      if (searchParams.get('activeTab')) {
        setActiveTab(searchParams.get('activeTab'));
        onClickTabItem(searchParams.get('activeTab'));
      }
    }
  }, [activeTab]);

  useEffect(() => {
    if (props?.allDetailsButtonClick > 0) {
      setActiveTab('About');
    }
  }, [props?.allDetailsButtonClick]);

  useEffect(() => {
    setTabHeaderWidth(tabHeader?.current?.getBoundingClientRect().width);
    setTabMenuWidth(tabMenu?.current?.getBoundingClientRect().width);
  }, [tabHeader]);

  const handleTabLeftMenuScroll = () => {
    if (tabHeader.current) {
      const currentScrollLeft = tabHeader.current.scrollLeft;
      tabHeader.current.scrollTo({
        left: currentScrollLeft - slideSize,
        behavior: 'smooth', // Smooth scrolling behavior
      });
    }
  };
  const handleTabRightMenuScroll = () => {
    if (tabHeader.current) {
      const currentScrollLeft = tabHeader.current.scrollLeft;
      tabHeader.current.scrollTo({
        left: currentScrollLeft + slideSize,
        behavior: 'smooth', // Smooth scrolling behavior
      });
    }
  };
  return (
    <div className={`custom-tabs ${className}`}>
      <div className="custom-tab-header d-flex align-center" ref={tabHeader}>
        {tabMenuWidth > tabHeaderWidth && (
          <NewButton className="arrow-left" onClick={handleTabLeftMenuScroll}>
            <LeftArrowIcon />
          </NewButton>
        )}
        <ul
          data-cy="tab-list"
          className="custom-tab-list"
          ref={tabMenu}
          // style={{ position: 'relative', left: scrollValue }}
        >
          {props?.children?.map((child) => {
            const { label } = child.props;
            const { icon } = child.props;
            const { notification } = child.props;
            const { completed } = child.props;
            const { value } = child.props;

            return (
              <Tab
                ref={tabRef}
                activeTab={activeTab}
                key={label}
                label={label}
                onClick={onClickTabItem}
                icon={icon}
                notification={notification}
                completed={completed}
                value={value}
              />
            );
          })}
        </ul>
        {tabMenuWidth > tabHeaderWidth && (
          <NewButton className="arrow-right" onClick={handleTabRightMenuScroll}>
            <RightArrowIcon />
          </NewButton>
        )}
      </div>

      <div className="custom-tab-content">
        {props?.children?.map((child) => {
          if (child.props.value !== activeTab) {
            return undefined;
          }
          return child.props.children;
        })}
      </div>
    </div>
  );
}
Tabs.propTypes = {
  children: PropTypes.node.isRequired,
  allDetailsButtonClick: PropTypes.arrayOf(Object),
  className: PropTypes.string,
  disableURL: PropTypes.bool,
  slideSize: PropTypes.number,
};
Tabs.defaultProps = {
  allDetailsButtonClick: [],
  className: '',
  disableURL: false,
  slideSize: 100,
};
export default Tabs;
