import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './user-counter.scss';

export default function UserCounter({ size, text, onClick, id, fillColor }) {
  const [imageSize, setImageSize] = useState('');

  useEffect(() => {
    switch (size) {
      case 'large':
        setImageSize('user-counter large');
        break;
      case 'medium':
        setImageSize('user-counter medium');
        break;
      case 'small':
        setImageSize('user-counter small');
        break;
      case 'x-small':
        setImageSize('user-counter x-small');
        break;
      default:
        setImageSize('user-counter');
    }
  }, [size]);

  return (
    <span
      className={`${imageSize}`}
      onClick={onClick}
      onKeyUp={onClick}
      role="button"
      tabIndex="0"
      id={id}
      style={{ backgroundColor: fillColor }}
    >
      {text}
    </span>
  );
}
UserCounter.propTypes = {
  size: PropTypes.string,
  text: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  id: PropTypes.string,
  fillColor: PropTypes,
};
UserCounter.defaultProps = {
  size: 'small',
  text: '',
  id: '',
  fillColor: '',
};
