import React, { useState } from 'react';
import { SearchIcon } from '../icons/icons';
import './global-search.scss';
import SearchJson from './orderList.json';

export default function GlobalSearch() {
  const [showSearchItem, setSowSearchItem] = useState('');
  const showSearchList = () => {
    setSowSearchItem(!showSearchItem);
  };

  return (
    <div className="global-search-container">
      <div className="global-search-box">
        <span className="icon">
          <SearchIcon />
        </span>
        <input
          type="text"
          placeholder="Search here..."
          onFocus={showSearchList}
        />
      </div>

      {showSearchItem && (
        <div className="search-result">
          <ul className="shipment-list">
            {SearchJson.map((val) => (
              <li>
                <button type="button" onClick={showSearchList}>
                  Order No : {val.id}
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}
