import React from 'react';
import './unverified.scss';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import Logo from '../../assets/image/logo.png';
import Authentication from '../../assets/image/authentication.svg';
// import { logout } from '../../service/authService';

function UnAuthorized({ channelPartnerData }) {
  const [t] = useTranslation('global');
  const navigate = useNavigate();
  const { relation } = useParams();
  return (
    <div className="un-verifiyed-container">
      <div className="un-verifiyed">
        <div className="un-verifiyed-wrap">
          <div className="co-logo">
            <img src={channelPartnerData?.logoPath || Logo} alt="" />
          </div>
          <div className="icon">
            <img src={Authentication} alt="" width="30%" />
          </div>
          <h3>{t('unauthorizedscreen.accessforbidden')}</h3>
          <div className="button-block">
            <button
              type="button"
              onClick={() => {
                navigate(`/app/${relation}/dashboard`);
              }}
            >
              {t('unauthorizedscreen.visitdashboard')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    channelPartnerData: state.omniDetails.channelPartnerData,
  };
}

export default connect(mapStateToProps, null)(UnAuthorized);

UnAuthorized.propTypes = {
  channelPartnerData: PropTypes.instanceOf(Object),
};
UnAuthorized.defaultProps = {
  channelPartnerData: {},
};
