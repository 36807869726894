import { gql } from '@apollo/client';

const addUpdateUserActivity = gql`
  mutation UserActivity($input: userActivityInput) {
    UserActivity(input: $input) {
      data {
        typeId
        type
        userId
        relation
        ActivityTime
      }
      message
    }
  }
`;
export default addUpdateUserActivity;
