import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './multiple-image-preview.scss';
import Dialog from '../../dialog/dialog';
import useDialog from '../../dialog/useDialog';

export default function MultipleImagePreview({ data }) {
  const { isShowing: isShowAllImage, toggle: showAllImage } = useDialog();
  const [fullSize, setFullSIze] = useState(data[0].path);
  const [fileName, setFileName] = useState(data[0].originalFilename);
  const [filePath, setFilePath] = useState(data[0].path);
  const [fileType, setFileType] = useState(data[0].type);

  const getImage = (e, i) => {
    setFullSIze(e);
    setFileType(i);
  };
  const getFile = (e, f, g) => {
    setFullSIze(null);
    setFileType(f);
    setFilePath(e);
    setFileName(g);
  };
  return (
    <div className="multiple-image-preview-container">
      {data.length <= 4 && (
        <div className="multiple-image-preview">
          {data?.map((val) => {
            return (
              <>
                {['jpg', 'png', 'jpeg', 'JPEG', 'JPG', 'PNG'].includes(
                  val.type
                ) ? (
                  <button
                    type="button"
                    className="img-box"
                    onClick={showAllImage}
                  >
                    <img src={val.path} alt="" />
                  </button>
                ) : (
                  <button
                    className="img-box"
                    type="button"
                    onClick={showAllImage}
                  >
                    <span className={`file-format ${val.type}`}>
                      <span className="lines">&nbsp;</span>
                      <span className="format">{val.type}</span>
                    </span>
                  </button>
                )}
                <span />
              </>
            );
          })}
        </div>
      )}
      {data.length > 4 && (
        <div className="multiple-image-preview">
          {data?.slice(0, 3).map((val) => {
            return (
              <>
                {['jpg', 'png', 'jpeg', 'JPEG', 'JPG', 'PNG'].includes(
                  val.type
                ) ? (
                  <button
                    type="button"
                    className="img-box"
                    onClick={showAllImage}
                  >
                    <img src={val.path} alt="" />
                  </button>
                ) : (
                  <button
                    className="img-box"
                    type="button"
                    onClick={showAllImage}
                  >
                    <span className={`file-format ${val.type}`}>
                      <span className="lines">&nbsp;</span>
                      <span className="format">{val.type}</span>
                    </span>
                    <span className="file-name">{val.originalFilename}</span>
                  </button>
                )}
                <span />
              </>
            );
          })}
          <button type="button" className="count-thumb" onClick={showAllImage}>
            +{data.length - 3}
          </button>
        </div>
      )}
      <Dialog
        isShowing={isShowAllImage}
        hide={showAllImage}
        placement="center"
        noSpacing
        enableOutsideClick
        enableCloseIcon
        disabledPointer
        enableBlurBG
        closeColor="#ffffff"
        closeSize="1.667vw"
        maskBG="linear-gradient(
          243.36deg,
          rgba(29, 63, 90, 0.5) 0%,
          rgba(29, 63, 90, 0.424) 51.32%,
          rgba(29, 63, 90, 0.2) 100%
        )"
      >
        <div className="full-screen-image-preview">
          {['jpg', 'png', 'jpeg', 'JPEG', 'JPG', 'PNG'].includes(fileType) ? (
            <div className="preview-block">
              <img src={fullSize} alt="" />
            </div>
          ) : (
            <div className="preview-block">
              <a className="file-container" href={filePath}>
                <span className={`file-format ${fileType}`}>
                  <span className="lines">&nbsp;</span>
                  <span className="format">{fileType}</span>
                </span>
                <span className="file-name">{fileName}</span>
              </a>
            </div>
          )}
          <div className="thumb-block">
            {data?.map((val) => {
              return (
                <>
                  {['jpg', 'png', 'jpeg', 'JPEG', 'JPG', 'PNG'].includes(
                    val.type
                  ) ? (
                    <button
                      type="button"
                      className="img-item"
                      onClick={() => getImage(val.path, val.type)}
                    >
                      <img src={val.path} alt="" />
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="img-item"
                      onClick={() =>
                        getFile(val.path, val.type, val.originalFilename)
                      }
                    >
                      <span className={`file-format ${val.type}`}>
                        <span className="lines">&nbsp;</span>
                        <span className="format">{val.type}</span>
                      </span>
                    </button>
                  )}
                  <span />
                </>
              );
            })}
          </div>
        </div>
      </Dialog>
    </div>
  );
}
MultipleImagePreview.propTypes = {
  data: PropTypes.instanceOf(Array),
};
MultipleImagePreview.defaultProps = {
  data: [],
};
