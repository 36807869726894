import React from 'react';
import './login-register.scss';
import './login/login.scss';
import Skeleton from '../../Components/skeleton/skeleton';

export default function LoginRegisterLoader() {
  return (
    <div className="login-register-container">
      <div className="login-signup-block">
        <div
          className={
            window.location.pathname === '/register'
              ? 'login-signup-left ls-bg register'
              : 'login-signup-left ls-bg'
          }
        >
          <span className="ball">&nbsp;</span>
          <Skeleton
            height="2.5vw"
            width="100%"
            skMargin="0.764vw 0 3.056vw"
            opacity="0.5"
          />
          <Skeleton
            height="1.458vw"
            width="100%"
            skMargin="0 0 1.111vw"
            opacity="0.5"
          />
          <Skeleton
            height="1.458vw"
            width="80%"
            skMargin="0 0 1.111vw"
            opacity="0.5"
          />
          <Skeleton
            height="1.458vw"
            width="90%"
            skMargin="0 0 1.111vw"
            opacity="0.5"
          />
          <Skeleton
            height="1.458vw"
            width="70%"
            skMargin="0 0 1.111vw"
            opacity="0.5"
          />
          <Skeleton
            height="1.458vw"
            width="90%"
            skMargin="0 0 1.111vw"
            opacity="0.5"
          />
          <Skeleton
            height="1.458vw"
            width="20%"
            skMargin="0 0 4.583vw"
            opacity="0.5"
          />
          <Skeleton
            height="3.611vw"
            width="8.333vw"
            skMargin="0 0 2.083vw"
            skRadius="2.778vw"
            opacity="0.5"
          />
          <div className="footer">
            <p style={{ display: 'flex', alignItems: 'center' }}>
              <Skeleton
                skHeight="1.111vw"
                skWidth="10.764vw"
                skMargin="0.347vw 0.694vw"
                opacity="0.5"
              />
              <span>|</span>
              <Skeleton
                skHeight="1.111vw"
                skWidth="10.764vw"
                skMargin="0.347vw 0.694vw"
                opacity="0.5"
              />
            </p>
          </div>
        </div>
        <div
          className={
            window.location.pathname === '/register'
              ? 'login-signup-right register'
              : 'login-signup-right'
          }
        >
          <div className="logo">
            <Skeleton height="1.667vw" width="6.667vw" skMargin="0 0 0.833vw" />
          </div>
          <Skeleton height="1.667vw" width="24.306vw" skMargin="0 0 1.111vw" />
          <Skeleton height="1.111vw" width="24.306vw" skMargin="0 0 3.056vw" />
          <Skeleton height="2.986vw" width="100%" skMargin="0 0 1.111vw" />
          <Skeleton height="2.986vw" width="100%" skMargin="0 0 1.111vw" />
          <div
            style={{
              padding: '0.556vw 0',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Skeleton height="0.972vw" width="8.681vw" skMargin="0 0 0.694vw" />
          </div>
          <Skeleton
            height="2.986vw"
            width="100%"
            skMargin="0 0 1.111vw"
            skRadius="2.778vw"
          />
          <Skeleton
            height="2.986vw"
            width="100%"
            skMargin="0 0 1.111vw"
            skRadius="2.778vw"
          />
        </div>
      </div>
    </div>
  );
}
