import React from 'react';
import Header from './header';
import Footer from './footer';

function TermsAndConditions() {
  return (
    <div className="static-body">
      <Header link="/privacy-policy" ancorText="Privacy Policy" />
      <div class="body">
        <div class="container">
          <h1 class="text-uppercase">Quloi terms of use</h1>
          <p class="text-justify">
            These Quloi Terms of Use (&quot;<strong>Terms of Use</strong>&quot;)
            govern your use of the Quloi platform (the &quot;
            <strong>Platform</strong>&quot;), including all user manuals,
            technical manuals, and any other materials provided by Provider (as
            defined below), in printed, electronic, or other form, that describe
            the Platform or its use or specifications (the &quot;
            <strong>Documentation</strong>&quot;) provided to you (&quot;
            <strong>you</strong>&quot; or &quot;<strong>your</strong>&quot;) for
            use pursuant to and subject to a Software as a Service Agreement
            (the &quot;
            <strong>SaaS Agreement</strong>&quot;) between QULOI, Inc. (&quot;
            <strong>Provider</strong>,&quot; &quot;<strong>we</strong>,&quot;
            &quot;
            <strong>us</strong>,&quot; or &quot;<strong>our</strong>&quot;) and
            your employer or other person or entity who owns or otherwise
            lawfully controls the computer, device, or access credentials from
            which the Platform is accessed (&quot;<strong>Customer</strong>
            &quot;).
          </p>
          <p class="text-justify">
            BY CLICKING THE &quot;SIGN UP&quot; BUTTON YOU: (a) REPRESENT THAT
            YOU ARE DULY AUTHORIZED BY CUSTOMER TO ACCESS AND USE THE PLATFORM;
            AND, (b) ACCEPT THESE AUTHORIZED USER TERMS AND AGREE THAT YOU ARE
            LEGALLY BOUND BY THEM. IF YOU DO NOT AGREE TO THESE TERMS OF USE, DO
            NOT CLICK THE &quot;SIGN UP&quot; BUTTON AND YOU WILL HAVE NO
            LICENSE TO, AND MUST NOT ACCESS OR USE, THE PLATFORM.
          </p>
          <ol class="text-justify">
            <li data-text="LICENSE GRANT.">
              Subject to your strict compliance with these Terms of Use,
              Provider hereby grants you a non-exclusive, non-transferable,
              non-sublicensable, limited license to use the Platform solely in
              accordance with the Documentation, as installed on the equipment
              provided by Customer and for Customer&apos;s internal business
              purposes. The foregoing license will terminate immediately on the
              earlier to occur of:
              <ol class="number">
                <li>
                  the expiration or earlier termination of the SaaS Agreement
                  between Provider and Customer;
                </li>
                <li>
                  your ceasing to be authorized by Customer to use the Platform
                  for any or no reason; or,
                </li>
                <li>your breach of these Terms of Use.</li>
              </ol>
            </li>
            <li data-text="USE RESTRICTIONS.">
              You shall not, directly or indirectly:
              <ol class="number">
                <li>
                  use the Platform or Documentation except as set forth in
                  Section 1;
                </li>
                <li>
                  copy the Platform or Documentation, in whole or in part;
                </li>
                <li>
                  modify, translate, adapt, or otherwise create derivative works
                  or improvements, whether or not patentable, of the Platform or
                  any part thereof;
                </li>
                <li>
                  combine the Platform or any part thereof with, or incorporate
                  the Platform or any part thereof in, any other programs;
                </li>
                <li>
                  reverse engineer, disassemble, decompile, decode, or otherwise
                  attempt to derive or gain access to the source code of the
                  Platform or any part thereof;
                </li>
                <li>
                  remove, delete, alter, or obscure any trademarks or any
                  copyright, trademark, patent, or other intellectual property
                  or proprietary rights notices included on or in the Platform
                  or Documentation, including any copy thereof;
                </li>
                <li>
                  rent, lease, lend, sell, sublicense, assign, distribute,
                  publish, transfer, or otherwise provide any access to or use
                  of the Platform or any features or functionality of the
                  Platform, for any reason, to any other person or entity,
                  including any subcontractor, independent contractor,
                  affiliate, or service provider of Customer, whether or not
                  over a network and whether or not on a hosted basis, including
                  in connection with the internet, web hosting, wide area
                  network (WAN), virtual private network (VPN), virtualization,
                  time-sharing, service bureau, software as a service, cloud, or
                  other technology or service;
                </li>
                <li>
                  use the Platform or Documentation in, or in association with,
                  the design, construction, maintenance, or operation of any
                  hazardous environments or systems, including: (i) power
                  generation systems; (ii) aircraft navigation or communication
                  systems, air traffic control systems, or any other transport
                  management systems; (iii) safety-critical applications,
                  including medical or life-support systems, vehicle operation
                  applications, or any police, fire, or other safety response
                  systems; and, (iv) military or aerospace applications, weapons
                  systems, or environments.
                </li>
                <li>
                  use the Platform or Documentation in violation of any law,
                  regulation, or rule; or
                </li>
                <li>
                  use the Platform or Documentation for purposes of competitive
                  analysis of the Platform, the development of a competing
                  software product or service, or any other purpose that is to
                  the Provider&apos;s commercial disadvantage.
                </li>
              </ol>
            </li>
            <li data-text="ACCESSING AND USING THE PLATFORM.">
              You are responsible for making all arrangements necessary for you
              to have access to the Platform. To access the Platform, you may be
              asked to provide certain registration details or other information
              or log-in credentials will be provided to you. It is a condition
              of your use of the Platform that all the information you provide
              on the Platform is correct, current, and complete. You agree that
              all information you provide to register with this Platform or
              otherwise, including, but not limited to, through the use of any
              interactive features on the Platform, is governed by our Privacy
              Policy, and you consent to all actions we take with respect to
              your information consistent with our Privacy Policy. If you
              choose, or are provided with, a user name, password, or any other
              piece of information as part of our security procedures, you must
              treat such information as confidential, and you must not disclose
              it to any other person or entity. You also acknowledge that your
              account is personal to you and agree not to provide any other
              person with access to this Platform or portions of it using your
              user name, password, or other security information. You agree to
              notify us immediately of any unauthorized access to or use of your
              user name or password or any other breach of security. You also
              agree to ensure that you exit from your account at the end of each
              session. You should use particular caution when accessing your
              account from a public or shared computer so that others are not
              able to view or record your password or other personal
              information. We have the right to disable any user name, password,
              or other identifier, whether chosen by you or provided by us, at
              any time if, in our opinion, you have violated any provision of
              these Terms of Use.
            </li>
            <li data-text="USER CONTRIBUTIONS.">
              <ul class="number">
                <li>
                  User Contributions. The Platform contains interactive features
                  (collectively, &quot;Interactive Services&quot;) that allow
                  users to post, submit, publish, display, or transmit to other
                  users or other persons (hereinafter, &quot;post&quot;) content
                  or materials (collectively, &quot;User Contributions&quot;) on
                  or through the Platform. All User Contributions must comply
                  with the Content Standards set out in these Terms of Use. By
                  providing any User Contribution on the Platform, you grant us
                  and our affiliates and service providers, and each of their
                  and our respective licensees, successors, and assigns the
                  right to use, reproduce, modify, perform, display, distribute,
                  and otherwise disclose to third parties any such material for
                  any purpose, such as to share information with other users
                  about new order requests, to share invoice information and
                  provide information about when orders are fulfilled, ready for
                  shipment, or otherwise, and other similar purposes. You
                  represent and warrant that: (a) you own or control all rights
                  in and to the User Contributions and have the right to grant
                  the license granted above to us and our affiliates and service
                  providers, and each of their and our respective licensees,
                  successors, and assigns; and, (b) all of your User
                  Contributions do and will comply with these Terms of Use. You
                  understand and acknowledge that you are responsible for any
                  User Contributions you submit or contribute, and you, not the
                  Provider, have full responsibility for such content, including
                  its legality, reliability, accuracy, and appropriateness. We
                  are not responsible or liable to any third party for the
                  content or accuracy of any User Contributions posted by you or
                  any other user of the Platform.
                </li>
                <li>
                  Monitoring and Enforcement. We have the right to: (a) take any
                  action with respect to any User Contribution that we deem
                  necessary or appropriate in our sole discretion, including if
                  we believe that such User Contribution violates the Terms of
                  Use, including the Content Standards, infringes any
                  intellectual property right or other right of any person or
                  entity, threatens the personal safety of users of the Platform
                  or the public, or could create liability for the Provider; (b)
                  disclose your identity or other information about you to any
                  third party who claims that material posted by you violates
                  their rights, including their intellectual property rights or
                  their right to privacy; (c) take appropriate legal action,
                  including without limitation, referral to law enforcement, for
                  any illegal or unauthorized use of the Platform; and, (d)
                  terminate or suspend your access to all or part of the
                  Platform for any violation of these Terms of Use or as
                  otherwise permitted under the SaaS Agreement. Without limiting
                  the foregoing, we have the right to cooperate fully with any
                  law enforcement authorities or court order requesting or
                  directing us to disclose the identity or other information of
                  anyone posting any materials on or through the Platform. YOU
                  WAIVE AND HOLD HARMLESS THE PROVIDER AND ITS AFFILIATES,
                  LICENSEES, AND SERVICE PROVIDERS FROM ANY CLAIMS RESULTING
                  FROM ANY ACTION TAKEN BY ANY OF THE FOREGOING PARTIES DURING,
                  OR TAKEN AS A CONSEQUENCE OF, INVESTIGATIONS BY EITHER SUCH
                  PARTIES OR LAW ENFORCEMENT AUTHORITIES. However, we do not
                  undertake to review material before it is posted on the
                  Platform and cannot ensure prompt removal of objectionable
                  material after it has been posted. Accordingly, we assume no
                  liability for any action or inaction regarding transmissions,
                  communications, or content provided by any user or third
                  party. We have no liability or responsibility to anyone for
                  performance or nonperformance of the activities described in
                  this section.
                </li>
                <li>
                  Third Party Materials. The Platform may display, include, or
                  make available third-party content (including data,
                  information, applications, and other products, services,
                  and/or materials) or provide links to third-party websites or
                  services, including through third-party advertising
                  (&quot;Third Party Materials&quot;). You acknowledge and agree
                  that Provider is not responsible for Third Party Materials,
                  including their accuracy, completeness, timeliness, validity,
                  copyright compliance, legality, decency, quality, or any other
                  aspect thereof. Provider does not assume and will not have any
                  liability or responsibility to you or any other person or
                  entity for any Third Party Materials. Your access and use of
                  any Third Party Materials is entirely at your own risk and
                  subject to such third parties&apos; terms and conditions, to
                  the extent such terms and conditions exist.
                </li>
              </ul>
            </li>
            <li data-text="CONTENT STANDARDS.">
              These content standards apply to any and all User Contributions
              and use of Interactive Services. User Contributions must in their
              entirety comply with all applicable federal, state, local, and
              international laws, rules, and regulations. Without limiting the
              foregoing, User Contributions must not:
              <ol class="number">
                <li>
                  contain any material that is defamatory, obscene, indecent,
                  abusive, offensive, harassing, violent, hateful, inflammatory,
                  or otherwise objectionable;
                </li>
                <li>
                  promote sexually explicit or pornographic material, violence,
                  or discrimination based on race, sex, religion, nationality,
                  disability, sexual orientation, or age;
                </li>
                <li>
                  infringe any patent, trademark, trade secret, copyright, or
                  other intellectual property or other rights of any other
                  person;
                </li>
                <li>
                  violate the legal rights (including the rights of publicity
                  and privacy) of others or contain any material that could give
                  rise to any civil or criminal liability under applicable laws
                  or regulations or that otherwise may be in conflict with these
                  Terms of Use and our{' '}
                  <a href="/privacy-policy">Privacy Policy</a>;
                </li>
                <li>be likely to deceive any person;</li>
                <li>
                  promote any illegal activity, or advocate, promote, or assist
                  any unlawful act
                </li>
                <li>
                  cause annoyance, inconvenience, or needless anxiety or be
                  likely to upset, embarrass, alarm, or annoy any other person
                </li>
                <li>
                  impersonate any person, or misrepresent your identity or
                  affiliation with any person or organization; or,
                </li>
                <li>
                  give the impression that they emanate from or are endorsed by
                  us or any other person or entity if this is not the case.
                </li>
              </ol>
            </li>
            <li data-text="	UPDATES.">
              Provider may from time to time in its sole discretion develop and
              provide Platform updates, which may include upgrades, bug fixes,
              patches, other error corrections, and/or new features
              (collectively, including related documentation,
              &quot;Updates&quot;). Updates may also modify or delete in their
              entirety certain features and functionality. You agree that
              Provider has no obligation to provide any Updates or to continue
              to provide or enable any particular features or functionality
              except as may be described in the SaaS Agreement. You further
              agree that all Updates will be deemed part of the Platform and be
              subject to all terms and conditions of these Terms of Use.
            </li>
            <li data-text="COMPLIANCE MEASURES.">
              The Platform may contain technological copy protection or other
              security features designed to prevent unauthorized use of the
              Platform, including features to protect against use of the
              Platform: (a) beyond the scope of the license granted to pursuant
              to Section 1; or, (b) prohibited under Section 2. You shall not,
              and shall not attempt to, remove, disable, circumvent, or
              otherwise create or implement any workaround to, any such copy
              protection or security features.
            </li>
            <li data-text="PRIVACY; COLLECTION AND USE OF INFORMATION.">
              <ul class="number">
                <li>
                  Provider may, directly or indirectly through the services of
                  others, collect and store information and you and/or regarding
                  your use of the Platform and about equipment through which the
                  Platform is accessed and used, by means of: (i) providing
                  maintenance and support services, to the extent such services
                  are provided under the SaaS Agreement; (ii) security measures
                  included in the Platform as described in Section 3; and, (iii)
                  as otherwise disclosed in our Privacy Policy, available at:{' '}
                  <a href="/privacy-policy">Privacy Policy</a>.
                </li>
                <li>
                  You agree that the Provider may use such information for any
                  purpose related to any use of the Platform by you, including
                  but not limited to: (i) improving the performance of the
                  Platform or developing updates; (ii) verifying compliance with
                  the terms of these Terms of Use and enforcing Provider&apos;s
                  rights, including all intellectual property rights in and to
                  the Platform; and (iii) as otherwise disclosed in our Privacy
                  Policy, available at:{' '}
                  <a href="/privacy-policy">Privacy Policy</a>.
                </li>
                <li>
                  All information we collect through or in connection with this
                  Platform is subject to our Privacy Policy, available at:{' '}
                  <a href="/privacy-policy">Privacy Policy</a>. The Privacy
                  Policy is subject to change as described therein. By using
                  this Platform and providing information to or through this
                  Platform, you consent to all actions taken by us with respect
                  to your information in compliance with the{' '}
                  <a href="/privacy-policy">Privacy Policy</a>.
                </li>
              </ul>
            </li>
            <li data-text="INTELLECTUAL PROPERTY RIGHTS.">
              You acknowledge that the Platform is provided under license, and
              not sold, to you. You do not acquire any ownership interest in the
              Platform under these Terms of Use, or any other rights to the
              Platform other than to use the Platform in accordance with the
              license granted under these Terms of Use, subject to all terms,
              conditions, and restrictions. Provider and its licensors and
              service providers reserves and shall retain their entire right,
              title, and interest in and to the Platform and all intellectual
              property rights arising out of or relating to the Platform,
              subject to the license expressly granted to the Customer in these
              Terms of Use.
            </li>
            <li data-text="TERM; TERMINATION; SUSPENSION.">
              <ul class="number">
                <li>
                  Term. The term of these Terms of Use commences when you accept
                  these Terms of Use and will continue in effect until
                  terminated by you or Provider in accordance with this Section
                  11 or as described in the SaaS Agreement.
                </li>
                <li>
                  Termination. You may terminate these Terms of Use by ceasing
                  all use of the Platform and deleting or otherwise destroying
                  all copies of the Documentation in your possession. Provider
                  may terminate these Terms of Use at any time without notice as
                  permitted under the SaaS Agreement. In addition, these Terms
                  of Use will terminate immediately and automatically without
                  any notice if the SaaS Agreement is terminated or if you
                  violate any of these Terms of Use.
                </li>
                <li>
                  Effect of Termination. Upon termination: (i) all rights
                  granted to you under these Terms of Use will also terminate;
                  and, (ii) you must cease all use of the Platform and delete or
                  otherwise destroy all copes of the Documentation in your
                  possession.
                </li>
                <li>
                  Suspension. Notwithstanding anything to the contrary in these
                  Terms of Use, Provider may temporarily suspend your access to
                  any portion or all of the Platform if: (i) Provider reasonably
                  determines that (A) there is a threat or attack on any of the
                  Platform; (B) your use of the Platform disrupts or poses a
                  security risk to the Platform or to any other customer or
                  vendor of Provider; (C) you are using the Platform for
                  fraudulent or illegal activities; or, (D) Provider&apos;s
                  provision of the Platform to Customer or any other Authorized
                  User is prohibited by applicable law; (ii) any vendor of
                  Provider has suspended or terminated Provider&apos;s access to
                  or use of any third-party services or products required to
                  enable you to access the Platform; or (iii) in accordance with
                  the SaaS Agreement (any such suspension described in subclause
                  (i), (ii), or (iii), a &quot;Service Suspension&quot;).
                  Provider shall use commercially reasonable efforts to provide
                  written notice of any Service Suspension to you and to provide
                  updates regarding resumption of access to the Platform
                  following any Service Suspension. Provider shall use
                  commercially reasonable efforts to resume providing access to
                  the Platform as soon as reasonably possible after the event
                  giving rise to the Platform Suspension is cured. Provider will
                  have no liability for any damage, liabilities, losses
                  (including any loss of or profits), or any other consequences
                  that you may incur as a result of a Service Suspension.
                </li>
              </ul>
            </li>
            <li data-text="DISCLAIMER OF LIABILITY.">
              THE PLATFORM IS PROVIDED TO END USER &quot;AS IS&quot; AND WITH
              ALL FAULTS AND DEFECTS WITHOUT WARRANTY OF ANY KIND. TO THE
              MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW, PROVIDER, ON ITS
              OWN BEHALF AND ON BEHALF OF ITS AFFILIATES AND ITS AND THEIR
              RESPECTIVE PROVIDERS AND SERVICE PROVIDERS, EXPRESSLY DISCLAIMS
              ALL WARRANTIES, WHETHER EXPRESS, IMPLIED, STATUTORY, OR OTHERWISE,
              WITH RESPECT TO THE PLATFORM, INCLUDING ALL IMPLIED WARRANTIES OF
              MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND
              NON-INFRINGEMENT, AND WARRANTIES THAT MAY ARISE OUT OF COURSE OF
              DEALING, COURSE OF PERFORMANCE, USAGE, OR TRADE PRACTICE. WITHOUT
              LIMITATION TO THE FOREGOING, PROVIDER PROVIDES NO WARRANTY OR
              UNDERTAKING, AND MAKES NO REPRESENTATION OF ANY KIND THAT THE
              PLATFORM WILL MEET YOUR REQUIREMENTS, ACHIEVE ANY INTENDED
              RESULTS, BE COMPATIBLE, OR WORK WITH ANY OTHER SOFTWARE,
              APPLICATIONS, SYSTEMS, OR SERVICES, OPERATE WITHOUT INTERRUPTION,
              MEET ANY PERFORMANCE OR RELIABILITY STANDARDS, OR BE ERROR-FREE,
              OR THAT ANY ERRORS OR DEFECTS CAN OR WILL BE CORRECTED. <br />
              <br />
              SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF OR LIMITATIONS ON
              IMPLIED WARRANTIES OR THE LIMITATIONS ON THE APPLICABLE STATUTORY
              RIGHTS OF A CONSUMER, SO SOME OR ALL OF THE ABOVE EXCLUSIONS AND
              LIMITATIONS MAY NOT APPLY TO YOU
            </li>
            <li data-text="	LIMITATION OF LIABILITY.">
              IN NO EVENT WILL PROVIDER OR ITS AFFILIATES, OR ANY OF ITS OR
              THEIR RESPECTIVE PROVIDERS OR SERVICE PROVIDERS, BE LIABLE TO YOU
              FOR ANY USE, INTERRUPTION, DELAY, OR INABILITY TO USE THE
              PLATFORM. YOU ARE PROVIDED ACCESS TO THE PLATFORM PURSUANT TO THE
              SAAS AGREEMENT BETWEEN PROVIDER AND CUSTOMER, SOLELY FOR THE
              BENEFIT OF CUSTOMER AND AT CUSTOMER&apos;S DISCRETION. YOU
              ACKNOWLEDGE THAT YOU HAVE NO RIGHTS UNDER THAT SAAS AGREEMENT
              INCLUDING ANY RIGHTS TO ENFORCE ANY OF ITS TERMS. ANY OBLIGATION
              OR LIABILITY PROVIDER OR ITS AFFILIATES, OR ANY OF ITS OR THEIR
              PROVIDERS OR SERVICE PROVIDERS, MAY HAVE WITH RESPECT TO YOUR USE
              OR INABILITY TO USE THE PLATFORM SHALL BE SOLELY TO CUSTOMER
              PURSUANT TO THAT SAAS AGREEMENT AND SUBJECT TO ALL LIMITATIONS OF
              LIABILITY SET FORTH THEREIN.
              <br />
              <br />
              TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT
              WILL PROVIDER OR ITS AFFILIATES, OR ANY OF ITS OR THEIR RESPECTIVE
              PROVIDERS OR SERVICE PROVIDERS, HAVE ANY LIABILITY ARISING FROM OR
              RELATED TO YOUR USE OF OR INABILITY TO USE THE PLATFORM OR THE
              CONTENT AND SERVICES FOR: (a) PERSONAL INJURY, PROPERTY DAMAGE,
              LOST PROFITS, COST OF SUBSTITUTE GOODS OR SERVICES, LOSS OF DATA,
              LOSS OF GOODWILL, BUSINESS INTERRUPTION, COMPUTER FAILURE OR
              MALFUNCTION, OR ANY OTHER CONSEQUENTIAL, INCIDENTAL, INDIRECT,
              EXEMPLARY, SPECIAL, OR PUNITIVE DAMAGES; OR, (b) DIRECT DAMAGES IN
              AMOUNTS THAT IN THE AGGREGATE EXCEED THE AMOUNT ACTUALLY PAID BY
              CUSTOMER IN THE TWELVE (12) MONTH PERIOD PRECEDING THE EVENT OUT
              OF WHICH THE DAMAGES AROSE. THE FOREGOING LIMITATIONS WILL APPLY
              WHETHER SUCH DAMAGES ARISE OUT OF BREACH OF CONTRACT, TORT
              (INCLUDING NEGLIGENCE), OR OTHERWISE AND REGARDLESS OF WHETHER
              SUCH DAMAGES WERE FORESEEABLE OR PROVIDER WAS ADVISED OF THE
              POSSIBILITY OF SUCH DAMAGES. SOME JURISDICTIONS DO NOT ALLOW
              CERTAIN LIMITATIONS OF LIABILITY SO SOME OR ALL OF THE ABOVE
              LIMITATIONS OF LIABILITY MAY NOT APPLY TO YOU.
            </li>
            <li data-text="INDEMNIFICATION.">
              You agree to indemnify, defend, and hold harmless Provider and its
              officers, directors, employees, agents, affiliates, successors,
              and assigns from and against any and all losses, damages,
              liabilities, deficiencies, claims, actions, judgments,
              settlements, interest, awards, penalties, fines, costs, or
              expenses of whatever kind, including reasonable attorneys&apos;
              fees, arising from or relating to your use or misuse of the
              Platform or your breach of these Terms of Use, including but not
              limited to the content you submit or make available through this
              Platform.
            </li>
            <li data-text="EXPORT REGULATION.">
              The Platform may be subject to US export control laws, including
              the US Export Administration Act and its associated regulations.
              You shall not directly or indirectly export, re-export, or release
              the Platform to, or make the Platform or Documentation accessible
              from, any jurisdiction or country to which export, re-export, or
              release is prohibited by law, rule, or regulation. You shall
              comply with all applicable federal laws, regulations, and rules,
              and complete all required undertakings (including obtaining any
              necessary export license or other governmental approval), prior to
              exporting, re-exporting, releasing, or otherwise making the
              Platform available outside the US.
            </li>
            <li data-text="GOVERNING LAW.">
              These Terms of Use are governed by and construed in accordance
              with the internal laws of the State of New York without giving
              effect to any choice or conflict of law provision or rule (whether
              of the State of New York or any other jurisdiction) that would
              cause the application of Laws of any jurisdiction other than those
              of the State of New York.
            </li>
            <li data-text="SEVERABILITY.">
              If any provision of these Terms of Use is illegal or unenforceable
              under applicable law, the remainder of the provision will be
              amended to achieve as closely as possible the effect of the
              original term and all other provisions of these Terms of Use will
              continue in full force and effect.
            </li>
            <li data-text="WAIVER.">
              No failure to exercise, and no delay in exercising, on the part of
              either party, any right or any power hereunder shall operate as a
              waiver thereof, nor shall any single or partial exercise of any
              right or power hereunder preclude further exercise of that or any
              other right hereunder.
            </li>
            <li data-text="ENTIRE AGREEMENT.">
              These Terms of Use, the SaaS Agreement under which you gain access
              to the Platform, and our Privacy Policy constitute the entire
              agreement between you and Provider with respect to the Platform
              and supersede all prior or contemporaneous understandings and
              agreements, whether written or oral, with respect to the Platform.
            </li>
          </ol>
        </div>
      </div>
      <Footer link="/privacy-policy" linkText="Privacy Policy" />
    </div>
  );
}

export default TermsAndConditions;
