import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Tabs from '../../Components/tabs/custom-tab';
import './settings.scss';
import WebhookIntegration from '../profile/webhook-integration/webhook-integration';
import RolesAndPermissions from './roles-and-permissions';
import ApiDocumentation from './api-documentation/api-documentation';
import { checkPermission } from '../../helpers/permissionHelper';
import { giveProfile } from '../../Components/global-notification/notification-list';

function Settings() {
  const [t] = useTranslation('systemsettings');
  const { relation } = useParams();
  return (
    <>
      <div className="page-header">
        <h1>{t('systemsettings.title')}</h1>
      </div>
      <div className="profile-container">
        <Tabs className="profile">
          <div
            className="body"
            value="Roles & Permission"
            label={t('systemsettings.tabtitle.roles&permission')}
          >
            <RolesAndPermissions t={t} />
          </div>
          {checkPermission(
            giveProfile(relation),
            'Configuration',
            'configure Webhook'
          ) ? (
            <div
              label={t('systemsettings.tabtitle.systemintegrations')}
              className="body"
              value="System Integrations"
            >
              <h2>{t('systemsettings.tabtitle.systemintegrations')}</h2>
              <WebhookIntegration />
            </div>
          ) : (
            <div />
          )}
          {checkPermission(
            giveProfile(relation),
            'Configuration',
            'API Documentation'
          ) ||
          checkPermission(
            giveProfile(relation),
            'Configuration',
            'API Documentation',
            'V'
          ) ? (
            <div
              className="body"
              label={t('systemsettings.tabtitle.apidocument')}
              value="API Document"
            >
              <ApiDocumentation />
            </div>
          ) : (
            <div />
          )}
        </Tabs>
      </div>
    </>
  );
}

export default Settings;
