import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, Link } from 'react-router-dom';
import { gql, useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './group-component.scss';
import user from '../../../assets/image/profile.jpeg';
import { EditIcon } from '../../icons/icons';
import AddGroup from '../add-group/add-group';
import { getTotalNotificationCountOfOrganization } from '../../../helpers/notificationHelper';

const QUERY_EDIT_GROUP = gql`
  mutation Mutation($input: EditbuyerSupplierGroup) {
    EditbuyerSupplierGroup(input: $input) {
      message
    }
  }
`;

function GroupComponent({
  addGroupMenuDetails,
  inputStructureGroup,
  editGroup,
  clickEditGroup,
  closeEditGroup,
  notificationContainer,
  closeGroup,
}) {
  const [t] = useTranslation('toast');
  const [handleEditGroup] = useMutation(QUERY_EDIT_GROUP);
  const [currentURL, setCurrentUrl] = useState();
  const { relation } = useParams();

  const handleSubmit = () => {
    handleEditGroup({
      variables: {
        input: {
          groupName: inputStructureGroup.groupName,
          relationship: inputStructureGroup.relationship,
          list: inputStructureGroup?.listOrg?.map((list) => {
            return { orgId: String(list.orgId || list) };
          }),
          id: inputStructureGroup.id,
        },
      },
      onCompleted: () => {
        toast.success(t('groupcomponent.success.groupedited'));
        closeEditGroup();
        window.location.reload();
      },
      // onError: (err) => {
      //   toast.error(err.message);
      // },
    });
  };

  const handleInitialOrgName = (str) => {
    let name = str
      .split(' ')
      .map((word) => word[0])
      .join('');
    name = name.substr(0, 2);
    return name.toUpperCase();
  };
  useEffect(() => {
    setCurrentUrl(window.location.pathname);
  }, [window.location.pathname]);
  const getCurrentPage = (e) => {
    localStorage.setItem('activeOrg', e.orgId);
    setCurrentUrl(window.location.pathname);
    closeGroup();
  };
  return (
    <div>
      {!editGroup ? (
        addGroupMenuDetails && (
          <div className="group-component-container">
            <div className="group-container">
              <div className="group-image">
                {addGroupMenuDetails?.list?.map((group) => {
                  return (
                    <span>
                      {group?.orgName && group?.orgName[0]?.toUpperCase()}
                    </span>
                  );
                })}
              </div>

              <div className="group-name-container">
                <div className="group-name">
                  <p className="name">{addGroupMenuDetails.groupName}</p>
                  <button type="button" onClick={clickEditGroup}>
                    Edit
                    <EditIcon color="#ffffff" />
                  </button>
                </div>
              </div>
            </div>
            <ul className="user-list">
              {addGroupMenuDetails?.list?.map((group) => {
                const count =
                  notificationContainer[parseInt(group.orgId, 10)]?.totalCount;
                return (
                  <li
                    className={
                      `/app/${relation}/${group._id}/company-dashboard` ===
                      currentURL
                        ? 'active'
                        : ''
                    }
                  >
                    <Link
                      to={`/app/${relation}/${group._id}/company-dashboard`}
                      onClick={() => getCurrentPage(group)}
                    >
                      <div className="user-image">
                        {group.orgLogo === '' ? (
                          <span>{handleInitialOrgName(group.orgName)}</span>
                        ) : (
                          <img src={group.orgLogo || user} alt="" />
                        )}
                      </div>
                      <div className="user-details">
                        <p>{group.orgName}</p>
                        {count && count !== 0 && (
                          <span>
                            {count > 99
                              ? '99+'
                              : getTotalNotificationCountOfOrganization(
                                  notificationContainer[
                                    parseInt(group.orgId, 10)
                                  ]
                                )}
                          </span>
                        )}
                      </div>
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
        )
      ) : (
        <AddGroup
          cancelClick={closeEditGroup}
          submitClick={handleSubmit} // Edit group
          Clicklabel="Update"
        />
      )}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    addGroupMenuDetails: state.omniDetails.addGroupMenuDetails,
    inputStructureGroup: state.omniState.inputStructureGroup,
    notificationContainer: state.onmiNotifications,
  };
}

GroupComponent.propTypes = {
  addGroupMenuDetails: PropTypes.instanceOf(Object),
  inputStructureGroup: PropTypes.instanceOf(Object),
  clickEditGroup: PropTypes.func,
  editGroup: PropTypes.bool,
  closeEditGroup: PropTypes.func,
  notificationContainer: PropTypes.instanceOf(Object),
  // selected: PropTypes.bool,
  closeGroup: PropTypes.func,
};

GroupComponent.defaultProps = {
  addGroupMenuDetails: {},
  inputStructureGroup: {},
  clickEditGroup: () => {},
  editGroup: PropTypes.bool,
  closeEditGroup: () => {},
  notificationContainer: {},
  // selected: false,
  closeGroup: () => {},
};

export default connect(mapStateToProps, null)(GroupComponent);
