import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Link,
  useLocation,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import CountCard from '../Components/count-card/count-card';
import {
  BoxIcon,
  CalenderIcon,
  ChatIcon,
  // EditIcon,
  EditReceiptIcon,
  InfoIcon,
  // LocationIcon,
  // SMSIcon,
  // TextEditIcon,
  TimezoneIcon,
  UndoIcon,
} from '../Components/icons/icons';
import ListingStatus from '../Components/listing-status/listing-status';
import ShipmentMode from '../Components/shipment-mode/shipment-mode';
import UserGroup from '../Components/user-group/user-group';
import {
  isNotificationExistForBooking,
  isNotificationExistForOrder,
  isNotificationExistForShipment,
} from '../helpers/notificationHelper';
import QuantityCard from '../Components/quantity-card/quantity-card';
import PartNumber from '../Components/part-number/part-number';
import DateHistory from '../Components/date-history/date-history';
import DatepickerBox from '../Components/datepicker-box/datepicker-box';
import TimeLog from '../Components/time-log/time-log';
import EditBox from '../Components/edit-box/edit-box';
import Tooltip from '../Components/tooltip/tooltip';
// import TextBox from '../Components/text-box/text-box';
import AdvanceSelectLabel from '../Components/advance-select/advance-select-label';
import NumberCard from '../Components/number-card/number-card';
import { DropDownSts } from '../pages/details-page/details-listing/details-listing';
// import Button from '../Components/button/button';
import Dialog from '../Components/dialog/dialog';
import ViewBooking from '../Components/create-booking/view-booking';
import useDialog from '../Components/dialog/useDialog';
import BesicTable from '../Components/table/basicTable';
import AdvancePopover from '../Components/advance-popover/advance-popover';
import BuyerBooking from '../Components/create-booking/buyer';
import SearchSelect from '../Components/search-select/search-select';
import { VIEW_QUOTATION_LIST } from '../graphQL/bookingList';
import UserImage from '../Components/user-image/user-image';
import { getUserTimeZoneDateFormat } from '../helpers/dateHelper';
import IconButton from '../Components/icon-button/icon-button';
import { checkPermission } from '../helpers/permissionHelper';
import { giveProfile } from '../Components/global-notification/notification-list';
import getInitials from '../helpers/utils';

// import LocationGroup from '../Components/location-group/location-group';
// import UserImage from '../Components/user-image/user-image';
// import {
//   RatingStatus,
//   TotalRating,
// } from '../Components/total-rating/total-rating';
// import getInitials from '../helpers/utils';
const d = new Date();
const year = d.getFullYear();
function dateIsValid(date) {
  return !Number.isNaN(new Date(date).getTime());
}

const falseValue = false;

export const lineItemColumns = (cargoReady) => [
  {
    Header: () => {
      const [t] = useTranslation('manageorders');
      return <>{t('manageordersscreen.lineItembtn.itemid')}</>;
    },
    accessor: 'itemCode',
  },
  {
    Header: () => {
      const [t] = useTranslation('manageorders');
      return <>{t('manageordersscreen.lineItembtn.orderid')}</>;
    },
    accessor: 'order',
    Cell: (data) => {
      const { row } = data;
      const { relation, companyId } = useParams();
      return (
        <>
          {!['forwarder', 'customBroker'].includes(relation) ? (
            <Link
              to={`/app/${relation}/${companyId}/order-details/${
                row?.original?.orderId || ''
              }`}
            >
              {row.original.poNumber}
            </Link>
          ) : (
            row.original.poNumber
          )}
          <span />
        </>
      );
    },
  },
  ...(cargoReady
    ? [
        {
          Header: () => {
            const [t] = useTranslation('manageorders');
            return <>{t('manageordersscreen.lineItembtn.cargoready')}</>;
          },
          align: 'center',
          accessor: 'dueDate',
          type: 'date',
          Cell: (data) => {
            const {
              cell: {
                row: {
                  original: { dueDate },
                },
              },
            } = data;
            return getUserTimeZoneDateFormat(dueDate);
          },
        },
      ]
    : []),
  {
    Header: () => {
      const [t] = useTranslation('manageorders');
      return <>{t('manageordersscreen.lineItembtn.quantitytoship')}</>;
    },
    accessor: 'qtyShipped',
  },
  {
    Header: () => {
      const [t] = useTranslation('manageorders');
      return <>{t('manageordersscreen.lineItembtn.dimensions')}</>;
    },
    accessor: 'Dimensions (LXWXH)',
    Cell: (data) => {
      const {
        row: {
          original: { dimensions },
        },
      } = data;
      return (
        <span className="no-wrap text-center d-block">{`${dimensions?.[0]?.length} X ${dimensions?.[0]?.width} X ${dimensions?.[0]?.height}`}</span>
      );
    },
    align: 'center',
  },
  {
    Header: () => {
      const [t] = useTranslation('manageorders');
      return <>{t('manageordersscreen.lineItembtn.weight')}</>;
    },
    accessor: 'Weight',
    Cell: (data) => {
      const {
        row: {
          original: { grossWeight, weightUnit },
        },
      } = data;
      return <span className="no-wrap">{`${grossWeight} ${weightUnit}`}</span>;
    },
  },
];

export const BookListRateQuote = [
  {
    Header: '',
    accessor: 'orgLogo',
    Cell: (data) => {
      const {
        cell: { row },
      } = data;
      return (
        <UserImage
          name={row?.original?.forwarderDetail?.orgName}
          path={row?.original?.forwarderDetail?.orgLogo}
          shortName={getInitials(row?.original?.forwarderDetail?.orgName)}
          removeTooltip
        />
      );
    },
  },
  {
    Header: () => {
      const [t] = useTranslation('managebooking');
      return <>{t('managebookingscreen.quotesmenu.forwarder')}</>;
    },
    accessor: 'orgName',
    Cell: (data) => {
      const {
        cell: { row },
      } = data;
      const { relation, companyId } = useParams();
      return (
        <Link
          to={`/app/${relation}/${companyId}/booking-details/${
            row.original.channelId || ''
          }`}
        >
          {row?.original?.forwarderDetail?.orgName}
        </Link>
      );
    },
  },
  {
    Header: () => {
      const [t] = useTranslation('managebooking');
      return <>{t('managebookingscreen.quotesmenu.liner')}</>;
    },
    accessor: 'linearName',
  },
  {
    Header: () => {
      const [t] = useTranslation('managebooking');
      return <>{t('managebookingscreen.quotesmenu.pricequoted')}</>;
    },
    align: 'center',
    accessor: 'totalPrice',
    Cell: (data) => {
      const { row } = data;
      return (
        <>
          {row?.original?.currency && row?.original?.currency !== '--' ? (
            <p>
              {row?.original?.totalPrice} {row?.original?.currency}
            </p>
          ) : (
            '--'
          )}
          <span />
        </>
      );
    },
  },
  {
    Header: () => {
      const [t] = useTranslation('managebooking');
      return <>{t('managebookingscreen.quotesmenu.quotevalidity')}</>;
    },
    align: 'center',
    accessor: 'quotationExpiryDate',
    Cell: (data) => {
      const {
        cell: { row },
      } = data;
      return (
        <p>{getUserTimeZoneDateFormat(row?.original?.quotationExpiryDate)}</p>
      );
    },
  },
  {
    Header: () => {
      const [t] = useTranslation('managebooking');
      return <>{t('managebookingscreen.quotesmenu.quotestatus')}</>;
    },
    align: 'center',
    accessor: 'status',
    Cell: (data) => {
      const { row } = data;
      return (
        <>
          {row.original.status === '--' ? (
            '--'
          ) : (
            <ListingStatus statusLabel={row.original.status} />
          )}
          <span />
        </>
      );
    },
  },
];

export const BookListOrder = [
  {
    Header: () => {
      const [t] = useTranslation('managebooking');
      return <>{t('managebookingscreen.ordersmenu.order')}</>;
    },
    accessor: 'order',
  },
  {
    Header: () => {
      const [t] = useTranslation('managebooking');
      return <>{t('managebookingscreen.ordersmenu.item')}</>;
    },
    accessor: 'orderItem',
  },
  {
    Header: () => {
      const [t] = useTranslation('managebooking');
      return <>{t('managebookingscreen.ordersmenu.cargoready')}</>;
    },
    align: 'center',
    accessor: 'cargoReady',
  },
  {
    Header: () => {
      const [t] = useTranslation('managebooking');
      return <>{t('managebookingscreen.ordersmenu.status')}</>;
    },
    align: 'center',
    accessor: 'orderStatus',
  },
];

export const BookListOrder2 = [
  {
    Header: () => {
      const [t] = useTranslation('managebooking');
      return <>{t('managebookingscreen.ordersmenu.order')}</>;
    },
    accessor: 'order',
    Cell: (data) => {
      const {
        row,
        row: {
          values: { order },
        },
      } = data;
      const { relation, companyId } = useParams();
      return (
        <>
          {!['forwarder', 'customBroker'].includes(relation) ? (
            <Link
              to={`/app/${relation}/${companyId}/order-details/${
                row?.original?.orderId || ''
              }`}
            >
              {order}
            </Link>
          ) : (
            order
          )}
          <span />
        </>
      );
    },
  },
  {
    Header: () => {
      const [t] = useTranslation('managebooking');
      return <>{t('managebookingscreen.ordersmenu.item')}</>;
    },
    accessor: 'orderItem',
  },
  {
    Header: () => {
      const [t] = useTranslation('managebooking');
      return <>{t('managebookingscreen.ordersmenu.cargoready')}</>;
    },
    align: 'center',
    accessor: 'cargoReady',
  },
  {
    Header: () => {
      const [t] = useTranslation('managebooking');
      return <>{t('managebookingscreen.ordersmenu.status')}</>;
    },
    align: 'center',
    accessor: 'orderStatus',
  },
];

export const orderItemColumns = [
  {
    Header: () => {
      const [t] = useTranslation('manageorders');
      return <>{t('manageordersscreen.order.orderitem')}</>;
    },
    accessor: 'itemCode',
  },
  {
    Header: () => {
      const [t] = useTranslation('manageorders');
      return <>{t('manageordersscreen.order.productname')}</>;
    },
    accessor: 'itemName',
  },
  {
    Header: () => {
      const [t] = useTranslation('manageorders');
      return <>{t('manageordersscreen.order.quantity')}</>;
    },
    accessor: 'quantity',
    align: 'center',
    Cell: (data) => {
      const {
        cell: { row },
      } = data;
      const [t] = useTranslation('manageorders');
      return (
        <div className="d-flex justify-center">
          <NumberCard
            value={row.original.quantity}
            status="primary"
            content={t('manageordersscreen.order.tooltip.total')}
          />
          <NumberCard
            value={row.original.cargoQty}
            status="success"
            content={t('manageordersscreen.order.tooltip.processed')}
          />
          <NumberCard
            value={row.original.bookedQty}
            status="warning"
            content={t('manageordersscreen.order.tooltip.booked')}
          />
          <NumberCard
            value={row.original.pendingQty || 0}
            content={t('manageordersscreen.order.tooltip.pending')}
          />
        </div>
      );
    },
  },
  {
    Header: () => {
      const [t] = useTranslation('manageorders');
      return <>{t('manageordersscreen.order.totalprice')}</>;
    },
    accessor: 'total',
    align: 'center',
  },
  {
    Header: () => {
      const [t] = useTranslation('manageorders');
      return <>{t('manageordersscreen.order.cargoready')}</>;
    },
    accessor: 'dueDate',
    enableSortBy: true,
    align: 'center',
  },
  {
    Header: () => {
      const [t] = useTranslation('manageorders');
      return <>{t('manageordersscreen.order.status')}</>;
    },
    accessor: 'statusName',
    align: 'center',
    Cell: (data) => {
      const {
        cell: { row },
      } = data;
      const { relation } = useParams();
      return (
        <DropDownSts
          lineItem={row?.original}
          statusLabel={row.original.statusName}
          enableDropdown={
            row?.original?.eventAction?.upComingStage?.length > 0 &&
            checkPermission(giveProfile(relation), 'Order', 'Milestone update')
          }
          futureStatus={row?.original?.eventAction?.upComingStage}
        />
      );
    },
  },

  {
    Header: <TimezoneIcon width="1.25vw" height="1.25vw" />,
    accessor: 'newlineItemWiseProposeChange',
    Cell: (data) => {
      const {
        cell: { row },
      } = data;
      return <TimeLog data={row.original.newlineItemWiseProposeChange} />;
    },
  },
];

export const itemizedOrderItemColumns = [
  {
    Header: 'Order Item#',
    accessor: 'itemCode',
  },
  {
    Header: 'Product Name',
    accessor: 'itemName',
  },
  {
    Header: 'Impex PO#',
    accessor: 'po_number',
  },
  {
    Header: 'Quantity',
    accessor: 'quantity',
    CellL: (data) => {
      const {
        cell: { row },
      } = data;
      return (
        <QuantityCard
          quantityObj={row.original.quantityObj ? row.original.quantityObj : []}
        />
      );
    },
  },
  {
    Header: 'Status',
    accessor: 'statusName',
    Cell: (data) => {
      const {
        cell: { row },
      } = data;
      return <ListingStatus statusLabel={row.original.statusName} />;
    },
  },
  ...(falseValue
    ? [
        {
          Header: <TimezoneIcon width="1.25vw" height="1.25vw" />,
          accessor: 'timeline',
          Cell: (data) => {
            const {
              cell: { row },
            } = data;
            return <TimeLog data={row.original.timeline} />;
          },
        },
      ]
    : []),
];

export const ShipmentorderItemColumns = [
  {
    Header: 'Order Item#',
    accessor: 'itemCode',
  },
  {
    Header: 'Product Name',
    accessor: 'itemName',
  },
  {
    Header: 'Quantity',
    accessor: 'quantity',
  },

  {
    Header: 'Delivery Date',
    accessor: 'dueDate',
  },
  // {
  //   Header: 'Status',
  //   accessor: 'statusName',
  //   Cell: (data) => {
  //     const {
  //       cell: { row },
  //     } = data;
  //     return <ListingStatus statusLabel={row.original.statusName} />;
  //   },
  // },
];

const buyerBookingNewColumns = () => {
  const columnData = [
    {
      Header: 'Package type',
      accessor: '',
    },
    {
      Header: 'Quantity',
      accessor: 'quantity',
      Cell: (data) => {
        const {
          row: {
            values: { quantity },
          },
        } = data;

        return (
          <div className="d-flex justify-center">
            <NumberCard value={quantity} status="primary" />
            <NumberCard value={quantity} status="success" />
            <NumberCard value={quantity} status="warning" />
            <NumberCard value={quantity} />
          </div>
        );
      },
    },
    {
      Header: 'Dimensions (LXWXH)',
      accessor: '',
    },

    {
      Header: 'Gross Weight',
      accessor: '',
    },
    {
      Header: 'Line Items ',
      accessor: '',
      align: 'center',
      Cell: () => {
        return (
          <AdvanceSelectLabel
            placeholder="Select Line Items"
            className="select-item"
          />
        );
      },
    },
  ];
  return columnData;
};
export { buyerBookingNewColumns };

const buyerBookingDetailsColumns = (cargoReady) => {
  const columnsData = [
    {
      Header: () => {
        const [t] = useTranslation('manageorders');
        return <>{t('manageordersscreen.bookingdetailsbtn.packagetype')}</>;
      },
      accessor: 'packageType',
    },
    {
      Header: () => {
        const [t] = useTranslation('manageorders');
        return <>{t('manageordersscreen.bookingdetailsbtn.quantity')}</>;
      },
      accessor: 'pkgQty',
      align: 'center',
      Cell: (data) => {
        const { row } = data;
        const [t] = useTranslation('manageorders');
        return (
          <div className="d-flex justify-center">
            <NumberCard
              value={row?.original?.pkgQty}
              status="primary"
              content={t('manageordersscreen.bookingdetailsbtn.tooltip')}
            />
          </div>
        );
      },
    },
    {
      Header: () => {
        const [t] = useTranslation('manageorders');
        return <>{t('manageordersscreen.bookingdetailsbtn.dimensions')}</>;
      },
      accessor: 'Dimensions (LXWXH)',
      align: 'center',
      Cell: (data) => {
        const {
          row: {
            original: {
              dimentions: { length, width, height },
            },
          },
        } = data;
        return (
          <span className="no-wrap text-center d-block">{`${length} X ${width} X ${height}`}</span>
        );
      },
    },

    {
      Header: () => {
        const [t] = useTranslation('manageorders');
        return <>{t('manageordersscreen.bookingdetailsbtn.grossweight')}</>;
      },
      accessor: 'Gross Weight',
      align: 'center',
      Cell: (data) => {
        const {
          row: {
            original: { pkgGrossWt, pkgWtUnit },
          },
        } = data;
        return <span className="no-wrap">{`${pkgGrossWt} ${pkgWtUnit}`}</span>;
      },
    },
    {
      Header: () => {
        const [t] = useTranslation('manageorders');
        return <>{t('manageordersscreen.bookingdetailsbtn.lineitems')}</>;
      },
      accessor: 'Line Items',
      align: 'center',
      Cell: (data) => {
        const [eventValue, setEventValue] = useState();
        const togglePopover = (e) => {
          setEventValue(e.target);
        };
        const handleClose = () => {
          setEventValue(null);
        };
        const {
          cell: {
            row: {
              original: { lineItems, poNumber },
            },
          },
        } = data;
        const [t] = useTranslation('manageorders');
        return (
          <>
            <button
              className="item-selected-button"
              type="button"
              onClick={togglePopover}
              id="countPopover"
            >
              {lineItems?.length} {t('manageordersscreen.lineItembtn.itembtn')}
            </button>
            <AdvancePopover
              reference="countPopover"
              getEvent={eventValue}
              closePopover={handleClose}
              placement="left"
            >
              <div className="count-card-table">
                <BesicTable
                  columns={lineItemColumns(cargoReady)}
                  data={lineItems?.map((item) => ({
                    ...item,
                    poNumber: item?.poNumber || poNumber,
                  }))}
                />
              </div>
            </AdvancePopover>
          </>
        );
      },
    },
  ];
  return columnsData;
};

export { buyerBookingDetailsColumns };

export const bookingColumns = [
  {
    Header: () => {
      const [t] = useTranslation('manageorders');
      return <>{t('manageordersscreen.Bookings.booking')}</>;
    },
    accessor: 'referenceId',
    Cell: (data) => {
      const {
        row: {
          values: { referenceId },
        },
      } = data;
      // const { relation, companyId } = useParams();
      return (
        // <Link
        //   to={`/app/${relation}/${companyId}/booking-details/${
        //     row.original._id || ''
        //   }`}
        // >
        <p>{referenceId}</p>
        // {bookingNo}
        // </Link>
      );
    },
  },

  {
    Header: () => {
      const [t] = useTranslation('manageorders');
      return <>{t('manageordersscreen.Bookings.mode')}</>;
    },
    accessor: 'mode',
    Cell: (data) => {
      const { row } = data;
      return (
        <ShipmentMode
          shipmentMode={row.original.mode}
          bookingType={row.original.bookingType}
        />
      );
    },
  },
  {
    Header: () => {
      const [t] = useTranslation('manageorders');
      return <>{t('manageordersscreen.Bookings.bookingdate')}</>;
    },
    accessor: 'bookingDate',
    Cell: (data) => {
      const { row } = data;
      return <p>{getUserTimeZoneDateFormat(row?.original?.bookingDate)}</p>;
    },
  },

  {
    Header: () => {
      const [t] = useTranslation('manageorders');
      return <>{t('manageordersscreen.Bookings.orgin')}</>;
    },
    accessor: 'originFacility',
  },
  {
    Header: () => {
      const [t] = useTranslation('manageorders');
      return <>{t('manageordersscreen.Bookings.destination')}</>;
    },
    accessor: 'destinationFacility',
  },
  {
    Header: () => {
      const [t] = useTranslation('manageorders');
      return <>{t('manageordersscreen.Bookings.status')}</>;
    },
    accessor: 'currentStatus',
    Cell: (data) => {
      const {
        cell: { row },
      } = data;
      return <ListingStatus statusLabel={row.original.currentStatus} />;
    },
  },
  {
    Header: () => {
      const [t] = useTranslation('manageorders');
      return <>{t('manageordersscreen.Bookings.actions')}</>;
    },
    accessor: 'actions',
    Cell: (data) => {
      const {
        isShowing: isViewBookingDetailsDislog,
        toggle: toggleViewDetailsModal,
      } = useDialog();
      const {
        cell: { row },
      } = data;
      const { relation } = useParams();
      const [searchParams, setSearchParams] = useSearchParams();
      const location = useLocation();
      const popover = searchParams.get('popover');
      const popId = searchParams.get('popId');
      useEffect(() => {
        const checkURLParamsAndOpenDialog = () => {
          if (
            popover === 'Create Booking' &&
            !isViewBookingDetailsDislog &&
            popId === row?.original?._id
          ) {
            // popover=Create+Booking&popId=170922967377830106
            toggleViewDetailsModal();
          } else if (
            popover !== 'Create Booking' &&
            isViewBookingDetailsDislog
          ) {
            toggleViewDetailsModal();
          }
        };
        checkURLParamsAndOpenDialog();
      }, [location.search]);
      return (
        <div>
          {row?.original?.bookingAction?.addForwarder &&
          checkPermission(giveProfile(relation), 'Booking', 'createBooking') ? (
            <>
              <IconButton
                onClick={() => {
                  // toggleViewDetailsModal();
                  if (
                    searchParams.get('popover') !== 'Create Booking' &&
                    !location.search?.includes('Create+Booking')
                  ) {
                    searchParams.set('popover', 'Create Booking');
                    setSearchParams(searchParams);
                  }
                  if (
                    searchParams.get('popId') !== row?.original?._id &&
                    !location.search?.includes('popId')
                  ) {
                    searchParams.set('popId', row?.original?._id);
                    setSearchParams(searchParams);
                  }
                }}
                verient="grey"
              >
                <BoxIcon />
              </IconButton>
              <Dialog
                isShowing={isViewBookingDetailsDislog}
                hide={() => {
                  toggleViewDetailsModal();
                  searchParams.delete('popover');
                  searchParams.delete('popId');
                  setSearchParams(searchParams);
                }}
                placement="center"
                // enableOutsideClick
                enableCloseIcon
              >
                <BuyerBooking
                  columns={buyerBookingDetailsColumns()}
                  itemsData={row?.original}
                  toggleViewDetailsModal={toggleViewDetailsModal}
                />
              </Dialog>
            </>
          ) : (
            <>
              <IconButton
                onClick={() => {
                  // toggleViewDetailsModal();
                  if (
                    searchParams.get('popover') !== 'Create Booking' &&
                    !location.search?.includes('Create+Booking')
                  ) {
                    searchParams.set('popover', 'Create Booking');
                    setSearchParams(searchParams);
                  }
                  if (
                    searchParams.get('popId') !== row?.original?._id &&
                    !location.search?.includes('popId')
                  ) {
                    searchParams.set('popId', row?.original?._id);
                    setSearchParams(searchParams);
                  }
                }}
                verient="grey"
              >
                <EditReceiptIcon />
              </IconButton>

              <Dialog
                isShowing={isViewBookingDetailsDislog}
                hide={() => {
                  toggleViewDetailsModal();
                  searchParams.delete('popover');
                  searchParams.delete('popId');
                  setSearchParams(searchParams);
                }}
                placement="center"
                // enableOutsideClick
                enableCloseIcon
              >
                <ViewBooking
                  id={row?.original?._id}
                  columns={buyerBookingDetailsColumns()}
                  itemsData={row?.original?.packages}
                />
              </Dialog>
            </>
          )}
        </div>
      );
    },
  },
];

export const orderOverviewDetails = [
  {
    title: 'Order#',
    accessor: 'poNumber',
    type: 'string',
  },
  {
    title: 'Created Date',
    accessor: 'createdAt',
    type: 'date',
  },
  {
    title: 'Status',
    accessor: 'currentStatus',
    type: 'string',
  },
  {
    title: 'Last Update',
    accessor: 'LastUpdatedAt',
    type: 'date',
  },
  {
    title: 'Cargo Ready',
    accessor: 'poDueDate',
    type: 'date',
  },
  {
    title: 'Cargo Type',
    accessor: 'cargoType',
    type: 'string',
  },
  // {
  //   title: 'ETA',
  //   accessor: 'ETA',
  //   type: 'date',
  // },
  // {
  //   title: 'New ETA',
  //   accessor: 'newETA',
  //   type: 'date',
  // },
  // {
  //   title: 'Planned ETA',
  //   accessor: 'plannedETA',
  //   type: 'date',
  // },
  {
    title: 'Origin',
    accessor: 'originFacility',
    type: 'string',
  },
  {
    title: 'Destination',
    accessor: 'destinationFacility',
    type: 'string',
  },
];

export const bookingMetaData = [
  {
    title: 'Booking #',
    accessor: 'referenceId',
  },
  {
    title: 'Booking Date',
    accessor: 'bookingDate',
  },
  {
    title: 'Current Status',
    accessor: 'currentStatus',
  },
  {
    title: 'Mode',
    accessor: 'mode',
  },
  {
    title: 'Booking Type',
    accessor: 'bookingType',
  },
  {
    title: 'Incoterm',
    accessor: 'incoTerm',
  },
  {
    title: 'Type of Move',
    accessor: 'typeofMove',
  },
  {
    title: 'Origin',
    accessor: 'originFacility',
  },
  {
    title: 'Destination',
    accessor: 'destinationFacility',
  },
  {
    title: 'Container Type',
    accessor: 'containerType',
    isHide: true,
    hideMode: ['Air', 'Inland'],
  },
  {
    title: 'Cargo Load',
    accessor: 'cargoLoad',
    isHide: true,
    hideMode: ['Air', 'Ocean'],
  },
  {
    title: 'Number of Trucks',
    accessor: 'numberOfTrucks',
    isHide: true,
    hideMode: ['Air', 'Ocean'],
  },
  {
    title: 'Shipment #',
    accessor: 'shipmentNo',
    redirectId: 'shipmentId',
    redirectPage: 'shipment-details',
  },
  {
    title: 'Order#',
    accessor: 'poNumber',
    redirectId: 'orderID',
    redirectPage: 'order-details',
  },
];

export const bookingItemColumns = [
  {
    Header: 'Item#',
    accessor: 'itemName',
  },
  {
    Header: 'Item Code',
    accessor: 'itemCode',
  },
  // {
  //   Header: 'Price',
  //   accessor: 'price',
  // },
  {
    Header: 'Qty.',
    accessor: 'quantity',
    Cell: (data) => {
      const {
        cell: { row },
      } = data;
      return (
        <div className="d-flex justify-center">
          <NumberCard
            value={row?.original?.quantity}
            status="primary"
            content="Quantity"
          />
        </div>
      );
    },
  },
  {
    Header: 'Weight',
    accessor: 'grossWeight',
  },
  {
    Header: 'Line Items',
    accessor: '',
    align: 'center',
    Cell: (data) => {
      const [eventValue, setEventValue] = useState();
      const togglePopover = (e) => {
        setEventValue(e.target);
      };
      const handleClose = () => {
        setEventValue(null);
      };
      const {
        cell: {
          row: {
            original: { lineItems },
          },
        },
      } = data;
      const [t] = useTranslation('manageorders');
      return (
        <>
          <button
            className="item-selected-button"
            type="button"
            onClick={togglePopover}
            id="countPopover"
          >
            {lineItems?.length} {t('manageordersscreen.lineItembtn.itembtn')}
          </button>
          <AdvancePopover
            reference="countPopover"
            getEvent={eventValue}
            closePopover={handleClose}
            placement="left"
          >
            <BesicTable columns={lineItemColumns()} data={lineItems} />
          </AdvancePopover>
        </>
      );
    },
  },
];

export const bookingListColumns = [
  {
    Header: () => {
      const [t] = useTranslation('managebooking');
      return <>{t('managebookingscreen.bookingcardrow.participants')}</>;
    },
    accessor: 'participants',
    Cell: (data) => {
      const {
        row: { original },
      } = data;
      return (
        <div className="table-col-row">
          <UserGroup userData={original?.uniqueUserIds || []} />
        </div>
      );
    },
  },
  {
    Header: () => {
      const [t] = useTranslation('managebooking');
      return <>{t('managebookingscreen.bookingcardrow.booking')}</>;
    },
    accessor: 'referenceId',
    Cell: (data) => {
      const {
        row,
        row: {
          values: { referenceId },
        },
      } = data;
      const { relation, companyId } = useParams();
      return row?.original?.rateQuote === '-' || relation === 'forwarder' ? (
        <Link
          to={`/app/${relation}/${companyId}/booking-details/${
            row.original._id || ''
          }`}
        >
          {referenceId}
        </Link>
      ) : (
        referenceId
      );
    },
  },
  {
    Header: () => {
      const [t] = useTranslation('managebooking');
      return <>{t('managebookingscreen.bookingcardrow.order')}</>;
    },
    accessor: 'poNumber',
    Cell: (data) => {
      const {
        row: {
          original: { packages, poNumber, orderID },
        },
      } = data;
      // const { relation } = useParams();
      let totalOrders = [];
      const lineItemsData = [];
      packages?.forEach((pkg) =>
        pkg?.lineItems?.forEach((item) => {
          lineItemsData?.push({
            orderId: item?.poID || item?.orderId || orderID,
            order: item?.poNumber || poNumber,
            orderItem: item?.itemCode,
            qtyShipped: item?.qtyShipped,
            orderStatus: item?.statusName,
            cargoReady: getUserTimeZoneDateFormat(item?.dueDate),
          });
          totalOrders.push(item?.poNumber || poNumber);
        })
      );
      totalOrders = [...new Set(totalOrders)];
      // const [quoteList, setQuoteList] = useState([]);
      // const [handleQuotations] = useLazyQuery(VIEW_QUOTATION_LIST);

      // const handleQuotes = (ip) => {
      //   handleQuotations({
      //     variables: {
      //       input: {
      //         referenceId: ip?.referenceId,
      //         poNumber: ip?.poNumber,
      //         bookingType: ip?.bookingType,
      //       },
      //     },
      //     onCompleted: (resp) => {
      //       setQuoteList(resp?.ViewQuotations?.data);
      //     },
      //   });
      // };
      const [t] = useTranslation('managebooking');
      return (
        <>
          {/* {relation !== 'forwarder' ? (
            <Link
              to={`/app/${relation}/${companyId}/order-details/${
                row.original.orderID || ''
              }`}
            >
              {poNumber}
            </Link> */}
          <CountCard
            data={lineItemsData}
            label={
              totalOrders?.length === 1
                ? `${totalOrders?.length} ${t(
                    'managebookingscreen.quotesmenu.orderSingular'
                  )}`
                : `${totalOrders?.length || 0} ${t(
                    'managebookingscreen.quotesmenu.orderPlural'
                  )}`
            }
            enableDynamicTable={true}
            columns={BookListOrder2}
            disableBorder
          />
          {/* ) : ( poNumber )}
          <span /> */}
        </>
      );
    },
  },
  {
    Header: () => {
      const [t] = useTranslation('managebooking');
      return <>{t('managebookingscreen.bookingcardrow.bookingdate')}</>;
    },
    accessor: 'bookingDate',
    enableSortBy: true,
    setWidth: '6.944vw',
  },
  {
    Header: () => {
      const [t] = useTranslation('managebooking');
      return <>{t('managebookingscreen.bookingcardrow.quotes')}</>;
    },
    accessor: 'rate_quotes',
    enableSortBy: true,
    Cell: (data) => {
      const {
        cell: { row },
      } = data;
      const { relation } = useParams();
      const [quoteList, setQuoteList] = useState([]);
      const [handleQuotations] = useLazyQuery(VIEW_QUOTATION_LIST);
      const handleQuotes = (ip) => {
        handleQuotations({
          variables: {
            input: {
              referenceId: ip?.referenceId,
              poNumber: ip?.poNumber,
              bookingType: ip?.bookingType,
              profileType: giveProfile(relation),
            },
          },
          onCompleted: (resp) => {
            setQuoteList(resp?.ViewQuotations?.data);
          },
        });
      };
      const [t] = useTranslation('managebooking');
      return row?.original?.rateQoute !== '-' ? (
        <CountCard
          onClick={() => handleQuotes(row?.original)}
          data={quoteList}
          label={
            row?.original?.rateQoute === 1
              ? `${row?.original?.rateQoute} ${t(
                  'managebookingscreen.quotesmenu.titleSingular'
                )}`
              : `${row?.original?.rateQoute} ${t(
                  'managebookingscreen.quotesmenu.titlePlural'
                )}`
          }
          enableDynamicTable
          columns={BookListRateQuote}
        />
      ) : (
        <p>{row?.original?.rateQoute}</p>
      );
    },
  },
  {
    Header: () => {
      const [t] = useTranslation('managebooking');
      return <>{t('managebookingscreen.bookingcardrow.mode')}</>;
    },
    accessor: 'mode',
    Cell: (data) => {
      const { row } = data;
      return (
        <ShipmentMode
          shipmentMode={row.original.mode}
          bookingType={row.original.bookingType}
        />
      );
    },
    enableSortBy: true,
  },
  // {
  //   Header: 'Shipper',
  //   accessor: 'ShipperName',
  // },
  {
    Header: () => {
      const [t] = useTranslation('managebooking');
      return <>{t('managebookingscreen.bookingcardrow.origin')}</>;
    },
    accessor: 'originFacility',
    enableSortBy: false,
    setWidth: '8.333vw',
  },
  {
    Header: () => {
      const [t] = useTranslation('managebooking');
      return <>{t('managebookingscreen.bookingcardrow.destination')}</>;
    },
    accessor: 'destinationFacility',
    enableSortBy: true,
    setWidth: '8.333vw',
  },
  // {
  //   Header: 'Consignee',
  //   accessor: 'ConsigneeName',
  // },
  {
    Header: () => {
      const [t] = useTranslation('managebooking');
      return <>{t('managebookingscreen.bookingcardrow.status')}</>;
    },
    accessor: 'currentStatus',
    align: 'center',
    setWidth: '5.556vw',
    Cell: (data) => {
      const {
        row: {
          values: { currentStatus },
        },
      } = data;
      return <ListingStatus statusLabel={currentStatus} />;
    },
    enableSortBy: true,
  },
  {
    Header: () => {
      const [t] = useTranslation('managebooking');
      return <>{t('managebookingscreen.bookingcardrow.notification')}</>;
    },
    accessor: 'notification',
    setWidth: '$px100',
    Cell: (data) => {
      const { row } = data;
      const { relation, companyId } = useParams();
      return (
        <Link
          to={`/app/${relation}/${companyId}/booking-details/${
            row.original.BookingId || ''
          }`}
        >
          <button type="button" className="list-chat-icon">
            {isNotificationExistForBooking(row.original.BookingId) === true && (
              <span className="count">&nbsp;</span>
            )}
            <span className="number">
              <ChatIcon color="#1D3E69" />
            </span>
          </button>
        </Link>
      );
    },
  },
];

export const ItemizedBookingListColumns = [
  {
    Header: 'Participants',
    accessor: 'participants',
    Cell: (data) => {
      const {
        row: { original },
      } = data;
      return (
        <div className="table-col-row">
          <UserGroup userData={original?.uniqueUserIds || []} />
        </div>
      );
    },
  },
  {
    Header: 'Booking #',
    accessor: 'bookingNo',
    Cell: (data) => {
      const {
        row,
        row: {
          values: { bookingNo },
        },
      } = data;
      const { relation, companyId } = useParams();
      return (
        <Link
          to={`/app/${relation}/${companyId}/booking-details/${
            row.original.BookingId || ''
          }`}
        >
          {bookingNo}
        </Link>
      );
    },
  },
  {
    Header: 'Mode',
    accessor: 'mode',
    Cell: (data) => {
      const {
        row: {
          values: { mode },
        },
      } = data;
      return <ShipmentMode shipmentMode={mode} />;
    },
    enableSortBy: true,
  },
  {
    Header: 'POL',
    accessor: 'originFacility',
    enableSortBy: true,
  },
  {
    Header: 'POD',
    accessor: 'pdestinationFacilityod',
    enableSortBy: true,
  },

  // {
  //   Header: 'Shipper',
  //   accessor: 'ShipperName',
  // },
  {
    Header: 'Ship to unit',
    accessor: 'shipToUnit',
    enableSortBy: true,
  },
  {
    Header: 'Status',
    accessor: 'currentStatus',
    Cell: (data) => {
      const {
        row: {
          values: { currentStatus },
        },
      } = data;
      return <ListingStatus statusLabel={currentStatus} />;
    },
    enableSortBy: true,
  },
  {
    Header: 'Notification',
    accessor: 'notification',
    Cell: (data) => {
      const { row } = data;
      const { relation, companyId } = useParams();
      return (
        <Link
          to={`/app/${relation}/${companyId}/booking-details/${
            row.original.BookingId || ''
          }`}
        >
          <button type="button" className="list-chat-icon">
            {isNotificationExistForBooking(row.original.BookingId) === true && (
              <span className="count">&nbsp;</span>
            )}
            <span className="number">
              <ChatIcon color="#1D3E69" />
            </span>
          </button>
        </Link>
      );
    },
  },
];

export const orderListColumns = [
  {
    Header: () => {
      const [t] = useTranslation('manageorders');
      return <>{t('manageordersscreen.ordercardrow.participants')}</>;
    },
    accessor: 'username',
    align: 'center',

    Cell: (data) => {
      const {
        row: { original },
      } = data;

      return (
        <div className="table-col-row justify-center">
          <UserGroup userData={original?.uniqueUserIds || []} />
        </div>
      );
    },
  },
  {
    Header: () => {
      const [t] = useTranslation('manageorders');
      return <>{t('manageordersscreen.ordercardrow.order')}</>;
    },
    accessor: 'poNumber',
    align: 'center',

    Cell: (data) => {
      const {
        row,
        row: {
          values: { poNumber },
        },
      } = data;
      const { relation, companyId } = useParams();
      return (
        <Link
          to={`/app/${relation}/${companyId}/order-details/${
            row.original.poId || ''
          }`}
        >
          {poNumber}
        </Link>
      );
    },
  },
  // {
  //   Header: 'Total Qty',
  //   accessor: 'totalQty',
  //   Cell: (data) => {
  //     const {
  //       row: {
  //         values: { totalQty },
  //       },
  //     } = data;
  //     return <div style={{ textAlign: 'center' }}>{totalQty}</div>;
  //   },
  // },
  {
    Header: () => {
      const [t] = useTranslation('manageorders');
      return <>{t('manageordersscreen.ordercardrow.totalitems')}</>;
    },
    accessor: 'lineItems',
    align: 'center',

    Cell: (data) => {
      const {
        cell: { row },
      } = data;
      return (
        <CountCard
          data={row.original.lineItems}
          label={row?.original?.lineItems?.length}
          align="center"
        />
      );
    },
  },
  {
    Header: () => {
      const [t] = useTranslation('manageorders');
      return <>{t('manageordersscreen.ordercardrow.createddate')}</>;
    },
    accessor: 'createdAt',
    enableSortBy: true,
    align: 'center',
  },
  {
    Header: () => {
      const [t] = useTranslation('manageorders');
      return <>{t('manageordersscreen.ordercardrow.cargoready')}</>;
    },
    accessor: 'poDueDate',
    enableSortBy: true,
    align: 'center',
  },
  {
    Header: () => {
      const [t] = useTranslation('manageorders');
      return <>{t('manageordersscreen.ordercardrow.updatedon')}</>;
    },
    accessor: 'LastUpdatedAt',
    enableSortBy: true,
    align: 'center',
  },
  {
    Header: () => {
      const [t] = useTranslation('manageorders');
      return <>{t('manageordersscreen.ordercardrow.status')}</>;
    },
    enableSortBy: true,
    accessor: 'currentStatus',
    align: 'center',
    Cell: (data) => {
      const {
        cell: { row },
      } = data;
      return (
        <ListingStatus
          statusLabel={row.original.currentStatus || ''}
          className="max-w-125"
        />
      );
    },
  },
  {
    Header: () => {
      const [t] = useTranslation('manageorders');
      return <>{t('manageordersscreen.ordercardrow.notification')}</>;
    },
    accessor: 'notification',
    align: 'center',

    Cell: (data) => {
      const { row } = data;
      const { relation, companyId } = useParams();
      return (
        <Link
          to={`/app/${relation}/${companyId}/order-details/${
            row.original.poId || ''
          }`}
          className="list-chat-icon"
        >
          {isNotificationExistForOrder(row.original.poId) === true && (
            <span className="count">&nbsp;</span>
          )}
          <span className="number">
            <ChatIcon color="#1D3E69" />
          </span>
        </Link>
      );
    },
  },
];

export const itemizedOrderListColumns = [
  {
    Header: 'Participants',
    accessor: 'username',
    Cell: (data) => {
      const {
        row: { original },
      } = data;
      return (
        <div className="table-col-row">
          <UserGroup userData={original?.uniqueUserIds || []} />
        </div>
      );
    },
  },
  {
    Header: 'JD Order #',
    accessor: 'poNumber',
    Cell: (data) => {
      const {
        row,
        row: {
          values: { poNumber },
        },
      } = data;
      const { relation, companyId } = useParams();
      return (
        <Link
          to={`/app/${relation}/${companyId}/order-details/${
            row.original.poId || ''
          }`}
        >
          {poNumber}
        </Link>
      );
    },
  },
  {
    Header: 'Parts',
    accessor: 'parts',
    Cell: (data) => {
      const {
        row: {
          values: { parts },
        },
      } = data;
      return <div style={{ textAlign: 'center' }}>{parts}</div>;
    },
  },
  {
    Header: 'Shipping Unit',
    accessor: 'shippingUnit',
  },
  {
    Header: 'Delivery Date',
    accessor: 'poDueDate',
    enableSortBy: true,
  },
  {
    Header: 'Quantity',
    accessor: 'lineItems',
    Cell: (data) => {
      const {
        cell: { row },
      } = data;
      return (
        <QuantityCard
          quantityObj={row.original.quantityObj ? row.original.quantityObj : []}
        />
      );
    },
  },

  {
    Header: 'Status',
    enableSortBy: true,
    accessor: 'currentStatus',
    Cell: (data) => {
      const {
        cell: { row },
      } = data;
      return <ListingStatus statusLabel={row.original.currentStatus || ''} />;
    },
  },
  // {
  //   Header: 'Mode',
  //   accessor: 'mode',
  //   Cell: () => {
  //     return (
  //       <ShipmentMode
  //         multipleMode
  //         startShipment="10000"
  //         endShipment="10000"
  //         startShipmentMode="Ocean"
  //         endShipmentMode="Air"
  //       />
  //     );
  //   },
  // },
  {
    Header: 'Notification',
    accessor: 'notification',
    Cell: (data) => {
      const { row } = data;
      const { relation, companyId } = useParams();
      return (
        <Link
          to={`/app/${relation}/${companyId}/order-details/${
            row.original.poId || ''
          }`}
          className="list-chat-icon"
        >
          {isNotificationExistForOrder(row.original.poId) === true && (
            <span className="count">&nbsp;</span>
          )}
          <span className="number">
            <ChatIcon color="#1D3E69" />
          </span>
        </Link>
      );
    },
  },
];

export const itemizedOrderListReadyToBookColumns = [
  {
    Header: 'Participants',
    accessor: 'username',
    Cell: (data) => {
      const {
        row: { original },
      } = data;
      return (
        <div className="table-col-row">
          <UserGroup userData={original?.uniqueUserIds || []} />
        </div>
      );
    },
  },
  {
    Header: 'JD Order #',
    accessor: 'poNumber',
    Cell: (data) => {
      const {
        row,
        row: {
          values: { poNumber },
        },
      } = data;
      const { relation, companyId } = useParams();
      return (
        <Link
          to={`/app/${relation}/${companyId}/order-details/${
            row.original.poId || ''
          }`}
        >
          {poNumber}
        </Link>
      );
    },
  },
  {
    Header: 'Parts',
    accessor: 'parts',
    Cell: () => {
      // const {
      //   cell: { row },
      // } = data;
      return (
        <PartNumber
          // text={row.original.parts}
          text="1234"
        />
      );
    },
  },
  {
    Header: 'Unit Name',
    accessor: 'shipping_unit',
  },
  {
    Header: `Delivery Dates ${year}`,
    accessor: 'deliveryDates',
    Cell: () => {
      // const {
      //   cell: { row },
      // } = data;
      return <DateHistory />;
    },
  },
  {
    Header: 'Quantity',
    accessor: 'lineItems',
    Cell: () => {
      // const {
      //   cell: { row },
      // } = data;
      return (
        <QuantityCard total="1000" placed="1000" warehouse="0" rejected="" />
      );
    },
  },

  {
    Header: 'Status',
    enableSortBy: true,
    accessor: 'currentStatus',
    Cell: (data) => {
      const {
        cell: { row },
      } = data;
      return <ListingStatus statusLabel={row.original.currentStatus || ''} />;
    },
  },
  {
    Header: 'Notification',
    accessor: 'notification',
    Cell: (data) => {
      const { row } = data;
      const { relation, companyId } = useParams();
      return (
        <Link
          to={`/app/${relation}/${companyId}/order-details/${
            row.original.poId || ''
          }`}
          className="list-chat-icon"
        >
          {isNotificationExistForOrder(row.original.poId) === true && (
            <span className="count">&nbsp;</span>
          )}
          <span className="number">
            <ChatIcon color="#1D3E69" />
          </span>
        </Link>
      );
    },
  },
];

export const shipmentColumns = [
  {
    Header: () => {
      const [t] = useTranslation('manageshipments');
      return <>{t('manageshipmentsscreen.shipmentscardrow.participants')}</>;
    },
    accessor: 'participants',
    Cell: (data) => {
      const {
        row: { original },
      } = data;
      return (
        <div className="table-col-row">
          <UserGroup userData={original?.uniqueUserIds || []} />
        </div>
      );
    },
  },
  {
    Header: () => {
      const [t] = useTranslation('manageshipments');
      return <>{t('manageshipmentsscreen.shipmentscardrow.shipment')}</>;
    },
    accessor: 'shipmentNo',
    Cell: (data) => {
      const {
        row,
        row: {
          values: { shipmentNo },
        },
      } = data;
      const { relation, companyId } = useParams();
      return (
        <Link
          to={`/app/${relation}/${companyId}/shipment-details/${row.original.shipmentId}`}
        >
          {shipmentNo}
        </Link>
      );
    },
    enableSortBy: true,
  },
  {
    Header: () => {
      const [t] = useTranslation('manageshipments');
      return <>{t('manageshipmentsscreen.shipmentscardrow.blno')}</>;
    },
    accessor: 'BLno',
    Cell: (data) => {
      const {
        row: {
          values: { BLno },
          original: { HBLNo },
        },
      } = data;
      return <p>{BLno || HBLNo}</p>;
    },
  },
  {
    Header: () => {
      const [t] = useTranslation('manageshipments');
      return <>{t('manageshipmentsscreen.shipmentscardrow.order')}</>;
    },
    accessor: 'orderNo',
    Cell: (data) => {
      const {
        row: {
          original: { packages, poNumber, orderID, orderNo },
        },
      } = data;
      let totalOrders = [];
      const lineItemsData = [];
      packages?.forEach((pkg) =>
        pkg?.lineItems?.forEach((item) => {
          lineItemsData?.push({
            orderId: item?.poID || item?.orderId || orderID,
            order: item?.poNumber || poNumber || orderNo,
            orderItem: item?.itemCode,
            qtyShipped: item?.qtyShipped,
            orderStatus: item?.statusName,
            cargoReady: getUserTimeZoneDateFormat(item?.dueDate),
          });
          totalOrders.push(item?.poNumber || poNumber);
        })
      );
      totalOrders = [...new Set(totalOrders)];

      return (
        <>
          <CountCard
            data={lineItemsData}
            label={
              totalOrders?.length === 1
                ? `${totalOrders?.length} Order`
                : `${totalOrders?.length || 0} Orders`
            }
            enableDynamicTable={true}
            columns={BookListOrder2}
            disableBorder
          />
          <span />
        </>
      );
    },
  },
  {
    Header: () => {
      const [t] = useTranslation('manageshipments');
      return <>{t('manageshipmentsscreen.shipmentscardrow.mode')}</>;
    },
    accessor: 'mode',
    Cell: (data) => {
      const { row } = data;
      return (
        <ShipmentMode
          shipmentMode={row.original.mode}
          bookingType={row.original.bookingType}
        />
      );
    },
    enableSortBy: true,
  },
  // {
  //   Header: 'Consignee',
  //   accessor: 'ConsigneeName',
  // },
  // {
  //   Header: 'Shipper',
  //   accessor: 'ShipperName',
  // },
  {
    Header: () => {
      const [t] = useTranslation('manageshipments');
      return <>{t('manageshipmentsscreen.shipmentscardrow.origin')}</>;
    },
    accessor: 'originFacility',
    enableSortBy: true,
  },
  {
    Header: () => {
      const [t] = useTranslation('manageshipments');
      return <>{t('manageshipmentsscreen.shipmentscardrow.destination')}</>;
    },
    accessor: 'destinationFacility',
    enableSortBy: true,
  },
  {
    Header: () => {
      const [t] = useTranslation('manageshipments');
      return <>{t('manageshipmentsscreen.shipmentscardrow.status')}</>;
    },
    accessor: 'currentStatus',
    align: 'center',
    Cell: (data) => {
      const {
        row: {
          values: { currentStatus },
        },
      } = data;
      return <ListingStatus statusLabel={currentStatus} />;
    },
  },
  {
    Header: () => {
      const [t] = useTranslation('manageshipments');
      return <>{t('manageshipmentsscreen.shipmentscardrow.ETA')}</>;
    },
    accessor: 'ETA',
    align: 'center',
  },
  {
    Header: () => {
      const [t] = useTranslation('manageshipments');
      return <>{t('manageshipmentsscreen.shipmentscardrow.event')}</>;
    },
    accessor: 'event',
    align: 'center',
    Cell: (data) => {
      const {
        row: {
          values: { event },
        },
      } = data;
      return <ListingStatus statusLabel={event} />;
    },
  },
  {
    Header: () => {
      const [t] = useTranslation('manageshipments');
      return <>{t('manageshipmentsscreen.shipmentscardrow.notification')}</>;
    },
    accessor: 'notification',
    align: 'center',
    Cell: (data) => {
      const { row } = data;
      const { relation, companyId } = useParams();
      return (
        <Link
          to={`/app/${relation}/${companyId}/shipment-details/${row.original.shipmentId}`}
          className="list-chat-icon"
        >
          {isNotificationExistForShipment(row.original.shipmentId) === true && (
            <span className="count">&nbsp;</span>
          )}
          <span className="number">
            <ChatIcon color="#1D3E69" />
          </span>
        </Link>
      );
    },
  },
];

export const shipmentOrderPageColumns = [
  {
    Header: () => {
      const [t] = useTranslation('manageorders');
      return <>{t('manageordersscreen.shipments.shipment')}</>;
    },
    accessor: 'shipmentNo',
    Cell: (data) => {
      const {
        row,
        row: {
          values: { shipmentNo },
        },
      } = data;
      const { relation, companyId } = useParams();
      return (
        <Link
          to={`/app/${relation}/${companyId}/shipment-details/${row.original._id}`}
        >
          {shipmentNo}
        </Link>
      );
    },
  },
  {
    Header: () => {
      const [t] = useTranslation('manageorders');
      return <>{t('manageordersscreen.shipments.BLHBL')}</>;
    },
    accessor: 'BLno',
  },
  {
    Header: () => {
      const [t] = useTranslation('manageorders');
      return <>{t('manageordersscreen.shipments.mode')}</>;
    },
    accessor: 'mode',
    Cell: (data) => {
      const {
        row: {
          original: { bookingType },
          values: { mode },
        },
      } = data;
      return <ShipmentMode shipmentMode={mode} bookingType={bookingType} />;
    },
  },

  // {
  //   Header: 'Status',
  //   accessor: 'currentStatus',
  //   Cell: (data) => {
  //     const {
  //       row: {
  //         values: { currentStatus },
  //       },
  //     } = data;
  //     return <ListingStatus statusLabel={currentStatus} />;
  //   },
  // },
  // {
  //   Header: 'Action',
  //   accessor: '',
  //   Cell: () => {
  //     return (
  //       <div className="action small">
  //         <Tooltip content="Accept" direction="bottom">
  //           <button type="button" className="accept">
  //             <AcceptBorderedIcon color="#666666" size="20" />
  //           </button>
  //         </Tooltip>

  //         <Tooltip content="Reject" direction="bottom">
  //           <button type="button" className="reject">
  //             <RejectBorderedIcon color="#666666" />
  //           </button>
  //         </Tooltip>
  //       </div>
  //     );
  //   },
  // },
];

export const shipmentRoutingColumns = [
  {
    Header: 'POL',
    accessor: 'pol',
  },
  {
    Header: 'POD',
    accessor: 'pod',
  },
  {
    Header: 'IMO#',
    accessor: 'imoNumber',
  },
  {
    Header: 'Vessel Name',
    accessor: 'vesselname',
  },
  {
    Header: 'Voyage',
    accessor: 'voyage',
  },
  {
    Header: 'Carrier',
    accessor: 'carrier',
  },
  {
    Header: 'ETA',
    accessor: 'eta',
  },
  {
    Header: 'CarrierCode',
    accessor: 'carrierCode',
  },
  {
    Header: 'Flight No',
    accessor: 'flightNo',
  },
];

export const shipmentContainerColumns = [
  {
    Header: 'Container #',
    accessor: 'description',
  },
];

const orderItemProposeChangeColumns = (setNewValue, setIsError) => {
  const columnData = [
    {
      Header: () => {
        const [t] = useTranslation('manageorders');
        return <>{t('manageordersscreen.proposechanges.orderitem')}</>;
      },
      accessor: 'itemCode',
    },
    {
      Header: () => {
        const [t] = useTranslation('manageorders');
        return <>{t('manageordersscreen.proposechanges.productname')}</>;
      },
      accessor: 'itemName',
    },
    {
      Header: () => {
        const [t] = useTranslation('manageorders');
        return <>{t('manageordersscreen.proposechanges.cargoready')}</>;
      },
      accessor: 'dueDate',
      Cell: (data) => {
        let {
          row: {
            original: { dueDate },
          },
        } = data;
        const {
          row: {
            index,
            original: { errorMessage, ErrorColumn },
          },
        } = data;
        const newDueDate = dueDate.toString().split('/');
        if (newDueDate[1]) {
          dueDate = `${newDueDate[1]}/${newDueDate[0]}/${newDueDate[2]}`;
        }
        return (
          <div
            className={`propose-change-date-picker ${
              ErrorColumn && ErrorColumn.includes('updatedDueDate')
                ? 'error'
                : ''
            }`}
          >
            <DatepickerBox
              selectedDate={dateIsValid(dueDate) && new Date(dueDate)}
              setDate={(e) => {
                setNewValue({
                  values: e,
                  columnName: 'updatedDueDate',
                  index,
                });
              }}
            />
            {ErrorColumn && ErrorColumn.includes('updatedDueDate') && (
              <Tooltip
                content={`${
                  ErrorColumn &&
                  ErrorColumn.includes('updatedDueDate') &&
                  errorMessage.filter((item) => {
                    if (item.updatedDueDate) {
                      return item.updatedDueDate;
                    }
                    return '';
                  })[0].updatedDueDate
                }`}
                direction="right"
              >
                <InfoIcon width="0.694vw" color="#f00" height="$px10" />
              </Tooltip>
            )}
            <CalenderIcon
              color={
                ErrorColumn && ErrorColumn.includes('updatedDueDate')
                  ? '#f00'
                  : ''
              }
            />
          </div>
        );
      },
    },
    {
      Header: () => {
        const [t] = useTranslation('manageorders');
        return <>{t('manageordersscreen.proposechanges.quantity')}</>;
      },
      accessor: 'quantity',
      Cell: (data) => {
        const {
          row: {
            index,
            values: { quantity },
            original: { preQuantity, errorMessage, ErrorColumn },
          },
        } = data;

        return (
          <EditBox
            defaultValue={quantity}
            setNewValue={(e) => {
              const nextVal = Math.trunc(e.target.value);
              setNewValue({
                values: nextVal,
                columnName: 'updatedQuantity',
                index,
              });
              //  }
            }}
            setIsError={setIsError}
            min="1"
            max={preQuantity}
            id="Quantity"
            pattern="[0-9]"
            isError={ErrorColumn && ErrorColumn.includes('updatedQuantity')}
            errorMessage={
              ErrorColumn &&
              ErrorColumn.includes('updatedQuantity') &&
              errorMessage.filter((item) => {
                if (item.updatedQuantity) {
                  return item.updatedQuantity;
                }
                return '';
              })[0].updatedQuantity
            }
          />
        );
      },
    },
    {
      Header: () => {
        const [t] = useTranslation('manageorders');
        return <>{t('manageordersscreen.proposechanges.priceperunit')}</>;
      },
      accessor: 'price',
      Cell: (data) => {
        const {
          row: {
            index,
            original: { price, errorMessage, ErrorColumn },
          },
        } = data;
        return (
          <EditBox
            defaultValue={price}
            setNewValue={(e) => {
              setNewValue({
                values: e.target.value || 0,
                columnName: 'perUnitPrice',
                index,
              });
            }}
            setIsError={setIsError}
            min="1"
            id="PerUnitPrice"
            isError={ErrorColumn && ErrorColumn.includes('perUnitPrice')}
            errorMessage={
              ErrorColumn &&
              ErrorColumn.includes('perUnitPrice') &&
              errorMessage.filter((item) => {
                if (item.perUnitPrice) {
                  return item.perUnitPrice;
                }
                return '';
              })[0].perUnitPrice
            }
          />
        );
      },
    },
    {
      Header: () => {
        const [t] = useTranslation('manageorders');
        return <>{t('manageordersscreen.proposechanges.discount')}</>;
      },
      accessor: 'discountPer',
      Cell: (data) => {
        const {
          row: {
            index,
            values: { discountPer },
            original: { errorMessage, ErrorColumn },
          },
        } = data;
        return (
          <EditBox
            defaultValue={discountPer}
            setNewValue={(e) => {
              setNewValue({
                values: e.target.value || 0,
                columnName: 'updatedDiscount',
                index,
              });
            }}
            setIsError={setIsError}
            min="0"
            id="DiscountPercentage"
            isError={ErrorColumn && ErrorColumn.includes('updatedDiscount')}
            errorMessage={
              ErrorColumn &&
              ErrorColumn.includes('updatedDiscount') &&
              errorMessage.filter((item) => {
                if (item.updatedDiscount) {
                  return item.updatedDiscount;
                }
                return '';
              })[0].updatedDiscount
            }
          />
        );
      },
    },
    {
      Header: () => {
        const [t] = useTranslation('manageorders');
        return <>{t('manageordersscreen.proposechanges.totalprice')}</>;
      },
      accessor: 'total',
      Cell: (data) => {
        const {
          row: {
            index,
            values: { total },
            original: { errorMessage, ErrorColumn },
          },
        } = data;
        return (
          <EditBox
            defaultValue={total}
            setNewValue={(e) => {
              if (e.target.value) {
                setNewValue({
                  values: e.target.value,
                  columnName: 'updatedTotal',
                  index,
                });
              }
            }}
            setIsError={setIsError}
            min="0"
            id="Total"
            isError={ErrorColumn && ErrorColumn.includes('updatedTotal')}
            errorMessage={
              ErrorColumn &&
              ErrorColumn.includes('updatedTotal') &&
              errorMessage.filter((item) => {
                if (item.updatedTotal) {
                  return item.updatedTotal;
                }
                return '';
              })[0].updatedTotal
            }
          />
        );
      },
    },

    {
      Header: '',
      accessor: 'reset',
      Cell: (data) => {
        const {
          row: {
            index,
            original: { _id, isUndo },
          },
        } = data;

        return (
          <IconButton
            className="button-transparent reset"
            onClick={() => {
              setNewValue({
                values: _id,
                columnName: 'reset',
                index,
              });
            }}
            disabled={!isUndo}
          >
            <UndoIcon />
          </IconButton>
        );
      },
    },
  ];
  return columnData;
};
export { orderItemProposeChangeColumns };

export const itemizedOrderSublineItemColumns = [
  {
    Header: 'Order Item#',
    accessor: 'itemCode',
  },
  {
    Header: 'Product Name',
    accessor: 'itemName',
  },
  {
    Header: 'Impex PO#',
    accessor: 'itemNumber',
  },
  {
    Header: 'Quantity',
    accessor: 'quantity',
    Cell: (data) => {
      const {
        cell: { row },
      } = data;
      return (
        <QuantityCard
          quantityObj={row.original.quantityObj ? row.original.quantityObj : []}
        />
      );
    },
  },
  {
    Header: 'Status',
    accessor: 'statusName',
    Cell: (data) => {
      const {
        cell: { row },
      } = data;
      return <ListingStatus statusLabel={row.original.statusName} />;
    },
  },
  ...(falseValue
    ? [
        {
          Header: <TimezoneIcon width="1.25vw" height="1.25vw" />,
          accessor: 'timeline',
          Cell: (data) => {
            const {
              cell: { row },
            } = data;
            return <TimeLog data={row.original.timeline} />;
          },
        },
      ]
    : []),
];

export const packageInformationColumn = [
  {
    Header: 'Package type',
    accessor: 'packageType',
  },
  {
    Header: 'Quantity',
    accessor: 'quantity',
    Cell: (data) => {
      const {
        cell: { row },
      } = data;
      return <NumberCard value={row?.original?.quantity} status="primary" />;
    },
  },
  {
    Header: 'Container#',
    accessor: 'containerNumber',
  },
  {
    Header: 'Dimensions (LXWXH)',
    accessor: 'dimensions',
  },
  {
    Header: 'Gross Weight ',
    accessor: 'grossWeight',
  },
  {
    Header: 'Line Items',
    accessor: '',
    align: 'center',
    Cell: (data) => {
      const [eventValue, setEventValue] = useState();
      const togglePopover = (e) => {
        setEventValue(e.target);
      };
      const handleClose = () => {
        setEventValue(null);
      };
      const {
        cell: {
          row: {
            original: { lineItems },
          },
        },
      } = data;
      const [t] = useTranslation('manageorders');
      return (
        <>
          <button
            className="item-selected-button"
            type="button"
            onClick={togglePopover}
            id="countPopover"
          >
            {lineItems?.length} {t('manageordersscreen.lineItembtn.itembtn')}
          </button>
          <AdvancePopover
            reference="countPopover"
            getEvent={eventValue}
            closePopover={handleClose}
            placement="left"
          >
            <BesicTable columns={lineItemColumns()} data={lineItems} />
          </AdvancePopover>
        </>
      );
    },
  },
];

export const supplierBookingNewColumns = ({
  setNewValue,
  packageOptions,
  handleAddPackage,
  selectProduct,
}) => {
  const columnData = [
    {
      Header: () => {
        const [t] = useTranslation('manageorders');
        return (
          <span className="no-wrap">
            {t('manageordersscreen.sendbookingdetails.orderitem')}
          </span>
        );
      },
      align: 'center',
      accessor: 'itemCode',
    },
    {
      Header: () => {
        const [t] = useTranslation('manageorders');
        return (
          <span className="no-wrap">
            {t('manageordersscreen.sendbookingdetails.productname')}
          </span>
        );
      },
      align: 'center',
      accessor: 'itemName',
    },
    {
      Header: () => {
        const [t] = useTranslation('manageorders');
        return (
          <span className="no-wrap">
            {t('manageordersscreen.sendbookingdetails.quantity')}
          </span>
        );
      },
      align: 'center',
      accessor: 'quantity',
      Cell: (data) => {
        const {
          cell: { row },
        } = data;
        const [t] = useTranslation('manageorders');
        return (
          <div className="d-flex justify-center">
            <NumberCard
              value={row?.original?.quantity}
              status="primary"
              content={t('manageordersscreen.order.tooltip.total')}
            />

            <NumberCard
              value={row?.original?.cargoQty}
              status="success"
              content={t('manageordersscreen.order.tooltip.processed')}
            />
            <NumberCard
              value={row?.original?.bookedQty}
              status="warning"
              content={t('manageordersscreen.order.tooltip.booked')}
            />
            <NumberCard
              value={row?.original?.pendingQty || 0}
              content={t('manageordersscreen.order.tooltip.pending')}
            />
          </div>
        );
      },
    },
    {
      Header: () => {
        const [t] = useTranslation('manageorders');
        return (
          <span className="no-wrap">
            {t('manageordersscreen.sendbookingdetails.dimensions')}
          </span>
        );
      },
      align: 'center',
      accessor: 'dimensions',
      Cell: (data) => {
        const { row } = data;
        return (
          <span className="no-wrap text-center d-block">
            {row?.original?.LXWXH}
          </span>
        );
      },
    },
    {
      Header: () => {
        const [t] = useTranslation('manageorders');
        return (
          <span className="no-wrap">
            {t('manageordersscreen.sendbookingdetails.cargoready')}
          </span>
        );
      },
      align: 'center',
      accessor: 'dueDate',
    },
    {
      Header: () => {
        const [t] = useTranslation('manageorders');
        return (
          <span className="no-wrap">
            {t('manageordersscreen.sendbookingdetails.quantitytoship')}
          </span>
        );
      },
      align: 'center',
      accessor: 'quantityToShip',
      Cell: (data) => {
        const { row } = data;
        const {
          row: {
            index,
            // original: { errorMessage, ErrorColumn },
          },
        } = data;

        return (
          <EditBox
            eanbleTextBox
            className="quantity-box"
            defaultValue={
              row?.original?.quantityToShip ||
              Number(row?.original?.cargoQty) -
                Number(row.original?.bookedQty) ||
              ''
            }
            setNewValue={(e) => {
              // if (e.target.value) {
              setNewValue({
                values: e.target.value,
                columnName: 'quantityToShip',
                index,
              });
              // }
            }}
            min="1"
            max={
              Number(row?.original?.cargoQty) - Number(row.original.bookedQty)
            }
            id="quantityToShip"
            errorMessage={`Quantity to ship must be less than or equal to ${
              Number(row?.original?.cargoQty) - Number(row.original.bookedQty)
            }`}
            errorLogic={
              Number(row?.original?.cargoQty) - Number(row.original.bookedQty)
            }
            name="quantityToShip"
          />
        );
      },
    },
    {
      Header: () => {
        const [t] = useTranslation('manageorders');
        return (
          <span className="no-wrap">
            {t('manageordersscreen.sendbookingdetails.grossweight')}
          </span>
        );
      },
      align: 'center',
      accessor: 'grossWeight',
      Cell: (data) => {
        const { row } = data;
        const {
          row: {
            index,
            original: {
              // errorMessage,
              ErrorColumn,
            },
          },
        } = data;
        return (
          <EditBox
            eanbleTextBox
            className="quantity-box"
            defaultValue={
              row?.original?.grossWeight || row?.original?.defaultGrossWeight
            }
            setNewValue={(e) => {
              // if (e.target.value) {
              setNewValue({
                values: e.target.value,
                columnName: 'grossWeight',
                index,
              });
              // }
            }}
            // reValue={3}
            // onChange={(e) => {
            //   if (e?.target?.value) {
            //     setNewValue({
            //       values: e.target.value,
            //       columnName: 'grossWeight',
            //       index,
            //     });
            //   }
            // }}
            // setIsError={setIsError}
            min="1"
            id="grossWeight"
            isError={ErrorColumn && ErrorColumn.includes('grossWeight')}
            // errorMessage={
            //   ErrorColumn &&
            //   ErrorColumn.includes('grossWeight') &&
            //   errorMessage.filter((item) => {
            //     if (item?.grossWeight) {
            //       return item?.grossWeight;
            //     }
            //     return '';
            //   })[0]?.grossWeight
            // }
            name="grossWeight"
          />
        );
      },
    },
    {
      Header: () => {
        const [t] = useTranslation('manageorders');
        return (
          <span className="no-wrap">
            {t('manageordersscreen.sendbookingdetails.package')}
          </span>
        );
      },
      accessor: 'package',
      align: 'center',
      setWidth: '170px',
      Cell: (data) => {
        const { row } = data;
        const {
          row: {
            index,
            // original: { _id },
          },
        } = data;
        let defaultValue = row?.original?.package?.label;
        packageOptions?.forEach((obj) => {
          if (obj?.value === row?.original?.package?.value && obj?.rename) {
            if (obj?.rename?.length > 10) {
              defaultValue = `${obj?.rename?.slice(0, 11)}...`;
            } else {
              defaultValue = obj?.rename;
            }
          }
        });
        const [t] = useTranslation('manageorders');
        return (
          <div className="d-inline-flex">
            <SearchSelect
              t={t}
              data={packageOptions}
              onClick={handleAddPackage}
              onChange={(e) => {
                setNewValue({
                  values: {
                    label: e?.target?.value,
                    value: e?.target?.value,
                  },
                  columnName: 'package',
                  index,
                });
              }}
              label={t(
                'manageordersscreen.sendbookingdetails.packagetype.title'
              )}
              defaultValue={defaultValue}
              selectProduct={selectProduct}
            />
            {/* <select
              className="supplier-select-package"
              value={row?.original?.package?.value || ''}
              onChange={(e) => {
                handlePackageChange(
                  row?.original?._id,
                  {
                    label: e?.target?.value,
                    value: e?.target?.value,
                  },
                  row?.original?.itemCode
                );
                setNewValue({
                  values: {
                    label: e?.target?.value,
                    value: e?.target?.value,
                  },
                  columnName: 'package',
                  index,
                });
              }}
            >
              <option value="">Package type</option>
              {packageOptions?.map((option) => (
                <option value={option?.value}>
                  {option?.rename || option?.label}
                </option>
              ))}
            </select> */}
            {/* <Button size="icon" className="p-0 ml-10" icon={<EditIcon />} /> */}
          </div>
        );
      },
    },
  ];
  return columnData;
};

export const supplierBulkBookingColumns = ({
  setNewValue,
  packageOptions,
  handleAddPackage,
  selectProduct,
  bulk,
}) => {
  const columnData = [
    {
      Header: () => {
        const [t] = useTranslation('manageorders');
        return <>{t('manageordersscreen.sendbookingdetails.orderitem')}</>;
      },
      align: 'center',
      accessor: 'itemCode',
    },
    {
      // Header: () => {
      //   const [t] = useTranslation('manageorders');
      //   return <>{t('manageordersscreen.sendbookingdetails.orderitem')}</>;
      // },
      Header: () => {
        const [t] = useTranslation('manageorders');
        return <>{t('manageordersscreen.sendbookingdetails.orderid')}</>;
      },
      align: 'center',
      accessor: 'poNumber',
    },
  ];

  if (bulk === 'bulkTab1') {
    columnData?.push(
      {
        Header: () => {
          const [t] = useTranslation('manageorders');
          return <>{t('manageordersscreen.sendbookingdetails.productname')}</>;
        },
        align: 'center',
        accessor: 'itemName',
      },
      {
        Header: () => {
          const [t] = useTranslation('manageorders');
          return <>{t('manageordersscreen.sendbookingdetails.cargoready')}</>;
        },
        align: 'center',
        accessor: 'cargoReadyDate',
        type: 'date',
        Cell: (data) => {
          const {
            cell: {
              row: {
                original: { dueDate },
              },
            },
          } = data;
          return getUserTimeZoneDateFormat(dueDate);
        },
      },
      {
        Header: () => {
          const [t] = useTranslation('manageorders');
          return <>{t('manageordersscreen.sendbookingdetails.quantity')}</>;
        },
        align: 'center',
        accessor: 'quantity',
        Cell: (data) => {
          const {
            cell: { row },
          } = data;
          const [t] = useTranslation('manageorders');
          return (
            <div className="d-flex justify-center">
              <NumberCard
                value={row?.original?.quantity}
                status="primary"
                content={t(
                  'manageordersscreen.sendbookingdetails.tooltip.total'
                )}
              />

              <NumberCard
                value={row?.original?.cargoQty}
                status="success"
                content={t(
                  'manageordersscreen.sendbookingdetails.tooltip.processed'
                )}
              />
              <NumberCard
                value={row?.original?.bookedQty}
                status="warning"
                content={t(
                  'manageordersscreen.sendbookingdetails.tooltip.booked'
                )}
              />
              <NumberCard
                value={row?.original?.pendingQty || 0}
                content={t(
                  'manageordersscreen.sendbookingdetails.tooltip.pending'
                )}
              />
            </div>
          );
        },
      },

      {
        align: 'center',
        Header: () => {
          const [t] = useTranslation('manageorders');
          return <>{t('manageordersscreen.sendbookingdetails.status')}</>;
        },
        accessor: 'statusName',
        Cell: (data) => {
          const {
            cell: { row },
          } = data;

          return <ListingStatus statusLabel={row.original.statusName} />;
        },
      }
    );
  }

  if (bulk === 'bulkTab2') {
    columnData?.push(
      {
        Header: () => {
          const [t] = useTranslation('manageorders');
          return <>{t('manageordersscreen.sendbookingdetails.dimensions')}</>;
        },
        accessor: 'dimensions',
        align: 'center',
        Cell: (data) => {
          const { row } = data;
          return (
            <span className="no-wrap text-center d-block">
              {row?.original?.LXWXH}
            </span>
          );
        },
      },
      {
        Header: () => {
          const [t] = useTranslation('manageorders');
          return (
            <>{t('manageordersscreen.sendbookingdetails.quantitytoship')}</>
          );
        },
        align: 'center',
        accessor: 'quantityToShip',
        Cell: (data) => {
          const { row } = data;
          const {
            row: {
              index,
              // original: { errorMessage, ErrorColumn },
            },
          } = data;

          return (
            <EditBox
              eanbleTextBox
              className="quantity-box"
              defaultValue={
                row?.original?.quantityToShip ||
                Number(row?.original?.cargoQty) -
                  Number(row.original?.bookedQty) ||
                ''
              }
              setNewValue={(e) => {
                setNewValue({
                  values: e.target.value,
                  columnName: 'quantityToShip',
                  index,
                });
              }}
              min="1"
              max={
                Number(row?.original?.cargoQty) - Number(row.original.bookedQty)
              }
              id="quantityToShip"
              errorMessage={`Quantity to ship must be less than or equal to ${
                Number(row?.original?.cargoQty) - Number(row.original.bookedQty)
              }`}
              errorLogic={
                Number(row?.original?.cargoQty) - Number(row.original.bookedQty)
              }
              name="quantityToShip"
            />
          );
        },
      },
      {
        Header: () => {
          const [t] = useTranslation('manageorders');
          return <>{t('manageordersscreen.sendbookingdetails.grossweight')}</>;
        },
        align: 'center',
        accessor: 'grossWeight',
        Cell: (data) => {
          const { row } = data;
          const {
            row: {
              index,
              original: { ErrorColumn },
            },
          } = data;
          return (
            <EditBox
              eanbleTextBox
              className="quantity-box text-center"
              defaultValue={
                row?.original?.grossWeight || row?.original?.defaultGrossWeight
              }
              setNewValue={(e) => {
                setNewValue({
                  values: e.target.value,
                  columnName: 'grossWeight',
                  index,
                });
              }}
              min="1"
              id="grossWeight"
              isError={ErrorColumn && ErrorColumn.includes('grossWeight')}
              name="grossWeight"
            />
          );
        },
      },
      {
        Header: () => {
          const [t] = useTranslation('manageorders');
          return <>{t('manageordersscreen.sendbookingdetails.package')}</>;
        },
        accessor: 'package',
        align: 'center',
        setWidth: '170px',
        Cell: (data) => {
          const { row } = data;
          const {
            row: { index },
          } = data;
          let defaultValue = row?.original?.package?.label;
          packageOptions?.forEach((obj) => {
            if (obj?.value === row?.original?.package?.value && obj?.rename) {
              if (obj?.rename?.length > 10) {
                defaultValue = `${obj?.rename?.slice(0, 11)}...`;
              } else {
                defaultValue = obj?.rename;
              }
            }
          });
          const [t] = useTranslation('manageorders');
          return (
            <div className="d-inline-flex text-center">
              <SearchSelect
                t={t}
                data={packageOptions}
                onClick={handleAddPackage}
                onChange={(e) => {
                  setNewValue({
                    values: {
                      label: e?.target?.value,
                      value: e?.target?.value,
                    },
                    columnName: 'package',
                    index,
                  });
                }}
                label={t(
                  'manageordersscreen.sendbookingdetails.packagetype.title'
                )}
                defaultValue={defaultValue}
                selectProduct={selectProduct}
              />
            </div>
          );
        },
      }
    );
  }

  return columnData;
};

export const associatedShipmentsColumn = [
  {
    Header: 'Shipment#',
    accessor: 'shipmentNo',
    Cell: (data) => {
      const {
        row,
        row: {
          values: { shipmentNo },
        },
      } = data;
      const { relation, companyId } = useParams();
      return (
        <Link
          to={`/app/${relation}/${companyId}/shipment-details/${row.original._id}`}
        >
          {shipmentNo}
        </Link>
      );
    },
  },
  {
    Header: 'Mode',
    accessor: 'mode',
    Cell: (data) => {
      const {
        cell: { row },
      } = data;
      return (
        <ShipmentMode
          shipmentMode={row.original.mode}
          bookingType={row.original.bookingType}
        />
      );
    },
  },
  // {
  //   Header: 'Delivery Date',
  //   accessor: 'ETA',
  // },
  {
    Header: 'Origin',
    accessor: 'originFacility',
  },
  {
    Header: 'Destination',
    accessor: 'destinationFacility',
  },
  {
    Header: 'Status',
    accessor: 'currentStatus',
    align: 'center',
    Cell: (data) => {
      const {
        cell: {
          row: {
            original: { currentStatus },
          },
        },
      } = data;
      return <ListingStatus statusLabel={currentStatus} />;
    },
  },
];

export const cargoRequestColumns = (orderListRefetch) => {
  const { relation } = useParams();
  return [
    {
      Header: () => {
        const [t] = useTranslation('manageorders');
        return <>{t('manageordersscreen.cargorequesttab.cargorequest')}</>;
      },
      accessor: 'referenceId',
      align: 'center',
    },
    {
      Header: () => {
        const [t] = useTranslation('manageorders');
        return <>{t('manageordersscreen.cargorequesttab.orders')}</>;
      },
      accessor: 'orders',
      align: 'center',
      Cell: (data) => {
        const {
          cell: {
            row: {
              original: { packages, bulkOrderIds },
            },
          },
        } = data;
        const lineItemsData = [];
        packages?.forEach((pkg) =>
          pkg?.lineItems?.forEach((item) => {
            lineItemsData?.push({
              orderId: item?.orderId,
              order: item?.poNumber,
              orderItem: item?.itemCode,
              qtyShipped: item?.qtyShipped,
              orderStatus: item?.statusName,
              cargoReady: getUserTimeZoneDateFormat(item?.dueDate),
            });
          })
        );
        const [t] = useTranslation('manageorders');
        return (
          <CountCard
            data={lineItemsData}
            label={
              bulkOrderIds?.length === 1
                ? `${bulkOrderIds?.length} ${t(
                    'manageordersscreen.cargorequesttab.order'
                  )}`
                : `${bulkOrderIds?.length || 0} ${t(
                    'manageordersscreen.cargorequesttab.orders'
                  )}`
            }
            enableDynamicTable={true}
            columns={BookListOrder2}
            disableBorder
            align="center"
          />
        );
      },
    },
    {
      Header: () => {
        const [t] = useTranslation('manageorders');
        return <>{t('manageordersscreen.cargorequesttab.mode')}</>;
      },
      accessor: 'mode',
      align: 'center',
      Cell: (data) => {
        const {
          cell: {
            row: {
              original: { mode },
            },
          },
        } = data;
        return mode ? (
          <ShipmentMode shipmentMode={mode} align="center" />
        ) : (
          '--'
        );
      },
    },
    {
      Header: () => {
        const [t] = useTranslation('manageorders');
        return <>{t('manageordersscreen.cargorequesttab.bookingdate')}</>;
      },
      accessor: 'bookingDate',
      align: 'center',
      Cell: (data) => {
        const {
          cell: {
            row: {
              original: { bookingDate },
            },
          },
        } = data;
        return getUserTimeZoneDateFormat(bookingDate);
      },
    },
    {
      Header: () => {
        const [t] = useTranslation('manageorders');
        return <>{t('manageordersscreen.cargorequesttab.origin')}</>;
      },
      accessor: 'originFacility',
      align: 'center',
    },
    {
      Header: () => {
        const [t] = useTranslation('manageorders');
        return <>{t('manageordersscreen.cargorequesttab.destination')}</>;
      },
      accessor: 'destinationFacility',
      align: 'center',
    },
    {
      Header: () => {
        const [t] = useTranslation('manageorders');
        return <>{t('manageordersscreen.cargorequesttab.owner')}</>;
      },
      accessor: 'owner',
      align: 'center',
      Cell: (data) => {
        const {
          cell: {
            row: {
              original: { owner },
            },
          },
        } = data;
        return giveProfile(relation) === owner
          ? 'Self'
          : `${owner?.charAt(0)?.toUpperCase()}${owner?.slice(1)}`;
      },
    },
    {
      Header: () => {
        const [t] = useTranslation('manageorders');
        return <>{t('manageordersscreen.cargorequesttab.status')}</>;
      },
      accessor: 'currentStatus',
      align: 'center',
      Cell: (data) => {
        const {
          cell: {
            row: {
              original: { currentStatus },
            },
          },
        } = data;
        return <ListingStatus statusLabel={currentStatus} />;
      },
    },
    ...(checkPermission(giveProfile(relation), 'Booking', 'createBooking') ||
    checkPermission(giveProfile(relation), 'Booking', 'createBooking', 'V')
      ? [
          {
            Header: () => {
              const [t] = useTranslation('manageorders');
              return <>{t('manageordersscreen.Bookings.actions')}</>;
            },
            accessor: 'actions',
            align: 'center',
            Cell: (data) => {
              const {
                isShowing: isViewBookingDetailsDislog,
                toggle: toggleViewDetailsModal,
              } = useDialog();
              const {
                cell: { row },
              } = data;
              const [searchParams, setSearchParams] = useSearchParams();
              const location = useLocation();
              const popover = searchParams.get('popover');
              const popId = searchParams.get('popId');
              useEffect(() => {
                const checkURLParamsAndOpenDialog = () => {
                  if (
                    popover === 'Create Booking' &&
                    !isViewBookingDetailsDislog &&
                    popId === row?.original?._id
                  ) {
                    // popover=Create+Booking&popId=170922967377830106
                    toggleViewDetailsModal();
                  }
                };
                checkURLParamsAndOpenDialog();
              }, [location.search]);
              return (
                <div className="d-flex justify-center">
                  {row?.original?.bookingAction?.addForwarder && (
                    <>
                      <IconButton
                        onClick={() => {
                          // toggleViewDetailsModal();
                          if (
                            searchParams.get('popover') !== 'Create Booking' &&
                            !location.search?.includes('Create+Booking')
                          ) {
                            searchParams.set('popover', 'Create Booking');
                            setSearchParams(searchParams);
                          }
                          if (
                            searchParams.get('popId') !== row?.original?._id &&
                            !location.search?.includes('popId')
                          ) {
                            searchParams.set('popId', row?.original?._id);
                            setSearchParams(searchParams);
                          }
                        }}
                        verient="grey"
                        disabled={
                          !checkPermission(
                            giveProfile(relation),
                            'Booking',
                            'createBooking'
                          )
                        }
                      >
                        <BoxIcon />
                      </IconButton>
                      <Dialog
                        isShowing={isViewBookingDetailsDislog}
                        hide={() => {
                          toggleViewDetailsModal();
                          searchParams.delete('popover');
                          searchParams.delete('popId');
                          setSearchParams(searchParams);
                        }}
                        placement="center"
                        // enableOutsideClick
                        enableCloseIcon
                      >
                        <BuyerBooking
                          columns={buyerBookingDetailsColumns()}
                          itemsData={row?.original}
                          toggleViewDetailsModal={toggleViewDetailsModal}
                          isBulk={true}
                          orderListRefetch={orderListRefetch}
                        />
                      </Dialog>
                    </>
                  )}
                  {!row?.original?.bookingAction?.addForwarder && (
                    <>
                      <IconButton
                        onClick={() => {
                          // toggleViewDetailsModal();
                          if (
                            searchParams.get('popover') !== 'Create Booking' &&
                            !location.search?.includes('Create+Booking')
                          ) {
                            searchParams.set('popover', 'Create Booking');
                            setSearchParams(searchParams);
                          }
                          if (
                            searchParams.get('popId') !== row?.original?._id &&
                            !location.search?.includes('popId')
                          ) {
                            searchParams.set('popId', row?.original?._id);
                            setSearchParams(searchParams);
                          }
                        }}
                        verient="grey"
                      >
                        <EditReceiptIcon />
                      </IconButton>

                      <Dialog
                        isShowing={isViewBookingDetailsDislog}
                        hide={() => {
                          toggleViewDetailsModal();
                          searchParams.delete('popover');
                          searchParams.delete('popId');
                          setSearchParams(searchParams);
                        }}
                        placement="center"
                        // enableOutsideClick
                        enableCloseIcon
                      >
                        <ViewBooking
                          id={row?.original?._id}
                          columns={buyerBookingDetailsColumns()}
                          itemsData={row?.original?.packages}
                        />
                      </Dialog>
                    </>
                  )}
                </div>
              );
            },
          },
        ]
      : []),
  ];
};
