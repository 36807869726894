import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';

function ConfirmEntity({
  notificationDetail,
  setNotificationDetail,
  consigneeDetail,
  setConsigneeDetail,
  buyerDetails,
  supplierDetails,
}) {
  const [t] = useTranslation('manageorders');
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div className="confirm-entity-item">
        <h4 className="title">
          {t(
            'manageordersscreen.createbookingmenu.confirmentities.buyerdetails'
          )}
        </h4>
        <p className="description">{ReactHtmlParser(buyerDetails)}</p>
      </div>
      <div className="confirm-entity-item">
        <h4 className="title">
          {t(
            'manageordersscreen.createbookingmenu.confirmentities.supplierdetails'
          )}
        </h4>
        <p className="description">{ReactHtmlParser(supplierDetails)}</p>
      </div>
      <div className="confirm-entity-item">
        <h4 className="title">
          {t(
            'manageordersscreen.createbookingmenu.confirmentities.notifyparty'
          )}
        </h4>
        <textarea
          name="notificationDetail"
          id="notificationDetail"
          rows="4"
          cols="50"
          maxLength="500"
          className="description"
          onChange={(e) => setNotificationDetail(e.target.value)}
        >
          {notificationDetail}
        </textarea>
      </div>
      <div className="confirm-entity-item">
        <h4 className="title">
          {t(
            'manageordersscreen.createbookingmenu.confirmentities.consigneedetails'
          )}
        </h4>
        <textarea
          id="consigneeDetail"
          name="consigneeDetail"
          rows="4"
          cols="50"
          maxLength="500"
          className="description"
          onChange={(e) => setConsigneeDetail(e.target.value)}
        >
          {consigneeDetail}
        </textarea>

        {/* <input type="text" className="description" /> */}
      </div>
    </div>
  );
}
ConfirmEntity.propTypes = {
  notificationDetail: PropTypes.string,
  setNotificationDetail: PropTypes.func,
  consigneeDetail: PropTypes.string,
  setConsigneeDetail: PropTypes.func,
  supplierDetails: PropTypes.string,
  buyerDetails: PropTypes.string,
};
ConfirmEntity.defaultProps = {
  notificationDetail: '',
  setNotificationDetail: () => {},
  consigneeDetail: '',
  setConsigneeDetail: () => {},
  supplierDetails: '',
  buyerDetails: '',
};
export default ConfirmEntity;
