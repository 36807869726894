import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  CalenderIcon,
  PricePerUnitIcon,
  TimezoneIcon,
  DiscountIcon,
  QuantityIcon,
} from '../icons/icons';
import PanelCard from '../panel-card/panel-card';
import AdvancePopover from '../advance-popover/advance-popover';
import './time-log.scss';
import UserImage from '../user-image/user-image';
import ListingStatus from '../listing-status/listing-status';
import {
  getUserTimeZoneTiDate,
  getUserTimeZoneTime,
  getUserTimeZoneDateFormat,
} from '../../helpers/dateHelper';

function TimeLog({ data }) {
  const [t] = useTranslation('manageorders');
  const [eventValue, setEventValue] = useState();
  const togglePopover = (e) => {
    setEventValue(e.target);
  };

  const handleClose = () => {
    setEventValue(null);
  };
  return (
    <div className="time-log">
      <button
        type="button"
        className="log-button"
        onClick={togglePopover}
        id="dateHistoryPopover"
        disabled={data.length === 0}
      >
        <TimezoneIcon color="#45B4D0" />
      </button>
      <AdvancePopover
        reference="dateHistoryPopover"
        getEvent={eventValue}
        closePopover={handleClose}
        placement="left"
      >
        <div className="time-log-logs">
          <PanelCard cardTitle={t('manageordersscreen.order.changetimeline')}>
            <div className="time-log-pop">
              {data?.map((value) => {
                return (
                  <ul className="time-log-list">
                    <span className="date-group">
                      {value.updatedAt
                        ? getUserTimeZoneTiDate(value.updatedAt)
                        : getUserTimeZoneTiDate(value.createdAt)}
                    </span>
                    {value &&
                      value?.changeLog?.map((val) => {
                        return (
                          <div className="reason-block">
                            {val?.reason && (
                              <p className="reason">
                                <span>
                                  {t('manageordersscreen.order.reason')}-
                                </span>{' '}
                                {val?.reason}
                              </p>
                            )}
                            {val?.reasonLog?.map((va) => {
                              return (
                                <li>
                                  <span className="circle">
                                    {va?.parameter === 'Due date change' && (
                                      <CalenderIcon color="#ffffff" />
                                    )}
                                    {(va?.parameter ===
                                      'Price per unit change' ||
                                      va?.parameter ===
                                        'Total price change') && (
                                      <PricePerUnitIcon />
                                    )}
                                    {va?.parameter === 'Discount change' && (
                                      <DiscountIcon />
                                    )}
                                    {va?.parameter === 'Quantity change' && (
                                      <QuantityIcon />
                                    )}
                                  </span>
                                  <div className="details">
                                    <div className="description">
                                      <UserImage
                                        name={`${value?.userData?.createdBy?.firstName} ${value?.userData?.createdBy?.lastName}`}
                                        path={
                                          value?.userData?.createdBy
                                            ?.profilePicture
                                        }
                                        removeTooltip
                                        type="square"
                                      />
                                      <div className="info">
                                        <p className="msg-description">
                                          {t(
                                            `manageordersscreen.order.${va.parameter}`
                                          )}
                                          <span>
                                            {va?.parameter === 'Due date change'
                                              ? getUserTimeZoneDateFormat(
                                                  va?.oldData
                                                )
                                              : va?.oldData}
                                            {va?.parameter ===
                                              'Price per unit change' ||
                                            va?.parameter ===
                                              'Total price change'
                                              ? '$'
                                              : ''}
                                            {va?.parameter === 'Discount change'
                                              ? '%'
                                              : ''}{' '}
                                            {t('manageordersscreen.order.to')}{' '}
                                            {va?.parameter === 'Due date change'
                                              ? getUserTimeZoneDateFormat(
                                                  va?.newData
                                                )
                                              : va?.newData}
                                            {va?.parameter ===
                                              'Price per unit change' ||
                                            va?.parameter ===
                                              'Total price change'
                                              ? '$'
                                              : ''}
                                            {va?.parameter === 'Discount change'
                                              ? '%'
                                              : ''}
                                          </span>
                                        </p>
                                      </div>
                                    </div>
                                    <p className="text">
                                      {t('manageordersscreen.order.by')}{' '}
                                      {`${value?.userData?.createdBy?.firstName} ${value?.userData?.createdBy?.lastName}`}
                                      ,{' '}
                                      <span>
                                        {t('manageordersscreen.order.at')}{' '}
                                        {getUserTimeZoneTime(val?.createdAt)}
                                      </span>
                                    </p>
                                  </div>
                                  <div className="status">
                                    <ListingStatus
                                      statusLabel={va?.currentStatus}
                                    />
                                  </div>
                                </li>
                              );
                            })}
                          </div>
                        );
                      })}
                  </ul>
                );
              })}
            </div>
            {/* );
            })} */}
          </PanelCard>
        </div>
      </AdvancePopover>
    </div>
  );
}

export default TimeLog;
TimeLog.propTypes = {
  data: PropTypes.arrayOf(Object),
};
TimeLog.defaultProps = {
  data: [],
};
