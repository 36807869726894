import { gql } from '@apollo/client';

export const SEARCH_COMPANY_GQL = gql`
  query SearchCompany($search: String!) {
    SearchCompany(search: $search) {
      data {
        orgName
        orgEmail
        orgAddress
        orgCity
        orgState
        orgZip
        orgPhone
        orgCountry
        orgWebsite
        orgLogo
        orgId
        orgType
      }
    }
  }
`;

export const UPDATE_ORG_RELATIONSHIP = gql`
  mutation UpdateOrgRelationship($input: orgRelationshipStatusInput!) {
    UpdateOrgRelationShip(input: $input) {
      message
    }
  }
`;

export const INVITE_ORGANIZATION_PARTNER = gql`
  mutation Mutation($input: inviteOrgPartnerInput) {
    InviteOrgPartner(input: $input) {
      msg
      data {
        orgName
        _id
        orgId
        orgStatus
        orgDomains
        orgEntities
        orgAddress
        orgCity
        orgState
        orgZip
        orgCountry
        orgPhone
        orgFax
        orgEmail
        orgWebsite
        orgPrimaryContact {
          contactName
          contactEmail
          contactRole
          contactCreatedAt
          contactCreatedBy
        }
        orgSecondaryContact {
          contactName
          contactEmail
          contactRole
          contactCreatedAt
          contactCreatedBy
        }
        admins
        users
        keyAdmin
        buyers
        suppliers
        forwarders
        orgLogo
        orgCover
        orgDescription
        orgCreatedBy
        defaultCurrency
      }
    }
  }
`;

export const CREATE_RELATIONSHIP = gql`
  mutation CreateRelationShip($input: relationshipInput!) {
    CreateRelationShip(input: $input) {
      message
    }
  }
`;

export const LIST_BUYER_SUPPLIER_RELATIONSHIP = gql`
  query ListBuyerSupplier($input: OrggDetailsInput) {
    listBuyerSupplier(input: $input) {
      OrganizationList {
        organization {
          orgName
          orgId
          orgEmail
        }
        status
        relationship
        invitedByOrg {
          orgName
          orgId
          orgEmail
        }
      }
    }
  }
`;
