import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { FETCH_ASSIGN_USER_LIST } from '../../graphQL/rolesAndPermissions';
import MultiSelect from '../../Components/advance-multi-select';
import GlobalLoader from '../../Components/global-loader/global-loader';
import { giveProfile } from '../../Components/global-notification/notification-list';

function AssignUsers({
  roleId,
  setSelectedContacts,
  selectedContacts,
  sliceVal,
  t,
}) {
  const [emailList, setEmailList] = useState([]);
  const [multiSelectValue, setMultiSelectValue] = useState();
  const { relation } = useParams();

  const { loading } = useQuery(FETCH_ASSIGN_USER_LIST, {
    variables: {
      input: {
        ...(roleId && { roleId }),
        profileType: giveProfile(relation),
      },
    },
    onCompleted: (data) => {
      setEmailList(data?.FetchAssignUserList?.data?.userDetails);
    },
  });

  useEffect(() => {
    if (selectedContacts?.length) {
      setMultiSelectValue(selectedContacts);
    }
  }, []);

  const handleMultiSelectChange = (e) => {
    setMultiSelectValue(e);
    setSelectedContacts(e);
  };

  return !loading ? (
    <MultiSelect
      label={t(
        'systemsettings.roles&permission.actionspopups.assignuserspopup.assignuser'
      )}
      placeholder={t(
        'systemsettings.roles&permission.actionspopups.assignuserspopup.assignuserplaceholder'
      )}
      value={multiSelectValue}
      options={emailList}
      getKey={(item) => String(item?.email)}
      renderOption={(item) => {
        return `${item?.firstName} ${item?.lastName}`;
      }}
      renderValue={(value) => {
        if (!value) {
          return '';
        }
        return `${value?.firstName} ${value?.lastName}`;
      }}
      onChange={(e) => handleMultiSelectChange(e)}
      message={t(
        'systemsettings.roles&permission.actionspopups.assignuserspopup.assignuserdescription'
      )}
      sliceVal={sliceVal}
      disableArrow
    />
  ) : (
    <GlobalLoader newLoad={true} height="6.944vw" width="6.944vw" />
  );
}

export default AssignUsers;

AssignUsers.propTypes = {
  roleId: PropTypes.string,
  setSelectedContacts: PropTypes.func,
  selectedContacts: PropTypes.instanceOf(Array),
  sliceVal: PropTypes.number,
  t: PropTypes.func,
};

AssignUsers.defaultProps = {
  roleId: '',
  setSelectedContacts: () => {},
  selectedContacts: [],
  sliceVal: 1,
  t: () => {},
};
