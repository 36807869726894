import React from 'react';
import PropTypes from 'prop-types';
import './account-settings-panel.scss';

function AccountSettingsPanelDescription({ ...props }) {
  return <p className="description">{props.children}</p>;
}

export default AccountSettingsPanelDescription;
AccountSettingsPanelDescription.propTypes = {
  children: PropTypes.node,
};
AccountSettingsPanelDescription.defaultProps = {
  children: {},
};
