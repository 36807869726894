import React from 'react';
import PropTypes from 'prop-types';
import './dialog-panel.scss';
import { CloseIcon } from '../icons/icons';

export default function DialogPanel({
  className,
  overflow,
  title,
  large,
  onClose,
  enableClose,
  ...props
}) {
  return (
    <div
      className={`dialog-panel ${className}`}
      style={{ overflow }}
      data-cy="dialog-panel"
    >
      {title !== '' && (
        <div className="dialog-panel-header" data-cy="dialog-panel-header">
          <h3
            data-cy="dialog-panel-title"
            className={`dialog-panel-title ${large}`}
          >
            {title}
          </h3>
          {enableClose && (
            <button
              onClick={onClose}
              className="close"
              type="button"
              data-cy="dialog-panel-close"
            >
              <CloseIcon color="#333333" size="1.111vw" />
            </button>
          )}
        </div>
      )}

      {props.children}
    </div>
  );
}
DialogPanel.propTypes = {
  title: PropTypes.string,
  overflow: PropTypes.string,
  large: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  onClose: PropTypes.func,
  enableClose: PropTypes.bool,
};
DialogPanel.defaultProps = {
  title: '',
  overflow: '',
  large: '',
  className: '',
  onClose: () => {},
  enableClose: false,
};
