import React from 'react';
import PropTypes from 'prop-types';
import './custom-switch.scss';

function CustomSwitch({ size, color, className, ...otherProps }) {
  return (
    <label className={`custom-switch ${className} ${size} ${color}`}>
      <input type="checkbox" {...otherProps} />
      <span className="wrapper">
        <span className="switch-bg">
          <span className="ball" />
        </span>
      </span>
    </label>
  );
}

export default CustomSwitch;
CustomSwitch.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
  className: PropTypes.string,
};
CustomSwitch.defaultProps = {
  size: '',
  color: '',
  className: '',
};
