import React from 'react';
import PropTypes from 'prop-types';
import './bar-panel.scss';

function BarPanel({
  title,
  subTitle,
  titleRight,
  subTitleRight,
  children,
  largeTitle,
}) {
  return (
    <div className="bar-panel">
      <div className={`bar-panel-header${largeTitle ? ' align-center' : ''}`}>
        <div className="bar-panel-header-left">
          <h3 className="bar-panel-title">{title}</h3>
          <h4 className={`bar-panel-sub-title${largeTitle ? ' large' : ''}`}>
            {subTitle}
          </h4>
        </div>
        <div className="bar-panel-header-right">
          <h3 className="bar-panel-title">{titleRight}</h3>
          <h4 className="bar-panel-sub-title">{subTitleRight}</h4>
        </div>
      </div>
      <div className={`bar-panel-body${!largeTitle ? ' details' : ''}`}>
        {children}
      </div>
    </div>
  );
}

export default BarPanel;
BarPanel.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  titleRight: PropTypes.string,
  subTitleRight: PropTypes.string,
  children: PropTypes.node,
  largeTitle: PropTypes.bool,
};

BarPanel.defaultProps = {
  title: '',
  subTitle: '',
  titleRight: '',
  subTitleRight: '',
  children: {},
  largeTitle: false,
};
