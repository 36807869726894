import React from 'react';
import Skeleton from '../skeleton/skeleton';
import GlobalLoader from '../global-loader/global-loader';

function GlobalSkeleton() {
  return (
    <div className="wrapper">
      <div className="wrapper-left">
        <div className="g-left-menu">
          <div className="global-left-menu">
            <Skeleton
              skHeight="2.778vw"
              skWidth="2.778vw"
              skMargin="0.625vw 0.694vw 0.556vw"
              skRadius="50%"
              opacity="0.8"
            />
            <Skeleton
              skHeight="2.778vw"
              skWidth="2.778vw"
              skMargin="0 0.694vw 0.972vw"
              skRadius="50%"
              opacity="0.8"
            />
            <Skeleton
              skHeight="0.069vw"
              skWidth="2.083vw"
              skMargin="0.625vw 1.042vw 0"
              opacity="0.3"
            />
            <Skeleton
              skHeight="2.778vw"
              skWidth="2.778vw"
              skMargin="0.903vw 0.694vw 0.417vw"
              skRadius="0.556vw"
              opacity="0.8"
            />
            <Skeleton
              skHeight="2.778vw"
              skWidth="2.778vw"
              skMargin="0 0.694vw 0.417vw"
              skRadius="0.556vw"
              opacity="0.8"
            />
            <Skeleton
              skHeight="2.778vw"
              skWidth="2.778vw"
              skMargin="0 0.694vw 0.417vw"
              skRadius="0.556vw"
              opacity="0.8"
            />
            <Skeleton
              skHeight="2.778vw"
              skWidth="2.778vw"
              skMargin="0 0.694vw 0.417vw"
              skRadius="0.556vw"
              opacity="0.8"
            />
            <Skeleton
              skHeight="2.778vw"
              skWidth="2.778vw"
              skMargin="0 0.694vw 0.417vw"
              skRadius="0.556vw"
              opacity="0.8"
            />
            <Skeleton
              skHeight="2.778vw"
              skWidth="2.778vw"
              skMargin="0 0.694vw 0.417vw"
              skRadius="0.556vw"
              opacity="0.8"
            />
            <Skeleton
              skHeight="2.778vw"
              skWidth="2.778vw"
              skMargin="0 0.694vw 0.417vw"
              skRadius="0.556vw"
              opacity="0.8"
            />
          </div>
        </div>
        <div className="left-menu">
          <div className="left-menu-container">
            <div className="user-section">
              <Skeleton
                skHeight="2.431vw"
                skWidth="2.431vw"
                skMargin="0 1.181vw 0 0"
                skRadius="50%"
              />
              <div className="user-details">
                <Skeleton
                  skHeight="0.833vw"
                  skWidth="70%"
                  skMargin="0.139vw 0 0 0"
                  opacity="0.8"
                />
                <Skeleton
                  skHeight="0.694vw"
                  skWidth="100%"
                  skMargin="0.486vw 0 0.208vw 0"
                  opacity="0.8"
                />
              </div>
            </div>
            <Skeleton
              skHeight="0"
              skWidth="100%"
              skMargin="0 0 0.833vw 0"
              opacity="0.5"
            />
            <div className="global-notification">
              <Skeleton
                skHeight="0.972vw"
                skWidth="60%"
                skMargin="0.486vw 0 0.972vw 10%"
                opacity="0.5"
              />
              <Skeleton
                skHeight="0.972vw"
                skWidth="40%"
                skMargin="0.486vw 0 0.972vw 10%"
                opacity="0.5"
              />
              <Skeleton
                skHeight="0.972vw"
                skWidth="50%"
                skMargin="0.486vw 0 0.972vw 10%"
                opacity="0.5"
              />
              <Skeleton
                skHeight="0.972vw"
                skWidth="80%"
                skMargin="0.486vw 0 0.972vw 10%"
                opacity="0.5"
              />
              <Skeleton
                skHeight="0.972vw"
                skWidth="50%"
                skMargin="0.486vw 0 0.694vw 10%"
                opacity="0.5"
              />
            </div>
            <Skeleton
              skHeight="0.972vw"
              skWidth="60%"
              skMargin="1.181vw 0 0.972vw 10%"
              opacity="0.5"
            />
            <Skeleton
              skHeight="0.972vw"
              skWidth="40%"
              skMargin="0.486vw 0 0.972vw 10%"
              opacity="0.5"
            />
            <Skeleton
              skHeight="0.972vw"
              skWidth="50%"
              skMargin="0.486vw 0 0.972vw 10%"
              opacity="0.5"
            />
            <Skeleton
              skHeight="0.972vw"
              skWidth="50%"
              skMargin="0.486vw 0 0.972vw 10%"
              opacity="0.5"
            />
            <Skeleton
              skHeight="0.972vw"
              skWidth="50%"
              skMargin="0.486vw 0 0.972vw 10%"
              opacity="0.5"
            />
            <Skeleton
              skHeight="3.194vw"
              skWidth="100%"
              skMargin="0 0 0 0"
              skRadius="0"
              position="absolute"
              bottom="0"
              left="0"
              right="0"
            />
          </div>
        </div>
      </div>
      <div className="wrapper-right d-flex align-items-center justify-center">
        <GlobalLoader newLoad={true} />
      </div>
    </div>
  );
}

export default GlobalSkeleton;
