import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import Popover from '../custom-popover/custom-popover';
import { logout } from '../../service/authService';

function AccountDetails({ profileDetail }) {
  const [t] = useTranslation('global');
  const { relation } = useParams();
  const [eventValue, setEventValue] = useState({});
  const togglePopover = (e) => {
    setEventValue(e.target);
  };
  const handleClose = () => {
    setEventValue(null);
  };

  return (
    <div className="user-section">
      <button
        className="profile"
        type="button"
        onClick={togglePopover}
        id="morePopover"
      >
        {profileDetail?.data?.profilePicture !== '' ? (
          <img src={profileDetail?.data?.profilePicture} alt="" />
        ) : (
          <span
            className={`short-name ${profileDetail?.data?.firstName
              ?.charAt(0)
              ?.toLowerCase()}`}
          >
            {profileDetail?.data?.firstName?.charAt(0).toUpperCase()}
            {profileDetail?.data?.lastName?.charAt(0)?.toUpperCase()}
          </span>
        )}

        <span className="user-details">
          <span className="user-name">
            {`${profileDetail?.data?.firstName || ''}  ${
              profileDetail?.data?.lastName || ''
            }`}
          </span>
          <span className="user-email">
            {profileDetail?.data?.email || '-'}
          </span>
        </span>
      </button>
      <Popover
        reference="morePopover"
        getEvent={eventValue}
        closePopover={handleClose}
        placement="middle"
      >
        <div className="account-dropdown">
          <Link to={`/app/${relation}/profile`} className="account-container">
            {profileDetail?.data?.profilePicture !== '' ? (
              <img src={profileDetail?.data?.profilePicture} alt="" />
            ) : (
              <span
                className={`short-name ${profileDetail?.data?.firstName
                  ?.charAt(0)
                  ?.toLowerCase()}`}
              >
                {profileDetail?.data?.firstName?.charAt(0).toUpperCase()}
                {profileDetail?.data?.lastName?.charAt(0)?.toUpperCase()}
              </span>
            )}

            <span className="user-details">
              <span className="user-name">
                {`${profileDetail?.data?.firstName} ${profileDetail?.data?.lastName}` ||
                  '-'}
              </span>
              <span className="user-email">
                {profileDetail?.data?.email || '-'}
              </span>
            </span>
          </Link>
          <Link to={`/app/${relation}/my-company`} className="company-name">
            {profileDetail?.companyName || ''}
          </Link>
          <ul>
            <li>
              <button
                type="button"
                onClick={(e) =>
                  e.target.innerHTML === t('globalnotification.logout')
                    ? logout()
                    : null
                }
              >
                {t('globalnotification.logout')}
              </button>
            </li>
          </ul>
        </div>
      </Popover>
    </div>
  );
}

AccountDetails.propTypes = {
  profileDetail: PropTypes.instanceOf(Object),
};

AccountDetails.defaultProps = {
  profileDetail: {},
};

function mapStateToProps(state) {
  return {
    profileDetail: state.omniProfile.profileDetail.fetchProfileDetails,
  };
}

export default connect(mapStateToProps, null)(AccountDetails);
