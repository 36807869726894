import React, { useState } from 'react';
import PropTypes from 'prop-types';
import PoItem from './po-item';
import {
  changeDateToDoFormat,
  getUserTimeZoneDateFormat,
} from '../../../helpers/dateHelper';

function PoGroup({ data, translate }) {
  const [moreItem, setMoreItem] = useState(false);
  const toggleMoreItem = () => {
    setMoreItem(!moreItem);
  };

  const returnParameter = (text) => {
    let updatedTxt = text;
    if (typeof text === 'string') {
      if (text.at(-1) === 'Z') {
        updatedTxt = getUserTimeZoneDateFormat(text);
      }
    }
    return updatedTxt;
  };

  const changeLog = data?.newChange?.[0]?.changeLog;
  const specReason = data.newChange[0].changeLog?.[0]?.reasonLog?.[0];

  const switchStatus = (status) => {
    let sts;
    switch (status) {
      case 'Pending':
        sts = 'Requested';
        break;
      default:
        sts = status;
    }
    return sts;
  };

  const userDetails = data?.newChange?.[0]?.createdBy;

  return (
    <div className={`po-group ${moreItem ? 'active' : ''}`}>
      {moreItem && (
        <h4 className="title">
          {translate('notifications.For')} {data?.itemCode}
        </h4>
      )}
      {moreItem ? (
        changeLog?.map((change, index) => {
          if (index === changeLog.length - 1) {
            return (
              <PoItem
                userName={`${userDetails?.firstName} ${userDetails?.lastName}`}
                userImage={userDetails?.profilePicture}
                bookingId={data?.itemCode}
                description={`${change?.reasonLog?.[0]?.parameter} ${translate(
                  'notifications.from'
                )} ${returnParameter(
                  change?.reasonLog?.[0]?.oldData
                )} ${translate('notifications.to')} ${returnParameter(
                  change?.reasonLog?.[0]?.newData
                )}`}
                dateTime={changeDateToDoFormat(data?.newChange?.[0]?.createdAt)}
                status={switchStatus(change?.reasonLog?.[0]?.currentStatus)}
                moreUpdate
                moreItemClick={toggleMoreItem}
                updateLength={0}
                translate={translate}
              />
            );
          }
          return (
            <PoItem
              userName={`${userDetails?.firstName} ${userDetails?.lastName}`}
              userImage={userDetails?.profilePicture}
              bookingId={data?.itemCode}
              description={`${change?.reasonLog?.[0]?.parameter} ${translate(
                'notifications.from'
              )} ${returnParameter(
                change?.reasonLog?.[0]?.oldData
              )} ${translate('notifications.to')} ${returnParameter(
                change?.reasonLog?.[0]?.newData
              )}`}
              dateTime={changeDateToDoFormat(data?.newChange?.[0]?.createdAt)}
              status={switchStatus(change?.reasonLog?.[0]?.currentStatus)}
              translate={translate}
            />
          );
        })
      ) : (
        <PoItem
          userName={`${userDetails?.firstName} ${userDetails?.lastName}`}
          userImage={userDetails?.profilePicture}
          bookingId={data?.itemCode}
          description={`${specReason?.parameter} ${translate(
            'notifications.from'
          )} ${returnParameter(specReason?.oldData)} ${translate(
            'notifications.to'
          )} ${returnParameter(specReason?.newData)}`}
          dateTime={changeDateToDoFormat(data?.newChange?.[0]?.createdAt)}
          updateLength={data.newChange[0].changeLog.length - 1}
          moreItemClick={toggleMoreItem}
          status={switchStatus(specReason?.currentStatus)}
          moreUpdate={data.newChange[0].changeLog.length > 1}
          translate={translate}
        />
      )}
    </div>
  );
}

PoGroup.propTypes = {
  data: PropTypes.instanceOf(Array),
  translate: PropTypes.func,
};
PoGroup.defaultProps = {
  data: [],
  translate: () => {},
};

export default PoGroup;
