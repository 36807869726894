import React, { memo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
// import CustomSelect from '../custom-select/custom-select';
import AdvanceSelectLabel from '../advance-select/advance-select-label';
import { getIncoTerm } from '../../graphQL/addAddress';
import TextBox from '../text-box/text-box';
import { getDigitsOnly } from '../../helpers/utils';

function AddShipment({
  mode,
  setMode,
  containerType,
  setContainerType,
  typeOfMove,
  setTypeOfMove,
  equipmentType,
  setEquipmentType,
  equipTypeQty,
  setEquipTypeQty,
  incoTerm,
  setIncoTerm,
  setNumberOfTrucks,
  numberOfTrucks,
  cargoLoad,
  setCargoLoad,
  changeMode,
  disableMode,
}) {
  const [t] = useTranslation('manageorders');
  const [options, setOptions] = useState([]);
  const [incoTermList] = useLazyQuery(getIncoTerm);
  const [incoTermOptions, setIncoTermOptions] = useState([]);

  useEffect(() => {
    if (mode.value === 'Ocean') {
      setOptions([
        { label: 'FCL', value: 'FCL' },
        { label: 'LCL', value: 'LCL' },
      ]);
    } else if (mode.value === 'Air') {
      setOptions([{ label: 'AIR', value: 'AIR' }]);
    } else {
      setOptions([
        { label: 'FTL', value: 'FTL' },
        { label: 'LTL', value: 'LTL' },
      ]);
    }
  }, [mode]);

  const serverCall = () => {
    incoTermList({
      variables: {},
      onCompleted: (data) => {
        const incoTermOption = [];
        setIncoTermOptions([]);

        data?.FetchIncoterm?.data.map((item) => {
          incoTermOption.push({
            label: item.Abbreviation,
            value: item.Abbreviation,
            mode: item.Mode,
          });

          return '';
        });
        setIncoTermOptions(incoTermOption);
      },
    });
  };

  useEffect(() => {
    serverCall();
  }, []);
  return (
    <>
      <div className="select-container">
        <AdvanceSelectLabel
          // forceValue={mode?.label}
          name="mode"
          id="mode"
          onChange={(e) => {
            if (e.target.value === 'Inland') {
              setTypeOfMove({ label: 'Door to Door', value: 'door to door' });
            }
            setMode({
              label: e?.target?.nextSibling?.innerHTML,
              value: e?.target?.value,
            });
          }}
          removeSelectedValue={() => {
            if (mode?.value) {
              changeMode('mode');
            }
          }}
          placeholder={t(
            'manageordersscreen.createbookingmenu.shipmentdetails.shipmentmodeplaceholder'
          )}
          label={t(
            'manageordersscreen.createbookingmenu.shipmentdetails.shipmentmode'
          )}
          required
          data={[
            { label: 'Ocean', value: 'Ocean' },
            { label: 'Air', value: 'Air' },
            { label: 'Road', value: 'Inland' },
          ]}
          value={mode}
          defaultValue={mode}
          disabled={disableMode}
          // isReadOnly
        />
      </div>

      <div className="select-container">
        <AdvanceSelectLabel
          name="typeOfMove"
          id="typeOfMove"
          required
          defaultValue={typeOfMove}
          onChange={(e) => {
            setTypeOfMove({
              label: e?.target?.nextSibling?.innerHTML,
              value: e?.target?.value,
            });
          }}
          removeSelectedValue={() => {
            setTypeOfMove({ label: '', value: '' });
          }}
          placeholder={t(
            'manageordersscreen.createbookingmenu.shipmentdetails.typeofmoveplaceholder'
          )}
          label={t(
            'manageordersscreen.createbookingmenu.shipmentdetails.typeofmove'
          )}
          data={[
            { label: 'Port to Port', value: 'port to port' },
            { label: 'Port to Door', value: 'port to door' },
            { label: 'Door to Port', value: 'door to port' },
            { label: 'Door to Door', value: 'door to door' },
          ]}
          disabled={mode?.value === 'Inland'}
          // isReadOnly
        />
      </div>
      <div className="select-container">
        <AdvanceSelectLabel
          name="incoTerm"
          id="incoTerm"
          required={mode?.value !== 'Inland'}
          defaultValue={incoTerm}
          placeholder={t(
            'manageordersscreen.createbookingmenu.shipmentdetails.incotermplaceholder'
          )}
          label={t(
            'manageordersscreen.createbookingmenu.shipmentdetails.incoterm'
          )}
          onChange={(e) => {
            setIncoTerm({
              label: e?.target?.nextSibling?.innerHTML,
              value: e?.target?.value,
            });
          }}
          removeSelectedValue={() => {
            setIncoTerm({ label: '', value: '' });
          }}
          data={
            mode?.value === 'Air'
              ? incoTermOptions.filter((item) => {
                  return item.mode === 'All';
                })
              : incoTermOptions
          }
          // isReadOnly
        />
      </div>
      {(mode?.value === 'Ocean' || mode?.value === 'Inland') && (
        <div className="select-container">
          <AdvanceSelectLabel
            id={mode?.value === 'Ocean' ? 'containerType' : 'cargoLoad'}
            name={mode?.value === 'Ocean' ? 'containerType' : 'cargoLoad'}
            data={options}
            required
            onChange={(e) => {
              if (mode?.value === 'Ocean') {
                setContainerType({
                  label: e?.target?.nextSibling?.innerHTML,
                  value: e?.target?.value,
                });
              } else {
                setCargoLoad({
                  label: e?.target?.nextSibling?.innerHTML,
                  value: e?.target?.value,
                });
              }
              setEquipmentType({});
              setEquipTypeQty(1);
              setNumberOfTrucks(1);
            }}
            removeSelectedValue={() => {
              if (mode?.value === 'Ocean') {
                setContainerType({ label: '', value: '' });
              } else {
                setCargoLoad({ label: '', value: '' });
              }
              setEquipmentType({});
              setEquipTypeQty(1);
              setNumberOfTrucks(1);
            }}
            placeholder={
              mode?.value === 'Ocean'
                ? t(
                    'manageordersscreen.createbookingmenu.shipmentdetails.oceanplaceholder'
                  )
                : t(
                    'manageordersscreen.createbookingmenu.shipmentdetails.roadplaceholder'
                  )
            }
            label={
              mode?.value === 'Ocean'
                ? t(
                    'manageordersscreen.createbookingmenu.shipmentdetails.containertype'
                  )
                : t(
                    'manageordersscreen.createbookingmenu.shipmentdetails.cargoload'
                  )
            }
            defaultValue={mode?.value === 'Ocean' ? containerType : cargoLoad}
            // isReadOnly
          />
        </div>
      )}
      {mode?.value === 'Ocean' && containerType.value === 'FCL' && (
        <div className="select-container d-flex justify-between align-end">
          <AdvanceSelectLabel
            id="equipmentType"
            name="equipmentType"
            required
            className="w-100"
            defaultValue={equipmentType}
            onChange={(e) => {
              setEquipmentType({
                label: e?.target?.nextSibling?.innerHTML,
                value: e.target.value,
              });
            }}
            removeSelectedValue={() => {
              setEquipmentType({ label: '', value: '' });
            }}
            placeholder={t(
              'manageordersscreen.createbookingmenu.shipmentdetails.equipmenttypeplaceholder'
            )}
            label={t(
              'manageordersscreen.createbookingmenu.shipmentdetails.equipmenttype'
            )}
            data={[
              { label: '20 standard dry', value: '20GP' },
              { label: '40 standard dry', value: '40GP' },
              { label: '40 high cube', value: '40HC' },
            ]}
            // isReadOnly
          />
          {mode?.value !== 'Inland' && (
            <>
              <span className="mb-15 mx-5">X</span>
              <TextBox
                type="number"
                label="Qty"
                width="4.444vw"
                required
                defaultValue={equipTypeQty}
                placeholder="Qty"
                onChange={(e) => setEquipTypeQty(e.target.value)}
                onKeyDown={(e) => {
                  getDigitsOnly(e);
                }}
              />
            </>
          )}
        </div>
      )}
      {mode?.value === 'Inland' && cargoLoad?.value === 'FTL' && (
        <div className="select-container">
          <TextBox
            type="number"
            label={t(
              'manageordersscreen.createbookingmenu.shipmentdetails.numberoftrucks'
            )}
            required
            placeholder={t(
              'manageordersscreen.createbookingmenu.shipmentdetails.numberoftrucksplaceholder'
            )}
            defaultValue={numberOfTrucks}
            onChange={(e) => setNumberOfTrucks(e.target.value)}
            onKeyDown={(e) => {
              getDigitsOnly(e);
            }}
          />
        </div>
      )}
    </>
  );
}
AddShipment.propTypes = {
  mode: PropTypes.string,
  setMode: PropTypes.func,
  changeMode: PropTypes.func,
  containerType: PropTypes.string,
  setContainerType: PropTypes.func,
  typeOfMove: PropTypes.string,
  setTypeOfMove: PropTypes.func,
  equipmentType: PropTypes.string,
  equipTypeQty: PropTypes.number,
  setEquipTypeQty: PropTypes.func,
  setEquipmentType: PropTypes.func,
  incoTerm: PropTypes.string,
  setIncoTerm: PropTypes.func,
  setNumberOfTrucks: PropTypes.func,
  numberOfTrucks: PropTypes.number,
  cargoLoad: PropTypes.string,
  setCargoLoad: PropTypes.func,
  disableMode: PropTypes.bool,
};
AddShipment.defaultProps = {
  mode: {},
  setMode: () => {},
  changeMode: () => {},
  containerType: {},
  setContainerType: () => {},
  typeOfMove: {},
  setTypeOfMove: () => {},
  equipmentType: {},
  setEquipmentType: () => {},
  equipTypeQty: 1,
  setEquipTypeQty: () => {},
  incoTerm: '',
  setIncoTerm: () => {},
  numberOfTrucks: 1,
  setNumberOfTrucks: () => {},
  cargoLoad: {},
  setCargoLoad: () => {},
  disableMode: false,
};
export default memo(AddShipment);
