import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import './tooltip.scss';

function Tooltip({
  className,
  delay,
  // block,
  ...props
}) {
  let timeout;
  const [active, setActive] = useState(false);
  const [leftPosition, setLeftPosition] = useState();
  const [topPosition, setTopPosition] = useState();
  const tooltipContainer = useRef(null);
  const tooltipHandeler = useRef(null);

  const showTip = () => {
    timeout = setTimeout(() => {
      const rectangle = tooltipHandeler.current.getBoundingClientRect();
      const tooltipHeight =
        tooltipContainer?.current?.getBoundingClientRect().height;
      const tooltipWidth =
        tooltipContainer?.current?.getBoundingClientRect().width;
      switch (props.direction) {
        case 'right':
          setLeftPosition(rectangle.x + rectangle.width);
          setTopPosition(
            rectangle.y + rectangle.height / 2 - tooltipHeight / 2
          );
          setActive(true);
          break;
        case 'top':
          setLeftPosition(rectangle.x + rectangle.width / 2 - tooltipWidth / 2);
          setTopPosition(rectangle.y - tooltipHeight - 12);
          setActive(true);
          break;
        case 'left':
          setLeftPosition(rectangle.x - (tooltipWidth + 12));
          setTopPosition(
            rectangle.y + (rectangle.height / 2 - tooltipHeight / 2)
          );
          setActive(true);
          break;
        case 'bottom-left':
          setLeftPosition(rectangle.x);
          setTopPosition(rectangle.y + rectangle.height);
          setActive(true);
          break;
        case 'center':
          setLeftPosition(rectangle.x + rectangle.width / 2 - tooltipWidth / 2);
          setTopPosition(rectangle.y + rectangle.height);
          setActive(true);
          break;
        default:
          setLeftPosition(rectangle.x + rectangle.width / 2 - tooltipWidth / 2);
          setTopPosition(rectangle.y + rectangle.height);
          setActive(true);
      }
    }, delay || 100);
  };

  const hideTip = () => {
    clearInterval(timeout);
    setActive(false);
  };

  return (
    <span
      onMouseEnter={showTip}
      onMouseLeave={hideTip}
      ref={tooltipHandeler}
      className="tooltip-wrapper"
    >
      {props.children}
      {/* {active && ( */}
      <span
        className={`tooltip-tip ${props.direction || 'bottom'} ${
          active ? 'show' : ''
        }`}
        style={{ left: leftPosition, top: topPosition }}
      >
        <span className={`tooltip-content ${className}`} ref={tooltipContainer}>
          {props.content}
        </span>
      </span>
      {/* )} */}
    </span>
  );
}

export default Tooltip;
Tooltip.propTypes = {
  delay: PropTypes.string,
  direction: PropTypes.string,
  content: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  // block: PropTypes.bool,
};
Tooltip.defaultProps = {
  delay: '',
  direction: '',
  content: '',
  className: '',
  // block: false,
};
