import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Checkbox from '../../Components/custom-checkbox/custom-checkbox';
import Tabs from '../../Components/tabs/custom-tab';

function PermissionsTab({ permissionList, viewOnly, setPermissionList, t }) {
  const [selectAllState, setSelectAllState] = useState({});

  useEffect(() => {
    const newSelectAllState = {};
    permissionList.forEach(({ moduleName, modulePermissions }) => {
      newSelectAllState[moduleName] = {
        E: modulePermissions.every((perm) =>
          perm.actionPermissions.includes('E')
        ),
        V: modulePermissions.every((perm) =>
          perm.actionPermissions.includes('V')
        ),
        N: modulePermissions.every((perm) =>
          perm.actionPermissions.includes('N')
        ),
      };
    });
    setSelectAllState(newSelectAllState);
  }, [permissionList]);

  const handleCheckboxChange = ({
    moduleName,
    actionName,
    permission,
    checked,
  }) => {
    setPermissionList((prevPermissionList) => {
      return prevPermissionList.map((section) => {
        if (section.moduleName === moduleName) {
          return {
            ...section,
            modulePermissions: section.modulePermissions.map(
              (permissionItem) => {
                if (permissionItem.actionName === actionName) {
                  const updatedPermissions = checked ? [permission] : [];

                  return {
                    ...permissionItem,
                    actionPermissions: updatedPermissions,
                  };
                }
                return permissionItem;
              }
            ),
          };
        }
        return section;
      });
    });
  };
  const handleSelectAllChange = (moduleName, permissionType, checked) => {
    setSelectAllState((prevState) => ({
      ...prevState,
      [moduleName]: {
        E: permissionType === 'E' ? checked : false,
        V: permissionType === 'V' ? checked : false,
        N: permissionType === 'N' ? checked : false,
      },
    }));

    setPermissionList((prevPermissionList) =>
      prevPermissionList.map((section) => {
        if (section.moduleName === moduleName) {
          return {
            ...section,
            modulePermissions: section.modulePermissions.map(
              (permissionItem) => {
                let updatedPermissions = [];
                if (permissionType === 'E') {
                  updatedPermissions = checked ? ['E'] : [];
                } else if (permissionType === 'V') {
                  updatedPermissions = checked ? ['V'] : [];
                } else if (permissionType === 'N') {
                  updatedPermissions = checked ? ['N'] : [];
                }

                return {
                  ...permissionItem,
                  actionPermissions: updatedPermissions,
                };
              }
            ),
          };
        }
        return section;
      })
    );
  };

  return (
    <Tabs className="settings-tabs" disableURL>
      {permissionList.map(
        ({ moduleName, moduleTranslated, modulePermissions }) => (
          <div
            key={moduleTranslated}
            label={moduleTranslated}
            value={moduleTranslated}
          >
            <div className="settings-tab-body">
              <div className="settings-table">
                <table>
                  <thead>
                    <tr>
                      <th align="left">
                        {t(
                          'systemsettings.roles&permission.permissionspopup.tablerow.actions'
                        )}
                      </th>
                      <th align="center">
                        {t(
                          'systemsettings.roles&permission.permissionspopup.tablerow.editable'
                        )}
                        <br />
                        <Checkbox
                          id="editableselectall"
                          className="t-3 l-3"
                          checked={selectAllState[moduleName]?.E || false}
                          onChange={(e) =>
                            handleSelectAllChange(
                              moduleName,
                              'E',
                              e.target.checked
                            )
                          }
                        />
                      </th>
                      <th align="center">
                        {t(
                          'systemsettings.roles&permission.permissionspopup.tablerow.viewonly'
                        )}
                        <br />
                        <Checkbox
                          id="viewonlyselectall"
                          className="t-3 l-3"
                          checked={selectAllState[moduleName]?.V || false}
                          onChange={(e) =>
                            handleSelectAllChange(
                              moduleName,
                              'V',
                              e.target.checked
                            )
                          }
                        />
                      </th>
                      <th align="center">
                        {t(
                          'systemsettings.roles&permission.permissionspopup.tablerow.none'
                        )}
                        <br />
                        <Checkbox
                          id="noneselectall"
                          className="t-3 l-3"
                          checked={selectAllState[moduleName]?.N || false}
                          onChange={(e) =>
                            handleSelectAllChange(
                              moduleName,
                              'N',
                              e.target.checked
                            )
                          }
                        />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {modulePermissions.map(
                      ({ actionName, actionPermissions }) => (
                        <tr key={actionName}>
                          <td>{actionName}</td>
                          <td className="text-center">
                            <Checkbox
                              onChange={(e) =>
                                handleCheckboxChange({
                                  moduleName,
                                  actionName,
                                  permission: 'E',
                                  checked: e.target.checked,
                                })
                              }
                              checked={actionPermissions?.includes('E')}
                              disabled={viewOnly}
                            />
                          </td>
                          <td className="text-center">
                            <Checkbox
                              checked={actionPermissions?.includes('V')}
                              onChange={(e) =>
                                handleCheckboxChange({
                                  moduleName,
                                  actionName,
                                  permission: 'V',
                                  checked: e.target.checked,
                                })
                              }
                              disabled={viewOnly}
                            />
                          </td>
                          <td className="text-center">
                            <Checkbox
                              checked={actionPermissions?.includes('N')}
                              onChange={(e) =>
                                handleCheckboxChange({
                                  moduleName,
                                  actionName,
                                  permission: 'N',
                                  checked: e.target.checked,
                                })
                              }
                              disabled={viewOnly}
                            />
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )
      )}
    </Tabs>
  );
}

export default PermissionsTab;

PermissionsTab.propTypes = {
  setPermissionList: PropTypes.func,
  permissionList: PropTypes.arrayOf(
    PropTypes.shape({
      moduleName: PropTypes.string.isRequired,
      moduleTranslated: PropTypes.string.isRequired,
      modulePermissions: PropTypes.arrayOf(
        PropTypes.shape({
          actionName: PropTypes.string.isRequired,
          actionPermissions: PropTypes.arrayOf(PropTypes.string).isRequired,
        })
      ).isRequired,
    })
  ).isRequired,
  viewOnly: PropTypes.bool,
  t: PropTypes.func.isRequired,
};

PermissionsTab.defaultProps = {
  setPermissionList: () => {},
  viewOnly: false,
};
