import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './edit-contact-permissions.scss';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import CustomTag from '../../../Components/custom-tag/custom-tag';
import UserImage from '../../../Components/user-image/user-image';
import PermissionsTab from '../permissions-tab';
import { PERMISSION_UNION_LIST } from '../../../graphQL/rolesAndPermissions';
import { giveProfile } from '../../../Components/global-notification/notification-list';
import GlobalLoader from '../../../Components/global-loader/global-loader';
import { convertCamelCaseToTitleCase } from '../../../helpers/utils';
import AdvancePopover from '../../../Components/advance-popover/advance-popover';
import ListRole from '../contact-list/list-role';

function EditContactPermissions({
  userName,
  email,
  roleName,
  location,
  roleLength,
  orgId,
  userSub,
  userType,
  roleId,
  t,
}) {
  const { relation } = useParams();
  const [permissionList, setPermissionList] = useState([]);
  const [eventValue, setEventValue] = useState(null);

  const { loading } = useQuery(PERMISSION_UNION_LIST, {
    variables: {
      input: {
        profileType: giveProfile(relation),
        userSub,
        orgId: Number(orgId),
      },
    },
    onCompleted: (data) => {
      setPermissionList(data?.PermissionUnionList?.data);
    },
  });

  const toggleLocationPopover = (e) => {
    setEventValue(e?.target);
  };
  const handleClose = () => {
    setEventValue(null);
  };

  return (
    <div className="edit-contact-permissions-container">
      {!loading && permissionList?.length ? (
        <>
          <div className="edit-contact-permissions-user-group">
            <div className="edit-contact-permissions-user-group-left">
              <CustomTag
                text={convertCamelCaseToTitleCase(userType?.slice(1))}
                className="small "
              />
              <div className="edit-contact-permissions-user-group-user-area">
                <UserImage name="User Name" />
                <div className="edit-contact-permissions-user-group-user-area-group">
                  <p className="user-name">{userName}</p>
                  <p className="user-email">{email}</p>
                </div>
              </div>
            </div>
            <div className="edit-contact-permissions-user-group-right">
              <div className="position-label">
                <p className="position">{roleName}</p>
                {roleLength > 1 && (
                  <button
                    type="button"
                    onClick={toggleLocationPopover}
                    id="moreLocationPopover"
                    className="counter"
                  >
                    +{roleLength && roleLength - 1}
                  </button>
                )}
                <AdvancePopover
                  reference="moreLocationPopover"
                  getEvent={eventValue}
                  closePopover={handleClose}
                  placement="left"
                  closeOnclick
                >
                  <ListRole roleId={roleId} userSub={userSub} />
                </AdvancePopover>
              </div>
              <p className="location">{location}</p>
            </div>
          </div>
          <h3 className="m-0 mb-20 sub-title-color-new fs-14 fw-500">
            {t(
              'systemsettings.roles&permission.activecontactspopup.actions.permissionsbtn.description'
            )}
          </h3>
          <PermissionsTab
            permissionList={permissionList}
            setPermissionList={setPermissionList}
            viewOnly={true}
            t={t}
          />
        </>
      ) : (
        <GlobalLoader newLoad={true} height="6.944vw" width="6.944vw" />
      )}
    </div>
  );
}

export default EditContactPermissions;

EditContactPermissions.propTypes = {
  userName: PropTypes.string,
  email: PropTypes.string,
  roleName: PropTypes.string,
  location: PropTypes.string,
  roleLength: PropTypes.number,
  orgId: PropTypes.string,
  userSub: PropTypes.string,
  userType: PropTypes.string,
  roleId: PropTypes.string,
  t: PropTypes.func,
};

EditContactPermissions.defaultProps = {
  userName: '',
  email: '',
  roleName: '',
  location: '',
  roleLength: 0,
  orgId: '',
  userSub: '',
  userType: '',
  roleId: '',
  t: () => {},
};
