import React from 'react';
import './dashboard-card.scss';
import Skeleton from '../../../Components/skeleton/skeleton';

function DashboardCardLoader() {
  return (
    <button type="button" className="dashboard-card">
      <span className="dashboard-card-top">
        <span className="dashboard-card-title">
          <Skeleton skHeight="1.042vw" skWidth="6.944vw" skMargin="0.347vw 0" />
        </span>
        <span className="dashboard-card-icon">
          <Skeleton skHeight="1.389vw" skWidth="1.389vw" skMargin="0" />
        </span>
      </span>
      <span className="dashboard-card-middle">
        <span className="dashboard-card-value">
          <Skeleton skHeight="2.083vw" skWidth="2.083vw" skMargin="0.694vw 0" />
        </span>
        {/* <span className="dashboard-card-value-icon">
          <Skeleton skHeight="2.083vw" skWidth="2.083vw" skMargin="0" />
        </span> */}
      </span>
      <span className="dashboard-card-bottom">
        <span className="dashboard-card-progress">
          <span className="dashboard-card-progress-value">
            <Skeleton
              skHeight="0.694vw"
              skWidth="3.472vw"
              skMargin="0.347vw 0"
            />
          </span>
        </span>
        <span className="dashboard-card-progress-range">
          <Skeleton skHeight="0.694vw" skWidth="3.472vw" skMargin="0.347vw 0" />
        </span>
      </span>
    </button>
  );
}

export default DashboardCardLoader;
