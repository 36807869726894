import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  MilestoneIcon,
  LeftMenuApprovalsIcon,
  LeftMenuDocumentsIcon,
  LeftMenuMentionsIcon,
  LeftmenuActionsIcon,
  POChangesIcon,
} from '../icons/icons';
import './global-notification.scss';

export default function GlobalNotification({
  showNotification,
  actionsNotification,
  approvalNotification,
  activeNotificationMenu,
  documentNotificationMenu,
  notifiCount,
  poChangesNotification,
  pochanges,
  enableChat,
  showChats,
}) {
  const [t] = useTranslation('global');
  return (
    <div className="global-notification">
      <ul className="left-menu-list">
        <li>
          <button
            type="button"
            onClick={showNotification}
            className={activeNotificationMenu === 'all' ? 'active' : ''}
          >
            <MilestoneIcon color="#ffffff" />
            {t('globalnotification.milestones')}
            {notifiCount?.milestone > 0 && (
              <span className="counter">
                {notifiCount?.milestone > 9 ? '9+' : notifiCount?.milestone}
              </span>
            )}
          </button>
        </li>
        <li>
          <button
            type="button"
            onClick={actionsNotification}
            className={activeNotificationMenu === 'action' ? 'active' : ''}
          >
            <LeftmenuActionsIcon color="#ffffff" />
            {t('globalnotification.actions')}
            {notifiCount?.actionable > 0 && (
              <span className="counter">
                {notifiCount?.actionable > 9 ? '9+' : notifiCount?.actionable}
              </span>
            )}
          </button>
        </li>
        <li>
          <button
            type="button"
            onClick={approvalNotification}
            className={activeNotificationMenu === 'approval' ? 'active' : ''}
          >
            <LeftMenuApprovalsIcon /> {t('globalnotification.approvals')}
            {notifiCount?.approval > 0 && (
              <span className="counter">
                {notifiCount?.approval > 9 ? '9+' : notifiCount?.approval}
              </span>
            )}
          </button>
        </li>
        {enableChat && (
          <li>
            <button
              type="button"
              onClick={showChats}
              className={activeNotificationMenu === 'Chats' ? 'active' : ''}
            >
              <LeftMenuMentionsIcon color="#ffffff" />
              {t('globalnotification.chat')}
              {notifiCount?.chat > 0 && (
                <span className="counter">
                  {notifiCount?.chat > 9 ? '9+' : notifiCount?.chat}
                </span>
              )}
            </button>
          </li>
        )}
        <li>
          <button
            type="button"
            onClick={documentNotificationMenu}
            className={activeNotificationMenu === 'document' ? 'active' : ''}
          >
            <LeftMenuDocumentsIcon color="#ffffff" />
            {t('globalnotification.documents')}
            {notifiCount?.document > 0 && (
              <span className="counter">
                {notifiCount?.document > 9 ? '9+' : notifiCount?.document}
              </span>
            )}
          </button>
        </li>
        {pochanges && (
          <li>
            <button
              type="button"
              onClick={poChangesNotification}
              className={
                activeNotificationMenu === 'Po Changes' ? 'active' : ''
              }
            >
              <POChangesIcon color="#CDD1D1" /> {t('menuSchema.pochanges')}
              {notifiCount?.poChange > 0 && (
                <span className="counter">
                  {notifiCount?.poChange > 9 ? '9+' : notifiCount?.poChange}
                </span>
              )}
            </button>
          </li>
        )}
      </ul>
    </div>
  );
}
GlobalNotification.propTypes = {
  showNotification: PropTypes.func,
  actionsNotification: PropTypes.func,
  approvalNotification: PropTypes.func,
  documentNotificationMenu: PropTypes.func,
  activeNotificationMenu: PropTypes.string,
  notifiCount: PropTypes.instanceOf(Object),
  poChangesNotification: PropTypes.func,
  pochanges: PropTypes.bool,
  enableChat: PropTypes.bool,
  showChats: PropTypes.func,
};
GlobalNotification.defaultProps = {
  showNotification: () => {},
  actionsNotification: () => {},
  approvalNotification: () => {},
  documentNotificationMenu: () => {},
  activeNotificationMenu: '',
  notifiCount: {},
  poChangesNotification: () => {},
  pochanges: false,
  enableChat: false,
  showChats: () => {},
};
