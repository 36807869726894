import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './lardge-widgets.scss';
import DashboardCardPanel from '../../../../Components/dashboard-components/dashboard-card-panel/dashboard-card-panel';
import DashboardCardPanelHeader from '../../../../Components/dashboard-components/dashboard-card-panel/dashboard-card-panel-header';
import {
  DashboardBarChartIcon,
  DashboardExpandIcon,
  DashboardLineChartIcon,
  DashboardPieChartIcon,
  DefaultNoDataIcon,
  DownloadIcon,
  MinusRoundIcon,
} from '../../../../Components/icons/icons';
import DashboardCardPanelBody from '../../../../Components/dashboard-components/dashboard-card-panel/dashboard-card-panel-body';
import ChartJsChart from '../../../../Components/chart/chart';
import ScoreCard from './score/score';
import HealthCard from './health/health';
import ListCard from './list/list-card';
import DashboardSearch from '../../../../Components/dashboard-components/dashboard-search/dashboard-search';
import NewButton from '../../../../Components/button/newButton';
import NoData from '../../../../Components/no-data/no-data';
import NewsIncedents from './news-incidents/news-incidents';
import BarPanel from '../../../../Components/bar-panel/bar-panel';
import StackedBar from '../../../../Components/chart/stacked-bar';
import UserDetails from '../../../../Components/user-details/user-details';

function LargeWidgets({
  title,
  subTitle,
  data,
  chartType,
  cardType,
  disableDownload,
  disableExpand,
  onExpandClick,
  detailsWidget,
  disableButtons,
  widget,
  enabelHover,
  enableRemove,
  onAddClick,
  onRemoveClick,
  widgetKey,
  xyText,
  chartAspectRatio,
  chartHeight,
  noData,
  mode,
  t,
  companySpecific,
  detailHeader,
  largeSubTitle,
  scoreCardClick,
  currentComp,
  setCurrentComp,
  height,
}) {
  const [changechartType, setChartType] = useState(chartType);

  const operationalLabels = [
    {
      labelName: 'Very Poor',
      color: '#F24C4C',
    },
    {
      labelName: 'Poor',
      color: '#F29C4C',
    },
    {
      labelName: 'Fair',
      color: '#F2C94C',
    },
    {
      labelName: 'Good',
      color: '#3B86F2',
    },
    {
      labelName: 'Excellent',
      color: '#603ACB',
    },
  ];

  return (
    <div className={`large-widgets-container${enabelHover ? ' hover' : ''}`}>
      <DashboardCardPanel>
        {cardType !== 'performance' && (title || subTitle) && (
          <DashboardCardPanelHeader
            inline={!detailHeader}
            title={title}
            subtitle={subTitle}
            smallTitle={detailsWidget}
            largeSubTitle={largeSubTitle}
          >
            {!disableButtons && cardType === 'chart' && (
              <button
                type="button"
                className={changechartType === 6 ? 'active' : ''}
                onClick={() => setChartType(6)}
              >
                <DashboardLineChartIcon />
              </button>
            )}
            {!disableButtons &&
              cardType === 'chart' &&
              data?.datasets?.length === 1 && (
                <button
                  type="button"
                  className={changechartType === 5 ? 'active' : ''}
                  onClick={() => setChartType(5)}
                >
                  <DashboardPieChartIcon />
                </button>
              )}
            {!disableButtons && cardType === 'chart' && (
              <button
                type="button"
                className={changechartType === 1 ? 'active' : ''}
                onClick={() => setChartType(1)}
              >
                <DashboardBarChartIcon />
              </button>
            )}
            {!disableDownload && (
              <button type="button">
                <DownloadIcon />
              </button>
            )}
            {cardType === 'list' && false && (
              <DashboardSearch className="widget-search mr-0" />
            )}
            {!disableExpand && (
              <button
                type="button"
                className="expand"
                onClick={() => {
                  onExpandClick(widgetKey);
                }}
              >
                <DashboardExpandIcon />
              </button>
            )}
          </DashboardCardPanelHeader>
        )}
        <DashboardCardPanelBody
          className={`${
            cardType === 'score' || cardType === 'health'
              ? 'p-0 h-250 ov-auto'
              : ''
          }${cardType === 'list' ? ' list-padding' : ''}${
            data.barType === 'multiple' ? 'pt-0' : ''
          }${cardType === 'chart' ? ' chart-body' : ''}${
            cardType === 'performance' ? ' h-auto' : ''
          }`}
        >
          {noData ? (
            <NoData
              image={
                <DefaultNoDataIcon height="13.889vw" marginBottom="0.694vw" />
              }
            />
          ) : (
            <>
              {cardType === 'chart' && (
                <ChartJsChart
                  type={changechartType}
                  height={chartHeight}
                  chartData={data}
                  chartAspectRatio={chartAspectRatio}
                  barType={data.barType}
                />
              )}

              {cardType === 'score' &&
                data.supplier?.map((val) => {
                  return (
                    <ScoreCard
                      name={val.orgName}
                      rating={val.AvgRating}
                      totalRatings={val.RatingCount}
                      performance={val.performance}
                      orderNumber={val.orderNumber}
                      onCompClick={(value) => {
                        scoreCardClick(value);
                        setCurrentComp(val);
                      }}
                      companyId={val._id}
                      image={val?.orgLogo}
                    />
                  );
                })}

              {cardType === 'performance' && data.supplier && (
                <>
                  {companySpecific === false && (
                    <UserDetails
                      size="small"
                      enableLink
                      userName={data.supplier && data.orgName}
                      url={`/app/supplier/${
                        currentComp.compId || data.compId
                      }/organization-details?activeTab=performance`}
                    />
                  )}

                  <BarPanel
                    // title="Statistics"
                    largeTitle=""
                    subTitle={title || 'Operation Performance'} // data?.companySpecific !== true &&
                    titleRight="Total:"
                    subTitleRight={data.totalOrders || '20 Orders'}
                  >
                    {data.supplier && (
                      <StackedBar
                        data={data.supplier}
                        height={height}
                        labels={operationalLabels}
                      />
                    )}
                  </BarPanel>
                </>
              )}
              {cardType === 'list' && (
                <ListCard
                  listData={data}
                  mode={mode}
                  companySpecific={companySpecific}
                />
              )}
              {cardType === 'health' && <HealthCard healthData={data.health} />}

              <p className="x-y-text">
                {xyText &&
                (changechartType === 1 ||
                  changechartType === 3 ||
                  changechartType === 4 ||
                  changechartType === 6)
                  ? xyText
                  : ' '}
              </p>

              {cardType === 'news' && <NewsIncedents />}
            </>
          )}
        </DashboardCardPanelBody>
      </DashboardCardPanel>
      {enabelHover && (
        <button
          type="button"
          onClick={() => onAddClick(widget)}
          className="large-widget-hover"
        >
          <NewButton className="add-widget-button">
            {t('addtodashboard')}{' '}
          </NewButton>
        </button>
      )}
      {enableRemove && (
        <button
          type="button"
          onClick={() => onRemoveClick(widget)}
          className="card-action-button remove"
        >
          <MinusRoundIcon />
        </button>
      )}
    </div>
  );
}

LargeWidgets.propTypes = {
  data: PropTypes.instanceOf(Array),
  title: PropTypes.string,
  subTitle: PropTypes.string,
  chartType: PropTypes.number,
  cardType: PropTypes.string,
  disableDownload: PropTypes.bool,
  disableExpand: PropTypes.bool,
  onExpandClick: PropTypes.func,
  detailsWidget: PropTypes.bool,
  disableButtons: PropTypes.bool,
  widget: PropTypes.string,
  enabelHover: PropTypes.bool,
  enableRemove: PropTypes.bool,
  onAddClick: PropTypes.func,
  onRemoveClick: PropTypes.func,
  widgetKey: PropTypes.string,
  xyText: PropTypes.string,
  chartAspectRatio: PropTypes.number,
  chartHeight: PropTypes.string,
  noData: PropTypes.bool,
  mode: PropTypes.string,
  t: PropTypes.instanceOf(Array),
  companySpecific: PropTypes.bool,
  detailHeader: PropTypes.bool,
  largeSubTitle: PropTypes.bool,
  scoreCardClick: PropTypes.func,
  currentComp: PropTypes.instanceOf(Object),
  setCurrentComp: PropTypes.func,
  height: PropTypes.string,
};
LargeWidgets.defaultProps = {
  data: [],
  title: '',
  subTitle: '',
  chartType: 1,
  cardType: 'chart',
  disableDownload: false,
  disableExpand: false,
  onExpandClick: () => {},
  detailsWidget: false,
  disableButtons: false,
  widget: '',
  enabelHover: false,
  enableRemove: false,
  onAddClick: () => {},
  onRemoveClick: () => {},
  widgetKey: '',
  xyText: '',
  chartAspectRatio: 2,
  chartHeight: '200px',
  noData: false,
  mode: '',
  t: [],
  companySpecific: false,
  detailHeader: false,
  largeSubTitle: false,
  scoreCardClick: () => {},
  currentComp: {},
  setCurrentComp: () => {},
  height: '15.278vw',
};

export default LargeWidgets;
