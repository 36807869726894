import { gql } from '@apollo/client';

const CHAT_MEDIA_UPLOAD_IN_PO = gql`
  mutation ChatUpload($input: uploadDocumentInput) {
    ChatUpload(input: $input) {
      success
      message
      data
    }
  }
`;
export default CHAT_MEDIA_UPLOAD_IN_PO;
