import React from 'react';
import PropTypes from 'prop-types';
import UserImage from '../../user-image/user-image';
import ListingStatus from '../../listing-status/listing-status';
import getInitials from '../../../helpers/utils';

function PoItem({
  userName,
  userImage,
  bookingId,
  description,
  dateTime,
  updateLength,
  moreItemClick,
  status,
  moreUpdate,
  translate,
}) {
  return (
    <div className="po-item">
      <div className="po-item-body">
        <div className="po-item-user-block">
          <UserImage
            shortName={getInitials(userName)}
            name={userName}
            path={userImage}
            removeTooltip
          />
          <span className="user-name">{userName}</span>
        </div>
        <div className="po-item-details-block">
          <p className="id">{bookingId}</p>
          <p className="description">{description}</p>
        </div>
        <div className="po-item-status-block">
          <ListingStatus statusLabel={status} />
        </div>
      </div>
      <div className="po-item-footer">
        <span className="time">{dateTime}</span>
        {moreUpdate && (
          <button
            type="button"
            className="po-item-update-count"
            onClick={moreItemClick}
          >
            {updateLength > 0
              ? `+${updateLength} ${translate('notifications.updates')}`
              : `${translate('notifications.seeLess')}`}
          </button>
        )}
      </div>
    </div>
  );
}

export default PoItem;
PoItem.propTypes = {
  userName: PropTypes.string,
  userImage: PropTypes.string,
  bookingId: PropTypes.string,
  description: PropTypes.string,
  status: PropTypes.string,
  dateTime: PropTypes.string,
  updateLength: PropTypes.number,
  moreItemClick: PropTypes.func,
  moreUpdate: PropTypes.bool,
  translate: PropTypes.func,
};
PoItem.defaultProps = {
  userName: '',
  userImage: '',
  bookingId: '',
  description: '',
  status: '',
  dateTime: '',
  updateLength: 0,
  moreItemClick: () => {},
  moreUpdate: false,
  translate: () => {},
};
