import React, { useRef } from 'react';
import './timeline.scss';
import {
  SliderNextIcon,
  SliderPrevIcon,
  NewOrderBox,
  DelayedOrderBox,
  UpdatedCargoReady,
  ExpectedCargoReady,
  InfoGradientIcon,
  InProgress,
} from '../icons/icons';
import PanelCard from '../panel-card/panel-card';
import NewButton from '../button/newButton';
import RatingBar from '../RatingBar.js/RatingBar';
import Tooltip from '../tooltip/tooltip';

function Timeline() {
  const timelineRef = useRef(null);
  const timelinewraper = useRef(null);

  // const [justifyCenter, setJustifyCenter] = useState(false);

  // useEffect(() => {
  //   const timeline = timelineRef.current;
  //   if (!timeline) return;
  //   const liElements = timeline.querySelectorAll('.timeline-tab-nav-item');
  //   if (liElements.length > 3) {
  //     setJustifyCenter(true);
  //   } else {
  //     setJustifyCenter(false);
  //   }
  // }, []);

  const handleTabLeftMenuScroll = () => {
    if (timelineRef.current) {
      const currentScrollLeft = timelineRef.current.scrollLeft;
      timelineRef.current.scrollTo({
        left: currentScrollLeft - 200,
        behavior: 'smooth', // Smooth scrolling behavior
      });
    }
  };
  const handleTabRightMenuScroll = () => {
    if (timelineRef.current) {
      const currentScrollLeft = timelineRef.current.scrollLeft;
      timelineRef.current.scrollTo({
        left: currentScrollLeft + 200,
        behavior: 'smooth', // Smooth scrolling behavior
      });
    }
  };

  return (
    <div className="timeline-container">
      <div className="timeline-pagination">
        <NewButton
          className="previous-page"
          onClick={() => handleTabLeftMenuScroll()} // Pass 'left' for previous button
        >
          <SliderPrevIcon />
        </NewButton>
        <NewButton
          className="next-page"
          onClick={() => handleTabRightMenuScroll()}
        >
          {' '}
          <SliderNextIcon />
        </NewButton>
      </div>
      <div className="timeline">
        <PanelCard className="w-100">
          <div
            className="timeline-tabs supplier-booking-wrapper"
            ref={timelinewraper}
          >
            <ul className="timeline-tab-list" ref={timelineRef}>
              <InProgress />
              <li className="timeline-tab-nav-item done">
                <div className="order-status">
                  <h6>New</h6>
                  <p>One Time</p>
                  <button type="button">
                    <NewOrderBox />
                  </button>
                  <label className="pt-5 pb-5">15/05/23</label>
                  <RatingBar
                    percentage={20}
                    textcolor="#55B938"
                    tooltip="5 out of 5 line items created"
                  />
                  <span className="pt-5 tab-list">5 line items created</span>
                </div>
              </li>
              <li className="timeline-tab-nav-item current">
                <div className="order-status">
                  <h6>Accepted</h6>
                  <p>Delayed by 3 days</p>
                  <button type="button">
                    <DelayedOrderBox />
                  </button>
                  <label className="pt-5 pb-5">
                    18/05/23{' '}
                    <Tooltip content="Expected- 15/05/23 Actual- 18/05/23">
                      <span>
                        <InfoGradientIcon />
                      </span>
                    </Tooltip>
                  </label>
                  <RatingBar
                    percentage={100}
                    textcolor="#55B938"
                    tooltip="2 line items on time -15/05/23 3 line items delayed -18/05/23"
                  />{' '}
                  <span className="pt-5 tab-list">
                    5 out of 5 items accepted
                  </span>
                </div>
              </li>
              <li className="timeline-tab-nav-item done in-progress">
                <div className="order-status">
                  <h6>Expected cargo ready </h6>
                  <p>Changed from 15/05/23 to 25/05/23</p>
                  <button type="button">
                    <ExpectedCargoReady />
                  </button>
                  <label className="pt-5 pb-5"> 15/05/23 </label>
                  <RatingBar
                    percentage={20}
                    textcolor="#55B938"
                    tooltip="4 line items date changed to 25/05/23"
                  />{' '}
                  <span className="pt-5 tab-list">
                    4 out of 5 items date changed
                  </span>
                </div>
              </li>
              <li className="timeline-tab-nav-item delay">
                <div className="order-status">
                  <h6 className="updated-cargoready">Updated cargo ready</h6>
                  <p>1 day passed, 1 item cargo ready</p>
                  <Tooltip content="4 line items date changed to 25/05/23">
                    <button type="button">
                      <UpdatedCargoReady />
                    </button>
                  </Tooltip>
                  <label className="pt-5 pb-5"> 25/05/23 </label>
                  <RatingBar percentage={20} textcolor="#55B938" />
                  <span className="pt-5 tab-list">
                    1 out of 5 items cargo ready
                  </span>
                </div>
              </li>
              <li className="timeline-tab-nav-item delay">
                <div className="order-status">
                  <h6>Actual cargo ready</h6>
                  <p>1 day passed, 1 item cargo ready</p>
                  <button type="button">
                    <UpdatedCargoReady />
                  </button>
                  <label className="pt-5 pb-5"> 25/05/23 </label>
                  <RatingBar percentage={20} textcolor="#55B938" />{' '}
                  <span className="pt-5 tab-list">
                    1 out of 5 items cargo ready
                  </span>
                </div>
              </li>
              <li className="timeline-tab-nav-item">
                <div className="order-status">
                  <h6>Cargo pick-up</h6>
                  <p>Pending</p>
                  <button type="button">
                    <UpdatedCargoReady />
                  </button>
                  <label className="pt-5 pb-5"> dd/mm/yy </label>
                  <RatingBar percentage={10} textcolor="#55B938" />{' '}
                  <span className="pt-5 tab-list">Pending</span>
                </div>
              </li>
            </ul>
          </div>
        </PanelCard>
      </div>
    </div>
  );
}

export default Timeline;
