// import React from 'react';
// import PropTypes from 'prop-types';
// import { Map, APILoader } from '@uiw/react-baidu-map';

// export default function BaiduMap({ mapHeight, mapWidth }) {
//   return (
//     <div style={{ width: mapWidth, height: mapHeight }}>
//       <APILoader>
//         <Map center={{ lng: 116.404269, lat: 39.916042 }} zoom={12} />
//       </APILoader>
//     </div>
//   );
// }
// BaiduMap.propTypes = {
//   mapHeight: PropTypes.string,
//   mapWidth: PropTypes.string,
// };
// BaiduMap.defaultProps = {
//   mapHeight: '',
//   mapWidth: '',
// };

import React, { useState } from 'react';
// import flagicon from '../assets/beachflag.png';
import {
  GoogleMap,
  InfoWindow,
  Marker,
  Polyline,
} from '@react-google-maps/api';
import shipicon from '../../assets/image/ship.png';

const baloonDataValue = [
  {
    customerName: 'Dadri',
    customerAddress: '<div> <h3></h3><br/><b>Dadri</b></div>',
    title: '',
    lat: 28.5526,
    lng: 77.554,
  },
  {
    customerName: 'Savanah',
    customerAddress:
      '<div><h4>Savannah</h4><p>Transit time FromToSavannah is:<b>0 (Max 0 , Min 0) days</b></p></div>',
    title: '',
    lat: 32.098676,
    lng: -81.109941,
  },
];

const linesDataValue = [
  {
    lat: 28.5526,
    lng: 77.554,
  },
  {
    lat: 28.5526,
    lng: 77.554,
  },
  {
    lat: 22.7333,
    lng: 69.7,
  },
  {
    lat: 22.7333,
    lng: 69.7,
  },
  {
    lat: 22.7382,
    lng: 69.6849,
  },
  {
    lat: 22.7383,
    lng: 69.6849,
  },
  {
    lat: 22.7333,
    lng: 69.7,
  },
  {
    lat: 22.6411,
    lng: 69.5395,
  },
  {
    lat: 22.6029,
    lng: 68.9281,
  },
  {
    lat: 21.8953,
    lng: 68.8629,
  },
  {
    lat: 21.4326,
    lng: 69.1016,
  },
  {
    lat: 20.6525,
    lng: 69.5587,
  },
  {
    lat: 19.4361,
    lng: 70.3019,
  },
  {
    lat: 19.2347,
    lng: 70.4212,
  },
  {
    lat: 19.0241,
    lng: 70.765,
  },
  {
    lat: 18.8819,
    lng: 71.5357,
  },
  {
    lat: 18.8381,
    lng: 72.3223,
  },
  {
    lat: 18.7821,
    lng: 72.6397,
  },
  {
    lat: 18.7823,
    lng: 72.6398,
  },
  {
    lat: 18.786,
    lng: 72.6412,
  },
  {
    lat: 18.7862,
    lng: 72.6403,
  },
  {
    lat: 18.7838,
    lng: 72.6386,
  },
  {
    lat: 18.7832,
    lng: 72.6388,
  },
  {
    lat: 18.7861,
    lng: 72.642,
  },
  {
    lat: 18.7862,
    lng: 72.6416,
  },
  {
    lat: 18.782,
    lng: 72.6402,
  },
  {
    lat: 18.7821,
    lng: 72.64,
  },
  {
    lat: 18.7822,
    lng: 72.6411,
  },
  {
    lat: 18.7862,
    lng: 72.6399,
  },
  {
    lat: 18.7835,
    lng: 72.6385,
  },
  {
    lat: 18.7834,
    lng: 72.6387,
  },
  {
    lat: 18.7826,
    lng: 72.6412,
  },
  {
    lat: 18.8384,
    lng: 72.7339,
  },
  {
    lat: 18.9394,
    lng: 72.9478,
  },
  {
    lat: 18.9474,
    lng: 72.94,
  },
  {
    lat: 18.9473,
    lng: 72.94,
  },
  {
    lat: 18.9473,
    lng: 72.9354,
  },
  {
    lat: 18.8561,
    lng: 72.5326,
  },
  {
    lat: 18.8982,
    lng: 71.899,
  },
  {
    lat: 18.3396,
    lng: 71.2027,
  },
  {
    lat: 18.233,
    lng: 70.7021,
  },
  {
    lat: 18.1714,
    lng: 70.4117,
  },
  {
    lat: 17.475,
    lng: 67.015,
  },
  {
    lat: 18.8498,
    lng: 72.8023,
  },
  {
    lat: 15.19,
    lng: 55.9383,
  },
  {
    lat: 13.265,
    lng: 49.0933,
  },
  {
    lat: 12.9633,
    lng: 48.205,
  },
  {
    lat: 12.0083,
    lng: 45.1002,
  },
  {
    lat: 14.0937,
    lng: 42.3566,
  },
  {
    lat: 15.6874,
    lng: 41.6386,
  },
  {
    lat: 17.1322,
    lng: 40.7983,
  },
  {
    lat: 18.6047,
    lng: 39.9429,
  },
];

const linesRoadValue = [
  [
    {
      lat: 28.5526,
      lng: 77.554,
      id: 0,
    },
  ],
];

const FlagDataValue = [
  {
    title: '',
    lat: 28.5526,
    lng: 77.554,
    customerAddress:
      '<div><h4>Dadri</h4><p>Transit time From Dadri To Dadri is:<b>0 ( Max 0, Min0 ) days</b></p></div>',
  },
  {
    title: '',
    lat: 22.7333,
    lng: 69.7,
    customerAddress:
      '<div><h4>Mundra</h4><p>Transit time From Dadri To Mundra is:<b>0 ( Max 0, Min0 ) days</b></p></div>',
  },
  {
    title: '',
    lat: 22.7333,
    lng: 69.7,
    customerAddress:
      '<div><h4>Mundra</h4><p>Transit time From Mundra To Mundra is:<b>0 ( Max 0, Min0 ) days</b></p></div>',
  },
  {
    title: '',
    lat: 18.8384,
    lng: 72.7339,
    customerAddress:
      '<div><h4></h4><p>Transit time From  To  is:<b>0 ( Max 0, Min0 ) days</b></p></div>',
  },
  {
    title: '',
    lat: 18.9394,
    lng: 72.9478,
    customerAddress:
      '<div><h4>Navi Mumbai (Nhava Sheva)</h4><p>Transit time From  To Navi Mumbai (Nhava Sheva) is:<b>0 ( Max 0, Min0 ) days</b></p></div>',
  },
  {
    title: '',
    lat: 18.6047,
    lng: 39.9429,
    customerAddress:
      '<div><h4></h4><p>Transit time From  To  is:<b>0 ( Max 0, Min0 ) days</b></p></div>',
  },
];

const RailLinesValue = [
  [
    {
      lat: 28.5526,
      lng: 77.554,
      id: 1,
    },
    {
      lat: 22.7333,
      lng: 69.7,
      id: 1,
    },
  ],
];

const linesDataFutureValue = [
  {
    lat: 18.6047,
    lng: 39.9429,
  },
  {
    lat: 18.6047,
    lng: 39.9429,
  },
  {
    lat: 32.098676,
    lng: -81.109941,
  },
];

const shipcoord = [
  { lat: linesDataFutureValue[0].lat, lng: linesDataFutureValue[0].lng },
];

// DEFINING HOW ARROWS SYMBOL ON LINE WILL LOOK LIKE

// Create the polyline and add the symbol to it via the 'icons' property.

export default function shipmentmaps() {
  const [activeMarker, setActiveMarker] = useState(null);
  // let startMarker = null;
  // let wayptMarker = [];

  // linesDataValue.map((item, index, arr) => {
  //   if (index === 0 || index === arr.length - 1) {
  //     //Since the start and end point are the same, I only get the start point details in my startMarker variable
  //     if (index === 0) {
  //       startMarker = item;
  //     }
  //   } else {
  //     wayptMarker.push(item);
  //   }
  // });

  const markers =
    baloonDataValue !== undefined &&
    baloonDataValue &&
    baloonDataValue.map((item, index) => ({
      id: index + 1,
      name: item.customerName,
      position: {
        lat: item && item.lat,
        lng: item && item.lng,
      },
    }));

  const flags =
    FlagDataValue !== undefined &&
    FlagDataValue &&
    FlagDataValue.map((item, index) => ({
      id: index + 1,
      name: `${item.customerName} - ${item.customerAddress}`,
      position: {
        lat: item && item.lat,
        lng: item && item.lng,
      },
    }));
  // const shipcoord = FlagDataValue.length - 1;
  const ship =
    shipcoord !== undefined &&
    shipcoord &&
    shipcoord.map((item, index) => ({
      id: index + 1,
      name: 'Shipcurrent-Location',
      position: {
        lat: item && item.lat,
        lng: item && item.lng,
      },
    }));

  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  const handleOnLoad = (elem) => {
    const bounds = new window.google.maps.LatLngBounds();
    markers?.forEach(({ position }) => {
      bounds.extend(position);
    });
    elem.fitBounds(bounds);
  };
  const lineSymbol = {
    path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
    scale: 2,
    // strokeColor: "#002d73",
    strokeOpacity: 1.5,
  };
  const lnSymbol = {
    path: 'M 0,-1 0,1', // doubt
    strokeOpacity: 1,
    scale: 4,
  };
  const lnSymbolRail = {
    path: window.google.maps.Polyline, // doubt
    strokeOpacity: 1,
    scale: 1,
  };

  return (
    <GoogleMap
      onLoad={handleOnLoad}
      onClick={() => setActiveMarker(null)}
      mapContainerStyle={{ width: '100%', height: '40vh' }}
    >
      {/* for road to rail */}
      <Polyline
        path={
          linesRoadValue !== undefined &&
          linesRoadValue &&
          linesRoadValue[0] &&
          linesRoadValue.map((item) => ({
            lat: item && item.lat,
            lng: item && item.lng,
          }))
        }
        options={{
          strokeColor: '#FFFF00',
          strokeOpacity: 2,
          strokeWeight: 8,
          icons: [
            {
              icon: lnSymbol,
              offset: '0',
              repeat: '1.181vw',
            },
          ],
        }}
      />
      {/* for rail */}
      <Polyline
        path={
          RailLinesValue !== undefined &&
          RailLinesValue &&
          RailLinesValue[0] &&
          RailLinesValue.map((item) => ({
            lat: item && item.lat,
            lng: item && item.lng,
          }))
        }
        options={{
          strokeColor: '#007FFF',
          strokeOpacity: 2,
          strokeWeight: 2,
          icons: [
            {
              icon: lnSymbolRail,
              offset: '0',
              repeat: '1.181vw',
            },
          ],
        }}
      />
      {/* for lines data future */}
      <Polyline
        path={
          linesDataFutureValue !== undefined &&
          linesDataFutureValue &&
          linesDataFutureValue[0] &&
          linesDataFutureValue.map((item) => ({
            lat: item && item.lat,
            lng: item && item.lng,
          }))
        }
        options={{
          strokeColor: '#FFC300',
          strokeOpacity: 1,
          strokeWeight: 2,
          icons: [
            {
              icon: lineSymbol,
              offset: '5%',
            },
            {
              icon: lineSymbol,
              offset: '20%',
            },
            {
              icon: lineSymbol,
              offset: '40%',
            },
            {
              icon: lineSymbol,
              offset: '60%',
            },
            {
              icon: lineSymbol,
              offset: '80%',
            },
            {
              icon: lineSymbol,
              offset: '100%',
            },
          ],
        }}
      />
      {/* for lines data */}

      <Polyline
        path={
          linesDataValue !== undefined &&
          linesDataValue &&
          linesDataValue[0] &&
          linesDataValue.map((item) => ({
            lat: item && item.lat,
            lng: item && item.lng,
          }))
        }
        options={{
          strokeColor: '#007FFF',
          strokeOpacity: 1,
          strokeWeight: 2,
          icons: [
            {
              icon: lineSymbol,
              offset: '10%', // doubt
            },
            {
              icon: lineSymbol,
              offset: '20%',
            },
            {
              icon: lineSymbol,
              offset: '30%',
            },
            {
              icon: lineSymbol,
              offset: '40%',
            },
            {
              icon: lineSymbol,
              offset: '50%',
            },
            {
              icon: lineSymbol,
              offset: '60%',
            },
            {
              icon: lineSymbol,
              offset: '70%',
            },
            {
              icon: lineSymbol,
              offset: '80%',
            },
            {
              icon: lineSymbol,
              offset: '90%',
            },
            {
              icon: lineSymbol,
              offset: '100%',
            },
          ],
        }}
      />
      {/* The following Marker object will only create markers for the middle points */}
      {markers &&
        markers.map(({ id, name, position }) => (
          <Marker
            key={id}
            position={position}
            onClick={() => handleActiveMarker(id)}
          >
            {activeMarker === id ? (
              <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                <div>{name}</div>
              </InfoWindow>
            ) : null}
          </Marker>
        ))}

      {flags &&
        flags.map(({ id, name, position }) => (
          <Marker
            key={id}
            position={position}
            onClick={() => handleActiveMarker(id)}
            icon="https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png"
          >
            {activeMarker === id ? (
              <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                <div>{name}</div>
              </InfoWindow>
            ) : null}
          </Marker>
        ))}

      {ship &&
        ship.map(({ id, name, position }) => (
          <Marker
            key={id}
            position={position}
            onClick={() => handleActiveMarker(id)}
            icon={shipicon}
          >
            {activeMarker === id ? (
              <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                <div>{name}</div>
              </InfoWindow>
            ) : null}
          </Marker>
        ))}
    </GoogleMap>
  );
}
