import { gql } from '@apollo/client';

const QUERY_LOCATIONWISE_STRUCTURE = gql`
  query LocationTreeStructure($input: locationTreeInput) {
    LocationTreeStructure(input: $input) {
      message
      data
    }
  }
`;

export const QUERY_ADD_CONTACT_INTO_ENTITY = gql`
  mutation AddUserInEnntity($input: addUserEntityInput) {
    addUserInEnntity(input: $input) {
      message
    }
  }
`;

export const QUERY_ADDCONTACT = gql`
  mutation Mutation($input: AddUserInput) {
    addUserIntoOrg(input: $input) {
      message
      data
    }
  }
`;

export const QUERY_EDIT_CONTACT = gql`
  mutation Mutation($input: EditOrgUser) {
    editUser(input: $input) {
      message
    }
  }
`;

export default QUERY_LOCATIONWISE_STRUCTURE;
