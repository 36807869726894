import { combineReducers } from 'redux';
// eslint-disable-next-line
import createOrgWiseNotificationMap from '../../helpers/notificationHelper';
import {
  ORGANIZATION_DETAILS,
  LIST_ORGANIZATION_USERS,
  LIST_ORGANIZATION_ENTITIES,
  PROFILE_DETAILS,
  LEFTMENUSUPPLIERBUYER,
  GROUPLISTMENU,
  LISTSUPPLIERBUYER,
  GROUPINPUT,
  TOGGLESTATE,
  SELECTED_SEARCH_ORG,
  NOTIFICATIONS_LOADED,
  NOTIFICATIONS_READ,
  NOTIFICATIONS_CREATED,
  CHANNEL_DATA,
} from '../actions/actionTypes';

let initialState = {
  fetchOrganizationDetail: [],
  fetchOrganizationUsers: [],
  fetchOrganizationEntities: [],
  addLeftMenuDetails: [],
  channelPartnerData: {},
};
let initialProfileState = {
  profileDetail: [],
};
let initialStateData = {
  inputStructureGroup: {},
  toggleState: '',
  selectedSearchOrg: {},
};

let initialNotificationState = {};

const omniDataReducer = (initialstate = initialState, action) => {
  switch (action.type) {
    case ORGANIZATION_DETAILS:
      initialState = {
        ...initialstate,
        fetchOrganizationDetail: action.payload,
      };
      break;
    case LIST_ORGANIZATION_USERS:
      initialState = {
        ...initialstate,
        fetchOrganizationUsers: action.payload,
      };
      break;
    case LIST_ORGANIZATION_ENTITIES:
      initialState = {
        ...initialstate,
        fetchOrganizationEntities: action.payload,
      };
      break;
    case LEFTMENUSUPPLIERBUYER:
      initialState = {
        ...initialState,
        addLeftMenuDetails: action.payload,
      };
      break;
    case GROUPLISTMENU:
      initialState = {
        ...initialState,
        addGroupMenuDetails: action.payload,
      };
      break;
    case LISTSUPPLIERBUYER:
      initialState = {
        ...initialState,
        listsupplierbuyer: action.payload,
      };
      break;
    case CHANNEL_DATA:
      initialState = {
        ...initialState,
        channelPartnerData: action.payload,
      };
      break;
    default:
      return initialState;
  }
  return initialState;
};
const omniProfileDataReducer = (initialstate = initialProfileState, action) => {
  switch (action.type) {
    case PROFILE_DETAILS:
      initialProfileState = {
        ...initialstate,
        profileDetail: action.payload,
      };
      break;
    default:
      return initialProfileState;
  }
  return initialProfileState;
};

const omniStateDataReducers = (initialstate = initialStateData, action) => {
  switch (action.type) {
    case GROUPINPUT:
      initialStateData = {
        ...initialstate,
        inputStructureGroup: action.payload,
      };
      break;
    case TOGGLESTATE:
      initialStateData = {
        ...initialstate,
        toggleState: action.payload,
      };
      break;
    case SELECTED_SEARCH_ORG:
      initialStateData = {
        ...initialstate,
        selectedSearchOrg: action.payload,
      };
      break;
    default:
      return initialStateData;
  }
  return initialStateData;
};

const notificationReducer = (
  initialstateNoti = initialNotificationState,
  action
) => {
  switch (action.type) {
    case NOTIFICATIONS_LOADED:
      initialNotificationState = action.payload;
      break;
    case NOTIFICATIONS_CREATED:
      initialNotificationState = createOrgWiseNotificationMap(
        [action.payload],
        initialNotificationState
      );
      break;
    case NOTIFICATIONS_READ: {
      const selectedId = localStorage.getItem('activeOrg');
      const notifications = initialstateNoti[selectedId];
      ['order', 'booking', 'shipment']?.forEach((item) => {
        action?.payload?.forEach((notification) => {
          if (notifications?.[item]?.[notification] !== undefined) {
            delete notifications?.[item]?.[notification];
            initialNotificationState[selectedId].totalCount -= 1;
          }
        });
      });
      initialNotificationState[selectedId] = notifications;
      break;
    }
    default:
      return initialNotificationState;
  }
  return initialNotificationState;
};

const rootReducer = combineReducers({
  omniDetails: omniDataReducer,
  omniProfile: omniProfileDataReducer,
  omniState: omniStateDataReducers,
  onmiNotifications: notificationReducer,
});
export default rootReducer;
