import React from 'react';
import PropTypes from 'prop-types';
import './icon-button.scss';

/**
 * @typedef {'default' | 'primary' | 'grey' | 'add'} VerientType
 * @typedef {'default' | 'lardge' | 'xMedium' | 'medium' | 'small'} SizeType
 */

/**
 * Component to demonstrate different sizes, appearances, and popup options.
 * @param {Object} props - Component props.
 * @param {VerientType} props.verient - Size of the component ('Small' or 'Large').
 * @param {SizeType} props.size - Size of the component ('Small' or 'Large').
 * @returns {JSX.Element} - Rendered component.
 */

export default function IconButton({
  onClick,
  type,
  verient,
  size,
  disabled,
  margin,
  className,
  ...props
}) {
  const verientProperties = {
    default: 'default',
    primary: 'primary',
    grey: 'grey',
    add: 'add',
  };
  const sizeProperties = {
    default: 's-default',
    large: 's-large',
    xMedium: 's-x-medium',
    medium: 's-medium',
    small: 's-small',
  };
  const verientProps = verientProperties[verient];
  const sizeProps = sizeProperties[size];
  return (
    <button
      className={`icon-button ${type !== '' ? type : 'default'}${
        className ? ` ${className}` : ''
      }${` ${verientProps}`}${` ${sizeProps}`}`}
      type="button"
      style={{ margin }}
      onClick={onClick}
      disabled={disabled}
    >
      {props.children}
    </button>
  );
}

IconButton.propTypes = {
  type: PropTypes.string,
  size: PropTypes.string,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  verient: PropTypes.string,
  margin: PropTypes.string,
  className: PropTypes.string,
};
IconButton.defaultProps = {
  type: '',
  size: '2.083vw',
  onClick: () => {},
  disabled: false,
  verient: '',
  margin: '',
  className: '',
};
