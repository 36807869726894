import React from 'react';
import PropTypes from 'prop-types';
import './multi-factor-option.scss';
import CustomSwitch from '../custom-switch/custom-switch';
import { DocumentDownloadIcon } from '../icons/icons';

function MultiFactorOption({
  label,
  onChange,
  onClick,
  actionable,
  defaultChecked,
}) {
  return (
    <div className="multi-factor-option">
      {label && <p>{label} </p>}

      {actionable ? (
        <button type="button" onClick={onClick} className="button-transparent">
          <DocumentDownloadIcon />
        </button>
      ) : (
        <CustomSwitch onChange={onChange} defaultChecked={defaultChecked} />
      )}
    </div>
  );
}

export default MultiFactorOption;
MultiFactorOption.propTypes = {
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  label: PropTypes.string,
  actionable: PropTypes.bool,
  defaultChecked: PropTypes.bool,
};
MultiFactorOption.defaultProps = {
  onChange: () => {},
  onClick: () => {},
  label: '',
  actionable: false,
  defaultChecked: false,
};
