import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Tab extends Component {
  onClick = () => {
    const { value, onClick } = this.props;
    onClick(value);
  };

  render() {
    const {
      onClick,
      props: { activeTab, label, icon, notification, completed, value },
    } = this;

    let className = 'custom-tab-nav-item';

    if (activeTab === value || completed) {
      className += ' active';
    }

    return (
      label && (
        <li data-cy="tab-item" className={className}>
          <button type="button" onClick={onClick}>
            {icon} {label}
            {false && notification && <span className="notificatio-dot" />}
          </button>
        </li>
      )
    );
  }
}
Tab.propTypes = {
  activeTab: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.node.isRequired,
  notification: PropTypes.bool,
  completed: PropTypes.bool,
  value: PropTypes.string.isRequired,
};
Tab.defaultProps = {
  notification: false,
  completed: false,
};
export default Tab;
