import React from 'react';
import PropTypes from 'prop-types';
import './stacked-bar.scss';
import Tooltip from '../tooltip/tooltip';

function StackedBar({ data, height, labels }) {
  return (
    <div className="stacked-bar-container">
      <table style={{ height }}>
        {data?.map((val) => {
          return (
            <tr>
              <td>
                <span className="label">{val?.titleText}</span>
              </td>
              <td align="right">
                <div className="d-flex align-center justify-between pt-5">
                  <div className="stacked-bg">
                    <div
                      className="stacked-container"
                      style={{ width: val?.score }}
                    >
                      {val?.evaluation?.map((chVal) => {
                        return (
                          <div
                            className="stacked-item"
                            style={{
                              width: chVal?.evalutionScore,
                              backgroundColor: chVal?.color,
                            }}
                          >
                            <Tooltip
                              content={`${chVal?.evalutionType} ${chVal?.evalutionScore}`}
                            >
                              &nbsp;
                            </Tooltip>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <p className="score">{val?.score}</p>
                </div>
                <p
                  className={`score ${
                    val?.performance?.toLowerCase() === 'very poor'
                      ? 'very-poor'
                      : val?.performance?.toLowerCase()
                  }`}
                >
                  {val?.performanceTxt || val?.performance}
                </p>
              </td>
            </tr>
          );
        })}
      </table>
      {labels && (
        <ul className="status">
          {labels?.map((labelBG) => {
            return (
              <li>
                <span className="dot" style={{ background: labelBG?.color }} />{' '}
                {labelBG?.labelName}{' '}
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
}

export default StackedBar;
StackedBar.propTypes = {
  data: PropTypes.instanceOf(Object),
  labels: PropTypes.instanceOf(Object),
  height: PropTypes.string,
};

StackedBar.defaultProps = {
  data: {},
  labels: {},
  height: '',
};
