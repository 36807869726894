import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import './pagination.scss';
import { LeftArrowIcon, RightArrowIcon } from '../icons/icons';

export default function Pagination({
  totalData,
  perPage,
  paginationData,
  currentPage,
  swicthPage,
  disablePagination,
  className,
}) {
  const [t] = useTranslation('manageorders');
  const pageLimit = 10;
  const pageToShowFunc = (data, currPage) => {
    const totalPage = data.length;
    const returnData = [];
    let startPage =
      currPage > Math.floor(pageLimit / 2)
        ? Math.floor(currPage - Math.floor(pageLimit / 2))
        : 1;

    if (totalPage - startPage < pageLimit) {
      startPage = totalPage - pageLimit;
    }
    if (totalPage < pageLimit) {
      return data;
    }
    for (let iCtr = startPage - 1; iCtr < startPage + pageLimit; iCtr++) {
      if (data[iCtr] && data[iCtr] > 0) {
        returnData.push(data[iCtr]);
      }
    }
    if (returnData[0] !== 1) {
      returnData.unshift('...');
      returnData.unshift(1);
    }
    if (returnData[returnData.length - 1] !== totalPage) {
      returnData.push('...');
      returnData.push(totalPage);
    }
    return returnData;
  };
  const pageToShow = pageToShowFunc(paginationData, currentPage);

  return (
    <div
      className={`custom-pagination${disablePagination ? ' justify-end' : ''}${
        className ? ` ${className}` : ''
      }`}
    >
      {!disablePagination && (
        <span className="page-count">{`${t(
          'manageordersscreen.createbookingmenu.pagination.showingrecords'
        )} ${currentPage * perPage - perPage + 1} ${t(
          'manageordersscreen.createbookingmenu.pagination.to'
        )} ${
          currentPage * perPage < totalData ? currentPage * perPage : totalData
        } ${t(
          'manageordersscreen.createbookingmenu.pagination.of'
        )} ${totalData}`}</span>
      )}
      <div className="paging-block">
        {!disablePagination && (
          <span className="page-count">
            {t('manageordersscreen.createbookingmenu.pagination.page')}
          </span>
        )}
        <button
          type="button"
          onClick={() => swicthPage(1)}
          disabled={currentPage === 1}
        >
          |<LeftArrowIcon />
        </button>
        <button
          type="button"
          onClick={() => swicthPage(currentPage - 1)}
          disabled={currentPage === 1}
        >
          <LeftArrowIcon />
        </button>

        {pageToShow.map((page) => {
          if (page !== '...') {
            return (
              <button
                className={currentPage === page && 'active'}
                type="button"
                id={page}
                onClick={() => swicthPage(page)}
              >
                {page}
              </button>
            );
          }
          return <button type="button">{page}</button>;
        })}
        <button
          type="button"
          onClick={() => swicthPage(currentPage + 1)}
          disabled={currentPage === paginationData.length}
        >
          <RightArrowIcon />
        </button>
        <button
          type="button"
          onClick={() => swicthPage(paginationData.length)}
          disabled={currentPage === paginationData.length}
        >
          <RightArrowIcon />|
        </button>
      </div>
    </div>
  );
}
Pagination.propTypes = {
  totalData: PropTypes.number,
  perPage: PropTypes.number,
  paginationData: PropTypes.instanceOf(Array),
  currentPage: PropTypes.number,
  swicthPage: PropTypes.func,
  disablePagination: PropTypes.bool,
  className: PropTypes.string,
};
Pagination.defaultProps = {
  totalData: 0,
  perPage: 25,
  paginationData: [],
  currentPage: 1,
  swicthPage: () => {},
  disablePagination: false,
  className: '',
};
