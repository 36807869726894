import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import './multi-select.scss';
import { CloseIcon, ShortDownIcon } from '../icons/icons';
// import Checkbox from '../custom-checkbox/custom-checkbox';
// import ReactCheckbox from '../custom-checkbox/react-check-box';
// import RoundCheckbox from '../custom-checkbox/round-check-box';
import multiSelectOption from './multi-select-option';

function MultiSelect({
  label,
  // placeholder,
  required,
  data,
  handleData,
  defaultOption,
  errorMessage,
  disabled,
  zIndex,
  iconPadding,
  width,
  mBottom,
  mode,
  children,
}) {
  const optionsWrapper = useRef(null);
  const optionsContainer = useRef(null);
  // const [optionWrapperWidth, setOptionWrapperWidth] = useState();
  // const [optionsContainerWidth, setOptionsContainerWidth] = useState();
  // const [textBoxWidth, setTextBoxWidth] = useState();
  const [listTop, setListTop] = useState();
  const [listLeft, setListLeft] = useState();
  const [listWidth, setListWidth] = useState();
  const [boxLeft, setBoxLeft] = useState();
  const [boxTop, setBoxTop] = useState();
  const [boxHeight, setBoxHeight] = useState();
  const [selectedId, setSelectedIds] = useState('');
  const [showLabel, setShowLabel] = useState([]);
  const [toggleMultiList, setToggleMultiList] = useState(false);
  const [parentId, setParentId] = useState();
  // const [toggleChild, setToggleChild] = useState([]);
  // const [globalCheck, setGlobalCheck] = useState(false);
  // setOptionWrapperWidth(optionsWrapper.current?.getBoundingClientRect());

  const handleChange = (e) => {
    const childIds = [];
    e.target.value?.split(',')?.forEach((item) => {
      if (item) {
        childIds.push(Number(item));
      }
    });
    const selfId = Number(e.target.id);
    if (e.target.checked) {
      setShowLabel(e.target.name);
      setSelectedIds([...childIds, selfId]);
      setParentId(selfId);
      handleData(selfId);
    } else {
      setSelectedIds((prev) =>
        prev.filter((item) => !childIds.includes(item) && item !== selfId)
      );
      if (parentId === selfId) {
        setParentId();
        setShowLabel();
        handleData('');
      }
    }
  };

  useEffect(() => {
    setShowLabel(() => defaultOption.parentName);
    setSelectedIds(() => defaultOption.childIds);
    setParentId(() => defaultOption.parentId);
  }, [defaultOption]);

  // const handleToggleChild = (val) => {
  //   const toggleChildId = val.target.value.split(',').map(Number);
  //   setToggleChild(toggleChildId);
  // };

  // useEffect(() => {
  //   setOptionWrapperWidth(
  //     optionsWrapper?.current?.getBoundingClientRect()?.width
  //   );
  // }, [optionsWrapper?.current]);
  // useEffect(() => {
  //   setOptionsContainerWidth(
  //     optionsContainer?.current?.getBoundingClientRect()?.width
  //   );
  // }, [optionsContainer?.current]);
  // useEffect(() => {
  //   if (optionsContainerWidth >= optionWrapperWidth / 1.5) {
  //     setTextBoxWidth(optionWrapperWidth - 24);
  //   } else {
  //     setTextBoxWidth(optionWrapperWidth - optionsContainerWidth - 28);
  //   }
  // }, [optionsContainer?.current]);

  const toggleList = () => {
    setToggleMultiList(!toggleMultiList);
    setBoxLeft(optionsWrapper?.current?.getBoundingClientRect()?.left);
    setBoxTop(optionsWrapper?.current?.getBoundingClientRect()?.top);
    setBoxHeight(optionsWrapper?.current?.getBoundingClientRect()?.height);
    setListTop(boxTop + boxHeight);
    setListLeft(boxLeft);
    setListWidth(optionsWrapper?.current?.getBoundingClientRect()?.width);
  };
  const showList = () => {
    setToggleMultiList(true);
    setBoxLeft(optionsWrapper?.current?.getBoundingClientRect()?.left);
    setBoxTop(optionsWrapper?.current?.getBoundingClientRect()?.top);
    setBoxHeight(optionsWrapper?.current?.getBoundingClientRect()?.height);
    setListTop(boxTop + boxHeight);
    setListLeft(boxLeft);
    setListWidth(optionsWrapper?.current?.getBoundingClientRect()?.width);
  };

  // const handleChange = (e) => {
  //   setQuery(e.target.value.toLowerCase());
  // };
  // const toggleSelf = (e) => {
  //   setCurrentID(e.target.id);
  //   setToggleChild(!toggleChild);
  // };
  // const handleCheck = () => {
  //   setGlobalCheck(!globalCheck);
  // };
  return (
    <div
      data-cy="drop-down-form"
      className={`multi-select${mode === 'material' ? ' material' : ''}`}
      style={{ marginBottom: mBottom }}
    >
      <label
        className={`multi-select-label${
          toggleMultiList || (showLabel && showLabel?.length !== 0)
            ? ' active'
            : ''
        }`}
      >
        {label}
        {required && <span className="required">*</span>}
      </label>
      <div
        className="multi-select-container"
        ref={optionsWrapper}
        onClick={toggleList}
        role="button"
        onKeyDown={showList}
        tabIndex="0"
      >
        <span className="icon">{children}</span>
        <span className="arrow" style={{ padding: iconPadding, width }}>
          <ShortDownIcon />
        </span>

        <div className="content" ref={optionsContainer}>
          {showLabel && showLabel?.length !== 0 && (
            <>
              <span className="label">
                {showLabel}
                <button
                  className="button-transparent"
                  type="button"
                  onClick={() => {
                    setShowLabel();
                    setParentId();
                    setSelectedIds([]);
                    handleData('');
                  }}
                  disabled={disabled}
                >
                  <CloseIcon />
                </button>
              </span>
              {selectedId && selectedId.length - 1 > 0 && (
                <span className="label count">+{selectedId.length - 1}</span>
              )}
            </>
          )}
        </div>
        {/* {selectedId?.length === 0 && (
          <input
            type="text"
            style={{ width: textBoxWidth }}
            placeholder={toggleMultiList ? placeholder : ''}
            // onChange={handleChange}
            disabled={disabled}
          />
        )} */}
      </div>
      {toggleMultiList && (
        <>
          <div
            className="multi-select-mask"
            onClick={toggleList}
            role="button"
            onKeyDown={toggleList}
            tabIndex="0"
          >
            &nbsp;
          </div>
          <div
            className="multi-select-options"
            style={{ width: listWidth, top: listTop, left: listLeft, zIndex }}
          >
            {multiSelectOption(
              data,
              true,
              true,
              handleChange,
              selectedId,
              parentId,
              disabled,
              zIndex
              // toggleChild
              // handleToggleChild
            )}
          </div>
        </>
      )}
      {errorMessage && <p className="error-message">{errorMessage}</p>}
    </div>
  );
}

export default MultiSelect;
MultiSelect.propTypes = {
  // placeholder: PropTypes.string,
  errorMessage: PropTypes.string,
  // onChange: PropTypes.func,
  data: PropTypes.instanceOf(Array),
  handleData: PropTypes.func,
  label: PropTypes.string,
  required: PropTypes.string,
  // errorMessage: PropTypes.string,
  // message: PropTypes.string,
  defaultOption: PropTypes.instanceOf(Object),
  disabled: PropTypes.bool,
  zIndex: PropTypes.number,
  iconPadding: PropTypes.string,
  width: PropTypes.string,
  mBottom: PropTypes.string,
  mode: PropTypes.string,
  children: PropTypes.node,
};
MultiSelect.defaultProps = {
  // placeholder: '',
  // defaultValue: '',
  // onChange: () => {},
  data: [],
  handleData: () => {},
  label: '',
  required: '',
  defaultOption: {
    parentName: [],
    childIds: [],
    parentId: '',
  },
  errorMessage: '',
  // message: '',
  disabled: false,
  zIndex: 6,
  iconPadding: '',
  width: '',
  mBottom: '',
  mode: '',
  children: [],
};
