import {
  ORGANIZATION_DETAILS,
  LIST_ORGANIZATION_USERS,
  LIST_ORGANIZATION_ENTITIES,
  PROFILE_DETAILS,
  LEFTMENUSUPPLIERBUYER,
  GROUPLISTMENU,
  LISTSUPPLIERBUYER,
  GROUPINPUT,
  TOGGLESTATE,
  SELECTED_SEARCH_ORG,
  NOTIFICATIONS_LOADED,
  NOTIFICATIONS_CREATED,
  NOTIFICATIONS_READ,
  CHANNEL_DATA,
} from './actionTypes';

export function addOrganizationDetails(values) {
  return {
    type: ORGANIZATION_DETAILS,
    payload: values,
  };
}

export function addOrganizationUsers(values) {
  return {
    type: LIST_ORGANIZATION_USERS,
    payload: values,
  };
}

export function addOrganizationEntities(values) {
  return {
    type: LIST_ORGANIZATION_ENTITIES,
    payload: values,
  };
}

export function addProfileDetails(values) {
  return {
    type: PROFILE_DETAILS,
    payload: values,
  };
}

export const addChannelPartnerData = (values) => {
  return {
    type: CHANNEL_DATA,
    payload: values,
  };
};

export function addLeftMenuDetails(values) {
  return {
    type: LEFTMENUSUPPLIERBUYER,
    payload: values,
  };
}

export function addGroupMenuDetails(values) {
  return {
    type: GROUPLISTMENU,
    payload: values,
  };
}

export function listsupplierbuyer(values) {
  return {
    type: LISTSUPPLIERBUYER,
    payload: values,
  };
}

export function inputStructureGroup(values) {
  return {
    type: GROUPINPUT,
    payload: values,
  };
}

export function toggleState(values) {
  return {
    type: TOGGLESTATE,
    payload: values,
  };
}

export function selectedSearchOrg(values) {
  return {
    type: SELECTED_SEARCH_ORG,
    payload: values,
  };
}

export const storeNotificationsInStore = (data) => {
  return {
    type: NOTIFICATIONS_LOADED,
    payload: data,
  };
};

export const newNotificationAdded = (data) => {
  return {
    type: NOTIFICATIONS_CREATED,
    payload: data,
  };
};

export const readNotification = (data) => {
  return {
    type: NOTIFICATIONS_READ,
    payload: data,
  };
};
