import React from 'react';
import PropTypes from 'prop-types';
import './card-panel.scss';

function PanelCardFooter({ align, ...props }) {
  return (
    <div data-cy="panel-card-footer" className={`card-footer ${align}`}>
      {props.children}
    </div>
  );
}

export default PanelCardFooter;
PanelCardFooter.propTypes = {
  children: PropTypes.node.isRequired,
  align: PropTypes.string,
};
PanelCardFooter.defaultProps = {
  align: 'center' || 'right',
};
