import React from 'react';
import PropTypes from 'prop-types';
import './account-settings-panel.scss';

function AccountSettingsPanelHead({ ...props }) {
  return (
    <div className="d-flex justify-between align-start">{props.children}</div>
  );
}

export default AccountSettingsPanelHead;
AccountSettingsPanelHead.propTypes = {
  children: PropTypes.node,
};
AccountSettingsPanelHead.defaultProps = {
  children: {},
};
