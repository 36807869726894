import React from 'react';
import './lardge-widgets.scss';
import DashboardCardPanel from '../../../../Components/dashboard-components/dashboard-card-panel/dashboard-card-panel';
import DashboardCardPanelHeader from '../../../../Components/dashboard-components/dashboard-card-panel/dashboard-card-panel-header';
import DashboardCardPanelBody from '../../../../Components/dashboard-components/dashboard-card-panel/dashboard-card-panel-body';
import Skeleton from '../../../../Components/skeleton/skeleton';

function LargeWidgetsLoader() {
  return (
    <div className="large-widgets-container">
      <DashboardCardPanel>
        <DashboardCardPanelHeader
          inline
          title={
            <Skeleton
              skHeight="1.042vw"
              skMargin="0.694vw 0"
              skWidth="13.889vw"
            />
          }
        >
          <Skeleton skHeight="1.111vw" skMargin="0" skWidth="1.111vw" />
          <Skeleton
            skHeight="1.111vw"
            skMargin="0 0 0 0.694vw"
            skWidth="1.111vw"
          />
          <Skeleton
            skHeight="1.111vw"
            skMargin="0 0 0 0.694vw"
            skWidth="1.111vw"
          />
          <Skeleton
            skHeight="1.111vw"
            skMargin="0 0 0 0.694vw"
            skWidth="1.111vw"
          />
        </DashboardCardPanelHeader>
        <DashboardCardPanelBody className="h-300">
          <Skeleton
            skHeight="2.083vw"
            skMargin="0 0 0.694vw 0 "
            skWidth="50%"
          />
          <Skeleton
            skHeight="2.083vw"
            skMargin="0 0 0.694vw 0 "
            skWidth="30%"
          />
          <Skeleton
            skHeight="2.083vw"
            skMargin="0 0 0.694vw 0 "
            skWidth="70%"
          />
          <Skeleton
            skHeight="2.083vw"
            skMargin="0 0 0.694vw 0 "
            skWidth="50%"
          />
        </DashboardCardPanelBody>
      </DashboardCardPanel>
    </div>
  );
}

export default LargeWidgetsLoader;
