import React, { useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import store from '../../redux/store';

import './new-dashboard.scss';
import AdvanceSelectLabel from '../../Components/advance-select/advance-select-label';
import {
  DashboardGridIcon,
  SliderNextIcon,
  SliderPrevIcon,
} from '../../Components/icons/icons';
import DashboardSearch from '../../Components/dashboard-components/dashboard-search/dashboard-search';

import SmallWidget from './widgets/small-widgets/small-widget';
import DashboardData from './widgets/widgets';
import LargeWidgets from './widgets/lardge-widgets/lardge-widgets';
import { gqlMicroServiceTokenClient } from '../../service/gqlClient';
import QUERY_DASHBOARDWIDGET, {
  QUERY_DASHBOARDDETAILWIDGET,
  QUERY_REMOVESUBSCRIPTION,
} from '../../graphQL/dashboardwidget';
import WidgetDetail from './widgets/widget-details/widget-details';
import AddNewWidget from './widgets/add-new/add-new';
import DashboardCardLoader from './dashboard-card/loader';
import LargeWidgetsLoader from './widgets/lardge-widgets/loader';
import { giveProfile } from '../../Components/global-notification/notification-list';
import NewButton from '../../Components/button/newButton';
import CardPanel from '../../Components/card-panel/card-panel';
import CardPanelBody from '../../Components/card-panel/card-panel-body';
import BarPanel from '../../Components/bar-panel/bar-panel';
import StackedBar from '../../Components/chart/stacked-bar';

// import ChartJsChart from '../../Components/chart/chart';

function NewDashboard({ profileDetail }) {
  // For dummy data
  const operationalRatings = [
    {
      score: '66%',
      performance: 'Good',
      evaluation: [
        {
          evalutionType: 'Excellent',
          evalutionScore: '15%',
          color: '#603ACB',
        },
        {
          evalutionType: 'Good',
          evalutionScore: '40%',
          color: '#0088FA',
        },
        {
          evalutionType: 'Fair',
          evalutionScore: '20%',
          color: '#F2C94C',
        },
        {
          evalutionType: 'Poor',
          evalutionScore: '10%',
          color: '#F29C4C',
        },
        {
          evalutionType: 'Very Poor',
          evalutionScore: '15%',
          color: '#F24C4C',
        },
      ],
      text: 'Overall average',
      titleText: 'On Time Rate',
    },
    {
      score: '85%',
      performance: 'Excellent',
      evaluation: [
        {
          evalutionType: 'Excellent',
          evalutionScore: '55%',
          color: '#603ACB',
        },
        {
          evalutionType: 'Good',
          evalutionScore: '30%',
          color: '#0088FA',
        },
        {
          evalutionType: 'Fair',
          evalutionScore: '5%',
          color: '#F2C94C',
        },
        {
          evalutionType: 'Poor',
          evalutionScore: '5%',
          color: '#F29C4C',
        },
        {
          evalutionType: 'Very Poor',
          evalutionScore: '5%',
          color: '#F24C4C',
        },
      ],
      text: 'Overall average',
      createdAt: '1696498912392',
      titleText: 'Responsiveness',
    },
    {
      score: '95%',
      performance: 'Excellent',
      evaluation: [
        {
          evalutionType: 'Excellent',
          evalutionScore: '75%',
          color: '#603ACB',
        },
        {
          evalutionType: 'Good',
          evalutionScore: '25%',
          color: '#0088FA',
        },
        {
          evalutionType: 'Fair',
          evalutionScore: '0%',
          color: '#F2C94C',
        },
        {
          evalutionType: 'Poor',
          evalutionScore: '0%',
          color: '#F29C4C',
        },
        {
          evalutionType: 'Very Poor',
          evalutionScore: '0%',
          color: '#F24C4C',
        },
      ],
      text: 'Overall average',
      createdAt: '1696498912392',
      titleText: 'Document centralisation',
    },
    {
      score: '65%',
      performance: 'Good',
      evaluation: [
        {
          evalutionType: 'Excellent',
          evalutionScore: '5%',
          color: '#603ACB',
        },
        {
          evalutionType: 'Good',
          evalutionScore: '50%',
          color: '#0088FA',
        },
        {
          evalutionType: 'Fair',
          evalutionScore: '15%',
          color: '#F2C94C',
        },
        {
          evalutionType: 'Poor',
          evalutionScore: '25%',
          color: '#F29C4C',
        },
        {
          evalutionType: 'Very Poor',
          evalutionScore: '5%',
          color: '#F24C4C',
        },
      ],
      text: 'Overall average',
      createdAt: '1696498912392',
      titleText: 'Lead Time',
    },
  ];
  const operationalLabels = [
    {
      labelName: 'Very Poor',
      color: '#F24C4C',
    },
    {
      labelName: 'Poor',
      color: '#F29C4C',
    },
    {
      labelName: 'Fair',
      color: '#F2C94C',
    },
    {
      labelName: 'Good',
      color: '#3B86F2',
    },
    {
      labelName: 'Excellent',
      color: '#603ACB',
    },
  ];
  // For dummy data end

  const getOmniProfile = () => {
    return store.getState().omniDetails.addLeftMenuDetails;
  };
  const details = getOmniProfile();

  const { relation, companyId } = useParams();

  const [enableRemove, setEnableRemove] = useState(false);
  const [detailsLoading, setDetailsLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [dashboardWidgetData] = useLazyQuery(QUERY_DASHBOARDWIDGET, {
    client: gqlMicroServiceTokenClient,
  });

  const [removeSubscriptionWidget] = useMutation(QUERY_REMOVESUBSCRIPTION, {
    client: gqlMicroServiceTokenClient,
  });

  const [dashboardDetailWidgetData] = useLazyQuery(
    QUERY_DASHBOARDDETAILWIDGET,
    {
      client: gqlMicroServiceTokenClient,
    }
  );

  const [dynamicData, setDynamicData] = useState([]);
  const [dataFilter, setDataFilter] = useState('yearly');
  const [currentPage, setCurrentPage] = useState(1);

  const [t] = useTranslation('dashboard');

  const mainData = () => {
    setLoading(true);
    try {
      dashboardWidgetData({
        variables: {
          input: {
            period: dataFilter,
            profile: giveProfile(relation),
            page: currentPage,
            ...(details?.buyerSupplierId && {
              partnerId: Number(details?.buyerSupplierId),
              profile: relation,
            }),
            language: window.localStorage.getItem('language') || 'en',
          },
        },

        onCompleted: (resp) => {
          setDynamicData(resp);
          setLoading(false);
        },
      });
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };
  useEffect(() => {
    mainData();
  }, [dataFilter, currentPage, relation, details?.buyerSupplierId, companyId]);

  const removeWidget = (widgetKey) => {
    try {
      removeSubscriptionWidget({
        variables: {
          input: {
            widgetKey,
            profile: giveProfile(relation),
          },
        },

        onCompleted: () => {
          mainData();
          setEnableRemove(false);
        },
      });
    } catch (error) {
      console.error(error);
      //  setLoading(false);
    }
  };
  const [widget, setWidget] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [widgetDetail, setWidgetDetail] = useState([]);
  const [activeCard, setActiveCard] = useState('');
  const [supplierComp, setSupplierComp] = useState('');
  useEffect(() => {
    setDetailsLoading(true);
    try {
      if (widget) {
        dashboardDetailWidgetData({
          variables: {
            input: {
              widgetKey: widget,
              period: dataFilter,
              profile: giveProfile(relation),
              currentStatus: selectedStatus || '',
              ...(details?.buyerSupplierId && {
                partnerId: Number(details?.buyerSupplierId),
                profile: relation,
              }),
              language: window.localStorage.getItem('language') || 'en',
              ...(supplierComp && {
                supplierComp,
              }),
            },
          },

          onCompleted: (resp) => {
            setWidgetDetail(resp?.dashboardDetailWidgets?.order);
            setDetailsLoading(false);
          },
        });
      }
    } catch (error) {
      console.error(error);
      setDetailsLoading(true);
    }
  }, [widget, selectedStatus, dataFilter, supplierComp]);
  const newData = DashboardData(dynamicData, t);

  const [toggleSmallDetails, setToggleSmallDetails] = useState('');
  const [toggleDetails, setToggleDetails] = useState('');

  const handleEnableRemove = () => {
    setEnableRemove(!enableRemove);
  };
  const showDetails = (e) => {
    setToggleDetails(e);
  };
  const hideToggleDetails = () => {
    setToggleDetails('');
    setToggleSmallDetails('');
  };
  const showSmallDetails = (e) => {
    setToggleSmallDetails(e);
  };
  return (
    <div className="new-dashboard-container">
      <div className="page-header justify-between dashboard">
        <h1 className="page-title">
          {`${t('dashboard')}`}{' '}
          <span className="name">
            {' '}
            {t('hello')}, {profileDetail?.data?.firstName} &nbsp;&#128075;
          </span>
        </h1>
        <div className="new-dashboard-header-right">
          {false && (
            <DashboardSearch placeholder="Type here to search by Address, Location or Email..." />
          )}
          <AdvanceSelectLabel
            data={[
              { label: t('monthly'), value: 'monthly' },
              { label: t('quarterly'), value: 'quarterly' },
              { label: t('yearly'), value: 'yearly' },
            ]}
            className="dashboard-select"
            defaultValue={t(dataFilter)}
            onChange={(e) => {
              setDataFilter(e.target.value);
            }}
            placeholder={t(dataFilter)}
            removeSelectedValue={() => {
              setDataFilter({ label: '', value: '' });
            }}
          />
          <button type="button" onClick={handleEnableRemove}>
            <DashboardGridIcon />
          </button>
        </div>
      </div>

      {/* {newData && newData[0]?.topData?.length > 0 && (
        <div className="page-sub-title-area">
          <h3 className="sub-title">
            Order Stats <span>({newData[0]?.totalOrders} Orders)</span>
          </h3>
        </div>
      )} */}
      <div className="new-dashboard-wrapper">
        {newData?.map((value) => {
          return (
            <>
              {loading ? (
                <div className="new-dashboard-row">
                  <div className="new-dashboard-order-card">
                    <DashboardCardLoader />
                  </div>
                  <div className="new-dashboard-order-card">
                    <DashboardCardLoader />
                  </div>
                  <div className="new-dashboard-order-card">
                    <DashboardCardLoader />
                  </div>
                  <div className="new-dashboard-order-card">
                    <DashboardCardLoader />
                  </div>
                  <div className="new-dashboard-order-card">
                    <DashboardCardLoader />
                  </div>
                  <div className="new-dashboard-order-card">
                    <DashboardCardLoader />
                  </div>
                </div>
              ) : (
                <div className="new-dashboard-row">
                  {value?.topData?.map((val) => {
                    return (
                      <div className="new-dashboard-order-card">
                        <SmallWidget
                          label={val?.label}
                          value={val?.value}
                          icon={val?.icon}
                          progress={val?.progress}
                          valueIcon={val?.valueIcon}
                          data={widgetDetail}
                          onClick={(label) => {
                            setWidget(label);
                            showSmallDetails(label);
                            if (val?.defaultStatus) {
                              setSelectedStatus(val?.defaultStatus);
                            }
                          }}
                          widgetKey={val?.widgetKey}
                          widget={val?.widget}
                          period={dataFilter}
                          activeCard={activeCard}
                          setActiveCard={setActiveCard}
                          // enableHover
                          enableRemove={enableRemove}
                          onRemoveClick={(key) => {
                            removeWidget(key);
                          }}
                          t={t}
                        />

                        <WidgetDetail
                          maskBG="linear-gradient(243.36deg, rgba(29, 63, 90, 0.5) 0%, rgba(29, 63, 90, 0.425) 51.32%, rgba(29, 63, 90, 0.2) 100%)"
                          toggleWidgetDetails={
                            toggleSmallDetails === val?.widgetKey
                          }
                          handleToggleWidgetDetailsDialog={() => {
                            hideToggleDetails();
                            setSelectedStatus('');
                          }}
                          widgetDetailsTitle={t('Order Overview')} // val?.title ||
                          widgetDetailsSubTitle={t('orders')}
                          data={widgetDetail}
                          onClick={(e) => {
                            setSelectedStatus(e);
                          }}
                          period={dataFilter}
                          loading={detailsLoading}
                          t={t}
                          mode="order"
                        />
                      </div>
                    );
                  })}
                  {value?.topData?.length < 5 &&
                    dynamicData?.dashboardWidgets?.order?.mediumWidget
                      ?.length === 4 && (
                      <div className="new-dashboard-order-card add">
                        <AddNewWidget
                          size="small"
                          refreshData={() => mainData()}
                          t={t}
                        />
                      </div>
                    )}
                </div>
              )}
              {loading ? (
                <div className="new-dashboard-row flex-wrap">
                  <div className="new-dashboard-large-card">
                    <LargeWidgetsLoader />
                  </div>
                  <div className="new-dashboard-large-card">
                    <LargeWidgetsLoader />
                  </div>
                  <div className="new-dashboard-large-card">
                    <LargeWidgetsLoader />
                  </div>
                  <div className="new-dashboard-large-card">
                    <LargeWidgetsLoader />
                  </div>
                </div>
              ) : (
                <div
                  className={`new-dashboard-row flex-wrap ${
                    dynamicData?.dashboardWidgets?.order?.mediumWidget
                      ?.length === 0
                      ? ' center'
                      : ''
                  }`}
                >
                  {value.bottomData.map((val) => {
                    return (
                      <div className="new-dashboard-large-card">
                        <LargeWidgets
                          data={val?.chartdata || val?.list}
                          title={val?.title}
                          subTitle={val?.subTitle}
                          chartType={val?.type}
                          cardType={val?.cardType}
                          onExpandClick={(widgetId) => {
                            setWidget(widgetId);
                            showDetails(widgetId);
                          }}
                          disableDownload={val?.disableDownload}
                          disableExpand={val?.disableExpand || val.nodata}
                          detailsWidget={val?.detailsWidget}
                          disableButtons={val?.disableButtons}
                          widget={val?.key}
                          widgetKey={val.widget}
                          label={val?.label}
                          enableRemove={enableRemove}
                          onRemoveClick={(wkey) => removeWidget(wkey)}
                          xyText={val.xyText}
                          chartAspectRatio={val.aspectRatio}
                          chartHeight={val.chartHeight}
                          noData={val.nodata}
                          t={t}
                          scoreCardClick={(score) => {
                            setSupplierComp(score);
                          }}
                          companySpecific={val.companySpecific}
                          mode={val.mode}
                          height="14.444vw"
                        />

                        {toggleDetails === val?.widget && (
                          <WidgetDetail
                            maskBG="linear-gradient(243.36deg, rgba(29, 63, 90, 0.5) 0%, rgba(29, 63, 90, 0.425) 51.32%, rgba(29, 63, 90, 0.2) 100%)"
                            toggleWidgetDetails={toggleDetails === val?.widget}
                            handleToggleWidgetDetailsDialog={() => {
                              hideToggleDetails();
                              setSelectedStatus('');
                            }}
                            widgetDetailsTitle={val?.title} // `${val?.title ?.split(' ')?.shift()} Overview`
                            data={widgetDetail}
                            onClick={(e) => setSelectedStatus(e)}
                            period={dataFilter}
                            widgetDetailsSubTitle={t(
                              `${val?.title
                                ?.split(' ')
                                ?.shift()
                                .toString()
                                .toLowerCase()}s`
                            )}
                            t={t}
                            mode={val?.mode}
                            loading={detailsLoading}
                            scoreCardClick={(score) => setSupplierComp(score)}
                          />
                        )}
                      </div>
                    );
                  })}
                  {false && (
                    <div className="new-dashboard-large-card">
                      <CardPanel>
                        <CardPanelBody>
                          <BarPanel
                            // title="Statistics"
                            largeTitle
                            subTitle="Operational Performance"
                            titleRight="Total:"
                            subTitleRight="20 Orders"
                          >
                            {operationalRatings && (
                              <StackedBar
                                data={operationalRatings}
                                height="13.889vw"
                                labels={operationalLabels}
                              />
                            )}
                          </BarPanel>
                        </CardPanelBody>
                      </CardPanel>
                    </div>
                  )}
                  {false && (
                    <div className="new-dashboard-large-card">
                      <LargeWidgets
                        cardType="news"
                        title="News & Incidents"
                        t={t}
                      />
                    </div>
                  )}
                  {dynamicData?.dashboardWidgets?.order?.mediumWidget?.length <
                    4 && (
                    <div className="new-dashboard-large-card">
                      <AddNewWidget refreshData={() => mainData()} t={t} />
                    </div>
                  )}
                </div>
              )}
              <div className="new-dashboard-pagination">
                {dynamicData?.dashboardWidgets?.order?.currentPage !== 1 && (
                  <NewButton
                    onClick={() => setCurrentPage((prevItem) => prevItem - 1)}
                    className="previous-page"
                    disabled={
                      dynamicData?.dashboardWidgets?.order?.currentPage === 1
                    }
                  >
                    <SliderPrevIcon />
                  </NewButton>
                )}

                {dynamicData?.dashboardWidgets?.order?.currentPage !==
                  dynamicData?.dashboardWidgets?.order?.totalPage && (
                  <NewButton
                    onClick={() => setCurrentPage((prevItem) => prevItem + 1)}
                    className="next-page"
                    disabled={
                      dynamicData?.dashboardWidgets?.order?.currentPage ===
                      dynamicData?.dashboardWidgets?.order?.totalPage
                    }
                  >
                    <SliderNextIcon />
                  </NewButton>
                )}
              </div>
              <div className="new-dashboard-pagination-dots">
                {[
                  ...Array(dynamicData?.dashboardWidgets?.order?.totalPage),
                ]?.map((_, index) => {
                  return (
                    <span
                      className={
                        index + 1 ===
                        dynamicData?.dashboardWidgets?.order?.currentPage
                          ? 'active'
                          : ''
                      }
                    />
                  );
                })}
              </div>
            </>
          );
        })}
      </div>
    </div>
  );
}
NewDashboard.propTypes = {
  profileDetail: PropTypes.instanceOf(Object),
};

NewDashboard.defaultProps = {
  profileDetail: {},
};
function mapStateToProps(state) {
  return {
    profileDetail: state.omniProfile.profileDetail.fetchProfileDetails,
  };
}

export default connect(mapStateToProps, null)(NewDashboard);
