/* eslint-disable no-const-assign */
/* eslint-disable import/no-unresolved */
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import './file-upload.scss';
import ProgressBar from '../progress-bar/progress-bar';
import { CloudUploadIcon } from '../icons/icons';
import FileType from '../file-type/file-type';
import Checkbox from '../custom-checkbox/custom-checkbox';

const VALID_FILE_TYPES = [
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/msword',
  'application/zip',
  'image/gif',
  'text/csv',
  'application/vnd.ms-powerpoint',
  'application/pdf',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel',
  'image/tiff',
  'image/bmp',
  'text/plain',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'image/jpg',
  'image/jpeg',
  'image/png',
];
// docx "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
// doc "application/msword"
// zip "application/zip"
// gif "image/gif"
// csv "text/csv"
// ppt "application/vnd.ms-powerpoint"
// pdf "application/pdf"
// xlsx "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
// xls "application/vnd.ms-excel"
// tiff "image/tiff"
// bmp "image/bmp"
// txt "text/plain"
// pptx "application/vnd.openxmlformats-officedocument.presentationml.presentation"
// png "image/png"
// jpg 'image/jpg'
// jpeg 'image/jpeg'

export default function FilesUpload({
  attachment,
  onUpload,
  selectedFiles,
  setSelectedFiles,
  getIsLoading,
  multiple,
  label,
  acceptType,
  enableSelectFileFor,
  preUploadedFiles,
  onSelectUploadedDocs,
  filePath,
}) {
  const [t] = useTranslation('manageorders');
  const [t2] = useTranslation('toast');
  const [ondragHoverHandler, setOndragHoverHandler] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const browseButton = useRef(null);
  const uploadLabel = useRef(null);
  const iconWidth = useRef(null);

  useEffect(() => {
    getIsLoading(isLoading);
  }, [isLoading, getIsLoading]);

  const dropHandler = () => {
    setOndragHoverHandler(false);
  };
  const dragOverHandler = () => {
    setOndragHoverHandler(true);
  };

  const abortController = useRef(null);
  const fileInputRef = useRef(null);

  async function handleUploadFiles(file) {
    const formattedFilePath = filePath ? `${filePath}` : 'omni';
    const formData = new FormData();
    formData.append('filepath', formattedFilePath);
    formData.append('appId', 'Omni');
    formData.append('file', file);
    setIsLoading(true);
    getIsLoading(true);

    try {
      abortController.current = new AbortController();
      const response = await axios({
        method: 'post',
        url: 'https://api.quloi.com/fileAPI/v1/service/upload',
        data: formData,

        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_FILE_UPLOAD_TOKEN}`,
          'Content-Type':
            'multipart/form-data; boundary=<calculated when request is sent>',
          'Access-Control-Allow-Origin': '*',
        },
        signal: abortController.current.signal,
      });

      if (response?.data?.data) {
        const uploadedDocs = [...attachment, ...response.data.data];
        getIsLoading(false);
        setIsLoading(false);
        onUpload(uploadedDocs);
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        toast.error(t2('fileupload.error.aborted'));
        abortController.current = null;
      } else {
        toast.error(error.message);
      }
      setIsLoading(false);
      getIsLoading(false);
    }
  }

  const getDetails = (e) => {
    const files = Array.from(e?.target?.files);
    if (files?.length === 0) {
      return;
    }

    const validFiles = files?.filter((file) => {
      const { type, name } = file;
      let fileExtention = false;
      const lastDotIndex = name?.lastIndexOf('.');
      if (lastDotIndex === -1) {
        fileExtention = '';
      } else {
        fileExtention = name?.substring(lastDotIndex);
      }

      if (
        !VALID_FILE_TYPES?.includes(type) &&
        fileExtention !== '.rar' &&
        acceptType !== type
      ) {
        toast.error('Invalid file format');
        return false;
      }
      // if (
      //   acceptType === type &&
      //   !['image/jpg', 'image/jpeg', 'image/png'].includes(type)
      // ) {
      //   toast.error(t2('fileupload.error.uploadimage'));
      //   return false;
      // }
      return true;
    });

    if (validFiles?.length === 0) {
      return;
    }

    setSelectedFiles((prevFiles) => [...prevFiles, ...validFiles]);

    setTimeout(() => {
      validFiles?.forEach((file) => {
        handleUploadFiles(file);
      });
    }, 500);

    e.target.value = '';
  };

  const rejectProgress = () => {
    if (abortController.current) {
      abortController.current.abort();
    }
  };

  return (
    <div className="upload-container">
      {label && <span className="label">{label}</span>}
      <div className="upload-block">
        <label
          data-cy="file-upload-container"
          onDrop={dropHandler}
          onDragOver={dragOverHandler}
          className={`file-upload ${ondragHoverHandler ? 'active' : ''} ${
            selectedFiles?.length > 0 ? 'small-height' : ''
          }`}
          htmlFor="upload"
          ref={uploadLabel}
        >
          <input
            type="file"
            id="upload"
            onChange={getDetails}
            multiple={multiple}
            accept={acceptType || '*.*'}
            ref={fileInputRef}
          />
          <span className="icon-block" ref={iconWidth}>
            <CloudUploadIcon />
          </span>

          <span
            className="title"
            data-cy="upload-file-title"
            style={{
              width:
                (uploadLabel &&
                  uploadLabel.current &&
                  uploadLabel.current.clientWidth) -
                (browseButton &&
                  browseButton.current &&
                  browseButton.current.clientWidth) -
                60,
            }}
          >
            {t(
              'manageordersscreen.sendbookingdetails.adddocumentsbtn.draganddropplaceholder'
            )}
          </span>

          <span
            className="button"
            data-cy="upload-file-select-file-btn"
            ref={browseButton}
          >
            {t(
              'manageordersscreen.sendbookingdetails.adddocumentsbtn.selectfile'
            )}
          </span>
        </label>

        <ul className="file-list">
          {selectedFiles?.map((file, index) => (
            <li>
              <FileType extantion={file?.name?.split('.')?.pop()} />
              <span
                className={`title ${
                  enableSelectFileFor ? 'checkbox-enabled' : ''
                }`}
              >
                <span>{file?.name}</span>
                {isLoading && file?.name && <ProgressBar />}
              </span>
              {file?.name && (
                <button
                  type="button"
                  onClick={() => {
                    const newSelectedFiles = selectedFiles?.filter(
                      (_, i) => i !== index
                    );
                    setSelectedFiles(newSelectedFiles);
                    const newAttachment = attachment?.filter(
                      (_, i) => i !== index
                    );
                    onUpload(newAttachment);
                    rejectProgress();
                  }}
                >
                  &times;
                </button>
              )}
            </li>
          ))}
          {enableSelectFileFor &&
            Object.keys(preUploadedFiles)?.map((key) => (
              <li>
                <Checkbox
                  checked={
                    preUploadedFiles?.[key]?.[`${enableSelectFileFor}Selected`]
                  }
                  key={key}
                  onChange={(e) => {
                    onSelectUploadedDocs(
                      e,
                      enableSelectFileFor,
                      preUploadedFiles?.[key]?.url
                    );
                  }}
                />
                <FileType
                  extantion={preUploadedFiles?.[key]?.originalName?.substr(
                    preUploadedFiles[key].originalName.lastIndexOf('.') + 1
                  )}
                />
                <span
                  className={`title ${
                    enableSelectFileFor ? 'checkbox-enabled' : ''
                  }`}
                >
                  {preUploadedFiles?.[key]?.originalName}
                </span>
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
}

FilesUpload.propTypes = {
  attachment: PropTypes.instanceOf(Array),
  onUpload: PropTypes.func,
  selectedFiles: PropTypes.instanceOf(Array),
  setSelectedFiles: PropTypes.func,
  getIsLoading: PropTypes.func,
  multiple: PropTypes.bool,
  label: PropTypes.string,
  acceptType: PropTypes.string,
  enableSelectFileFor: PropTypes.string,
  preUploadedFiles: PropTypes.instanceOf(Array),
  onSelectUploadedDocs: PropTypes.func,
  filePath: PropTypes.string,
};

FilesUpload.defaultProps = {
  attachment: [],
  onUpload: () => {},
  selectedFiles: [],
  setSelectedFiles: () => {},
  getIsLoading: () => {},
  multiple: false,
  label: '',
  acceptType: '',
  enableSelectFileFor: '',
  preUploadedFiles: [],
  onSelectUploadedDocs: () => {},
  filePath: '',
};
