import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './confirmation.scss';
import NewButton from '../button/newButton';
import { TrashAnimation, SuccessAnimation } from '../icons/animate-icons';

export default function Confirmation({
  title,
  description,
  onCancelClick,
  onSubmitClick,
  submitButtonText,
  cancelButtonText,
  type,
  className,
  ...props
}) {
  const [animateIcon, setAnimateIcon] = useState();
  const onSubmit = () => {
    setAnimateIcon(true);
    setTimeout(() => {
      setAnimateIcon(false);
    }, 2000);
    onSubmitClick();
  };
  return (
    <div className={`dialog-box ${className}`}>
      <h3 className="title">{title}</h3>
      <div className={`dialog-box-body ${type ? 'animation' : ''}`}>
        {type === 'delete' && <TrashAnimation active={animateIcon} />}
        {type === 'active' && <SuccessAnimation active={animateIcon} />}
        <p className="description ">{description}</p>
        {props.children}
      </div>
      <div className="dialog-box-footer">
        {cancelButtonText && (
          <NewButton color="cancel" onClick={onCancelClick}>
            {cancelButtonText}
          </NewButton>
        )}
        <NewButton color="primary" type="submit" onClick={() => onSubmit()}>
          {submitButtonText}
        </NewButton>
      </div>
    </div>
  );
}
Confirmation.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  submitButtonText: PropTypes.string,
  cancelButtonText: PropTypes.string,
  onCancelClick: PropTypes.func.isRequired,
  onSubmitClick: PropTypes.func.isRequired,
  type: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
};
Confirmation.defaultProps = {
  title: '',
  description: '',
  submitButtonText: '',
  cancelButtonText: '',
  type: '',
  className: '',
  children: [],
};
