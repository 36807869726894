import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { LIST_ROLE } from '../../../graphQL/rolesAndPermissions';
import { giveProfile } from '../../../Components/global-notification/notification-list';

function ListRole({ roleId, userSub }) {
  const [roleList, setRoleList] = useState([]);
  const { relation } = useParams();

  const { loading } = useQuery(LIST_ROLE, {
    variables: {
      input: {
        roleId,
        userSub,
        profileType: giveProfile(relation),
      },
    },
    onCompleted: (data) => {
      setRoleList(data?.ListRole?.data);
    },
  });

  return (
    !loading &&
    roleList.length && (
      <div className="scrollable-container shadow">
        <ul className="action-list">
          {roleList?.map((role) => (
            <li>
              <button type="button">{role?.roleName}</button>
            </li>
          ))}
        </ul>
      </div>
    )
  );
}

export default ListRole;

ListRole.propTypes = {
  roleId: PropTypes.string,
  userSub: PropTypes.string,
};

ListRole.defaultProps = {
  roleId: '',
  userSub: '',
};
