import React from 'react';
import PropTypes from 'prop-types';

function DashboardCardPanelBody({
  className,
  noTopSpacing,
  noBottomSpacing,
  ...props
}) {
  return (
    <div
      className={`dashboard-card-panel-body ${noTopSpacing ? 'pt-0' : ''} ${
        noBottomSpacing ? 'pb-0' : ''
      }${className}`}
    >
      {props.children}
    </div>
  );
}

export default DashboardCardPanelBody;
DashboardCardPanelBody.propTypes = {
  children: PropTypes.node.isRequired,
  noTopSpacing: PropTypes.bool,
  noBottomSpacing: PropTypes.bool,
  className: PropTypes.string,
};
DashboardCardPanelBody.defaultProps = {
  noTopSpacing: false,
  noBottomSpacing: false,
  className: '',
};
