import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/client';
import { writeFile, utils } from 'xlsx';
import Button from '../../../Components/button/button';
import './enableMFA.scss';
import {
  QUERY_ENABLE_MFA,
  QUERY_VERIFY_MFA_OTP,
} from '../../../graphQL/profilePageQuery';
import {
  CopyIcon,
  DocumentDownloadIcon,
} from '../../../Components/icons/icons';
import DialogPanel from '../../../Components/dialog-panel/dialog-panel';
import DialogPanelBody from '../../../Components/dialog-panel/dialog-panel-body/dialog-panel-body';
import DialogPanelFooter from '../../../Components/dialog-panel/dialog-panel-footer/dialog-panel-footer';

export default function enableMFA({ cancelClick }) {
  const [t] = useTranslation('toast');
  const [handleEnableMFA] = useMutation(QUERY_ENABLE_MFA);
  const [validateOTP] = useMutation(QUERY_VERIFY_MFA_OTP);
  const [qrImage, setQrImage] = useState(null);
  const [backupCode, setBackupCode] = useState([]);
  const [otpText, setOtpText] = useState({
    otp1: '',
    otp2: '',
    otp3: '',
    otp4: '',
    otp5: '',
    otp6: '',
  });

  const handleVerifyOTP = (e) => {
    setOtpText({
      ...otpText,
      [e.target.name]: e.target.value,
    });
  };

  const downloadCodeExcel = () => {
    const dataOfExcel = utils.json_to_sheet(
      backupCode.map((item) => {
        return { BackupCode: item.code };
      })
    );
    const wb = utils.book_new();
    utils.book_append_sheet(wb, dataOfExcel, 'data');
    writeFile(wb, 'BackupCode.xlsx');
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(
      backupCode
        .map((item) => {
          return item.code;
        })
        .join(' ')
    );
    toast(t('enableMFA.codecopied'));
  };

  const submitOTP = async () => {
    if (
      otpText.otp1 &&
      otpText.otp2 &&
      otpText.otp3 &&
      otpText.otp4 &&
      otpText.otp5 &&
      otpText.otp6
    ) {
      const OTP = Object.values(otpText).join('');
      await validateOTP({
        variables: {
          input: {
            otp: OTP,
          },
        },
        onCompleted: (data) => {
          setBackupCode(data.enableMFA.backupCodeMFA);
          toast.info(data.enableMFA.message);
        },
      });
    } else {
      toast.error(t('enableMFA.error.otp'));
    }
  };

  const serverCall = async () => {
    await handleEnableMFA({
      variables: {
        input: {
          app: 'Omni',
        },
      },
      onCompleted: (data) => {
        setQrImage(data.showEnableMFA.qrCode);
      },
    });
  };

  useEffect(() => {
    serverCall();
  }, []);

  const tabChange = (val) => {
    const ele = document.querySelectorAll('.otp_input');
    if (ele[val - 1].value !== '') {
      ele[val]?.focus();
    } else if (ele[val - 1].value === '') {
      ele[val - 2]?.focus();
    }
  };

  return (
    <DialogPanel title="Multi-Factor Authentication">
      {backupCode?.length === 0 && (
        <div className="mfa-container">
          <DialogPanelBody>
            <h5 className="mfa-title">Scan QR Code</h5>
            <p className="mfa-description">
              You will need a{' '}
              <a
                href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en_IN&gl=US&pli=1"
                target="_blank"
                rel="noopener noreferrer"
              >
                Authenticator
              </a>{' '}
              app to complete this process
            </p>
            <h5 className="mfa-title text-center">Scan QR Code</h5>
            <div className="text-center">
              <img src={qrImage} alt="abc" />
            </div>
          </DialogPanelBody>
          <div className="seperator" />
          <DialogPanelBody>
            <p className="mfa-description">
              Enter 6-digit authentication code generated by you app
            </p>
            <div className="d-flex justify-center">
              <div className="input-group-col d-flex">
                <input
                  className="otp_input"
                  name="otp1"
                  onChange={handleVerifyOTP}
                  type="text"
                  maxlength="1"
                  onKeyUp={() => tabChange(1)}
                />

                <input
                  className="otp_input"
                  name="otp2"
                  onChange={handleVerifyOTP}
                  type="text"
                  maxlength="1"
                  onKeyUp={() => tabChange(2)}
                />

                <input
                  className="otp_input"
                  name="otp3"
                  onChange={handleVerifyOTP}
                  type="text"
                  maxlength="1"
                  onKeyUp={() => tabChange(3)}
                />
              </div>
              <div className="input-group-col d-flex">
                <input
                  className="otp_input"
                  name="otp4"
                  onChange={handleVerifyOTP}
                  type="text"
                  maxlength="1"
                  onKeyUp={() => tabChange(4)}
                />

                <input
                  className="otp_input"
                  name="otp5"
                  onChange={handleVerifyOTP}
                  type="text"
                  maxlength="1"
                  onKeyUp={() => tabChange(5)}
                />

                <input
                  className="otp_input"
                  name="otp6"
                  onChange={handleVerifyOTP}
                  type="text"
                  maxlength="1"
                  onKeyUp={() => tabChange(6)}
                />
              </div>
            </div>
          </DialogPanelBody>
          <DialogPanelFooter align="center">
            <Button color="cancel" label="Cancel" onClick={cancelClick} />

            <Button color="submit" label="Verify" onClick={submitOTP} />
          </DialogPanelFooter>
        </div>
      )}
      {backupCode?.length !== 0 && (
        <>
          <DialogPanelBody>
            <div className="rectangle-parent">
              <div className="frame-child" />
              <div className="frame-item" />

              <div className="tick-1-1-parent">
                <img className="tick-1-1" alt="" src="public/tick-1-1.svg" />
                <h4 className="successfully-enabled">Successfully Enabled</h4>
              </div>
              <p className="save-this-emergency">
                Save this emergency Backup code and store it somewhere safe, if
                you lose your phone, you can use backup codes to sign in
              </p>
              <div className="frame-inner" />
              <div className="backupCode">
                <ul className="backup-list">
                  {backupCode.map((item) => {
                    return <li>{item.code}</li>;
                  })}
                </ul>
                <div className="action-buttons">
                  <button type="button" onClick={downloadCodeExcel}>
                    {/* <img
                  className="document-download-icon"
                  alt=""
                  src={documentdownload}
                /> */}
                    <DocumentDownloadIcon />
                  </button>
                  <div className="simcard-2" />
                  <button type="button" onClick={copyToClipboard}>
                    {/* <img className="copy-icon" alt="" src={copy} /> */}
                    <CopyIcon />
                  </button>
                </div>
              </div>
            </div>
          </DialogPanelBody>
          <DialogPanelFooter align="center">
            <Button color="submit" label="Done" onClick={cancelClick} />
          </DialogPanelFooter>
        </>
      )}
    </DialogPanel>
  );
}

enableMFA.propTypes = {
  cancelClick: PropTypes.func.isRequired,
};
