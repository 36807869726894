import React from 'react';
import PropTypes from 'prop-types';
import BesicTable from '../../../../../Components/table/basicTable';
import UserDetails from '../../../../../Components/user-details/user-details';
import RatingBar from '../../../../../Components/RatingBar.js/RatingBar';
import './health.scss';

function HealthCard({ healthData }) {
  const healthColumn = [
    {
      Header: 'SUPPLIER NAME',
      accessor: 'supplierName',
      Cell: (data) => {
        const { row } = data;
        return (
          <UserDetails
            size="small"
            profilePic={row.original.image}
            userName={row.original.name}
          />
        );
      },
    },
    {
      Header: 'DUE DATE',
      accessor: 'duedate',
      align: 'center',
      Cell: (data) => {
        const { row } = data;
        return <p className="date">{row.original.dueDate}</p>;
      },
    },
    {
      Header: 'DAYS LEFT',
      accessor: 'daysLeft',
      Cell: (data) => {
        const { row } = data;
        return (
          <div className="d-flex align-center">
            <p className="date">{row.original.dueDate}</p>
            <RatingBar textcolor="#f00" />
          </div>
        );
      },
    },
  ];
  return (
    <div className="health-card">
      <BesicTable columns={healthColumn} data={healthData} />
    </div>
  );
}

export default HealthCard;
HealthCard.propTypes = {
  healthData: PropTypes.instanceOf(Array),
};
HealthCard.defaultProps = {
  healthData: [],
};
