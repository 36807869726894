import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useParams,
} from 'react-router-dom';
import * as Sentry from '@sentry/react';
import './App.scss';
import jwtDecode from 'jwt-decode';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { connect, useDispatch } from 'react-redux';
import { useQuery } from '@apollo/client';
import { getIDToken, getToken } from './service/authService';
import LoginRegister from './pages/login-ragister/login-register';
import Profile from './pages/profile/profile';
import Settings from './pages/settings/settings';
import MenuContainer from './Components/menu-container/menu-container';
import LoginSuccess from './pages/login-success/login-success';
import { addOrganizationDetails, addProfileDetails } from './redux/actions';
import DirectChat from './pages/direct-chat/direct-chat';
import QUERY_PROFILEDETAILS, {
  QUERY_ORGANIZATIONDETAILS,
} from './graphQL/profilePageQuery';
import InvitePartners from './pages/invite-partners/invite-partners';
import UnVerified from './pages/unverified/unverified';
import Unauthorized from './pages/unverified/unauthorized';
import ErrorPage from './pages/error/error';
import GlobalSkeleton from './Components/global-skeleton/global-skeleton';
import NewDashboard from './pages/new-dashboard/new-dashboard';
import CalendarBox from './Components/calendar/calendar';
import { giveProfile } from './Components/global-notification/notification-list';
import Timeline from './Components/timeline/timeline';
import VerticalTimeline from './Components/timeline-vertical/timeline-vertical';
import CustomEditor from './Components/text-editor/custome-texteditor';
// import PdfViewer from './pages/pdfviewer';
import TermsAndConditions from './static/terms-and-conditions';
import PrivacyPolicy from './static/privacy-policy';
// import TermsofUsepdf from './assets/pdf/Quloi Platform Terms of Use .pdf';
// import PrivacyPolicypdf from './assets/pdf/Quloi Platform Privacy Policy .pdf';

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const Dashboard = React.lazy(() => import('./pages/dashboard/dashboard'));
const BookingOrderList = React.lazy(() =>
  import('./pages/booking-list/booking-list')
);
const SupplierManagement = React.lazy(() =>
  import('./pages/supplier-directory/supplier-directory')
);
const MyCompany = React.lazy(() => import('./pages/my-company/my-company'));
const ItemizedOrderDetailsPage = React.lazy(() =>
  import('./pages/details-page/itemized-details-page/order-details-page')
);
const OrderListContainer = React.lazy(() =>
  import('./pages/order-list/order-list-container')
);
const OrderDetailsContainer = React.lazy(() =>
  import('./pages/details-page/order-details-container')
);
const ShipmentOrderList = React.lazy(() =>
  import('./pages/shipment-list/shipment-list')
);
const BookingDetails = React.lazy(() =>
  import('./pages/booking-details/booking-details')
);
const Test = React.lazy(() =>
  import('./Components/imageCrops/imageFileUpload')
);
const ShipmentDetails = React.lazy(() =>
  import('./pages/shipment-details/shipment-details')
);
const SupplierDashboard = React.lazy(() =>
  import('./pages/supplier-dashboard/supplier-dashboard')
);

const OnboardPartners = React.lazy(() =>
  import('./pages/onboard-partners/onboard-partners')
);
const ERPCompanydetails = React.lazy(() =>
  import('./pages/onboard-partners/erp-companyDetails')
);

function App() {
  const token = getToken();
  const dispatch = useDispatch();
  let orgStatus;
  let orgType;
  const { relation } = useParams();

  const SaveDataToRedux = () => {
    if (getIDToken()) {
      const { data: ProfileData } = useQuery(QUERY_PROFILEDETAILS);
      if (ProfileData) {
        dispatch(addProfileDetails(ProfileData));
      }
      const { data: OrgData } = useQuery(QUERY_ORGANIZATIONDETAILS, {
        variables: {
          profileType: giveProfile(relation),
        },
      });
      if (OrgData) {
        dispatch(addOrganizationDetails(OrgData));
        orgStatus = OrgData?.fetchOrganizationDetails?.data?.orgStatus;
        orgType = OrgData?.fetchOrganizationDetails?.data?.orgType;
      }
    }
  };

  const isAuthenticated = () => {
    if (token && jwtDecode(token).exp * 1000 >= Date.now()) {
      return true;
    }
    // await tokenGeneration();
    return false;
  };

  return (
    <div>
      <ToastContainer />
      <Router basename="">
        <React.Suspense fallback={<GlobalSkeleton />}>
          <SentryRoutes>
            {isAuthenticated() && (
              <>
                {SaveDataToRedux()}
                {orgStatus === 'Pending' ? (
                  <>
                    <Route
                      path="*"
                      element={<Navigate replace to="/unverified" />}
                    />
                    <Route exact path="unverified" element={<UnVerified />} />
                  </>
                ) : (
                  <>
                    <Route
                      exact
                      path="app/:relation"
                      element={<MenuContainer />}
                    >
                      <Route
                        exact
                        path="unauthorized"
                        element={<Unauthorized />}
                      />
                      <Route exact path="calendar" element={<CalendarBox />} />
                      <Route exact path="timeline" element={<Timeline />} />
                      <Route
                        exact
                        path="timeline-vertical"
                        element={<VerticalTimeline />}
                      />
                      <Route
                        exact
                        path="custome-texteditor"
                        element={<CustomEditor disabledSend visibleOptions />}
                      />
                      <Route exact path="test" element={<Test />} />
                      <Route
                        exact
                        path="dashboard"
                        element={<NewDashboard />}
                      />
                      <Route
                        exact
                        path="new-dashboard"
                        element={<Dashboard />}
                      />

                      <Route exact path="profile" element={<Profile />} />
                      {/* <Route exact path="my-company" element={<MyCompany />} /> */}
                      <Route exact path="settings" element={<Settings />} />
                      <Route exact path="my-company" element={<MyCompany />} />
                      <Route
                        exact
                        path="invite-partners"
                        element={<InvitePartners />}
                      />
                      <Route
                        exact
                        path="supplier-directory"
                        element={<SupplierManagement />}
                      />
                      <Route
                        exact
                        path="onboard-partners"
                        element={<OnboardPartners />}
                      />
                      <Route
                        exact
                        path="erp-companyDetails"
                        element={<ERPCompanydetails />}
                      />
                      {/* <Route
                        exact
                        path="supplier-my-company"
                        element={<MyCompany />}
                      /> */}

                      {/* <Route exact path="Rating" element={<Rating />} /> */}
                      <Route exact path=":companyId">
                        <Route
                          path="order-list/:urlID?/:filter?"
                          element={<OrderListContainer />}
                        />
                        <Route
                          exact
                          path="booking-list/:urlID?"
                          element={<BookingOrderList />}
                        />
                        <Route
                          exact
                          path="shipment-list"
                          element={<ShipmentOrderList />}
                        />
                        <Route
                          exact
                          path="order-details/:poId"
                          element={<OrderDetailsContainer />}
                        />
                        <Route
                          exact
                          path="itemized-order-details/:poId"
                          element={<ItemizedOrderDetailsPage />}
                        />
                        <Route
                          exact
                          path="booking-details/:bookingId"
                          element={<BookingDetails />}
                        />
                        <Route
                          exact
                          path="shipment-details/:id"
                          element={<ShipmentDetails />}
                        />
                        <Route
                          exact
                          path="company-dashboard"
                          element={<NewDashboard />}
                        />
                        <Route
                          exact
                          path="company-newdashboard"
                          element={<SupplierDashboard />}
                        />
                        <Route
                          exact
                          path="organization-details"
                          element={<MyCompany />}
                        />
                      </Route>

                      <Route exact path="chat" element={<DirectChat />} />
                    </Route>
                    {orgType?.length > 0 && (
                      <Route
                        path="*"
                        element={
                          <Navigate
                            replace
                            to={`/app/${orgType && orgType[0]}/dashboard`}
                          />
                        }
                      />
                    )}
                    <Route exact path="unverified" element={<UnVerified />} />
                    <Route exact path="error" element={<ErrorPage />} />
                  </>
                )}
              </>
            )}
            {!isAuthenticated() && (
              <>
                <Route exact path="login" element={<LoginRegister />} />
                <Route exact path="register" element={<LoginRegister />} />
                <Route
                  exact
                  path="forgotpassword"
                  element={<LoginRegister />}
                />
                <Route exact path="verify" element={<LoginSuccess />} />
                <Route path="*" element={<Navigate replace to="/login" />} />
              </>
            )}
            <Route
              exact
              path="privacy-policy"
              element={
                // <PdfViewer pdf={PrivacyPolicypdf} title="Privacy policy" />
                <PrivacyPolicy />
              }
            />
            <Route
              exact
              path="terms-and-conditions"
              element={
                // <PdfViewer
                //   pdf={TermsofUsepdf}
                //   title="Terms and Conditions"
                // />
                <TermsAndConditions />
              }
            />
          </SentryRoutes>
        </React.Suspense>
      </Router>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    profileDetails: state.omniProfile.profileDetail,
    organizationDetails: state.omniDetails.fetchOrganizationDetail,
    toggleState: state.omniState.toggleState,
  };
}

// ReactDOM.render(
//   <React.StrictMode>
//     <ChakraProvider theme={theme}>
//       <Map />
//     </ChakraProvider>
//   </React.StrictMode>
// );

function mapDispatchToProps(dispatch) {
  return {
    addProfileDetail: (dispatchData) =>
      dispatch(addProfileDetails(dispatchData)),
    addOrganizationDetails: (dispatchData) => {
      dispatch(addOrganizationDetails(dispatchData));
    },
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(App);
