import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import Images from '../imagePreview/imagePreview';
import MultipleImagePreview from '../multiple-image-preview/multiple-image-preview';
import { getChatMessageDate } from '../../../helpers/dateHelper';
import FileType from '../../file-type/file-type';
import { MoreHorIcon } from '../../icons/icons';
// import AdvancePopover from '../../advance-popover/advance-popover';
import Action from '../../action/action';
// import { BotIcon } from '../../icons/icons';
// import UserImage from '../../user-image/user-image';
// import { AcceptIcon, RejectIcon } from '../../icons/icons';
// import Tooltip from '../../tooltip/tooltip';

function SentMessage({
  messageData,
  keyind,
  loader,
  reply,
  systemMessage,
  // buttonVisibility,
  // accept,
  // reject,
}) {
  // const [popover, setPopover] = useState();
  // const togglePopover = () => {
  //   setPopover(!popover);
  // };
  const [t] = useTranslation('manageorders');
  const clickedReply = () => {
    // setPopover(!popover);
    reply(messageData);
  };

  return (
    messageData?.messageContent.length > 0 &&
    messageData?.messageContent !== '<p></p>\n' && (
      <div className="chat-message-container sender">
        <div
          key={keyind}
          className={`chat-message sender ${systemMessage ? 'system' : ''}`}
        >
          <div className="msg-top">
            <span>{getChatMessageDate(messageData.createdAt)}</span>
            {messageData.edited && (
              <span
                title={
                  'Edited At ' +
                  `${moment(messageData.editedAt).format(
                    'DD-MMM-yyyy ddd, hh:mmA'
                  )}`
                }
              >
                {t('manageordersscreen.chatmenu.edited')}
              </span>
            )}
          </div>
          <Action
            className="message-action"
            moreIcon={<MoreHorIcon />}
            width="6.944vw"
            closeOnclick
          >
            <button type="button" onClick={clickedReply}>
              {t('manageordersscreen.chatmenu.reply')}
            </button>
          </Action>

          {/* <button
            type="button"
            name="togglePopover"
            className="message-action"
            onClick={togglePopover}
          >
           
            <MoreHorIcon />
          </button>
          <AdvancePopover
        reference="actionPopover"
        getEvent={eventValue}
        closePopover={handleClose}
        placement="left"
      >

          </AdvancePopover>
          {popover && (
            <>
              <div
                className="popover-mask"
                onClick={() => togglePopover()}
                onKeyDown={() => togglePopover()}
                tabIndex="0"
                role="button"
              >
                &nbsp;
              </div>
              <div className="action-popover sender">
                <ul>
                  <li>
                    <button type="button" onClick={clickedReply}>
                      Reply
                    </button>
                  </li>
                </ul>
              </div>
            </>
          )} */}
          {messageData?.mediaPath?.length === 1 &&
            messageData.mediaPath.map((element) => {
              return (
                <div className="chat-file-container sender">
                  {['jpg', 'png', 'jpeg', 'JPEG', 'JPG', 'PNG'].includes(
                    element?.type?.split('/').pop() || element?.type
                  ) ? (
                    <Images
                      imagePath={
                        element.path !== ''
                          ? element.path
                          : 'http://dummyimage.com/200x200.png/cc0000/ffffff'
                      }
                      loader={loader}
                    />
                  ) : (
                    <FileType
                      extantion={element?.originalFilename?.split('.')?.pop()}
                    />

                    // <span className={`file-format ${element.type}`}>
                    //   <span className="lines">&nbsp;</span>
                    //   <span className="format">{element.type}</span>
                    // </span>
                  )}
                  <span className="file-name">
                    <a href={element.path} download>
                      {element.originalFilename}
                    </a>
                  </span>
                  <span />
                </div>
              );
            })}
          {messageData?.mediaPath?.length > 1 && (
            <MultipleImagePreview data={messageData?.mediaPath} />
          )}
          <div className="msg-bottom">
            <p>{ReactHtmlParser(messageData?.messageContent)}</p>

            {/* {buttonVisibility &&
              (messageData?.messageContent.split(' ')[0] === 'Order' ||
                messageData?.messageContent.split(' ')[0] === 'Booking') &&
              (messageData?.messageContent.split(' ').pop() === 'Created' ||
                messageData?.messageContent.split(' ')[2] === 'Created') && (
                <div className="action-buttons">
                  <Tooltip content="Accept" direction="bottom">
                    <button type="button" onClick={accept}>
                      <AcceptIcon />
                    </button>
                  </Tooltip>
                  <Tooltip content="Reject" direction="bottom">
                    <button type="button" onClick={reject}>
                      <RejectIcon />
                    </button>
                  </Tooltip>
                </div>
              )} */}
          </div>
        </div>
      </div>
    )
  );
}
SentMessage.propTypes = {
  messageData: PropTypes.instanceOf(Array),
  reply: PropTypes.func.isRequired,
  keyind: PropTypes.number,
  loader: PropTypes.bool,
  systemMessage: PropTypes,
  // buttonVisibility: PropTypes.bool,
  // accept: PropTypes.func,
  // reject: PropTypes.func,
};
SentMessage.defaultProps = {
  messageData: [],
  // reply: () => {},
  keyind: 0,
  loader: false,
  systemMessage: false,
  // buttonVisibility: false,
  // accept: () => {},
  // reject: () => {},
};
export default SentMessage;
