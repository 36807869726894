import React from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import getInitials from '../../helpers/utils';
import { DownloadDocNotificationIcon } from '../icons/icons';
import Tooltip from '../tooltip/tooltip';
import UserImage from '../user-image/user-image';

function DocumentItem({ data, t }) {
  const hostUrl = `${window.location.protocol}//${window.location.host}`;
  const rmNameText = (txt, mediaArr) => {
    let rmText = '';
    if (mediaArr.length === 1) {
      rmText = `${txt.replace(
        mediaArr[0].originalFilename,
        `<a href=${mediaArr?.[0]?.path} class="link">
            <Tooltip content=${mediaArr[0].originalFilename} direction="bottom">
            ${
              mediaArr[0].originalFilename.length > 15
                ? `${mediaArr[0].originalFilename.slice(0, 15)}...`
                : mediaArr[0].originalFilename
            }
            </Tooltip>
          </a>
        `
      )}`;
    } else {
      rmText = txt;
    }
    return rmText;
  };

  return (
    <li>
      <div className="notification-item unread">
        <span className="time">{data?.diff}</span>
        <div className="user-image">
          <UserImage
            path={data?.senderOrgLogo}
            shortName={getInitials(data?.senderOrgName)}
          />
          {/* {data?.senderOrgLogo !== '' ? (
            <img src={data?.senderOrgLogo} alt={data?.senderOrgName} />
          ) : (
            <span className="image">{getInitials(data?.senderOrgName)}</span>
          )} */}
        </div>
        <div className="details actionable">
          <p>
            <span class="name">{data?.senderOrgName}</span>
          </p>
          <p>
            {ReactHtmlParser(
              rmNameText(data?.text, data?.notificationData?.media)
            )}{' '}
            in <span className="text-capitalize">{data?.type}</span>
            <a className="link" href={`${hostUrl}/${data.link}`}>
              {' '}
              #{data?.relationNo && data?.relationNo}
            </a>{' '}
            By <span className="name"> {data?.senderName}</span>{' '}
            {/* <a href={data?.notificationData?.media?.[0]?.path} className="link">
              {data?.notificationData?.media?.[0]?.originalFilename}
            </a> */}
          </p>
        </div>
        <div className="action">
          {data?.notificationData?.media?.map((value) => {
            return (
              <a href={value?.path}>
                <Tooltip
                  content={t('notifications.downloadTooltip')}
                  direction="bottom"
                >
                  <DownloadDocNotificationIcon />
                </Tooltip>
              </a>
            );
          })}
        </div>
      </div>
    </li>
  );
}

export default DocumentItem;
DocumentItem.propTypes = {
  data: PropTypes.arrayOf(Object),
  t: PropTypes.func,
};
DocumentItem.defaultProps = {
  data: {},
  t: () => {},
};
