import React from 'react';
import Skeleton from '../../../skeleton/skeleton';
import './Incident-item.scss';

export default function IncidentsLoader() {
  return (
    <div className="incident-item">
      <div className="incident-top">
        <div>
          <Skeleton skHeight="0.694vw" skWidth="15.278vw" />
          <Skeleton skHeight="0.694vw" skWidth="13.889vw" marginTop="0.694vw" />
        </div>
        <Skeleton skHeight="1.389vw" skWidth="1.389vw" />
      </div>

      <Skeleton
        marginTop="0.694vw"
        skHeight="2.083vw"
        skRadius="1.736vw"
        skWidth="100%"
      />
    </div>
  );
}
