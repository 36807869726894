import React from 'react';
import PropTypes from 'prop-types';
import { CloseOutlined } from '../icons/icons';
import './file-item.scss';
import FileType from '../file-type/file-type';

export default function FileItem({
  file,
  fileName,
  onDiscard,
  className,
  docId,
  disabledFileType,
  disabledRemove,
}) {
  let formattedFileName = fileName || file?.name;
  formattedFileName =
    formattedFileName?.length > 10
      ? `${formattedFileName?.slice(0, 11)}...`
      : formattedFileName;
  return (
    <div className={`file-item ${className}`}>
      {/* <span className={`file-format ${file?.name.split('.').pop()}`}>
        <span className="lines">&nbsp;</span>
        <span className="format">{file?.name.split('.').pop()}</span>
      </span> */}
      {!disabledFileType && (
        <FileType
          extantion={file?.name?.split('.').pop() || fileName?.split('.').pop()}
        />
      )}
      <span className="file-name">{formattedFileName}</span>
      {!disabledRemove && (
        <button
          className="file-remove"
          type="button"
          onClick={() => onDiscard({ file, docId })}
        >
          <CloseOutlined />
        </button>
      )}
    </div>
  );
}

FileItem.propTypes = {
  file: PropTypes.instanceOf(Object).isRequired,
  onDiscard: PropTypes.func.isRequired,
  fileName: PropTypes.string,
  className: PropTypes.string,
  docId: PropTypes.string,
  disabledFileType: PropTypes.bool,
  disabledRemove: PropTypes.bool,
};

FileItem.defaultProps = {
  fileName: '',
  className: '',
  docId: '',
  disabledFileType: false,
  disabledRemove: false,
};
