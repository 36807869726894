import React from 'react';
import './chat-loader.css';

function ChatLoader() {
  return (
    <div className="load-container">
      <div className="spans">
        <span />
        <span />
        <span />
        <span />
      </div>
    </div>
  );
}

export default ChatLoader;
