import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

function OutsideClick({ onClickOutside, ...props }) {
  const ref = useRef(null);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      onClickOutside();
    }
  };
  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);
  if (!props.children) {
    return null;
  }
  return <div ref={ref}>{props.children}</div>;
}

export default OutsideClick;
OutsideClick.propTypes = {
  onClickOutside: PropTypes.func,
  children: PropTypes.node.isRequired,
};
OutsideClick.defaultProps = {
  onClickOutside: () => {},
};
