import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import './text-box-icon.scss';
import { InfoIcon } from '../icons/icons';

export default function TextBoxIcon({
  id,
  label,
  errorMessage,
  required,
  message,
  mBottom,
  size,
  children,
  infoMessage,
  maxLength,
  value,
  placeholder,
  infoMessageClassName,
  type,
  ...otherProps
}) {
  const textBox = useRef(null);
  const boxValue = textBox?.current?.value;
  const [readOnly, setReadOnly] = useState(true);
  const enableTextArea = () => {
    setReadOnly(false);
  };
  // useEffect(() => {
  //   textBox?.current?.focus();
  // }, [textBox]);
  return (
    <div
      className="text-box-icon-group"
      data-cy={label.replace(/\s+/g, '')}
      style={{ marginBottom: mBottom }}
    >
      <div
        data-cy="form-input"
        className={`text-box-icon-area${readOnly ? ' read-only' : ''}`}
      >
        <span className="icon">{children}</span>
        <input
          id={id}
          {...otherProps}
          ref={textBox}
          style={{ fontSize: size }}
          readOnly={readOnly}
          onClick={enableTextArea}
          onFocus={enableTextArea}
          maxLength={maxLength}
          value={value}
          placeholder={
            !(boxValue === '' || boxValue === undefined || value === '')
              ? placeholder
              : ''
          }
          type={type}
        />
        <label
          htmlFor={id}
          className={`${(boxValue || value) === '' ? 'in-active' : ''}${
            readOnly ? ' read-only' : ''
          }`}
        >
          {label}
          {required && <span className="required">*</span>}
        </label>
        {infoMessage !== '' && (
          <span className="info-icon">
            <InfoIcon />
            <span className={`info-message ${infoMessageClassName}`}>
              {infoMessage}
            </span>
          </span>
        )}
      </div>
      {message !== '' && <p className="message">{message}</p>}
      {errorMessage !== '' && (
        <p className="error-message" data-cy="error-message">
          {errorMessage}
        </p>
      )}
    </div>
  );
}
TextBoxIcon.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  errorMessage: PropTypes.string,
  required: PropTypes.bool,
  message: PropTypes.string,
  mBottom: PropTypes.string,
  size: PropTypes.string,
  children: PropTypes.node.isRequired,
  infoMessage: PropTypes.string,
  maxLength: PropTypes.number,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  infoMessageClassName: PropTypes.string,
  type: PropTypes.string,
};
TextBoxIcon.defaultProps = {
  label: '',
  id: '',
  errorMessage: '',
  required: false,
  message: '',
  mBottom: '',
  size: '',
  infoMessage: '',
  maxLength: 50,
  value: '',
  placeholder: '',
  infoMessageClassName: '',
  type: 'text',
};
