/* eslint-disable no-param-reassign */
import axios from 'axios';
import { toast } from 'react-toastify';
import URLConst from './URLConst';

export const host = `https://${
  process.env.REACT_APP_STAGE === 'prod'
    ? 'api.quloi.com'
    : 'omni-dev-api.quloi.com'
}/authAPI/v1/sso/`;

let authToken = '';

function setTokenInHeader(token) {
  authToken = token;
}

axios.interceptors.response.use(null, (error) => {
  const expectedError =
    (error.response &&
      error.response.status >= 400 &&
      error.response.status < 500) ||
    true;

  if (!expectedError) {
    toast.error('Please check internet connection..!!');
  }
  return Promise.reject(error);
});

axios.interceptors.request.use((config) => {
  if (!config.headers.Authorization) {
    if (authToken) {
      config.headers.Authorization = `Bearer ${authToken}`;
      return config;
    }
    return config;
  }
  return config;
});

const http = {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  setTokenInHeader,
};

export const chatHost = `${URLConst.host}/v1/`;

export const getNotificationCountAPI = async (profile) => {
  const url = `${chatHost}notifications/notiCount`;
  const totalNtf = ['general', 'actionable', 'approval', 'milestone'];
  const approvalNtf = ['general'];
  const actionNtf = ['actionable', 'approval'];
  const milestNtf = ['milestone'];
  const docNtf = ['document'];
  const poChangeNtf = ['poChange'];
  const chatNtf = ['chat'];
  const ntfConfig = {
    method: 'GET',
    url,
    params: {
      profile,
    },
  };
  const ntfData = await axios(ntfConfig).then((res) => res.data);
  let totalCount = 0;
  let actionCount = 0;
  let approvalCount = 0;
  let milestoneCount = 0;
  let docCount = 0;
  let poChangeCount = 0;
  let chatCount = 0;

  ntfData?.data?.forEach((ntf) => {
    if (totalNtf?.includes(ntf._id)) {
      totalCount += ntf?.count || 0;
    }
    if (approvalNtf?.includes(ntf._id)) {
      approvalCount += ntf?.count || 0;
    }
    if (actionNtf?.includes(ntf._id)) {
      actionCount += ntf?.count || 0;
    }
    if (milestNtf?.includes(ntf._id)) {
      milestoneCount += ntf?.count || 0;
    }
    if (docNtf?.includes(ntf._id)) {
      docCount += ntf?.count || 0;
    }
    if (poChangeNtf?.includes(ntf._id)) {
      poChangeCount += ntf?.count || 0;
    }
    if (chatNtf?.includes(ntf._id)) {
      chatCount += ntf?.count || 0;
    }
  });

  const response = {
    total: totalCount,
    actionable: actionCount,
    approval: approvalCount,
    document: docCount,
    milestone: milestoneCount,
    poChange: poChangeCount,
    chat: chatCount,
  };
  return response;
};

export default http;
