import { gql } from '@apollo/client';

const CREATE_ROLE = gql`
  mutation CreateRole($input: createRoleIP) {
    CreateRole(input: $input)
  }
`;

export const ROLE_NOT_EXIST = gql`
  query RoleExist($input: roleExistInput) {
    RoleExist(input: $input) {
      success
      data
      message
    }
  }
`;

export const UPDATE_ROLE_NAME = gql`
  mutation UpdateRoleName($input: updRoleNameIP) {
    UpdateRoleName(input: $input)
  }
`;

export const DELETE_ROLE = gql`
  mutation DeleteRole($input: deleteRoleInput) {
    DeleteRole(input: $input)
  }
`;

export const FETCH_PERMISSIONS = gql`
  query FetchPermissions($input: fetchPermissionsInput) {
    FetchPermissions(input: $input)
  }
`;

export const MAP_ROLE_USER = gql`
  mutation MapRoleUser($input: mapRoleUserInput) {
    MapRoleUser(input: $input)
  }
`;

export const FETCH_MASTER_INPUT = gql`
  query Query($input: fetchMasterInput) {
    FetchMasterPermissionsModule(input: $input)
  }
`;

export const CONTACT_LIST = gql`
  query ContactList($input: contactListInput) {
    ContactList(input: $input)
  }
`;

export const LIST_ROLE = gql`
  query ListRole($input: listRoleInput) {
    ListRole(input: $input)
  }
`;

export const LOCATION_LIST = gql`
  query LocationList($input: locationListInput) {
    LocationList(input: $input)
  }
`;

export const PERMISSION_UNION_LIST = gql`
  query PermissionUnionList($input: permissionUnionListInput) {
    PermissionUnionList(input: $input)
  }
`;

export const FETCH_ASSIGN_USER_LIST = gql`
  query FetchAssignUserList($input: fetchAssignUserListInput) {
    FetchAssignUserList(input: $input) {
      success
      data {
        userDetails {
          email
          timezone
          phoneNumber
          country
          profilePicture
          firstName
          lastName
          userSub
        }
      }
      message
    }
  }
`;

export const FETCH_ROLES = gql`
  query FetchRoles($input: fetchRoleIP) {
    FetchRoles(input: $input) {
      message
      data {
        isCNR
        totalPages
        totalRoles
        roleList {
          roleName
          roleId
          users {
            userDetails {
              email
              timezone
              phoneNumber
              country
              profilePicture
              firstName
              lastName
              userSub
            }
          }
          actions
        }
      }
    }
  }
`;

export default CREATE_ROLE;
