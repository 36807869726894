import React, { useState, Children } from 'react';
import PropTypes from 'prop-types';
import './action.scss';
import AdvancePopover from '../advance-popover/advance-popover';
import { MoreHorNewIcon } from '../icons/icons';

export default function Action({
  children,
  spacing,
  width,
  disabled,
  hidden,
  label,
  className,
  moreIcon,
  closeOnclick,
  zIndex,
}) {
  const [eventValue, setEventValue] = useState();
  const togglePopover = (e) => {
    setEventValue(e.target);
  };

  const handleClose = () => {
    setEventValue(null);
  };
  const mappedChildren = Children.map(children, (child) =>
    child ? <li>{child}</li> : null
  );
  return !hidden ? (
    <>
      <button
        type="button"
        onClick={togglePopover}
        id="actionPopover"
        className={`more-button d-flex justify-end align-items-center ${className}`}
        disabled={disabled || mappedChildren.length === 0}
      >
        {label !== '' ? label : moreIcon}
      </button>
      <AdvancePopover
        reference="actionPopover"
        getEvent={eventValue}
        closePopover={handleClose}
        placement="left"
        closeOnclick={closeOnclick}
        zIndex={zIndex}
      >
        <ul className="action-list-item" style={{ padding: spacing, width }}>
          {mappedChildren}
        </ul>
      </AdvancePopover>
    </>
  ) : null;
}
Action.propTypes = {
  children: PropTypes.node.isRequired,
  spacing: PropTypes.string,
  label: PropTypes.string,
  width: PropTypes.string,
  disabled: PropTypes.bool,
  hidden: PropTypes.bool,
  className: PropTypes.string,
  moreIcon: PropTypes.element,
  closeOnclick: PropTypes.bool,
  zIndex: PropTypes.number,
};
Action.defaultProps = {
  spacing: '',
  label: '',
  width: '',
  disabled: false,
  hidden: false,
  className: '',
  moreIcon: <MoreHorNewIcon color="#828282" />,
  closeOnclick: false,
  zIndex: '999999',
};
