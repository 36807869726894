import { gql } from '@apollo/client';

export const FILE_UPLOAD_BASE64 = gql`
  mutation FileUploadBase64($input: fileUploadInput) {
    fileUploadBase64(input: $input) {
      success
      data {
        filePath
        url
        originalFileName
      }
      message
    }
  }
`;

export const variable = 'hi';
