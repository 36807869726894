import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FilesUpload from '../file-upload/file-upload';

export default function UploadDocument({
  selectedFiles,
  onUpload,
  attachment,
  setSelectedFiles,
  getLoaderStatus,
  Width,
  multiple,
  label,
  acceptType,
  enableSelectFileFor,
  preUploadedFiles,
  onSelectUploadedDocs,
  filePath,
}) {
  const [loadingStatus, setLoadingStatus] = useState(false);
  const getLoader = (e) => {
    setLoadingStatus(e);
    getLoaderStatus(e);
  };
  return (
    <div style={{ width: Width }}>
      <FilesUpload
        onUpload={(data) => onUpload(data)}
        attachment={attachment}
        selectedFiles={selectedFiles}
        setSelectedFiles={setSelectedFiles}
        getIsLoading={getLoader}
        multiple={multiple}
        label={label}
        acceptType={acceptType}
        enableSelectFileFor={enableSelectFileFor}
        preUploadedFiles={preUploadedFiles}
        onSelectUploadedDocs={onSelectUploadedDocs}
        filePath={filePath}
      />
      {loadingStatus}
    </div>
  );
}
UploadDocument.propTypes = {
  onUpload: PropTypes.func,
  attachment: PropTypes.instanceOf(Object),
  selectedFiles: PropTypes.instanceOf(Array),
  setSelectedFiles: PropTypes.func,
  getLoaderStatus: PropTypes.func,
  Width: PropTypes.string,
  multiple: PropTypes.bool,
  label: PropTypes.string,
  acceptType: PropTypes.string,
  enableSelectFileFor: PropTypes.string,
  preUploadedFiles: PropTypes.instanceOf(Array),
  onSelectUploadedDocs: PropTypes.func,
  filePath: PropTypes.string,
};
UploadDocument.defaultProps = {
  onUpload: () => {},
  attachment: [],
  selectedFiles: [],
  setSelectedFiles: () => {},
  getLoaderStatus: () => {},
  Width: '',
  multiple: true,
  label: '',
  acceptType: '',
  enableSelectFileFor: '',
  preUploadedFiles: [],
  onSelectUploadedDocs: () => {},
  filePath: '',
};
