import React, { useState, useEffect, memo } from 'react';
// import React, { useState, useEffect, memo, useRef } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { gql, useMutation, useLazyQuery, useQuery } from '@apollo/client';
import './profile.scss';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
// import { tokenGeneration } from '../../service/authService';
// import { useParams } from 'react-router-dom';
import { QUERY_ORGANIZATION_USERS_LIST } from '../../graphQL/supplierQuery';
import Dialog from '../../Components/dialog/dialog';
import useDialog from '../../Components/dialog/useDialog';
import Confirmation from '../../Components/confirmation/confirmation';
import 'react-image-crop/dist/ReactCrop.css';
import menuSchema from '../../schema/menuSchema';
import ResetPassword from './reset-password/reset-password';
// import {
//   EditImageIcon,
//   RemoveProfileIcon,
//   // MoreIcon,
//   TextEditIcon,
//   TransferButtonIcon,
// } from '../../Components/icons/icons';
import {
  EditImageIcon,
  EmailIcon,
  GlobeIcon,
  MapIcon,
  NameIcon,
  PhoneIcon,
  RemoveProfileIcon,
  // MoreIcon,
  TextEditIcon,
  TimezoneIcon,
  TransferButtonIcon,
  TranslateIcon,
} from '../../Components/icons/icons';
import user from '../../assets/image/profile.jpeg';
// import CustomSelect from '../../Components/custom-select/custom-select';
import {
  QUERY_PROFILEUPDATION,
  QUERY_FETCH_PROFILE,
  SEARCH_COUNTRY,
  QUERY_FETCH_TIMEZONE,
} from '../../graphQL/profilePageQuery';
// import { MUTATE_ADD_TOKEN, QUERY_APILISTKEY } from '../../graphQL/apiDoc';
import { getToken, logout, tokenGeneration } from '../../service/authService';
// import GlobalLoader from '../../Components/global-loader/global-loader';
// import AdvancePopover from '../../Components/advance-popover/advance-popover';
import ProfileLoader from './loader';
import TransferAccount from '../../Components/transfer-account/transfer-account';
import Tabs from '../../Components/tabs/custom-tab';
import AccountSettingsPanel from '../../Components/account-settings-panel/account-settings-panel';
// import TextBox from '../../Components/text-box/text-box';
// import AdvanceSelect from '../../Components/advance-select/advance-select';
import ProfileTitleDescription from '../../Components/profile-title-description/profile-title-description';
import MultiFactorOption from '../../Components/multi-factor-option/multi-factor-option';
import EnableMFA from './enable-MFA/enableMFA';
import ImageUpload from '../../Components/image-upload/image-upload';
import { getDigitsForPhoneNumber, phoneRegExp } from '../../helpers/utils';
// import WebhookIntegration from './webhook-integration/webhook-integration';
import AccountSettingsPanelTitle from '../../Components/account-settings-panel/account-settings-panel-title';
import CustomTag from '../../Components/custom-tag/custom-tag';
import TextBoxIcon from '../../Components/text-box-icon/text-box-icon';
import AdvanceSelectLabelIcon from '../../Components/advance-select/advance-select-icon';

let fetchTimezoneCountry = {};
let options = [
  {
    value: 'Country',
    label: '',
  },
];
let fetchCountry = '';
function Profile({ profileDetails }) {
  let templang;
  const [t, i18n] = useTranslation('accountsettings');
  const [t2] = useTranslation('toast');
  const languageSchema = menuSchema()?.languageSchema;

  // const [languageLogo, setLanguageLogo] = useState('');

  function findLanguageFromStorage(schema, lang) {
    const defaultlang = schema.find((language) => language.value === lang);
    return defaultlang;
  }
  // const [t] = useTranslation('accountsettings');
  // const [language, setLanguage] = useState('Switch language');
  // const [eventValue, setEventValue] = useState();
  // const togglePopover = (e) => {
  //   setEventValue(e.target);
  // };

  // const handleClose = () => {
  //   setEventValue(null);
  // };
  const [buttonLoading, setButtonLoading] = useState(false);
  const [transferData, setTransferData] = useState('');
  // const { email } = useParams();
  const [removeProfilePic, setRemoveProfilePic] = useState(true);
  const { isShowing: isChangePasswordPopup, toggle: changePasswordPopup } =
    useDialog();
  // const { isShowing: isGenerateTokenPop, toggle: GenerateTokenPop } =
  //   useDialog();
  const { isShowing: isTransferAccountPopup, toggle: transferAccountPopup } =
    useDialog();
  const {
    isShowing: isTransferAccountConfirmation,
    toggle: transferAccountConfirmation,
  } = useDialog();
  const { isShowing: isRemoveDialog, toggle: removeProfile } = useDialog();
  const { isShowing: isShowingProfile, toggle: showProfile } = useDialog();
  const { isShowing: isEnableMFAPopup, toggle: enableMFAPopup } = useDialog();
  // const [eventValue, setEventValue] = useState();
  // const { refetch: tokenRefetch } = useQuery(QUERY_APILISTKEY, {
  //   onCompleted: (data) => {
  //     setApiKeysList(data?.ApiKeyList?.data);
  //   },
  // });

  const { loading } = useQuery(SEARCH_COUNTRY, {
    onCompleted: (data) => {
      options = data.fetchCountry.map((currency) => {
        fetchCountry = {
          label: currency.CountryName,
          value: currency.CountryCode,
        };
        return fetchCountry;
      });
    },
  });

  // const [handleCreateApiToken] = useMutation(MUTATE_ADD_TOKEN);

  const initialValues = profileDetails.fetchProfileDetails
    ? {
        firstName: profileDetails
          ? profileDetails.fetchProfileDetails.data.firstName
          : '',
        lastName: profileDetails
          ? profileDetails.fetchProfileDetails.data.lastName
          : '',
        phoneNumber: profileDetails
          ? profileDetails.fetchProfileDetails.data.phoneNumber
          : '',
        country: profileDetails
          ? profileDetails.fetchProfileDetails.data.country
          : '',
        timezone: profileDetails
          ? profileDetails.fetchProfileDetails.data.timezone
          : '',
        comapnyName: profileDetails
          ? profileDetails.fetchProfileDetails.companyName
          : '',
        email: profileDetails
          ? profileDetails.fetchProfileDetails.data.email
          : '',
        // lang: findLanguageFromStorage(
        //   languageSchema,
        //   profileDetails.fetchProfileDetails.data.language
        // )?.label,
      }
    : {};
  const [fetchTimezone] = useLazyQuery(QUERY_FETCH_TIMEZONE);
  const nullstring = ' ';
  const [handleuserprofile] = useMutation(QUERY_PROFILEUPDATION);
  const { refetch } = useQuery(QUERY_FETCH_PROFILE, {
    enabled: false,
  });
  const [switchEditMode, setSwitchEditMode] = useState(false);
  // const [switchEditAddressMode, setSwitchEditAddressMode] = useState(false);
  const [ZoneOptions, setZoneOptions] = useState([
    {
      label: 'No TimeZone Found',
      value: '',
    },
  ]);
  // const fetchTimezoneC = (e) => {
  //   if (e || e !== false) {
  //     fetchTimezone({
  //       variables: { input: e?.value || '' },
  //       onCompleted: (zones) => {
  //         if (zones.fetchTimezone.message !== 'record not found') {
  //           ZoneOptions = zones.fetchTimezone.data[0].WindowsTimeZones.map(
  //             (zone) => {
  //               fetchTimezoneCountry = {
  //                 label: zone.Name,
  //                 value: zone.Name,
  //               };
  //               return fetchTimezoneCountry;
  //             }
  //           );
  //           ZoneOptions.push({ label: 'Select time zone', value: '' }, [
  //             ...ZoneOptions,
  //           ]);
  //         }
  //       },
  //     });
  //   }
  // };

  // const handleWidth = (e) => {
  //   setFirstNameTextLength(e);
  //   setLastNameTextLength(e);
  // };

  function checkAndShowToast() {
    if (localStorage.getItem('showToast') === 'true') {
      toast.success(t2('profile.success.profileupdate'));
      localStorage.removeItem('showToast');
    }
  }

  useEffect(() => {
    checkAndShowToast();
  }, []);

  const fetchTimezoneC = (e) => {
    if (e || e !== false) {
      fetchTimezone({
        variables: { input: e?.value || '' },
        onCompleted: (zones) => {
          let newZones = [];
          if (zones.fetchTimezone.message !== 'record not found') {
            newZones = zones.fetchTimezone.data[0].TimeZones.map((zone) => {
              fetchTimezoneCountry = {
                label: zone,
                value: zone,
              };
              return fetchTimezoneCountry;
            });

            if (newZones.length > 1) {
              newZones.splice(0, 0, {
                label: t('accountsettings.myprofile.timezoneplaceholder'),
                value: '',
              });
            }
            setZoneOptions(newZones);
          }
        },
        onError: () => {
          setZoneOptions([
            {
              label: t('accountsettings.myprofile.timezoneplaceholder2'),
              value: '',
            },
          ]);
        },
      });
    }
  };
  const serverCall = (submitpayload) => {
    setButtonLoading(true);
    handleuserprofile({
      variables: {
        input: {
          ...submitpayload,
        },
      },
      onCompleted: () => {
        refetch();
        if (templang) {
          localStorage.setItem('showToast', 'true');
          tokenGeneration();
        } else {
          toast.success(t2('profile.success.profileupdate'));
        }
        setButtonLoading(false);
      },
    });
  };
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object({
      firstName: Yup.string()
        .required(t('accountsettings.myprofile.formvalidation.name'))
        .min(2, t('accountsettings.myprofile.formvalidation.nameshort'))
        .max(50, t('accountsettings.myprofile.formvalidation.namelong')),
      lastName: Yup.string().required(
        t('accountsettings.myprofile.formvalidation.lastname')
      ),
      country: Yup.string().required(
        t('accountsettings.myprofile.formvalidation.country')
      ),
      phoneNumber: Yup.string().matches(
        phoneRegExp,
        t('accountsettings.myprofile.formvalidation.number')
      ),
    }),
    onSubmit: (values) => {
      if (values.lang) {
        templang = true;
      } else {
        templang = false;
      }
      const payload = {
        firstName: values.firstName,
        lastName: values.lastName,
        // ...(values.phoneNumber && { phoneNumber: String(values.phoneNumber) }),
        lang: values.lang ? values.lang : localStorage.getItem('language'),
        phoneNumber: values.phoneNumber,
        country: values.country,
        timezone: values.timezone || ZoneOptions[0].value,
        accessToken: getToken(),
      };
      serverCall(payload);
      localStorage.setItem(
        'language',
        values.lang ? values.lang : localStorage.getItem('language')
      );
      i18n.changeLanguage(
        values.lang ? values.lang : localStorage.getItem('language')
      );
      setSwitchEditMode(false);
      // setSwitchEditAddressMode(false);
    },
  });
  // const togglePopover = (e) => {
  //   setEventValue(e.target);
  // };
  // const handleClose = () => {
  //   setEventValue(null);
  // };
  useEffect(() => {
    if (
      { ...formik.getFieldProps('country') }.value &&
      options &&
      options.length > 1
    ) {
      fetchTimezoneC(
        options &&
          options.length > 1 &&
          options.filter(
            (item) =>
              item.value === `${{ ...formik.getFieldProps('country') }.value}`
          )[0]
      );
    }
  }, [{ ...formik.getFieldProps('country') }.value, options]);
  const handleFileUploadSuccess = (fileData) => {
    handleuserprofile({
      variables: {
        input: {
          profilePicture: fileData.url,
          accessToken: getToken(),
        },
      },
      onCompleted: () => {
        if (fileData.url !== user) {
          setRemoveProfilePic(true);
        }
        refetch();
      },
    });
    showProfile(false);
  };

  const TRANSFER_ACCOUNT_OWNERSHIP = gql`
    mutation Mutation($input: transferInput) {
      TransferOwnershipMutate(input: $input) {
        msg
      }
    }
  `;
  const { toggle: activateContact } = useDialog();
  const [transferOwnership] = useMutation(TRANSFER_ACCOUNT_OWNERSHIP);

  const handleTransferOwnership = (value) => {
    transferOwnership({
      variables: {
        input: {
          userEmail: value,
        },
      },
      onCompleted: () => {
        logout();
        activateContact();
      },
    });
  };
  const [userEmails, setUserEmails] = useState([]);
  const userEmail = [];
  const { data } = useQuery(QUERY_ORGANIZATION_USERS_LIST, {
    onCompleted: (Data) => {
      const admin = Data?.fetchListOrganisationUsers?.data?.filter(
        (e) => e.userType === '_admin'
      );

      admin?.map((item) => {
        userEmail.push({
          value: item?.email,
          image: item?.profilePicture,
          firstName: item?.firstName,
          lastName: item?.lastName,
        });
        return '';
      });
      setUserEmails(userEmail);
    },
  });

  useEffect(() => {}, [data]);
  const toggleMode = () => {
    // if (switchEditAddressMode === true) {
    //   alert('Hi there');
    // } else {
    setSwitchEditMode(true);
    // }
  };

  // const CreateTokenHandler = () => {
  //   handleCreateApiToken({
  //     variables: {
  //       input: {
  //         description: tkDesc,
  //       },
  //     },
  //     onCompleted: (tkData) => {
  //       toast.success(tkData?.GenerateApiKey?.message);
  //       tokenRefetch();
  //       GenerateTokenPop();
  //     },
  //   });
  // };

  return (
    <>
      {loading && <ProfileLoader />}
      {!loading && (
        <>
          <div className="page-header">
            <h1>{t('accountsettings.title')}</h1>
          </div>
          <div className="profile-container">
            <Tabs className="profile">
              <div
                label={t('accountsettings.tabtitle.myprofile')}
                value="My Profile"
                className="body"
              >
                <h2>{t('accountsettings.tabtitle.myprofile')}</h2>
                <AccountSettingsPanel>
                  <div className="my-profile-row">
                    <div
                      className={`profile-image ${
                        profileDetails?.fetchProfileDetails?.data?.userType ===
                        '_keyAdmin'
                          ? 'border-keyadmin'
                          : ''
                      }`}
                    >
                      <img
                        src={
                          profileDetails?.fetchProfileDetails?.data
                            ?.profilePicture || user
                        }
                        alt="Profile Pic"
                      />
                      <button
                        type="button"
                        className="edit-image"
                        onClick={showProfile}
                      >
                        <EditImageIcon />
                      </button>
                      {profileDetails?.fetchProfileDetails?.data
                        ?.profilePicture &&
                        removeProfilePic && (
                          <button
                            type="button"
                            className="remove-image"
                            onClick={removeProfile}
                          >
                            <Dialog
                              isShowing={isRemoveDialog}
                              hide={removeProfile}
                              placement="center"
                            >
                              <Confirmation
                                cancelButtonText={t(
                                  'accountsettings.myprofile.transferaccountdilog.confirmation.cancel'
                                )}
                                submitButtonText={t(
                                  'accountsettings.myprofile.transferaccountdilog.confirmation.confirm'
                                )}
                                title={t(
                                  'accountsettings.myprofile.transferaccountdilog.confirmation.remove'
                                )}
                                description={t(
                                  'accountsettings.myprofile.transferaccountdilog.confirmation.removedescription'
                                )}
                                onSubmitClick={() => {
                                  serverCall({
                                    profilePicture: nullstring,
                                    accessToken: getToken(),
                                  });
                                  setRemoveProfilePic(false);
                                }}
                              />
                            </Dialog>
                            <RemoveProfileIcon />
                          </button>
                        )}
                    </div>
                    <div className="user-details">
                      <div className="user-information">
                        <div className="d-flex align-start">
                          <p className="name">
                            <span>
                              {
                                profileDetails?.fetchProfileDetails?.data
                                  ?.firstName
                              }
                            </span>{' '}
                            <span>
                              {
                                profileDetails?.fetchProfileDetails?.data
                                  ?.lastName
                              }
                            </span>
                          </p>
                          <CustomTag
                            text={
                              profileDetails?.fetchProfileDetails?.data
                                ?.userType === '_keyAdmin'
                                ? 'Key Admin'
                                : 'User'
                            }
                            className="ml-20"
                          />
                        </div>

                        <p className="location">
                          {profileDetails?.fetchProfileDetails?.data?.country}
                        </p>
                      </div>
                    </div>
                  </div>
                  {profileDetails?.fetchProfileDetails?.data?.userType ===
                    '_keyAdmin' && (
                    <div className="button-transfer-area">
                      <button type="button" onClick={transferAccountPopup}>
                        {t(
                          'accountsettings.myprofile.transferaccountdilog.transferaccount'
                        )}
                        <TransferButtonIcon />
                      </button>
                    </div>
                  )}
                </AccountSettingsPanel>
                <AccountSettingsPanel className="form">
                  <AccountSettingsPanelTitle>
                    {t('accountsettings.myprofile.personalinformation')}
                  </AccountSettingsPanelTitle>
                  {switchEditMode ? (
                    <form onSubmit={formik.handleSubmit}>
                      <div className="my-profile-row  align-items-start">
                        <div className="profile-form">
                          <TextBoxIcon
                            required
                            id="firstName"
                            name="firstName"
                            label={t('accountsettings.myprofile.firstname')}
                            {...formik.getFieldProps('firstName')}
                            errorMessage={formik.errors.firstName}
                            placeholder={t(
                              'accountsettings.myprofile.fistnameplaceholder'
                            )}
                            maxLength="20"
                          >
                            <NameIcon color="#A6A6A6" />
                          </TextBoxIcon>
                        </div>
                        <div className="profile-form">
                          <TextBoxIcon
                            required
                            id="lastName"
                            name="lastName"
                            label={t('accountsettings.myprofile.lastname')}
                            {...formik.getFieldProps('lastName')}
                            errorMessage={formik.errors.lastName}
                            placeholder={t(
                              'accountsettings.myprofile.lastnameplaceholder'
                            )}
                            maxLength="20"
                          >
                            <NameIcon color="#A6A6A6" />
                          </TextBoxIcon>
                        </div>
                      </div>
                      <div className="my-profile-row align-items-start">
                        <div className="profile-form">
                          <TextBoxIcon
                            id="email"
                            type="text"
                            name="email"
                            label={t('accountsettings.myprofile.emailID')}
                            {...formik.getFieldProps('email')}
                            errorMessage={formik.errors.email}
                            disabled
                          >
                            <EmailIcon color="#A6A6A6" />
                          </TextBoxIcon>
                        </div>

                        <div className="profile-form">
                          <TextBoxIcon
                            id="phoneNumber"
                            name="phoneNumber"
                            label={t('accountsettings.myprofile.contactnumber')}
                            type="text"
                            {...formik.getFieldProps('phoneNumber')}
                            // onChange={() => {
                            //   formik.handleChange();
                            // }}
                            onKeyDown={(e) => {
                              // getNumber(e);
                              getDigitsForPhoneNumber(e);
                            }}
                            errorMessage={formik.errors.phoneNumber}
                            placeholder={t(
                              'accountsettings.myprofile.contactplaceholder'
                            )}
                          >
                            <PhoneIcon color="#A6A6A6" />
                          </TextBoxIcon>
                        </div>
                      </div>
                      <div className="my-profile-row align-items-start">
                        <div className="profile-form">
                          <AdvanceSelectLabelIcon
                            id="country"
                            data={options}
                            name="country"
                            onChange={(e) => {
                              formik.setFieldValue('country', e.target.value);
                              formik.setFieldValue('timezone', '');
                              setZoneOptions([
                                {
                                  label: t(
                                    'accountsettings.myprofile.searchingplaceholder'
                                  ),
                                  value: '',
                                },
                              ]);
                            }}
                            placeholder={t(
                              'accountsettings.myprofile.countryplaceholder'
                            )}
                            label={t('accountsettings.myprofile.country')}
                            required
                            fontSize="0.833vw"
                            errorMessage={formik.errors.country}
                            defaultValue={
                              options.filter(
                                (item) =>
                                  item.value ===
                                  profileDetails?.fetchProfileDetails?.data
                                    ?.country
                              )[0]
                            }
                          >
                            <GlobeIcon color="#A6A6A6" />
                          </AdvanceSelectLabelIcon>
                        </div>
                        <div className="profile-form">
                          <AdvanceSelectLabelIcon
                            name="timezone"
                            data={ZoneOptions}
                            placeholder={t(
                              'accountsettings.myprofile.timezoneplaceholder'
                            )}
                            label={t('accountsettings.myprofile.timezone')}
                            onChange={(e) =>
                              formik.setFieldValue('timezone', e.target.value)
                            }
                            defaultValue={
                              ZoneOptions &&
                              ZoneOptions.length > 0 &&
                              ZoneOptions.length === 1
                                ? ZoneOptions[0]
                                : ZoneOptions.filter(
                                    (item) =>
                                      item.value ===
                                      `${
                                        { ...formik.getFieldProps('timezone') }
                                          .value
                                      }`
                                  )[0]
                            }
                          >
                            <TimezoneIcon
                              color="#A6A6A6"
                              width="1.389vw"
                              height="1.389vw"
                            />
                          </AdvanceSelectLabelIcon>
                        </div>
                      </div>
                      <div className="my-profile-row align-items-start">
                        <div className="profile-form">
                          <AdvanceSelectLabelIcon
                            name="language"
                            data={languageSchema}
                            placeholder={t(
                              'accountsettings.myprofile.languageplaceholder'
                            )}
                            label={t('accountsettings.myprofile.language')}
                            onChange={(e) => {
                              formik.setFieldValue('lang', e.target.value);
                            }}
                            defaultValue={findLanguageFromStorage(
                              languageSchema,
                              localStorage.getItem('language')
                            )}
                          >
                            <MapIcon color="#A6A6A6" />
                          </AdvanceSelectLabelIcon>
                        </div>
                      </div>
                      <div className="button-submit-area">
                        <button type="submit" className="button">
                          {t('accountsettings.myprofile.save')}
                        </button>
                      </div>
                    </form>
                  ) : (
                    <>
                      <div className="my-profile-row">
                        <div className="profile-form">
                          <ProfileTitleDescription
                            title={t('accountsettings.myprofile.firstname')}
                            description={
                              profileDetails?.fetchProfileDetails?.data
                                ?.firstName
                            }
                            className="full-width"
                          />
                        </div>
                        <div className="profile-form">
                          <ProfileTitleDescription
                            title={t('accountsettings.myprofile.lastname')}
                            description={
                              profileDetails?.fetchProfileDetails?.data
                                ?.lastName
                            }
                            className="full-width"
                          />
                        </div>
                      </div>
                      <div className="my-profile-row">
                        <div className="profile-form">
                          <ProfileTitleDescription
                            title={t('accountsettings.myprofile.emailID')}
                            description={
                              profileDetails?.fetchProfileDetails?.data?.email
                            }
                            className="full-width"
                          />
                        </div>

                        <div className="profile-form">
                          <ProfileTitleDescription
                            title={t('accountsettings.myprofile.contactnumber')}
                            description={
                              profileDetails?.fetchProfileDetails?.data
                                ?.phoneNumber
                            }
                            className="full-width"
                          />
                        </div>
                      </div>
                      <div className="my-profile-row">
                        <div className="profile-form">
                          <ProfileTitleDescription
                            title={t('accountsettings.myprofile.country')}
                            description={
                              options?.filter(
                                (item) =>
                                  item?.value ===
                                  profileDetails?.fetchProfileDetails?.data
                                    ?.country
                              )[0]?.label
                            }
                            className="full-width"
                          />
                        </div>
                        <div className="profile-form">
                          <ProfileTitleDescription
                            title={t('accountsettings.myprofile.timezone')}
                            description={
                              profileDetails?.fetchProfileDetails?.data
                                ?.timezone || '--'
                            }
                            className="full-width"
                          />
                        </div>
                      </div>
                      <div className="my-profile-row">
                        <div className="profile-form">
                          <ProfileTitleDescription
                            title={t('accountsettings.myprofile.language')}
                            description={
                              findLanguageFromStorage(
                                languageSchema,
                                profileDetails?.fetchProfileDetails?.data
                                  ?.language
                              )?.label || '--'
                            }
                            className="full-width"
                            icon={<TranslateIcon />}
                          />
                        </div>
                      </div>
                      <div className="button-submit-area">
                        <button
                          type="button"
                          className="button"
                          onClick={toggleMode}
                          disabled={buttonLoading}
                        >
                          {t('accountsettings.myprofile.edit')}
                          <TextEditIcon />
                        </button>
                      </div>
                    </>
                  )}
                </AccountSettingsPanel>
              </div>

              <div
                label={t('accountsettings.tabtitle.password&security')}
                value="Password & Security"
                className="body"
              >
                <h2>{t('accountsettings.tabtitle.password&security')}</h2>
                <AccountSettingsPanel>
                  <AccountSettingsPanelTitle>
                    {t('accountsettings.password.password')}
                  </AccountSettingsPanelTitle>
                  <div className="my-profile-row">
                    <ProfileTitleDescription
                      title={t('accountsettings.password.currentpassword')}
                      description="************"
                      className="full-width"
                    />
                  </div>
                  <div className="button-submit-area">
                    <button
                      type="button"
                      className="button"
                      onClick={changePasswordPopup}
                    >
                      {t('accountsettings.password.edit')}
                      <TextEditIcon />
                    </button>
                  </div>
                </AccountSettingsPanel>
                {false && (
                  <AccountSettingsPanel>
                    <AccountSettingsPanelTitle>
                      Multi-Factor Authentication
                    </AccountSettingsPanelTitle>
                    <MultiFactorOption
                      label="Enable on multi-factor authentication "
                      onChange={enableMFAPopup}
                      defaultChecked={
                        profileDetails?.fetchProfileDetails?.data?.enableMFA
                      }
                    />
                    <MultiFactorOption
                      label="Download back-up codes"
                      actionable
                    />
                  </AccountSettingsPanel>
                )}
              </div>

              {/* {profileDetails?.fetchProfileDetails?.data?.userType ===
              '_keyAdmin' ? (
                <div
                  label={t('accountsettings.tabtitle.systemintegrations')}
                  className="body"
                >
                  <h2>{t('accountsettings.tabtitle.systemintegrations')}</h2>
                  <WebhookIntegration />
                </div>
              ) : (
                <div />
              )} */}

              {/* <div
                label={t('accountsettings.tabtitle.apidocument')}
                className="body"
              >
                <div className="flex space-btw">
                  <h2>{t('accountsettings.tabtitle.apidocument')}</h2>
                  <div>
                    <NewButton
                      color="color"
                      onClick={GenerateTokenPop}
                      styles="button"
                      className="submit mr-10"
                    >
                      {t('accountsettings.apidocument.generatetoken')}
                    </NewButton>
                    <NewButton
                      type="link"
                      link="omni-apidoc-dev.quloi.com/"
                      linkType="external"
                      className="fs-12 fw-400"
                    >
                      {t('accountsettings.apidocument.apidocs')}
                    </NewButton>
                  </div>
                </div>
                <AccountSettingsPanel>
                  <AccountSettingsPanelTitle>
                    {t('accountsettings.apidocument.usertokengeneration')}
                  </AccountSettingsPanelTitle>
                  <div className="my-profile-row mb-20">
                    <AccountSettingsPanelDescription>
                      {t(
                        'accountsettings.apidocument.usertokengenerationdescription'
                      )}
                    </AccountSettingsPanelDescription>
                  </div>
                  <TokenTable
                    data={apiKeysList}
                    tokenRefetch={tokenRefetch}
                    t={t}
                  />
                  <Dialog
                    isShowing={isGenerateTokenPop}
                    hide={GenerateTokenPop}
                    placement="middle"
                  >
                    <DialogPanel
                      title={t(
                        'accountsettings.generatetokenbtn.generateanusertoken'
                      )}
                    >
                      <DialogPanelBody>
                        <div className="pop-up-width">
                          <TextBox
                            type="input"
                            placeholder={t(
                              'accountsettings.generatetokenbtn.inputplaceholder'
                            )}
                            label={t(
                              'accountsettings.generatetokenbtn.tokendescription'
                            )}
                            onChange={(e) => setTkDesc(e.target.value)}
                            defaultValue={tkDesc}
                            message={t(
                              'accountsettings.generatetokenbtn.helpertext'
                            )}
                          />
                        </div>
                      </DialogPanelBody>
                      <DialogPanelFooter>
                        <Button
                          label={t('accountsettings.generatetokenbtn.cancel')}
                          styles="cancel"
                          onClick={GenerateTokenPop}
                        />
                        <Button
                          type="button"
                          label={t('accountsettings.generatetokenbtn.generate')}
                          color="secondary"
                          onClick={CreateTokenHandler}
                        />
                      </DialogPanelFooter>
                    </DialogPanel>
                  </Dialog>
                </AccountSettingsPanel>
              </div> */}

              {/* <div label="Associations" className="body">
                jhgbv
              </div>
              <div label="Notifications" className="body">
                lkjhgfd
              </div>
              <div label="Dashboard & Widgets" className="body">
                fghjh
              </div> */}
            </Tabs>
          </div>
          <Dialog
            isShowing={isShowingProfile}
            hide={showProfile}
            placement="center"
          >
            <ImageUpload
              title={t(
                'accountsettings.myprofile.profilepicture.changeprofilepicture'
              )}
              setCroppedImage={handleFileUploadSuccess}
              module="users"
              moduleId={profileDetails?.fetchProfileDetails?.data?.userSub}
            />
          </Dialog>
          <Dialog
            isShowing={isChangePasswordPopup}
            hide={changePasswordPopup}
            placement="center"
          >
            <ResetPassword
              cancelClick={changePasswordPopup}
              hide={changePasswordPopup}
            />
          </Dialog>
          <Dialog
            isShowing={isTransferAccountPopup}
            hide={transferAccountPopup}
            placement="center"
          >
            <TransferAccount
              openConfirmation={() => {
                transferAccountConfirmation();
                transferAccountPopup();
              }}
              userEmails={userEmails}
              fetchTransferData={transferData}
              setValue={setTransferData}
              closeTransfer={transferAccountPopup}
            />
          </Dialog>
          <Dialog
            isShowing={isTransferAccountConfirmation}
            hide={transferAccountConfirmation}
            placement="center"
          >
            <Confirmation
              title={t(
                'accountsettings.myprofile.transferaccountdilog.confirmation.title'
              )}
              description={`${t(
                'accountsettings.myprofile.transferaccountdilog.confirmation.description'
              )} ${transferData}`}
              // description= temp.concat(" ",transferData);
              onCancelClick={() => {
                transferAccountConfirmation();
                transferAccountPopup();
              }}
              onSubmitClick={() => {
                handleTransferOwnership(transferData);
                transferAccountConfirmation();
              }}
              submitButtonText={t(
                'accountsettings.myprofile.transferaccountdilog.confirmation.yes'
              )}
              cancelButtonText={t(
                'accountsettings.myprofile.transferaccountdilog.confirmation.no'
              )}
            />
          </Dialog>
          <Dialog
            isShowing={isEnableMFAPopup}
            hide={enableMFAPopup}
            placement="center"
          >
            <EnableMFA cancelClick={enableMFAPopup} hide={enableMFAPopup} />
          </Dialog>
        </>
      )}
    </>
  );
}
function mapStateToProps(state) {
  return {
    profileDetails: state.omniProfile.profileDetail,
  };
}
Profile.propTypes = {
  profileDetails: PropTypes.instanceOf(Object),
  addProfileDetail: PropTypes.func,
};
Profile.defaultProps = {
  profileDetails: {},
  addProfileDetail: () => {},
};
export default connect(mapStateToProps, null)(memo(Profile));
