import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './user-image.scss';
import UserInfo from '../user-info/user-info';

export default function UserImage({
  size = 'small' || 'x-large' || 'large' || 'x-medium' || 'medium' || 'x-small',
  path,
  name,
  removeTooltip,
  marginRight,
  shortName,
  email,
  infoDirection,
  userRole,
  verified,
  type,
  className,
}) {
  const [imageSize, setImageSize] = useState('');
  const [colorCode, setColorCode] = useState('');
  useEffect(() => {
    switch (size) {
      case 'x-large':
        setImageSize('x-large');
        break;
      case 'large':
        setImageSize('large');
        break;
      case 'x-medium':
        setImageSize('x-medium');
        break;
      case 'medium':
        setImageSize('medium');
        break;
      case 'small':
        setImageSize('small');
        break;
      case 'x-small':
        setImageSize('x-small');
        break;
      default:
        setImageSize('');
    }
  }, [size]);
  useEffect(() => {
    const colorIns = shortName?.split('');
    setColorCode(colorIns?.shift()?.toLowerCase());
  });
  return (
    <span
      style={{ marginRight: `${marginRight}` }}
      className="user-image-container"
      data-cy="user-image-container"
    >
      {!removeTooltip ? (
        <UserInfo
          userName={name}
          shortName={shortName}
          userUrl={path}
          userEmail={email}
          userRole={userRole}
          direction={infoDirection}
        >
          {path ? (
            <span
              className={`thumb-image ${imageSize} ${
                !verified && 'unverified'
              }${type}`}
            >
              <img src={path} alt="" />
            </span>
          ) : (
            <span
              className={`thumb-sm ${imageSize} ${colorCode} ${
                !verified && 'unverified'
              }${type}`}
            >
              <span>{shortName}</span>
            </span>
          )}
        </UserInfo>
      ) : (
        <>
          {path && (
            <span className={`thumb-image ${imageSize} ${type} ${className}`}>
              <img src={path} alt="" />
            </span>
          )}
          {!path && (
            <span
              className={`thumb-sm ${imageSize} ${colorCode} ${type} ${className}`}
            >
              <span>{shortName}</span>
            </span>
          )}
        </>
      )}
    </span>
  );
}
UserImage.propTypes = {
  size: PropTypes.string,
  path: PropTypes.string,
  name: PropTypes.string,
  removeTooltip: PropTypes.bool,
  marginRight: PropTypes.string,
  shortName: PropTypes.string,
  email: PropTypes.string,
  infoDirection: PropTypes.string,
  userRole: PropTypes.string,
  verified: PropTypes.bool,
  type: PropTypes.string,
  className: PropTypes.string,
};
UserImage.defaultProps = {
  size: 'small',
  path: '',
  name: '',
  removeTooltip: false,
  marginRight: '',
  shortName: '',
  email: '',
  infoDirection: '',
  userRole: '',
  verified: true,
  type: '',
  className: '',
};
