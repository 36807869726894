import globalen from './languages/en/global.json';
import globalturkish from './languages/tr/global-tr.json';
import companyen from './languages/en/company.json';
import accountsettingsen from './languages/en/account-settings.json';
import companyturkish from './languages/tr/company.json';
import companyes from './languages/es/company.json';
import accountsettingsturkish from './languages/tr/account-settings.json';
import mydashboarden from './languages/en/my-dashbord.json';
import mydashboardturkish from './languages/tr/my-dashbord.json';
import notificationsen from './languages/en/notifications.json';
import notificationsturkish from './languages/tr/notifications.json';
import invitepartneren from './languages/en/invite-partners.json';
import invitepartnerturkish from './languages/tr/invite-partners.json';
import manageordersen from './languages/en/manage-orders.json';
import manageordersturkish from './languages/tr/manage-orders.json';
import managebookingen from './languages/en/manage-bookings.json';
import managebookingturkish from './languages/tr/manage-bookings.json';
import manageshipmentsen from './languages/en/manage-shipments.json';
import manageshipmentsturkish from './languages/tr/manage-shipments.json';
import globalleftmenuen from './languages/en/global-left-menu.json';
import globalleftmenuturkish from './languages/tr/global-left-menu.json';
import mysuppliersen from './languages/en/my-suppliers.json';
import mysuppliersturkish from './languages/tr/my-suppliers.json';
import systemsettingsen from './languages/en/system-settings.json';
import systemsettingsturkish from './languages/tr/system-settings.json';
import toasten from './languages/en/toast.json';
import toastturkish from './languages/tr/toast.json';
import statusen from './languages/en/status.json';
import statusturkish from './languages/tr/status.json';
import dashboarden from './languages/en/dashboard.json';
import dashboardtr from './languages/tr/dashboard.json';
import dashboardes from './languages/es/dashboard.json';
import accountsettingses from './languages/es/account-settings.json';
import globalleftmenues from './languages/es/global-left-menu.json';
import globales from './languages/es/global.json';
import invitepartneres from './languages/es/invite-partners.json';
import manageshipmentses from './languages/es/manage-shipments.json';
import mydashboardes from './languages/es/my-dashbord.json';
import mysupplierses from './languages/es/my-suppliers.json';
import notificationses from './languages/es/notifications.json';
import statuses from './languages/es/status.json';
import systemsettingses from './languages/es/system-settings.json';
import managebookinges from './languages/es/manage-bookings.json';
import toastes from './languages/es/toast.json';
import manageorderses from './languages/es/manage-orders.json';

const setLanguage = () => {
  return {
    fallbackLng: 'en',
    interpolation: { escapeValue: false },
    lng: window.localStorage.getItem('language') || 'en',
    resources: {
      en: {
        global: globalen,
        company: companyen,
        accountsettings: accountsettingsen,
        mydashboard: mydashboarden,
        notifications: notificationsen,
        invitepartner: invitepartneren,
        manageorders: manageordersen,
        managebooking: managebookingen,
        manageshipments: manageshipmentsen,
        globalleftmenu: globalleftmenuen,
        mysuppliers: mysuppliersen,
        systemsettings: systemsettingsen,
        toast: toasten,
        status: statusen,
        dashboard: dashboarden,
      },
      tr: {
        global: globalturkish,
        company: companyturkish,
        accountsettings: accountsettingsturkish,
        mydashboard: mydashboardturkish,
        notifications: notificationsturkish,
        invitepartner: invitepartnerturkish,
        manageorders: manageordersturkish,
        managebooking: managebookingturkish,
        manageshipments: manageshipmentsturkish,
        globalleftmenu: globalleftmenuturkish,
        mysuppliers: mysuppliersturkish,
        systemsettings: systemsettingsturkish,
        toast: toastturkish,
        status: statusturkish,
        dashboard: dashboardtr,
      },
      es: {
        global: globales,
        company: companyes,
        dashboard: dashboardes,
        accountsettings: accountsettingses,
        globalleftmenu: globalleftmenues,
        invitepartner: invitepartneres,
        manageshipments: manageshipmentses,
        mydashboard: mydashboardes,
        mysuppliers: mysupplierses,
        notifications: notificationses,
        status: statuses,
        systemsettings: systemsettingses,
        managebooking: managebookinges,
        toast: toastes,
        manageorders: manageorderses,
      },
    },
  };
};

export default setLanguage;
