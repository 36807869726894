import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import getInitials from '../../helpers/utils';
import { BackIcon, SearchIcon } from '../icons/icons';

export default function MoreItems({ orgData, groupData, hide }) {
  const [t] = useTranslation('globalleftmenu');
  const [query, setQuery] = useState('');
  // const [groupQuery, setGroupQuery] = useState('');
  const [searchValue, setSearchValue] = useState();
  // const [searchGroupValue, setSearchGroupValue] = useState();
  const [groupList, setGroupList] = useState([]);
  const [groupListFilter, setGroupListFilter] = useState(false);

  const handleChange = (e) => {
    setQuery(e.target.value?.toLowerCase());
    setSearchValue(e.target.value);
    // setSearchGroupValue(e.target.value);
    setGroupListFilter(false);
  };
  // const handleGroupChange = (e) => {
  //   setGroupQuery(e.target.value?.toLowerCase());
  //   setSearchGroupValue(e.target.value);
  // };
  const { relation } = useParams();

  const groupIconClick = (list) => {
    setGroupList(list);
    setGroupListFilter(true);
    // setQuery('');
    // setSearchValue('');
  };
  const backTolist = () => {
    setGroupListFilter(false);
  };
  return (
    <div className="more-item-container">
      <div className="more-item-header">
        <h3>
          {relation === 'buyer'
            ? t('searchpopup.searchbuyer')
            : t('searchpopup.searchsupplier')}
        </h3>
      </div>
      <div className="more-item-block">
        <div className="more-item-search">
          {/* {groupListFilter === false ? ( */}
          <input
            type="text"
            placeholder={t('searchpopup.placeholder')}
            onChange={handleChange}
            value={searchValue}
          />
          {/* ) : (
            <input
              type="text"
              placeholder="Search here..."
              onChange={handleGroupChange}
              value={searchGroupValue}
            />
          )} */}
          <SearchIcon height="1.111vw" width="1.111vw" />
        </div>
        <div className="more-item-list">
          {groupListFilter === false && (
            <>
              {orgData
                ?.filter((val) =>
                  val?.organization.orgName?.toLowerCase()?.includes(query)
                )
                .map((val) => {
                  return (
                    <div className="more-item" key={val.organization.orgName}>
                      <Link
                        to={`/app/${val?.relationship}/${val?.organization?._id}/company-dashboard`}
                        onClick={hide}
                        className="item-block"
                      >
                        <span className="icon">
                          {val.organization.orgLogo ? (
                            <img src={val.organization.orgLogo} alt="" />
                          ) : (
                            <>{getInitials(val.organization.orgName)}</>
                          )}
                        </span>

                        <span className="name">{val.organization.orgName}</span>
                      </Link>
                    </div>
                  );
                })}
              {groupData?.filter((val) => {
                return val?.list
                  .map((item) => {
                    return item.orgName?.toLowerCase()?.includes(query);
                  })
                  .includes(true);
              }).length === 0 && (
                <label className="no-search-data">
                  <span>{t('searchpopup.noresult')}</span>
                </label>
              )}

              {groupData
                ?.filter((val) => {
                  return val?.list
                    .map((item) => {
                      return item.orgName?.toLowerCase()?.includes(query);
                    })
                    .includes(true);
                })
                .map((group) => {
                  return (
                    <>
                      {group?.list?.length > 0 && (
                        <div className="more-item" key={group.list}>
                          <button
                            type="button"
                            className="item-block group"
                            onClick={() => groupIconClick(group.list)}
                          >
                            <span className="icon">
                              {group.list.slice(0, 4).map((groupName) => {
                                return (
                                  <span className="short">
                                    {groupName?.orgName &&
                                      groupName?.orgName[0]?.toUpperCase()}
                                  </span>
                                );
                              })}
                            </span>
                            <span className="name">{group.groupName}</span>
                          </button>
                        </div>
                      )}
                      <span />
                    </>
                  );
                })}
            </>
          )}
          {groupListFilter === true && (
            <>
              <button className="back" type="button" onClick={backTolist}>
                <BackIcon />
              </button>
              {groupList
                ?.filter((val) => val?.orgName?.toLowerCase()?.includes(query))
                .map((val) => {
                  return (
                    <div className="more-item" key={val.orgName}>
                      <Link
                        to={`/app/${relation}/${val?._id}/company-dashboard`}
                        onClick={hide}
                        className="item-block"
                      >
                        <span className="icon">
                          {val.orgLogo ? (
                            <img src={val.orgLogo} alt="" />
                          ) : (
                            <>{getInitials(val.orgName)}</>
                          )}
                        </span>

                        <span className="name">{val.orgName}</span>
                      </Link>
                    </div>
                  );
                })}
            </>
          )}
        </div>
        {/* <div className="more-item-footer">
            <button type="button">View All</button>
          </div> */}
      </div>
    </div>
  );
}
MoreItems.propTypes = {
  orgData: PropTypes.arrayOf(Object),
  groupData: PropTypes.arrayOf(Object),
  hide: PropTypes.func,
};
MoreItems.defaultProps = {
  orgData: [],
  groupData: [],
  hide: () => {},
};
