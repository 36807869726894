import { gql } from '@apollo/client';

const SAVE_BOOKING = gql`
  mutation SaveBooking($input: saveBookingInput) {
    saveBooking(input: $input) {
      success
      data {
        _id
      }
      message
    }
  }
`;

export const UPDATE_BOOKING = gql`
  mutation UpdatingBooking($input: updateBookingInput) {
    UpdatingBooking(input: $input) {
      success
      data
      message
    }
  }
`;

export const BULK_BUYER_BOOKING = gql`
  mutation BulkCompleteBooking($input: updateBookingInput) {
    BulkCompleteBooking(input: $input) {
      success
      data
      message
    }
  }
`;

export const FETCH_BOOKING = gql`
  query FetchBookingTypeList($input: fetchBookInput) {
    FetchBookingTypeList(input: $input) {
      success
      data
      message
    }
  }
`;

export default SAVE_BOOKING;
