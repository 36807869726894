import { gql } from '@apollo/client';

const QUERY_QULOI_NEWS = gql`
  query QuloiNews {
    QuloiNews {
      title
      link
      pubDate
      content
      contentSnippet
      enclosure {
        url
        length
        type
      }
      guid
      isoDate
      description
    }
  }
`;

export const QUERY_P44_INCIDENT = gql`
  query FetchProject44IncidentsData {
    FetchProject44IncidentsData {
      title
      description
    }
  }
`;

export default QUERY_QULOI_NEWS;
