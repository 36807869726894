import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import './user-details.scss';
import getInitials from '../../helpers/utils';
import noUser from '../../assets/image/profile.jpeg';

/**
 * @typedef {'small' | 'large' | 'medium'} SizeType - Size choices for the Demo component.
 */

/**
 * Component to demonstrate different sizes, appearances, and popup options.
 * @param {Object} props - Component props.
 * @param {SizeType} props.size - Size of the component ('Small' or 'Large').
 * @returns {JSX.Element} - Rendered component.
 */
export default function UserDetails({
  size,
  userName,
  userEmail,
  profilePic,
  userRole,
  enableLink,
  url,
}) {
  const sizesProperties = {
    small: 'small',
    medium: 'medium',
    large: 'large',
  };

  const sizeProps = sizesProperties[size];
  // add a condition
  let name = userName;
  let userImage = profilePic;
  if (name === 'undefined undefined') {
    name = 'No User';
    userImage = noUser;
  } else {
    name = userName;
    userImage = profilePic;
  }
  return (
    <div className="user-details">
      <div className={`user-details-image ${sizeProps}`}>
        {userImage !== '' ? (
          <img src={userImage} alt="" className={sizeProps} />
        ) : (
          <span>{getInitials(name)}</span>
        )}
      </div>
      <div className="user-details-info">
        {enableLink ? (
          <Link
            className={`info-user-name${enableLink ? ' link' : ''}`}
            to={url}
          >
            {name}
          </Link>
        ) : (
          <p className="info-user-name">{name}</p>
        )}
        <p className="info-user-email">{userEmail}</p>
        <p className="info-user-role">{userRole}</p>
      </div>
    </div>
  );
}

UserDetails.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'Large']),
  userName: PropTypes.string,
  userEmail: PropTypes.string,
  userRole: PropTypes.string,
  profilePic: PropTypes.string,
  enableLink: PropTypes.bool,
  url: PropTypes.string,
};

UserDetails.defaultProps = {
  size: 'small',
  userName: '',
  userEmail: '',
  userRole: '',
  profilePic: '',
  enableLink: false,
  url: '',
};
