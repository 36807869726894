// import moment from 'moment-timezone';
import moment from 'moment-timezone';
import store from '../redux/store';

export const getUserTimeZoneDateTime = (date) => {
  const userTimeZone =
    store.getState().omniProfile.profileDetail?.fetchProfileDetails?.data
      ?.timezone;

  return moment.tz(date, userTimeZone).format('DD/MM/YYYY hh:mm A');
};

export const getUserTimeZoneDateLong = (date) => {
  const userTimeZone =
    store.getState().omniProfile.profileDetail?.fetchProfileDetails?.data
      ?.timezone;

  return moment.tz(date, userTimeZone).format('dddd, MMMM DD, YYYY');
};

export const getChatHeaderDate = (date) => {
  const userTimeZone =
    store.getState().omniProfile.profileDetail?.fetchProfileDetails?.data
      ?.timezone;
  if (moment(date).calendar().includes('Today')) {
    return 'Today';
  }
  return moment.tz(date, userTimeZone).format('dddd, MMMM DD, YYYY');
};

export const getChatMessageDate = (date) => {
  const userTimeZone =
    store.getState().omniProfile.profileDetail?.fetchProfileDetails?.data
      ?.timezone;

  return moment.tz(date, userTimeZone).format('ddd, hh:mmA');
};
export const getUserTimeZoneTime = (date) => {
  const userTimeZone =
    store.getState().omniProfile.profileDetail?.fetchProfileDetails?.data
      ?.timezone;

  return moment.tz(date, userTimeZone).format('hh:mm A');
};
export const getUserTimeZoneTiDate = (date) => {
  const userTimeZone =
    store.getState().omniProfile.profileDetail?.fetchProfileDetails?.data
      ?.timezone;

  return moment.tz(date, userTimeZone).format('MMMM DD, YYYY');
};

export const getUserTimeZoneDateWithOrdinalIndicators = (date) => {
  const userTimeZone =
    store.getState().omniProfile.profileDetail?.fetchProfileDetails?.data
      ?.timezone;

  return moment.tz(date, userTimeZone).format('Do MMM YYYY');
};

export const getUserTimeZoneDateFormat = (date) => {
  const userTimeZone =
    store.getState().omniProfile.profileDetail?.fetchProfileDetails?.data
      ?.timezone;

  const dateFormat = moment.tz(date, userTimeZone).format('DD/MM/YYYY');
  if (dateFormat === 'Invalid date') {
    return '--';
  }
  return moment.tz(date, userTimeZone).format('DD/MM/YYYY') || '--';
};

export const compareDatesEqualWithoutTime = (date1, date2) => {
  const date1WithoutTime = new Date(date1);
  date1WithoutTime?.setHours(0, 0, 0, 0);

  const date2WithoutTime = new Date(date2);
  date2WithoutTime?.setHours(0, 0, 0, 0);

  return date1WithoutTime.getTime() > date2WithoutTime.getTime();
};

export const changeDateToDoFormat = (date) => {
  const userTimeZone =
    store.getState().omniProfile.profileDetail?.fetchProfileDetails?.data
      ?.timezone;

  return moment.tz(date, userTimeZone).format('DD MMM YYYY HH:mm A');
};

export const getUserTimeZoneDateTimeTest = (date) => {
  const userTimeZone =
    store.getState().omniProfile.profileDetail?.fetchProfileDetails?.data
      ?.timezone;
  const commentDate = moment.tz(date, userTimeZone);
  const now = moment.tz(userTimeZone);
  if (now.diff(commentDate, 'hours') < 24) {
    return commentDate.fromNow();
  }

  return commentDate.format('DD/MM/YYYY hh:mm A');
};

export const dateRegex =
  /^Date(.+?)(?:\d+)-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})\.(\d{3})Z$/;
