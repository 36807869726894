import { gql } from '@apollo/client';

export const QUERY_APILISTKEY = gql`
  query ApiKeyListQuery {
    ApiKeyList
  }
`;

export const MUTATE_ADD_TOKEN = gql`
  mutation GenerateApiKey($input: generateApiInput) {
    GenerateApiKey(input: $input)
  }
`;
