import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { LOCATION_LIST } from '../../../graphQL/rolesAndPermissions';
import { giveProfile } from '../../../Components/global-notification/notification-list';

function ListLocation({ locationChildren }) {
  const [locationList, setLocationList] = useState([]);
  const { relation } = useParams();

  const { loading } = useQuery(LOCATION_LIST, {
    variables: {
      input: {
        locationChildren,
        profileType: giveProfile(relation),
      },
    },
    onCompleted: (data) => {
      setLocationList(data?.LocationList?.data);
    },
  });

  return (
    !loading &&
    locationList?.length && (
      <div className="scrollable-container shadow">
        <ul className="action-list">
          {locationList?.map((location) => (
            <li>
              <button type="button">{location?.entityName}</button>
            </li>
          ))}
        </ul>
      </div>
    )
  );
}

export default ListLocation;

ListLocation.propTypes = {
  locationChildren: PropTypes.instanceOf(Array),
};

ListLocation.defaultProps = {
  locationChildren: [],
};
