import React from 'react';
import PropTypes from 'prop-types';
import UserImage from '../user-image/user-image';
import './activity-timeline.scss';
import {
  getChatMessageDate,
  getUserTimeZoneDateLong,
} from '../../helpers/dateHelper';
import { BotIcon } from '../icons/icons';

export default function ActivityTimeline({ activity, getUserDetailsById }) {
  let currentDate = '';

  return (
    <div className="activity-timeline-container">
      {activity.map((item) => {
        let renderHead = false;
        const user = getUserDetailsById(item?.sender);
        if (currentDate !== getUserTimeZoneDateLong(item?.createdAt)) {
          renderHead = true;
          currentDate = getUserTimeZoneDateLong(item?.createdAt);
        }
        return (
          <div className="activity-timeline">
            {renderHead && <h3 className="title-date">{currentDate}</h3>}
            <ul>
              <li>
                <div className="user-block">
                  {user ? (
                    <UserImage
                      path={user?.profilePicture || ''}
                      size="small"
                      name={`${user?.firstName} ${user?.lastName}`}
                      shortName={`${user?.firstName[0]}${user?.lastName[0]}`?.toUpperCase()}
                      email={user?.email}
                    />
                  ) : (
                    <span className="thumb-sm small">
                      <BotIcon />
                    </span>
                  )}
                </div>
                <div className="details-block">
                  <div className="activity-block">
                    <div className="head">
                      <h5 className="activity-title">
                        {user
                          ? `${user?.firstName} ${user?.lastName}`
                          : 'System Generated'}
                      </h5>
                      <p className="time">
                        {getChatMessageDate(item?.createdAt)}
                      </p>
                    </div>
                    <p className="activity-description">{item?.text}</p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        );
      })}
    </div>
  );
}

ActivityTimeline.propTypes = {
  activity: PropTypes.instanceOf(Array),
  getUserDetailsById: PropTypes.func.isRequired,
};

ActivityTimeline.defaultProps = {
  activity: [],
};
