import React from 'react';
import '../global-notification.scss';
import './po-changes.scss';
import PropTypes from 'prop-types';
// import PoItem from './po-item';
import UserImage from '../../user-image/user-image';
import PoGroup from './po-group';
import getInitials from '../../../helpers/utils';

function PoChangesGroup({ data, translate }) {
  const hostUrl = `${window.location.protocol}//${window.location.host}`;
  return data?.map((poData) => {
    return (
      <div className={`po-item-group${!poData.isRead ? ' active' : ''}`}>
        <div className="po-item-group-header">
          <UserImage
            removeTooltip
            path={poData?.senderOrgDetails?.senderOrgLogo}
            shortName={getInitials(poData?.senderOrgDetails.senderOrgName)}
          />

          <p className="po-item-group-id">
            {poData?.senderOrgDetails.senderOrgName}{' '}
            <span className="notification-devider-line">|</span>{' '}
            {translate('notifications.PO')} #{' '}
            <a href={`${hostUrl}/${poData?.link}`}>{poData?.poNumber}</a>
          </p>
          <p className="po-item-group-last-update">{poData?.diff}</p>
        </div>
        <div className="po-item-group-body">
          {poData?.newLineItem?.map((line) => {
            return <PoGroup data={line} translate={translate} />;
          })}
        </div>
      </div>
    );
  });
}

PoChangesGroup.propTypes = {
  data: PropTypes.instanceOf(Array),
  translate: PropTypes.func,
};
PoChangesGroup.defaultProps = {
  data: [],
  translate: () => {},
};

export default PoChangesGroup;
