import React from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from '../../Components/skeleton/skeleton';
import AccountSettingsPanel from '../../Components/account-settings-panel/account-settings-panel';
import ProfileTitleDescriptionLoader from '../../Components/profile-title-description/loader';

export default function ProfileLoader() {
  const [t] = useTranslation('accountsettings');
  return (
    <>
      <div className="page-header">
        <h1>{t('accountsettings.loadingprofile')}</h1>
      </div>
      <div className="profile-container">
        <div className="custom-tabs profile">
          <ul className="custom-tab-list">
            <li className="custom-tab-nav-item">
              <Skeleton skHeight="2.361vw" skWidth="6.944vw" />
            </li>
            <li className="custom-tab-nav-item">
              <Skeleton skHeight="2.361vw" skWidth="13.889vw" />
            </li>
            <li className="custom-tab-nav-item">
              <Skeleton skHeight="2.361vw" skWidth="11.111vw" />
            </li>
            <li className="custom-tab-nav-item">
              <Skeleton skHeight="2.361vw" skWidth="13.889vw" />
            </li>
            <li className="custom-tab-nav-item">
              <Skeleton skHeight="2.361vw" skWidth="12.5vw" />
            </li>
          </ul>
          <div className="custom-tab-content">
            <Skeleton
              skHeight="1.667vw"
              skMargin="0 0 2.639vw 0"
              skWidth="11.111vw"
            />
            <AccountSettingsPanel>
              <div className="my-profile-row">
                <div className="profile-image">
                  <Skeleton
                    skHeight="6.806vw"
                    skWidth="6.806vw"
                    skRadius="50%"
                  />
                </div>
                <div className="user-details">
                  <div className="user-information">
                    <Skeleton
                      skHeight="1.25vw"
                      skWidth="8.333vw"
                      skMargin="0 0 0.694vw 0"
                    />
                    <Skeleton
                      skHeight="1.25vw"
                      skWidth="13.889vw"
                      skMargin="0 0 0.694vw 0"
                    />
                    <Skeleton skHeight="1.25vw" skWidth="5.556vw" />
                  </div>
                </div>
              </div>
              <div className="button-transfer-area">
                <Skeleton skHeight="2.361vw" skWidth="12.5vw" />
              </div>
            </AccountSettingsPanel>
            <AccountSettingsPanel>
              <Skeleton
                skHeight="1.389vw"
                skWidth="19.444vw"
                skMargin="0.347vw 0 1.389vw 0"
              />
              <div className="my-profile-row">
                <div className="profile-form">
                  <ProfileTitleDescriptionLoader />
                </div>
                <div className="profile-form">
                  <ProfileTitleDescriptionLoader />
                </div>
              </div>
              <div className="my-profile-row">
                <div className="profile-form">
                  <ProfileTitleDescriptionLoader />
                </div>
                <div className="profile-form">
                  <ProfileTitleDescriptionLoader />
                </div>
              </div>
              <div className="button-submit-area">
                <Skeleton
                  skHeight="2.778vw"
                  skWidth="5.903vw"
                  skRadius="1.389vw"
                />
              </div>
            </AccountSettingsPanel>
            <AccountSettingsPanel>
              <Skeleton
                skHeight="1.389vw"
                skWidth="19.444vw"
                skMargin="0.347vw 0 1.389vw 0"
              />
              <div className="my-profile-row">
                <div className="profile-form">
                  <ProfileTitleDescriptionLoader />
                </div>
                <div className="profile-form">
                  <ProfileTitleDescriptionLoader />
                </div>
              </div>

              <div className="button-submit-area">
                <Skeleton
                  skHeight="2.778vw"
                  skWidth="5.903vw"
                  skRadius="1.389vw"
                />
              </div>
            </AccountSettingsPanel>
          </div>
        </div>
      </div>
    </>
  );
}
