import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { gql, useMutation } from '@apollo/client';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import logo from '../../assets/image/logo.png';
import './login-success.scss';

const SIGNUP_SELF_VERIFY = gql`
  mutation Mutation($input: selfSignupInput) {
    userVerifySelfSignup(input: $input) {
      message
    }
  }
`;

export default function LoginSuccess() {
  const [t] = useTranslation('toast');
  const [verified, setVerified] = useState(false);

  const [handleSelfSignUp] = useMutation(SIGNUP_SELF_VERIFY);

  useEffect(() => {
    function fetchSelfToken() {
      const params = new URLSearchParams(window.location.search);
      handleSelfSignUp({
        variables: {
          input: {
            userSub: params.get('sub'),
          },
        },
        onCompleted: (data) => {
          if (data.userVerifySelfSignup.message !== 'success') {
            toast.info(data.userVerifySelfSignup.message);
            window.location = 'login';
          } else {
            toast.success(data.userVerifySelfSignup.message);
            setVerified(true);
            window.location = 'login';
          }
        },
        onError: () => {
          toast.error(t('loginsuccess.error.unabletoverify'));
          setVerified(false);
        },
      });
    }
    fetchSelfToken();
    return () => {};
  }, []);

  return (
    <div className="login-success-container">
      <div className="login-success-block">
        <span className="ball">&nbsp;</span>
        <div className="logo">
          <img src={logo} alt="" />
        </div>
        <div className="icon-block">
          <svg
            width="68"
            height="68"
            viewBox="0 0 68 68"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M34 67.3337C15.59 67.3337 0.666626 52.4103 0.666626 34.0003C0.666626 15.5903 15.59 0.666992 34 0.666992C52.41 0.666992 67.3333 15.5903 67.3333 34.0003C67.3333 52.4103 52.41 67.3337 34 67.3337ZM34 60.667C41.0724 60.667 47.8552 57.8575 52.8561 52.8565C57.8571 47.8555 60.6666 41.0728 60.6666 34.0003C60.6666 26.9279 57.8571 20.1451 52.8561 15.1441C47.8552 10.1432 41.0724 7.33366 34 7.33366C26.9275 7.33366 20.1448 10.1432 15.1438 15.1441C10.1428 20.1451 7.33329 26.9279 7.33329 34.0003C7.33329 41.0728 10.1428 47.8555 15.1438 52.8565C20.1448 57.8575 26.9275 60.667 34 60.667ZM30.6766 47.3337L16.5333 33.1903L21.2466 28.477L30.6766 37.907L49.53 19.0503L54.2466 23.7637L30.6766 47.3337Z"
              fill="#52C41A"
            />
          </svg>
        </div>
        {verified === true ? (
          <>
            <h2 className="message">You have been successfully verified</h2>
            <h3 className="text">Thank You!</h3>
            <Link to="/login" className="link">
              Click to login again
            </Link>
          </>
        ) : (
          <>
            <h2 className="message">Verifying your Token...</h2>{' '}
          </>
        )}

        <div className="footer">
          <p>Powered by Omni</p>
        </div>
      </div>
    </div>
  );
}
