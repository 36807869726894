import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import CustomTable from '../../../Components/table/table';
import './details-listing.scss';
import PanelCard from '../../../Components/panel-card/panel-card';
import {
  AcceptIcon,
  RejectIcon,
  ProposeChangeIcon,
} from '../../../Components/icons/icons';
import NoData from '../../../Components/no-data/no-data';
import BesicTable from '../../../Components/table/basicTable';
import ListingStatus from '../../../Components/listing-status/listing-status';
import { UPDATE_ORDERITEMS } from '../../../graphQL/orderDetailsPage';
// import Button from '../../../Components/button/button';
import NewButton from '../../../Components/button/newButton';
import { giveProfile } from '../../../Components/global-notification/notification-list';

export default function DetailsListing({
  title,
  data,
  columns,
  activeButtons,
  acceptClick,
  rejectClick,
  proposeClick,
  defaultClick,
  defaultButton,
  disableDefaultButton,
  sendData,
  eventAction,
  isCheckBoxRequired,
  lineItemIdSelected,
  emptyArrayflag,
  boolVal,
  poItemCodeArray,
}) {
  const [t] = useTranslation('manageorders');
  return (
    <div className="details-listing-container">
      {title !== '' && (
        <div className="details-listing-header">
          <h3 className="title">{title}</h3>
          <div className="button-section">
            {!disableDefaultButton && defaultButton !== '' && (
              <NewButton className="propose" onClick={defaultClick}>
                {defaultButton}
              </NewButton>
            )}
            {disableDefaultButton && (
              <NewButton className="disabled" disabled>
                {defaultButton}
              </NewButton>
            )}
            {activeButtons && (
              <>
                {eventAction?.Accept && (
                  <button
                    type="button"
                    className="accept"
                    onClick={acceptClick}
                  >
                    <AcceptIcon /> {t('manageordersscreen.acceptpopup.title')}
                  </button>
                )}
                {eventAction?.Reject && (
                  <button
                    type="button"
                    className="reject"
                    onClick={rejectClick}
                  >
                    <RejectIcon /> {t('manageordersscreen.rejectpopup.title')}
                  </button>
                )}
                {eventAction?.ProposeChange && (
                  <NewButton className="propose" onClick={proposeClick}>
                    <ProposeChangeIcon />
                    {t('manageordersscreen.proposechanges.title')}
                  </NewButton>
                  // <Button className="propose" onClick={proposeClick}>
                  //   <ProposeChangeIcon />
                  //   Propose Changes
                  // </Button>
                )}
              </>
            )}
          </div>
        </div>
      )}
      <div className="details-listing-body">
        <PanelCard noSpacing>
          <div>
            {(data && data?.length === 0) || data?.length === undefined ? (
              <NoData />
            ) : (
              <div>
                {!isCheckBoxRequired ? (
                  <BesicTable
                    columns={columns}
                    data={data}
                    getChildData={(x, isChecked) => {
                      sendData(x, isChecked);
                    }}
                  />
                ) : (
                  <CustomTable
                    emptyArrayflag={emptyArrayflag}
                    boolVal={boolVal}
                    poItemCodeArray={poItemCodeArray}
                    lineItemIdSelected={lineItemIdSelected}
                    columns={columns}
                    data={data}
                    getChildData={(x, isChecked) => {
                      sendData(x, isChecked);
                    }}
                    isCheckBoxRequired={isCheckBoxRequired}
                  />
                )}
              </div>
            )}
          </div>
        </PanelCard>
      </div>
    </div>
  );
}
DetailsListing.propTypes = {
  title: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.string).isRequired,
  columns: PropTypes.arrayOf(PropTypes.string).isRequired,
  eventAction: PropTypes.instanceOf(Object),
  lineItemIdSelected: PropTypes.instanceOf(Array),
  acceptClick: PropTypes.func,
  emptyArrayflag: PropTypes.number,
  rejectClick: PropTypes.func,
  proposeClick: PropTypes.func,
  defaultClick: PropTypes.func,
  activeButtons: PropTypes.bool,
  boolVal: PropTypes.bool,
  defaultButton: PropTypes.string,
  sendData: PropTypes.func,
  poItemCodeArray: PropTypes.instanceOf(Array),
  isCheckBoxRequired: PropTypes.bool,
  disableDefaultButton: PropTypes.bool,
};
DetailsListing.defaultProps = {
  title: '',
  defaultButton: '',
  emptyArrayflag: 0,
  poItemCodeArray: [],
  eventAction: {},
  activeButtons: false,
  lineItemIdSelected: [],
  sendData: () => {},
  acceptClick: () => {},
  rejectClick: () => {},
  proposeClick: () => {},
  defaultClick: () => {},
  isCheckBoxRequired: false,
  boolVal: false,
  disableDefaultButton: false,
};

export function DropDownSts({
  statusLabel,
  enableDropdown,
  futureStatus,
  lineItem,
}) {
  const { poId, bookingId, id } = useParams();
  const [handleUpdateOrderItem] = useMutation(UPDATE_ORDERITEMS);
  const { relation } = useParams();

  const checkChannelType = (chnid) => {
    let chnType;
    switch (chnid) {
      case poId:
        chnType = 'order';
        break;
      case bookingId:
        chnType = 'booking';
        break;
      case id:
        chnType = 'shipment';
        break;
      default:
        break;
    }
    return chnType;
  };

  const handleSubmit = (ip) => {
    handleUpdateOrderItem({
      variables: {
        input: {
          id: poId || bookingId || id,
          module: checkChannelType(poId || bookingId || id),
          lineItemId: [lineItem._id],
          profileType: giveProfile(relation),
          ...ip,
        },
      },
      onCompleted: (data) => {
        toast.success(data?.updateOrderItem?.message);
      },
    });
  };

  return (
    <ListingStatus
      statusLabel={statusLabel}
      enableDropdown={enableDropdown}
      futureStatus={futureStatus}
      handleSubmit={handleSubmit}
      item={lineItem}
    />
  );
}
DropDownSts.propTypes = {
  statusLabel: PropTypes.string,
  lineItem: PropTypes.string,
  enableDropdown: PropTypes.bool,
  futureStatus: PropTypes.instanceOf(Array),
};
DropDownSts.defaultProps = {
  statusLabel: '',
  lineItem: '',
  enableDropdown: false,
  futureStatus: [],
};
