import React from 'react';
import PropTypes from 'prop-types';

function CardPanelBody({ noTopSpacing, noBottomSpacing, ...props }) {
  return (
    <div
      data-cy="card-panel-body"
      className={`card-body ${noTopSpacing ? 'pt-0' : ''} ${
        noBottomSpacing ? 'pb-0' : ''
      }`}
    >
      {props.children}
    </div>
  );
}

export default CardPanelBody;
CardPanelBody.propTypes = {
  children: PropTypes.node.isRequired,
  noTopSpacing: PropTypes.bool,
  noBottomSpacing: PropTypes.bool,
};
CardPanelBody.defaultProps = {
  noTopSpacing: false,
  noBottomSpacing: false,
};
