import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  BoxOutlineAddIcon,
  BoxOutlineIcon,
  ByAirRedIcon,
  CurgoTruckIcon,
  DestinationDoorIcon,
  DestinationPortIcon,
  OceanModeRedIcon,
  OriginDoorIcon,
  OriginPortIcon,
  RedDomesticTruckIcon,
  TruckMulticolorIcon,
} from '../icons/icons';

function ShipmentProgress({
  activeOriginDoor,
  activeDestinationDoor,
  enableDestinationDoor,
  enableOriginDoor,
  disableOriginDoor,
  disableDestinationDoor,
  selectedVendorType,
  mode,
  typeOfMove,
}) {
  const [showOriginDoor, setShowOriginDoor] = useState(false);
  const [showDestinationDoor, setShowDestinationDoor] = useState(false);
  useEffect(() => {
    const typeOfMoveArrey = typeOfMove.split(' ');
    const firstIndex = typeOfMoveArrey.shift();
    const lastIndex = typeOfMoveArrey[typeOfMoveArrey.length - 1];
    setShowOriginDoor(firstIndex);
    setShowDestinationDoor(lastIndex);
  }, [typeOfMove]);
  return (
    <div className="shipment-progress mb-30">
      <ul className="shipment-progress-bar">
        <li
          className={`${
            (activeOriginDoor && selectedVendorType !== 'delivery') ||
            selectedVendorType === 'pickUp'
              ? ''
              : 'disable'
          }${showOriginDoor === 'door' ? '' : ' hide'}`}
        >
          <span className="content">
            <OriginDoorIcon />
            <span className="label">Origin Door</span>
          </span>
          {mode !== 'Inland' && (
            <span class="middle-content">
              <span className="icon">
                <RedDomesticTruckIcon size="24" />
              </span>
              <span className="text">
                <button
                  type="button"
                  onClick={enableOriginDoor}
                  disabled={disableOriginDoor}
                >
                  <span className="box">
                    <BoxOutlineIcon />
                  </span>
                  {activeOriginDoor ? '' : 'Add '}Pick-Up
                  <span className="truck">
                    <TruckMulticolorIcon />
                  </span>
                </button>
              </span>
            </span>
          )}
        </li>
        {mode !== 'Inland' && (
          <>
            <li
              className={`last ${
                selectedVendorType === 'delivery' ? 'disable' : ''
              }`}
            >
              <span className="content">
                <OriginPortIcon />
                <span className="label">Origin Port</span>
              </span>
            </li>
            <li
              className={`middle ${
                selectedVendorType === 'delivery' ||
                selectedVendorType === 'pickUp'
                  ? 'disable'
                  : ''
              }`}
            >
              <span class="middle-content">
                <span className="icon">
                  {mode === 'Air' && <ByAirRedIcon />}
                  {mode === 'Ocean' && <OceanModeRedIcon />}
                </span>
                {mode !== 'Inland' ? (
                  <span className="text">Primary</span>
                ) : null}
              </span>
            </li>
            <li className={selectedVendorType === 'pickUp' ? 'disable' : ''}>
              <span className="content">
                <DestinationPortIcon />
                <span className="label">Destination Port</span>
              </span>
            </li>
          </>
        )}
        <li
          className={`${
            (activeDestinationDoor && selectedVendorType !== 'pickUp') ||
            selectedVendorType === 'delivery'
              ? ''
              : 'disable'
          }${showDestinationDoor === 'door' ? '' : ' hide'}`}
        >
          <span className="content">
            <DestinationDoorIcon />
            <span className="label">Destination Door</span>
          </span>
          <span class="middle-content">
            <span className="icon">
              {mode !== 'Inland' ? (
                <RedDomesticTruckIcon size="24" />
              ) : (
                <CurgoTruckIcon size="24" />
              )}
            </span>
            {mode !== 'Inland' && (
              <span className="text">
                <button
                  type="button"
                  onClick={enableDestinationDoor}
                  disabled={disableDestinationDoor}
                >
                  <span className="box">
                    <BoxOutlineAddIcon />
                  </span>
                  {disableDestinationDoor ||
                  (activeDestinationDoor && selectedVendorType !== 'pickUp') ||
                  selectedVendorType === 'delivery'
                    ? ''
                    : 'Add '}
                  Delivery
                  <span className="truck">
                    <TruckMulticolorIcon />
                  </span>
                </button>
              </span>
            )}
          </span>
        </li>
      </ul>
    </div>
  );
}

export default ShipmentProgress;
ShipmentProgress.propTypes = {
  activeOriginDoor: PropTypes.bool,
  activeDestinationDoor: PropTypes.bool,
  enableDestinationDoor: PropTypes.func,
  enableOriginDoor: PropTypes.func,
  disableOriginDoor: PropTypes.bool,
  disableDestinationDoor: PropTypes.bool,
  selectedVendorType: PropTypes.string,
  mode: PropTypes.string,
  typeOfMove: PropTypes.string,
};
ShipmentProgress.defaultProps = {
  activeOriginDoor: false,
  activeDestinationDoor: false,
  enableDestinationDoor: () => {},
  enableOriginDoor: () => {},
  disableOriginDoor: false,
  disableDestinationDoor: false,
  selectedVendorType: 'primary',
  mode: '',
  typeOfMove: '',
};
