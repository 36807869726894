import React from 'react';
import PropTypes from 'prop-types';
import './file-type.scss';

function FileType({ extantion }) {
  return (
    <span className={`file-format ${extantion}`}>
      <span className="lines">&nbsp;</span>
      <span className="format">{extantion}</span>
    </span>
  );
}

export default FileType;

FileType.propTypes = {
  extantion: PropTypes.string,
};
FileType.defaultProps = {
  extantion: '',
};
