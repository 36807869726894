import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './user-short-name.scss';
import UserInfo from '../user-info/user-info';

export default function UserShartName({
  size,
  text,
  counter,
  bg,
  name,
  email,
  infoDirection,
  userRole,
  removeTooltip,
}) {
  const [imageSize, setImageSize] = useState('');
  const [bgColor, setBgColor] = useState('');

  const Color = (alpha) => {
    if (alpha) {
      setBgColor(alpha.toLowerCase());
    }
  };
  useEffect(() => {
    switch (size) {
      case 'x-large':
        setImageSize('thumb-sm x-large');
        break;
      case 'large':
        setImageSize('thumb-sm large');
        break;
      case 'medium':
        setImageSize('thumb-sm medium');
        break;
      case 'small':
        setImageSize('thumb-sm small');
        break;
      default:
        setImageSize('thumb-sm');
    }
  }, [size]);
  useEffect(() => {
    Color(bg);
  }, [bg]);
  return !removeTooltip ? (
    <UserInfo
      userName={name}
      shortName={text}
      userEmail={email}
      userRole={userRole || 'Admin'}
      direction={infoDirection}
    >
      <span className={`${imageSize} ${counter ? 'counter' : ''} ${bgColor}`}>
        {text}
      </span>
    </UserInfo>
  ) : (
    <span className={`${imageSize} ${counter ? 'counter' : ''} ${bgColor}`}>
      {text}
    </span>
  );
}
UserShartName.propTypes = {
  size: PropTypes.string,
  text: PropTypes.string,
  counter: PropTypes.string,
  bg: PropTypes.string,
  name: PropTypes.string,
  email: PropTypes.string,
  infoDirection: PropTypes.string,
  userRole: PropTypes.string,
  removeTooltip: PropTypes.bool,
};
UserShartName.defaultProps = {
  size: 'small',
  text: '',
  counter: '',
  bg: '',
  name: '',
  email: '',
  infoDirection: '',
  userRole: '',
  removeTooltip: false,
};
