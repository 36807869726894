import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import './user-list.scss';
import { ShortDownIcon } from '../icons/icons';
import { getSingleInitials } from '../../helpers/utils';

export default function UserList({
  placeholder,
  onChange,
  data,
  handleInputChanged,
  defaultValue,
  label,
  errorMessage,
  message,
  required,
  children,
}) {
  const [t] = useTranslation('accountsettings');
  const [query, setQuery] = useState('');
  const [selectedValue, setSelectedValue] = useState();
  const [openDDL, setOpenDDl] = useState();
  const [getTop, setGetTop] = useState();
  const [getLeft, setGetLeft] = useState();
  const [getWidth, setGetWidth] = useState();
  const listHeight = useRef();
  const [searchValue, setSearchValue] = useState();
  const ref = useRef(null);
  const boxHeight = useRef(null);

  useEffect(() => {
    setSelectedValue(defaultValue?.label);
    setSearchValue('');
    setOpenDDl(false);
  }, [defaultValue?.label]);

  const getValue = (e) => {
    setSelectedValue(e.target.nextSibling.innerHTML);
    onChange(e);
    setOpenDDl(false);
    setSearchValue('');
  };

  const getPosition = () => {
    setQuery('');
    setTimeout(() => {
      if (
        boxHeight.current.getBoundingClientRect().top +
          boxHeight.current.getBoundingClientRect().height +
          listHeight.current.getBoundingClientRect().height >
        window.innerHeight
      ) {
        setGetTop(
          boxHeight.current.getBoundingClientRect().top -
            listHeight.current.getBoundingClientRect().height
        );
      } else {
        setGetTop(
          boxHeight.current.getBoundingClientRect().top +
            boxHeight.current.getBoundingClientRect().height
        );
      }

      setOpenDDl(true);
      ref.current.focus();
    }, 200);
    setGetWidth(boxHeight.current.getBoundingClientRect().width);
    setGetLeft(boxHeight.current.getBoundingClientRect().left);
  };

  const handleChange = (e) => {
    setQuery(e.target.value.toLowerCase());
    handleInputChanged(e);
    setSelectedValue(null);
    setSearchValue(e.target.value);
  };
  const closeDropdown = () => {
    setOpenDDl(false);
  };
  const removeValue = () => {
    setSelectedValue('');
  };
  return (
    <div className="user-list-select-container">
      <div className={`user-list-select ${openDDL ? 'active' : ''}`}>
        <label
          htmlFor={label}
          className={`label${openDDL || selectedValue ? ' active' : ''}`}
        >
          {label}
          {required && <span className="required">*</span>}
        </label>
        <span className="icon">{children}</span>

        <div className="textbox-area" ref={boxHeight}>
          {/* {selectedValue && ( */}
          <div className="selected-value">{ReactHtmlParser(selectedValue)}</div>
          {/* )} */}

          {/* {!selectedValue && ( */}
          <input
            type="text"
            placeholder={!selectedValue && openDDL ? placeholder : ''}
            onChange={handleChange}
            onClick={getPosition}
            onKeyDown={removeValue}
            ref={ref}
            value={searchValue}
            style={{ cursor: 'pointer' }}
            id={label}
          />
          {/* )} */}

          <ShortDownIcon />
        </div>

        {openDDL && (
          <div
            className="select-mask"
            onClick={closeDropdown}
            onKeyDown={closeDropdown}
            role="button"
            tabIndex="0"
          >
            &nbsp;
          </div>
        )}

        <ul
          className={`user-list-options ${openDDL ? 'show' : ''}`}
          style={{ width: getWidth, top: getTop, left: getLeft }}
          ref={listHeight}
        >
          {data?.filter(
            (val) =>
              val.value?.toLowerCase().includes(query) ||
              val.firstName?.toLowerCase().includes(query) ||
              val.lastName?.toLowerCase().includes(query)
          ).length === 0 && (
            <li>
              <label className="option">
                <span className="label no-data">
                  {t('accountsettings.myprofile.transferaccountdilog.nodata')}
                </span>
              </label>
            </li>
          )}
          {data
            ?.filter(
              (val) =>
                val.value?.toLowerCase().includes(query) ||
                val.firstName?.toLowerCase().includes(query) ||
                val.lastName?.toLowerCase().includes(query)
            )
            .map((val) => (
              <li key={val.value}>
                <label
                  className={`option ${
                    selectedValue === val.value ? 'active' : ''
                  }`}
                  htmlFor={val.value}
                >
                  <input
                    type="radio"
                    onChange={getValue}
                    onClick={getValue}
                    name="ddOptions"
                    value={val.value}
                    defaultChecked={selectedValue === val.value}
                    id={val.value}
                  />
                  <span className="label">
                    <span className="profile-image">
                      {val.image === '' ? (
                        getSingleInitials(val.firstName) +
                        getSingleInitials(val.lastName)
                      ) : (
                        <img src={val.image} alt="" />
                      )}
                    </span>
                    <span className="user-details">
                      <span className="user-full-name">
                        {val.firstName} {val.lastName}
                      </span>
                      <span className="user-email">{val.value}</span>
                    </span>
                  </span>
                </label>
              </li>
            ))}
        </ul>
      </div>
      {message !== '' && <p className="message">{message}</p>}
      {errorMessage !== '' && <p className="error-message">{errorMessage}</p>}
    </div>
  );
}
UserList.propTypes = {
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
  data: PropTypes.instanceOf(Array),
  handleInputChanged: PropTypes.func,
  label: PropTypes.string,
  errorMessage: PropTypes.string,
  message: PropTypes.string,
  children: PropTypes.node,
};
UserList.defaultProps = {
  required: false,
  placeholder: '',
  defaultValue: '',
  onChange: () => {},
  data: [],
  handleInputChanged: () => {},
  label: '',
  errorMessage: '',
  message: '',
  children: [],
};
