import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { ShippingProgressNoDataIcon } from '../../icons/icons';
import './shipping-progress-no-data.scss';

export default function ShippingProgressNoData({ message, size }) {
  const [t] = useTranslation('manageshipments');
  const defaultMessage = t(
    'manageshipmentsscreen.shipmentdetails.emptymessage.notrackingdataavailable'
  );
  return (
    <div className="shipping-progress-no-data">
      <ShippingProgressNoDataIcon height={size} width={size} />
      <p>{message || defaultMessage}</p>
    </div>
  );
}
ShippingProgressNoData.propTypes = {
  message: PropTypes.string,
  size: PropTypes.string,
};
ShippingProgressNoData.defaultProps = {
  message: null,
  size: '',
};
