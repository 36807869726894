import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import { getYear } from 'date-fns';
import './datepicker-box.scss';
import { CalenderIcon, EditCalenderIcon } from '../icons/icons';
// import Action from '../action/action';
// import { RadioButton } from '../radio-button/radio-button';
// import { getUserTimeZoneDateLong } from '../../helpers/dateHelper';

export default function DatepickerBox({
  id,
  label,
  errorMessage,
  required,
  selectsRange,
  isClearable,
  startDate,
  endDate,
  mBottom,
  placeholder,
  onChange,
  selectedDate,
  setDate,
  monthsShown,
  dateFormat,
  enableEdit,
  disabled,
  handleEnable,
  className,
  showTimeInput,
  mTop,
  inline,
  customHeader,
}) {
  const renderDayContents = (date) => {
    return <span>{date}</span>;
  };
  const currentYear = new Date().getFullYear();

  // Create an array of years from 1990 to the current year
  const years = [];
  for (let year = 1990; year <= currentYear; year++) {
    years.push(year);
  }
  // const months = [
  //   'January',
  //   'February',
  //   ' March',
  //   'April',
  //   ' May',
  //   'June',
  //   ' July',
  //   'August',
  //   'September',
  //   'October',
  //   'November',
  //   ' December',
  // ];
  return (
    <div
      className={`date-box-group ${className}`}
      style={{ marginBottom: mBottom, marginTop: mTop }}
    >
      {label && (
        <label htmlFor={id}>
          {label}
          {required && <span className="required">*</span>}
        </label>
      )}
      <div className="position-relative">
        {enableEdit && (
          <button type="button" className="edit-box" onClick={handleEnable}>
            <EditCalenderIcon />
          </button>
        )}
        {customHeader ? (
          <DatePicker
            renderCustomHeader={({
              date,
              monthDate,
              customHeaderCount,
              changeYear,
              // changeMonth,
              decreaseMonth,
              increaseMonth,
            }) => (
              <div>
                <button
                  aria-label="Previous Month"
                  className="react-datepicker__navigation react-datepicker__navigation--previous"
                  style={
                    customHeaderCount === 1 ? { visibility: 'hidden' } : null
                  }
                  onClick={decreaseMonth}
                  type="button"
                >
                  <span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--previous">
                    {'<'}
                  </span>
                </button>
                <span className="react-datepicker__current-month">
                  {/* {console.log(months[getMonth(date)])}
                  <select
                    value={months[getMonth(date)]}
                    onChange={({ target: { value } }) =>
                      changeMonth(months.indexOf(value))
                    }
                  >
                    {months.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select> */}

                  {/* <select
                    value={getYear(date)}
                    onChange={({ target: { value } }) => changeYear(value)}
                  >
                    {years.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select> */}
                  {monthDate?.toLocaleString('en-US', {
                    month: 'long',
                    // year: 'numeric',
                  })}
                  {/* {console.log(getYear(date))} */}
                  {/* <Action
                    label={getYear(date)}
                    zIndex={10000000}
                    // closeOnclick
                    className="react-datepicker__current-month"
                  >
                    {years.map((option) => (
                      <RadioButton
                        id={option}
                        key={option}
                        value={option}
                        className="radio-button-individual"
                        defaultChecked={getYear(date) === option}
                        onChange={({ target: { value } }) => changeYear(value)}
                        name="years"
                      >
                        {option}
                      </RadioButton>
                    ))}
                  </Action> */}
                  <select
                    value={getYear(date)}
                    onChange={({ target: { value } }) => changeYear(value)}
                  >
                    {years.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                </span>
                <button
                  aria-label="Next Month"
                  className="react-datepicker__navigation react-datepicker__navigation--next"
                  style={
                    customHeaderCount === 0 ? { visibility: 'hidden' } : null
                  }
                  onClick={increaseMonth}
                  type="button"
                >
                  <span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--next">
                    {'>'}
                  </span>
                </button>
              </div>
            )}
            selectsRange={selectsRange}
            monthsShown={monthsShown}
            isClearable={isClearable}
            startDate={startDate}
            endDate={endDate}
            placeholderText={placeholder}
            dateFormat={dateFormat}
            disabled={disabled}
            timeInputLabel="Time:"
            showTimeInput={showTimeInput}
            inline={inline}
            renderDayContents={renderDayContents}
            selected={selectedDate}
            onChange={(e) => {
              onChange(e);
              setDate(e);
            }}
          />
        ) : (
          <DatePicker
            selected={selectedDate}
            onChange={(e) => {
              onChange(e);
              setDate(e);
            }}
            selectsRange={selectsRange}
            monthsShown={monthsShown}
            isClearable={isClearable}
            startDate={startDate}
            endDate={endDate}
            placeholderText={placeholder}
            dateFormat={dateFormat}
            disabled={disabled}
            timeInputLabel="Time:"
            showTimeInput={showTimeInput}
            inline={inline}
            renderDayContents={renderDayContents}
          />
        )}
        {enableEdit && <CalenderIcon />}
      </div>

      {errorMessage !== '' && <p className="error-message">{errorMessage}</p>}
    </div>
  );
}
DatepickerBox.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  errorMessage: PropTypes.string,
  required: PropTypes.bool,
  selectsRange: PropTypes.bool,
  isClearable: PropTypes.bool,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  mBottom: PropTypes.string,
  placeholder: PropTypes.string,
  selectedDate: PropTypes.instanceOf(Date).isRequired,
  onChange: PropTypes.func,
  setDate: PropTypes.func,
  className: PropTypes.string,
  monthsShown: PropTypes.number,
  dateFormat: PropTypes.string,
  enableEdit: PropTypes.bool,
  disabled: PropTypes.bool,
  handleEnable: PropTypes.func,
  showTimeInput: PropTypes.bool,
  mTop: PropTypes.string,
  inline: PropTypes.bool,
  customHeader: PropTypes.bool,
};
DatepickerBox.defaultProps = {
  label: '',
  id: '',
  className: '',
  errorMessage: '',
  required: false,
  selectsRange: false,
  isClearable: false,
  startDate: '',
  endDate: '',
  mBottom: '',
  placeholder: '',
  onChange: () => {},
  setDate: () => {},
  monthsShown: 1,
  dateFormat: 'dd/MM/yyyy',
  enableEdit: false,
  disabled: false,
  handleEnable: () => {},
  showTimeInput: false,
  mTop: '',
  inline: false,
  customHeader: false,
};
