import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import './custom-texteditor.scss';
import {
  BoldDarkIcon,
  ItalicIconNew,
  // LinkIconNew,
} from '../icons/comments-icon';
import NewButton from '../button/newButton';

function CommentEditor({ getEditorValue }) {
  const editorRef = useRef(null);
  const [currentButton, setCurrentButton] = useState('');

  const applyStyle = (style) => {
    document.execCommand(style, false, null);
    editorRef.current.focus();
    setCurrentButton(style);
    getEditorValue(editorRef.current.innerHTML);
  };

  const handleKeyPress = () => {
    setTimeout(() => {
      getEditorValue(editorRef.current.innerHTML);
    }, 0);
  };

  return (
    <div className="custom-editor no-border">
      <div
        ref={editorRef}
        className="editor-content boreder-bottom"
        contentEditable
        role="textbox"
        tabIndex="0"
        onKeyDown={handleKeyPress}
        placeholder="Enter your comment here (optional)"
      />
      <div className="toolbar-wrapper justify-between">
        <div className="toolbar">
          <div className="inline-options">
            <div className="option-wrapper">
              <button
                type="button"
                className={`toolbar-btn${
                  currentButton === 'bold' ? ' active' : ''
                }`}
                onClick={() => applyStyle('bold', null)}
              >
                <BoldDarkIcon />
              </button>
            </div>
            <div className="option-wrapper">
              <button
                type="button"
                className={`toolbar-btn${
                  currentButton === 'italic' ? ' active' : ''
                }`}
                onClick={() => applyStyle('italic', null)}
              >
                <ItalicIconNew />
              </button>
            </div>
          </div>

          {/* <div className="link-options">
            <div className="option-wrapper">
              <button
                type="button"
                className={`toolbar-btn${
                  currentButton === 'toolbar-btn' ? ' active' : ''
                }`}
              >
                <LinkIconNew />
              </button>
            </div>
          </div> */}
        </div>
        <div className="Comment-btn-area">
          <div className="option-wrapper">
            <NewButton styles="secondary" className="comment-btn">
              Comment
            </NewButton>{' '}
          </div>
        </div>
      </div>
    </div>
  );
}

CommentEditor.propTypes = {
  getEditorValue: PropTypes.func,
};

CommentEditor.defaultProps = {
  getEditorValue: () => {},
};

export default CommentEditor;
