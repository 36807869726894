import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import UserImage from '../../user-image/user-image';
import Images from '../imagePreview/imagePreview';
import { getChatMessageDate } from '../../../helpers/dateHelper';
import MultipleImagePreview from '../multiple-image-preview/multiple-image-preview';
import {
  BotIcon,
  MoreHorIcon,
  // AcceptIcon, RejectIcon
} from '../../icons/icons';
import FileType from '../../file-type/file-type';
import Action from '../../action/action';
// import Tooltip from '../../tooltip/tooltip';

export default function ReceivedMessage({
  type,
  messageData,
  keyind,
  reply,
  // buttonVisibility,
  // accept,
  // reject,
  systemMessage,
}) {
  const handleInitialUserName = (str) => {
    let name = str
      ?.split(' ')
      ?.map((word) => word[0])
      ?.join('');
    name = name?.substr(0, 2);
    return name?.toUpperCase();
  };
  const clickedReply = () => {
    reply(messageData);
  };

  const [t] = useTranslation('manageorders');

  return (
    <div
      className={`chat-message-container ${
        type === 'success' ? 'accepted' : ''
      }${type === 'requested' ? 'requested' : ''}${
        systemMessage ? ' system' : ''
      }`}
      key={keyind}
    >
      <div className="user">
        {systemMessage ? (
          <span className="thumb-sm small">
            <BotIcon />
          </span>
        ) : (
          <UserImage
            path={messageData.profilePicture}
            name={messageData.fullName}
            shortName={handleInitialUserName(messageData?.fullName)}
            email={messageData?.email}
          />
        )}
      </div>
      <div className="chat-message">
        <div className="msg-top">
          {messageData && (
            <>
              <span className="name">
                {systemMessage ? 'QuloiBot' : messageData.fullName}
              </span>
              <span className="date">
                {getChatMessageDate(messageData.createdAt)}
              </span>
            </>
          )}
          <Action
            className="message-action received"
            moreIcon={<MoreHorIcon />}
            width="6.944vw"
            closeOnclick
          >
            <button type="button" onClick={clickedReply}>
              {t('manageordersscreen.chatmenu.reply')}
            </button>
          </Action>
        </div>

        {messageData?.mediaPath?.length === 1 &&
          messageData.mediaPath.map((element) => {
            return (
              <div className="chat-file-container received">
                {['jpg', 'png', 'jpeg', 'JPEG', 'JPG', 'PNG'].includes(
                  element?.type?.split('/').pop() || element?.type
                ) ? (
                  <Images
                    imagePath={
                      element.path !== ''
                        ? element.path
                        : 'http://dummyimage.com/200x200.png/cc0000/ffffff'
                    }
                  />
                ) : (
                  <>
                    {/* <span className={`file-format ${element.type}`}>
                      <span className="lines">&nbsp;</span>
                      <span className="format">{element.type}</span>
                    </span> */}
                    <FileType
                      extantion={
                        element?.originalFilename?.split('.')?.pop() ||
                        element.type
                      }
                    />
                  </>
                )}
                <span className="file-name">
                  <a href={element.path} download>
                    {element.originalFilename}
                  </a>
                </span>
                <span />
              </div>
            );
          })}
        {messageData?.mediaPath?.length > 1 && (
          <MultipleImagePreview data={messageData?.mediaPath} />
        )}
        {/* <div className="msg-bottom">
          <p>{messageData.messageContent || 'This message has been deleted'}</p>
          {buttonVisibility &&
            (messageData?.messageContent.split(' ')[0] === 'Order' ||
              messageData?.messageContent.split(' ')[0] === 'Booking') &&
            (messageData?.messageContent.split(' ').pop() === 'Created' ||
              messageData?.messageContent.split(' ')[2] === 'Created') && (
              <div className="action-buttons">
                <Tooltip content="Accept" direction="bottom">
                  <button type="button" onClick={accept}>
                    <AcceptIcon color="#1cab00" />
                  </button>
                </Tooltip>
                <Tooltip content="Reject" direction="bottom">
                  <button type="button" onClick={reject}>
                    <RejectIcon color="#ff1111" />
                  </button>
                </Tooltip>
              </div>
            )}
        </div> */}
        <p>
          {ReactHtmlParser(messageData.messageContent) ||
            'This message has been deleted'}
        </p>
      </div>
    </div>
  );
}
ReceivedMessage.propTypes = {
  type: PropTypes.string,
  messageData: PropTypes.instanceOf(Array),
  keyind: PropTypes.string,
  reply: PropTypes.func,
  systemMessage: PropTypes.bool,
  // buttonVisibility: PropTypes.bool,
  // accept: PropTypes.func,
  // reject: PropTypes.func,
};

ReceivedMessage.defaultProps = {
  type: '',
  messageData: [],
  keyind: '',
  reply: () => {},
  // buttonVisibility: false,
  // accept: () => {},
  // reject: () => {},
  systemMessage: false,
};
