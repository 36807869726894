import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './accordion-panel.scss';
import {
  AccordionToggleDownIcon,
  AccordionToggleRightIcon,
} from '../icons/icons';

function AccordionPanel({ title, isOpen, hasBG, ...props }) {
  const [togglePanel, setTogglePanel] = useState(isOpen);
  // const [panelHeight, setPanelHeight] = useState(0);
  const handleTogglePanel = () => {
    setTogglePanel(!togglePanel);
    // setPanelHeight(!panelHeight);
  };
  return (
    <div className={`accordion-panel${hasBG ? ' bg-white' : ''}`}>
      <button
        type="button"
        className="accordion-panel-head"
        onClick={handleTogglePanel}
      >
        {!togglePanel ? (
          <AccordionToggleRightIcon />
        ) : (
          <AccordionToggleDownIcon />
        )}
        <h4 className="accordion-panel-title">{title}</h4>
      </button>
      {togglePanel && (
        <div className="accordion-panel-body">{props.children}</div>
      )}
    </div>
  );
}

export default AccordionPanel;
AccordionPanel.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  isOpen: PropTypes.bool,
  hasBG: PropTypes.bool,
};
AccordionPanel.defaultProps = {
  children: {},
  title: '',
  isOpen: false,
  hasBG: false,
};
