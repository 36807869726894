import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Editor } from 'react-draft-wysiwyg';
// import ReactHtmlParser from 'react-html-parser';
import {
  EditorState,
  convertToRaw,
  convertFromHTML,
  ContentState,
} from 'draft-js';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import FormattingButton from './toggle-Formating-button';

export default function RichTextEditor({
  label,
  id,
  required,
  setContentChange,
  onChange,
  value,
  placeholder,
  formatting,
  chat,
  clearEditorOnSend,
  setFormatting,
  enableFormating,
  setOverviewError,
  // onKeyPress,
}) {
  if (chat) {
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    useEffect(() => {
      if (clearEditorOnSend > 0) {
        setEditorState(EditorState.createEmpty());
      }
    }, [clearEditorOnSend]);
    // const [editorState, setEditorState] = useState(() => {
    //   const blocksFromHTML = convertFromHTML(value);
    //   const contentState = ContentState.createFromBlockArray(
    //     blocksFromHTML.contentBlocks,
    //     blocksFromHTML.entityMap
    //   );

    //   return EditorState.createWithContent(contentState);
    // });

    // onEditorStateChange = (editorState) => {
    //   this.setState({
    //     editorState,
    //   });
    // };

    // const { editorState } = this.state;

    setContentChange(
      draftToHtml(convertToRaw(editorState.getCurrentContent()))
    );
    return (
      <div className="rich-text-editor-container">
        {label !== '' && (
          <label htmlFor={id} className="label">
            {label}
            {required && <span className="required">*</span>}
          </label>
        )}
        <div className="schedule-control-section">
          {/* {formatting && ( */}
          <div className="col-lg-12 control-section">
            <div
              style={{
                fontSize: '$px14',
                color: '#333333',
                // fontWeight: '600',
                height: 'unset',
                width: '100%',
                border: 'none',
                cursor: 'text',
                minHeight: '$px50',
                font: '-webkit-small-controlContentState',
              }}
              className="control-wrapper"
            >
              <Editor
                toolbarHidden={!formatting}
                toolbar={{
                  options: ['inline', 'list'],
                  inline: {
                    inDropdown: false,
                    options: ['bold', 'italic', 'underline'],
                  },
                  list: {
                    inDropdown: false,
                    options: ['unordered', 'ordered'],
                  },
                }}
                // initialContentState={value}
                placeholder={placeholder}
                editorState={editorState}
                onChange={onChange}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                // ariaExpanded={false}
                // ariaLabel="Formatting"
                onEditorStateChange={(e) => setEditorState(e)}
              />
              {/* <FormattingButton sendFormatting={setFormatting} /> */}
            </div>
          </div>
          {/* )} */}
          {enableFormating && (
            <FormattingButton sendFormatting={setFormatting} />
          )}
        </div>
      </div>
    );
  }
  if (value !== undefined) {
    const [editorState, setEditorState] = useState(
      EditorState.createWithContent(
        ContentState.createFromBlockArray(convertFromHTML(String(value)))
      )
    );

    setContentChange(
      draftToHtml(convertToRaw(editorState.getCurrentContent()))
    );

    setOverviewError(
      !convertToRaw(editorState.getCurrentContent())?.blocks?.some((para) =>
        para?.text?.trim()
      )
    );
    return (
      <div className="rich-text-editor-container">
        {label !== '' && (
          <label htmlFor={id} className="label">
            {label}
            {required && <span className="required">*</span>}
          </label>
        )}
        <div className="schedule-control-section">
          {/* {formatting && ( */}
          <div className="col-lg-12 control-section">
            <div
              style={{
                fontSize: '0.972vw',
                color: '#333333',
                // fontWeight: '600',
                height: 'unset',
                width: '100%',
                border: 'ridge',
                cursor: 'text',
                minHeight: '6.944vw',
              }}
              className="control-wrapper"
            >
              <Editor
                toolbar={{
                  options: [
                    'inline',
                    'fontSize',
                    'list',
                    'textAlign',
                    // 'italic',
                    // 'underline',
                  ],
                  inline: {
                    inDropdown: false,
                    options: ['bold', 'italic', 'underline'],
                  },
                  list: {
                    inDropdown: false,
                    options: ['unordered', 'ordered'],
                  },
                  textAlign: {
                    inDropdown: false,
                    options: ['left', 'center', 'right'],
                  },
                }}
                // initialContentState={value}
                placeholder={placeholder}
                editorState={editorState}
                toolbarHidden={!formatting}
                onChange={onChange}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                // ariaExpanded={false}
                // ariaLabel="Formatting"
                onEditorStateChange={(e) => setEditorState(e)}
              />
              {/* <FormattingButton sendFormatting={setFormatting} /> */}
            </div>
          </div>
          {/* )} */}
          {/* <FormattingButton sendFormatting={setFormatting} /> */}
        </div>
      </div>
    );
  }
}
RichTextEditor.propTypes = {
  setContentChange: PropTypes.func,
  // onKeyPress: PropTypes.func,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.string.isRequired,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  formatting: PropTypes.bool,
  chat: PropTypes.bool,
  clearEditorOnSend: PropTypes.number,
  setFormatting: PropTypes.func,
  enableFormating: PropTypes.bool,
  setOverviewError: PropTypes.func,
  // size: PropTypes.string,
};
RichTextEditor.defaultProps = {
  setContentChange: () => {},
  // onKeyPress: () => {},
  formatting: false,
  chat: false,
  onChange: () => {},
  // value: '',
  placeholder: '',
  label: '',
  id: '',
  required: false,
  clearEditorOnSend: 0,
  // size: '',
  setFormatting: () => {},
  enableFormating: true,
  setOverviewError: false,
};
