import React from 'react';
import PropTypes from 'prop-types';
import './score.scss';
import {
  MedelIcon,
  PerformanceGraphIconExcellent,
  PerformanceGraphIconFair,
  PerformanceGraphIconGood,
  PerformanceGraphIconPoor,
  PerformanceGraphIconVeryPoor,
  StarIcon,
} from '../../../../../Components/icons/icons';
import UserImage from '../../../../../Components/user-image/user-image';

function ScoreCard({
  image,
  name,
  rating,
  totalRatings,
  performance,
  orderNumber,
  onCompClick,
  companyId,
}) {
  return (
    <div
      className="score-item"
      onClick={() => {
        onCompClick(companyId);
      }}
      tabIndex={0}
      role="button"
      onKeyUp={() => onCompClick(companyId)}
    >
      <div className="content-column">
        <div className="column-body">
          {/* <button type="button" Style="display:none" onClick={onClick(67)}> */}
          <UserImage removeTooltip path={image} />
          <div className="details">
            <p className="name">{name}</p>
            <div className="review">
              <StarIcon />
              <p>
                {rating} ({totalRatings} Ratings)
              </p>
            </div>
          </div>
          {/* </button> */}
        </div>
        <p className="column-footer">User Ratings</p>
      </div>
      <div className="graphic-column">
        <div className="column-body">
          <div className="graphic-block">
            {performance.toLowerCase().replace(/\s/g, '-') === 'excellent' && (
              <PerformanceGraphIconExcellent />
            )}
            {performance.toLowerCase().replace(/\s/g, '-') === 'good' && (
              <PerformanceGraphIconGood />
            )}
            {performance.toLowerCase().replace(/\s/g, '-') === 'fair' && (
              <PerformanceGraphIconFair />
            )}
            {performance.toLowerCase().replace(/\s/g, '-') === 'poor' && (
              <PerformanceGraphIconPoor />
            )}
            {performance.toLowerCase().replace(/\s/g, '-') === 'verypoor' && (
              <PerformanceGraphIconVeryPoor />
            )}
            <div className="performance-content">
              <span
                className={`label ${performance
                  .toLowerCase()
                  .replace(/\s/g, '-')}`}
              >
                {performance}
              </span>
            </div>
          </div>
        </div>
        <p className="column-footer">Performance</p>
      </div>
      <div className="value-column">
        <div className="column-body">
          <p className="order-number">{orderNumber}</p>
        </div>
        <p className="column-footer">Orders</p>
      </div>
      {rating > 4 && (
        <span className="medel">
          <MedelIcon />
        </span>
      )}
    </div>
  );
}

export default ScoreCard;
ScoreCard.propTypes = {
  image: PropTypes.string,
  name: PropTypes.string,
  rating: PropTypes.string,
  totalRatings: PropTypes.string,
  performance: PropTypes.string,
  orderNumber: PropTypes.string,
  onCompClick: PropTypes.func,
  companyId: PropTypes.number,
};
ScoreCard.defaultProps = {
  image: '',
  name: '',
  rating: '',
  totalRatings: '',
  performance: '',
  orderNumber: '',
  onCompClick: () => {},
  companyId: 0,
};
