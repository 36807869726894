import React from 'react';
import Skeleton from '../../skeleton/skeleton';

export default function NewsLoader() {
  return (
    <div className="news-item">
      <div className="image">
        <Skeleton skWidth="3.264vw" skHeight="3.264vw" />
      </div>
      <div className="text">
        <Skeleton skWidth="100%" skHeight="1.042vw" />
        <Skeleton
          skWidth="90%"
          skHeight="1.042vw"
          marginTop="0.694vw"
          marginBottom="1.389vw"
        />
        <p className="description">
          <Skeleton skWidth="100%" skHeight="0.694vw" />
          <Skeleton skWidth="100%" skHeight="0.694vw" marginTop="0.694vw" />
          <Skeleton skWidth="60%" skHeight="0.694vw" marginTop="0.694vw" />
        </p>
      </div>
    </div>
  );
}
