import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import './advance-select-icon.scss';
import { useTranslation } from 'react-i18next';
import { ShortDownIcon } from '../icons/icons';

export default function AdvanceSelectLabelIcon({
  placeholder,
  onChange,
  data,
  handleInputChanged,
  defaultValue,
  label,
  errorMessage,
  message,
  required,
  maxLength,
  onRemoveValue,
  ...props
}) {
  const [query, setQuery] = useState('');
  const [selectedValue, setSelectedValue] = useState();
  const [t] = useTranslation('accountsettings');
  const [openDDL, setOpenDDl] = useState();
  const [getTop, setGetTop] = useState();
  const [getLeft, setGetLeft] = useState();
  const [getWidth, setGetWidth] = useState();
  const listHeight = useRef();
  const [searchValue, setSearchValue] = useState();
  const ref = useRef(null);
  const boxHeight = useRef(null);
  const getPosition = () => {
    setTimeout(() => {
      if (
        boxHeight.current.getBoundingClientRect().top +
          boxHeight.current.getBoundingClientRect().height +
          listHeight.current.getBoundingClientRect().height >
        window.innerHeight
      ) {
        setGetTop(
          boxHeight.current.getBoundingClientRect().top -
            listHeight.current.getBoundingClientRect().height
        );
      } else {
        setGetTop(
          boxHeight.current.getBoundingClientRect().top +
            boxHeight.current.getBoundingClientRect().height
        );
      }

      setOpenDDl(true);
      ref.current.focus();
      document.body.style.overflow = 'hidden';
    }, 200);
    setGetWidth(boxHeight.current.getBoundingClientRect().width);
    setGetLeft(boxHeight.current.getBoundingClientRect().left);
  };
  useEffect(() => {
    setSelectedValue(defaultValue?.label);
    setSearchValue(defaultValue?.label);
    setOpenDDl(false);
  }, [defaultValue?.label]);

  const getValue = (e) => {
    setSelectedValue(e.target.nextSibling.innerHTML);
    onChange(e);
    setOpenDDl(false);
    setSearchValue(e.target.nextSibling.innerHTML);
    document.body.style.overflow = 'auto';
  };

  const handleChange = (e) => {
    setQuery(e.target.value.toLowerCase());
    handleInputChanged(e);
    setSelectedValue(null);
    setSearchValue(e.target.value);
  };
  const closeDropdown = () => {
    setOpenDDl(false);
    document.body.style.overflow = 'auto';
  };
  const removeValue = (e) => {
    setSelectedValue('');
    if (searchValue?.length <= 1) {
      onRemoveValue(e);
    }
  };
  return (
    <div
      className={`advance-select-icon ${openDDL ? 'active' : ''}`}
      ref={boxHeight}
    >
      <div className="advance-select-icon-group">
        <span className="icon">{props.children}</span>
        <label
          className={`label ${
            (selectedValue === undefined || selectedValue === '') && !openDDL
              ? 'in-active'
              : ''
          }`}
          htmlFor={label}
        >
          {label}
          {required && <span className="required">*</span>}
        </label>
        <div className="textbox-area">
          {/* <div className="selected-value">{selectedValue}</div> */}

          <input
            type="text"
            placeholder={selectedValue === '' && openDDL ? placeholder : null}
            onChange={handleChange}
            ref={ref}
            value={searchValue}
            onClick={getPosition}
            onKeyDown={(e) => {
              if (e.key === 'Backspace') {
                removeValue();
              }
              getPosition();
            }}
            style={{ cursor: 'pointer' }}
            id={label}
            maxLength={maxLength}
          />
          <span className="arrow">
            <ShortDownIcon />
          </span>
        </div>

        {openDDL && (
          <div
            className="select-mask"
            onClick={closeDropdown}
            onKeyDown={closeDropdown}
            role="button"
            tabIndex="0"
          >
            &nbsp;
          </div>
        )}

        <ul
          data-cy="drop-down-from"
          className={`advance-select-icon-options ${openDDL ? 'show' : ''}`}
          style={{ width: getWidth, top: getTop, left: getLeft }}
          ref={listHeight}
        >
          {data?.filter((val) => val.label.toLowerCase().includes(query))
            .length === 0 && (
            <li>
              <label className="option">
                <span className="label no-data">
                  {t('accountsettings.myprofile.nodata')}
                </span>
              </label>
            </li>
          )}
          {data
            ?.filter((val) => val.label.toLowerCase().includes(query))
            .map((val) => (
              <li key={val.value}>
                <label
                  className={`option ${
                    selectedValue === val.value ? 'active' : ''
                  }`}
                  htmlFor={val.label}
                >
                  <input
                    type="radio"
                    onChange={getValue}
                    onClick={getValue}
                    name="ddOptions"
                    value={val.value}
                    defaultChecked={selectedValue === val.value}
                    id={val.label}
                  />
                  <span className="label">{val.label}</span>
                </label>
              </li>
            ))}
        </ul>
      </div>

      {message !== '' && <p className="message">{message}</p>}
      {errorMessage !== '' && <p className="error-message">{errorMessage}</p>}
    </div>
  );
}
AdvanceSelectLabelIcon.propTypes = {
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
  data: PropTypes.instanceOf(Array),
  handleInputChanged: PropTypes.func,
  label: PropTypes.string,
  errorMessage: PropTypes.string,
  message: PropTypes.string,
  children: PropTypes.node.isRequired,
  maxLength: PropTypes.number,
  onRemoveValue: PropTypes.func,
};
AdvanceSelectLabelIcon.defaultProps = {
  required: false,
  placeholder: '',
  defaultValue: '',
  onChange: () => {},
  data: [],
  handleInputChanged: () => {},
  label: '',
  errorMessage: '',
  message: '',
  maxLength: 50,
  onRemoveValue: () => {},
};
