/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-shadow */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-expressions */

import React, { useState } from 'react';
import { useTable, useSortBy, usePagination, useRowSelect } from 'react-table';
// import { ShortBothIcon, ShortDownIcon, ShortUpIcon } from '../icons/icons';
import Checkbox from '../custom-checkbox/custom-checkbox';
import { ShortBothIcon, ShortDownIcon, ShortUpIcon } from '../icons/icons';
import './table.scss';

const IndeterminateCheckbox = React.forwardRef(
  (
    {
      indeterminate,
      // isChecked,
      ...rest
    },
    ref
  ) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;
    // const [value, setValue] = useState('')

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <Checkbox
          ref={resolvedRef}
          {...rest}
          title=""
          // checked={isChecked}
        />
        <input
          type="checkbox"
          ref={resolvedRef}
          style={{ display: 'none' }}
          checked
        />
      </>
    );
  }
);

function CustomTable({
  columns,
  data,
  poIdSelected,
  poItemCodeArray,
  BookingIdSelected,
  getChildData,
  pageSize,
  isCheckBoxRequired,
  setSortColumnHeader,
  setSort,
  handleSortArrow,
  handleColumnHeader,
  lineItemIdSelected,
  className,
  isBulk = false,
  selectedOrderIds,
  excludeIds,
}) {
  const [BookingIdSelectedArray, setBookingIdSelectedArray] = useState([]);
  const [poIdSelectedArray, setPoIdSelectedArray] = useState([]);
  const [lineItemIdArray, setLineItemIdArray] = useState([]);
  const [itemCodeArray, setItemCodeArray] = useState([]);
  const perPage = pageSize && pageSize > 0 ? pageSize : 50;
  // headerGroup is an array for theader - hedaerGroup props

  let currentIndex = 0;
  const result = data?.reduce((acc, row) => {
    const index = currentIndex++;
    if (selectedOrderIds?.includes(row?.poId || row?._id || row?.shipmentId)) {
      acc[index] = true;
    } else {
      acc[index] = false;
    }
    if (excludeIds?.includes(row?.poId)) {
      acc[index] = false;
    }
    return acc;
  }, {});

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } =
    useTable(
      {
        columns,
        data,
        initialState: {
          pageSize: perPage,
          selectedRowIds: result,
          // selectedRowIds: { 0: true, 1: true, 3: false },
        },
        setSortColumnHeader,
        setSort,
        handleSortArrow,
        handleColumnHeader,
      },
      useSortBy,
      usePagination,
      useRowSelect,
      (hooks) => {
        hooks?.visibleColumns?.push((columns) => [
          {
            id: 'anyThing',
            disableSortBy: true,
            Header: ({ toggleRowSelected, isAllPageRowsSelected, page }) => {
              const modifiedOnChange = (event) => {
                page.forEach((row) => {
                  !row.original.disabled &&
                    toggleRowSelected(row.id, event.currentTarget.checked);
                });
              };

              let selectableRowsInCurrentPage = 0;
              let selectedRowsInCurrentPage = 0;
              page.forEach((row) => {
                row.isSelected && selectedRowsInCurrentPage++;
                !row.original.disabled && selectableRowsInCurrentPage++;
              });

              const disabled = selectableRowsInCurrentPage === 0;
              const checked =
                (isAllPageRowsSelected ||
                  selectableRowsInCurrentPage === selectedRowsInCurrentPage) &&
                !disabled;

              return (
                isCheckBoxRequired !== false && (
                  <IndeterminateCheckbox
                    onChange={(e) => {
                      modifiedOnChange(e);
                      getChildData(page, e.target.checked);
                    }}
                    checked={checked}
                    disabled={disabled}
                  />
                )
              );
            },
            Cell: ({ toggleRowSelected, row }) => {
              return (
                isCheckBoxRequired !== false && (
                  <IndeterminateCheckbox
                    {...row.getToggleRowSelectedProps()}
                    disabled={row.original.disabled}
                    // disabled={
                    //   defaultCheckStatus
                    //     ? row.original.currentStatus !== defaultCheckStatus
                    //     : row.original.disabled
                    // }
                    // isChecked={
                    //   // defaultCheckStatus
                    //   //   ? row.original.currentStatus === defaultCheckStatus
                    //   // :
                    //   row.isSelected ||
                    //   defaultSelectedOrders?.includes(row.original.poId)
                    // }
                    // isChecked={row.isSelected}
                    onChange={(event) => {
                      toggleRowSelected(row.id, event.currentTarget.checked);
                      getChildData(row.original, event.target.checked);
                      if (row.original.__typename === 'BookingListData') {
                        if (!row.isSelected === false) {
                          const removeBookingIdIndex =
                            BookingIdSelectedArray.indexOf(
                              row.original.BookingId
                            );
                          setBookingIdSelectedArray(
                            BookingIdSelectedArray.splice(
                              removeBookingIdIndex,
                              1
                            )
                          );
                          BookingIdSelected(BookingIdSelectedArray);
                        } else {
                          setBookingIdSelectedArray(
                            BookingIdSelectedArray?.push(row.original.BookingId)
                          );
                          BookingIdSelected(BookingIdSelectedArray);
                        }
                      }

                      if (
                        row.original.__typename === 'LineListItem' &&
                        !isBulk
                      ) {
                        if (!row.isSelected === false) {
                          const removeLineIdIndex = lineItemIdArray?.indexOf(
                            row.original._id
                          );
                          setLineItemIdArray(
                            lineItemIdArray?.splice(removeLineIdIndex, 1)
                          );
                          lineItemIdSelected(lineItemIdArray);
                          itemCodeArray?.splice(removeLineIdIndex, 1);
                          poItemCodeArray(itemCodeArray);
                        } else {
                          itemCodeArray?.push(row.original.itemCode);

                          setItemCodeArray(itemCodeArray);
                          poItemCodeArray(itemCodeArray);

                          setLineItemIdArray(
                            lineItemIdArray?.push(row.original._id)
                          );
                          lineItemIdSelected(lineItemIdArray);
                        }
                      }

                      if (
                        row.original.__typename === 'LineListItem' &&
                        isBulk
                      ) {
                        return;
                      }

                      if (row.original.__typename === 'POList') {
                        if (!row.isSelected === false) {
                          const removePoIdIndex = poIdSelectedArray.indexOf(
                            row.original.poId
                          );
                          setPoIdSelectedArray(
                            poIdSelectedArray.splice(removePoIdIndex, 1)
                          );
                          poIdSelected(poIdSelectedArray);
                          itemCodeArray?.splice(removePoIdIndex, 1);
                          // poItemCodeArray(itemCodeArray);
                        } else {
                          const tempItemCodeArray = [];
                          row.original.lineItems.map((e) =>
                            tempItemCodeArray?.push(e.itemCode)
                          );

                          setItemCodeArray(
                            itemCodeArray?.push(tempItemCodeArray)
                          );
                          // poItemCodeArray(itemCodeArray);
                          setPoIdSelectedArray(
                            poIdSelectedArray.push(row.original.poId)
                          );
                          poIdSelected(poIdSelectedArray);
                        }
                      }
                    }}
                  />
                )
              );
            },
          },
          ...columns,
        ]);
      }
    );

  const disableInTodo = rows?.some(
    (row) => row?.isSelected && row?.original?.currentStatus === 'New'
  );

  return (
    <div className={`custom-table-container ${className}`}>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  style={{ width: column.setWidth }}
                  className={`${
                    column.render('Header') === 'Notification'
                      ? 'notification'
                      : column.render('Header') === 'Booking No.'
                      ? 'id'
                      : column.render('Header') === 'Booking Date'
                      ? 'date-time'
                      : column.render('Header') === 'Quantity'
                      ? 'center'
                      : column.render('Header') === 'Mode'
                      ? 'center'
                      : column.align !== undefined
                      ? column.align
                      : ''
                  }`}
                >
                  <span>
                    {column.render('Header')}
                    {column.enableSortBy ? (
                      <span>
                        {handleSortArrow === 0 && (
                          <button
                            type="button"
                            tabIndex="0"
                            onClick={() => {
                              setSortColumnHeader(column.Header);
                              setSort(1);
                            }}
                          >
                            <ShortBothIcon />
                          </button>
                        )}
                        {handleSortArrow === 1 && (
                          <button
                            type="button"
                            tabIndex="0"
                            onClick={() => {
                              setSortColumnHeader(column.Header);
                              setSort(-1);
                            }}
                          >
                            {handleColumnHeader === column.Header ? (
                              <ShortDownIcon />
                            ) : (
                              <ShortBothIcon />
                            )}
                          </button>
                        )}
                        {handleSortArrow === -1 && (
                          <button
                            type="button"
                            tabIndex="0"
                            onClick={() => {
                              setSortColumnHeader(column.Header);
                              setSort(1);
                            }}
                          >
                            {handleColumnHeader === column.Header ? (
                              <ShortUpIcon />
                            ) : (
                              <ShortBothIcon />
                            )}
                          </button>
                        )}
                      </span>
                    ) : null}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                id={row.id}
                value={row.getRowProps()}
                className={
                  row.isSelected &&
                  (disableInTodo
                    ? ![
                        'Rejected',
                        'Change Requested',
                        'Partially Accepted',
                      ].includes(row?.original?.currentStatus)
                    : true)
                    ? 'active'
                    : ''
                }
              >
                {row.cells.map((cell) => {
                  const { column } = cell;
                  return (
                    <td
                      {...cell.getCellProps()}
                      className={`
                        ${
                          cell.render('Header') === 'Notification' &&
                          'notification'
                        }
                          ${cell.render('Header') === 'Shipper' && 'no-wrap'}
                          ${cell.render('Header') === 'Consignee' && 'no-wrap'}
                          ${cell.column.align}
                      `}
                      style={{
                        opacity:
                          column.id !== 'anyThing' &&
                          column.id !== 'username' &&
                          disableInTodo &&
                          [
                            'Rejected',
                            'Change Requested',
                            'Partially Accepted',
                          ].includes(row?.original?.currentStatus)
                            ? 0.5
                            : 1,
                      }}
                    >
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default CustomTable;
