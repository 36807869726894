import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import useDialog from '../../Components/dialog/useDialog';
import AccountSettingsPanel from '../../Components/account-settings-panel/account-settings-panel';
import AccountSettingsPanelTitle from '../../Components/account-settings-panel/account-settings-panel-title';
import './settings.scss';
import BesicTable from '../../Components/table/basicTable';
import ActionButton from '../../Components/actions/actions-btn';
import UserGroup from '../../Components/user-group/user-group';
import Dialog from '../../Components/dialog/dialog';
import DialogPanel from '../../Components/dialog-panel/dialog-panel';
import DialogPanelBody from '../../Components/dialog-panel/dialog-panel-body/dialog-panel-body';
import Button from '../../Components/button/button';
import DialogPanelFooter from '../../Components/dialog-panel/dialog-panel-footer/dialog-panel-footer';
import AccountSettingsPanelHead from '../../Components/account-settings-panel/account-settings-panel-head';
import AccountSettingsPanelHeadAction from '../../Components/account-settings-panel/account-settings-panel-head-action';
import NewButton from '../../Components/button/newButton';
import {
  AddUserNewIcon,
  EditNewIcon,
  KeyOutlineIcon,
  NewShortIcon,
  NewTrashIcon,
} from '../../Components/icons/icons';
import IconButton from '../../Components/icon-button/icon-button';
import CreateNewRole from './create-new-role/create-new-role';
import ContactPermissions from './contact-permissions/contact-permissions';
import AssignContact from './assign-contact/assign-contact';
import EditContactPermissions from './edit-contact-permissions/edit-contact-permissions';
import { FETCH_ROLES } from '../../graphQL/rolesAndPermissions';
import { giveProfile } from '../../Components/global-notification/notification-list';
import RenameRole from './rename-role';
import Contactlist from './contact-list/contact-list';
import Pagination from '../../Components/pagination/pagination';
import RolesSearchBox from './roles-search-box';
import NoData from '../../Components/no-data/no-data';
import GlobalLoader from '../../Components/global-loader/global-loader';
import UserDetails from '../../Components/user-details/user-details';
import DeleteRole from './forms/deleteRole';
import { checkPermission } from '../../helpers/permissionHelper';

function RolesAndPermissions({ t }) {
  const { relation } = useParams();
  const { isShowing: createRole, toggle: createRoleToggle } = useDialog();
  const [rolesData, setRolesData] = useState([]);
  // const [isCNR, setIsCNR] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [paginationData, setPaginationData] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalRoles, setTotalRoles] = useState(0);
  const perPage = 25;

  const { loading, refetch } = useQuery(FETCH_ROLES, {
    variables: {
      input: {
        profileType: giveProfile(relation),
        page: pageNumber,
        perPage,
        ...(searchValue && { searchTerm: searchValue }),
      },
    },
    onCompleted: (data) => {
      setRolesData(data?.FetchRoles?.data?.roleList);
      // setIsCNR(data?.FetchRoles?.data?.isCNR);
      const output = [];
      for (let i = 1; i <= data?.FetchRoles?.data?.totalPages; i++) {
        output.push(i);
      }
      setPaginationData(output);
      setTotalRoles(data?.FetchRoles?.data?.totalRoles);
    },
  });

  useEffect(() => {
    setPageNumber(1);
  }, [searchValue]);

  const columns = [
    {
      Header: t('systemsettings.roles&permission.roletablerow.roles'),
      accessor: 'roleName',
      setWidth: '25%',
    },
    {
      Header: t('systemsettings.roles&permission.roletablerow.activecontacts'),
      accessor: 'users',
      setWidth: '25%',
      Cell: (users) => {
        const {
          cell: { row },
        } = users;
        const { isShowing: isContactListDislog, toggle: contactList } =
          useDialog();
        const {
          isShowing: isContactPermissionDislog,
          toggle: contactPermission,
        } = useDialog();
        return (
          <>
            {row?.original?.users?.userDetails?.length === 0 ? (
              <UserDetails
                size="medium"
                profilePic={
                  row?.original?.users?.userDetails[0]?.profilePicture
                }
                userName={`${row?.original?.users?.userDetails[0]?.firstName} ${row?.original?.users?.userDetails[0]?.lastName}`}
                userEmail={row?.original?.users?.userDetails[0]?.email}
              />
            ) : (
              <span
                onClick={contactList}
                role="button"
                tabIndex={0}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    contactList();
                  }
                }}
              >
                {row?.original?.users?.userDetails?.length > 1 ? (
                  <UserGroup
                    userData={row?.original?.users?.userDetails}
                    onExtarnalClick={contactList}
                    extarnalClick
                    removeTooltip={true}
                  />
                ) : (
                  <UserDetails
                    size="medium"
                    profilePic={
                      row?.original?.users?.userDetails[0]?.profilePicture
                    }
                    userName={`${row?.original?.users?.userDetails[0]?.firstName} ${row?.original?.users?.userDetails[0]?.lastName}`}
                    userEmail={row?.original?.users?.userDetails[0]?.email}
                  />
                )}
              </span>
            )}
            {false && (
              <Dialog
                isShowing={isContactPermissionDislog}
                placement="center"
                hide={contactPermission}
              >
                <DialogPanel title="Contact Permissions">
                  <DialogPanelBody>
                    <EditContactPermissions />
                  </DialogPanelBody>
                  <DialogPanelFooter>
                    <Button label="Cancel" styles="cancel" />
                    <Button label="Update" color="secondary" />
                  </DialogPanelFooter>
                </DialogPanel>
              </Dialog>
            )}
            <Dialog
              isShowing={isContactListDislog}
              placement="center"
              hide={() => {
                contactList();
                refetch();
              }}
            >
              <DialogPanel
                title={t(
                  'systemsettings.roles&permission.activecontactspopup.title'
                )}
              >
                <DialogPanelBody>
                  <Contactlist
                    roleId={row?.original?.roleId}
                    refetchRolesList={refetch}
                    roleName={row?.original?.roleName}
                    t2={t}
                  />
                </DialogPanelBody>
              </DialogPanel>
            </Dialog>
          </>
        );
      },
    },
    {
      Header: t('systemsettings.roles&permission.roletablerow.permissions'),
      accessor: 'permissions',
      setWidth: '25%',
      Cell: (data) => {
        const {
          cell: { row },
        } = data;
        const { isShowing: isActivateDislog, toggle: activateContact } =
          useDialog();
        return (
          <>
            <NewButton
              styles="primary outline xx-small"
              onClick={activateContact}
            >
              <KeyOutlineIcon />
              {t('systemsettings.roles&permission.permissionspopup.btntitle')}
            </NewButton>
            <Dialog
              isShowing={isActivateDislog}
              placement="center"
              hide={activateContact}
            >
              <ContactPermissions
                roleName={row?.original?.roleName}
                roleId={row?.original?.roleId}
                togglehandler={activateContact}
                refetchRolesList={refetch}
                t={t}
              />
            </Dialog>
          </>
        );
      },
    },
    ...(checkPermission(
      giveProfile(relation),
      'Configuration',
      'Manage Roles'
    ) ||
    checkPermission(giveProfile(relation), 'Configuration', 'Deactivate Role')
      ? [
          {
            Header: t('systemsettings.roles&permission.roletablerow.actions'),
            align: 'left',
            accessor: 'actions',
            setWidth: '25%',
            Cell: (users) => {
              const {
                cell: { row },
              } = users;
              const { isShowing: Rename, toggle: renameToggle } = useDialog();
              const renameToggleFunc = () => {
                renameToggle();
              };
              const { isShowing: isActivateDislog1, toggle: deleteToggle } =
                useDialog();
              const { isShowing: assignUser, toggle: assignUserToggle } =
                useDialog();
              return (
                <div className="d-flex">
                  {checkPermission(
                    giveProfile(relation),
                    'Configuration',
                    'Manage Roles'
                  ) && (
                    <ActionButton
                      className="btn-temp1"
                      onClick={assignUserToggle}
                      disabled={!row?.original?.actions?.assignUsers}
                    >
                      <AddUserNewIcon />
                      {t(
                        'systemsettings.roles&permission.actionspopups.assignuserspopup.btntitle'
                      )}
                    </ActionButton>
                  )}
                  <Dialog
                    isShowing={assignUser}
                    placement="center"
                    hide={assignUserToggle}
                  >
                    <AssignContact
                      t={t}
                      roleName={row?.original?.roleName}
                      assignUserToggle={assignUserToggle}
                      roleId={row?.original?.roleId}
                      refetchRolesList={refetch}
                    />
                  </Dialog>
                  {checkPermission(
                    giveProfile(relation),
                    'Configuration',
                    'Manage Roles'
                  ) && (
                    <ActionButton
                      className="btn-temp2"
                      onClick={renameToggleFunc}
                      disabled={!row?.original?.actions?.rename}
                    >
                      <EditNewIcon />
                      {t(
                        'systemsettings.roles&permission.actionspopups.renamepopup.btntitle'
                      )}
                    </ActionButton>
                  )}
                  <Dialog
                    isShowing={Rename}
                    placement="center"
                    hide={renameToggleFunc}
                  >
                    <RenameRole
                      renameToggleFunc={renameToggleFunc}
                      roleName={row?.original?.roleName}
                      roleId={row?.original?.roleId}
                      refetchRolesList={refetch}
                      existingRoleNames={rolesData?.map((role) =>
                        role?.roleName?.toUpperCase()
                      )}
                      t={t}
                    />
                  </Dialog>
                  {checkPermission(
                    giveProfile(relation),
                    'Configuration',
                    'Deactivate Roles'
                  ) && (
                    <ActionButton
                      className="btn-temp3"
                      onClick={deleteToggle}
                      disabled={!row?.original?.actions?.delete}
                    >
                      <NewTrashIcon />{' '}
                      {t(
                        'systemsettings.roles&permission.actionspopups.deletepopup.btntitle'
                      )}
                    </ActionButton>
                  )}
                  <Dialog
                    isShowing={isActivateDislog1}
                    placement="center"
                    hide={deleteToggle}
                  >
                    <DeleteRole
                      roleId={row?.original?.roleId}
                      deleteToggle={deleteToggle}
                      refetchRolesList={refetch}
                      t={t}
                    />
                  </Dialog>
                </div>
              );
            },
          },
        ]
      : []),
  ];

  return (
    <>
      <AccountSettingsPanel>
        <AccountSettingsPanelHead>
          <AccountSettingsPanelTitle>
            {t('systemsettings.tabtitle.roles&permission')}
          </AccountSettingsPanelTitle>
          <AccountSettingsPanelHeadAction>
            <RolesSearchBox
              margin="0 0.694vw 0 0"
              paddingRight="2.083vw"
              lineHeight="2.222vw"
              width="100%"
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              t={t}
            />
            {false && (
              <IconButton margin="0 0.694vw 0 0">
                <NewShortIcon />
              </IconButton>
            )}
            {checkPermission(
              giveProfile(relation),
              'Configuration',
              'Manage Roles'
            ) && (
              <NewButton
                onClick={createRoleToggle}
                styles="primary outline fw-600 fs-16 no-wrap"
                // disabled={!isCNR}
              >
                {t('systemsettings.roles&permission.createnewrolepopup.title')}
              </NewButton>
            )}
          </AccountSettingsPanelHeadAction>
        </AccountSettingsPanelHead>
        {loading && (
          <GlobalLoader newLoad={true} height="6.944vw" width="6.944vw" />
        )}
        {!loading && rolesData?.length ? (
          <BesicTable
            columns={columns}
            data={rolesData}
            className="roles-premission"
          />
        ) : (
          ''
        )}
        {!loading && !rolesData?.length && (
          <NoData message={t('systemsettings.roles&permission.noroles')} />
        )}
      </AccountSettingsPanel>

      <Dialog isShowing={createRole} placement="center" hide={createRoleToggle}>
        <CreateNewRole
          togglehandler={createRoleToggle}
          refetchRolesList={refetch}
          t={t}
        />
      </Dialog>
      {!loading && rolesData?.length ? (
        <Pagination
          totalData={totalRoles}
          perPage={perPage}
          paginationData={paginationData}
          currentPage={pageNumber}
          disablePagination
          swicthPage={(page) => {
            setPageNumber(page);
          }}
          className="roles-pagination"
        />
      ) : (
        ''
      )}
    </>
  );
}

RolesAndPermissions.propTypes = {
  t: PropTypes.func,
};
RolesAndPermissions.defaultProps = {
  t: () => {},
};

export default RolesAndPermissions;
