import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import './button.scss';
import { bool } from 'yup';
import { ExportIcon, SpinnerIcon } from '../icons/icons';

export default function Button({
  label,
  color,
  size,
  type,
  radius,
  styles,
  icon,
  iconRight,
  spacing,
  loading,
  disabled,
  link,
  linkType,
  _blank,
  className,
  ...otherProps
}) {
  const [t] = useTranslation('manageorders');
  return (
    <>
      {type === 'submit' && (
        <button
          data-cy="submit-btn"
          type="submit"
          className={`button ${size} ${color} ${styles} ${className}`}
          style={{ borderRadius: radius }}
          disabled={loading || disabled}
          {...otherProps}
        >
          {icon && <span style={{ marginRight: spacing }}>{icon}</span>}
          {loading
            ? t(
                'manageordersscreen.orderstatus.orderstatusmenu.updatestatus.wait'
              )
            : label}
          {loading ? <SpinnerIcon /> : ''}
          {iconRight && (
            <span style={{ marginLeft: spacing }}>{iconRight}</span>
          )}
        </button>
      )}
      {type === 'link' && type !== 'submit' && linkType === 'external' && (
        <a
          href={link}
          className="link-button"
          target="_blank"
          rel="noreferrer"
          data-cy="link-btn"
        >
          {label} <ExportIcon />
        </a>
      )}
      {(!type || type === 'button') && (
        <button
          data-cy="button"
          type="button"
          className={`button ${size} ${color} ${styles} ${className}`}
          style={{ borderRadius: radius }}
          disabled={disabled}
          {...otherProps}
        >
          {icon && <span style={{ marginRight: spacing }}>{icon}</span>}
          {label}
          {iconRight && (
            <span style={{ marginLeft: spacing }}>{iconRight}</span>
          )}
        </button>
      )}
    </>
  );
}
Button.propTypes = {
  label: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.string,
  type: PropTypes.string,
  radius: PropTypes.string,
  styles: PropTypes.string,
  icon: PropTypes.element.isRequired,
  iconRight: PropTypes.element.isRequired,
  className: PropTypes.string,
  spacing: PropTypes.string,
  loading: bool,
  disabled: bool,
  linkType: PropTypes.string,
  link: PropTypes.string,
  _blank: PropTypes.bool,
};
Button.defaultProps = {
  label: '',
  color: '',
  size: '',
  type: '',
  radius: '',
  styles: '',
  spacing: '',
  loading: false,
  disabled: false,
  linkType: '',
  link: '',
  className: '',
  _blank: false,
};
