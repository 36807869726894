import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import menuSchema from '../../schema/menuSchema';
import { getUser } from '../../service/authService';
import { getNotificationCount } from '../../helpers/notificationHelper';
import { MenuOrg } from '../icons/icons';

function MenuItem({
  addLeftMenuDetails,
  organizationType,
  notificationContainer,
  menuConfig,
  isOnmi,
}) {
  const user = getUser();
  const userType = user['custom:userType'];
  const [currentURL, setCurrentUrl] = useState();
  let OrgData;
  if (addLeftMenuDetails.length !== 0) {
    OrgData = { ...addLeftMenuDetails };
  }
  const getCurrentPage = () => {
    setCurrentUrl(window.location.pathname);
  };

  let menuItems;
  if (OrgData?.orgType === 'buyer') {
    menuItems = menuSchema()?.supplierMenuSchema;
  } else if (OrgData?.orgType === 'supplier') {
    menuItems = menuSchema()?.buyerMenuSchema;
  } else if (OrgData?.orgType === 'forwarder') {
    menuItems = menuSchema()?.forwarderMenuSchema;
  } else if (OrgData?.orgType === 'customBroker') {
    menuItems = menuSchema()?.customBrokerMenuSchema;
  }
  useEffect(() => {
    setCurrentUrl(window.location.pathname);
  }, [window.location.pathname]);

  return (
    <ul className={`left-menu-list ${isOnmi ? 'omni' : ''}`}>
      {menuSchema()
        ?.leftmenuSchema?.filter((menu) => {
          return userType === '_user' ||
            organizationType.includes('forwarder') ||
            organizationType.includes('customBroker')
            ? menu.name.toString() !== 'InvitePartners'
            : menu;
        })
        .map(
          (menu) =>
            menuConfig?.includes(menu?.name.toLowerCase()) && (
              <li key={menu?.name}>
                <Link
                  onClick={getCurrentPage}
                  id={menu.name}
                  to={menu.path}
                  style={{ textDecoration: 'none' }}
                  className={menu?.path === currentURL ? 'active' : ''}
                >
                  {menu?.icon}
                  {menu?.placeholder}
                </Link>
              </li>
            )
        )}
      {OrgData && OrgData.orgName && (
        <>
          <li className="org-name">
            {/* <p>
              <MenuOrg />
              <span className="label">{OrgData.orgName.toUpperCase()}</span>
            </p> */}
            <Link
              to={`/app/${OrgData.orgType}/${OrgData._id}/organization-details`}
              className={`${
                currentURL?.includes('organization-details') ? 'active' : ''
              } ${currentURL?.includes('supplier') ? '' : 'disabled'}`}
            >
              <MenuOrg />
              <span className="label">{OrgData.orgName.toUpperCase()}</span>
            </Link>
          </li>
          {menuItems &&
            menuItems?.map((col) => {
              return (
                <li>
                  <Link
                    key={col.placeholder}
                    to={col.path}
                    onClick={getCurrentPage}
                    id={col.placeholder}
                    className={
                      currentURL?.includes(col.name.toLowerCase())
                        ? 'active'
                        : ''
                    }
                  >
                    {col?.icon}
                    {col.placeholder}
                    {getNotificationCount(
                      notificationContainer[OrgData?.buyerSupplierId],
                      col.placeholder
                    ) ? (
                      <span className="counter">
                        {getNotificationCount(
                          notificationContainer[OrgData?.buyerSupplierId],
                          col.placeholder
                        ) > '9'
                          ? '9+'
                          : getNotificationCount(
                              notificationContainer[OrgData?.buyerSupplierId],
                              col.placeholder
                            )}
                      </span>
                    ) : null}
                  </Link>
                </li>
              );
            })}
        </>
      )}
    </ul>
  );
}

function mapStateToProps(state) {
  return {
    addLeftMenuDetails: state.omniDetails.addLeftMenuDetails,
    organizationType:
      state.omniDetails.fetchOrganizationDetail?.fetchOrganizationDetails?.data
        ?.orgType,
    notificationContainer: state.onmiNotifications,
  };
}

MenuItem.propTypes = {
  addLeftMenuDetails: PropTypes.instanceOf(Object),
  organizationType: PropTypes.instanceOf(Array),
  notificationContainer: PropTypes.instanceOf(Object),
  menuConfig: PropTypes.instanceOf(Array),
  isOnmi: PropTypes.bool,
};

MenuItem.defaultProps = {
  addLeftMenuDetails: {},
  organizationType: [],
  notificationContainer: {},
  menuConfig: [],
  isOnmi: false,
};

export default connect(mapStateToProps, null)(MenuItem);
