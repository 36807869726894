import { React, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import NewButton from '../../../Components/button/newButton';
import AccountSettingsPanel from '../../../Components/account-settings-panel/account-settings-panel';
import AccountSettingsPanelDescription from '../../../Components/account-settings-panel/account-settings-panel-description';
import AccountSettingsPanelTitle from '../../../Components/account-settings-panel/account-settings-panel-title';
import Dialog from '../../../Components/dialog/dialog';
import DialogPanel from '../../../Components/dialog-panel/dialog-panel';
import DialogPanelBody from '../../../Components/dialog-panel/dialog-panel-body/dialog-panel-body';
import TextBox from '../../../Components/text-box/text-box';
import DialogPanelFooter from '../../../Components/dialog-panel/dialog-panel-footer/dialog-panel-footer';
import Button from '../../../Components/button/button';
import TokenTable from '../../../Components/token/token-table';
import useDialog from '../../../Components/dialog/useDialog';
import { MUTATE_ADD_TOKEN, QUERY_APILISTKEY } from '../../../graphQL/apiDoc';
import urldoc from '../../../service/APIDocsURL';
import { checkPermission } from '../../../helpers/permissionHelper';
import { giveProfile } from '../../../Components/global-notification/notification-list';
import { getUser } from '../../../service/authService';

function ApiDocumentation() {
  const [apiKeysList, setApiKeysList] = useState([]);
  const user = getUser();
  const [handleCreateApiToken] = useMutation(MUTATE_ADD_TOKEN);
  const { refetch: tokenRefetch } = useQuery(QUERY_APILISTKEY, {
    onCompleted: (data) => {
      setApiKeysList(data?.ApiKeyList?.data);
    },
  });
  const { isShowing: isGenerateTokenPop, toggle: GenerateTokenPop } =
    useDialog();
  const [tkDesc, setTkDesc] = useState('');
  const { relation } = useParams();
  const CreateTokenHandler = () => {
    handleCreateApiToken({
      variables: {
        input: {
          description: tkDesc,
        },
      },
      onCompleted: (tkData) => {
        toast.success(tkData?.GenerateApiKey?.message);
        tokenRefetch();
        GenerateTokenPop();
        setTkDesc('');
      },
    });
  };

  const [t] = useTranslation('accountsettings');

  return (
    <>
      <div className="flex space-btw">
        <h2>{t('accountsettings.tabtitle.apidocument')}</h2>
        <div>
          {!(user['custom:userType'] === '_user') &&
            checkPermission(
              giveProfile(relation),
              'Configuration',
              'API Documentation',
              'E'
            ) && (
              <NewButton
                color="color"
                onClick={GenerateTokenPop}
                styles="button"
                className="submit mr-10"
              >
                {t('accountsettings.apidocument.generatetoken')}
              </NewButton>
            )}
          <NewButton
            type="link"
            link={urldoc}
            linkType="external"
            className="fs-12 fw-400"
          >
            {t('accountsettings.apidocument.apidocs')}
          </NewButton>
        </div>
      </div>
      <AccountSettingsPanel>
        <AccountSettingsPanelTitle>
          {t('accountsettings.apidocument.usertokengeneration')}
        </AccountSettingsPanelTitle>
        <div className="my-profile-row mb-20">
          <AccountSettingsPanelDescription>
            {t('accountsettings.apidocument.usertokengenerationdescription')}
          </AccountSettingsPanelDescription>
        </div>
        <TokenTable data={apiKeysList} tokenRefetch={tokenRefetch} t={t} />
        <Dialog
          isShowing={isGenerateTokenPop}
          hide={GenerateTokenPop}
          placement="middle"
        >
          <DialogPanel
            title={t('accountsettings.generatetokenbtn.generateanusertoken')}
          >
            <DialogPanelBody>
              <div className="pop-up-width">
                <TextBox
                  type="input"
                  placeholder={t(
                    'accountsettings.generatetokenbtn.inputplaceholder'
                  )}
                  defaultValue={tkDesc}
                  label={t('accountsettings.generatetokenbtn.tokendescription')}
                  message={t('accountsettings.generatetokenbtn.helpertext')}
                  onChange={(e) => setTkDesc(e.target.value)}
                />
              </div>
            </DialogPanelBody>
            <DialogPanelFooter>
              <Button
                label={t('accountsettings.generatetokenbtn.cancel')}
                styles="cancel"
                onClick={GenerateTokenPop}
              />
              <Button
                type="button"
                label={t('accountsettings.generatetokenbtn.generate')}
                color="secondary"
                onClick={CreateTokenHandler}
              />
            </DialogPanelFooter>
          </DialogPanel>
        </Dialog>
      </AccountSettingsPanel>
    </>
  );
}

export default ApiDocumentation;
