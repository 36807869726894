import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import './count-card.scss';
import AdvancePopover from '../advance-popover/advance-popover';
import BesicTable from '../table/basicTable';
import '../table/table.scss';
import { ArrowCircleDownIcon } from '../icons/icons';

/**
 * @typedef {'left' | 'center' | 'right'} AlignType - Size choices for the Demo component.
 */

/**
 * Component to demonstrate different sizes, appearances, and popup options.
 * @param {Object} props
 * @param {AlignType} props.align
 * @returns {JSX.Element}
 */

export default function CountCard({
  data,
  label,
  onClick,
  enableDynamicTable,
  columns,
  disableBorder,
  align,
  disableClick,
  enableIcon,
  // showIcon,
}) {
  const alignProperties = {
    left: 'left',
    center: 'center',
    right: 'right',
  };
  const alignProps = alignProperties[align];
  const [t] = useTranslation('manageorders');
  const [eventValue, setEventValue] = useState();
  const [showIcon, setShowIcon] = useState(false);

  const togglePopover = (e) => {
    if (!disableClick) {
      setEventValue(e.target);
    }
    onClick();
  };
  const handleClose = () => {
    setEventValue(null);
  };
  return (
    <div className={`count-card-container${` ${alignProps}`}`}>
      <button
        className={`count-card${disableBorder ? ' disable-border' : ''}`}
        type="button"
        onClick={togglePopover}
        onMouseEnter={() => {
          setShowIcon(true);
        }}
        onMouseLeave={() => {
          setShowIcon(false);
        }}
        id="countPopover"
      >
        <span>{label}</span>
        {disableBorder && enableIcon && showIcon && (
          <ArrowCircleDownIcon className="arrow-icon" />
        )}
      </button>
      <AdvancePopover
        reference="countPopover"
        getEvent={eventValue}
        closePopover={handleClose}
        placement="center"
      >
        <div className="count-card-table custom-table-container">
          {!enableDynamicTable ? (
            <table>
              <thead>
                <tr>
                  <th align="left">
                    {t('manageordersscreen.totalitemspopup.productname')}
                  </th>
                  <th align="centert">
                    {t('manageordersscreen.totalitemspopup.deliverydate')}
                  </th>
                  <th align="centert">
                    {t('manageordersscreen.totalitemspopup.quantity')}
                  </th>
                  <th align="centert">
                    {t('manageordersscreen.totalitemspopup.weight')}
                  </th>
                  <th align="centert">
                    {t('manageordersscreen.totalitemspopup.priceperunit')}
                  </th>
                  <th align="centert">
                    {t('manageordersscreen.totalitemspopup.totalprice')}
                  </th>
                </tr>
              </thead>
              <tbody>
                {data?.map((val) => {
                  return (
                    <tr>
                      <td align="left">{val?.itemName}</td>
                      <td align="center">{val?.dueDate}</td>
                      <td align="center">{val?.quantity}</td>
                      <td align="center">
                        {val?.grossWeight} {val?.weightUnit}
                      </td>
                      <td align="center">{val?.price}</td>
                      <td align="center">{val?.total}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <BesicTable data={data} columns={columns} />
          )}
        </div>
      </AdvancePopover>
    </div>
  );
}
CountCard.propTypes = {
  data: PropTypes.arrayOf(Object),
  columns: PropTypes.arrayOf(Object),
  label: PropTypes.string,
  onClick: PropTypes.func,
  enableDynamicTable: PropTypes.bool,
  disableBorder: PropTypes.bool,
  align: PropTypes.string,
  disableClick: PropTypes.bool,
  enableIcon: PropTypes.bool,
};
CountCard.defaultProps = {
  data: [],
  columns: [],
  label: '',
  onClick: () => {},
  enableDynamicTable: false,
  disableBorder: false,
  align: 'left',
  disableClick: false,
  enableIcon: false,
};
