import React, { useState } from 'react';
import './calendar.scss';
import PropTypes from 'prop-types';

function CalendarBox({ size = 'small' }) {
  const [currentDate, setCurrentDate] = useState(new Date());
  const currentDay = currentDate.getDate();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();

  const changeYear = (amount) => {
    const newDate = new Date(currentDate);
    newDate.setFullYear(currentYear + amount);
    setCurrentDate(newDate);
  };

  const changeMonth = (amount) => {
    const newDate = new Date(currentDate);
    newDate.setMonth(currentMonth + amount);
    setCurrentDate(newDate);
  };

  const daysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate();
  const firstDayOfMonth = new Date(currentYear, currentMonth, 1).getDay();
  const days = [];
  const weeks = [];

  const dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const dayHeaders = dayNames.map((day) => <th key={day}>{day}</th>);

  for (let i = 0; i < firstDayOfMonth; i++) {
    days.push(<td key={`blank-${i}`} />);
  }

  for (let i = 1; i <= daysInMonth; i++) {
    const date = new Date(currentYear, currentMonth, i);
    const isCurrentDate = currentDay === i;
    const isWeekend = date.getDay() === 0 || date.getDay() === 6;

    days.push(
      <td
        key={`day-${i}`}
        className={`date ${isCurrentDate ? 'current-date' : ''} ${
          isWeekend ? 'weekend' : ''
        }`}
      >
        <button
          type="button"
          className={`date-btn ${isCurrentDate ? 'current-date-btn' : ''}`}
        >
          {i}
        </button>
      </td>
    );
  }

  for (let i = 0; i < days.length; i += 7) {
    weeks.push(<tr key={`week-${i}`}>{days.slice(i, i + 7)}</tr>);
  }

  return (
    <div className={`calendar ${size}`}>
      <div className="calendar-header">
        <button type="button" onClick={() => changeYear(-1)}>
          &lt;
        </button>
        <button type="button" onClick={() => changeMonth(-1)}>
          &lt;&lt;
        </button>
        <h2 className="year">
          {currentYear}
          <span className="month">
            {' '}
            {currentDate.toLocaleString('default', { month: 'long' })}
          </span>
        </h2>
        <button type="button" onClick={() => changeMonth(1)}>
          &gt;&gt;
        </button>
        <button type="button" onClick={() => changeYear(1)}>
          &gt;
        </button>
      </div>

      <table cellSpacing="21" cellPadding="21" className="date-container">
        <caption />

        <thead className="days-container">
          <tr>{dayHeaders}</tr>
        </thead>

        <tbody>{weeks}</tbody>
      </table>
    </div>
  );
}

CalendarBox.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']),
};

CalendarBox.defaultProps = {
  size: 'small',
};

export default CalendarBox;
