import React from 'react';
import PropTypes from 'prop-types';
import './skeleton.scss';

export default function Skeleton({
  skHeight,
  skWidth,
  skRadius,
  skMargin,
  ...oterProps
}) {
  return (
    <span
      className="skeleton-container"
      style={{
        height: skHeight,
        width: skWidth,
        borderRadius: skRadius,
        margin: skMargin,
        ...oterProps,
      }}
    >
      &nbsp;
    </span>
  );
}
Skeleton.propTypes = {
  skHeight: PropTypes.string,
  skWidth: PropTypes.string,
  skRadius: PropTypes.string,
  skMargin: PropTypes.string,
};
Skeleton.defaultProps = {
  skWidth: '',
  skHeight: '',
  skRadius: '',
  skMargin: '',
};
