/* eslint-disable react/prop-types */
import React from 'react';
import './table.scss';
import {
  useTable,
  usePagination,
  useSortBy,
  useGlobalFilter,
} from 'react-table';
import { ShortBothIcon, ShortDownIcon, ShortUpIcon } from '../icons/icons';

function BesicTable({
  columns,
  data,
  setSortColumnHeader,
  setSort,
  handleSortArrow,
  handleColumnHeader,
  noSpacing,
  pageSize,
  className,
}) {
  const perPage = pageSize && pageSize > 0 ? pageSize : 10;

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } =
    useTable(
      {
        columns,
        data,
        setSortColumnHeader,
        setSort,
        handleSortArrow,
        handleColumnHeader,
        initialState: {
          //  pageIndex: 0,
          // setSort: 0,
          // setSortColumnHeader: '',
          pageSize: perPage,
        },
      },
      useGlobalFilter,
      useSortBy,
      usePagination
    );

  // Render the UI for your table
  return (
    <div
      className={`custom-table-container ${
        noSpacing ? 'no-spacing' : ''
      } ${className}`}
    >
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => {
            return (
              <tr
                data-cy="table-head-row"
                {...headerGroup.getHeaderGroupProps()}
              >
                {headerGroup.headers.map((column) => (
                  <th
                    data-cy="tabel-head-data"
                    className={`
                      ${
                        column.render('Header') === 'Notification'
                          ? 'notification'
                          : ''
                      }
                        ${column.align}
                    `}
                    style={{ width: column.setWidth, textAlign: column.align }}
                  >
                    <span>
                      {column.render('Header')}
                      {column.enableSortBy ? (
                        <span>
                          {handleSortArrow === 0 && (
                            <button
                              type="button"
                              tabIndex="0"
                              onClick={() => {
                                setSortColumnHeader(column.Header);
                                setSort(1);
                              }}
                            >
                              <ShortBothIcon />
                            </button>
                          )}
                          {handleSortArrow === 1 && (
                            <button
                              type="button"
                              tabIndex="0"
                              onClick={() => {
                                setSortColumnHeader(column.Header);
                                setSort(-1);
                              }}
                            >
                              {handleColumnHeader === column.Header ? (
                                <ShortDownIcon />
                              ) : (
                                <ShortBothIcon />
                              )}
                            </button>
                          )}
                          {handleSortArrow === -1 && (
                            <button
                              type="button"
                              tabIndex="0"
                              onClick={() => {
                                setSortColumnHeader(column.Header);
                                setSort(1);
                              }}
                            >
                              {handleColumnHeader === column.Header ? (
                                <ShortUpIcon />
                              ) : (
                                <ShortBothIcon />
                              )}
                            </button>
                          )}
                        </span>
                      ) : null}
                    </span>
                  </th>
                ))}
              </tr>
            );
          })}
        </thead>
        <tbody data-cy="tabel-body-data" {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr data-cy="tabel-body-row" {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <td
                    {...cell.getCellProps()}
                    align={`
                      ${
                        cell.render('Header') === 'Notification' ? 'center' : ''
                      }
                      ${cell.column.align}
                    `}
                    style={{ textAlign: cell.column.align }}
                  >
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default BesicTable;
