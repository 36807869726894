import React from 'react';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import Tabs from '../../../../../Components/tabs/custom-tab';
import NoData from '../../../../../Components/no-data/no-data';
import { IncidentsNoData } from '../../../../../Components/icons/icons';
import Incidents from '../../../../../Components/dashboard-components/Incidents/Incidents';
import News from '../../../../../Components/dashboard-components/news/news';
import './news-incidents.scss';
import QUERY_QULOI_NEWS, {
  QUERY_P44_INCIDENT,
} from '../../../../../graphQL/dashboardQuery';

function NewsIncedents() {
  const { data: incidentData } = useQuery(QUERY_P44_INCIDENT);
  const { data: newsData } = useQuery(QUERY_QULOI_NEWS);
  const [t] = useTranslation('mydashboard');
  return (
    <Tabs className="news-incidents-container">
      <div label={t('tabtitle.incidents')} value="Incidents">
        {incidentData?.FetchProject44IncidentsData[0].title ===
        'There are no incidents' ? (
          <NoData
            message={t('incidents.placeholder')}
            image={<IncidentsNoData size={200} />}
          />
        ) : (
          <Incidents data={incidentData} />
        )}
      </div>
      <div label={t('tabtitle.latestnews')} value="Latest News">
        {newsData.QuloiNews.length > 0 ? (
          <News data={newsData} />
        ) : (
          <NoData
            message={t('incidents.placeholder')}
            image={<IncidentsNoData size={200} />}
          />
        )}
      </div>
    </Tabs>
  );
}

export default NewsIncedents;
