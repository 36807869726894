import React from 'react';
import PropTypes from 'prop-types';

function CardPanelHeader({ title, subtitle, inline, ...props }) {
  return (
    <div className="card-header" data-cy="card-panel-header">
      <h3
        data-cy="card-panel-title"
        className={`card-title ${inline ? 'inline' : ''}`}
      >
        {title}
        {subtitle !== '' && <span className="card-subtitle">{subtitle}</span>}
      </h3>
      <div data-cy="action-area" className="action-area">
        {props.children}
      </div>
    </div>
  );
}

export default CardPanelHeader;
CardPanelHeader.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  inline: PropTypes.bool,
  children: PropTypes.node.isRequired,
};
CardPanelHeader.defaultProps = {
  title: '',
  subtitle: '',
  inline: false,
};
