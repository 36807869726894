import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './webhook-integration.scss';
import { useMutation, useQuery } from '@apollo/client';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  SUBSCRIBER_DETAILS,
  WEBHOOK_SUBSCRIBE,
  TEST_ENDPOINT_URL,
  EVENTS_LIST,
  ACTIVATE_SUBSCRIPTION,
} from '../../../graphQL/webhookQuery';
import AccountSettingsPanel from '../../../Components/account-settings-panel/account-settings-panel';
import AccountSettingsPanelTitle from '../../../Components/account-settings-panel/account-settings-panel-title';
import {
  // PlusIcon,
  // TextEditIcon,
  WebhookIcon,
  InfoIcon,
} from '../../../Components/icons/icons';
import AccountSettingsPanelDescription from '../../../Components/account-settings-panel/account-settings-panel-description';
import TextBox from '../../../Components/text-box/text-box';
import AdvanceSelect from '../../../Components/advance-select/advance-select';
import Button from '../../../Components/button/button';
import Tabs from '../../../Components/tabs/custom-tab';
import Checkbox from '../../../Components/custom-checkbox/custom-checkbox';
import { getUser } from '../../../service/authService';
import useDialog from '../../../Components/dialog/useDialog';
import Dialog from '../../../Components/dialog/dialog';
import DialogPanel from '../../../Components/dialog-panel/dialog-panel';
import DialogPanelBody from '../../../Components/dialog-panel/dialog-panel-body/dialog-panel-body';
import DialogPanelFooter from '../../../Components/dialog-panel/dialog-panel-footer/dialog-panel-footer';
import AccountSettingsPanelHead from '../../../Components/account-settings-panel/account-settings-panel-head';
import AccountSettingsPanelHeadAction from '../../../Components/account-settings-panel/account-settings-panel-head-action';
import CustomSwitch from '../../../Components/custom-switch/custom-switch';
import SyntaxHighlighter from '../../../Components/code-comp/syntax-highlighter';

function WebhookIntegration({ organizationId }) {
  const [t] = useTranslation('accountsettings');
  const [eventList, setEventList] = useState();
  const [dropDownList, setDropDownList] = useState();
  const [webhookData, setWebhookData] = useState({});

  const { loading } = useQuery(EVENTS_LIST, {
    onCompleted: (webData) => {
      setEventList(webData?.ListEvents?.data);
      setDropDownList(webData?.ListEvents?.dropDown);
    },
  });

  const [subscriberData, setSubscriberData] = useState();
  const [handleSubscriberDetails] = useMutation(SUBSCRIBER_DETAILS);
  const [handleWebhookSubcribe] = useMutation(WEBHOOK_SUBSCRIBE);
  const [handleTestEndpointUrl] = useMutation(TEST_ENDPOINT_URL);
  const [handleActivateSubscription] = useMutation(ACTIVATE_SUBSCRIPTION);
  const [isTestEndpointUrlSuccessful, setIsTestEndpointUrlSuccessful] =
    useState(false);
  const [testEndpointUrlMessage, setTestEndpointUrlMessage] = useState('');
  const [eventsErrorMessage, setEventsErrorMessage] = useState('');
  const [selectedEvents, setSelectedEvents] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [jsonPreview, setJsonPreview] = useState({});
  const [active, setActive] = useState(false);
  const eventTabs = eventList && Object.keys(eventList);
  const user = getUser();
  const { isShowing: isShowingJSON, toggle: showJSON } = useDialog();
  const { isShowing: isShowingEventJSON, toggle: showEventJSON } = useDialog();

  const handleWebhookData = async ({ name }) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_WEBHOOK_DATA_URL,
        {
          fetch: name,
        }
      );
      setWebhookData(response?.data);
    } catch (error) {
      setWebhookData(t('accountsettings.webhookintegrations.error'));
    }
  };
  const objToString = (obj) => {
    return JSON.stringify(obj, null, 2);
  };

  const getSubscriberDetails = async () => {
    await handleSubscriberDetails({
      variables: {
        input: {
          orgId: Number(organizationId),
        },
      },
      onCompleted: (data) => {
        if (data?.SubscriberDetails?.data?.endpointUrl) {
          setSubscriberData(data?.SubscriberDetails?.data);
          setActive(data?.SubscriberDetails?.data?.active);
          setIsEdit(true);
          if (data?.SubscriberDetails?.data?.events) {
            setSelectedEvents(data.SubscriberDetails.data.events);
          }
        }
        if (!data?.SubscriberDetails) {
          setIsEdit(false);
        }
      },
    });
  };

  useEffect(() => {
    if (!subscriberData?._id) {
      setIsEdit(false);
    }
  }, [subscriberData]);

  useEffect(() => {
    if (Number(organizationId)) {
      getSubscriberDetails();
    }
  }, [organizationId]);

  const WebhookSubscribeServerCall = async (values) => {
    const {
      endpointUrl,
      method,
      payloadFormat,
      retryAttempts,
      authentication,
      events,
    } = values;
    const input = {
      organizationId: Number(organizationId),
      userType: user['custom:userType'],
      endpointUrl,
      method,
      authentication,
      events,
      payloadFormat,
      retryAttempts: {
        attempt: Number(retryAttempts?.attempt),
        delay: Number(retryAttempts?.delay),
      },
    };

    await handleWebhookSubcribe({
      variables: {
        input,
      },
      onCompleted: (data) => {
        toast.success(data?.Subscribe?.message);
        getSubscriberDetails();
      },
    });
  };

  const activeSubscriptionServerCall = async () => {
    await handleActivateSubscription({
      variables: {
        input: {
          subscriberId: subscriberData?._id,
        },
      },
      onCompleted: (data) => {
        if (data?.Activate?.message === 'Activated') {
          setActive(true);
        } else if (
          data?.Activate?.message?.startsWith('DeactivatedSubscriber')
        ) {
          setActive(false);
        }
        toast.success(data?.Activate?.message);
      },
    });
  };

  const testEndpointUrlServerCall = async (values) => {
    await handleTestEndpointUrl({
      variables: {
        input: {
          ...values,
        },
      },
      onCompleted: (data) => {
        setJsonPreview(data?.ValidateEndURL?.data);
        if (data?.ValidateEndURL?.success) {
          setIsTestEndpointUrlSuccessful(true);
          setTestEndpointUrlMessage(
            t(
              'accountsettings.webhookintegrations.test&previewdilog.testsuccessful'
            )
          );
          toast.success(data?.ValidateEndURL?.message);
        } else {
          setIsTestEndpointUrlSuccessful(false);
          toast.error(data?.ValidateEndURL?.message);
          setTestEndpointUrlMessage(
            t(
              'accountsettings.webhookintegrations.test&previewdilog.testfailed'
            )
          );
        }
      },
    });
  };

  const handleTest = (values) => {
    setIsTestEndpointUrlSuccessful(false);
    setTestEndpointUrlMessage('');
    testEndpointUrlServerCall(values);
  };

  const testMessageStyle = {
    fontSize: '0.972vw',
    fontWeight: '400',
    lineHeight: '1.111vw',
    color: testEndpointUrlMessage === 'Test successful' ? 'green' : 'red',
    marginTop: 0,
    textAlign: 'left',
  };

  const eventsErrorMessageStyle = {
    fontSize: '0.694vw',
    fontWeight: '400',
    lineHeight: '1.111vw',
    color: 'red',
    marginTop: 0,
    textAlign: 'left',
  };

  const jsonPreviewStyle = {
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
  };

  const handleEventType = (e, id) => {
    if (e?.target?.checked && !selectedEvents?.includes(id)) {
      setEventsErrorMessage('');
      setSelectedEvents((prev) => [...prev, id]);
    } else {
      if (selectedEvents.length === 1) {
        setEventsErrorMessage('Please select atleast one event');
      }
      setSelectedEvents((current) => current.filter((i) => i !== id));
    }
  };

  const formik = useFormik({
    initialValues: isEdit
      ? subscriberData
      : {
          authentication: {
            password: '',
            authType: 'no auth',
            username: '',
            secretKey: '',
          },
          method: 'post',
          endpointUrl: '',
          payloadFormat: 'JSON',
          retryAttempts: {
            attempt: 3,
            delay: 5,
          },
        },
    validationSchema: Yup.object({
      authentication: Yup.object({
        username: Yup.string().when('authType', {
          is: 'Basic',
          then: Yup.string().required('User name required for Basic Auth'),
          otherwise: Yup.string(),
        }),
        authType: Yup.string(),
        password: Yup.string().when('authType', {
          is: 'Basic',
          then: Yup.string()
            .matches(
              /^(?=.*\d)(?=.*[!@#$%^&*()])(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
              t(
                'accountsettings.password.editdilog.formvalidation.passwordinvalid'
              )
            )
            .min(
              8
              // 'Password must include 8 characters, a numerical value,  a special, a lower and an upper case character.'
            )
            .required('Password required for Basic Auth'),
          otherwise: Yup.string(),
        }),
        secretKey: Yup.string().when('authType', {
          is: 'secretKey',
          then: Yup.string().required(
            'Secret key required for Secret Key Auth'
          ),
          otherwise: Yup.string(),
        }),
      }),
      endpointUrl: Yup.string().required('Please enter your Endpoint URL'),
      method: Yup.string().required('Please select your Method'),
      payloadFormat: Yup.string(),
      retryAttempts: Yup.object({
        attempt: Yup.number().positive(),
        delay: Yup.number().positive(),
      }),
    }),
    onSubmit: (values) => {
      if (!selectedEvents.length) {
        setEventsErrorMessage('Please select atleast one event');
      } else {
        const payload = {
          ...values,
          events: selectedEvents,
        };
        WebhookSubscribeServerCall(payload);
      }
    },
    enableReinitialize: true,
  });

  const toggleStatus = (e) => {
    if (isEdit) {
      activeSubscriptionServerCall();
    } else {
      if (e?.target?.checked === true) {
        setActive(e?.target?.checked);
        return;
      }
      setActive(false);
    }
  };

  return (
    <form
      onSubmit={formik.handleSubmit}
      enableReinitialize={true}
      autoComplete="off"
    >
      <div className="webhook-integration-container">
        <AccountSettingsPanel className="form">
          <AccountSettingsPanelHead>
            <AccountSettingsPanelTitle>
              <WebhookIcon />
              {t(
                'accountsettings.webhookintegrations.webhookintegrationstitle'
              )}
            </AccountSettingsPanelTitle>
            <AccountSettingsPanelHeadAction>
              <CustomSwitch onChange={toggleStatus} checked={active} />
            </AccountSettingsPanelHeadAction>
          </AccountSettingsPanelHead>

          <AccountSettingsPanelDescription>
            {t(
              'accountsettings.webhookintegrations.webhookintegrationsdescription'
            )}
          </AccountSettingsPanelDescription>
        </AccountSettingsPanel>

        {active && (
          <>
            <AccountSettingsPanel className="form">
              <AccountSettingsPanelTitle>
                {t('accountsettings.webhookintegrations.endpointconfiguration')}
              </AccountSettingsPanelTitle>
              <div className="my-profile-row flex-wrap align-items-start">
                <div className="profile-form webhook-form">
                  <TextBox
                    id="endpointUrl"
                    type="url"
                    placeholder={t('accountsettings.webhookintegrations.url')}
                    label={t('accountsettings.webhookintegrations.endpointurl')}
                    errorMessage={formik.errors?.endpointUrl}
                    {...formik.getFieldProps('endpointUrl')}
                    onChange={(e) => {
                      setIsTestEndpointUrlSuccessful(false);
                      setTestEndpointUrlMessage('');
                      formik.setFieldValue('endpointUrl', e.target.value);
                    }}
                    maxLength="undefined"
                  />
                </div>
                <div className="profile-form webhook-form">
                  {!loading && dropDownList?.method && (
                    <AdvanceSelect
                      id="method"
                      name="method"
                      label={t('accountsettings.webhookintegrations.method')}
                      placeholder={t(
                        'accountsettings.webhookintegrations.post'
                      )}
                      data={dropDownList?.method}
                      defaultValue={
                        dropDownList?.method?.length > 0 &&
                        dropDownList?.method?.filter((item) =>
                          item.value === isEdit
                            ? formik?.initialValues?.method
                            : 'post'
                        )[0]
                      }
                      onChange={(e) => {
                        formik.setFieldValue('method', e.target.value);
                      }}
                      errorMessage={formik.errors?.method}
                    />
                  )}
                </div>
                <div className="profile-form webhook-form">
                  {!loading && dropDownList?.authentication && (
                    <AdvanceSelect
                      id="authentication.authType"
                      name="authentication.authType"
                      placeholder={t(
                        'accountsettings.webhookintegrations.noauth'
                      )}
                      label={t(
                        'accountsettings.webhookintegrations.authentication'
                      )}
                      {...formik.getFieldProps('authentication.authType')}
                      data={dropDownList?.authentication}
                      defaultValue={
                        dropDownList?.authentication?.length > 0 &&
                        dropDownList?.authentication?.filter(
                          (item) =>
                            item?.value ===
                            (isEdit
                              ? formik?.initialValues?.authentication?.authType
                              : 'no_auth')
                        )[0]
                      }
                      onChange={(e) => {
                        formik.setFieldValue(
                          'authentication.authType',
                          e.target.value
                        );
                      }}
                      errorMessage={formik.errors?.authentication?.authType}
                    />
                  )}
                </div>
              </div>
              {formik.getFieldProps('authentication.authType').value ===
                'Basic' && (
                <div className="my-profile-row flex-wrap align-items-start">
                  <div className="profile-form webhook-form">
                    <TextBox
                      id="authentication.username"
                      name="authentication.username"
                      label="User Name"
                      placeholder="User Name"
                      {...formik.getFieldProps('authentication.username')}
                      errorMessage={formik.errors?.authentication?.username}
                    />
                  </div>
                  <div className="profile-form webhook-form">
                    <TextBox
                      id="password"
                      name="authentication.password"
                      placeholder="Password"
                      label="Password"
                      type="password"
                      infoMessage={t(
                        'accountsettings.password.editdilog.formvalidation.passwordinvalidInfo'
                      )}
                      {...formik.getFieldProps('authentication.password')}
                      errorMessage={formik.errors?.authentication?.password}
                    />
                  </div>
                </div>
              )}
              {formik.getFieldProps('authentication.authType').value ===
                'secretKey' && (
                <div className="my-profile-row flex-wrap align-items-start">
                  <div className="profile-form webhook-form">
                    <TextBox
                      id="authentication.secretKey"
                      name="authentication.secretKey"
                      label="Secret key"
                      placeholder="Secret key"
                      {...formik.getFieldProps('authentication.secretKey')}
                      errorMessage={formik.errors?.authentication?.secretKey}
                      maxLength="undefined"
                    />
                  </div>
                </div>
              )}
            </AccountSettingsPanel>

            <AccountSettingsPanel className="form">
              <AccountSettingsPanelTitle>
                {t('accountsettings.webhookintegrations.events')}
              </AccountSettingsPanelTitle>
              {!loading && eventList && (
                <>
                  <Tabs className="webhook-events" disableURL>
                    {eventTabs?.map((eventTab) => (
                      <div
                        value={eventTab[0].toUpperCase() + eventTab.slice(1)}
                        className="event-tab"
                        label={eventTab[0].toUpperCase() + eventTab.slice(1)}
                      >
                        {eventList?.[eventTab]?.map((event) => {
                          return (
                            <div className="f-basis">
                              <Checkbox
                                id={event?._id}
                                name="events"
                                label={event?.label}
                                checked={selectedEvents?.includes(event?._id)}
                                defaultChecked={formik?.initialValues?.events?.includes(
                                  event?._id
                                )}
                                onChange={(e) => {
                                  handleEventType(e, event?._id);
                                }}
                                className="w-fit"
                              />
                              <button
                                type="button"
                                className="webhook-info-btn"
                                onClick={() => {
                                  setWebhookData({});
                                  handleWebhookData({ name: event.eventType });
                                  showEventJSON();
                                }}
                              >
                                <InfoIcon
                                  width="0.694vw"
                                  height="0.694vw"
                                  color="#1d3e69"
                                />
                              </button>
                            </div>
                          );
                        })}
                      </div>
                    ))}
                  </Tabs>
                  <Dialog
                    isShowing={isShowingEventJSON}
                    hide={showEventJSON}
                    placement="center"
                  >
                    <DialogPanel
                      title={t(
                        'accountsettings.webhookintegrations.webhookdata'
                      )}
                    >
                      <DialogPanelBody>
                        <SyntaxHighlighter
                          title={t('accountsettings.webhookintegrations.json')}
                          codeStringProp={objToString(webhookData)}
                        />
                      </DialogPanelBody>
                    </DialogPanel>
                  </Dialog>
                </>
              )}
              {eventsErrorMessage && (
                <p
                  className="events-error-message"
                  style={eventsErrorMessageStyle}
                >
                  {eventsErrorMessage}
                </p>
              )}
              {/* <AccountSettingsPanel className="form">
          <AccountSettingsPanelTitle>Events</AccountSettingsPanelTitle>
          <div className="my-profile-row flex-wrap align-items-start">
            <div className="profile-form webhook-form">
              <AdvanceSelect
                name="event[0]"
                label="Order"
                placeholder="Select order actions"
              />
            </div>
          </div>
        </AccountSettingsPanel> */}
            </AccountSettingsPanel>
            <AccountSettingsPanel className="form">
              <AccountSettingsPanelTitle>
                {t('accountsettings.webhookintegrations.payloadconfiguration')}
              </AccountSettingsPanelTitle>
              <div className="my-profile-row align-items-start">
                <div className="profile-form">
                  {!loading && dropDownList?.payloadFormat && (
                    <AdvanceSelect
                      name="payloadFormat"
                      label={t(
                        'accountsettings.webhookintegrations.payloadformat'
                      )}
                      placeholder="Select Payload Format"
                      data={dropDownList?.payloadFormat}
                      defaultValue={
                        dropDownList?.payloadFormat?.length > 0 &&
                        dropDownList?.payloadFormat?.filter(
                          (item) =>
                            item.value === formik?.initialValues?.payloadFormat
                        )[0]
                      }
                      onChange={(e) => {
                        formik.setFieldValue('payloadFormat', e.target.value);
                      }}
                      errorMessage={formik.errors?.payloadFormat}
                    />
                  )}
                </div>
              </div>
            </AccountSettingsPanel>

            <AccountSettingsPanel className="form">
              <AccountSettingsPanelTitle>
                {t(
                  'accountsettings.webhookintegrations.retry&time-outsettings'
                )}
              </AccountSettingsPanelTitle>
              <div className="my-profile-row align-items-start">
                <div className="profile-form">
                  {!loading && dropDownList?.retry && (
                    <AdvanceSelect
                      name="retryAttempts.attempt"
                      label={t(
                        'accountsettings.webhookintegrations.retryattempts'
                      )}
                      placeholder="Select Retry Attempts"
                      data={dropDownList?.retry}
                      defaultValue={
                        dropDownList?.retry?.length > 0 &&
                        dropDownList?.retry?.filter(
                          (item) =>
                            item.value ===
                            Number(
                              formik?.initialValues?.retryAttempts?.attempt
                            )
                        )[0]
                      }
                      onChange={(e) => {
                        formik.setFieldValue(
                          'retryAttempts.attempt',
                          e.target.value
                        );
                      }}
                      errorMessage={formik.errors?.retryAttempts?.attempt}
                    />
                  )}
                </div>
                <div className="profile-form">
                  {!loading && dropDownList?.delay && (
                    <AdvanceSelect
                      name="retryAttempts.delay"
                      label={t(
                        'accountsettings.webhookintegrations.timeoutafter'
                      )}
                      placeholder="Select Timeout time"
                      data={dropDownList?.delay}
                      defaultValue={
                        dropDownList?.delay?.length > 0 &&
                        dropDownList?.delay?.filter(
                          (item) =>
                            item.value ===
                            Number(formik?.initialValues?.retryAttempts?.delay)
                        )[0]
                      }
                      onChange={(e) => {
                        formik.setFieldValue(
                          'retryAttempts.delay',
                          e.target.value
                        );
                      }}
                      errorMessage={formik.errors?.retryAttempts?.delay}
                    />
                  )}
                </div>
              </div>
            </AccountSettingsPanel>

            <AccountSettingsPanel className="form">
              <AccountSettingsPanelTitle>
                {t('accountsettings.webhookintegrations.webhooklogs&debugging')}
              </AccountSettingsPanelTitle>
              <div className="webhook-form-group">
                <label className="label">
                  {t('accountsettings.webhookintegrations.testwebhook')}
                </label>
                <Button
                  color="primary"
                  label={t('accountsettings.webhookintegrations.test&preview')}
                  disabled={
                    isEdit
                      ? subscriberData?.endpointUrl ===
                        formik?.values?.endpointUrl
                      : isTestEndpointUrlSuccessful
                  }
                  onClick={() => {
                    handleTest({
                      endpointUrl: formik.values?.endpointUrl,
                      method: formik.values?.method,
                      auth: formik.values?.authLayer?.authType,
                    });
                    showJSON();
                  }}
                />
              </div>
              {testEndpointUrlMessage && (
                <p
                  className="test-endpoint-url-message"
                  style={testMessageStyle}
                >
                  {testEndpointUrlMessage}
                </p>
              )}
              {/* <div className="webhook-form-group">
            <label className="label">Link to Webhook logs</label>
            <a
              href="https://app.quloi.com/company_id/webhook_logs"
              className="value"
            >
              https://app.quloi.com/company_id/webhook_logs
            </a>
          </div> */}
            </AccountSettingsPanel>

            {/* <AccountSettingsPanel className="form">
          <AccountSettingsPanelTitle>End-Point URLs</AccountSettingsPanelTitle>

          <div className="webhook-form-group pb-80">
            <label className="label">Active End-Point URL</label>
            <a
              href="https://app.quloi.com/company_id/webhook_logs"
              className="value"
            >
              https://app.quloi.com/company_id/webhook_logs
            </a>
          </div>
          <div className="button-submit-area">
            <button type="button" className="button">
              Add
              <PlusIcon color="#0088FA" />
            </button>
            <button type="button" className="button">
              Edit
              <TextEditIcon />
            </button>
          </div>
        </AccountSettingsPanel> */}

            <div className="webhook-integration-footer">
              <Button
                color="primary"
                label={t('accountsettings.webhookintegrations.save')}
                type="submit"
                disabled={
                  isEdit
                    ? !(
                        (formik?.dirty ||
                          !(
                            formik?.initialValues?.events?.length ===
                              selectedEvents?.length &&
                            formik?.initialValues?.events
                              ?.slice()
                              .sort()
                              .every(
                                (value, index) =>
                                  value ===
                                  selectedEvents?.slice()?.sort()?.[index]
                              )
                          )) &&
                        selectedEvents?.length &&
                        (subscriberData?.endpointUrl ===
                          formik?.values?.endpointUrl ||
                          isTestEndpointUrlSuccessful)
                      )
                    : !(
                        formik.dirty &&
                        formik?.isValid &&
                        selectedEvents?.length &&
                        isTestEndpointUrlSuccessful
                      )
                }
              />
            </div>
          </>
        )}
      </div>
      <Dialog isShowing={isShowingJSON} hide={showJSON} placement="center">
        <DialogPanel
          title={t(
            'accountsettings.webhookintegrations.test&previewdilog.previewjson'
          )}
        >
          <DialogPanelBody>
            <div className="json-preview">
              <pre style={jsonPreviewStyle}>
                {JSON.stringify({ jsonPreview }, null, 1)}
              </pre>
            </div>
          </DialogPanelBody>
          <DialogPanelFooter>
            <Button
              label={t(
                'accountsettings.webhookintegrations.test&previewdilog.close'
              )}
              onClick={showJSON}
              styles="Okay"
            />
          </DialogPanelFooter>
        </DialogPanel>
      </Dialog>
    </form>
  );
}

function mapStateToProps(state) {
  return {
    organizationId:
      state?.omniDetails?.fetchOrganizationDetail?.fetchOrganizationDetails
        ?.data?.orgId,
  };
}

WebhookIntegration.propTypes = {
  organizationId: PropTypes.string,
};

WebhookIntegration.defaultProps = {
  organizationId: '',
};

export default connect(mapStateToProps, null)(WebhookIntegration);
