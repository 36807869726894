// client1.js
import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  from,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import * as Sentry from '@sentry/react';
import { setContext } from '@apollo/client/link/context';
import { toast } from 'react-toastify';
import urlConst from './URLConst';
import { getIDBasicToken, getIDToken, tokenGeneration } from './authService';

const httpLink1 = createHttpLink({
  uri: urlConst.GraphQLUrl,
});

const secondHttpLink = createHttpLink({
  uri: urlConst.GraphQLUrlService,
});

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'cache-and-network',
    // errorPolicy: 'none',
  },
  query: {
    fetchPolicy: 'network-only',
    errorPolicy: 'none',
  },
  mutate: {
    errorPolicy: 'none',
  },
};

const authLink = setContext((_, { headers }) => {
  const token = getIDToken();
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const secondAuthLink = setContext((_, { headers }) => {
  const token = getIDBasicToken();
  return {
    headers: {
      ...headers,
      authorization: token ? `Basic ${token}` : '',
    },
  };
});

const authGqlMircoserviceLink = setContext((_, { headers }) => {
  const token = getIDToken();
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message }) => {
      if (
        ![
          'Token invalid, please check and try again',
          'PO not found',
          'No booking available',
          'Shipment not found',
          'error ',
          '',
          'record not found',
          'No versions found!',
          'Not yet subscribed!',
          'Access Forbidden',
          'Invitation expired, Try requesting buyer again',
        ].includes(message)
      ) {
        toast.error(message);
        Sentry.captureException(`'ApolloError': ${message}`);
      }
      if (message === 'Token invalid, please check and try again') {
        tokenGeneration();
      }
    });
  }
  if (networkError) toast.error(`[Network error]: ${networkError}`);
});

const client1 = new ApolloClient({
  cache: new InMemoryCache({}),
  defaultOptions,
  queryDeduplication: true,
  link: from([errorLink, authLink.concat(httpLink1)]),
});

export const secondClient = new ApolloClient({
  cache: new InMemoryCache({}),
  defaultOptions,
  queryDeduplication: true,
  link: from([errorLink, secondAuthLink.concat(secondHttpLink)]),
});

export const gqlMicroServiceTokenClient = new ApolloClient({
  cache: new InMemoryCache({}),
  defaultOptions,
  queryDeduplication: true,
  link: from([errorLink, authGqlMircoserviceLink.concat(secondHttpLink)]),
});

export default client1;
