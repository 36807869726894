// eslint-disable-next-line
import getNotificationsFromStore from './storeHelper';

const createOrgWiseNotificationMap = (notifications, oldData = {}) => {
  const notificationMap = { ...oldData };
  notifications.forEach((notification) => {
    if (notification.metadata.collaboration) {
      notification.metadata.collaboration.forEach((colab) => {
        if (notificationMap[colab]) {
          if (notificationMap[colab][notification.type]) {
            notificationMap[colab][notification.type][notification._id] =
              notification;
          } else {
            notificationMap[colab][notification.type] = {
              [notification._id]: notification,
            };
          }
          notificationMap[colab].totalCount += 1;
        } else {
          notificationMap[colab] = {
            [notification.type]: {
              [notification._id]: notification,
            },
            totalCount: 1,
          };
        }
      });
    } else if (notificationMap.other) {
      notificationMap.other.push(notification);
    } else {
      notificationMap.other = [notification];
    }
  });
  notificationMap.totalCount = notifications.length;
  return notificationMap;
};

export default createOrgWiseNotificationMap;

export const getNotificationCount = (data, type) => {
  if (type === 'Orders' && data?.order) {
    return Object.keys(data.order).length;
  }
  if (type === 'Bookings' && data?.booking) {
    return Object.keys(data.booking).length;
  }
  if (type === 'Shipments' && data?.shipment) {
    return Object.keys(data.shipment).length;
  }
  return 0;
};

export const isNotificationExistForOrder = (relationId) => {
  try {
    const orgId = localStorage.getItem('activeOrg');
    const notifications = getNotificationsFromStore();
    if (notifications && notifications[orgId]) {
      const data = Object.values(notifications[orgId]?.order).filter(
        (notification) => notification?.metadata?.orderId === relationId
      );
      if (data.length !== 0) {
        return true;
      }
      return false;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const isNotificationExistForBooking = (relationId) => {
  try {
    const orgId = localStorage.getItem('activeOrg');
    const notifications = getNotificationsFromStore();
    if (notifications && notifications[orgId]) {
      const data = Object.values(notifications[orgId]?.booking).filter(
        (notification) => notification?.metadata?.bookingId === relationId
      );
      if (data.length !== 0) {
        return true;
      }
      return false;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const isNotificationExistForShipment = (relationId) => {
  try {
    const orgId = localStorage.getItem('activeOrg');
    const notifications = getNotificationsFromStore();
    if (notifications && notifications[orgId]) {
      const data = Object.values(notifications[orgId]?.shipment).filter(
        (notification) => notification?.metadata?.shipmentId === relationId
      );
      if (data.length !== 0) {
        return true;
      }
      return false;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const getNotificationsForOrder = (relationId) => {
  try {
    const orgId = localStorage.getItem('activeOrg');
    const notifications = getNotificationsFromStore();
    if (notifications) {
      const data = Object.values(notifications[orgId]?.order).filter(
        (notification) => notification?.metadata?.orderId === relationId
      );
      return data;
    }
    return [];
  } catch (error) {
    return [];
  }
};

export const getNotificationListForType = (type) => {
  const orgId = localStorage.getItem('activeOrg');
  const notifications = getNotificationsFromStore();
  if (notifications && notifications[orgId] && notifications[orgId][type]) {
    return Object.values(notifications[orgId][type]);
  }
  return [];
};

export const getNotificationByStatus = (type, statusDetails) => {
  const data = getNotificationListForType(type);
  let queryId;
  switch (type) {
    case 'order':
      queryId = 'orderId';
      break;
    case 'booking':
      queryId = 'bookingId';
      break;
    case 'shipment':
      queryId = 'shipmentId';
      break;
    default:
      break;
  }
  const uniqueOrderId = [];
  const dataUnique = [];
  data.forEach((item) => {
    if (!uniqueOrderId.includes(item.metadata[queryId])) {
      uniqueOrderId.push(item.metadata[queryId]);
      dataUnique.push(item);
    }
  });
  const statusNotificationData = [];
  statusDetails.forEach((state) => {
    let count = 0;
    dataUnique.forEach((item) => {
      if (item?.metadata?.statusName) {
        if (state.subStatus.includes(item.metadata.statusName)) {
          count += 1;
        }
      }
    });
    statusNotificationData.push({
      ...state,
      notificationCount: count,
    });
  });
  return statusNotificationData;
};

export const getNotificationsForBooking = (relationId) => {
  try {
    const orgId = localStorage.getItem('activeOrg');
    const notifications = getNotificationsFromStore();
    if (notifications) {
      const data = Object.values(notifications[orgId]?.booking).filter(
        (notification) => notification?.metadata?.bookingId === relationId
      );
      return data;
    }
    return [];
  } catch (error) {
    return [];
  }
};

export const getNotificationsForShipment = (relationId) => {
  try {
    const orgId = localStorage.getItem('activeOrg');
    const notifications = getNotificationsFromStore();
    if (notifications) {
      const data = Object.values(notifications[orgId]?.shipment).filter(
        (notification) => notification?.metadata?.shipmentId === relationId
      );
      return data;
    }
    return [];
  } catch (error) {
    return [];
  }
};

export const getTotalNotificationCountOfOrganization = (data) => {
  let count = 0;
  if (data?.order) {
    count += Object.keys(data.order).length;
  }
  if (data?.booking) {
    count += Object.keys(data.booking).length;
  }
  if (data?.shipment) {
    count += Object.keys(data.shipment).length;
  }
  return count;
};

export const getGroupNotificationCount = (orgIdList) => {
  let count = 0;
  const notifications = getNotificationsFromStore();
  orgIdList?.forEach((org) => {
    const orgId = parseInt(org.orgId, 10);
    if (notifications[orgId]) {
      count += getTotalNotificationCountOfOrganization(notifications[orgId]);
    }
  });
  return count;
};

export const getTotalUnreadNotificationCount = () => {
  const notifications = getNotificationsFromStore();
  return notifications.totalCount;
};
