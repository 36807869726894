import React, { useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import './login.scss';
// import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { QUERY_PROFILEUPDATION } from '../../../graphQL/profilePageQuery';
import GlobalLoader from '../../../Components/global-loader/global-loader';
import {
  getUser,
  tokenGeneration,
  getToken,
} from '../../../service/authService';
import TextBoxIcon from '../../../Components/text-box-icon/text-box-icon';
import { EmailIcon, PasswordIcon } from '../../../Components/icons/icons';
// import AdvanceSelectLabelIcon from '../../../Components/advance-select/advance-select-icon';

const QUERY_USER_SIGNIN = gql`
  query UserLogin($input: UserSignInInput) {
    userlogin(input: $input) {
      message
      data {
        AuthenticationResult {
          AccessToken
          TokenType
          RefreshToken
          IdToken
        }
      }
      channel
    }
  }
`;

export default function Login() {
  const [hostName, setHostName] = useState('');
  const [t] = useTranslation('global'); // i18n
  // const [t, i18n] = useTranslation('global');
  const cookie = new Cookies();

  useEffect(() => {
    setHostName(window.location.hostname);
  }, []);

  const [loadingUserSignIn, { called, loading }] =
    useLazyQuery(QUERY_USER_SIGNIN);
  // const langauageOptions = [
  //   {
  //     label: 'English',
  //     value: 'en',
  //   },
  //   {
  //     label: 'Turkish',
  //     value: 'turkish',
  //   },
  //   {
  //     label: 'Mexican',
  //     value: 'spanish',
  //   },
  // ];
  // const [selectedLanguage, setSelectedLangauge] = useState(
  //   window.localStorage.getItem('language') || 'en'
  // );
  const [handleuserprofile] = useMutation(QUERY_PROFILEUPDATION);
  const serverCall = (submitLogin) => {
    loadingUserSignIn({
      variables: {
        input: {
          ...submitLogin,
          clientDomain: hostName,
          language: localStorage.getItem('language'),
        },
      },
      onCompleted: (data) => {
        localStorage.setItem(
          'AccessToken',
          data.userlogin.data.AuthenticationResult.AccessToken
        );
        localStorage.setItem(
          'IdToken',
          data.userlogin.data.AuthenticationResult.IdToken
        );
        localStorage.setItem(
          'RefreshToken',
          data.userlogin.data.AuthenticationResult.RefreshToken
        );
        cookie.set(
          'access_token',
          data.userlogin.data.AuthenticationResult.AccessToken,
          { domain: '.quloi.com', path: '/' }
        );
        cookie.set(
          'refresh_token',
          data.userlogin.data.AuthenticationResult.RefreshToken,
          { domain: '.quloi.com', path: '/' }
        );
        cookie.set(
          'id_token',
          data.userlogin.data.AuthenticationResult.IdToken,
          { domain: '.quloi.com', path: '/' }
        );
        const user = getUser();
        const userType = user['custom:language'];
        // compare
        if (userType === localStorage.getItem('language')) {
          localStorage.setItem('language', userType);
          window.location.reload();
        } else {
          handleuserprofile({
            variables: {
              input: {
                lang: localStorage.getItem('language'),
                accessToken: getToken(),
              },
            },
            onCompleted: () => {
              tokenGeneration();
            },
          });
        }
      },
    });
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email(t('loginscreen.login.emailinvalidatemsg'))
        .required(t('loginscreen.login.emailrequiredmsg')),
      password: Yup.string().required(
        t('loginscreen.login.passwordrequiredmsg')
      ),
    }),
    onSubmit: (val) => {
      const payload = {
        ...val,
      };
      serverCall(payload);
    },
  });
  return (
    <>
      {loading && called && (
        <div className="login-loader">
          <GlobalLoader newLoad={true} />
        </div>
      )}

      <div className="login-container">
        <form onSubmit={formik.handleSubmit} autoComplete="off">
          {/* <div className="login-group">
            <span className="group-icon">
              <EmailIcon color="#A6A6A6" />
            </span> */}
          <TextBoxIcon
            required
            id="email"
            name="email"
            label={t('loginscreen.login.email')}
            type="email"
            {...formik.getFieldProps('email')}
            autoComplete="false"
            data-cy="email-login-page"
            errorMessage={
              formik.touched.email && formik.errors.email
                ? formik.errors.email
                : null
            }
          >
            <EmailIcon color="#A6A6A6" />
          </TextBoxIcon>
          {/* </div> */}
          {/* {formik.touched.email && formik.errors.email ? (
            <p className="error-message">{formik.errors.email}</p>
          ) : null} */}
          {/* <div className="login-group">
            <span className="group-icon">
              <PasswordIcon color="#A6A6A6" />
            </span> */}
          <TextBoxIcon
            required
            id="password"
            name="password"
            label={t('loginscreen.login.password')}
            type="password"
            {...formik.getFieldProps('password')}
            autoComplete="false"
            data-cy="password-login-page"
            errorMessage={
              formik.touched.password && formik.errors.password
                ? formik.errors.password
                : null
            }
          >
            <PasswordIcon color="#A6A6A6" />
          </TextBoxIcon>
          {/* </div> */}
          {/* {formik.touched.password && formik.errors.password ? (
            <p className="error-message">{formik.errors.password}</p>
          ) : null} */}
          <div
            className="login-group forgot-password"
            data-cy="forget-password-link"
          >
            <Link to="/forgotpassword">{t('loginscreen.login.forgot')}</Link>
          </div>
          {/* <div> */}
          {/* <AdvanceSelectLabelIcon
              mBottom="$px23"
              id="language"
              data={langauageOptions}
              name="language"
              label="choose language"
              defaultValue={
                langauageOptions &&
                langauageOptions.length > 0 &&
                langauageOptions.filter(
                  (item) => item.value === selectedLanguage
                )[0]
              }
              onChange={(e) => {
                setSelectedLangauge(e.target.value);
                i18n.changeLanguage(e.target.value);
                window.localStorage.setItem('language', e.target.value);
              }}
            />
          </div>
          {/* <div>
            <AdvanceSelectLabelIcon
              mBottom="$px23"
              id="language"
              data={langauageOptions}
              name="language"
              label="choose language"
              defaultValue={
                langauageOptions &&
                langauageOptions.length > 0 &&
                langauageOptions.filter(
                  (item) => item.value === selectedLanguage
                )[0]
              }
              onChange={(e) => {
                setSelectedLangauge(e.target.value);
                i18n.changeLanguage(e.target.value);
                window.localStorage.setItem('language', e.target.value);
              }}
            />
          </div> */}
          <div className="login-group btn-block" data-cy="loginbtn-login-page">
            <button type="submit" className="submit" value="Submit">
              {t('loginscreen.login.loginbtn')}
            </button>
          </div>
        </form>
        <div className="login-group btn-block" data-cy="registerbtn-login-page">
          <Link className="other" to="/register">
            {t('loginscreen.login.signup')}
          </Link>
        </div>
      </div>
    </>
  );
}
