import React from 'react';
import Messages from '../../Components/message/Chat';
import './direct-chat.scss';
import UsersList from './users-list/users-list';

export default function DirectChat() {
  return (
    <div className="direct-chat-container">
      <div className="direct-chat-user-list">
        <UsersList />
      </div>
      <div className="direct-chat-messages">
        <Messages enableTab={true} />
      </div>
    </div>
  );
}
