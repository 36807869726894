const createAddress = (data) => {
  return `${data.orgAddress} ${data.orgCity} ${data.orgState}, ${data.orgCountry}, ${data.orgZip}`;
};

export const allOrgIdArray = (data) => {
  const orgList = [];
  data?.OrganizationList?.forEach((item) => {
    orgList.push({ relationShip: item.relationship, ...item.organization });
  });

  data?.buyerSupplierGroupList?.forEach((list) => {
    const { relationship } = list;
    list?.list?.forEach((org) => {
      orgList.push({ relationShip: relationship, ...org });
    });
  });
  return orgList;
};

export const allOgDetails = (data, specOrg) => {
  const orgs = [];
  data.forEach((item) => {
    if (specOrg?.includes(Number(item.orgId))) {
      orgs.push(item);
    }
  });
  return orgs;
};

export default createAddress;
