import React from 'react';
import PropTypes from 'prop-types';
import './dashboard-card.scss';
import {
  DashboardExpandIcon,
  ProgressDownIcon,
  ProgressUpIcon,
} from '../../../Components/icons/icons';

function DashboardCard({
  title,
  cardValue,
  icon,
  value,
  // valueIcon,
  progressValue,
  onClick,
  // defaultCard,
  period,
  activeCard,
  setActiveCard,
  enableActive,
  disableExpand,
  t,
}) {
  let cardText = '';

  switch (period.toLowerCase()) {
    case 'monthly':
      cardText = t('month');
      break;
    case 'quarterly':
      cardText = t('quarter');
      break;
    case 'yearly':
      cardText = t('year');
      break;
    default:
      cardText = t('month');
      break;
  }

  return (
    <button
      type="button"
      className={`dashboard-card hover${
        enableActive && activeCard === title ? ' active' : ''
      } `}
      title={title}
      onClick={() => {
        onClick(cardValue);
        setActiveCard(title);
        // handleCurretCard(e);
      }}
    >
      <span className="dashboard-card-top">
        <span className="dashboard-card-title">{title}</span>

        {icon && <span className="dashboard-card-icon">{icon}</span>}
        {icon && !disableExpand && (
          <span className="dashboard-card-icon hover">
            <span className="icon">
              <DashboardExpandIcon size="0.833vw" />
            </span>
          </span>
        )}
      </span>
      <span className="dashboard-card-middle">
        <span className="dashboard-card-value">{value}</span>
        {/* <span className="dashboard-card-value-icon">{valueIcon}</span> */}
      </span>
      <span className="dashboard-card-bottom">
        <span className="dashboard-card-progress">
          <span
            className={`dashboard-card-progress-value${
              progressValue.split('')?.shift()?.charAt(0) === '+' &&
              progressValue !== '+0.00'
                ? // (progressValue !== '-0.00' || )
                  ' up'
                : ''
            }${
              progressValue.split('')?.shift()?.charAt(0) === '-' &&
              progressValue !== '-0.00'
                ? ' down'
                : ''
            }`}
          >
            {progressValue === '-0.00' || progressValue === '+0.00'
              ? '0.00'
              : progressValue}
            {/* {(() => {
              const number = Number.parseFloat(progressValue);
              if (!Number.isNaN(number)) {
                return number.toFixed(2);
              }
              return '--';
            })()} */}
          </span>
          <span className="dashboard-card-progress-icon">
            {progressValue?.split('')?.shift()?.charAt(0) === '+' &&
              progressValue !== '+0.00' && <ProgressUpIcon />}
            {progressValue?.split('')?.shift()?.charAt(0) === '-' &&
              progressValue !== '-0.00' && <ProgressDownIcon />}
          </span>
        </span>
        <span className="dashboard-card-progress-range">{`${t(
          'this'
        )} ${cardText}`}</span>
      </span>
    </button>
  );
}

DashboardCard.propTypes = {
  title: PropTypes.string,
  cardValue: PropTypes.string,
  icon: PropTypes.element.isRequired,
  value: PropTypes.string,
  // valueIcon: PropTypes.element.isRequired,
  progressValue: PropTypes.string,
  onClick: PropTypes.func,
  // defaultCard: PropTypes.string,
  period: PropTypes.string,
  activeCard: PropTypes.string,
  setActiveCard: PropTypes.func,
  enableActive: PropTypes.bool,
  disableExpand: PropTypes.bool,
  t: PropTypes.func,
};
DashboardCard.defaultProps = {
  title: '',
  cardValue: '',
  value: '',
  progressValue: '',
  onClick: () => {},
  period: '',
  activeCard: '',
  setActiveCard: () => {},
  enableActive: false,
  disableExpand: false,
  t: () => {},
};
export default DashboardCard;
