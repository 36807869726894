import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import Button from '../button/button';
import { TransferAccountIcon } from '../icons/icons';
import './transfer-account.scss';
// import AdvanceSelectLabel from '../advance-select/advance-select-label';
import UserList from '../user-list/user-list';
import DialogPanel from '../dialog-panel/dialog-panel';
import DialogPanelBody from '../dialog-panel/dialog-panel-body/dialog-panel-body';
import DialogPanelFooter from '../dialog-panel/dialog-panel-footer/dialog-panel-footer';

export default function TransferAccount({
  openConfirmation,
  userEmails,
  setValue,
  closeTransfer,
}) {
  const [t] = useTranslation('accountsettings');
  const [transferData, setData] = useState('');

  const formik = useFormik({
    initialValues: { transferData: '' },
    validationSchema: Yup.object({
      transferData: Yup.string()
        .email()
        .required(
          t('accountsettings.myprofile.transferaccountdilog.validation')
        ),
    }),
    enableReinitialize: true,
    onSubmit: (values) => {
      const payload = {
        ...values,
      };
      setValue(payload.transferData);
      openConfirmation();
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <DialogPanel
        title={t(
          'accountsettings.myprofile.transferaccountdilog.transferaccount'
        )}
      >
        <DialogPanelBody className="transfer-account">
          <div className="transfer-account-icon">
            <TransferAccountIcon color="#0288fa" />
          </div>
          <div className="transfer-account-ddl">
            <UserList
              defaultValue={transferData}
              id="transferData"
              label={t(
                'accountsettings.myprofile.transferaccountdilog.selectkeyadmin'
              )}
              placeholder={t(
                'accountsettings.myprofile.transferaccountdilog.selectuserplaceholder'
              )}
              required
              data={userEmails}
              onChange={(e) => {
                formik.setFieldValue(
                  'transferData',
                  e.target.value,
                  setData(e.target.value)
                );
              }}
              errorMessage={
                formik.touched.transferData && formik.errors.transferData
                  ? formik.errors.transferData
                  : null
              }
            >
              <TransferAccountIcon color="#A6A6A6" />
            </UserList>
          </div>
        </DialogPanelBody>
        <DialogPanelFooter>
          <Button
            label={t('accountsettings.myprofile.transferaccountdilog.cancel')}
            color="cancel"
            onClick={closeTransfer}
          />
          <Button
            onClick={() => {
              // setValue(transferData);
              // openConfirmation();
            }}
            type="submit"
            value="Submit"
            label={t('accountsettings.myprofile.transferaccountdilog.submit')}
            color="submit"
          />
        </DialogPanelFooter>
      </DialogPanel>
      {/* <div className="transfer-account">
        <div className="transfer-account-head">
          <h3>
            {t(
              'accountsettings.myprofile.transferaccountdilog.transferaccount'
            )}
          </h3>
        </div>
        <div className="transfer-account-body">
          <div className="transfer-account-icon">
            <TransferAccountIcon color="#0288fa" />
          </div>
          <div className="transfer-account-ddl">
            <UserList
              defaultValue={transferData}
              id="transferData"
              label={t(
                'accountsettings.myprofile.transferaccountdilog.selectkeyadmin'
              )}
              placeholder={t(
                'accountsettings.myprofile.transferaccountdilog.selectuserplaceholder'
              )}
              required
              data={userEmails}
              onChange={(e) => {
                formik.setFieldValue(
                  'transferData',
                  e.target.value,
                  setData(e.target.value)
                );
              }}
            />
            {formik.touched.transferData && formik.errors.transferData ? (
              <p className="profile-error-message">
                {formik.errors.transferData}
              </p>
            ) : null}
          </div>
        </div>
        <div className="transfer-account-footer">
          <Button
            label={t('accountsettings.myprofile.transferaccountdilog.cancel')}
            color="cancel"
            onClick={closeTransfer}
          />
          <Button
            onClick={() => {
              // setValue(transferData);
              // openConfirmation();
            }}
            type="submit"
            value="Submit"
            label={t('accountsettings.myprofile.transferaccountdilog.submit')}
            color="submit"
          />
        </div>
      </div> */}
    </form>
  );
}
TransferAccount.propTypes = {
  openConfirmation: PropTypes.func,
  closeTransfer: PropTypes.func,
  userEmails: PropTypes.instanceOf(Array),
  setValue: PropTypes.func,
};
TransferAccount.defaultProps = {
  openConfirmation: () => {},
  closeTransfer: () => {},
  userEmails: [],
  setValue: () => {},
};
