import React from 'react';
import PropTypes from 'prop-types';
import './profile-title-description.scss';

function ProfileTitleDescription({ title, description, className, icon }) {
  return (
    <div className={`profile-title-description ${className}`}>
      {title && <p className="title">{title}</p>}
      <p className="description">
        {icon} {description}
      </p>
    </div>
  );
}

export default ProfileTitleDescription;
ProfileTitleDescription.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  className: PropTypes.string,
  icon: PropTypes.element,
};
ProfileTitleDescription.defaultProps = {
  title: '',
  description: '--',
  className: '',
  icon: null,
};
