import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import ReactHtmlParser from 'react-html-parser';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import {
  AcceptIcon,
  ActionableNotificationNoDataIcon,
  AddQuotationIcon,
  AllNotificationNoDataIcon,
  ChatNoData,
  CreateBookingIcon,
  CreateShipmentIcon,
  DocumetsNotificationNoDataIcon,
  POChangesNoData,
  // NotificationNoData,
  ResponceNotificationNoDataIcon,
} from '../icons/icons';
import './global-notification.scss';
import { UPDATE_ORDERITEMS } from '../../graphQL/orderDetailsPage';
import { MUTATION_BOOKING_ACCEPT_REJECT } from '../../graphQL/bookingList';
import URLConst from '../../service/URLConst';
import Skeleton from '../skeleton/skeleton';
import NoData from '../no-data/no-data';
import getInitials from '../../helpers/utils';
import ActionDialog from './action-dialog';
import http from '../../service/APIUtil';
import { tokenGeneration } from '../../service/authService';
import { readChannelNotifications } from '../../service/chatAPIHandler';
import store from '../../redux/store';
import OutsideClick from '../outside-click/outside-click';
import Tooltip from '../tooltip/tooltip';
import DocumentItem from './document-item';
import PoChangesGroup from './po-changes/po-changes';
import MentionsMessages from './mentions-messages/mentions-messages';
import UserImage from '../user-image/user-image';
import { dateRegex, getUserTimeZoneDateFormat } from '../../helpers/dateHelper';

export const giveProfile = (profileType) => {
  let profile = '';
  switch (profileType) {
    case 'buyer':
      profile = 'supplier';
      break;
    case 'supplier':
      profile = 'buyer';
      break;
    default:
      profile = profileType;
      break;
  }
  return profile;
};

function NotificationList({ closeNotification, value, toggleState, show }) {
  const { relation } = useParams();
  const [handleUpdateOrderItem] = useMutation(UPDATE_ORDERITEMS);
  const [handleBookingAcceptReject] = useMutation(
    MUTATION_BOOKING_ACCEPT_REJECT
  );
  const profile = giveProfile(toggleState);

  const [markRead, setMarkRead] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [showUnread, setShowUnread] = useState(false);

  const chatHost = `${URLConst.host}/v1/`;

  const hostUrl = `${window.location.protocol}//${window.location.host}`;
  const [showData, setShowData] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [currentID, setCurrentID] = useState(false);
  const notificationContainer = useRef(null);
  const [toggleSelfItem, setToggleSelfItem] = useState(false);
  const [notificationPage, setNotificationPage] = useState({
    index: 1,
    available: true,
    perPage: 100,
  });

  const getNotification = async (more, event, unreadState) => {
    try {
      if (more) {
        setNotificationPage((prevState) => ({
          ...prevState,
          index: prevState.index + 1,
          available: event === 'toggled' ? true : prevState.available,
        }));
      } else {
        let ntfIndex = notificationPage.index + 1;
        if (event === 'markAllRead') {
          ntfIndex = notificationPage.index;
        }

        setNotificationPage({
          ...notificationPage,
          index: ntfIndex,
          available: event === 'toggled' ? true : notificationPage.available,
        });
      }
      if (notificationPage.available === true || event === 'toggled') {
        const NotificationData = await http.get(
          `${chatHost}notifications/alllist?page=${
            event === 'toggled' ? 1 : notificationPage.index
          }&perPage=${notificationPage.perPage}${
            unreadState ? '&filterList=true' : ''
          }${
            value ? `&notificationType=${value}` : '&notificationType=general'
          }${toggleState && `&profileType=${profile}`}`
        );

        if (more) {
          setShowData([...showData, ...NotificationData.data.data]);
        } else {
          setShowData([...NotificationData.data.data]);
        }
        if (notificationContainer && event === 'toggled') {
          notificationContainer.current.scrollTop = 0;
        }
      }
      setShowLoader(false);
    } catch (error) {
      if (error?.response?.status === 401) {
        await tokenGeneration();
        setShowLoader(true);
      }
      setShowLoader(false);
      if (error.response && error.response.status === 404) {
        if (event === 'toggled') {
          setShowData([]);
        }
        setNotificationPage({
          ...notificationPage,
          available: false,
        });
      }
    }
  };

  useEffect(() => {
    setShowLoader(true);
    if (markRead) {
      getNotification(false, 'markAllRead', false);
    } else {
      getNotification(false, '', false);
    }
  }, [markRead]);

  const handleShowUnread = (e) => {
    setShowUnread(e.target.checked);
    if (e.target.checked) {
      getNotification(false, 'toggled', true);
    } else {
      getNotification(false, 'toggled', false);
    }
  };

  const handleMarkAsRead = async () => {
    await http
      .post(`${chatHost}notifications/markAllasRead`, {
        notificationType: [value],
        profileType: profile,
      })
      .then((res) => {
        setMarkRead(true);
        return res.data;
      });
  };

  const getMoreNotifications = async () => {
    setIsFetching(true);
    await getNotification(true, '', showUnread);
    setIsFetching(false);
  };

  const srcollHandler = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight;
    if (bottom < 1 && showLoader === false) {
      getMoreNotifications();
    }
  };

  const saveAcceptRejectNotification = (
    Status,
    itemCodeList,
    poId,
    actionTakenFrom
  ) => {
    if (actionTakenFrom === 'order') {
      handleUpdateOrderItem({
        variables: {
          input: {
            id: poId,
            module: 'order',
            lineItemId: itemCodeList,
            statusName: Status,
            statusDate: new Date(),
            profileType: giveProfile(relation),
          },
        },
        onCompleted: async (data) => {
          toast.success(data.updateOrderItem.message);
          await readChannelNotifications(poId, 'order');
        },
      });
    }
    if (actionTakenFrom === 'booking') {
      handleBookingAcceptReject({
        variables: {
          input: {
            status: Status,
            bookingIds: itemCodeList,
          },
        },
        onCompleted: async (data) => {
          toast.success(data.bookingAcceptReject.message);
          await readChannelNotifications(itemCodeList, 'booking');
        },
      });
    }
  };

  const getTextByCond = (text, itm) => {
    let replaceText = text;
    const arrDateFilter = text
      ?.split(' ')
      ?.filter((str) => str.match(dateRegex));
    if (text?.split(' ').includes('Accepted')) {
      replaceText = `${replaceText.replace(
        'Accepted',
        '<span class="text-success">Accepted</span> '
      )} `;
    }
    if (text?.split(' ').includes('Rejected')) {
      replaceText = `${replaceText.replace(
        'Rejected',
        '<span class="text-danger">Rejected</span> '
      )} `;
    }
    if (arrDateFilter?.length > 0) {
      arrDateFilter?.forEach((dateData) => {
        replaceText = replaceText.replace(
          dateData,
          getUserTimeZoneDateFormat(dateData?.split('Date')?.[1])
        );
      });
    }
    if (text?.split(' ').includes(itm?.relationNo)) {
      replaceText = replaceText?.replace(
        itm?.relationNo,
        ` <a class="link" href=${hostUrl}/${itm?.link}>
          ${itm?.relationNo}
        </a>`
      );
    } else {
      replaceText = `${text} `;
    }
    return replaceText;
  };

  const toggleSelf = (e) => {
    setCurrentID(e.target.id);
    setToggleSelfItem(true);
  };
  const closeSelf = () => {
    setToggleSelfItem(false);
  };
  const [t] = useTranslation('notifications');
  return (
    <OutsideClick onClickOutside={closeNotification}>
      <div className="notification-list-container">
        <div className="notification-list-heder">
          <h3 className="notification-list-title">
            {t('notifications.title')}
          </h3>
          <div className="notification-list-heder-right">
            {show && (
              <label
                className="notification-switch"
                htmlFor="switchNotification"
              >
                <input
                  value="unread"
                  type="checkbox"
                  onClick={handleShowUnread}
                  id="switchNotification"
                />
                <span className="wrapper">
                  <span className="switch-label">
                    {t('notifications.showonlyunread')}
                  </span>
                  <span className="switch-bg">
                    <span className="ball" />
                  </span>
                </span>
              </label>
            )}
          </div>
        </div>
        <div className="notification-list-tab">
          <div className="notification-list-tab-buttons">
            {value ? (
              <label>
                {t(`notifications.${value}`)} {t('notifications.notifications')}
              </label>
            ) : (
              <label>Milestone</label>
            )}
          </div>
          {show && (
            <div className="notification-list-tab-buttons">
              {showData.length !== 0 && (
                <button type="button" onClick={handleMarkAsRead}>
                  <AcceptIcon color="#333333" />
                  {t('notifications.markallasread')}
                </button>
              )}
            </div>
          )}
        </div>

        <div
          className="notification-list-body"
          onScroll={srcollHandler}
          ref={notificationContainer}
        >
          {!['poChange', 'chat', 'mention'].includes(value) && !showLoader ? (
            <ul className="notification-list">
              {showData.length === 0 ? (
                <div>
                  {value === 'actionable' && (
                    <NoData
                      image={
                        <ActionableNotificationNoDataIcon
                          Width="100%"
                          Height="13.889vw"
                        />
                      }
                    />
                  )}
                  {value === 'approval' && (
                    <NoData
                      image={
                        <ResponceNotificationNoDataIcon
                          Width="100%"
                          Height="13.889vw"
                        />
                      }
                    />
                  )}
                  {value === 'document' && (
                    <NoData
                      image={
                        <DocumetsNotificationNoDataIcon
                          Width="100%"
                          Height="13.889vw"
                        />
                      }
                    />
                  )}
                  {value !== 'actionable' &&
                    value !== 'approval' &&
                    value !== 'document' && (
                      <NoData
                        image={
                          <AllNotificationNoDataIcon
                            Width="100%"
                            Height="13.889vw"
                          />
                        }
                      />
                    )}
                </div>
              ) : (
                showData?.map((item) => {
                  return item.notificationType !== 'document' ? (
                    <li
                      className={`${currentID === item._id ? 'active' : ''}${
                        ['general'].includes(item.typeOfNotification) &&
                        !item.isRead
                          ? ' unread'
                          : ''
                      }${
                        ['milestone'].includes(item.typeOfNotification) &&
                        !item.isRead
                          ? ' unread'
                          : ''
                      }${
                        ['actionable'].includes(item.typeOfNotification)
                          ? ' unread'
                          : ''
                      }${
                        ['approval'].includes(item.typeOfNotification) &&
                        !item.isRead
                          ? ' unread'
                          : ''
                      }`}
                      role="presentation"
                      onClick={toggleSelf}
                      id={item._id}
                      onKeyUp={toggleSelf}
                    >
                      <div className="notification-item">
                        <span className="time">{item.diff}</span>
                        <div className="user-image">
                          {/* {item.senderOrgLogo !== '' ? (
                              <img
                                src={item.senderOrgLogo}
                                alt={item.senderOrgName}
                              />
                            ) : (
                              <span className="image sn">
                                {getInitials(item.senderOrgName)}
                              </span>
                            )} */}
                          <UserImage
                            path={item.senderOrgLogo}
                            shortName={getInitials(item.senderOrgName)}
                            size="medium"
                          />
                        </div>
                        <div
                          className={`details ${
                            item.typeOfNotification !== 'approval' &&
                            item.typeOfNotification !== 'actionable'
                              ? 'no-action'
                              : ''
                          }`}
                        >
                          <p>
                            <span className="name">{item.senderOrgName}</span>
                          </p>
                          <p>
                            {ReactHtmlParser(getTextByCond(item.text, item))}
                            {/* {item.text.replace('Accepted', '') ||
                                  item.text.replace('Rejected', '')}{' '}
                                {item.text.split(' ').includes('Accepted') ? (
                                  <span className="text-success">Accepted</span>
                                ) : (
                                  ''
                                )}
                                {item.text.split(' ').includes('Rejected') ? (
                                  <span className="text-danger">Rejected</span>
                                ) : (
                                  ''
                                )}{' '} */}{' '}
                            {t('notifications.By')}{' '}
                            <span className="name">
                              {item.senderName}
                              {/* in{' '} */}
                            </span>{' '}
                            {/* <span className="text-capitalize">
                                  {item?.type}{' '}
                                </span>
                                <a
                                  className="link"
                                  href={`${hostUrl}/${item.link}`}
                                >
                                  {item?.relationNo && item.relationNo}
                                </a>{' '} */}
                            {item.changes?.changes?.length > 0 &&
                              !(currentID === item._id) && (
                                <span
                                  className="id"
                                  onClick={toggleSelf}
                                  role="presentation"
                                  id={item._id}
                                >
                                  +{item.changes?.changes?.length}{' '}
                                  {t('notifications.More')}{' '}
                                  {item.changes?.changes?.length > 1
                                    ? t('notifications.updates')
                                    : t('notifications.update')}
                                </span>
                              )}
                          </p>
                          {item?.changes?.reason && currentID === item._id && (
                            <p>
                              {t('notifications.reason')}
                              {': '}
                              {item?.changes?.reason}
                            </p>
                          )}
                        </div>

                        {item.typeOfNotification === 'approval' && (
                          <ActionDialog
                            type={item.type}
                            acceptYesClick={() =>
                              saveAcceptRejectNotification(
                                'Accepted',
                                item.itemIds,
                                item.relationId,
                                item.type
                              )
                            }
                            t={t}
                            rejectYesClick={() =>
                              saveAcceptRejectNotification(
                                'Rejected',
                                item.itemIds,
                                item.relationId,
                                item.type
                              )
                            }
                          />
                        )}
                        {item.typeOfNotification === 'actionable' &&
                          item?.action?.value !== '' && (
                            <div className="action">
                              <a
                                href={`${hostUrl}/${item.link}?popover=${item.action.value}`}
                              >
                                {item?.action?.value === 'Add Booking' && (
                                  <Tooltip
                                    content={item?.action?.key}
                                    direction="bottom"
                                  >
                                    <CreateBookingIcon />
                                  </Tooltip>
                                )}
                                {item?.action?.value === 'Create Shipment' && (
                                  <Tooltip
                                    content={item?.action?.key}
                                    direction="bottom"
                                  >
                                    <CreateShipmentIcon />
                                  </Tooltip>
                                )}
                                {item?.action?.value === 'Add Quotation' && (
                                  <Tooltip
                                    content={item?.action?.key}
                                    direction="bottom"
                                  >
                                    <AddQuotationIcon />
                                  </Tooltip>
                                )}
                              </a>
                            </div>
                          )}
                      </div>
                      {/* Childs */}
                      {item.changes?.changes?.length > 0 &&
                        currentID === item._id &&
                        item?.changes?.changes?.map((ele) => {
                          let prevData;
                          let upData;
                          let content;
                          if (!ele?.changes) {
                            prevData = ele.previousData;
                            upData = ele.updatedData;

                            if (ele.parameter.match(/date/gi)) {
                              const userTimeZone =
                                store.getState().omniProfile.profileDetail
                                  ?.fetchProfileDetails?.data?.timezone;
                              prevData = moment
                                .tz(ele.previousData, userTimeZone)
                                .format('DD/MM/YYYY');
                              upData = moment
                                .tz(ele.updatedData, userTimeZone)
                                .format('DD/MM/YYYY');
                            }

                            content = `${item.text}
                                    <span className="text-capitalize">
                                    ${t(`notifications.${ele.parameter}`)}
                                    </span>
                                    <strong>${prevData}</strong> ${t(
                              'notifications.to'
                            )}
                                    <strong>${upData}</strong>`;
                          }
                          if (ele?.changes) {
                            const desc = [];
                            ele.changes.forEach((descEle) => {
                              prevData = descEle.previousData;
                              upData = descEle.updatedData;

                              if (descEle.parameter.match(/date/gi)) {
                                const userTimeZone =
                                  store.getState().omniProfile.profileDetail
                                    ?.fetchProfileDetails?.data?.timezone;
                                prevData = moment
                                  .tz(descEle.previousData, userTimeZone)
                                  .format('DD/MM/YYYY');
                                upData = moment
                                  .tz(descEle.updatedData, userTimeZone)
                                  .format('DD/MM/YYYY');
                              }

                              desc.push(`<span className="text-capitalize">
                                ${t(`notifications.${descEle.parameter}`)} ${t(
                                'notifications.ChangeRequested'
                              )} ${t('notifications.from')}
                              </span>
                              <strong>${prevData}</strong> ${t(
                                'notifications.to'
                              )}
                              <strong>${upData}</strong>`);
                            });
                            content = [...desc];
                          }

                          return (
                            <div
                              className={
                                (item.isRead
                                  ? 'notification-item'
                                  : 'notification-item unread') ||
                                ([
                                  'notification-item actionable',
                                  ' notification-itemapproval',
                                ].includes(item.typeOfNotification)
                                  ? 'notification-item unread'
                                  : '')
                              }
                            >
                              {/* <span className="time">{item.diff}</span> */}
                              <div className="user-image">
                                <UserImage
                                  path={item.senderOrgLogo}
                                  shortName={getInitials(item.senderOrgName)}
                                  size="medium"
                                />
                              </div>
                              <div className="details">
                                <p>
                                  <span className="name">
                                    <a
                                      className="link"
                                      href={`${hostUrl}/${item.link}`}
                                    >
                                      {ele?.itemCode ? (
                                        <>#{ele.itemCode}</>
                                      ) : (
                                        item?.relationNo && item.relationNo
                                      )}
                                    </a>
                                    {ele?.itemCode && (
                                      <span>
                                        {' '}
                                        {t('notifications.ChangeRequested')}
                                      </span>
                                    )}
                                  </span>
                                  <p>{ReactHtmlParser(content)}</p>
                                </p>
                              </div>
                            </div>
                          );
                        })}
                      {item?.changes?.changes &&
                        currentID === item._id &&
                        toggleSelfItem && (
                          <button
                            type="button"
                            className="show-less"
                            onClick={closeSelf}
                          >
                            {t('notifications.seeLess')}
                          </button>
                        )}
                    </li>
                  ) : (
                    <DocumentItem data={item} t={t} />
                  );
                })
              )}

              {isFetching && (
                <li>
                  <div className="notification-item loader">
                    <span className="time">
                      <Skeleton
                        skHeight="0.556vw"
                        skWidth="3.472vw"
                        skMargin="0.417vw 0 0"
                      />
                    </span>
                    <div className="user-image">
                      <Skeleton
                        skHeight="2.639vw"
                        skWidth="2.639vw"
                        skRadius="50%"
                        skMargin="0 0 0.694vw"
                      />
                    </div>
                    <div className="details">
                      <p>
                        <Skeleton
                          skHeight="0.833vw"
                          skWidth="13.889vw"
                          skMargin="0 0 0.278vw"
                          skRadius="0.694vw"
                        />
                        <Skeleton
                          skHeight="0.833vw"
                          skWidth="10.417vw"
                          skRadius="0.694vw"
                        />
                      </p>
                      <Skeleton
                        skHeight="0.833vw"
                        skWidth="17.361vw"
                        skRadius="0.694vw"
                      />
                    </div>
                  </div>
                </li>
              )}
              {!notificationPage.available &&
              value !== 'actionable' &&
              value !== 'approval' &&
              value !== 'document' ? (
                <li>
                  <div className="notification-item text-center align-center">
                    <p className="mb-0">No new notifications available</p>
                  </div>
                </li>
              ) : null}
            </ul>
          ) : (
            showLoader && (
              <ul className="notification-list">
                {[...Array(7)]?.map(() => {
                  return (
                    <li>
                      <div className="notification-item loader">
                        <span className="time">
                          <Skeleton
                            skHeight="0.556vw"
                            skWidth="3.472vw"
                            skMargin="0.417vw 0 0"
                          />
                        </span>
                        <div className="user-image">
                          <Skeleton
                            skHeight="2.639vw"
                            skWidth="2.639vw"
                            skRadius="50%"
                            skMargin="0 0 0.694vw"
                          />
                        </div>
                        <div className="details">
                          <p>
                            <Skeleton
                              skHeight="0.833vw"
                              skWidth="13.889vw"
                              skMargin="0 0 0.278vw"
                              skRadius="0.694vw"
                            />
                            <Skeleton
                              skHeight="0.833vw"
                              skWidth="10.417vw"
                              skRadius="0.694vw"
                            />
                          </p>
                          <Skeleton
                            skHeight="0.833vw"
                            skWidth="17.361vw"
                            skRadius="0.694vw"
                          />
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ul>
            )
          )}
          {value === 'poChange' &&
            (showData.length > 0 ? (
              <PoChangesGroup data={showData} translate={t} />
            ) : (
              <NoData
                image={<POChangesNoData Width="100%" Height="13.889vw" />}
              />
            ))}
          {value === 'chat' &&
            (showData.length > 0 ? (
              <MentionsMessages data={showData} t={t} />
            ) : (
              <NoData image={<ChatNoData Width="100%" Height="13.889vw" />} />
            ))}
        </div>
      </div>
    </OutsideClick>
  );
}

function mapStateToProps(state) {
  return {
    toggleState: state.omniState.toggleState,
  };
}

NotificationList.propTypes = {
  closeNotification: PropTypes.func,
  toggleState: PropTypes.string,
  value: PropTypes.string,
  show: PropTypes.bool,
};
NotificationList.defaultProps = {
  closeNotification: () => {},
  value: '',
  toggleState: '',
  show: false,
};

export default connect(mapStateToProps, null)(NotificationList);
