import React, { useEffect, useState } from 'react';
import './contact-list.scss';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useMutation, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  CONTACT_LIST,
  MAP_ROLE_USER,
} from '../../../graphQL/rolesAndPermissions';
import BesicTable from '../../../Components/table/basicTable';
import UserImage from '../../../Components/user-image/user-image';
import AdvancePopover from '../../../Components/advance-popover/advance-popover';
import Dialog from '../../../Components/dialog/dialog';
import EditContactPermissions from '../edit-contact-permissions/edit-contact-permissions';
import useDialog from '../../../Components/dialog/useDialog';
import {
  AcceptIcon,
  EditNewIcon,
  KeyOutlineIcon,
  NewShortIcon,
} from '../../../Components/icons/icons';
import DialogPanel from '../../../Components/dialog-panel/dialog-panel';
import DialogPanelBody from '../../../Components/dialog-panel/dialog-panel-body/dialog-panel-body';
import CustomTag from '../../../Components/custom-tag/custom-tag';
import ActionButton from '../../../Components/actions/actions-btn';
import EditContact from '../../my-company/edit-contact/edit-contact';
import AssignUsers from '../assign-users';
// import Button from '../../../Components/button/button';
import RolesSearchBox from '../roles-search-box';
import IconButton from '../../../Components/icon-button/icon-button';
import { giveProfile } from '../../../Components/global-notification/notification-list';
import GlobalLoader from '../../../Components/global-loader/global-loader';
import ListRole from './list-role';
import ListLocation from './list-location';
import { checkProfile } from '../../../Components/contact-item/contact-item';
import getInitials, {
  convertCamelCaseToTitleCase,
} from '../../../helpers/utils';

function Contactlist({ roleId, roleName, organizationId, t2 }) {
  const { relation } = useParams();
  const [t] = useTranslation('company');
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [contactList, setContactList] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [filteredContactList, setFilteredContactList] = useState(contactList);
  const [handleMapRoleUser] = useMutation(MAP_ROLE_USER);

  const { loading, refetch } = useQuery(CONTACT_LIST, {
    variables: {
      input: {
        roleId,
        profileType: giveProfile(relation),
        // "search": null,
      },
    },
    onCompleted: (data) => {
      setContactList(data?.ContactList?.data);
    },
  });

  useEffect(() => {
    if (searchValue) {
      const updatedContactList = contactList?.filter((list) => {
        if (
          list?.firstName
            ?.toLowerCase()
            ?.includes(searchValue?.toLowerCase()) ||
          list?.lastName?.toLowerCase()?.includes(searchValue?.toLowerCase()) ||
          list?.email
            ?.split('@')?.[0]
            ?.toLowerCase()
            ?.includes(searchValue?.toLowerCase())
        ) {
          return list;
        }
        return '';
      });
      setFilteredContactList(updatedContactList);
    } else {
      setFilteredContactList(contactList);
    }
  }, [searchValue, contactList]);

  const mapRoleUserServerCall = async () => {
    const input = {
      action: 'add',
      users: selectedContacts?.map((val) => val?.userSub),
      roleId,
      profileType: giveProfile(relation),
    };
    await handleMapRoleUser({
      variables: {
        input,
      },
      onCompleted: (data) => {
        toast.success(data?.MapRoleUser?.message);
        refetch();
      },
    });
  };

  const columns = [
    {
      Header: t2('systemsettings.roles&permission.activecontactspopup.user'),
      accessor: 'firstName',
      setWidth: '20%',
      Cell: (user) => {
        const {
          cell: { row },
        } = user;
        return (
          <div className="d-flex">
            <UserImage
              // firstName={row?.original?.firstName}
              // lastName={row?.original?.lastName}
              name={`${row?.original?.firstName} ${row?.original?.lastName}`}
              removeTooltip
              shortName={`${getInitials(row?.original?.firstName)}${getInitials(
                row?.original?.lastName
              )}`}
            />
            <div className="pl-8">
              <p className="user-name">
                {row?.original?.firstName} {row?.original?.lastName}
              </p>
              <p className="user-email">{row?.original?.email}</p>
            </div>
          </div>
        );
      },
    },
    {
      Header: t2(
        'systemsettings.roles&permission.activecontactspopup.usertype'
      ),
      accessor: 'type',
      setWidth: '10%',
      Cell: (type) => {
        const {
          cell: { row },
        } = type;

        return (
          <CustomTag
            text={
              row?.original?.userType?.slice(1) &&
              convertCamelCaseToTitleCase(row?.original?.userType?.slice(1))
            }
          />
        );
      },
    },
    {
      Header: t2('systemsettings.roles&permission.activecontactspopup.role'),
      accessor: 'role',
      setWidth: '18%',
      Cell: (role) => {
        const {
          cell: { row },
        } = role;
        const [eventValue, setEventValue] = useState();
        const toggleLocationPopover = (e) => {
          setEventValue(e?.target);
        };
        const handleClose = () => {
          setEventValue(null);
        };
        return (
          <div className="location-list">
            <p>{row?.original?.role?.roleName}</p>

            {row?.original?.role?.roleLength > 1 && (
              <button
                type="button"
                onClick={toggleLocationPopover}
                id="moreLocationPopover"
                className="count"
              >
                +
                {row?.original?.role?.roleLength &&
                  row.original.role.roleLength - 1}
              </button>
            )}
            <AdvancePopover
              reference="moreLocationPopover"
              getEvent={eventValue}
              closePopover={handleClose}
              placement="left"
              closeOnclick
            >
              <ListRole
                roleId={row?.original?.role?.roleId}
                userSub={row?.original?.userSub}
              />
            </AdvancePopover>
          </div>
        );
      },
    },
    {
      Header: t2(
        'systemsettings.roles&permission.activecontactspopup.location'
      ),
      accessor: 'location',
      setWidth: '18%',
      Cell: (role) => {
        const {
          cell: { row },
        } = role;
        const [eventValue, setEventValue] = useState();
        const toggleLocationPopover = (e) => {
          setEventValue(e.target);
        };
        const handleClose = () => {
          setEventValue(null);
        };
        return (
          <div className="location-list">
            <p>{row?.original?.locations?.locationName}</p>
            {row?.original?.locations?.locationChildren?.length > 0 && (
              <>
                <button
                  type="button"
                  onClick={toggleLocationPopover}
                  id="moreLocationPopove"
                  className="count"
                >
                  +
                  {row?.original?.locations?.locationLength &&
                    row?.original.locations.locationLength}
                </button>
                <AdvancePopover
                  reference="moreLocationPopove"
                  getEvent={eventValue}
                  closePopover={handleClose}
                  placement="left"
                  closeOnclick
                >
                  <ListLocation
                    locationChildren={
                      row?.original?.locations?.locationChildren
                    }
                  />
                </AdvancePopover>
              </>
            )}
          </div>
        );
      },
    },
    {
      Header: t2(
        'systemsettings.roles&permission.activecontactspopup.actions.title'
      ),
      align: 'left',
      accessor: 'actions',
      setWidth: '30%',
      Cell: (role) => {
        const {
          cell: { row },
        } = role;
        // const { isShowing: isDeleteDislog, toggle: deleteToggle } = useDialog();
        const { isShowing: Rename, toggle: renameToggle } = useDialog();
        const { isShowing: isActivateDislog, toggle: activateContact } =
          useDialog();
        return (
          <div className="d-flex">
            <ActionButton
              className="btn-pre"
              onClick={activateContact}
              disabled={!row?.original?.action?.permission}
            >
              <KeyOutlineIcon color="#0088FA" />
              {t2(
                'systemsettings.roles&permission.activecontactspopup.actions.permissionsbtn.title'
              )}
            </ActionButton>
            <Dialog
              isShowing={isActivateDislog}
              placement="center"
              hide={activateContact}
              maskZindex="99999"
            >
              <DialogPanel
                title={t2(
                  'systemsettings.roles&permission.activecontactspopup.actions.permissionsbtn.contactpermissions'
                )}
              >
                <DialogPanelBody>
                  <EditContactPermissions
                    userName={`${row?.original?.firstName} ${row?.original?.lastName}`}
                    email={row?.original?.email}
                    roleName={row?.original?.role?.roleName}
                    location={row?.original?.locations?.locationName}
                    roleLength={row?.original?.role?.roleLength}
                    orgId={organizationId}
                    userSub={row?.original?.userSub}
                    userType={row?.original?.userType}
                    roleId={row?.original?.role?.roleId}
                    t={t2}
                  />
                </DialogPanelBody>
              </DialogPanel>
            </Dialog>

            <ActionButton
              className="btn-temp2 edit"
              onClick={renameToggle}
              disabled={!row?.original?.action?.edit}
            >
              <EditNewIcon />
              {t2(
                'systemsettings.roles&permission.activecontactspopup.actions.editbtn.title'
              )}
            </ActionButton>
            <Dialog
              isShowing={Rename}
              placement="center"
              hide={renameToggle}
              maskZindex="99999"
            >
              <EditContact
                SubmitText={t2(
                  'systemsettings.roles&permission.activecontactspopup.actions.editbtn.update'
                )}
                t={t}
                isEditing={true}
                contactDetails={{
                  firstName: row?.original?.firstName,
                  lastName: row?.original?.lastName,
                  email: row?.original?.email,
                  userType: `${
                    row?.original?.userType
                      ? `_${checkProfile(
                          row?.original?.userType.replace('_', '')
                        )}`
                      : ''
                  }`,
                  parentEntityId: [row?.original?.locations?._id],
                }}
                cancelClick={renameToggle}
                submitEdit={refetch}
                // validEmailDomain={['plexvenet.com']}
                roleId={row?.original?.role?.roleId}
                userSub={row?.original?.userSub}
                roleName={row?.original?.role?.roleName}
              />
              {/* <EditContact
                contactDetails={editContactPayload}
                SubmitText="Submit"
                cancelClick={editContact}
                submitEdit={refetchData}
                isEditing={isEditing}
                validEmailDomain={orgDetails?.orgDomains}
                t={t}
              /> */}
            </Dialog>
            {/* Removed the Delete Action currently, may need to revoke later */}
            {/* <ActionButton
              className="btn-temp3"
              onClick={deleteToggle}
              disabled={!row?.original?.action?.remove}
            >
              <NewTrashIcon /> Delete
            </ActionButton>
            <Dialog
              isShowing={isDeleteDislog}
              placement="center"
              hide={deleteToggle}
              maskZindex="99999"
            >
              <Confirmation
                title="Delete this role"
                description="Are you sure you want to Delete this Role?"
                submitButtonText="Yes"
                cancelButtonText="No"
              />
            </Dialog> */}
          </div>
        );
      },
    },
  ];

  return (
    <div className="contact-list-container">
      {!loading && (
        <>
          <div className="d-flex justify-between align-center">
            <h3 className="m-0 mb-20 sub-title-color fs-20 fw-600">
              {roleName}
            </h3>
            <RolesSearchBox
              margin="0 0.694vw 0 0"
              lineHeight="2.222vw"
              width="400px"
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              t={t2}
            />
            {false && (
              <IconButton margin="0 0.694vw 0 0">
                <NewShortIcon />
              </IconButton>
            )}
          </div>
          <div className="d-flex justify-between align-center position-relative">
            <div className="w-100">
              <AssignUsers
                roleId={roleId}
                setSelectedContacts={setSelectedContacts}
                sliceVal={4}
                t={t2}
              />
              <IconButton
                onClick={mapRoleUserServerCall}
                disabled={!selectedContacts?.length}
                className="position-absolute z-2 inline-action"
              >
                <AcceptIcon size="1.25vw" color="#0288FA" />
              </IconButton>
            </div>

            {/* <Button
              styles="icon"
              className="px-10 position-absolute r-0 z-2"
              icon={<AcceptIcon size="1.25vw" color="#0288FA" />}
              onClick={mapRoleUserServerCall}
              disabled={!selectedContacts?.length}
            /> */}
          </div>
          <BesicTable columns={columns} data={filteredContactList} />
        </>
      )}
      {loading && (
        <GlobalLoader newLoad={true} height="6.944vw" width="6.944vw" />
      )}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    organizationId:
      state?.omniDetails?.fetchOrganizationDetail?.fetchOrganizationDetails
        ?.data?.orgId,
  };
}

Contactlist.propTypes = {
  roleId: PropTypes.string,
  roleName: PropTypes.string,
  organizationId: PropTypes.string,
  t2: PropTypes.func,
};

Contactlist.defaultProps = {
  roleId: '',
  roleName: '',
  organizationId: '',
  t2: () => {},
};

export default connect(mapStateToProps, null)(Contactlist);
