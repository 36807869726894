/* eslint-disable react/prop-types */
import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import './dialog.scss';
import { CloseIcon } from '../icons/icons';

function Dialog({
  isShowing,
  hide,
  placement,
  enableOutsideClick,
  enableCloseIcon,
  noSpacing,
  maskZindex,
  maskBG,
  enableBlurBG,
  disabledPointer,
  closeColor,
  closeSize,
  ...props
}) {
  return isShowing
    ? ReactDOM.createPortal(
        <>
          {enableOutsideClick ? (
            <div
              className={`dialog-mask${enableBlurBG ? ' blur' : ''}`}
              role="button"
              onClick={hide}
              tabIndex="0"
              onKeyUp={hide}
              style={{ zIndex: maskZindex, background: maskBG }}
            />
          ) : (
            <div
              className={`dialog-mask${enableBlurBG ? ' blur' : ''}`}
              style={{ zIndex: maskZindex }}
            />
          )}
          {enableOutsideClick ? (
            <div
              className={`dialog-wrapper disable-pointer ${placement} ${
                noSpacing ? 'p-0' : ''
              }`}
              style={{ zIndex: maskZindex + 1 }}
            >
              <div
                className={`dialog-container${
                  !disabledPointer ? ' enable-pointer' : ''
                }`}
              >
                {(!enableOutsideClick || enableCloseIcon) && (
                  <button type="button" className="dialog-close" onClick={hide}>
                    <span>
                      <CloseIcon size={closeSize} color={closeColor} />
                    </span>
                  </button>
                )}
                {props.children}
              </div>
            </div>
          ) : (
            <div
              className={`dialog-wrapper ${placement} ${
                noSpacing ? 'p-0' : ''
              }`}
              style={{ zIndex: maskZindex + 1 }}
            >
              <div className="dialog-container">
                {!enableOutsideClick && (
                  <button type="button" className="dialog-close" onClick={hide}>
                    <span>
                      <CloseIcon size={closeSize} color={closeColor} />
                    </span>
                  </button>
                )}
                {props.children}
              </div>
            </div>
          )}
        </>,
        document.body
      )
    : null;
}

Dialog.propTypes = {
  isShowing: PropTypes.bool.isRequired,
  hide: PropTypes.func.isRequired,
  placement: PropTypes.string,
  enableOutsideClick: PropTypes.bool,
  noSpacing: PropTypes.bool,
  enableCloseIcon: PropTypes.bool,
  maskZindex: PropTypes.number,
  maskBG: PropTypes.string,
  disabledPointer: PropTypes.bool,
  closeColor: PropTypes.string,
  closeSize: PropTypes.string,
};
Dialog.defaultProps = {
  placement: 'center',
  enableOutsideClick: false,
  noSpacing: false,
  enableCloseIcon: false,
  maskZindex: 1040,
  maskBG: '',
  disabledPointer: false,
  closeColor: '',
  closeSize: '1.111vw',
};
export default Dialog;
