import { Component } from 'react';
import PropTypes from 'prop-types';
// import { logout } from '../../service/authService';

export default class GlobalErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error) {
    if (error) {
      console.log(error);
      this.setState({ hasError: true });
    }
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;
    if (hasError) {
      // logout();
    }
    return children;
  }
}

GlobalErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};
