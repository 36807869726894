import React from 'react';
import { DashboardCardOrderIcon } from '../../../Components/icons/icons';

const DashboardData = (dynamicadata) => {
  const topdata = [];
  const bottomData = [];
  let totalOrders = 0;
  if (dynamicadata) {
    if (dynamicadata) {
      dynamicadata?.dashboardWidgets?.order?.smallStatusWidget?.forEach(
        (item) => {
          const progress = item[Object.keys(item)[0]]?.progress;
          const value = item[Object.keys(item)[0]]?.currentValue;
          totalOrders += item[Object.keys(item)[0]]?.currentValue || 0;
          //  const key = item[Object.keys(item)[0]]?.cardValue || '';
          const defaultStatus = item[Object.keys(item)[0]]?.defaultStatus || 0;
          topdata.push({
            label: Object.keys(item)[0],
            icon: <DashboardCardOrderIcon />,
            progress,
            value,
            widgetKey: item[Object.keys(item)[0]]?.widget,
            defaultStatus,
            detailsData: {},
            widget: item[Object.keys(item)[0]]?.key,
          });
        }
      );
      const middleWidget =
        dynamicadata?.dashboardWidgets?.order?.mediumWidget || [];
      const detailsData = {};

      middleWidget?.forEach((element) => {
        const newData = { ...element, ...detailsData };
        bottomData.push(newData);
      });
    }
  }

  return [
    {
      topData: topdata,
      bottomData,
      totalOrders,
    },
  ];
};
export default DashboardData;
