import axios from 'axios';
import Cookies from 'universal-cookie';
import jwtDecode from 'jwt-decode';
import http, { host } from './APIUtil';

export const login = async (credentials) => {
  const response = await http.post(`${host}signin`, credentials);
  localStorage.setItem(
    'AccessToken',
    response.data.data.AuthenticationResult.AccessToken
  );
  localStorage.setItem(
    'IdToken',
    response.data.data.AuthenticationResult.IdToken
  );
  localStorage.setItem(
    'RefreshToken',
    response.data.data.AuthenticationResult.RefreshToken
  );
  window.location = 'app/dashboard';
};

export const signUp = async (credentials) => {
  await http.post(`${host}signup`, credentials);
  window.location = '/dashboard';
};

export const forgotpassword = async (credentials) => {
  await http.post(`${host}forgotPassword`, credentials);
  // window.location = '/dashboard';
};

export const passwordVerify = async (credentials) => {
  await http.post(`${host}forgotPasswordVerify`, credentials);
  window.location = '/dashboard';
};

export const getUser = () => jwtDecode(localStorage.getItem('IdToken'));

export const logout = () => {
  const cookie = new Cookies();
  localStorage.removeItem('AccessToken');
  localStorage.removeItem('IdToken');
  localStorage.removeItem('POStatusList');
  window.localStorage.removeItem('RefreshToken');
  window.localStorage.removeItem('channel');
  cookie.remove('access_token', { domain: '.quloi.com' });
  cookie.remove('refresh_token', { domain: '.quloi.com' });
  cookie.remove('id_token', { domain: '.quloi.com' });
  window.location = '/login';
};

export const activeOrgId = () => localStorage.getItem('activeOrg');

export const getToken = () => localStorage.getItem('AccessToken');

export const getRefreshToken = () => localStorage.getItem('RefreshToken');

export const getIDToken = () => localStorage.getItem('IdToken');

export const getIDBasicToken = () => process.env.REACT_APP_GRAPHQL_BASIC_TOKEN;

export const tokenGeneration = async () => {
  await axios
    .post(
      `${host}refreshToken`,
      { refreshToken: getRefreshToken() },
      {
        auth: {
          username: process.env.REACT_APP_BASIC_USERNAME_AUTH,
          password: process.env.REACT_APP_BASIC_PASSWORD_AUTH,
        },
      }
    )
    .then((response) => {
      localStorage.setItem(
        'AccessToken',
        response.data.data.AuthenticationResult.AccessToken
      );
      localStorage.setItem(
        'IdToken',
        response.data.data.AuthenticationResult.IdToken
      );
      window.location.reload();
    })
    .catch(() => {
      logout();
    });
};

http.setTokenInHeader(getIDToken());
