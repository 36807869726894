import React from 'react';
import PropTypes from 'prop-types';
import {
  AirModeIcon,
  FclModeIcon,
  FtlModeIcon,
  LclModeIcon,
  LtlModeIcon,
  OceanModeIcon,
  PurpleDomesticTruckIcon,
  InlandPrimaryIcon,
} from '../icons/icons';
import Tooltip from '../tooltip/tooltip';
import './shipment-mode.scss';

/**
 * @typedef {'left' | 'center' | 'right'} AlignType - Size choices for the Demo component.
 */

/**
 * Component to demonstrate different sizes, appearances, and popup options.
 * @param {Object} props
 * @param {AlignType} props.align
 * @returns {JSX.Element}
 */

export default function ShipmentMode({
  shipmentMode,
  multipleMode,
  endShipment,
  startShipment,
  startShipmentMode,
  endShipmentMode,
  bookingType,
  align,
}) {
  const alignProperties = {
    left: 'left',
    center: 'center',
    right: 'right',
  };
  const alignProps = alignProperties[align];
  return (
    <div className={`mode-icon${` ${alignProps}`}`}>
      {!multipleMode ? (
        <Tooltip content={`${shipmentMode} ${bookingType}`} direction="right">
          {/* <Tooltip content={`${shipmentMode}`} direction="right"> */}
          {shipmentMode === 'Ocean' && <OceanModeIcon size="2.778vw" />}
          {shipmentMode === 'Air' && <AirModeIcon size="2.778vw" />}
          {shipmentMode === 'FCL' && <FclModeIcon size="2.778vw" />}
          {shipmentMode === 'FTL' && <FtlModeIcon size="2.778vw" />}
          {shipmentMode === 'LTL' && <LtlModeIcon size="2.778vw" />}
          {shipmentMode === 'LCL' && <LclModeIcon size="2.778vw" />}
          {/* {shipmentMode === 'Inland' && <LtlModeIcon size="30" />} */}
          {shipmentMode === 'Inland' && bookingType === 'Pickup' && (
            <LtlModeIcon size="2.778vw" />
          )}
          {shipmentMode === 'Inland' && bookingType === 'Delivery' && (
            <PurpleDomesticTruckIcon size="2.778vw" />
          )}
          {shipmentMode === 'Inland' && bookingType === 'Primary' && (
            <InlandPrimaryIcon size="2.778vw" />
          )}
        </Tooltip>
      ) : (
        <div className="multiple-mode">
          {startShipment && (
            <Tooltip direction="top" content={`By ${startShipmentMode}`}>
              <span className="mode-block">
                <span className="mode-text">{startShipment}</span>
                <span className="icon">
                  {startShipmentMode === 'Ocean' && <OceanModeIcon />}
                  {startShipmentMode === 'Air' && <AirModeIcon />}
                  {startShipmentMode === 'FCL' && <FclModeIcon />}
                  {startShipmentMode === 'FTL' && <FtlModeIcon />}
                  {(startShipmentMode === 'LTL' ||
                    startShipmentMode === 'Inland') && <LtlModeIcon />}
                  {startShipmentMode === 'LCL' && <LclModeIcon />}
                </span>
              </span>
            </Tooltip>
          )}
          {endShipment && (
            <Tooltip direction="top" content={`By ${endShipmentMode}`}>
              <span className="mode-block">
                <span className="mode-text">{endShipment}</span>
                <span className="icon">
                  {endShipmentMode === 'Ocean' && <OceanModeIcon />}
                  {endShipmentMode === 'Air' && <AirModeIcon />}
                  {endShipmentMode === 'FCL' && <FclModeIcon />}
                  {endShipmentMode === 'FTL' && <FtlModeIcon />}
                  {(endShipmentMode === 'LTL' ||
                    endShipmentMode === 'Inland') && <LtlModeIcon />}
                  {endShipmentMode === 'LCL' && <LclModeIcon />}
                </span>
              </span>
            </Tooltip>
          )}
        </div>
      )}
    </div>
  );
}
ShipmentMode.propTypes = {
  shipmentMode: PropTypes.string,
  multipleMode: PropTypes.bool,
  endShipment: PropTypes.string,
  startShipment: PropTypes.string,
  startShipmentMode: PropTypes.string,
  endShipmentMode: PropTypes.string,
  bookingType: PropTypes.string,
  align: PropTypes.string,
};
ShipmentMode.defaultProps = {
  shipmentMode: '',
  multipleMode: false,
  endShipment: '',
  startShipment: '',
  startShipmentMode: '',
  endShipmentMode: '',
  bookingType: '',
  align: '',
};
