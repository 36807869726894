import { gql } from '@apollo/client';

export const QUERY_FETCH_BOOKING_LIST = gql`
  query FetchBookingList($input: BookingListInput) {
    fetchBookingList(input: $input) {
      success
      data {
        packages {
          lineItems {
            itemName
            itemCode
            dueDate
            _id
            poID
            orderId
            poNumber
            cargoReadyDate
            statusName
          }
          _id
        }
        buyerId
        bookingNo
        supplierId
        createdBy
        bookingDate
        poNumber
        mode
        poId
        orderID
        ShipperName
        ConsigneeName
        createdAt
        originFacility
        destinationFacility
        currentStatus
        BookingId
        bookingAction
        rateQoute
        bookingOwner
        bookingType
        _id
        referenceId
        uniqueUserIds {
          email
          profilePicture
          firstName
          lastName
          userSub
          userType
          organizationId
        }
      }
      message
      totalCount
      statusCount {
        _id
        _idTranslated
        count
        subStatus
        color
        filteredText
      }
    }
  }
`;

export const QUERY_FETCH_BOOKING_LIST2 = gql`
  query bookingList($input: BookingListInput) {
    bookingList(input: $input) {
      success
      data
      message
      totalCount
    }
  }
`;

export const QUERY_FETCH_BOOKING_STATUS = gql`
  query bookingStatus($input: BookingListInput) {
    bookingStatus(input: $input) {
      success
      data
      message
      totalCount
    }
  }
`;

export const QUERY_FETCH_BOOKING_PARTICIPATION_LIST = gql`
  query Query {
    fetchListofParticipants {
      username
      userid
      orgID
    }
  }
`;

export const MUTATION_BOOKING_ACCEPT_REJECT = gql`
  mutation SaveBooking($input: BookingAcceptRejectInput) {
    bookingAcceptReject(input: $input) {
      message
    }
  }
`;

export const DELETE_BOOKINGS = gql`
  mutation DeleteBookings($input: deleteInput) {
    DeleteBookings(input: $input)
  }
`;

export const VIEW_QUOTATION_LIST = gql`
  query ViewQuotations($input: quotationDetailsInput) {
    ViewQuotations(input: $input) {
      success
      data
      message
    }
  }
`;
