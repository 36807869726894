import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import UserImage from '../../user-image/user-image';
import ReceivedMessage from './received-message';
import './reply-message.scss';
import { getChatMessageDate } from '../../../helpers/dateHelper';

function ReplyMessageReceived({ message, reply }) {
  const [t] = useTranslation('manageorders');
  const [popover, setPopover] = useState();
  const togglePopover = () => {
    setPopover(!popover);
  };
  const handleInitialUserName = (str) => {
    let name = str
      ?.split(' ')
      ?.map((word) => word[0])
      ?.join('');
    name = name?.substr(0, 2);
    return name?.toUpperCase();
  };
  return (
    <div className="chat-message-container reply">
      <div className="user">
        <UserImage
          path={message.profilePicture}
          name={message.fullName}
          shortName={handleInitialUserName(message?.fullName)}
        />
      </div>
      <div className="message-reply-box">
        <div className="chat-message">
          <div className="msg-top">
            <span className="name">{message.fullName}</span>
            <span className="date">
              {getChatMessageDate(message.createdAt)}
            </span>
            <button
              type="button"
              name="togglePopover"
              className="message-action"
              onClick={togglePopover}
            >
              <svg
                width="10"
                height="4"
                viewBox="0 0 18 4"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.5 0.5C0.675 0.5 0 1.175 0 2C0 2.825 0.675 3.5 1.5 3.5C2.325 3.5 3 2.825 3 2C3 1.175 2.325 0.5 1.5 0.5ZM16.5 0.5C15.675 0.5 15 1.175 15 2C15 2.825 15.675 3.5 16.5 3.5C17.325 3.5 18 2.825 18 2C18 1.175 17.325 0.5 16.5 0.5ZM9 0.5C8.175 0.5 7.5 1.175 7.5 2C7.5 2.825 8.175 3.5 9 3.5C9.825 3.5 10.5 2.825 10.5 2C10.5 1.175 9.825 0.5 9 0.5Z"
                  fill="#333333"
                />
              </svg>
            </button>
            {popover && (
              <>
                <div
                  className="popover-mask"
                  onClick={() => togglePopover()}
                  onKeyDown={() => togglePopover()}
                  tabIndex="0"
                  role="button"
                >
                  &nbsp;
                </div>
                <div className="action-popover sender">
                  <ul>
                    <li>
                      <button type="button" onClick={() => reply(message)}>
                        {t('manageordersscreen.chatmenu.reply')}
                      </button>
                    </li>
                  </ul>
                </div>
              </>
            )}
          </div>

          <ReceivedMessage messageData={message?.replyFor} />
          <p>{ReactHtmlParser(message.messageContent)}</p>
        </div>
      </div>
    </div>
  );
}

ReplyMessageReceived.propTypes = {
  message: PropTypes.instanceOf(Object),
  reply: PropTypes.func.isRequired,
};
ReplyMessageReceived.defaultProps = {
  message: {},
};

export default ReplyMessageReceived;
