import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import './booking.scss';
import Button from '../button/button';
import BesicTable from '../table/basicTable';
import { BagTickIcon } from '../icons/icons';
import DialogPanel from '../dialog-panel/dialog-panel';
import DialogPanelBody from '../dialog-panel/dialog-panel-body/dialog-panel-body';
import AccordionPanel from '../accordion-panel/accordion-panel';
import CommentSectionAccordion from '../comment-section/comment-section-accordion';

function ViewBooking({ columns, itemsData }) {
  const [t] = useTranslation('manageorders');
  return (
    <div className="supplier-booking-container">
      <DialogPanel
        title={t('manageordersscreen.bookingdetailsbtn.title')}
        large="booking-details-title"
      >
        <DialogPanelBody>
          <div className="custom-tabs supplier-booking-wrapper">
            <div className="supplier-booking-content">
              <div className="d-flex justify-between align-center">
                <h3>{t('manageordersscreen.bookingdetailsbtn.description')}</h3>
                {false && (
                  <Button
                    styles="primary"
                    color="outline"
                    label="Load Calculator"
                    iconRight={<BagTickIcon />}
                    spacing="0.208vw"
                    size="medium"
                  />
                )}
              </div>
              <AccordionPanel
                isOpen="true"
                title={t('manageordersscreen.bookingdetailsbtn.addpackage')}
              >
                <BesicTable
                  columns={columns}
                  data={itemsData}
                  className="supplier-booking"
                />
              </AccordionPanel>
              {false && (
                <CommentSectionAccordion
                  title={t('manageordersscreen.bookingdetailsbtn.packagenotes')}
                />
              )}
            </div>
          </div>
        </DialogPanelBody>
      </DialogPanel>
    </div>
  );
}

export default ViewBooking;
ViewBooking.propTypes = {
  itemsData: PropTypes.arrayOf(PropTypes.string),
  columns: PropTypes.arrayOf(PropTypes.string),
};
ViewBooking.defaultProps = {
  itemsData: [],
  columns: [],
};
