import React from 'react';
import PropTypes from 'prop-types';
import './account-settings-panel.scss';

function AccountSettingsPanelTitle({ ...props }) {
  return <h3 className="title">{props.children}</h3>;
}

export default AccountSettingsPanelTitle;
AccountSettingsPanelTitle.propTypes = {
  children: PropTypes.node,
};
AccountSettingsPanelTitle.defaultProps = {
  children: {},
};
