import React, { useState, useEffect } from 'react';
import './syntax-highlighter.scss';
import Prism from 'prismjs';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Copiedbtn, Copybtn } from '../icons/icons';

function SyntaxHighlighter({ title, codeStringProp }) {
  const [copy, setCopy] = useState(false);
  const [t] = useTranslation('accountsettings');

  useEffect(() => {
    Prism.highlightAll();
  }, [codeStringProp]);

  function handlecopy() {
    navigator.clipboard.writeText(codeStringProp);
    setCopy(true);
    setTimeout(() => {
      setCopy(false);
    }, 3000);
  }
  return (
    <div className="code-block-container">
      <div className="code-container-upper">
        <div className="code-container-title">{title}</div>
        {copy ? (
          <button type="button" className="syntax-highlighter-copy-btn">
            <Copiedbtn />
            {t('accountsettings.webhookintegrations.copied')}
          </button>
        ) : (
          <button
            type="button"
            className="syntax-highlighter-copy-btn"
            onClick={handlecopy}
          >
            <Copybtn />
            {t('accountsettings.webhookintegrations.copycode')}
          </button>
        )}
      </div>
      <div className="codeblock">
        <pre>
          <code className="language-javascript">{codeStringProp}</code>
        </pre>
      </div>
    </div>
  );
}

SyntaxHighlighter.propTypes = {
  title: PropTypes.string,
  codeStringProp: PropTypes.objectOf,
};

SyntaxHighlighter.defaultProps = {
  title: '',
  codeStringProp: '',
};

export default SyntaxHighlighter;
