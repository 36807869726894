import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import './error.scss';
import Logo from '../../assets/image/logo.png';
import ErrorIcon from '../../assets/image/error.svg';

export default function ErrorPage({ title, buttonText, link }) {
  return (
    <div className="error-container">
      <div className="error">
        <div className="error-wrap">
          <div className="co-logo">
            <img src={Logo} alt="" />
          </div>
          <div className="icon">
            <img src={ErrorIcon} alt="" />
          </div>
          <h3>
            {title !== ''
              ? title
              : 'You are not authorised to visit this page!'}
          </h3>
          <p>
            Please contact at{' '}
            <a href="mailto:admin@quloi.com">admin@quloi.com </a>
          </p>
          <div className="button-block">
            <Link className="action" to={`/app/${link}`}>
              {buttonText}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
ErrorPage.propTypes = {
  title: PropTypes.string,
  buttonText: PropTypes.string,
  link: PropTypes.string,
};
ErrorPage.defaultProps = {
  title: '',
  buttonText: '',
  link: '',
};
