import React from 'react';
import PropTypes from 'prop-types';
import { CloseFillIcon } from '../icons/icons';
import './chip.scss';

function Chip({ name, removeComp }) {
  return (
    <div className="chip-container">
      <span className="text">{name}</span>
      <button className="action" onClick={removeComp} type="button">
        <CloseFillIcon />
      </button>
    </div>
  );
}

Chip.propTypes = {
  name: PropTypes.string,
  removeComp: PropTypes.func,
};

Chip.defaultProps = {
  name: '',
  removeComp: () => {},
};

export default Chip;
