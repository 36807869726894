import React from 'react'; // , { useState }
// import RoundCheckbox from '../custom-checkbox/round-check-box';
// import ReactCheckbox from '../custom-checkbox/react-check-box';
// import { RightDirectionIcon } from '../icons/icons';
// import RoundCheckbox from '../custom-checkbox/round-check-box';

const multiSelectOption = (
  data,
  isFirst,
  checked,
  handleChange,
  ids,
  parentId,
  disabled
  // toggleChild
  // handleToggleChild
) => {
  // const [selfId, setSelfId] = useState();
  // const handleToggleChild = (e) => {
  //   setSelfId(e.target.id);
  // };
  return isFirst ? (
    <ul className="parent">
      {data?.map((item) => (
        <li>
          <div className="item">
            <label>
              <input
                type="checkbox"
                onChange={handleChange}
                checked={ids?.includes(item._id)}
                value={item.children}
                name={item.entityName}
                id={item._id}
                disabled={
                  (ids?.includes(item._id) && item._id !== parentId) || disabled
                }
              />
              <span
                className={`checkbox ${
                  ids?.includes(item._id) ? 'checked' : ''
                } ${
                  (ids?.includes(item._id) && item._id !== parentId) || disabled
                    ? 'disabled'
                    : ''
                }`}
              />
              <span className="value">{item.entityName}</span>
              {/* {item.entityName} */}
            </label>
            {/* <button
              type="button"
              id={item?._id}
              onClick={handleToggleChild}
              value={item.children}
            >
              <RightDirectionIcon color="#3B86F2" />
            </button> */}
          </div>
          {item?.myChildren?.map((child) =>
            multiSelectOption(
              child,
              false,
              checked,
              handleChange,
              ids,
              parentId,
              disabled
              // toggleChild
              // handleToggleChild
            )
          )}
        </li>
      ))}
    </ul>
  ) : (
    // toggleChild?.includes(Number(data._id)) && (
    <ul className="child">
      <li>
        <div className="item">
          <label>
            <input
              type="checkbox"
              checked={ids?.includes(data._id)}
              value={data.children}
              id={data._id}
              name={data.entityName}
              onChange={handleChange}
              disabled={
                (ids?.includes(data._id) && data._id !== parentId) || disabled
              }
            />
            <span
              className={`checkbox ${
                ids?.includes(data._id) ? 'checked' : ''
              } ${
                (ids?.includes(data._id) && data._id !== parentId) || disabled
                  ? 'disabled'
                  : ''
              }`}
            />
            <span className="value">{data.entityName}</span>
          </label>
          {/* {data.myChildren?.length > 0 && (
              <button
                type="button"
                id={data._id}
                onClick={handleToggleChild}
                value={data.children}
                // onClick={toggleSelf}
                // id={data._id}
                // className={currentID === data._id && toggleChild ? 'rotate' : ''}
              >
                <RightDirectionIcon color="#3B86F2" />
              </button>
            )} */}
        </div>
        {data.myChildren?.length > 0 && (
          <ul className="sub-child">
            {data.myChildren.map((subchild) =>
              multiSelectOption(
                subchild,
                false,
                checked,
                handleChange,
                ids,
                parentId,
                disabled
                // toggleChild
                // handleToggleChild
              )
            )}
          </ul>
        )}
      </li>
    </ul>
    // )
  );
  //   </li>
  // </ul>
};

export default multiSelectOption;
