import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import io from 'socket.io-client';
import './message.scss';
import './chat.scss';
import axios from 'axios';
import { useParams, useSearchParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
// import ReactHtmlParser from 'react-html-parser';
import { AcceptIcon, AttachIcon, RejectIcon, SendIcon } from '../icons/icons';
import MessageHeaderLoader from './loader';
import ActivityTimeline from '../activity-timeline/activity-timeline';
import ReceivedMessage from './message/received-message';
import {
  addUserInChat,
  createNewMessage,
  getChatActions,
  getMessages,
} from '../../service/chatAPIHandler';
import { getUser } from '../../service/authService';
import uploadFiles from '../../service/fileService';
import UserGroup from '../user-group/user-group';
import FileItem from '../file-item/file-item';
import UserImage from '../user-image/user-image';
import ChatLoader from '../chat-loader/ChatLoader';
import {
  dateRegex,
  getChatHeaderDate,
  getUserTimeZoneDateFormat,
} from '../../helpers/dateHelper';
import SentMessage from './message/sent-message';
import ReplyMessageReceived from './message/reply-message-received';
import ReplyMessageSend from './message/reply-message-sent';
import ProgressBar from '../progress-bar/progress-bar';
import Tooltip from '../tooltip/tooltip';
import URLConst from '../../service/URLConst';
import RichTextEditor from '../rich-text-editor/rich-text-editor';
import FormattingButton from '../rich-text-editor/toggle-Formating-button';
import CHAT_MEDIA_UPLOAD_IN_PO from '../../graphQL/chatQuery';
import IconButton from '../icon-button/icon-button';
import CustomEditor from '../text-editor/custome-texteditor';
import getInitials from '../../helpers/utils';

const autoEnableChatUserRoles = ['_keyAdmin', '_admin'];
const socket = io(URLConst.host, {
  autoConnect: false,
});
function Chat({
  fullHeight,
  enableTab,
  enableActivity,
  isOnDetailsPage,
  channel,
  fileSetter,
  chatUsers,
  profileDetail,
  userOrgDetails,
  channelSetter,
  bulkActionHandler,
  chatActions,
  refetch,
  allRefetch,
}) {
  const [t] = useTranslation('manageorders');
  const [searchParams, setSearchParams] = useSearchParams();
  const activeTabLabel = searchParams.get('activeTab');
  const [handleUploadMedia] = useMutation(CHAT_MEDIA_UPLOAD_IN_PO);
  const { poId, bookingId, id } = useParams();
  const chatHost = `${URLConst.host}/v1/`;
  const user = getUser();
  let newDate = '';
  let previousMessage = '';
  const isChatParticipant =
    channel?.participants?.includes(user.sub) ||
    autoEnableChatUserRoles.includes(user['custom:userType']);
  const [chatNotification, setChatNotification] = useState();
  const [iscount, setCount] = useState(0);
  const [messageSending, setMessageSending] = useState(false);
  const [loading, setLoading] = useState(false);
  const messagesScrollRef = useRef(null);
  const [chat, setChat] = useState([]);
  const [hide, setHide] = useState(false);
  const [messageText, setMessageText] = useState('');
  const [editorMessageText, setEditorMessageText] = useState('');
  const [defaultTab, setDefaultTab] = useState('chat');
  const [replyMessage, setReplyMessage] = useState(null);
  const [formatting, setFormatting] = useState(false);
  const [chatFiles, setChatFiles] = useState([]);
  const [chatPage, setChatPage] = useState({
    index: 1,
    available: true,
  });

  useEffect(() => {
    setSearchParams(window.location.search);
  }, []);

  const chatNotificationFunc = async () => {
    await axios
      .get(
        `${chatHost}notifications/alllist?page=1&perPage=1&type=${
          poId || bookingId || id
        }`
      )
      .then((res) => setChatNotification(res.data.data[0]))
      .catch(() => {});
  };
  const [chatActivity, setChatActivity] = useState([]);

  const getUserDetailsById = (temp) => {
    return chatUsers?.totalUsers[temp];
  };

  const checkChannelType = (chnid) => {
    let chnType;
    switch (chnid) {
      case poId:
        chnType = 'order';
        break;
      case bookingId:
        chnType = 'booking';
        break;
      case id:
        chnType = 'shipment';
        break;
      default:
        break;
    }
    return chnType;
  };

  const uploadAttachment = (data) => {
    handleUploadMedia({
      variables: {
        input: {
          channelType: checkChannelType(poId || bookingId || id),
          channelId: poId || bookingId || id,
          attachments: data,
        },
      },
      onCompleted: () => {
        if (activeTabLabel === 'Documents') {
          refetch();
        }
      },
    });
  };
  useEffect(() => {
    socket.connect();
    async function requestMessages() {
      try {
        const resp = await getMessages(channel._id, 1);
        setChat(resp.data.data);
      } catch (error) {
        setChat([]);
      }
    }
    async function requestChatActions() {
      const response = await getChatActions(channel._id);
      setChatActivity(response.data.data);
    }
    requestMessages();
    requestChatActions();
    chatNotificationFunc();
    return () => {
      socket.disconnect();
    };
  }, []);

  const handleChatFiles = (e) => {
    setChatFiles([...chatFiles, ...Array.from(e.target.files)]);
  };

  setTimeout(() => {
    setHide(true);
  }, 2000);

  const toggleChat = (e) => {
    setDefaultTab(e.target.id);
  };

  const addUserInChannel = async (sub) => {
    try {
      await addUserInChat(sub, channel?._id);
    } catch (error) {
      toast.warning(error?.response?.message);
    }
  };

  const createMessage = () => {
    if (!messageText || !editorMessageText) {
      if (chatFiles.length !== 0) {
        return 'New file';
      }
    }
    return formatting && editorMessageText !== '<p></p>\n'
      ? editorMessageText
      : messageText;
  };

  const clearMessageState = () => {
    setMessageText('');
    setEditorMessageText('<p></p>\n');
    setChatFiles([]);
    setReplyMessage(null);
    setMessageSending(false);
  };
  const sendMessage = async () => {
    try {
      setMessageSending(true);
      if (
        autoEnableChatUserRoles.includes(user['custom:userType']) &&
        !channel.participants.includes(user.sub)
      ) {
        await addUserInChannel(user.sub);
      }
      const chatMedia = [];
      const attachmentMedia = [];
      if (chatFiles.length > 0) {
        const response = await uploadFiles(chatFiles);
        response.data.data.forEach((item) => {
          attachmentMedia.push({
            type: 'file',
            mimeType: item?.fileType,
            size: item?.fileSize,
            visibility: 'restricted',
            parentId: '',
            originalName: item?.originalFileName,
            path: item?.filePath,
            url: item?.url,
          });
          chatMedia.push({
            type: item.originalFileName.split('.').pop(),
            path: item.url,
            originalFilename: item.originalFileName,
          });
        });
        uploadAttachment(attachmentMedia);
      }
      const payload = {
        channelType: channel.channelType,
        messageContent: createMessage(),
        messageType: 'text',
        toId: replyMessage ? replyMessage._id : '1',
        channelId: channel._id,
        mediaPath: chatMedia,
      };
      await createNewMessage(payload);
      socket.emit(channel._id, payload);
      clearMessageState();
    } catch (error) {
      clearMessageState();
    }
  };

  const handleReply = (data) => {
    setReplyMessage(data);
  };

  const handleRemoveReply = () => {
    setReplyMessage(null);
  };

  useEffect(() => {
    if (messagesScrollRef && messagesScrollRef.current) {
      messagesScrollRef.current.scrollTop =
        messagesScrollRef?.current?.scrollHeight;
    }

    socket.on(channel?._id, (message) => {
      const msg = message;
      if (message.system) {
        allRefetch();
        const usrLng = user['custom:language'];
        if (usrLng !== 'en' && msg?.messageContentLng?.[usrLng]) {
          msg.messageContent = msg?.messageContentLng?.[usrLng];
        }
        if (msg.messageContent) {
          const dateMsg = msg?.messageContent
            ?.split(' ')
            ?.filter((str) => str.match(dateRegex));
          if (dateMsg?.length > 0) {
            dateMsg?.forEach((dDate) => {
              msg.messageContent = msg.messageContent.replace(
                dDate,
                getUserTimeZoneDateFormat(dDate?.split('Date')[1])
              );
            });
          }
        }
      }
      setChat([...chat, msg]);
      if (message.mediaPath.length > 0) {
        fileSetter(
          message.mediaPath.map((item) => {
            return {
              type: item.type,
              path: item.path,
              originalFilename: item.originalFilename,
              createdAt: message.createdAt,
              fromId: message.fromId,
            };
          })
        );
      }
    });

    socket.on(`${channel?._id}-update`, (updatedChannel) => {
      channelSetter(updatedChannel);
    });

    socket.on(`${user.sub}-notification`, (activity) => {
      setChatActivity([...chatActivity, activity]);
      // refetch();
      // Add auto-refetch condition here @Saurav
    });
  });

  const scrollHandler = async (e) => {
    const element = e.target;
    try {
      if (element.scrollTop === 0 && chatPage.available) {
        setLoading(true);
        const resp = await getMessages(channel._id, chatPage.index + 1);
        setChat([...resp.data.data, ...chat]);
        setChatPage({
          ...chatPage,
          index: chatPage.index + 1,
        });
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      if (error.response && error.response.status === 404) {
        setChatPage({
          ...chatPage,
          available: false,
        });
      }
    }
  };

  const getUserForInvite = () => {
    if (userOrgDetails?.orgType?.includes('forwarder')) {
      const forwarderUser = [];
      chatUsers?.notInvited.forEach((userItem) => {
        if (userItem.organizationId === userOrgDetails.orgId) {
          forwarderUser.push(userItem);
        }
      });
      return forwarderUser;
    }
    return chatUsers?.notInvited;
  };

  const handleFileDiscard = ({ file }) => {
    const updatedFiles = [];
    chatFiles.forEach((item) => {
      if (item.name !== file.name && item.size !== file.size) {
        updatedFiles.push(item);
      }
    });
    setChatFiles(updatedFiles);
  };

  return (
    <div className={`message-container ${fullHeight ? 'full-height' : ''}`}>
      {hide === true && (
        <div className="message-header">
          <div
            className={`current-user-block ${isOnDetailsPage ? 'small' : ''}`}
          >
            <UserImage
              path={profileDetail.profilePicture}
              size="small"
              name={`${profileDetail.firstName} ${profileDetail.lastName}`}
              email={profileDetail.email}
              shortName={getInitials(
                `${profileDetail.firstName}
                ${profileDetail.lastName}`
              )}
            />

            <div className="user-details">
              <p className="chat-user-name">
                {profileDetail.firstName} {profileDetail.lastName}
              </p>
              {!isOnDetailsPage && (
                <p className="chat-seen-details">Last seen today at 12:20 pm</p>
              )}
            </div>
          </div>
          {enableTab && (
            <div className={`tab-block ${isOnDetailsPage ? 'small' : ''}`}>
              <ul className="tab-nav">
                <li>
                  <button
                    type="button"
                    onClick={toggleChat}
                    className={defaultTab === 'chat' ? 'active' : ''}
                    id="chat"
                  >
                    {t('manageordersscreen.chatmenu.chat')}
                  </button>
                </li>
                {!enableActivity && (
                  <li>
                    <button
                      type="button"
                      onClick={toggleChat}
                      className={defaultTab === 'files' ? 'active' : ''}
                      id="files"
                    >
                      Files
                    </button>
                  </li>
                )}

                {enableActivity && (
                  <li>
                    <button
                      type="button"
                      onClick={toggleChat}
                      className={defaultTab === 'activity' ? 'active' : ''}
                      id="activity"
                    >
                      {t('manageordersscreen.chatmenu.activity')}
                    </button>
                  </li>
                )}
              </ul>
            </div>
          )}
          <div className="user-group-block">
            <UserGroup
              userData={Object.values(chatUsers?.invited)}
              infoDirection="left"
              size="x-small"
              onAddClick={addUserInChannel}
              eventID="actionPopover"
              enableAdd={getUserForInvite().length !== 0}
              newUserData={getUserForInvite()}
              // removeTooltip
              enableHover
            />
          </div>
        </div>
      )}
      {hide !== true && <MessageHeaderLoader />}
      {defaultTab === 'chat' && (
        <>
          {loading === true && <ChatLoader />}
          <div
            className={`message-body-container ${replyMessage ? 'reply' : ''} ${
              chatFiles.length !== 0 ? 'file' : ''
            }`}
            onScroll={scrollHandler}
            ref={messagesScrollRef}
          >
            {/* {(chatActions?.Accept === true || chatActions?.Reject === true) && ( */}
            {chatNotification && (
              <div className="sticky-button">
                <p>{chatNotification.text}</p>
                <div className="button-area">
                  {chatActions?.Accept === true && (
                    <Tooltip
                      content={t('manageordersscreen.chatmenu.accept')}
                      direction="bottom"
                    >
                      <button
                        type="button"
                        onClick={() => {
                          bulkActionHandler(
                            'Accepted',
                            chatNotification.itemIds
                          );
                        }}
                      >
                        <AcceptIcon color="#1cab00" />
                      </button>
                    </Tooltip>
                  )}
                  {chatActions?.Reject === true && (
                    <Tooltip
                      content={t('manageordersscreen.chatmenu.reject')}
                      direction="bottom"
                    >
                      <button
                        type="button"
                        onClick={() => {
                          bulkActionHandler(
                            'Rejected',
                            chatNotification.itemIds
                          );
                        }}
                      >
                        <RejectIcon color="#ff1111" />
                      </button>
                    </Tooltip>
                  )}
                </div>
              </div>
            )}
            <div className="mesage-body">
              {chat.map((item, index) => {
                const chatUser = getUserDetailsById(item.fromId);
                const replyUser = getUserDetailsById(item?.replyFor?.fromId);
                const itm = item;
                if (itm?.system) {
                  const dateMsg = itm?.messageContent
                    ?.split(' ')
                    ?.filter((str) => str.match(dateRegex));
                  if (dateMsg?.length > 0) {
                    dateMsg?.forEach((dDate) => {
                      itm.messageContent = itm.messageContent.replace(
                        dDate,
                        getUserTimeZoneDateFormat(dDate?.split('Date')[1])
                      );
                    });
                  }
                }
                let message = {
                  ...itm,
                  type: 'System Generated',
                  fullName: 'System Generated',
                };
                if (chatUser) {
                  message = {
                    ...itm,
                    fullName: `${chatUser?.firstName || 'Guest'} ${
                      chatUser?.lastName
                    }`,
                    email: chatUser?.email,
                    profilePicture: chatUser?.profilePicture,
                    ...(item.replyFor && {
                      replyFor: {
                        ...item.replyFor,
                        fullName: `${replyUser?.firstName || 'Guest'} ${
                          replyUser?.lastName
                        }`,
                        email: replyUser?.email,
                      },
                    }),
                  };
                }
                const dayOfMessage = moment(item.createdAt).format('LL');
                if (newDate === dayOfMessage) {
                  newDate = '';
                  previousMessage = dayOfMessage;
                } else if (newDate !== dayOfMessage && newDate !== '') {
                  newDate = dayOfMessage;
                } else if (previousMessage !== dayOfMessage) {
                  newDate = dayOfMessage;
                }
                const scrollToBottom =
                  document.getElementById('scrollToBottom');
                scrollToBottom?.scrollIntoView(false);

                return message.fromId === user.sub ? (
                  <>
                    {newDate !== '' && (
                      <div className="separator">
                        <span>{getChatHeaderDate(message.createdAt)}</span>
                      </div>
                    )}
                    {message.toId === '1' ? (
                      <SentMessage
                        keyind={index}
                        messageData={message}
                        reply={handleReply}
                        systemMessage={item.system}
                      />
                    ) : (
                      <ReplyMessageSend message={message} reply={handleReply} />
                    )}
                  </>
                ) : (
                  <>
                    {newDate !== '' && (
                      <div className="separator">
                        <span>{getChatHeaderDate(message.createdAt)}</span>
                      </div>
                    )}
                    {message.toId === '1' ? (
                      <ReceivedMessage
                        keyind={index}
                        messageData={message}
                        reply={handleReply}
                        systemMessage={item?.system}
                      />
                    ) : (
                      <ReplyMessageReceived
                        message={message}
                        reply={handleReply}
                      />
                    )}
                  </>
                );
              })}
            </div>
          </div>
        </>
      )}
      {defaultTab === 'activity' && (
        <ActivityTimeline
          activity={chatActivity}
          getUserDetailsById={getUserDetailsById}
        />
      )}
      {defaultTab === 'chat' && (
        <div className="mesage-footer">
          {replyMessage && (
            <div className="reply-section">
              <button
                type="button"
                className="close"
                onClick={handleRemoveReply}
              >
                &times;
              </button>
              <ReceivedMessage messageData={replyMessage} />
            </div>
          )}
          {false && (
            <div className="file-block">
              {chatFiles.length !== 0 &&
                chatFiles.map((doc) => {
                  return <FileItem file={doc} onDiscard={handleFileDiscard} />;
                })}
            </div>
          )}

          {messageSending && <ProgressBar />}
          <div className="chat">
            <div className={`text-message ${formatting ? 'editor' : ''}`}>
              <div
                className={`text-message-container ${
                  formatting ? 'editor' : ''
                }`}
              >
                {isChatParticipant && false && (
                  <label className="add-file-button">
                    <IconButton verient="grey">
                      <AttachIcon />
                    </IconButton>
                    <input
                      type="file"
                      className="attachment"
                      onChange={handleChatFiles}
                      multiple
                    />
                  </label>
                )}

                {/* {formatting && ( */}
                {/* <> */}
                <CustomEditor
                  getEditorValue={(e) => {
                    setMessageText(e);
                  }}
                  onSendClick={() => {
                    sendMessage();
                    setCount((trigger) => trigger + 1);
                  }}
                  onAttachClick={handleChatFiles}
                  chatFiles={chatFiles}
                  onFileDiscardClick={handleFileDiscard}
                  disabled={messageSending}
                  charLimit="250"
                />
                {false && (
                  <RichTextEditor
                    clearEditorOnSend={iscount}
                    chat={true}
                    formatting={formatting}
                    placeholder={
                      isChatParticipant
                        ? t('manageordersscreen.chatmenu.placeholder')
                        : t('manageordersscreen.chatmenu.participants')
                    }
                    setContentChange={setEditorMessageText}
                    value={'<p></p>\n'}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter' && !messageSending) {
                        sendMessage();
                      }
                    }}
                    disabled={!isChatParticipant}
                    enableFormating={false}
                  />
                )}
                {/* </> */}
                {/* )} */}
                {!formatting && false && (
                  <input
                    className="text-message-input panel-item"
                    placeholder={
                      isChatParticipant
                        ? t('manageordersscreen.chatmenu.placeholder')
                        : t('manageordersscreen.chatmenu.participants')
                    }
                    onChange={(e) => setMessageText(e.target.value)}
                    value={messageText}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter' && !messageSending) {
                        sendMessage();
                      }
                    }}
                    disabled={!isChatParticipant}
                  />
                )}

                {isChatParticipant && false && (
                  <button
                    type="button"
                    className="text-message-button panel-item btn-icon send-message-button"
                    onClick={() => {
                      sendMessage();
                      setCount((trigger) => trigger + 1);
                    }}
                    disabled={messageSending}
                  >
                    <SendIcon />
                  </button>
                )}

                {false && (
                  <FormattingButton
                    sendFormatting={setFormatting}
                    toggle={!formatting}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

Chat.propTypes = {
  fullHeight: PropTypes.bool,
  enableTab: PropTypes.bool,
  enableActivity: PropTypes.bool,
  isOnDetailsPage: PropTypes.bool,
  channel: PropTypes.string.isRequired,
  fileSetter: PropTypes.func,
  channelSetter: PropTypes.func.isRequired,
  chatUsers: PropTypes.objectOf(Array),
  profileDetail: PropTypes.instanceOf(Object),
  userOrgDetails: PropTypes.instanceOf(Object),
  bulkActionHandler: PropTypes.func,
  refetch: PropTypes.func,
  allRefetch: PropTypes.func,
  chatActions: PropTypes.instanceOf(Object),
};

Chat.defaultProps = {
  fullHeight: false,
  enableTab: false,
  enableActivity: false,
  isOnDetailsPage: false,
  fileSetter: () => {},
  chatUsers: [],
  profileDetail: {},
  userOrgDetails: {},
  bulkActionHandler: () => {},
  refetch: () => {},
  allRefetch: () => {},
  chatActions: {},
};

function mapStateToProps(state) {
  return {
    profileDetail: state.omniProfile.profileDetail.fetchProfileDetails?.data,
    userOrgDetails:
      state.omniDetails.fetchOrganizationDetail.fetchOrganizationDetails?.data,
  };
}

export default connect(mapStateToProps, null)(Chat);
