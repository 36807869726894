import React from 'react';
import PropTypes from 'prop-types';
import './animate-icon.scss';

export function TrashAnimation({ active }) {
  return (
    <span class={`trash ${active ? 'active' : ''}`}>
      <span className="lid" />
      <span className="box" />
      <span className="box" />
      <span className="box" />
      <i className="buckate" />
    </span>
  );
}

TrashAnimation.propTypes = {
  active: PropTypes.bool,
};
TrashAnimation.defaultProps = {
  active: false,
};
export function SuccessAnimation({ active }) {
  return (
    <span class={`success-icon ${active ? 'active' : ''}`}>
      <span class="success-icon__tip" />
      <span class="success-icon__long" />
    </span>
  );
}
SuccessAnimation.propTypes = {
  active: PropTypes.bool,
};
SuccessAnimation.defaultProps = {
  active: false,
};
