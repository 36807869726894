import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { gql, useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import Button from '../../../Components/button/button';
import './reset-password.scss';
import { getToken } from '../../../service/authService';
import DialogPanel from '../../../Components/dialog-panel/dialog-panel';
import DialogPanelFooter from '../../../Components/dialog-panel/dialog-panel-footer/dialog-panel-footer';
import DialogPanelBody from '../../../Components/dialog-panel/dialog-panel-body/dialog-panel-body';
import TextBoxIcon from '../../../Components/text-box-icon/text-box-icon';
import { PasswordIcon } from '../../../Components/icons/icons';
// import { InfoIcon } from '../../../Components/icons/icons';

const QUERY_CHANGE_PASSWORD = gql`
  mutation Mutation($input: ChangePassInput) {
    changePassword(input: $input) {
      message
    }
  }
`;

export default function ResetPassword({ cancelClick, hide }) {
  const [t] = useTranslation('accountsettings');
  const [t2] = useTranslation('toast');
  const [handlChangePassword] = useMutation(QUERY_CHANGE_PASSWORD);

  const serverCall = (submitpayload) => {
    handlChangePassword({
      variables: {
        input: {
          ...submitpayload,
        },
      },
      onCompleted: () => {
        toast.success(t2('resetpassword.success.passwordupdated'));
        hide();
      },
      // onError: (error) => {
      //      toast.error(error.message);
      //      },
    });
  };

  const formik = useFormik({
    initialValues: {
      oldPassword: '',
      newPassword: '',
      confirmNewPassword: '',
    },
    validationSchema: Yup.object({
      oldPassword: Yup.string().required(
        t('accountsettings.password.editdilog.formvalidation.password')
      ),
      newPassword: Yup.string()
        .required(
          t('accountsettings.password.editdilog.formvalidation.newpassword')
        )
        .matches(
          /^(?=.*\d)(?=.*[!@#$%^&*()])(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
          t('accountsettings.password.editdilog.formvalidation.passwordinvalid')
        )
        .min(
          8,
          t('accountsettings.password.editdilog.formvalidation.passwordmin')
        ),
      confirmNewPassword: Yup.string()
        .oneOf(
          [Yup.ref('newPassword'), null],
          t('accountsettings.password.editdilog.formvalidation.passwordmust')
        )
        .required(
          t('accountsettings.password.editdilog.formvalidation.confirmpassword')
        ),
    }),
    onSubmit: (values) => {
      const payload = {
        ...values,
        accessToken: getToken(),
      };
      serverCall(payload);
    },
  });

  return (
    <div className="reset-password-container">
      <DialogPanel
        title={t('accountsettings.password.editdilog.changepassword')}
      >
        <form onSubmit={formik.handleSubmit}>
          <DialogPanelBody>
            <TextBoxIcon
              required
              id="oldPassword"
              name="oldPassword"
              label={t(
                'accountsettings.password.editdilog.currentpasswordlabel'
              )}
              type="password"
              placeholder={t(
                'accountsettings.password.editdilog.currentpasswordplaceholder'
              )}
              {...formik.getFieldProps('oldPassword')}
              errorMessage={
                formik.touched.oldPassword && formik.errors.oldPassword
                  ? formik.errors.oldPassword
                  : null
              }
            >
              <PasswordIcon color="#A6A6A6" />
            </TextBoxIcon>

            <TextBoxIcon
              required
              id="newPassword"
              name="newPassword"
              label={t('accountsettings.password.editdilog.newpasswordlabel')}
              type="password"
              placeholder={t(
                'accountsettings.password.editdilog.newpasswordplaceholder'
              )}
              {...formik.getFieldProps('newPassword')}
              // required
              // errorMessage={formik.errors.newPassword}
              infoMessage={t(
                'accountsettings.password.editdilog.formvalidation.passwordinvalidInfo'
              )}
              errorMessage={
                formik.touched.newPassword && formik.errors.newPassword
                  ? formik.errors.newPassword
                  : null
              }
            >
              <PasswordIcon color="#A6A6A6" />
            </TextBoxIcon>
            {/* {formik.touched.newPassword && formik.errors.newPassword ? (
              <p className="profile-error-message mt-15">
                {formik.errors.newPassword}
              </p>
            ) : null} */}
            <TextBoxIcon
              required
              id="confirmNewPassword"
              name="confirmNewPassword"
              label={t(
                'accountsettings.password.editdilog.confirmnewpasswordlabel'
              )}
              type="password"
              placeholder={t(
                'accountsettings.password.editdilog.confirmnewpasswordplaceholder'
              )}
              {...formik.getFieldProps('confirmNewPassword')}
              errorMessage={
                formik.touched.confirmNewPassword &&
                formik.errors.confirmNewPassword
                  ? formik.errors.confirmNewPassword
                  : null
              }
            >
              <PasswordIcon color="#A6A6A6" />
            </TextBoxIcon>
          </DialogPanelBody>
          <DialogPanelFooter align="flex-end">
            <Button
              color="cancel"
              label={t('accountsettings.password.editdilog.cancel')}
              onClick={cancelClick}
            />
            <Button
              type="submit"
              color="submit"
              label={t('accountsettings.password.editdilog.updatepassword')}
            />
          </DialogPanelFooter>
        </form>
      </DialogPanel>
    </div>
  );
}

ResetPassword.propTypes = {
  cancelClick: PropTypes.func.isRequired,
  hide: PropTypes.func.isRequired,
};
