import React from 'react';
import './profile-title-description.scss';
import Skeleton from '../skeleton/skeleton';

function ProfileTitleDescriptionLoader() {
  return (
    <div className="profile-title-description">
      <p className="title">
        <Skeleton
          skHeight="1.111vw"
          skWidth="10.417vw"
          skMargin="0 0 0.556vw 0"
        />
      </p>
      <p className="description">
        <Skeleton skHeight="1.111vw" skWidth="10.417vw" />
      </p>
    </div>
  );
}

export default ProfileTitleDescriptionLoader;
